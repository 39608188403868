import { useEffect } from 'react'

import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useUser from 'hooks/useUser'

export default function DealerRedirector() {
    const { user } = useUser()
    const featureFlags = useFeatureFlagContext()

    useEffect(() => {
        if (featureFlags?.whiteLabelRedirect) {
            if (
                user?.dealerPortalUrl &&
                window.location.origin !== user?.dealerPortalUrl
            ) {
                window.location.replace(user?.dealerPortalUrl)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.dealerPortalUrl, featureFlags])

    return null
}
