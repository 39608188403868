import { FieldInstruction } from 'containers/FormBuilder'
import InputField from 'form/fieldComponents/InputField'
import { normalizeNameFormatFn as formatFn } from 'form/fields/utils/nameFieldUtils'
import {
    FIELD_REQUIRED,
    FIELD_TOO_LONG,
    FIELD_TOO_SHORT,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

const lastName: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Last Name',
        validator: Yup.string()
            .min(2, FIELD_TOO_SHORT(2))
            .max(65, FIELD_TOO_LONG(65))
            .matches(
                /^[A-Za-z]'?[a-zA-Z -]+$/,
                // eslint-disable-next-line max-len
                "Last names can only contain letters, hyphens and apostrophes(- ')",
            )
            .required(FIELD_REQUIRED),
        formatFn,
    },
}

export default lastName
