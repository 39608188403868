import React from 'react'

import { FormBuilderContextType } from '../../index'

const FormBuilderContext = React.createContext<
    FormBuilderContextType | undefined
>(undefined)

FormBuilderContext.displayName = 'FormBuilderContext'

export const FormBuilderContextProvider = FormBuilderContext.Provider
export const FormBuilderContextConsumer = FormBuilderContext.Consumer

export function useFormBuilderContext() {
    const context = React.useContext(FormBuilderContext)

    return context
}
