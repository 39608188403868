import React from 'react'

import Card from 'components/Card'
import GeneralInfoForm from 'containers/MyAccount/GeneralInfoForm'
import { SUPPORT_PHONE } from 'utils/consts/contactInfo'

import { Information } from './GeneralInfo.styled'

export default function GeneralInfoPage(): JSX.Element {
    return (
        <Card
            title="General Info"
            rightComponent={
                <Information>
                    If you need to change your username call us at
                    {` ${SUPPORT_PHONE}`}.
                </Information>
            }
            data-testid="general-info"
        >
            <GeneralInfoForm />
        </Card>
    )
}
