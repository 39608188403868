import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function getMaterialKit(
    dealershipId: number,
    flexLinkId?: number,
) {
    try {
        let url = `${config.API_BASE_URL}/api/v3/dealerships/flex-pdf/${dealershipId}`

        if (flexLinkId) url += `?flexLinkId=${flexLinkId}`

        const { data } = await axios.get(url, {
            responseType: 'blob',
            withCredentials: true,
        })

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get material kit: ${axiosError.message}`,
            axiosError,
            { dealershipId },
        )

        throw new Error(axiosError.message)
    }
}
