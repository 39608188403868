export type LeadActivityType = 'new' | 'viewed-by-dealer'

export default class LeadActivity {
    // eslint-disable-next-line no-useless-constructor
    constructor(
        public type: LeadActivityType,
        public created: Date,
        public dealerUserName?: string | null,
    ) {}

    static parseLeadActivityFromApiResponse(response: {
        type: string
        created: string
        dealer_user_name?: string | null
    }) {
        return new LeadActivity(
            response.type as LeadActivityType,
            new Date(response.created),
            response?.dealer_user_name ?? null,
        )
    }
}
