import { typography } from 'assets/styles'
import styled from 'styled-components'

export const ErrorText = styled.h2`
    ${typography.Copy24}
    color: ${(props) => props.theme.colors.monochrome.graphite};
`

export const CloseButtonWrapper = styled.div`
    position: fixed;
    right: 10px;
    top: 10px;
`
