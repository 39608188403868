import { useEffect } from 'react'

import useIntercomChat from './useIntercomChat'
import useUser from './useUser'

export default function useLiveChat() {
    const { user } = useUser()
    const intercomChat = useIntercomChat()

    useEffect(() => {
        if (user) {
            intercomChat.setUser(user)
        }
    }, [intercomChat, user])

    return intercomChat
}
