import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    @media print {
        display: flex;
        align-items: last baseline;
    }
`

export const TitleSubmissionTimeLimit = styled.span`
    ${({ theme }) => theme.fonts.Karla['s16-ln20-fw700']};
    color: ${(props) => props.theme.colors.alert.error};
`
