import { ApiResponse } from 'api/types'
import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

const BASE_URL = `${config.API_BASE_URL}/api/v3/user_invitation`

export default async function acceptInvitation(
    token: string,
    password: string,
    phone: string,
): Promise<ApiResponse> {
    try {
        const { data } = await axios.post(`${BASE_URL}/${token}/accept/`, {
            password,
            dealer_phone: phone,
        })

        return data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to accept invitation: ${axiosError.message}`,
            axiosError,
        )

        if (error?.response?.message) {
            throw new Error(error.response.message)
        }

        throw new Error(axiosError.message)
    }
}
