import WindowLocalStorageIFrameClient from './WindowLocalStorageIFrameClient'

export default class SyncLocalStorageItems {
    client: WindowLocalStorageIFrameClient

    fromLocalStorage: Storage

    constructor(
        fromLocalStorage: Storage,
        client: WindowLocalStorageIFrameClient,
    ) {
        this.client = client
        this.fromLocalStorage = fromLocalStorage
    }

    // eslint-disable-next-line class-methods-use-this
    isSynced(): boolean {
        const syncFlag = localStorage.getItem('localStorageSynced')

        if (syncFlag === null) {
            return false
        }

        return true
    }

    getLocalStorageItems(): string[] {
        return Object.keys(this.fromLocalStorage)
    }

    sync(): void {
        const isSynced = this.isSynced()

        if (isSynced) {
            return
        }

        const localStorageItemKeys = this.getLocalStorageItems()

        localStorageItemKeys.forEach((key: string) => {
            const item = this.fromLocalStorage.getItem(key)

            if (item) {
                this.client.setItem(key, item)
            }
        })

        this.fromLocalStorage.setItem('localStorageSynced', 'true')
    }
}
