import React from 'react'

import { Avatar, UiArrowRightIcon } from '@octane/spark'
import useUser from 'hooks/useUser'
import { useTheme } from 'styled-components'

import {
    Aside,
    MenuItem,
    Navigation,
    AvatarWrapper,
    Wrapper,
} from './AsideMenu.styled'
import { Props } from './types'

const ICON_SIZE = 16

export default function AsideMenu({
    nestedRoutes,
    basePath,
    showAvatar,
    showAssignFlexLinks = true,
}: Props): JSX.Element {
    const theme = useTheme()
    const { user } = useUser()

    return (
        <Wrapper>
            {user && showAvatar && (
                <AvatarWrapper>
                    <Avatar
                        name={`${user.firstName} ${user.lastName}`}
                        size="large"
                        color="auto"
                    />
                </AvatarWrapper>
            )}
            <Aside>
                <Navigation>
                    {nestedRoutes.map((item) => {
                        if (
                            item.path === 'assign-flex-links' &&
                            !showAssignFlexLinks
                        ) {
                            return null
                        }

                        const path = item.path
                            ? `${basePath}/${item.path}`
                            : basePath

                        const isActive = `#${path}` === window.location.hash

                        return (
                            <MenuItem
                                key={item.path ? item.path : basePath}
                                active={isActive}
                                to={path}
                                testId="aside-link"
                            >
                                {item.label}
                                {isActive && (
                                    <UiArrowRightIcon
                                        color={
                                            theme.colors.monochrome.jetlineBlack
                                        }
                                        width={ICON_SIZE}
                                        height={ICON_SIZE}
                                    />
                                )}
                            </MenuItem>
                        )
                    })}
                </Navigation>
            </Aside>
        </Wrapper>
    )
}
