import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 1280px;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`

export const Title = styled.h2`
    ${({ theme }) => theme.fonts.Montserrat['s32-ln40-fw800']}
    margin: 0;
    text-transform: capitalize;
`

export const LeadId = styled.div`
    align-items: flex-end;
    display: flex;

    span {
        ${({ theme }) => theme.fonts.Karla['s16-ln20-fw400']}
    }
`

export const LeadIdLabel = styled.label`
    ${({ theme }) => theme.fonts.Karla['s16-ln20-fw800']}
    margin-right: 10px;
`

export const Content = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        width: 31%;
    }
`

export const ContactInformation = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 10px;
    height: 332px;

    a {
        margin-top: auto;
    }
`

export const ContactRow = styled.div`
    align-items: center;
    display: flex;
    width: 100%;

    > div {
        margin-right: 10px;
        width: 22px;

        > img {
            margin: 0 auto;
        }
    }
`

export const Card = styled.div`
    background-color: ${({ theme }) => theme.colors.monochrome.white};
    border: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    border-radius: 5px;
    box-shadow: ${({ theme }) => theme.shadows.tile};
    padding: 20px;
`

export const CardHeader = styled.div<{
    flexDirection?: 'column' | 'row'
    justifyContent?: 'flex-start' | 'space-between'
}>`
    border-bottom: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    justify-content: ${({ justifyContent }) =>
        justifyContent || 'space-between'};
    min-height: 105px;
    padding-bottom: 20px;
`

export const CardLabel = styled.label`
    ${({ theme }) => theme.fonts.Karla['s12-ln16-fw400']}
    color: ${({ theme }) => theme.colors.monochrome.asphalt};
`

export const CardTitle = styled.h3`
    ${({ theme }) => theme.fonts.Karla['s20-ln26-fw800']}
    margin: 0 0 5px 0;
`

export const CardRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    span:first-child {
        ${({ theme }) => theme.fonts.Karla['s16-ln25-fw400']}
        color: ${({ theme }) => theme.colors.monochrome.ravenSub};
    }

    span:last-child {
        ${({ theme }) => theme.fonts.Karla['s16-ln20-fw800']}
    }
`

export const SSNStatus = styled.span`
    align-items: center;
    display: flex;
    gap: 10px;
`

export const Capitalize = styled.span`
    text-transform: capitalize;
`
export const NoVehicle = styled.label`
    font-family: lato;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    font-style: italic;
    color: rgba(137, 143, 153, 1);
`
