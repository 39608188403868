import React, { useState } from 'react'

import { Button, CepDocsPendingIcon, Select } from '@octane/spark'
import { AssignableUser } from 'api/user/checkUserDeleteRequirements'
import reassignApps from 'api/user/reassignApps'
import * as toast from 'utils/toast'

import { Field, Form } from './UserDeleteModal.styled'

type ReassignAppsProps = {
    userId: number | undefined
    usersToReassign: AssignableUser[]
    onSuccess: () => void
}

export default function ReassignApps({
    userId,
    usersToReassign,
    onSuccess,
}: ReassignAppsProps) {
    const [person, setPerson] = useState('')
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    function reassign() {
        if (userId && person) {
            setLoading(true)

            reassignApps(userId, Number(person))
                .then((data) => {
                    toast.success({
                        message: data.message,
                        withCloseIcon: false,
                    })

                    setLoading(false)
                    onSuccess()
                })
                .catch((error) => {
                    toast.error(error)
                    onSuccess()
                })
        }
    }

    function handlePersonChange(value: string) {
        setPerson(value)
        setIsButtonDisabled(false)
    }

    return (
        <Form>
            <CepDocsPendingIcon width={130} height={130} color="" />
            <h2>This person has open applications.</h2>
            <Field>
                <p>Reassign all of the open applications to another person.</p>
            </Field>
            <Field>
                <Select
                    label="Reassign to"
                    placeholder="Select a Person"
                    id="dropdown-reassign-person"
                    value={person}
                    onChange={(value) => handlePersonChange(value)}
                >
                    {usersToReassign.map((user) => {
                        return (
                            <Select.Option value={`${user.id}`} key={user.id}>
                                {user.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Field>
            <Field>
                <Button
                    fullWidth
                    onClick={() => reassign()}
                    loading={loading}
                    disabled={isButtonDisabled}
                >
                    Reassign Apps
                </Button>
            </Field>
        </Form>
    )
}
