import React from 'react'

import config from 'config'

import FormContent from '../../components/FormContent'
import {
    YearDropdown,
    MakeDropdown,
    ModelDropdown,
    VehicleDropdown,
} from './dropdowns'

interface vehicleSelectionProps {
    assetType?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    vehicleNumber: number
    prefilled?: boolean
    vehicleTypeList?: []
}

const defaultProps = {
    assetType: null,
    prefilled: false,
    vehicleTypeList: null,
}

export default function VehicleSelection({
    assetType,
    formik,
    vehicleNumber,
    prefilled,
    vehicleTypeList,
}: vehicleSelectionProps) {
    const { values } = formik
    const { vehicle } = values
    const { condition } = vehicle

    const dataSource =
        condition === 'new' ? ['LOS_ADMIN', 'LOS'] : ['LOS_ADMIN', 'NADA']

    return (
        <>
            <FormContent gridTemplate="repeat(3, minmax(138px, 2000px))">
                <YearDropdown
                    formik={formik}
                    lenderId={config.ROADRUNNER_LENDER_ID}
                    dataSource={dataSource}
                    assetType={assetType}
                    vehicleNumber={vehicleNumber}
                    prefilled={prefilled}
                    vehicleTypeList={vehicleTypeList}
                />
                <MakeDropdown
                    formik={formik}
                    lenderId={config.ROADRUNNER_LENDER_ID}
                    dataSource={dataSource}
                    assetType={assetType}
                    vehicleNumber={vehicleNumber}
                    prefilled={prefilled}
                    vehicleTypeList={vehicleTypeList}
                />
                {!formik.values.vehicle.isTrailer && (
                    <ModelDropdown
                        formik={formik}
                        lenderId={config.ROADRUNNER_LENDER_ID}
                        dataSource={dataSource}
                        assetType={assetType}
                        vehicleNumber={vehicleNumber}
                        prefilled={prefilled}
                        vehicleTypeList={vehicleTypeList}
                    />
                )}
            </FormContent>
            {!formik.values.vehicle.isTrailer && (
                <FormContent gridTemplate="minmax(296px, 2fr) minmax(0, .96fr)">
                    <VehicleDropdown
                        formik={formik}
                        lenderId={config.ROADRUNNER_LENDER_ID}
                        dataSource={dataSource}
                        assetType={assetType}
                        vehicleNumber={vehicleNumber}
                        prefilled={prefilled}
                    />
                </FormContent>
            )}
        </>
    )
}

VehicleSelection.defaultProps = defaultProps
