import styled from 'styled-components'

export const Legend = styled.legend`
    color: ${(props) => props.theme.colors.monochrome.asphalt};
    font-size: 0.7em;
    font-style: italic;
    /* float: none; */
    display: flex;
    position: absolute;
    right: 0px;
    width: auto;
    z-index: 1;
    justify-content: right;
    transform: translateY(3px);
    svg {
        float: none;
        display: inline-block;
        margin-bottom: 2px;
        margin-left: 0px;
    }
`
