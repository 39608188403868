import * as typography from 'assets/styles/typography'
import styled from 'styled-components'

export const Table = styled.table`
    width: 100%;
`

interface TableHeadProps {
    size?: number
}
export const TableHead = styled.th<TableHeadProps>`
    text-align: left;
    padding: 12px 10px;
    border-bottom: 1px solid
        ${(props) => props.theme.colors.monochrome.concrete};
    ${({ size }) => size && `width: ${size}px;`}
`

interface TableHeadButtonProps {
    canSort?: boolean
}
export const TableHeadButton = styled.button<TableHeadButtonProps>`
    ${typography.TablePrimaryHeader13}
    color: ${(props) => props.theme.colors.monochrome.graphite};
    display: flex;
    align-items: center;
    gap: 6px;
    text-align: left;
`

export const TableRow = styled.tr`
    ${typography.Copy16};
    line-height: 25px;
    color: ${(props) => props.theme.colors.monochrome.ravenSub};
    > td {
        padding: 12px 10px;
        line-height: 1em;
    }
    border-bottom: solid 1px;
    border-color: ${(props) => props.theme.colors.monochrome.smoke};
    transition: background-color 0.2s ease-out;
    &:hover {
        background-color: ${(props) => props.theme.colors.brand.octaneIce};
    }
`
export const ActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 0;
    align-items: center;
    margin-top: -10px;
    margin-bottom: -10px;
    [title='Resend Invitation'] {
        transform: translateY(3px);
    }
`

export const ActionIconContainer = styled.span`
    > svg {
        cursor: pointer;
        padding: 12px 0px;
        &:hover {
            fill: ${({ theme }) => theme.colors.brand.rideOctaneBlue};
        }
    }
`
