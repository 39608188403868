import { FieldInstruction } from 'containers/FormBuilder'
import InputField from 'form/fieldComponents/InputField'
import {
    FIELD_REQUIRED,
    FIELD_TOO_LONG,
    FIELD_TOO_SHORT,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

export const financeManager: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Finance Manager',
        validator: Yup.string()
            .min(2, FIELD_TOO_SHORT(2))
            .max(100, FIELD_TOO_LONG(100))
            .matches(
                /^[-A-Za-z' .]+$/,
                // eslint-disable-next-line max-len
                'Only letters, dashes, apostrophes, periods and spaces are allowed',
            )
            .required(FIELD_REQUIRED),
    },
}
