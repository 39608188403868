import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import AsideMenu from 'components/AsideMenu'
import AssetType from 'state/enums/AssetType'

import useUser from '../../hooks/useUser'
import Enrollment from './Enrollment'
import Home from './Home'
import Lenders from './Lenders'
import { Title, Divisor, Container, OutletContainer } from './MyAccount.styled'
import MyDealership from './MyDealership'
import Notifications from './Notifications'
import Security from './Security'

export const NestedMyAccountRoutes = [
    {
        index: true,
        element: <Home />,
        path: 'my-account',
        label: 'My Account',
    },
    {
        index: false,
        element: <Security />,
        path: 'security',
        label: 'Password & Security',
    },
    {
        index: false,
        element: <Notifications />,
        path: 'notifications',
        label: 'Notifications',
    },
    {
        index: false,
        element: <MyDealership />,
        path: 'my-dealership',
        label: 'My Dealership',
    },
    {
        index: false,
        element: <Enrollment />,
        path: 'enrollment',
        label: 'Product Enrollment',
    },
    {
        index: false,
        element: <Lenders />,
        path: 'lenders',
        label: 'Manage Lenders',
    },
]

export default function MyAccount(): JSX.Element {
    const { user } = useUser()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const defaultRoute = 'my-account'
    let nestedMyAccountRoutesFiltered = NestedMyAccountRoutes

    if (user?.dealership?.primaryAssetType === AssetType.AUTOMOBILE) {
        nestedMyAccountRoutesFiltered = nestedMyAccountRoutesFiltered.filter(
            (x) =>
                x.label !== 'Product Enrollment' &&
                x.label !== 'Manage Lenders',
        )
    }

    useEffect(() => {
        const baseRoutes = ['/account-settings', '/account-settings/']

        if (baseRoutes.indexOf(pathname) !== -1) {
            navigate(defaultRoute)
        }
    }, [pathname, navigate, user])

    return (
        <>
            <Title data-testid="myaccount-title">Account Settings</Title>
            <Divisor />
            <Container>
                <AsideMenu
                    data-testid="myaccount-asideMenu"
                    showAvatar
                    basePath="/account-settings"
                    nestedRoutes={nestedMyAccountRoutesFiltered}
                />
                <TransitionGroup component={OutletContainer}>
                    <CSSTransition
                        key={pathname}
                        classNames="fade"
                        timeout={300}
                    >
                        <Outlet />
                    </CSSTransition>
                </TransitionGroup>
            </Container>
        </>
    )
}
