/* eslint-disable @typescript-eslint/no-explicit-any */
import { validateEmail } from 'api/utils/valueFormats'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'
import { LRUCache } from 'lru-cache'
import * as Yup from 'yup'

let waitingCallbacks: ((value: unknown) => void)[] = []
let emailValidationTimeout: NodeJS.Timeout | null = null

export const emailValidationCache = new LRUCache({
    max: 30,
    ttl: 2 * 60 * 1000, // 2min
})

const yupEmailTest = Yup.string().email()

function frontendValidation(value: string) {
    return yupEmailTest.isValid(value)
}

function backendValidation(yupContext: any, value: string) {
    return new Promise((accept) => {
        waitingCallbacks.push(accept)

        if (emailValidationTimeout !== null) {
            clearTimeout(emailValidationTimeout)
            emailValidationTimeout = null
        }

        const respondWithResult = (result: unknown) => {
            const callbacks = [...waitingCallbacks]

            waitingCallbacks = []

            callbacks.forEach((callback) => callback(result))
        }

        const handleValidationResult = (result: any) => {
            if (result.is_valid) {
                respondWithResult(true)
            } else {
                respondWithResult(
                    yupContext.createError({
                        message: result.message,
                    }),
                )
            }
        }

        const cachedResult = emailValidationCache.get(value)

        if (cachedResult) {
            handleValidationResult(cachedResult)
        } else {
            emailValidationTimeout = setTimeout(() => {
                validateEmail(value)
                    .then((result) => {
                        emailValidationCache.set(value, result)
                        handleValidationResult(result)
                    })
                    .catch((error): void => {
                        // If the server fails to validate the email,
                        // treat the email as valid instead of blocking
                        // the user.
                        respondWithResult(true)

                        OctaneLogger.error(
                            'Unhandled error when trying to validate email.',
                            undefined,
                            error as Error,
                        )
                    })
            }, 400)
        }
    })
}

export default function emailValidation(this: any, value: string | undefined) {
    if (!value) {
        return this.createError({
            message: 'Email cannot be empty',
        })
    }

    return frontendValidation(value).then((result) => {
        if (!result) {
            return this.createError({
                message: 'Must be a valid email address',
            })
        }

        return backendValidation(this, value)
    })
}
