import React from 'react'

import AddRecipientButton from '../AddRecipientButton'
import NotificationDefaultEmail from '../NotificationDefaultEmail'
import NotificationRecipientEmail from '../NotificationRecipientEmail'
import {
    Wrapper,
    Divider,
    NotificationBody,
    NotificationContent,
    NotificationContainer,
    NotificationDescription,
    NotificationName,
} from './Notification.styled'

export interface Recipient {
    email: string
    status: string
    uuid: string
}

interface NotificationProps {
    name: string
    description: string
    status: boolean
    recipients: Array<Recipient>
}

export interface NotificationComponentProps {
    defaultEmail?: string
    notification: NotificationProps
    callback: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default function Notification({
    notification: { name, description, status, recipients },
    defaultEmail,
    callback,
}: NotificationComponentProps) {
    return (
        <Wrapper>
            <Divider />
            <NotificationContainer>
                <NotificationName>{name}</NotificationName>
                <NotificationBody>
                    <NotificationDescription>
                        {description}
                    </NotificationDescription>
                    <NotificationContent>
                        {defaultEmail && (
                            <NotificationDefaultEmail
                                name={name}
                                email={defaultEmail}
                                status={status}
                                callback={callback}
                            />
                        )}
                        {recipients.map((recipient) => {
                            return (
                                <NotificationRecipientEmail
                                    key={recipient.email}
                                    recipient={recipient}
                                    notificationName={name}
                                    callback={callback}
                                />
                            )
                        })}
                        <AddRecipientButton
                            notificationName={name}
                            callback={callback}
                        />
                    </NotificationContent>
                </NotificationBody>
            </NotificationContainer>
        </Wrapper>
    )
}

Notification.defaultProps = {
    defaultEmail: 'test@example.com',
}
