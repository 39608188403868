import styled, { css } from 'styled-components'

import { Container as ToolbarContainer } from './Toolbar.styled'

export const FileViewerContainer = styled.div<{
    isLoaded: boolean
}>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    height: 100%;
    border: solid 5px #323639;
    backdrop-filter: blur(5px);

    ${({ isLoaded }) =>
        isLoaded &&
        css`
            ${ToolbarContainer} {
                opacity: 1;
            }
        `};
`
