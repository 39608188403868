import { FeatureFlagContextInterface } from 'contexts/FeatureFlagContextProvider'
import AssetType from 'state/enums/AssetType'

import isLightspeed from './lightspeed'

const LEGACY_COPY_INTO_NEW_PATH = '#!/application/copy'
const NEW_COPY_INTO_NEW_PATH = '#/copy-into-new'

const makePath = (path: string, applicationId: number) => {
    return `${path}/${applicationId}/`
}

export default (
    featureFlags: FeatureFlagContextInterface | null,
    assetType: AssetType | undefined,
    applicationId: number,
) => {
    if (!assetType || isLightspeed()) {
        return makePath(LEGACY_COPY_INTO_NEW_PATH, applicationId)
    }

    if (assetType === AssetType.AUTOMOBILE) {
        return makePath(NEW_COPY_INTO_NEW_PATH, applicationId)
    }

    if (assetType === AssetType.MARINE) {
        return makePath(NEW_COPY_INTO_NEW_PATH, applicationId)
    }

    if (assetType === AssetType.RECREATIONAL_VEHICLE) {
        return makePath(LEGACY_COPY_INTO_NEW_PATH, applicationId)
    }

    if (assetType === AssetType.POWERSPORTS_VEHICLE) {
        if (featureFlags?.shortApp || featureFlags?.newPowersportsApp) {
            return makePath(NEW_COPY_INTO_NEW_PATH, applicationId)
        }
    }

    return makePath(LEGACY_COPY_INTO_NEW_PATH, applicationId)
}
