const getApplicationDecisionPath = (
    applicationId: string | number,
    decisionPage2Powersports: boolean | undefined,
) => {
    if (decisionPage2Powersports) {
        return `#/application/decision/${applicationId}`
    }

    return `#!/application/${applicationId}/approval`
}

export default getApplicationDecisionPath
