import React from 'react'

import { Button, Checkbox, Modal, TextLink } from '@octane/spark'
import { createLink } from 'api/flexLinks'
import { AssignToPerson } from 'api/flexLinks/getAssignToPersons'
import {
    FormikDropdown,
    FormikMaskedTextInput,
    FormikTextInput,
} from 'components/FormikFields'
import { Form, Formik, FormikHelpers } from 'formik'
import * as toast from 'utils/toast'

import useUser from '../../../../hooks/useUser'
import { findPerson } from '../utils'
import {
    Title,
    Row,
    Divisor,
    Container,
    Actions,
    PopupInfo,
} from './CreateNewLink.styled'
import { CreateNewLinkSchema } from './schema.yup'

type CreateNewLinkProps = {
    open: boolean
    persons: AssignToPerson[]
    onClose: (evt: unknown, refresh?: boolean) => void
}

type FormProps = {
    link: string
    isPrimary: boolean
    assignedTo: string
    email: string
    phoneNumber: string
}

function CreateNewLink({
    open,
    persons,
    onClose,
}: Readonly<CreateNewLinkProps>) {
    const { user: userAuth } = useUser()

    const listUser = persons?.map((person) => {
        const prefix = person.salesPersonId ? 'slp' : 'dup'

        return {
            name: person.name,
            value: `${prefix}#${person.salesPersonId ?? person.dealerUserId}`,
        }
    })

    const initialValuesForm = {
        link: '',
        isPrimary: false,
        assignedTo: '',
        email: '',
        phoneNumber: '',
    }

    const handleSelectAssignedTo = (
        value: string,
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => {
        const personSelected = findPerson(persons, value)

        setFieldValue('email', personSelected.email, false)
        setFieldValue('phoneNumber', personSelected.phoneNumber ?? '', false)
    }

    const handleCreateLink = async (
        values: FormProps,
        { resetForm }: FormikHelpers<FormProps>,
    ) => {
        try {
            const person = findPerson(persons, values.assignedTo)

            await createLink({
                dealershipId: userAuth?.dealership?.id as number,
                link: values.link,
                isPrimary: values.isPrimary,
                // one of these will be empty
                dealerUserId: person.dealerUserId,
                salesPersonId: person.salesPersonId,
            })

            toast.success({
                title: 'Success',
                message: 'Link created successfully!',
            })

            // close the modal and trigger refresh list
            onClose(null, true)

            // clean the form
            resetForm()
        } catch (error) {
            toast.error({
                message: 'An error occurred while creating the link.',
            })
        }

        return true
    }

    return (
        <Modal
            size="large"
            // Force modal to close only changing state
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClose={() => {}}
            withCloseIcon={false}
            title=""
            isOpen={open}
        >
            <Modal.Body>
                <Formik
                    initialValues={initialValuesForm}
                    onSubmit={handleCreateLink}
                    validationSchema={CreateNewLinkSchema}
                    validateOnBlur
                    validateOnChange
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                        dirty,
                        isValid,
                    }) => (
                        <Container>
                            <Title>Create New Link</Title>
                            <Form>
                                <Row columns={2}>
                                    <FormikTextInput
                                        name="link"
                                        label="Link Label"
                                    />
                                </Row>
                                <Row
                                    columns={2}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <Checkbox
                                        id="newLinkPrimary"
                                        label="This link is primarily used in-store"
                                        value="isPrimary"
                                        checked={values.isPrimary}
                                        onChange={(val) => {
                                            const event = {
                                                target: {
                                                    name: 'isPrimary',
                                                    value: val,
                                                },
                                            }

                                            handleChange(event)
                                        }}
                                    />
                                </Row>
                                <Row columns={2}>
                                    <FormikDropdown
                                        name="assignedTo"
                                        info="Used to attribute the source of the app"
                                        label="Assigned to"
                                        placeholder="Select..."
                                        onChange={(value) => {
                                            handleSelectAssignedTo(
                                                value,
                                                setFieldValue,
                                            )
                                        }}
                                        infoText={
                                            <PopupInfo>
                                                Link attribution will be visible
                                                on the Leads Page and in the
                                                Lead email. The assigned owner
                                                in this dropdown will receive
                                                the Lead emails this link
                                                generates.
                                            </PopupInfo>
                                        }
                                    >
                                        {listUser}
                                    </FormikDropdown>
                                </Row>
                                <Row columns={2}>
                                    <FormikTextInput
                                        name="email"
                                        label="Email Address"
                                        disabled
                                    />
                                    <FormikMaskedTextInput
                                        name="phoneNumber"
                                        label="Phone Number"
                                        mask="(000)-000-0000"
                                        placeholder=""
                                        infoText="Optional"
                                        disabled
                                    />
                                </Row>
                            </Form>

                            <Divisor />

                            <Actions>
                                <Button
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                    disabled={
                                        !dirty || !isValid || isSubmitting
                                    }
                                >
                                    Create Link
                                </Button>
                                <TextLink as="button" onClick={onClose}>
                                    Cancel
                                </TextLink>
                            </Actions>
                        </Container>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default CreateNewLink
