import getZipCodes, {
    ZipCodeListByStateCountyCity,
} from 'api/zipCode/getZipCodes'
import dayjs from 'dayjs'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

export type StateCountyCityByZipCode = {
    [zipcode: string]: {
        county: string
        state: string
        city: string
    }
}
type StoragePayload = {
    lastModifiedDate: string
    zipCodeMap: StateCountyCityByZipCode
}

const STORAGE_KEY = 'octane.dealer.zipcodes'

export const formatZipCodeMap = (
    zipCodeMap: ZipCodeListByStateCountyCity,
): StateCountyCityByZipCode => {
    const result: StateCountyCityByZipCode = {}

    Object.keys(zipCodeMap).forEach((state) => {
        Object.keys(zipCodeMap[state]).forEach((county) => {
            Object.keys(zipCodeMap[state][county]).forEach((city) => {
                zipCodeMap[state][county][city].forEach((zipCode) => {
                    result[zipCode] = {
                        state,
                        county,
                        city,
                    }
                })
            })
        })
    })

    return result
}

export class ZipCodesRepository {
    private zipCodeStorage: Storage

    constructor(storage: Storage) {
        this.zipCodeStorage = storage
    }

    async getZipCodeMap(): Promise<StateCountyCityByZipCode> {
        const storedZipCodesData = this.getCachedZipCodeMap()

        if (storedZipCodesData) {
            return storedZipCodesData
        }

        const stateCountyCityMap = await getZipCodes()
        const formattedZipCodeMap = formatZipCodeMap(stateCountyCityMap)

        this.setCachedZipCodeMap(formattedZipCodeMap)

        return formattedZipCodeMap
    }

    private getCachedZipCodeMap(): StateCountyCityByZipCode | null {
        const storedZipCodesData = this.zipCodeStorage.getItem(STORAGE_KEY)

        if (!storedZipCodesData) {
            return null
        }

        const storedZipCodesPayload = JSON.parse(
            storedZipCodesData,
        ) as StoragePayload

        const lastModifiedDate = dayjs(storedZipCodesPayload.lastModifiedDate)
        const lastModifiedHours = dayjs().diff(lastModifiedDate, 'hours')

        // if the zip code map is still fresh (<=24 hours), return it
        if (lastModifiedHours >= 25) {
            return null
        }

        return storedZipCodesPayload.zipCodeMap
    }

    private setCachedZipCodeMap(zipCodeMap: StateCountyCityByZipCode) {
        const storedData = JSON.stringify({
            lastModifiedDate: new Date().toISOString(),
            zipCodeMap,
        })

        try {
            this.zipCodeStorage.setItem(STORAGE_KEY, storedData)
        } catch (error) {
            OctaneLogger.warn(
                'Failed to cache zip codes on the storage.',
                error as Error,
            )
        }
    }
}

export default ZipCodesRepository
