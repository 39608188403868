export enum BookingSource {
    LIKE_INVOICE = 'No Bookout Amount Available',
}

export const LENDING_VALUE_TEXT =
    // eslint-disable-next-line max-len
    'This amount is automatically calculated and will be 90% of the Like Invoice if the Mileage is 6000 or less. ' +
    // eslint-disable-next-line max-len
    'The amount will be 80% of the Like Invoice if the Mileage is greater than 6000'

export function isLastYearOrNewer(year?: number) {
    const currentYear = new Date().getFullYear()
    const isValid = year && year >= currentYear - 1 && year <= currentYear + 1

    return Boolean(isValid)
}

export function isLikeInvoiceBookingSource(bookingSource?: string) {
    return bookingSource === BookingSource.LIKE_INVOICE
}

export function isLikeInvoice(year: number, bookingSource?: string) {
    return isLastYearOrNewer(year) && isLikeInvoiceBookingSource(bookingSource)
}
