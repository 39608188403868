import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateUser as updateUserApi } from 'api/user'

import { loadUser } from '.'

interface updateUserParams {
    [key: string]: string | number
}

const updateUser = createAsyncThunk(
    'user/updateUser',
    async (params: updateUserParams, { dispatch }) => {
        return updateUserApi(params).then(() => dispatch(loadUser()))
    },
)

export default updateUser
