import brandConfig from 'branding'
import { Brand } from 'branding/types'

function updateButtonColors(isCWCaptive = false) {
    if (isCWCaptive) {
        document.documentElement.style.setProperty(
            '--primary-color-start',
            getComputedStyle(document.documentElement).getPropertyValue(
                '--alternate-color-start',
            ),
        )

        document.documentElement.style.setProperty(
            '--primary-color-end',
            getComputedStyle(document.documentElement).getPropertyValue(
                '--alternate-color-end',
            ),
        )
    } else {
        document.documentElement.style.setProperty(
            '--primary-color-start',
            getComputedStyle(document.documentElement).getPropertyValue(
                '--primary-color-start',
            ),
        )

        document.documentElement.style.setProperty(
            '--primary-color-end',
            getComputedStyle(document.documentElement).getPropertyValue(
                '--primary-color-end',
            ),
        )
    }
}

export function applyBrandConfig(): void {
    if (brandConfig?.faviconPath) {
        const link = document.createElement('link')

        link.rel = 'icon'
        link.href = brandConfig.faviconPath

        const head = document.querySelector('head')

        if (head) {
            const existingIcons = head.querySelectorAll(
                'link[rel="icon"], link[rel="shortcut icon"]',
            )

            existingIcons.forEach((icon) => head.removeChild(icon))

            head.insertBefore(link, head.firstChild)
        }
    }

    if (brandConfig?.pageTitle) {
        document.title = brandConfig.pageTitle
    }

    const isCWCaptive: boolean = brandConfig.brand === Brand.GoodSam

    updateButtonColors(isCWCaptive)
}
