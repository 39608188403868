import React, { Ref, useCallback, useEffect, useState } from 'react'
import { useIMask } from 'react-imask'

import { UiHideIcon, UiShowIcon, theme } from '@octane/spark'

import { FormikInput } from '../../components/FormikFields/FormikTextInput/FormikTextInput.styled'
import {
    FieldConfigBase,
    FieldKit,
    FieldProps,
    FormValues,
} from '../../containers/FormBuilder'

export type SsnInputFieldOnChange = (
    value: string,
    fieldName: string,
    formValues: FormValues,
    fieldKit: FieldKit,
) => void

interface SsnInputFieldConfig extends FieldConfigBase {
    label: string
    disabled?: boolean
    placeholder?: string
    prefilled?: boolean
    onChange?: SsnInputFieldOnChange
}

export default function SsnInputField(props: FieldProps<SsnInputFieldConfig>) {
    const { fieldName, fieldKit, fieldConfig } = props
    const touched = fieldKit.touched[fieldName]
    const error = fieldKit.errors[fieldName]
    const fieldKitValue = fieldKit.values[fieldName]
    const [maskSsn, setMaskSsn] = useState(true)
    //    This variable is used as an auxiliary way to verify if the field was changed
    //    by the user or by an external function like Swap applicant and co-applicant data.
    //    That's required because Firefox is not working properly with this component in render time
    const [previousValue, setPreviousValue] = useState('')

    const [opts, setOpts] = useState({
        mask: 'XXX-XX-0000',
        definitions: {
            X: {
                mask: '0',
                displayChar: '*',
            },
        },
    })

    const { ref, maskRef, value } = useIMask(opts)

    useEffect(() => {
        if (maskRef.current) {
            if (value === previousValue) {
                maskRef.current.value = fieldKitValue
                setPreviousValue(fieldKitValue)
            } else {
                fieldKit.setFieldValue(fieldName, maskRef.current.value)
                setPreviousValue(maskRef.current.value)
            }
        }

        if (!fieldKitValue) {
            setMaskSsn(false)
        }
    }, [value, fieldKitValue])

    useEffect(() => {
        setOpts((o) => {
            return {
                ...o,
                mask: maskSsn ? 'XXX-XX-0000' : '000-00-0000',
            }
        })
    }, [maskSsn])

    const toggleMask = useCallback(() => {
        setMaskSsn(!maskSsn)
    }, [maskSsn, setMaskSsn])

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            fieldKit.handleBlur(e)
            if (!error) {
                setMaskSsn(true)
            }
        },
        [fieldKit, error, setMaskSsn],
    )

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
                <FormikInput
                    className="no-print"
                    disabled={fieldConfig.disabled}
                    prefilled={fieldConfig.prefilled}
                    error={!!(touched && error)}
                    errorText={error}
                    id={fieldName}
                    name={fieldName}
                    label={fieldConfig.label}
                    onChange={() => {
                        if (fieldConfig.onChange) {
                            fieldConfig.onChange(
                                value,
                                fieldName,
                                fieldKit.values,
                                fieldKit,
                            )
                        } else {
                            const event = {
                                target: {
                                    name: fieldName,
                                    value,
                                },
                            }

                            fieldKit.handleChange(event)
                        }
                    }}
                    onBlur={handleBlur}
                    placeholder={fieldConfig.placeholder}
                    data-testid={fieldName}
                    ref={ref as Ref<HTMLInputElement>}
                />
                <div
                    className="print-only"
                    style={{ display: 'none', fontSize: '12px' }}
                >
                    {fieldKit.values[fieldName]}
                </div>
            </div>
            <div style={{ marginTop: '33px', marginLeft: '5px' }}>
                {maskSsn && (
                    <UiShowIcon
                        color={theme.colors.brand.rideOctaneBlue}
                        width={24}
                        height={24}
                        onClick={toggleMask}
                    />
                )}
                {!maskSsn && (
                    <UiHideIcon
                        color={theme.colors.brand.rideOctaneBlue}
                        width={24}
                        height={24}
                        onClick={toggleMask}
                    />
                )}
            </div>
        </div>
    )
}
