import AuthenticatorIFrameClient from './AuthenticatorIFrameClient'
import AuthenticatorIFrameServer from './AuthenticatorIFrameServer'
import DocumentViewerServer from './DocumentViewerServer'
import WindowLocalStorageIFrameClient from './WindowLocalStorageIFrameClient'
import WindowLocalStorageIFrameServer from './WindowLocalStorageIFrameServer'
import WindowNavigatorIFrameClient from './WindowNavigatorIFrameClient'
import WindowNavigatorIFrameServer from './WindowNavigatorIFrameServer'

declare global {
    interface Window {
        authenticatorIFrameClient?: AuthenticatorIFrameClient
        authenticatorIFrameServer?: AuthenticatorIFrameServer
        windowNavigatorIFrameClient?: WindowNavigatorIFrameClient
        windowNavigatorIFrameServer?: WindowNavigatorIFrameServer
        windowLocalStorageIFrameClient?: WindowLocalStorageIFrameClient
        windowLocalStorageIFrameServer?: WindowLocalStorageIFrameServer
        documentViewerServer?: DocumentViewerServer
    }
}

export default class IFrameSync {
    serverWindow: Window

    target: Window

    targetOrigin: string

    setIsLoaded: () => void

    authenticatorIFrameClient?: AuthenticatorIFrameClient

    authenticatorIFrameServer?: AuthenticatorIFrameServer

    windowNavigatorIFrameClient?: WindowNavigatorIFrameClient

    windowNavigatorIFrameServer?: WindowNavigatorIFrameServer

    windowLocalStorageIFrameClient?: WindowLocalStorageIFrameClient

    windowLocalStorageIFrameServer?: WindowLocalStorageIFrameServer

    documentViewerServer?: DocumentViewerServer

    constructor(
        serverWindow: Window,
        target: Window,
        targetOrigin: string,
        setIsLoaded: () => void,
    ) {
        this.serverWindow = serverWindow
        this.target = target
        this.targetOrigin = targetOrigin
        this.setIsLoaded = setIsLoaded

        this.setupClients()
        this.setupServers()
    }

    setupClients() {
        this.authenticatorIFrameClient = new AuthenticatorIFrameClient(
            this.target,
            this.targetOrigin,
        )

        this.windowNavigatorIFrameClient = new WindowNavigatorIFrameClient(
            this.target,
            this.targetOrigin,
        )

        this.windowLocalStorageIFrameClient =
            new WindowLocalStorageIFrameClient(this.target, this.targetOrigin)

        window.authenticatorIFrameClient = this.authenticatorIFrameClient

        window.windowLocalStorageIFrameClient =
            this.windowLocalStorageIFrameClient
    }

    setupServers() {
        this.authenticatorIFrameServer = new AuthenticatorIFrameServer(
            this.serverWindow,
            this.targetOrigin,
        )

        this.windowNavigatorIFrameServer = new WindowNavigatorIFrameServer(
            this.serverWindow,
            this.targetOrigin,
        )

        this.windowLocalStorageIFrameServer =
            new WindowLocalStorageIFrameServer(
                this.serverWindow,
                this.targetOrigin,
                this.setIsLoaded,
                this.windowLocalStorageIFrameClient,
            )

        this.documentViewerServer = new DocumentViewerServer(
            this.serverWindow,
            this.targetOrigin,
        )

        window.windowNavigatorIFrameServer = this.windowNavigatorIFrameServer
    }

    setHash(hash: string) {
        const hashChangedMessages =
            this.windowNavigatorIFrameServer?.hashChangedMessages

        if (hashChangedMessages?.exists(hash)) {
            hashChangedMessages?.remove(hash)

            return
        }

        // Verify if when this is run, that we should still
        // change the hash.
        // When redirecting from a legacy route to a web-app one
        // it might redirect us away from the page otherwise.
        if (window.location.hash === hash) {
            this.windowNavigatorIFrameClient?.setHash(hash)
        }
    }
}

export function createIFrameSync(
    serverWindow: Window | null,
    frame: HTMLIFrameElement | null,
    targetOrigin: string,
    setIsLoaded: () => void,
) {
    if (!serverWindow || !frame?.contentWindow) {
        return null
    }

    return new IFrameSync(
        serverWindow,
        frame.contentWindow,
        targetOrigin,
        setIsLoaded,
    )
}
