// eslint-disable react/no-unstable-nested-components
import React, { useEffect, useState } from 'react'

import { ColumnDef } from '@tanstack/react-table'
import Table from 'components/Table'
import { UserInvitation } from 'components/UserInvitationModal/types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { LevelOfAccess } from 'pages/Dealership/ManageUsers/partials/TableColumns'

import ActionsColumn from './partials/ActionsColumn'
import InvitationStatusColumn from './partials/InvitationStatusColumn'
import SendingInfoColumn from './partials/SendingInfoColumn'
import { InvitationList } from './UserInvitationList.styled'

dayjs.extend(relativeTime)

interface InvitationListProps {
    invitations: UserInvitation[]
}

const INVITATION_STATUS_COLUMN: ColumnDef<UserInvitation> = {
    header: 'Invitation Status',
    accessorKey: 'invitationStatus',
    cell: ({ getValue }) => <InvitationStatusColumn status={getValue()} />,
    size: 170,
}

const USER_NAME_COLUMN: ColumnDef<UserInvitation> = {
    header: 'User Name',
    accessorKey: 'fullName',
    size: 300,
}

const EMAIL_COLUMN: ColumnDef<UserInvitation> = {
    header: 'Email',
    accessorKey: 'emailAddress',
    size: 340,
}

const LEVEL_OF_ACCESS_COLUMN: ColumnDef<UserInvitation> = {
    header: 'Level of Access',
    accessorKey: 'levelOfAccess',
    cell: ({ getValue }) => <LevelOfAccess value={getValue()} />,
    size: 150,
}

const INVITATION_SENT_COLUMN: ColumnDef<UserInvitation> = {
    header: 'Invitation Sent',
    accessorFn: (invitationInfo) => invitationInfo,
    cell: ({ getValue }) => <SendingInfoColumn value={getValue()} />,
    size: 240,
}

const ACTIONS_COLUMN: ColumnDef<UserInvitation> = {
    id: 'actions',
    accessorFn: (invitationInfo) => invitationInfo,
    cell: ({ getValue }) => <ActionsColumn value={getValue()} />,
    size: 80,
}

export default function UserInvitationList({
    invitations,
}: InvitationListProps) {
    const [data, setData] = useState(invitations)

    useEffect(() => setData(invitations), [invitations])

    return (
        <InvitationList>
            <Table
                columns={[
                    INVITATION_STATUS_COLUMN,
                    USER_NAME_COLUMN,
                    EMAIL_COLUMN,
                    LEVEL_OF_ACCESS_COLUMN,
                    INVITATION_SENT_COLUMN,
                    ACTIONS_COLUMN,
                ]}
                data={data}
            />
        </InvitationList>
    )
}
