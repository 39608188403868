/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react'

import { Modal, Button } from '@octane/spark'
import { FormikTextInput } from 'components/FormikFields'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'
import useAppDispatch from 'hooks/useAppDispatch'
import { reauthenticateUser, logoutUser } from 'state/user/actions'

import { Copy, ModalTitle } from './ReAuthentication.styled'
import ReauthenticateModalSchema from './schema.yup'

interface ReAuthValues {
    password: string
}

type ReAuthenticationModalProps = {
    isOpen: boolean
    handleClose: () => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleCallback: any
}

const MAX_ATTEMPTS = 5

const INITIAL_VALUES = {
    password: '',
}

function ReAuthenticationModal({
    isOpen,
    handleCallback,
    handleClose,
}: ReAuthenticationModalProps): JSX.Element {
    const formik = useRef<FormikProps<ReAuthValues>>(null)
    const dispatch = useAppDispatch()
    const [attempts, setAttempts] = useState(0)

    const handleFailedAttempt = ({
        setSubmitting,
        setFieldError,
    }: FormikHelpers<ReAuthValues>) => {
        if (attempts === MAX_ATTEMPTS - 1) {
            dispatch(logoutUser())

            return
        }

        const remainingAttempts = MAX_ATTEMPTS - (attempts + 1)

        setFieldError(
            'password',
            `Password is not correct. You have ${remainingAttempts} more attempt${
                remainingAttempts === 1 ? '' : 's'
            } until you are logged out of your account.`,
        )

        setAttempts((attempt) => attempt + 1)
        setSubmitting(false)
    }

    const handlePasswordConfirm = (
        { password }: ReAuthValues,
        formikHelpers: FormikHelpers<ReAuthValues>,
    ) => {
        dispatch(reauthenticateUser({ password })).then((response) => {
            if (response.type !== 'user/reauthenticateUser/rejected') {
                handleCallback()

                return handleClose()
            }

            return handleFailedAttempt(formikHelpers)
        })
    }

    useEffect(() => {
        if (!isOpen) {
            setAttempts(0)
            formik?.current?.resetForm()
        }
    }, [isOpen])

    return (
        <Modal
            data-testid="reauth-modal"
            title=""
            isOpen={isOpen}
            onClose={handleClose}
            withCloseIcon
        >
            <Formik
                innerRef={formik}
                initialValues={INITIAL_VALUES}
                validationSchema={ReauthenticateModalSchema}
                onSubmit={handlePasswordConfirm}
            >
                {({ isSubmitting, dirty, isValid }) => (
                    <Form>
                        <Modal.Body>
                            <ModalTitle>
                                To start, verify your password
                            </ModalTitle>
                            <Copy>
                                Please enter your Octane Account password to
                                verify that you are the logged in user.
                            </Copy>
                            <FormikTextInput
                                label="Password"
                                name="password"
                                type="password"
                                placeholder="Password"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                id="button-verify-password"
                                fullWidth
                                disabled={!dirty || !isValid || isSubmitting}
                                type="submit"
                                size="small"
                            >
                                Verify
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default React.memo(ReAuthenticationModal)
