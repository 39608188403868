import styled from 'styled-components'

import { typography } from '../../../assets/styles'

export const CardMultifactorBody = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 1050px) {
        flex-direction: column;
    }
`

export const LabelWrapper = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 1280px) {
        flex-direction: column;
    }
`
export const MFALabel = styled.div`
    display: flex;
`

export const Information = styled.div`
    margin-left: 17px;
    margin-top: 3px;
    ${typography.Copy14};
    color: ${(props) => props.theme.colors.monochrome.asphalt};
    margin-right: 243px;

    @media (max-width: 1280px) {
        margin-left: 0;
    }
`
