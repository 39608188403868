import React from 'react'

import AssetType from 'state/enums/AssetType'

import BaseCustomerEligibility from './BaseCustomerEligibility'
import CustomerNotEligibleWarningBox from './CustomerNotEligibleWarningBox'

interface AutomobileCustomerEligibilityProps {
    sectionWidth?: string
    sectionMargin?: string
    children: (selectedState: string) => JSX.Element
    initialValue?: string
}

const defaultProps = {
    sectionWidth: undefined,
    sectionMargin: undefined,
    initialValue: undefined,
}

const renderCustomerNotEligibleContent = () => (
    <CustomerNotEligibleWarningBox
        content={
            <>
                The primary applicant must be a resident of one of the listed
                states inorder to be able to purchase an automobile.
            </>
        }
    />
)

const STATE_NOT_LISTED_TOOLTIP_TEXT =
    // eslint-disable-next-line max-len
    'We only fund automobiles in select states. The primary customer must live in an eligible state to qualify for funding for an automobile.'

function AutomobileCustomerEligibility(
    props: AutomobileCustomerEligibilityProps,
): JSX.Element {
    return (
        <BaseCustomerEligibility
            assetType={AssetType.AUTOMOBILE}
            renderCustomerNotEligibleContent={renderCustomerNotEligibleContent}
            stateNotListedToolTipText={STATE_NOT_LISTED_TOOLTIP_TEXT}
            wrapInFormSection
            {...props}
        />
    )
}

AutomobileCustomerEligibility.defaultProps = defaultProps

export default AutomobileCustomerEligibility
