import { typography } from 'assets/styles'
import FormSection from 'components/FormSection'
import styled from 'styled-components'

export const MainContainer = styled.main`
    max-width: 825px;
    padding: 0;
`

export const Wrapper = styled.div`
    justify-content: space-between;
    display: flex;
`

export const StyledHeaderSection = styled(FormSection)`
    border: none;
    justify-content: space-between;
    margin: 0;
    width: 100%;

    @media print {
        margin: 0;
        padding: 0;
        font-size: 12px;

        span {
            padding: 0;
        }
        svg {
            display: none;
        }
    }
`

export const ButtonContainer = styled.div`
    @media print {
        display: none;
    }
`

export const LenderNameText = styled.div`
    ${typography.BodyCopy16};
    color: ${(props) => props.theme.colors.monochrome.graphite};
`

export const Header = styled.h1`
    ${typography.Hero24};
    margin: 0;
`
