import React, { useEffect, useState } from 'react'

import { Spinner } from '@octane/spark'

import Card from '../../../components/Card'
import {
    CardContent,
    Loader,
    Title,
    Content,
    LoaderWrapper,
    FadeWrapperDiv,
} from './Loading.styled'

export const START_TITLE = "We're finding the best offers..."

export const START_MESSAGE =
    "We're considering your customer for all of our loan programs."

export const CHANGE_TEXT_THRESHOLD_SECONDS = 15000

export const TITLE_AFTER_THRESHOLD_SECONDS = 'Gathering those offers now.'
export const MESSAGE_AFTER_THRESHOLD_SECONDS =
    "We're just adding the finishing touches."

const FADE_OUT_THRESHOLD_MILLISECONDS = 13000

type ComponentProps = {
    modal?: boolean
}

export default function Loading({ modal }: ComponentProps): JSX.Element {
    const [title, setTitle] = useState<string>(START_TITLE)
    const [content, setContent] = useState<string>(START_MESSAGE)
    const [fadeOutState, setFadeOutState] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            setFadeOutState(false)
            setTitle(TITLE_AFTER_THRESHOLD_SECONDS)
            setContent(MESSAGE_AFTER_THRESHOLD_SECONDS)
        }, CHANGE_TEXT_THRESHOLD_SECONDS)
    })

    useEffect(() => {
        const fadeTimeout = setTimeout(() => {
            setFadeOutState(title === START_TITLE)
        }, FADE_OUT_THRESHOLD_MILLISECONDS)

        return () => clearTimeout(fadeTimeout)
    })

    const renderLoading = (): JSX.Element => {
        return (
            <CardContent active={modal}>
                <LoaderWrapper>
                    <Loader>
                        <Spinner shadow />
                    </Loader>
                    <FadeWrapperDiv fadeOut={fadeOutState}>
                        <Title>{title}</Title>
                        <Content>{content}</Content>
                    </FadeWrapperDiv>
                </LoaderWrapper>
            </CardContent>
        )
    }

    return (
        <div>
            {modal ? <>{renderLoading()}</> : <Card>{renderLoading()}</Card>}
        </div>
    )
}

Loading.defaultProps = {
    modal: false,
}
