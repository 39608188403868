import { FieldInstruction } from 'containers/FormBuilder'
import InputField from 'form/fieldComponents/InputField'
import { normalizeNameFormatFn as formatFn } from 'form/fields/utils/nameFieldUtils'
import {
    FIELD_REQUIRED,
    FIELD_TOO_LONG,
    FIELD_TOO_SHORT,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

const firstName: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'First Name',
        validator: Yup.string()
            .min(2, FIELD_TOO_SHORT(2))
            .max(32, FIELD_TOO_LONG(32))
            .matches(
                /^[A-Za-z]-?[-a-zA-Z]+$/,
                'Only letters are allowed, for compound names one hyphen is allowed.', // eslint-disable-line max-len
            )
            .required(FIELD_REQUIRED),
        formatFn,
    },
}

export default firstName
