import React, { useState } from 'react'

import { Checkbox, Modal, TextLink } from '@octane/spark'

import { Terms, TermsCheck, TermsText } from './TermsCheckbox.styled'
import TermsContent from './TermsContent'

type Props = {
    onChange: (checked: boolean) => void
}

export default function TermsCheckbox({ onChange }: Props): JSX.Element {
    const [terms, setTerms] = useState(false)
    const [showTerms, setShowTerms] = useState(false)

    function handleClick(value: boolean) {
        setTerms(value)
        onChange(value)
    }

    const handleTokenKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') setShowTerms(true)
    }

    return (
        <Terms>
            <TermsCheck>
                <Checkbox
                    value="terms"
                    checked={terms}
                    onChange={(value) => handleClick(value)}
                    data-testid="terms-checkbox"
                />
            </TermsCheck>
            <TermsText
                data-testid="terms-text"
                onClick={() => handleClick(!terms)}
            >
                By clicking here and selecting Create Account below you agree to
                Octane&apos;s&nbsp;
                <span
                    onClick={(e) => {
                        setShowTerms(true)
                        e.stopPropagation()

                        return false
                    }}
                    onKeyDown={handleTokenKeyDown}
                    role="button"
                    tabIndex={0}
                    data-testid="terms-link"
                >
                    <TextLink as="span" size="medium" variant="simple">
                        Terms of Service.
                    </TextLink>
                </span>
            </TermsText>
            <Modal
                isOpen={showTerms}
                scroll="paper"
                title="Terms and conditions"
                size="large"
                onClose={() => {
                    setShowTerms(false)

                    return false
                }}
            >
                {/* eslint-disable-next-line react/no-danger */}
                <TermsContent />
            </Modal>
        </Terms>
    )
}
