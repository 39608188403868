import styled from 'styled-components'

import { typography } from '../../assets/styles'

export const MainContainer = styled.main`
    max-width: 825px;
    padding: 0;
    margin-top: 0;
`

export const LoaderWrapper = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`

export const ApprovedTitle = styled.h2`
    ${typography.Hero36}
    color: ${({ theme }) => theme.colors.alert.success};
    text-transform: capitalize;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
`

export const DeclinedTitle = styled.h2`
    ${typography.Hero36}
    color: ${({ theme }) => theme.colors.alert.error};
    text-transform: capitalize;
    margin-top: 0.5em;
    margin-bottom: 0;
`

export const SubmittedTitle = styled.h2`
    ${typography.Hero36}
    color: ${({ theme }) => theme.colors.monochrome.jetlineBlack};
    text-transform: capitalize;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
`

export const BodyText = styled.span`
    ${({ theme }) => theme.fonts.Karla['s24-ln32-fw400']}
    margin-top: 1em;
    margin-bottom: 1em;
`

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1em;
    margin-top: 0.5em;
`

export const FinePrint = styled.span`
    ${({ theme }) => theme.fonts.Karla['s16-ln25-fw400']};
    margin-top: 1em;
`

export const RuleLine = styled.hr`
    border-bottom: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    width: 100%;
`

export const DisclosureSpinnerWrapper = styled.div`
    margin-top: 1em;
`

export const DisclosureContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 1em;
`

export const DisclosureTitle = styled.h4`
    ${({ theme }) => theme.fonts.Karla['s14-ln16-fw800']}
    margin-top: 0.7em;
    margin-bottom: 0.3em;
`

export const DisclosureParagraph = styled.p`
    ${({ theme }) => theme.fonts.Karla['s12-ln22-fw400']}
    margin-top: 0.7em;
    margin-bottom: 0;
`
