import React from 'react'

import { getIn, FieldProps, Field } from 'formik'

import { AnimatedField, FormikInput } from './FormikTextInput.styled'

type FormikTextInputProps = {
    name: string
    label: string
    type?: 'text' | 'email' | 'password' | 'number'
    disabled?: boolean
    placeholder?: string
    prefilled?: boolean
    infoText?: string
} & typeof defaultProps

const defaultProps = {
    disabled: false,
    placeholder: '',
    type: 'text',
    prefilled: false,
    infoText: '',
}

function FormikTextInput({
    name,
    placeholder,
    label,
    disabled,
    type,
    prefilled,
    infoText,
}: FormikTextInputProps) {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                const touched = getIn(form.touched, field.name)
                const error = getIn(form.errors, field.name)

                return (
                    <AnimatedField>
                        <FormikInput
                            type={type}
                            disabled={
                                disabled ||
                                form.values.disabledFields?.includes(field.name)
                            }
                            error={!!(touched && error)}
                            errorText={error}
                            id={field.name}
                            name={field.name}
                            label={label}
                            infoText={infoText}
                            value={field.value}
                            onChange={(val: string) => {
                                let parsedValue: string | number | undefined =
                                    val

                                if (type === 'number') {
                                    parsedValue = Number(val)

                                    if (Number.isNaN(parsedValue)) {
                                        parsedValue = undefined
                                    }
                                }

                                const event = {
                                    target: {
                                        name,
                                        value: parsedValue,
                                    },
                                }

                                form.handleChange(event)
                            }}
                            onBlur={form.handleBlur}
                            placeholder={placeholder}
                            data-testid={name}
                            prefilled={prefilled}
                        />
                    </AnimatedField>
                )
            }}
        </Field>
    )
}

FormikTextInput.defaultProps = defaultProps

export default FormikTextInput
