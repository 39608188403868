import React from 'react'

import { FieldInstruction } from 'containers/FormBuilder'
import CheckboxField from 'form/fieldComponents/CheckboxField'
import DisclosureField from 'form/fieldComponents/DisclosureField'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ConsentLabel } from '../../components/FormikFields/FormikCheckbox/FormikCheckbox.styled'

const ConsentContainer = styled.div`
    display: block;
    padding-left: 1vw;
`

const ConsentParagraph = styled.p`
    margin-top: 0;
`

function CustomerVerificationCheckboxLabel(): JSX.Element {
    return (
        <ConsentLabel>
            <ConsentContainer>
                <ConsentParagraph>
                    Customer ID Verification Acknowledgment:
                </ConsentParagraph>

                <ConsentParagraph>
                    Dealership acknowledges that it has verified the identity of
                    each borrower submitted in accordance with Roadrunner
                    Financial’s requirements, as set forth in the Dealer
                    Underwriting Guide, by checking 2 forms of identification
                    for each borrower. The dealership confirms that one ID
                    consisted of a current valid U.S. state issued driver’s
                    license, U.S. state issued identification card, U.S.
                    military driver’s license, U.S. passport or U.S. Green Card.
                    Dealer acknowledges that failure to adhere to the
                    requirements as set forth in Roadrunner Financial’s Dealer
                    Underwriting Guide may result in a repurchase of any
                    affected loans.
                </ConsentParagraph>

                <ConsentParagraph>
                    Following the Dealer Underwriting Guide is required under
                    the terms of the Dealer Agreement, with which Dealers must
                    comply at all times. The Dealer Underwriting Guide is
                    maintained at https://www.roadrunnerfinancial.com/dealer and
                    may be updated from time to time. Dealer is responsible for
                    monitoring updates and complying with the updated Dealer
                    Underwriting Guide.
                </ConsentParagraph>
            </ConsentContainer>
        </ConsentLabel>
    )
}

export const customerVerificationCheckbox: FieldInstruction<
    typeof CheckboxField
> = {
    Component: CheckboxField,
    config: {
        label: <CustomerVerificationCheckboxLabel />,
        alignItems: 'flex-start',
        validator: Yup.bool().oneOf([true]).required(FIELD_REQUIRED),
        initialValue: false,
    },
}

export const customerVerificationDisclosure: FieldInstruction<
    typeof DisclosureField
> = {
    Component: DisclosureField,
    config: {
        content: <CustomerVerificationCheckboxLabel />,
        validator: Yup.object()
            .shape({
                name: Yup.string(),
                val: Yup.bool().oneOf([true]),
            })
            .required(FIELD_REQUIRED),
        initialValue: false,
    },
}
