import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export async function sendResetPassword(emailAddress: string) {
    const requestParams = {
        email: emailAddress,
        is_from_dealer_portal: true,
    }

    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/users/send_reset_password_email/`, // eslint-disable-line max-len
            requestParams,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to send reset password email: ${axiosError.message}`,
            axiosError,
        )

        throw axiosError
    }
}
