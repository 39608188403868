import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUsers } from 'api/dealership'
import { RootState } from 'state/store'

const loadUsers = createAsyncThunk<
    any, // eslint-disable-line @typescript-eslint/no-explicit-any
    number | undefined,
    {
        state: RootState
    }
>('dealership/loadUsers', async (page: number | undefined, thunkAPI) => {
    const state: RootState = thunkAPI.getState()
    const currentPage = state.dealership.users.meta.page || 1

    return getUsers(page || currentPage)
})

export default loadUsers
