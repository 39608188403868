import React from 'react'
import { useLocation } from 'react-router-dom'

import LegacyFrame from '../../components/LegacyFrame/LegacyFrame'
import config from '../../config'
import useUser from '../../hooks/useUser'
import LoginPage from '../../pages/Authentication/LoginPage'
import LoadingStatus from '../../state/enums/LoadingStatus'
import { isPublicRoute } from '../../utils/isPublicRoute'
import isLightspeed from '../../utils/lightspeed'

export default function LoginManager(): JSX.Element {
    const { isLoggedIn, status } = useUser()
    const { pathname } = useLocation()

    if (!isPublicRoute(pathname) && !isLightspeed()) {
        if (
            !isLoggedIn &&
            (status === LoadingStatus.FAILED || status === LoadingStatus.LOADED)
        ) {
            return <LoginPage />
        }
    }

    return <LegacyFrame url={config.LEGACY_APP_URL} pathname={pathname} />
}
