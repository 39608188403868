import { UiArrowExpandIcon } from '@octane/spark'
import brandConfig from 'branding'
import styled from 'styled-components'

export const NavbarDropdownList = styled.li`
    position: relative;
    display: block;
    padding-top: 5px;
`
export const NavbarDropdownButtonStyled = styled.button`
    padding-bottom: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const NavbarDropdownSubtext = styled.span`
    font-size: 70%;
    font-family: 'Karla';
    font-weight: 200;
    color: ${brandConfig.theme.navbar.color};
    text-align: center;
    padding-top: 3px;
    ${NavbarDropdownButtonStyled}:hover & {
        color: ${brandConfig.theme.navbar.hoverColor};
    }
`
export const NavbarDropdownIcon = styled(UiArrowExpandIcon)`
    margin-top: 3px;
    display: inline-block;
    position: absolute;
    ${NavbarDropdownButtonStyled}:hover & {
        fill: ${brandConfig.theme.navbar.hoverColor};
    }
`

export const NavbarDropdownHelpIcon = styled.span`
    ${NavbarDropdownButtonStyled}:hover & {
        fill: ${brandConfig.theme.navbar.hoverColor};
    }
`
