import { AssignToPerson } from 'api/flexLinks/getAssignToPersons'

export const findPerson = (
    persons: AssignToPerson[],
    personId: string,
    separator = '#',
) => {
    const [type, id] = personId.split(separator)

    return persons.find((person) => {
        if (type === 'slp') {
            return person.salesPersonId?.toString() === id
        }

        // type === 'dup'
        return person.dealerUserId?.toString() === id
    }) as AssignToPerson
}

export const buildAssignedToId = (
    dealerUserId: string | undefined,
    salesPersonId: string | undefined,
) => {
    const prefix = salesPersonId ? 'slp' : 'dup'

    return `${prefix}#${dealerUserId ?? salesPersonId}`
}
