import { createAsyncThunk } from '@reduxjs/toolkit'
import logout from 'api/user/logout'

interface LogoutUserConfig {
    skipLogoutLegacyApp?: boolean
    skipRedirect?: boolean
}

const logoutUser = createAsyncThunk(
    'user/logout',
    async (params?: LogoutUserConfig) => {
        if (!params?.skipLogoutLegacyApp) {
            /* @ts-expect-error: this was set in IFrameSync.ts */
            window.authenticatorIFrameClient.logout()
        }

        await logout()

        // shutting LiveChat down
        if (window.Intercom) window.Intercom('shutdown')

        if (!params?.skipRedirect) window.location.hash = '#!/'
    },
)

export default logoutUser
