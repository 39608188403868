/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCollaterals, postApplicationCollateral } from 'api/application'
import deleteApplicationCollateral from 'api/application/deleteApplicationCollateral'
import { getVehicleDetails } from 'api/vehicles/getVehicles'
import { createPostCollateralDTO } from 'pages/NewApplication/Powersports/utils'
import { PostCollateralDTO } from 'pages/NewApplication/Powersports/utils/createPostCollateralDTO'

const deleteApplicationCollaterals = async (applicationUuid: string) => {
    const collaterals = await getCollaterals(applicationUuid)

    const promises = collaterals.map((collateral) =>
        deleteApplicationCollateral(applicationUuid, collateral.id.toString()),
    )

    return Promise.all(promises)
}

const makeTitleInformation = (vehicleData: any) => {
    return {
        has_title: vehicleData.hasTitle === true,
        no_title_reason:
            vehicleData.noTitleReason !== ''
                ? vehicleData.noTitleReason
                : undefined,
        no_title_other_reason:
            vehicleData.noTitleOtherReason !== ''
                ? vehicleData.noTitleOtherReason
                : undefined,
    }
}

const makeTrailerCollateral = (vehicleData: any) => {
    const mileage = vehicleData.mileage
        ? parseInt(vehicleData.mileage, 10)
        : undefined

    return {
        asset_type: 'powersports_vehicle',
        asset_value: vehicleData.trailerValue,
        // Data source is set as 'los_asset' instead of 'write_in' to be
        // compatible with legacy trailer implementation.
        data_source: 'los_asset',
        asset: {
            vehicle_type: 'trailer',
            condition: vehicleData.condition === 'new' ? 'N' : 'U',
            make: vehicleData.make,
            year: vehicleData.year,
            model: vehicleData.trailerModel,
            weight: vehicleData.trailerWeight,
            title_information: makeTitleInformation(vehicleData),
            mileage,
        },
    }
}

const makeVehicleCollateral = async (vehicleData: any) => {
    const vehicleId = vehicleData.selectedVehicleId.split(':')[2]
    const vehicle = await getVehicleDetails(vehicleId)

    const mileage = vehicleData.mileage
        ? parseInt(vehicleData.mileage, 10)
        : undefined

    const collateral = createPostCollateralDTO(
        vehicle,
        null,
    ) as PostCollateralDTO

    collateral.asset.mileage = mileage
    collateral.asset.title_information = makeTitleInformation(vehicleData)

    return Promise.resolve(collateral)
}

export default async function setApplicationCollaterals(
    applicationUuid: string,
    {
        condition,
        makeId,
        isTrailer,
        multiUnit,
        primary: primaryVehicle,
        secondary: secondaryVehicle,
    }: any,
    deleteExisting = false,
) {
    const collaterals = []

    if (isTrailer) {
        collaterals.push(
            makeTrailerCollateral({
                ...primaryVehicle,
                condition,
                makeId,
            }),
        )
    } else {
        collaterals.push(await makeVehicleCollateral(primaryVehicle))

        if (multiUnit && secondaryVehicle?.selectedVehicleId) {
            collaterals.push(await makeVehicleCollateral(secondaryVehicle))
        }
    }

    if (deleteExisting) {
        await deleteApplicationCollaterals(applicationUuid)
    }

    /**
     * The collaterals must be saved in serial to prevent unexpected
     * behaviors such as multiple UCC Title Decisions being created
     * for used multi-unit applications.
     *
     * See https://octane.atlassian.net/browse/FIKA-2899
     *
     * Accomplish this by using reduce instead of a for...of as it's
     * not recommended by eslint due to it's large polyfill size.
     */
    await collaterals.reduce(async (a, collateral) => {
        await a
        await postApplicationCollateral(applicationUuid, collateral)
    }, Promise.resolve())
}
