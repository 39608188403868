import React, { useState } from 'react'

import { Avatar, TextLink } from '@octane/spark'
import getAssignToPersons, {
    AssignToPerson,
} from 'api/flexLinks/getAssignToPersons'
import DealerUserDropdown from 'pages/LeadsPage/utils/DealerUserDropdown'

import { Wrapper, User, DropdownWrapper } from './DealerAssigned.styled'

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
}

export default function DealerAssigned({
    value,
}: Readonly<Props>): JSX.Element {
    const [editUser, setEditUser] = useState(false)
    const [response, updateResponse] = useState([] as AssignToPerson[])
    let name = ''
    let dealUserId = ''
    let salesPersonId = ''
    let applicationId = ''

    if (value.application_root_id) {
        applicationId = value.application_root_id

        if (value.dealer_user) {
            const { first_name: first, last_name: last, id } = value.dealer_user

            name = first.concat(' ')
            name = name.concat(last)
            dealUserId = id
        }

        if (value.salesperson) {
            const { first_name: first, last_name: last, id } = value.salesperson

            name = first.concat(' ')
            name = name.concat(last)
            salesPersonId = id
        }
    } else {
        applicationId = value
    }

    const person = {
        name,
        email: '',
        dealUserId,
        salesPersonId,
    }

    const [user, updateUser] = useState<AssignToPerson | undefined>(person)

    function getUsers() {
        getAssignToPersons().then((persons: AssignToPerson[]) => {
            updateResponse(persons)
        })

        setEditUser(true)
    }

    const setUser = (): JSX.Element => {
        return (
            <>
                {user && user?.name !== '' && (
                    <>
                        <Avatar
                            name={`${user?.name}`}
                            size="large"
                            color="auto"
                        />
                        <User>{user?.name}</User>
                    </>
                )}
                {user && user?.name === '' && <User>Unassigned</User>}
            </>
        )
    }

    return (
        <>
            {!editUser && (
                <Wrapper>
                    {value && setUser()}
                    {!value && <User>Unassigned</User>}
                    <TextLink
                        as="button"
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault()
                            getUsers()
                        }}
                    >
                        edit
                    </TextLink>
                </Wrapper>
            )}
            {editUser && (
                <DropdownWrapper>
                    <DealerUserDropdown
                        users={response}
                        setEditUser={setEditUser}
                        appId={applicationId}
                        user={user}
                        updateUser={updateUser}
                    />
                </DropdownWrapper>
            )}
        </>
    )
}
