import React from 'react'

import { FormikCheckbox } from 'components/FormikFields/FormikCheckbox/FormikCheckbox.styled'

import {
    FieldConfigBase,
    FieldKit,
    FieldProps,
    FormValues,
} from '../../containers/FormBuilder'

interface CheckboxFieldConfig extends FieldConfigBase {
    label: JSX.Element
    disabled?: boolean
    alignItems?: React.CSSProperties['alignItems']
    prefilled?: boolean
    onChange?: (
        value: boolean,
        fieldName: string,
        formValues: FormValues,
        fieldKit: FieldKit,
    ) => void
}

export default function CheckboxField(
    props: FieldProps<CheckboxFieldConfig>,
): JSX.Element {
    const { fieldName, fieldKit, fieldConfig } = props
    const touched = fieldKit.touched[fieldName]
    const error = fieldKit.errors[fieldName]
    const value = fieldKit.values[fieldName]
    const alignItems = fieldConfig.alignItems ?? 'center'
    const checkboxId = fieldName

    return (
        <div style={{ display: 'flex', alignItems }}>
            <FormikCheckbox
                disabled={fieldConfig.disabled}
                error={!!(touched && error)}
                id={checkboxId}
                label=""
                value={fieldName}
                checked={value}
                onChange={(val) => {
                    if (fieldConfig.onChange) {
                        fieldConfig.onChange(
                            val,
                            fieldName,
                            fieldKit.values,
                            fieldKit,
                        )
                    } else {
                        const event = {
                            target: {
                                name: fieldName,
                                value: val,
                            },
                        }

                        fieldKit.handleChange(event)
                    }
                }}
                data-testid={fieldName}
                prefilled={fieldConfig.prefilled}
            />
            <label htmlFor={checkboxId}>{fieldConfig.label}</label>
        </div>
    )
}

CheckboxField.defaultProps = {
    disabled: false,
}
