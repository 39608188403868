import React, { useEffect, useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { Button, UiDeleteIcon, UiPlusIcon } from '@octane/spark'
import { getLenderOptions } from 'api/lender'
import Card from 'components/Card'
import Select from 'components/Select'
import useUser from 'hooks/useUser'
import * as toast from 'utils/toast'

import {
    Description,
    LendersList,
    LenderSelectRow,
    SelectWrapper,
    AddedLender,
    LenderName,
    LenderWrapper,
    LenderExtraInfo,
    LenderInfoWrapper,
    LenderInfo,
} from './Lenders.styled'
import { RemoveLenderModal } from './Modals/RemoveLenderModal'
import {
    adapterLenderOptionsToObject,
    getAlreadyAddedLenders,
    getUnaddedLenders,
    isPartner,
    LenderOptions,
    updateAlreadyAddedLendersStorage,
} from './utils'

export default function LendersPage(): JSX.Element {
    const { user } = useUser()
    const [lenderOptions, setLenderOptions] = useState<LenderOptions[]>([])
    const [addedLenders, setAddedLenders] = useState<LenderOptions[]>([])
    const [allOptions, setAllOptions] = useState<LenderOptions[]>([])
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
    const [lenderToRemove, setLenderToRemove] = useState<LenderOptions | null>()

    const [selectedLender, setSelectedLender] = useState<LenderOptions | null>(
        null,
    )

    const handleAddLender = () => {
        if (!selectedLender || !user) {
            return
        }

        const alreadyAddedLenders = getAlreadyAddedLenders(user.id)

        alreadyAddedLenders?.push(selectedLender.value)
        updateAlreadyAddedLendersStorage(user.id, alreadyAddedLenders)

        const newLenderOptions = getUnaddedLenders(
            lenderOptions,
            selectedLender,
        )

        const lendersAdapted = adapterLenderOptionsToObject(user.id)

        setAddedLenders(lendersAdapted)
        setLenderOptions(newLenderOptions)

        toast.success({
            message: `${selectedLender.label} successfully added.`,
        })
    }

    const removeLender = () => {
        if (!user || !lenderToRemove) {
            return
        }

        const newLenderOptions = [...lenderOptions, lenderToRemove]

        setLenderOptions(newLenderOptions)

        const newAddedLendersObj = addedLenders.filter(
            (lender) => lender.value !== lenderToRemove.value,
        )

        setAddedLenders(newAddedLendersObj)

        const newAddedLendersString = newAddedLendersObj.map(
            (lender) => lender.value,
        )

        updateAlreadyAddedLendersStorage(user.id, newAddedLendersString)

        setIsRemoveModalOpen(false)

        toast.success({
            message: `${lenderToRemove.label} successfully removed.`,
        })

        setLenderToRemove(null)
        setSelectedLender(null)
    }

    const handleOnClickRemoveButton = (lender: LenderOptions) => {
        setLenderToRemove(lender)
        setIsRemoveModalOpen(true)
    }

    const renderAddedLenders = () => {
        return addedLenders.map((lender) => (
            <CSSTransition key={lender.value} timeout={300} classNames="slide">
                <AddedLender>
                    <LenderWrapper>
                        <LenderInfoWrapper>
                            <LenderName>{lender.value}</LenderName>
                            <LenderInfo>
                                Doesn&apos;t require setting credentials
                            </LenderInfo>
                        </LenderInfoWrapper>
                        {lender.value === 'Fax Lender' ? (
                            <LenderExtraInfo>
                                Dealers can send faxes through Octane to any
                                lender. Octane does not guarantee that lenders
                                will accept faxes sent by Octane.
                            </LenderExtraInfo>
                        ) : null}
                        {lender.value === 'Springleaf' ? (
                            <LenderExtraInfo>
                                You must add the fax number for a Springleaf
                                branch to submit applications with Springleaf.
                            </LenderExtraInfo>
                        ) : null}
                        {!isPartner(allOptions, lender) ? (
                            <LenderExtraInfo>
                                (Not a partner lender)
                            </LenderExtraInfo>
                        ) : null}
                    </LenderWrapper>
                    {lender.value !== 'Roadrunner Financial' ? (
                        <Button
                            data-testid="removeLenderButton"
                            variant="secondary"
                            startIcon={UiDeleteIcon}
                            onClick={() => handleOnClickRemoveButton(lender)}
                        >
                            Remove Lender
                        </Button>
                    ) : null}
                </AddedLender>
            </CSSTransition>
        ))
    }

    useEffect(() => {
        const setAlreadyAddedLenders = (lenders: LenderOptions[]) => {
            if (user) {
                const lendersAdapted = adapterLenderOptionsToObject(user.id)

                setAddedLenders(lendersAdapted)

                if (lendersAdapted.length) {
                    const newLenderOptions = getUnaddedLenders(
                        lenders,
                        lendersAdapted,
                    )

                    setLenderOptions(newLenderOptions)
                } else {
                    setLenderOptions(lenders)
                }
            }
        }

        const loadLendersOptions = async () => {
            const result = await getLenderOptions()

            const lenders = result.data.objects.map((lender) => ({
                label: lender.name,
                value: lender.name,
                partner: lender.partner,
            }))

            setAllOptions(lenders)

            setAlreadyAddedLenders(lenders)
        }

        loadLendersOptions()
    }, [user])

    return (
        <div>
            <Card title="Manage Lenders">
                <RemoveLenderModal
                    isOpen={isRemoveModalOpen}
                    handleOnClickRemoveButton={removeLender}
                    onClose={() => setIsRemoveModalOpen(false)}
                />
                <Description>
                    As a convenience for dealers and to eliminate the need to
                    rekey customer information, Octane allows dealers to
                    integrate a dealer&apos;s existing lenders. These are
                    lenders that dealers currently use, but Octane maintains no
                    affiliation. For these lenders, dealers have the option to
                    store passwords locally on their Google Chrome browser, but
                    not on Octane&apos;s servers. Since the lender credentials
                    are stored locally on your computer, Octane staff and
                    servers cannot and will not access your lender credentials.
                </Description>
                <LendersList data-testid="addedLenderList">
                    <TransitionGroup>{renderAddedLenders()}</TransitionGroup>
                </LendersList>
                {lenderOptions.length ? (
                    <LenderSelectRow data-testid="lender-row">
                        <SelectWrapper>
                            <Select
                                value={selectedLender?.value ?? ''}
                                placeholder="Select Lender"
                                label=""
                                id="dropdown-lender"
                                options={lenderOptions}
                                onChange={(value) =>
                                    setSelectedLender({
                                        label: value,
                                        value,
                                    })
                                }
                            />
                        </SelectWrapper>
                        <Button
                            disabled={!selectedLender}
                            startIcon={UiPlusIcon}
                            onClick={handleAddLender}
                        >
                            Add Lender
                        </Button>
                    </LenderSelectRow>
                ) : null}
            </Card>
        </div>
    )
}
