/* eslint-disable @typescript-eslint/no-explicit-any */
import { verifyMfaFactor } from 'api/user/mfa'

import { FactorType } from './enums'
import { FactorResult } from './types'

export default async function verifyFactor(
    factorId: FactorType,
    challengeAnswer: string,
): Promise<FactorResult> {
    return verifyMfaFactor(factorId, challengeAnswer)
        .then((response) => response?.data)
        .then((response: any) => {
            if (response?.is_valid !== true) {
                throw new Error(response?.message || 'Failed to verify code.')
            }

            return response
        })
}
