import getClientApplication, {
    ResponseApprovalTerm,
    ResponseClientApplication,
    ResponseDecisionBlock,
} from 'api/leads/getLeadDetails'
import getCollateralsVehicle, {
    ResponseCollateralVehicle,
} from 'api/vehicles/getCollateralsVehicle'
import getVehicle, { ResponseVehicle } from 'api/vehicles/getVehicle'

import LeadActivity from '../models/lead_activity'
import {
    ApprovalTerm,
    CollateralVehicle,
    Consumer,
    DecisionBlock,
    LeadDetail,
    Vehicle,
    VehicleCondition,
} from '../types'

export function parseCondition(condition?: string): VehicleCondition {
    if (condition === 'U') return 'used'

    if (condition === 'N') return 'new'

    return 'unknown'
}

function parseApprovalTerms(
    approvalTerms?: ResponseApprovalTerm[] | null,
): ApprovalTerm[] {
    return (approvalTerms ?? new Array<ResponseApprovalTerm>()).map(
        (el: ResponseApprovalTerm) => ({
            id: el.id,
            isPrime: el.is_prime,
            riskTier: el.risk_tier ? parseFloat(el.risk_tier) : null,
            riskTierLabel: el.risk_tier_label,
        }),
    )
}

export function reduceDecisionsBlocks(decisionBlocks: DecisionBlock[]): {
    isPrime: boolean
    riskTier: number | null
} {
    if (decisionBlocks.length === 0) return { isPrime: false, riskTier: null }

    let approvalTermsList: ApprovalTerm[] = []

    decisionBlocks.forEach((el: DecisionBlock) => {
        approvalTermsList = [...approvalTermsList, ...el.approvalTerms]
    })

    return approvalTermsList.reduce<{
        isPrime: boolean
        riskTier: number | null
    }>(
        (
            prevItem: { isPrime: boolean; riskTier: number | null },
            currentItem: ApprovalTerm,
        ) => {
            let { riskTier } = prevItem

            if (
                !riskTier ||
                (currentItem.riskTier && currentItem.riskTier <= riskTier)
            )
                riskTier = currentItem.riskTier

            return {
                isPrime: prevItem.isPrime || (currentItem.isPrime ?? false),
                riskTier,
            }
        },
        { isPrime: false, riskTier: null },
    )
}

function parseDecisionsBlocks(
    decisionsBlocks?: ResponseDecisionBlock[] | null,
): DecisionBlock[] {
    return (decisionsBlocks ?? new Array<ResponseDecisionBlock>()).map(
        (el: ResponseDecisionBlock) => ({
            id: el.id,
            message: el.message,
            approvalTerms: parseApprovalTerms(el.approval_terms),
            type: el.type,
        }),
    )
}

function parseVehicle(responseVehicle: ResponseVehicle): Vehicle {
    return {
        id: responseVehicle?.id,
        model: responseVehicle?.model?.name,
        make: responseVehicle?.model?.make?.name,
        year: responseVehicle?.year,
        msrp: responseVehicle?.msrp,
        isDummy: responseVehicle?.is_dummy ?? false,
        condition: parseCondition(responseVehicle?.condition),
        category: responseVehicle?.vehicle_type,
    }
}

function parseCollateralVehicle(
    responseCollateralVehicle: ResponseCollateralVehicle,
): CollateralVehicle {
    return {
        model: responseCollateralVehicle.model,
        make: responseCollateralVehicle.make,
        year: responseCollateralVehicle.year,
        msrp: responseCollateralVehicle.msrp,
        condition: parseCondition(responseCollateralVehicle?.condition),
    }
}

function parseSSN(responseLead: ResponseClientApplication) {
    if (responseLead.social_security_number)
        return responseLead.social_security_number

    if (responseLead.ssn1 && responseLead.ssn2 && responseLead.ssn3)
        return `${responseLead.ssn1}-${responseLead.ssn2}-${responseLead.ssn3}`

    return null
}

function parseConsumer(responseLead: ResponseClientApplication) {
    return {
        firstName: responseLead.first_name,
        lastName: responseLead.last_name,
        middleName: responseLead.middle_name ?? null,
        county: responseLead.primary_county ?? null,
        city: responseLead.primary_city,
        state: responseLead.primary_state,
        zipCode: responseLead.primary_zip,
        emailAddress: responseLead.email_address,
        workPhone: responseLead.work_phone ?? null,
        mobilePhone: responseLead.mobile_phone,
        homePhone: responseLead.home_phone,
    }
}

async function getVehicleDataFromCollaterals(leadId: number) {
    const collateralVehicleData = await getCollateralsVehicle(leadId)

    if (collateralVehicleData)
        return parseCollateralVehicle(collateralVehicleData)

    return null
}

export default async function getLeadDetails(leadId: number) {
    const lead: ResponseClientApplication = await getClientApplication(leadId)
    let [vehicleData] = lead.vehicles ?? []

    // If the application/lead data from LOS doesn't have a vehicle
    // object, use the reference in the attribute vehicle to get one
    if (!vehicleData && lead.vehicle) {
        const vehicleURL = lead.vehicle

        const vehicleId = vehicleURL
            ?.split('/')
            .filter((s: string) => s)
            .pop()

        if (vehicleId) {
            vehicleData = await getVehicle(parseInt(vehicleId, 10))
        }
    }

    const parsedVehicle = vehicleData ? parseVehicle(vehicleData) : null

    const vehicle =
        parsedVehicle ??
        (await getVehicleDataFromCollaterals(leadId)) ??
        "Customer's preferred vehicle is unknown"

    const parsedConsumer: Consumer = {
        ssn: parseSSN(lead),
        ...parseConsumer(lead),
    }

    const parsedDecisionsBlocks: DecisionBlock[] = parseDecisionsBlocks(
        lead.decision_blocks,
    )

    const lastLeadActivty = lead.last_lead_activity
        ? LeadActivity.parseLeadActivityFromApiResponse(lead.last_lead_activity)
        : null

    return {
        id: lead.id,
        consumer: parsedConsumer,
        vehicle,
        decisionsBlocks: parsedDecisionsBlocks,
        uuid: lead.uuid,
        source: lead.source,
        sourceClient: lead.source_client,
        status: lead.status === 'Approved' ? 'Prequalified' : lead.status,
        progress: lead.progress,
        createdAt: lead.created,
        lastActivity: lastLeadActivty,
        ...reduceDecisionsBlocks(parsedDecisionsBlocks),
    } as LeadDetail
}
