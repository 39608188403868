import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../../integrations/datadog/OctaneLogger'

export default async function validateEmail(value: string) {
    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/utils/value-formats/email/validate/`,
            {
                value,
            },
            {
                withCredentials: true,
            },
        )

        return data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to validate email: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
