import { IntercomSettings } from './types'

declare global {
    interface Window {
        attachEvent(event: string, listener: EventListener): boolean
        intercomSettings: IntercomSettings
        Intercom: (command: string, ...args: unknown[]) => void
    }
}

export default class Intercom {
    queue: unknown[]

    appId: string

    constructor(appId: string) {
        this.queue = []
        this.appId = appId

        this.configure()
    }

    configure(settings?: IntercomSettings) {
        window.intercomSettings = {
            ...window.intercomSettings,
            ...settings,
            app_id: this.appId,
        }
    }

    static isInitialized() {
        return typeof window.Intercom === 'function'
    }

    initialize() {
        if (Intercom.isInitialized()) {
            this.sendCommand('reattach_activator')
            if (window.intercomSettings)
                this.sendCommand('update', window.intercomSettings)

            return
        }

        window.Intercom = Intercom.createWindowAdapter(this.queue)

        Intercom.loadWidget(this.appId)
    }

    sendCommand(command: string, ...args: unknown[]) {
        if (!this.isEnabled()) return

        if (!Intercom.isInitialized()) this.initialize()

        window.Intercom(command, ...args)
    }

    setUser(data: IntercomSettings) {
        this.configure(data)
        if (window.intercomSettings) this.boot(window.intercomSettings)
    }

    static loadWidget(appId: string) {
        const injectWidgetLoader = () => {
            const widgetScript = document.createElement('script')
            const firstScriptNode = document.getElementsByTagName('script')[0]

            widgetScript.async = true
            widgetScript.type = 'text/javascript'
            widgetScript.src = `https://widget.intercom.io/widget/${appId}`

            firstScriptNode?.parentNode?.insertBefore(
                widgetScript,
                firstScriptNode,
            )
        }

        if (document.readyState === 'complete') {
            injectWidgetLoader()
        } else if (window.attachEvent) {
            window.attachEvent('onload', injectWidgetLoader)
        } else {
            window.addEventListener('load', injectWidgetLoader, false)
        }
    }

    static createWindowAdapter(queue: unknown[], ...args: unknown[]) {
        const adapter = () => queue.push(args)

        adapter.q = queue

        return adapter
    }

    isEnabled = () => Boolean(this.appId)

    boot = (settings: IntercomSettings) => this.sendCommand('boot', settings)

    show = () => this.sendCommand('show')

    hide = () => this.sendCommand('hide')

    shutdown = () => this.sendCommand('shutdown')

    onHide = (callback: () => void) => this.sendCommand('onHide', callback)

    onShow = (callback: () => void) => this.sendCommand('onShow', callback)

    startTour = (tourId: string) => this.sendCommand('startTour', tourId)

    onUnreadCountChange = (callback: () => void) =>
        this.sendCommand('onUnreadCountChange', callback)

    trackEvent = (event: unknown, metadata: unknown) =>
        this.sendCommand('trackEvent', event, metadata)
}
