import styled, { css } from 'styled-components'

export const Container = styled.div`
    position: relative;
`

export const Legend = styled.legend<{ withIcon?: boolean }>`
    color: ${(props) => props.theme.colors.monochrome.asphalt};
    font-size: 0.7em;
    font-style: italic;
    float: right;
    transform: translateY(3px);
    svg {
        float: right;
        margin-left: 5px;
    }

    ${(props) =>
        props.withIcon &&
        css`
            position: absolute;
            right: 15px;
            top: -2px;
        `}
`
export const ErrorMessage = styled.span`
    ${(props) => props.theme.fontFamily.Karla};
    color: ${(props) => props.theme.colors.alert.error};
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin-top: 2px;
    margin-bottom: 5px;
    text-align: left;
`
