import { theme as BaseTheme } from '@octane/spark'
import { DefaultTheme } from 'styled-components'

const octaneTheme: DefaultTheme = {
    ...BaseTheme,
    colors: {
        ...BaseTheme.colors,
    },
    navbar: {
        color: BaseTheme.colors.brand.rideOctaneBlue,
        hoverColor: BaseTheme.colors.monochrome.jetlineBlack,
        loggedInNameColor: BaseTheme.colors.monochrome.jetlineBlack,
        loggedInHoverBackground: BaseTheme.colors.monochrome.smoke,
    },
}

export default octaneTheme
