import { createAsyncThunk } from '@reduxjs/toolkit'
import { getDashboard } from 'api/dashboard'

import { UseUserInterface } from '../../../hooks/types/useUser.types'

const loadStats = createAsyncThunk(
    'dashboard/loadStats',
    async (user: UseUserInterface) => {
        return getDashboard(user)
    },
)

export default loadStats
