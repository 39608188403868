import React from 'react'

import { FormikMaskedInput } from 'components/FormikFields/FormikMaskedTextInput/FormikMaskedTextInput.styled'

import {
    FieldConfigBase,
    FieldKit,
    FieldProps,
    FormValues,
} from '../../containers/FormBuilder'

interface InputFieldMaskedConfig extends FieldConfigBase {
    label: string
    mask: string
    info?: JSX.Element
    helpText?: string
    disabled?: boolean
    placeholder?: string
    prefilled?: boolean
    onChange?: (
        value: string,
        fieldName: string,
        formValues: FormValues,
        fieldKit: FieldKit,
    ) => void
}

export default function InputFieldMasked(
    props: FieldProps<InputFieldMaskedConfig>,
) {
    const { fieldName, fieldKit, fieldConfig } = props
    const touched = fieldKit.touched[fieldName]
    const error = fieldKit.errors[fieldName]
    const value = fieldKit.values[fieldName]
    let extraProps = {}

    if (fieldConfig.info?.props.children !== ' ') {
        extraProps = {
            info: fieldConfig.info,
        }
    }

    return (
        <FormikMaskedInput
            info={fieldConfig.info}
            disabled={fieldConfig.disabled}
            prefilled={fieldConfig.prefilled}
            error={!!(touched && error)}
            errorText={error}
            id={fieldName}
            name={fieldName}
            label={fieldConfig.label}
            value={value}
            helpText={fieldConfig.helpText}
            onChange={(val) => {
                if (fieldConfig.onChange) {
                    fieldConfig.onChange(
                        val,
                        fieldName,
                        fieldKit.values,
                        fieldKit,
                    )
                } else {
                    const event = {
                        target: {
                            name: fieldName,
                            value: val,
                        },
                    }

                    fieldKit.handleChange(event)
                }
            }}
            onBlur={fieldKit.handleBlur}
            placeholder={fieldConfig.placeholder}
            mask={fieldConfig.mask}
            data-testid={fieldName}
            {...extraProps}
        />
    )
}

InputFieldMasked.defaultProps = {
    disabled: false,
    placeholder: '',
    helpText: '',
    info: <> </>,
}
