import React from 'react'

import { Button, LayoutBlock, CepPasswordLockIcon } from '@octane/spark'

import FormContent from '../../components/FormContent'
import FormGridPlacement from '../../components/FormGridPlacement'
import FormStatusIndicator from '../../components/FormStatusIndicator/FormStatusIndicator'
import {
    FieldGroup,
    FieldInstructionBundle,
    FieldKit,
    Form,
    FormBuilder,
    FormBuilderProps,
    FormKit,
    FormValues,
} from '../FormBuilder'
import {
    FieldStyle,
    FormContainer,
    HeaderSubtitle,
    HeaderTitle,
    StatusIndicatorStyle,
} from './AuthenticationFormBuilder.styled'

export type AuthenticationFormBuilderProps = {
    instructions: FieldInstructionBundle
    isModal?: boolean
    icon?: React.ReactNode
    headerTitle?: string
    headerSubtitle?: string
    submitButtonText?: string
    textLink?: React.ReactNode
    finePrint?: React.ReactNode
    onSubmitFn: FormBuilderProps['onSubmit']
    statusMessage?: string
    shouldShowStatusMessage?: boolean
}

const header = (title = '', subtitle = '') => (
    <>
        <div>
            <HeaderTitle>{title}</HeaderTitle>
        </div>
        <div>
            <HeaderSubtitle>{subtitle}</HeaderSubtitle>
        </div>
    </>
)

const defaultProps = {
    icon: <CepPasswordLockIcon color="#2366b1" height={100} width={100} />,
    headerTitle: '',
    headerSubtitle: '',
    submitButtonText: '',
    textLink: null,
    finePrint: null,
    isModal: false,
    statusMessage: '',
    shouldShowStatusMessage: false,
}

export default function AuthenticationFormBuilder(
    props: AuthenticationFormBuilderProps,
) {
    const {
        instructions,
        submitButtonText,
        onSubmitFn,
        icon,
        isModal,
        headerTitle,
        headerSubtitle,
        textLink,
        finePrint,
        statusMessage,
        shouldShowStatusMessage,
    } = props

    const submitButton = (
        <Button
            id="formikSubmitButton"
            type="submit"
            variant="primary"
            fullWidth
        >
            {submitButtonText}
        </Button>
    )

    const form = (
        fieldKit: FieldKit<FormValues>,
        formKit: FormKit<FormValues>,
    ) => (
        <>
            <FieldStyle>
                <FieldGroup
                    fieldKit={fieldKit}
                    fieldInstructionBundle={instructions}
                />
            </FieldStyle>
            <StatusIndicatorStyle>
                <FormStatusIndicator
                    id="statusIndicator"
                    message={statusMessage || ''}
                    formIsValid={false}
                    submitAttempted={
                        !!(shouldShowStatusMessage && formKit.submitCount > 0)
                    }
                    variant="error"
                />
            </StatusIndicatorStyle>
        </>
    )

    return (
        <FormBuilder
            onSubmit={onSubmitFn}
            fieldInstructionBundle={instructions}
        >
            {({ fieldKit, formKit }) => {
                return (
                    <FormContainer>
                        <Form>
                            {/* eslint-disable-next-line max-len */}
                            <FormContent gridTemplate="auto minmax(auto, 600px) auto">
                                <FormGridPlacement gridArea="1 / 2 / 1 / 2">
                                    <LayoutBlock
                                        isModal={isModal}
                                        icon={icon}
                                        finePrint={finePrint || null}
                                        form={form(fieldKit, formKit)}
                                        cta={submitButton}
                                        header={header(
                                            headerTitle,
                                            headerSubtitle,
                                        )}
                                        textLink={textLink || null}
                                    />
                                </FormGridPlacement>
                            </FormContent>
                        </Form>
                    </FormContainer>
                )
            }}
        </FormBuilder>
    )
}

AuthenticationFormBuilder.defaultProps = defaultProps
