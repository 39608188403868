import relativeDays from 'utils/relativeDays'
import titleCase from 'utils/titleCase'

import { OctaneLogger } from '../../../integrations/datadog/OctaneLogger'
import LeadActivity from '../models/lead_activity'
import { LeadResult } from '../types'
import getLeadSourceClient from './getLeadSourceClient'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getDecisionValue(lead: any) {
    if (lead.is_oem_child) return 'Converted'

    if (lead.status_text === 'Pre-Approved') return 'Prequalified'

    return lead.status_text
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function parseLead(payload: any): LeadResult | null {
    const today = new Date()

    try {
        return {
            id: payload.id,
            lastActivity: payload.last_lead_activity
                ? LeadActivity.parseLeadActivityFromApiResponse(
                      payload.last_lead_activity,
                  )
                : null,
            received: relativeDays(new Date(payload.created_date), today),
            decision: getDecisionValue(payload),
            source: getLeadSourceClient(
                payload.parent_source_client ?? payload.source_client,
            ),
            name: `${titleCase(payload.first_name)} ${titleCase(
                payload.last_name,
            )}`,
            assigned: payload.dealer_assigned_application ?? payload.id,
            condition: payload.vehicle_condition === 'N' ? 'New' : 'Used',
            vehicleRequested:
                payload.collaterals.length > 0 &&
                payload.collaterals[0].asset_name.length > 0
                    ? payload.collaterals[0].asset_name
                    : "Customer's preferred vehicle is unknown",
            is_oem_child: payload.is_oem_child,
        }
    } catch (error) {
        OctaneLogger.error(
            `Failed to parse lead with ID ${payload.id}: ${error}`,
            undefined,
            error as Error,
        )

        return null
    }
}
