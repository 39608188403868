import React from 'react'
import { useParams } from 'react-router-dom'

import {
    Spinner,
    NoticeBox,
    NoticeBoxColors,
    NoticeBoxVariants,
    VsExclamationIcon,
} from '@octane/spark'
import Card from 'components/Card'
import useClientApplication from 'hooks/useClientApplication'
import ApplicationStatus from 'state/enums/ApplicationStatus'

import { MainContainer, LoaderWrapper } from './DecisionResult.styled'
import DecisionResultApproved from './DecisionResultApproved'
import DecisionResultDeclined from './DecisionResultDeclined'
import DecisionResultSubmitted from './DecisionResultSubmitted'

export default function DecisionResult(): JSX.Element {
    const { applicationId } = useParams()

    const { clientApplication, loading, error } =
        useClientApplication(applicationId)

    if (error) {
        return (
            <MainContainer>
                <NoticeBox
                    // eslint-disable-next-line max-len
                    content="An error occurred when loading the decision. Refresh the page to try again or contact support."
                    heading=""
                    icon={VsExclamationIcon}
                    color={NoticeBoxColors.ERROR}
                    variant={NoticeBoxVariants.BOX_HIGHLIGHTED}
                />
            </MainContainer>
        )
    }

    if (loading || !clientApplication) {
        return (
            <LoaderWrapper data-testid="DecisionResult-spinner">
                <Spinner />
            </LoaderWrapper>
        )
    }

    const renderContent = () => {
        switch (clientApplication?.status) {
            case ApplicationStatus.APPROVED:
                return (
                    <DecisionResultApproved
                        clientApplication={clientApplication}
                    />
                )

            case ApplicationStatus.DECLINE:
                return (
                    <DecisionResultDeclined
                        clientApplication={clientApplication}
                    />
                )

            default:
                return (
                    <DecisionResultSubmitted
                        clientApplication={clientApplication}
                    />
                )
        }
    }

    return (
        <MainContainer>
            <Card>{renderContent()}</Card>
        </MainContainer>
    )
}
