import { useState, useEffect } from 'react'

import { getLenderDisclosures } from 'api/lender'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'
import DisclosureType from 'state/enums/DisclosureType'

export interface UseLenderDisclosureInterface {
    disclosureText: string | undefined
    loading: boolean
    error: Error | null
}

const useLenderDisclosure = (
    disclosureName: DisclosureType,
    lenderId: number,
    applicationId?: number,
): UseLenderDisclosureInterface => {
    const [disclosureText, setDisclosureText] = useState<string>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const loadLenderDisclosures = async () => {
            const result = await getLenderDisclosures(lenderId, applicationId)

            const disclosure = result.data.find(
                (item) => item.name === disclosureName,
            )

            if (!disclosure) {
                throw new Error(
                    `Disclosure with name "${disclosureName}" not found.`,
                )
            }

            return disclosure.text
        }

        if (lenderId) {
            loadLenderDisclosures()
                .then((disclosure: string) => {
                    setError(null)
                    setDisclosureText(disclosure)
                })
                .catch((disclosureError) => {
                    OctaneLogger.error(
                        'Error when getting disclosure text.',
                        { applicationId, lenderId, disclosureName },
                        disclosureError as Error,
                    )

                    setError(disclosureError)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [applicationId, lenderId, disclosureName])

    return {
        disclosureText,
        loading,
        error,
    }
}

export default useLenderDisclosure
