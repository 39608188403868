import React from 'react'

import { LayoutBlock } from '@octane/spark'
import ErrorFooterProps from 'components/ErrorLayout/ErrorFooter/ErrorFooterProps'

import ErrorFooter from './ErrorFooter'
import ErrorHeader from './ErrorHeader'
import ErrorHeaderProps from './ErrorHeader/ErrorHeaderProps'
import ErrorTryAgain from './ErrorTryAgain'

type ErrorLayoutProps = {
    header: ErrorHeaderProps
    footer: ErrorFooterProps
    navigateHistorySteps?: number
}

const defaultProps = {
    navigateHistorySteps: 0,
}

function ErrorLayout({
    header,
    footer,
    navigateHistorySteps,
}: ErrorLayoutProps) {
    return (
        <LayoutBlock
            icon={header?.icon && <header.icon height={100} width={100} />}
            header={
                <>
                    <ErrorHeader title={header?.title} body={header?.body} />
                    <ErrorTryAgain
                        navigateHistorySteps={navigateHistorySteps}
                    />
                </>
            }
            finePrint={
                <ErrorFooter
                    supportPhoneNumber={footer?.supportPhoneNumber}
                    supportEmail={footer?.supportEmail}
                />
            }
        />
    )
}

ErrorLayout.defaultProps = defaultProps

export default ErrorLayout
