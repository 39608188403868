import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface ResponseCollateralVehicle {
    condition: string
    year: number
    make: string
    model: string
    msrp: number
}

export default async function getCollateralsVehicle(leadId: number) {
    try {
        if (!leadId) {
            throw new Error('Invalid leadId used with getCollateralsVehicle')
        }

        const response = await axios.get(
            `${config.API_BASE_URL}/api/v3/applications/${leadId}/collaterals/`,
            { withCredentials: true },
        )

        if (response.status !== 200) {
            throw new Error(
                `Unexpected response status of ${response.status} while fetching lead collaterals`,
            )
        }

        return {
            ...response.data[0]?.asset,
        } as ResponseCollateralVehicle
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get collaterals vehicle: ${axiosError.message}`,
            axiosError,
            { leadId },
        )

        throw new Error(axiosError.message)
    }
}
