import React, { useState } from 'react'

import {
    Button,
    Checkbox,
    Modal,
    TextLink,
    UiCheckmarkIcon,
} from '@octane/spark'
import { getMaterialKit } from 'api/dealership'
import { AssignToPerson } from 'api/flexLinks/getAssignToPersons'
import updateLink from 'api/flexLinks/updateLink'
import {
    FormikDropdown,
    FormikMaskedTextInput,
    FormikTextInput,
} from 'components/FormikFields'
import { Form, Formik, FormikHelpers } from 'formik'
import useUser from 'hooks/useUser'
import { FlexLink } from 'state/dealership/DealershipState'
import * as toast from 'utils/toast'

import { buildAssignedToId, findPerson } from '../utils'
import { DownloadIcon } from './Icons'
import { UpdateLinkSchema } from './schema.yup'
import {
    Title,
    Row,
    Divisor,
    Container,
    Actions,
    PopupInfo,
    QrCode,
    QrCodeContainer,
    ContainerAside,
    UrlActions,
} from './UpdateLink.styled'

type UpdateLinkProps = {
    open: boolean
    data: FlexLink | null
    persons: AssignToPerson[]
    onClose: (evt: unknown, refresh?: boolean) => void
}

type FormProps = {
    label: string
    isPrimary: boolean
    assignedTo: string
    email: string
    phoneNumber: string
    flexUrl: string
}

function UpdateLink({
    open,
    persons,
    data,
    onClose,
}: Readonly<UpdateLinkProps>) {
    const [copied, setCopied] = useState(false)
    const { user } = useUser()

    const listPersons = persons.map((person) => {
        const assignedToId = buildAssignedToId(
            person.dealerUserId,
            person.salesPersonId,
        )

        return {
            name: person.name,
            value: assignedToId,
        }
    })

    const personAssigned = findPerson(
        persons,
        buildAssignedToId(data?.dealerUserId, data?.salesPersonId),
    )

    const initialValuesForm = {
        label: data?.label as string,
        isPrimary: data?.isPrimary as boolean,
        assignedTo: personAssigned.salesPersonId
            ? `slp#${personAssigned.salesPersonId}`
            : `dup#${personAssigned.dealerUserId}`,
        email: personAssigned.email,
        phoneNumber: personAssigned.phoneNumber as string,
        flexUrl: data?.link as string,
    }

    const onCopy = () => {
        if (copied) return

        navigator.clipboard.writeText(data?.link as string)
        setCopied(true)

        setTimeout(() => {
            setCopied(false)
        }, 3000)
    }

    const onDownload = async () => {
        const blobData = await getMaterialKit(
            data?.dealershipId as number,
            data?.id,
        )

        const url = window.URL.createObjectURL(new Blob([blobData]))
        const link = document.createElement('a')
        const pdfName = `Octane Prequal Flex Kit - [${user?.dealership?.name}]:[${data?.label}].pdf`

        link.href = url
        link.setAttribute('download', pdfName)
        document.body.appendChild(link)
        link.click()
    }

    const handleUpdateLink = async (
        values: FormProps,
        { resetForm }: FormikHelpers<FormProps>,
    ) => {
        try {
            const person = findPerson(persons, values.assignedTo)

            await updateLink({
                id: data?.id as number,
                dealershipId: data?.dealershipId as number,
                label: values.label,
                isPrimary: values.isPrimary,
                // one of these will be empty
                dealerUserId: person.dealerUserId,
                salesPersonId: person.salesPersonId,
            })

            toast.success({
                title: 'Success',
                message: 'Link updated successfully!',
            })

            // close the modal and trigger refresh list
            onClose(null, true)
            resetForm()
        } catch (error) {
            toast.error({
                message: 'An error occurred while updating the link.',
            })
        }
    }

    return (
        <Modal
            size="large"
            // Force modal to close only changing state
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClose={() => {}}
            withCloseIcon={false}
            title=""
            isOpen={open}
        >
            <Modal.Body>
                <Formik
                    initialValues={initialValuesForm}
                    onSubmit={handleUpdateLink}
                    validationSchema={UpdateLinkSchema}
                    validateOnBlur
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        dirty,
                        isValid,
                    }) => (
                        <Container>
                            <Title>Edit Link</Title>
                            <Form>
                                <QrCodeContainer>
                                    <QrCode src={data?.qrCodeUrl} />

                                    <ContainerAside>
                                        <Row columns={1}>
                                            <FormikTextInput
                                                name="label"
                                                label="Link Label"
                                            />
                                        </Row>
                                        <Row
                                            columns={1}
                                            style={{ marginBottom: '10px' }}
                                        >
                                            <Checkbox
                                                id="newLinkPrimary"
                                                label="This link is primarily used in-store"
                                                value="isPrimary"
                                                checked={values.isPrimary}
                                                onChange={(val) => {
                                                    const event = {
                                                        target: {
                                                            name: 'isPrimary',
                                                            value: val,
                                                        },
                                                    }

                                                    handleChange(event)
                                                }}
                                            />
                                        </Row>
                                        <Row columns={1}>
                                            <FormikDropdown
                                                name="assignedTo"
                                                info="Used to attribute the source of the app"
                                                label="Assigned to"
                                                placeholder="Select..."
                                                infoText={
                                                    <PopupInfo>
                                                        Link attribution will be
                                                        visible on the Leads
                                                        Page and in the Lead
                                                        email. The assigned
                                                        owner in this dropdown
                                                        will receive the Lead
                                                        emails this link
                                                        generates.
                                                    </PopupInfo>
                                                }
                                            >
                                                {listPersons}
                                            </FormikDropdown>
                                        </Row>
                                    </ContainerAside>
                                </QrCodeContainer>

                                <Row columns={2}>
                                    <FormikTextInput
                                        name="flexUrl"
                                        label="URL"
                                        disabled
                                    />

                                    <UrlActions>
                                        <Button
                                            style={{ flex: 1 }}
                                            onClick={onCopy}
                                            variant={
                                                copied ? 'tertiary' : 'primary'
                                            }
                                            startIcon={
                                                copied
                                                    ? UiCheckmarkIcon
                                                    : undefined
                                            }
                                        >
                                            {copied ? 'Copied' : 'Copy link'}
                                        </Button>

                                        <Button
                                            onClick={onDownload}
                                            startIcon={DownloadIcon}
                                            variant="primaryOutlined"
                                        >
                                            Download link
                                        </Button>
                                    </UrlActions>
                                </Row>

                                <Row columns={2}>
                                    <FormikTextInput
                                        name="email"
                                        label="Email Address"
                                        disabled
                                    />
                                    <FormikMaskedTextInput
                                        name="phoneNumber"
                                        label="Phone Number"
                                        mask="(000)-000-0000"
                                        placeholder=""
                                        infoText="Optional"
                                        disabled
                                    />
                                </Row>
                            </Form>

                            <Divisor />

                            <Actions>
                                <Button
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                    disabled={
                                        !dirty || !isValid || isSubmitting
                                    }
                                >
                                    Update Link
                                </Button>
                                <TextLink as="button" onClick={onClose}>
                                    Cancel
                                </TextLink>
                            </Actions>
                        </Container>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateLink
