import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

interface CreditProfileResponse {
    id: number
    pull_type: string
    applicant_type: string
    credit_access_id: string
    is_expired: boolean
}

export interface CreditProfile {
    id: number
    pullType: string
    applicantType: string
    creditAccessId: string
    isExpired: boolean
}

export default async function getCreditProfiles(uuid: string) {
    try {
        const { data } = await axios.get(
            // eslint-disable-next-line max-len
            `${config.API_BASE_URL}/api/v3/applications/${uuid}/credit-profiles/`,
            {
                withCredentials: true,
            },
        )

        if (!Array.isArray(data)) {
            return [] as CreditProfile[]
        }

        return data.map((creditProfileResponse: CreditProfileResponse) => ({
            id: creditProfileResponse.id,
            pullType: creditProfileResponse.pull_type,
            applicantType: creditProfileResponse.applicant_type,
            creditAccessId: creditProfileResponse.credit_access_id,
            isExpired: creditProfileResponse.is_expired,
        })) as CreditProfile[]
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get credit profiles: ${axiosError.message}`,
            axiosError,
            { applicationUuid: uuid },
        )

        throw new Error(axiosError.message)
    }
}
