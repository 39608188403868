import React from 'react'

import { IconComponent } from '@octane/spark'
import { useTheme } from 'styled-components'

import { ActionIconContainer } from './Table.styled'

type RowActionButtonProps = {
    icon: IconComponent
    title: string
    onClick?: () => void
}

function RowActionButton({
    icon: ActionIcon,
    title,
    onClick,
}: RowActionButtonProps) {
    const theme = useTheme()

    const iconProps = {
        width: 35,
        height: 45,
        color: theme.colors.monochrome.steel,
    }

    return (
        <button type="button" title={title} onClick={onClick}>
            <ActionIconContainer>
                <ActionIcon {...iconProps} />
            </ActionIconContainer>
        </button>
    )
}

RowActionButton.defaultProps = {
    onClick: null,
}

export default RowActionButton
