import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { GlobalStyles, Toaster } from '@octane/spark'
import brandConfig from 'branding'
import DocumentViewerManager from 'containers/DocumentViewerManager'
import FeatureFlagContextProvider from 'contexts/FeatureFlagContextProvider'
import useDataDogRUM from 'hooks/useDataDogRUM'
import useGoogleTagManager from 'hooks/useGoogleTagManager'
import useKeepSessionAlive from 'hooks/useKeepSessionAlive'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

import AppRoutes from './AppRoutes'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import ApplicationRedirector from './containers/ApplicationRedirector'
import DashboardManager from './containers/DashboardManager'
import DealerRedirector from './containers/DealerRedirector'
import IntercomManager from './containers/IntercomManager'
import LoginManager from './containers/LoginManager/LoginManager'
import NavbarContainer from './containers/Navbar'
import useUser from './hooks/useUser'
import './App.css'
import { OctaneLogger } from './integrations/datadog/OctaneLogger'
import isLightspeed from './utils/lightspeed'
import { isRouteOneSSO } from './utils/routeone'

const AppBodyBackgroundGlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colors.monochrome.moonGrey};
  }
`

function App(): JSX.Element {
    const { pathname } = useLocation()
    const { isLoggedIn, user } = useUser()
    const googleTagManager = useGoogleTagManager()
    const { ddRUM } = useDataDogRUM()

    useKeepSessionAlive()

    useEffect(() => {
        if (user) {
            ddRUM?.setUser(user)
            OctaneLogger.setUser(user)
        } else {
            ddRUM?.clearUser()
            OctaneLogger.clearUser()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id])

    useEffect(() => {
        ddRUM?.startView(pathname.replace('!', ''))
    }, [pathname, ddRUM])

    useEffect(() => {
        googleTagManager.trackPageview(user?.id)
    }, [pathname, googleTagManager, user?.id])

    return (
        <>
            <GlobalStyles />
            <ScrollToTop />
            <ThemeProvider theme={brandConfig.theme}>
                <FeatureFlagContextProvider>
                    <Toaster />
                    <AppBodyBackgroundGlobalStyle />
                    {!(isLightspeed() || isRouteOneSSO()) && (
                        <NavbarContainer />
                    )}
                    <LoginManager />
                    {isLoggedIn && (
                        <>
                            <DocumentViewerManager />
                            <IntercomManager />
                            <DashboardManager />
                        </>
                    )}
                    <DealerRedirector />
                    <ApplicationRedirector />

                    <main>
                        <AppRoutes />
                    </main>
                    <Footer />
                </FeatureFlagContextProvider>
            </ThemeProvider>
        </>
    )
}

export default App
