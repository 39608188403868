import { useEffect, useState } from 'react'

import { getCreditProfiles } from 'api/application'

import useUser from './useUser'

const useSafeCheck = (applicationUUID: string) => {
    const { user } = useUser()
    const [canUseSafeCheck, setCanUseSafeCheck] = useState<boolean>(false)
    const [canEnroll, setCanEnroll] = useState<boolean>(false)
    const [hasSafeCheckReport, setHasSafeCheckReport] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const hasSafeCheckEnabled = user?.dealership?.isSafeCheckEnabled
        const hasPrequalEnabled = user?.dealership?.isPrequalEnabled
        const isIndividualUser = user?.actualNumberOfEndUsers === 1

        setCanUseSafeCheck(
            Boolean(
                isIndividualUser && hasPrequalEnabled && hasSafeCheckEnabled,
            ),
        )

        setCanEnroll(
            Boolean(
                isIndividualUser && hasPrequalEnabled && !hasSafeCheckEnabled,
            ),
        )
    }, [user])

    useEffect(() => {
        if (!canUseSafeCheck || !applicationUUID) {
            setIsLoading(false)

            return
        }

        setIsLoading(true)

        getCreditProfiles(applicationUUID)
            .then((creditProfiles) => {
                const hasSafeCheckSoftPull = Boolean(
                    creditProfiles.find(
                        (creditProfile) =>
                            creditProfile.creditAccessId &&
                            creditProfile.pullType === 'safecheck_soft_pull',
                    ),
                )

                setHasSafeCheckReport(hasSafeCheckSoftPull)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [canUseSafeCheck, applicationUUID])

    return { canUseSafeCheck, canEnroll, hasSafeCheckReport, isLoading }
}

export default useSafeCheck
