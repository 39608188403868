import React, { useEffect, useState } from 'react'

import getMakes, { GetMakesOptions, Make } from 'api/vehicles/getMakes'
import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    disable?: boolean
    dataSource?: string[]
    assetType?: unknown
    primaryVehicleId?: number
}

const defaultProps = {
    disable: false,
    dataSource: null,
    assetType: null,
    primaryVehicleId: null,
}

export default function MakeDropdown({
    formik,
    disable,
    dataSource,
    assetType,
    primaryVehicleId,
}: QueryProps) {
    const [response, updateResponse] = useState([] as Make[])
    const { vehicle } = formik.values
    const { condition } = vehicle
    const { year, make } = vehicle

    useEffect(() => {
        const abortController = new AbortController()

        if (condition && year) {
            const getMakesOptions = {
                condition,
                year,
                disable,
                dataSource,
                assetType,
                primaryVehicleId,
            } as GetMakesOptions

            getMakes(getMakesOptions, abortController)
                .then((makes: Make[]) => {
                    updateResponse(makes)
                    if (
                        makes.filter((e) => `${e.name}` === make).length === 0
                    ) {
                        formik.setFieldValue('vehicle.make', '')
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                .catch(() => {})
        }

        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition, year])

    if (!year || year === '0') {
        return (
            <FormikDropdown
                name="vehicle.make"
                label="Make"
                placeholder="Select One"
                disabled
            >
                {{ name: '', value: '' }}
                {{ name: '', value: '' }}
            </FormikDropdown>
        )
    }

    return (
        <FormikDropdown
            name="vehicle.make"
            label="Make"
            placeholder="Select One"
            disabled={response.length === 0}
            error={make === null || make === ''}
        >
            {response.map((child: Make) => {
                const newBorn: FormikDropdownChild = {
                    name: child.name,
                    value: child.name,
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}

MakeDropdown.defaultProps = defaultProps
