import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useUser from 'hooks/useUser'

import useDashboard from '../../hooks/useDashboard'

export default function DashboardManager() {
    const { isLoggedIn, isAutoDealership, user } = useUser()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const featureFlags = useFeatureFlagContext()
    const { hasDashboard, status } = useDashboard()

    const isHomePage =
        window.location.hash === '#!/' ||
        window.location.hash === '#/' ||
        // root path
        (window.location.hash === '' && window.location.pathname === '/')

    // Pathname is not included in the dependency list to avoid redirecting
    // a non-auto user if they intend to go to the homepage
    useEffect(() => {
        if (isHomePage && isLoggedIn && pathname.indexOf('invitation') === -1) {
            if (isAutoDealership) {
                navigate('dashboard')
            } else if (hasDashboard) {
                if (featureFlags?.webAppLoyaltyDashboard) {
                    navigate('dashboard')
                } else window.location.hash = '#!/loyalty-dashboard'
            }
        }
    }, [isAutoDealership, isLoggedIn, status, hasDashboard, user]) // eslint-disable-line

    return null
}
