import {
    FIELD_TOO_LONG,
    FIELD_REQUIRED,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

const ReauthenticateModalSchema = Yup.object().shape({
    password: Yup.string().max(40, FIELD_TOO_LONG(40)).required(FIELD_REQUIRED),
})

export default ReauthenticateModalSchema
