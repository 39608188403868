import { FieldInstruction } from 'containers/FormBuilder'
import TabSelectField from 'form/fieldComponents/TabSelectField'

const applicationType: FieldInstruction<typeof TabSelectField> = {
    Component: TabSelectField,
    config: {
        label: 'Application Type',
        initialValue: 'individual',
        items: [
            { value: 'individual', label: 'Individual' },
            { value: 'joint', label: 'Joint' },
        ],
    },
}

export default applicationType
