import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface DecisionType {
    status: string
}

export default async function getSplitDecision(uuid: string) {
    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/client-application/${uuid}/decision/`,
            null,
            {
                withCredentials: true,
            },
        )

        return data as DecisionType
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get split decision: ${axiosError.message}`,
            axiosError,
            { applicationUuid: uuid },
        )

        throw new Error(axiosError.message)
    }
}
