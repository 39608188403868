import React from 'react'

import { Select as SelectSpark } from '@octane/spark'

import { Props } from './types'

export default function Select({
    label,
    placeholder,
    value,
    options,
    id,
    ...rest
}: Props) {
    return (
        <SelectSpark
            {...rest}
            placeholder={placeholder}
            value={value}
            label={label}
            id={id}
        >
            {options.map((option) => (
                <SelectSpark.Option key={option.label} value={option.value}>
                    {option.label}
                </SelectSpark.Option>
            ))}
        </SelectSpark>
    )
}
