import { FieldInstruction } from 'containers/FormBuilder'
import SelectField from 'form/fieldComponents/SelectField'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import * as Yup from 'yup'

const citizenshipStatus: FieldInstruction<typeof SelectField> = {
    Component: SelectField,
    config: {
        label: 'Citizenship Status',
        options: [
            { name: 'US Citizen', value: 'us_citizen' },
            { name: 'Permanent Resident', value: 'resident_alien' },
            { name: 'Other', value: 'other' },
        ],
        validator: Yup.string()
            .test(
                'citizenship-validator',
                '', // don't display any validation message
                (value) => value !== 'other',
            )
            .required(FIELD_REQUIRED),
    },
}

export default citizenshipStatus
