import store from 'state/store'
import { loginUser } from 'state/user'
import { logoutUser } from 'state/user/actions'

import IFrameServer from './IFrameServer'

export default class AuthenticatorIFrameServer extends IFrameServer {
    constructor(target: Window, targetOrigin: string) {
        super('authenticator', target, targetOrigin)
    }

    // eslint-disable-next-line class-methods-use-this
    login() {
        store.dispatch(loginUser())
    }

    // eslint-disable-next-line class-methods-use-this
    logout() {
        const skipLogoutLegacyApp = true

        store.dispatch(logoutUser({ skipLogoutLegacyApp, skipRedirect: true }))
    }
}
