import React, { useState } from 'react'

import { Button, Modal } from '@octane/spark'
import { inviteNewUser, saveNewSalesperson } from 'api/dealership'
import UserBasicInfoForm from 'components/UserForm/UserBasicInfoForm'
import { Form, Formik, FormikHelpers } from 'formik'
import useAppDispatch from 'hooks/useAppDispatch'
import { loadUsersAndSalespersons } from 'state/dealership/actions'
import LoadingStatus from 'state/enums/LoadingStatus'
import { loadInvitations } from 'state/invitation/actions'
import { loadUser } from 'state/user/actions'
import * as toast from 'utils/toast'

import { FormTitle, Divisor, LinkButton } from '../UserForm/UserForm.styled'
import { UserInvitationSchema } from './schema.yup'
import { UserInvitation, UserInvitationModalProps } from './types'

const INITIAL_VALUES: UserInvitation = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    levelOfAccess: 'Teammate',
    applicationAccess: 'Default',
    phoneNumber: '',
}

export default function UserInvitationModal({
    onClose,
}: UserInvitationModalProps) {
    const dispatch = useAppDispatch()
    const [levelOfAccess, setLevelOfAccess] = useState<string | null>(null)

    function handleInvitationConfirm(
        invitationValues: UserInvitation,
        { setErrors, setStatus }: FormikHelpers<UserInvitation>,
    ) {
        setStatus(LoadingStatus.LOADING)

        inviteNewUser(invitationValues)
            .then((response) => {
                toast.success({
                    title: 'Invitation Sent',
                    message: response.message || '',
                })

                dispatch(loadInvitations())
                onClose()
            })
            .catch((error) => {
                if (error.response?.status === 412) {
                    toast.error({
                        message: error.response?.data?.message || '',
                    })

                    dispatch(loadUser())
                    dispatch(loadInvitations())
                    onClose()
                } else if (error.response?.data?.message?.email) {
                    setErrors({
                        emailAddress: error.response.data.message.email,
                    })
                } else if (error.response?.data?.message) {
                    setErrors({
                        emailAddress: error.response.data.message,
                    })
                } else {
                    toast.error({
                        message: 'Error during sending invitation',
                    })
                }
            })
            .finally(() => {
                setStatus(LoadingStatus.IDLE)
            })
    }

    function handleSaveSalesperson(
        salespersonValues: UserInvitation,
        { setStatus }: FormikHelpers<UserInvitation>,
    ) {
        setStatus(LoadingStatus.LOADING)
        /* eslint-disable no-param-reassign */
        salespersonValues.applicationAccess = 'Sales'

        saveNewSalesperson(salespersonValues)
            .then((response) => {
                toast.success({
                    title: 'Salesperson Saved',
                    message: response.message || '',
                })

                dispatch(loadUser())
                dispatch(loadUsersAndSalespersons())
                onClose()
            })
            .catch(() => {
                toast.error({
                    message: 'Error when saving salesperson',
                })
            })
            .finally(() => {
                setStatus(LoadingStatus.IDLE)
            })
    }

    return (
        <Modal isOpen onClose={onClose} size="large">
            <Modal.Body>
                <FormTitle>Invite a New User</FormTitle>
                <Formik
                    initialValues={INITIAL_VALUES}
                    validationSchema={UserInvitationSchema}
                    validateOnBlur
                    onSubmit={(values, helpers) =>
                        values.levelOfAccess === 'Sales'
                            ? handleSaveSalesperson(values, helpers)
                            : handleInvitationConfirm(values, helpers)
                    }
                >
                    {({ status }) => (
                        <Form>
                            <UserBasicInfoForm
                                setLevelOfAccess={setLevelOfAccess}
                            />
                            <Divisor />
                            <Button
                                fullWidth
                                loading={status === LoadingStatus.LOADING}
                                type="submit"
                            >
                                {levelOfAccess === 'Sales'
                                    ? 'Save Salesperson'
                                    : 'Send Invite'}
                            </Button>
                            <LinkButton
                                fullWidth
                                variant="white"
                                onClick={onClose}
                            >
                                Cancel
                            </LinkButton>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}
