import { FileRequest } from 'components/DocumentViewerModal/DocumentViewerModal'

import IFrameServer from './IFrameServer'

export default class DocumentViewerServer extends IFrameServer {
    constructor(target: Window, targetOrigin: string) {
        super('documentViewer', target, targetOrigin)
    }

    // eslint-disable-next-line class-methods-use-this
    view(file: FileRequest) {
        window.documentViewer.view(file)
    }

    // eslint-disable-next-line class-methods-use-this
    close() {
        window.documentViewer.close()
    }
}
