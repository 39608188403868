import React from 'react'

import { NoticeBox, UiCloseSmallIcon, VsWrenchIcon, theme } from '@octane/spark'
import styled from 'styled-components'

const StickyDiv = styled.div`
    position: fixed;
    left: 0;
    top: 60px;
    border: 1px solid #d5d9e0;
    border-radius: 5px;
    z-index: 1;

    #refreshButton {
        margin-left: 5px;
        margin-right: 140px;
        color: #2366b1;
        text-decoration: underline;
        cursor: pointer;
    }

    #closeButton {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }

    *:nth-child(1) {
        background-color: white;
    }
`

interface UpdateAvailableNoticeProps {
    readonly renderNotification: (value: boolean) => void
}

export default function UpdateAvailableNotice(
    props: UpdateAvailableNoticeProps,
) {
    const { renderNotification } = props
    const reloadPage = () => window.location.reload()

    return (
        <StickyDiv>
            <NoticeBox
                icon={VsWrenchIcon}
                color="success"
                heading="A new version is available."
                content={
                    <>
                        <span>
                            Please reload the page to get the latest version.
                        </span>
                        <button
                            id="refreshButton"
                            type="button"
                            onClick={reloadPage}
                        >
                            Refresh now
                        </button>
                    </>
                }
            />
            <UiCloseSmallIcon
                id="closeButton"
                data-testid="updateNotificationCloseButton"
                color={theme.colors.monochrome.ravenSub}
                height={24}
                width={24}
                onClick={() => renderNotification(false)}
            />
        </StickyDiv>
    )
}
