import React, { useEffect } from 'react'

import {
    Button,
    CepApprovedIcon,
    CepChecklistIcon,
    CepCreditScorePrimeIcon,
    CepDeclinedIcon,
    StCompleteIcon,
    VsEmailIcon,
    VsPhoneIcon,
    VsPlaceIcon,
} from '@octane/spark'
import NoIcon from 'components/Icons/No.svg'
import SafeCheckReportButtonContainer from 'containers/SafeCheckReportButtonContainer'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useUser from 'hooks/useUser'
import { LeadDetail } from 'pages/LeadsPage/types'
import { formatCurrency } from 'utils/formatCurrency'
import formatDate from 'utils/formatDate'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import getCopyIntoNewUrl from 'utils/getCopyIntoNewPath'
import {
    pushConvertToAppModalClick,
    pushViewLeadModalImpression,
} from 'utils/gtm-utils'
import joinTextValues from 'utils/joinTextValues'
import relativeDays from 'utils/relativeDays'

import getLeadSourceClient from '../../utils/getLeadSourceClient'
import {
    Wrapper,
    Header,
    Title,
    LeadId,
    LeadIdLabel,
    Content,
    ContactInformation,
    ContactRow,
    Card,
    CardLabel,
    CardHeader,
    CardRow,
    CardTitle,
    SSNStatus,
    Capitalize,
    NoVehicle,
} from './LeadQuickView.styled'

export default function LeadQuickView({
    lead,
}: {
    lead: LeadDetail
}): JSX.Element {
    const { user } = useUser()
    const featureFlags = useFeatureFlagContext()
    const isSSNProvided = !!lead.consumer.ssn

    const getStatusIcon = (status: string) => {
        return (
            {
                Prequalified: CepApprovedIcon,
                Declined: CepDeclinedIcon,
            }[status] || CepChecklistIcon
        )
    }

    const StatusIcon = getStatusIcon(lead.status)

    useEffect(() => {
        pushViewLeadModalImpression(lead.id)
    }, [lead.id])

    return (
        <Wrapper>
            <Header>
                <Title>
                    {joinTextValues([
                        lead.consumer.firstName?.toLocaleLowerCase(),
                        lead.consumer.lastName?.toLocaleLowerCase(),
                    ])}
                </Title>
                <LeadId>
                    <LeadIdLabel>Lead ID</LeadIdLabel>
                    <span>{lead.id}</span>
                </LeadId>
            </Header>
            <Content>
                <ContactInformation>
                    {lead.isPrime && (
                        <CepCreditScorePrimeIcon width={65} height={65} />
                    )}
                    <ContactRow>
                        <div>
                            <VsPhoneIcon width={22} height={22} />
                        </div>
                        <span>
                            {formatPhoneNumber(
                                lead.consumer.mobilePhone ||
                                    lead.consumer.homePhone,
                            )}
                        </span>
                    </ContactRow>
                    <ContactRow>
                        <div>
                            <VsEmailIcon width={22} height={22} />
                        </div>
                        <span>{lead.consumer.emailAddress}</span>
                    </ContactRow>
                    <ContactRow>
                        <div>
                            <VsPlaceIcon width={22} height={22} />
                        </div>
                        <span>
                            {joinTextValues(
                                [lead.consumer.city, lead.consumer.state],
                                ', ',
                            )}
                        </span>
                    </ContactRow>
                    <a
                        href={getCopyIntoNewUrl(
                            featureFlags,
                            user?.dealership?.primaryAssetType,
                            lead.id,
                        )}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button
                            fullWidth
                            onClick={() => {
                                pushConvertToAppModalClick(lead.id)
                            }}
                        >
                            Convert to App
                        </Button>
                    </a>
                </ContactInformation>
                <Card>
                    <CardHeader>
                        <div>
                            <CardLabel>Decision</CardLabel>
                            <CardTitle>{lead.status}</CardTitle>

                            <SafeCheckReportButtonContainer
                                applicationUUID={lead.uuid}
                            />
                        </div>
                        <StatusIcon width={65} height={65} />
                    </CardHeader>
                    <CardRow>
                        <span>Received</span>
                        <span>
                            {relativeDays(
                                new Date(formatDate(lead.createdAt)),
                                new Date(),
                            )}
                        </span>
                    </CardRow>
                    <CardRow>
                        <span>Risk Tier</span>
                        <span>{lead.riskTier}</span>
                    </CardRow>
                    <CardRow>
                        <span>SSN Provided</span>
                        {isSSNProvided ? (
                            <SSNStatus>
                                <StCompleteIcon width={16} height={16} />
                                Yes
                            </SSNStatus>
                        ) : (
                            <SSNStatus>
                                <img src={NoIcon} alt="No" />
                                No
                            </SSNStatus>
                        )}
                    </CardRow>
                </Card>
                <Card>
                    <CardHeader
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <CardLabel>Requested Vehicle</CardLabel>
                        {typeof lead.vehicle !== 'string' ? (
                            <CardTitle>
                                {joinTextValues([
                                    lead.vehicle.year?.toString(),
                                    lead.vehicle.make,
                                    lead.vehicle.model.toUpperCase(),
                                ])}
                            </CardTitle>
                        ) : (
                            <NoVehicle>
                                Customer&apos;s preferred vehicle is unknown
                            </NoVehicle>
                        )}
                    </CardHeader>
                    <CardRow>
                        <span>Lead Source</span>
                        <span>{getLeadSourceClient(lead.sourceClient)}</span>
                    </CardRow>
                    {typeof lead.vehicle !== 'string' && (
                        <>
                            <CardRow>
                                <span>Condition</span>
                                <Capitalize>
                                    {lead.vehicle?.condition}
                                </Capitalize>
                            </CardRow>
                            <CardRow>
                                <span>MSRP</span>
                                <span>
                                    {formatCurrency(lead.vehicle?.msrp)}
                                </span>
                            </CardRow>
                        </>
                    )}
                </Card>
            </Content>
        </Wrapper>
    )
}
