import React from 'react'
import { Outlet } from 'react-router-dom'

import ResetPasswordRequest from 'containers/MyAccount/ResetPassword'

import EmailConfirmation from './EmailConfirmation'
import ResetConfirmation from './ResetConfirmation'
import ResetPassword from './ResetPassword'
import { Container } from './ResetPasswordPage.styled'

export const NestedResetPasswordRoutes = [
    {
        index: true,
        element: <ResetPasswordRequest />,
        path: '',
        label: 'Reset Password Request',
    },
    {
        index: false,
        element: <EmailConfirmation />,
        path: 'email-sent',
        label: 'Reset Request Sent',
    },
    {
        index: false,
        element: <ResetConfirmation />,
        path: 'success',
        label: 'Password Reset Success',
    },
    {
        index: false,
        element: <ResetPassword />,
        path: 'reset/:dealer/:token',
        label: 'Reset Request Sent',
    },
]

export default function ResetPasswordPage(): JSX.Element {
    return (
        <Container>
            <Outlet />
        </Container>
    )
}
