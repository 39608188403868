import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'
import SourceAction from '../../state/enums/SourceAction'
import SourceClient from '../../state/enums/SourceClient'
import SourceSubclient from '../../state/enums/SourceSubclient'

export interface DisclosureDTO {
    disclosure_id: number
    checked: boolean
}

export interface SubmitApplicationDTO {
    application_type: string
    source_action: SourceAction
    source_client: SourceClient
    source_subclient?: SourceSubclient
    parent?: number
    group?: number
    finance_manager: string

    // Primary
    first_name: string
    middle_initial?: string
    last_name: string
    suffix?: string
    primary_address: string
    primary_apt: string
    primary_zip: string
    primary_state: string
    primary_city: string
    social_security_number: string
    dob: string
    citizenship_status?: string

    // CoApplicant
    coapplicant_first_name?: string
    coapplicant_middle_initial?: string
    coapplicant_last_name?: string
    coapplicant_suffix?: string
    coapplicant_same_address?: boolean
    coapplicant_primary_address?: string
    coapplicant_primary_apt?: string
    coapplicant_primary_zip?: string
    coapplicant_primary_state?: string
    coapplicant_primary_city?: string
    coapplicant_social_security_number?: string
    coapplicant_dob?: string
    coapplicant_citizenship_status?: string
    coapplicant_relationship?: string

    home_phone?: string
    mobile_phone?: string
    work_phone?: string
    residential_status?: string
    residential_years?: string
    residential_months?: string
    monthly_housing_payment?: string
    job_title?: string
    employment_status?: string
    employer_name?: string
    employer_years?: string
    employer_months?: string
    employer_monthly_gross?: string
    employer_gross_amount?: string
    employer_gross_interval?: string
    other_income_source?: string
    other_monthly_gross_income?: string
    other_income_gross_amount?: string
    other_income_gross_interval?: string
    email_address?: string

    coapplicant_home_phone?: string
    coapplicant_mobile_phone?: string
    coapplicant_work_phone?: string
    coapplicant_residential_status?: string
    coapplicant_residential_years?: string
    coapplicant_residential_months?: string
    coapplicant_monthly_housing_payment?: string
    coapplicant_job_title?: string
    coapplicant_employment_status?: string
    coapplicant_employer_name?: string
    coapplicant_employer_years?: string
    coapplicant_employer_months?: string
    coapplicant_employer_monthly_gross?: string
    coapplicant_employer_gross_amount?: string
    coapplicant_employer_gross_interval?: string
    coapplicant_other_income_source?: string
    coapplicant_other_monthly_gross_income?: string
    coapplicant_other_income_gross_amount?: string
    coapplicant_other_income_gross_interval?: string
    coapplicant_email_address?: string

    trade_in_intent?: boolean
    disclosures?: DisclosureDTO[]
}

export default async function postApplication(params: SubmitApplicationDTO) {
    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/client-application/`,
            params,
            {
                withCredentials: true,
            },
        )

        return data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError('Failed to post application', axiosError)

        throw new Error(axiosError.message)
    }
}
