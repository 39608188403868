import React from 'react'

import RideNowNavbarLogo from 'assets/img/ridenow-logo-navbar.svg'
import RideNowLogo from 'assets/img/ridenow-logo.svg'
import rideNowTheme from 'themes/rideNowTheme'

import { Brand, BrandConfig } from '../types'

const rideNowConfig: BrandConfig = {
    brand: Brand.RideNow,
    name: 'RideNow',
    hostRegex: /ridenow\..*/,
    theme: rideNowTheme,
    logo: RideNowLogo,
    navbarLogo: RideNowNavbarLogo,
    hideNavbarAtLogin: true,
    loginHeaderTitle: 'Financial Portal',
    loginLogoWidth: 300,
    whitelabelId: 'rideNow',
    footerContent: (
        <>
            RideNow&reg; and the RideNow Icon are registered trademarks of
            RumbleOn, Inc.. Octane&reg;, Roadrunner Financial&reg; and
            Roadrunner Account Services&reg; are registered service marks of
            Octane Lending, Inc.&reg;. RideNow Finance is the brand name for a
            program that offers certain consumer credit plans extended by
            participating dealers to borrowers for the purchase of Powersports
            Vehicles from RumbleOn, Inc. affiliated dealers. Financing for these
            programs is provided by Roadrunner Financial, Inc. (NMLS ID 1525116)
            without regard to race, color, religion, national origin, sex or
            familial status. Roadrunner Account Services, LLC (NMLS ID 1758660)
            services these loans on behalf of Roadrunner Financial, Inc.
            Roadrunner Financial, Inc. and Roadrunner Account Services, LLC are
            subsidiaries of Octane Lending, Inc.
        </>
    ),
    faviconPath: `${window.location.origin}/img/favicons/ridenow-favicon.png`,
    pageTitle: 'RideNow Powered by Octane Dealer Portal',
}

export default rideNowConfig
