import React from 'react'

import { FormikTabSelect } from 'components/FormikFields'

export default function ConditionTab() {
    const conditions = [
        { value: 'N', label: 'New' },
        { value: 'U', label: 'Pre-owned' },
    ]

    return (
        <FormikTabSelect
            name="marine.condition"
            label="Condition"
            items={conditions}
            initialValue="new"
        />
    )
}
