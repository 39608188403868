import { FieldInstruction } from 'containers/FormBuilder'
import InputFieldMasked from 'form/fieldComponents/InputFieldMasked'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import { transformMonthDayYearStringToDate } from 'form/validation/validationUtils'
import moment from 'moment'
import * as Yup from 'yup'

const dateOfBirth: FieldInstruction<typeof InputFieldMasked> = {
    Component: InputFieldMasked,
    config: {
        label: 'Date of Birth',
        mask: '00 / 00 / 0000',
        placeholder: 'MM / DD / YYYY',
        validator: Yup.date()
            .transform(transformMonthDayYearStringToDate)
            .max(new Date(), "Date of birth can't be greater than today.")
            .test('over-18-validator', 'Must be 18 years or older', (date) => {
                return moment().diff(moment(date), 'years') >= 18
            })
            .test(
                'under-120-validator',
                'Must be younger than 120 years',
                (date) => {
                    return moment().diff(moment(date), 'years') < 120
                },
            )
            .required(FIELD_REQUIRED)
            .typeError('Please enter a valid date.'),
    },
}

export default dateOfBirth
