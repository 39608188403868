import axios, { AxiosError } from 'axios'

import config from '../../config'

export interface reauthenticateParams {
    password: string
}

async function reauthenticate(params: reauthenticateParams) {
    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/auth/sessions/reauthenticate/`,
            params,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        throw new Error(axiosError.message)
    }
}

export default reauthenticate
