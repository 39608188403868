export default function saveUrlAsFile(
    objectUrl: string,
    fileName: string,
): void {
    const anchor = document.createElement('a')

    anchor.href = objectUrl
    anchor.download = fileName
    anchor.click()
}
