import { createAsyncThunk } from '@reduxjs/toolkit'
import { getInvitations } from 'api/invitation'

const loadInvitations = createAsyncThunk(
    'invitation/loadInvitations',
    async () => {
        return getInvitations()
    },
)

export default loadInvitations
