export const FIELD_TOO_SHORT = (min: number) =>
    `The field must have at least ${min} characters`
export const FIELD_TOO_LONG = (max: number) =>
    `The maximum length is ${max} characters`
export const FIELD_REQUIRED = 'The field is required'
export const FIELD_INVALID_EMAIL_TOO_LONG =
    'The maximum length is 75 characters'
export const FIELD_INVALID_EMAIL = 'The email is invalid'
export const FIELD_INVALID_PHONE = 'The phone number is invalid'
export const FIELD_NEGATIVE_NUMBER = 'The field must contain positive numbers'
export const FIELD_GREATER_THAN_ZERO = 'The field must be greater than zero'
export const FIELD_MAX_VALUE = (max: number) =>
    `The maximum value for this field is ${max}`
export const FIELD_INVALID_SSN = 'The SSN number is invalid'
export const FIELD_INVALID_STATE =
    'Roadrunner does not finance applicants in the selected state'
export const FIELD_SAME_STATE =
    "The 'State Eligibility' and 'State' fields must have the " +
    'same state selected to be a valid application to submit'
export const FIELD_NOT_A_NUMBER = 'Only numbers are allowed'
export const FIELD_MAX_VALUE_USED_MILEAGE =
    'We currently do not finance pre-owned units with more than 120,000 miles.'
export const FIELD_VALUE_MONTHS = 'Please enter a value between 0 and 11'
