import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, TextInput, CepPasswordLockIcon } from '@octane/spark'
import { sendResetPassword } from 'api/user'
import { AxiosError } from 'axios'
import useAppDispatch from 'hooks/useAppDispatch'
import useUser from 'hooks/useUser'
import { logoutUser } from 'state/user/actions'
import isValidEmail from 'utils/isValidEmail'
import * as toast from 'utils/toast'

import { Field, Form } from './ResetPassword.styled'

export default function ResetPasswordRequest(): JSX.Element {
    const { user } = useUser()
    const [emailAddress, setEmailAddress] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    function sendRequestEmail() {
        if (emailAddress) {
            sendResetPassword(emailAddress)
                .then(() => {
                    dispatch(logoutUser({ skipRedirect: true })).then(() => {
                        navigate('/password-reset/email-sent')
                    })
                })
                .catch((error: AxiosError) => {
                    toast.error({ message: error.message })
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => user && setEmailAddress(user.email), [user])

    return (
        <Form>
            <CepPasswordLockIcon width={100} height={100} color="" />
            <p>
                Enter the email address associated with your account and we’ll
                send you instructions to reset your password.
            </p>
            <Field>
                <TextInput
                    id="email-address"
                    data-testid="email-address"
                    label="Email Address"
                    value={emailAddress}
                    onChange={setEmailAddress}
                    type="email"
                />
            </Field>
            <Button
                className="send-reset-email"
                fullWidth
                id="button-send-reset-email"
                onClick={() => sendRequestEmail()}
                disabled={!isValidEmail(emailAddress)}
                loading={isLoading}
            >
                Send Reset Email
            </Button>
        </Form>
    )
}
