import { UiSwapIcon } from '@octane/spark'
import { shadows, typography } from 'assets/styles'
import styled from 'styled-components'

export const Title = styled.h1`
    ${typography.Hero24};
    margin: 0;
    display: flex;
    align-items: center;
`

export const RefreshIconWrapper = styled.div`
    margin-left: 8px;
`

export const SpinnerIcon = styled(UiSwapIcon)<{ $spin: boolean }>`
    ${(props) => (props.$spin ? 'animation: rotate .35s linear infinite;' : '')}

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
`

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.monochrome.white};
    padding: 40px;
    ${shadows.Tile};
`

export const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
`

export const PrimaryEmail = styled.div`
    ${typography.Copy14}
    color: ${(props) => props.theme.colors.monochrome.asphalt};
`
