import axios, { AxiosError } from 'axios'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export type ZipCodeListByStateCountyCity = {
    [state: string]: {
        [county: string]: {
            [city: string]: string[]
        }
    }
}

const getZipCodes = async (): Promise<ZipCodeListByStateCountyCity> => {
    try {
        const response = await axios.get('/data/zipcodesByState.json')
        const responseData: ZipCodeListByStateCountyCity = response.data

        return responseData
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get zip codes: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}

export default getZipCodes
