import React, { useState, useEffect } from 'react'

import { NoticeBox, NoticeBoxColors, VsInformationIcon } from '@octane/spark'
import getConsumerShoppedCollateralString from 'api/application/getConsumerShoppedCollateralString'

type Props = {
    applicationUuid: string
}

export default function ConsumerShoppedCollateralNotice({
    applicationUuid,
}: Props) {
    const [
        consumerShoppedCollateralString,
        setConsumerShoppedCollateralString,
    ] = useState<string | undefined>()

    useEffect(() => {
        const loadCollateralString = async () => {
            const collateralString = await getConsumerShoppedCollateralString(
                applicationUuid,
            )

            setConsumerShoppedCollateralString(collateralString)
        }

        loadCollateralString().catch(() => {
            // Suppress errors to not cause issues for non-consumer applications.
        })
    }, [applicationUuid])

    if (!consumerShoppedCollateralString) {
        return null
    }

    return (
        <NoticeBox
            icon={VsInformationIcon}
            color={NoticeBoxColors.NOTE}
            content={`The customer chose ${consumerShoppedCollateralString}`}
        />
    )
}
