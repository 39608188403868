import React from 'react'

import { FieldInstruction } from 'containers/FormBuilder'
import CheckboxField from 'form/fieldComponents/CheckboxField'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import DisclosureType from 'state/enums/DisclosureType'
import * as Yup from 'yup'

import ConsentCheckboxLabel from './consentCheckboxLabel'

function createConsentCheckbox(
    disclosureType: DisclosureType,
): FieldInstruction<typeof CheckboxField> {
    return {
        Component: CheckboxField,
        config: {
            label: <ConsentCheckboxLabel disclosureType={disclosureType} />,
            validator: Yup.bool().oneOf([true]).required(FIELD_REQUIRED),
            initialValue: false,
        },
    }
}

export default createConsentCheckbox
