import React from 'react'

import { NoticeBox, VsExclamationIcon } from '@octane/spark'

import { Container } from './CustomerNotEligibleWarningBox.styled'

interface CustomerNotEligibleWarningBoxProps {
    content: React.ReactNode
    style?: React.CSSProperties
}

const defaultProps = {
    style: undefined,
}

export default function CustomerNotEligibleWarningBox({
    content,
    style,
}: CustomerNotEligibleWarningBoxProps) {
    return (
        <Container style={style}>
            <NoticeBox content={content} heading="" icon={VsExclamationIcon} />
        </Container>
    )
}

CustomerNotEligibleWarningBox.defaultProps = defaultProps
