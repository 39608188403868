import styled from 'styled-components'

export const InvitationList = styled.div`
    width: 100%;
`
export const ColunmWrapper = styled.div`
    text-overflow: ellipsis;
    width: 180px;
    height: 18px;
    white-space: nowrap;
    overflow: hidden;
`
