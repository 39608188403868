import React from 'react'

import FormContent from 'components/FormContent'
import { FormikAmountField, FormikTextInput } from 'components/FormikFields'

export default function TrailerWriteIn(props: {
    isPrimary: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    prefilled?: boolean
}): JSX.Element {
    const { formik, isPrimary, prefilled } = props
    const primaryOrSecondary = isPrimary ? 'primary' : 'secondary'
    const isConditionUsed = formik.values.vehicle.condition === 'used'

    const trailerValueLabel = isConditionUsed
        ? 'NADA Wholesale Value'
        : 'Manufacturer Invoice Value'

    const trailerValueTooltip = isConditionUsed
        ? undefined
        : 'Manufacturer Invoice Value is the sum of the unit cost and surcharge up to 50%, excluding freight.'

    return (
        <>
            <FormContent gridTemplate="2fr .96fr">
                <FormikAmountField
                    name={`vehicle.${primaryOrSecondary}.trailerValue`}
                    label={trailerValueLabel}
                    tooltip={trailerValueTooltip}
                    placeholder="0"
                    omitDecimals
                    prefilled={prefilled}
                />
            </FormContent>
            <FormContent gridTemplate="2fr .96fr">
                <FormikTextInput
                    label="Trailer Model Name"
                    name={`vehicle.${primaryOrSecondary}.trailerModel`}
                    prefilled={prefilled}
                />
            </FormContent>
            <FormContent gridTemplate="2fr .96fr">
                <FormikAmountField
                    name={`vehicle.${primaryOrSecondary}.trailerWeight`}
                    label="Trailer Weight (in pounds)"
                    placeholder="0"
                    currencySymbol=""
                    omitDecimals
                    prefilled={prefilled}
                />
            </FormContent>
        </>
    )
}

const defaultProps = {
    prefilled: false,
}

TrailerWriteIn.defaultProps = defaultProps
