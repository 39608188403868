import { useEffect, useState } from 'react'

import { getDeclineReasons } from 'api/application'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

export interface UseDeclineReasonsInterface {
    declineReasons: string[]
    loading: boolean
    error: Error | null
}

const useDeclineReasons = (
    applicationUuid: string | undefined,
): UseDeclineReasonsInterface => {
    const [declineReasons, setDeclineReasons] = useState<string[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const abortController = new AbortController()

        const loadDeclineReasons = async () => {
            return getDeclineReasons(applicationUuid || '', abortController)
        }

        if (!applicationUuid) {
            return
        }

        loadDeclineReasons()
            .then((reasons: string[]) => {
                setError(null)
                setDeclineReasons(reasons)
            })
            .catch((declineReasonsError) => {
                OctaneLogger.error(
                    'Error when getting decline reasons for application.',
                    { applicationUuid },
                    declineReasonsError as Error,
                )

                setError(declineReasonsError)
            })
            .finally(() => {
                setLoading(false)
            })

        // eslint-disable-next-line consistent-return
        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationUuid])

    return {
        declineReasons,
        loading,
        error,
    }
}

export default useDeclineReasons
