// eslint-disable-next-line import/no-unresolved
import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Banner = styled('div')<{ padding?: string; height?: string }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${(props) => props.padding || '45px 15px'};
    gap: 10px;

    width: 100%;
    height: ${(props) => props.height || '56px'};
    left: 685px;
    top: 149px;
    border: 1px solid #d5d9e0;
    border-radius: 5px;
    box-shadow: 4px 4px 5px #d5d9e0;
`

export const BannerText = styled.p`
    ${typography.BannerText};
    color: ${(props) => props.theme.colors.monochrome.jetlineBlack};
`
