import React from 'react'

import SafeCheckReportButton from 'components/SafeCheckReportButton'
import useGoogleTagManager from 'hooks/useGoogleTagManager'
import useSafeCheck from 'hooks/useSafeCheck'
import useSession from 'hooks/useSession'
import useUser from 'hooks/useUser'

type Props = {
    applicationUUID: string
}

const getSafeCheckReportUrl = (applicationUUID: string) => {
    return `/#/safecheck/report/${applicationUUID}/`
}

export default function SafeCheckReportButtonContainer({
    applicationUUID,
}: Props): JSX.Element {
    const { user } = useUser()
    const googleTagManager = useGoogleTagManager()
    const session = useSession()
    const requireMFA = !session.mfaTimeLeft
    const safeCheckReportUrl = getSafeCheckReportUrl(applicationUUID)

    const { canUseSafeCheck, hasSafeCheckReport, isLoading } =
        useSafeCheck(applicationUUID)

    const buttonText = 'View Credit Report'

    const disabledButtonText = isLoading
        ? 'Looking for Credit Report...'
        : 'Credit Report Not Available'

    if (!canUseSafeCheck || !user?.mfaVerified) {
        return <div />
    }

    return (
        <SafeCheckReportButton
            user={user}
            hasSafeCheckReport={hasSafeCheckReport}
            safeCheckReportUrl={safeCheckReportUrl}
            buttonText={buttonText}
            disabledButtonText={disabledButtonText}
            requireMFA={requireMFA}
            onReportOpen={() => {
                googleTagManager.trackEvent({
                    event: 'view_credit_report',
                    link_text: buttonText,
                    link_url: safeCheckReportUrl,
                    application_id: applicationUUID,
                    custom_events: {
                        credit_report_views: 1,
                    },
                })
            }}
        />
    )
}
