/**
 * Sets the value and touched status of a given field after a delay, as a workaround for a bug in Formik where nested
 * fields'values do not get properly updated. This function uses the setTimeout method with a delay of 0 milliseconds to
 * ensure that the formik instance is properly updated with the new field value and touched status.
 * @param {any} formik An instance of the Formik component that contains the form with the nested fields.
 * @param {string} fieldName The name of the field whose value needs to be set and touched status should be set to true.
 * @param {unknown} fieldValue The new value that should be assigned to the field.
 */
export const setFieldValueAndTouched = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any,
    fieldName: string,
    fieldValue: unknown,
) => {
    formik.setFieldValue(fieldName, fieldValue)
    setTimeout(() => formik.setFieldTouched(fieldName, true))
}
