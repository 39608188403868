import React from 'react'
import { Page, pdfjs } from 'react-pdf'

import {
    Container,
    InnerContainer,
    PageThumbContainer,
    PdfPageThumb,
    Document,
} from './PdfViewerNavigator.styled'

// eslint-disable-next-line max-len
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export type Props = {
    documentUrl: string
    numberOfPages: number
    openPage: (n: number) => void
    page: number
}

export default function PdfViewerNavigator({
    documentUrl,
    page,
    openPage,
    numberOfPages,
}: Props): JSX.Element {
    return (
        <Container data-test-id="rfrfr">
            <Document file={documentUrl}>
                <InnerContainer>
                    {Array.from(Array(numberOfPages).keys()).map((n) => (
                        <PageThumbContainer
                            key={n}
                            onClick={() => openPage(n + 1)}
                            data-page-idx={n}
                            data-testid="PageThumbContainer"
                            data-selected={n + 1 === page ? 'true' : 'false'}
                        >
                            <PdfPageThumb
                                selected={n + 1 === page}
                                data-testid={PageThumbContainer}
                            >
                                <Page
                                    pageNumber={n + 1}
                                    height={110}
                                    renderAnnotationLayer={false}
                                    renderInteractiveForms={false}
                                    renderTextLayer={false}
                                    rotate={0}
                                />
                            </PdfPageThumb>
                            <small>Page {n + 1}</small>
                        </PageThumbContainer>
                    ))}
                </InnerContainer>
            </Document>
        </Container>
    )
}
