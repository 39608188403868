/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Button, CepExpiredIcon } from '@octane/spark'
import Card from 'components/Card'
import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import useUser from 'hooks/useUser'
import LoadingStatus from 'state/enums/LoadingStatus'
import { logoutUser } from 'state/user/actions'

import { FullContainer, Text } from '../Invitation.styled'
import { Centered, AccountButton, BoldText } from './LoggedIn.styled'

export default function LoggedIn() {
    const navigate = useNavigate()
    const { token } = useParams()
    const dispatch = useAppDispatch()
    const { user, isLoggedIn } = useUser()
    const { status } = useAppSelector((state) => state.iframe)

    const logoutAndRedirect = () => {
        dispatch(logoutUser({ skipRedirect: true })).then(() => {
            navigate(`/invitation/${token}/`)
        })
    }

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(`/invitation/${token}/`)
        }
    }, [isLoggedIn, navigate, token])

    return (
        <FullContainer>
            <Card>
                <Centered>
                    <CepExpiredIcon width={100} height={100} />
                    <h1>You are already logged in</h1>
                    <Text>To accept an invitation you must be logged out.</Text>
                    {isLoggedIn && (
                        <>
                            <BoldText>Email: {user?.email}</BoldText>
                            <Button
                                onClick={logoutAndRedirect}
                                loading={status !== LoadingStatus.LOADED}
                                disabled={status !== LoadingStatus.LOADED}
                            >
                                Logout and create new account
                            </Button>
                            <AccountButton
                                disabled={status !== LoadingStatus.LOADED}
                                data-testid="myaccount-link"
                                onClick={() => navigate('/account-settings')}
                            >
                                Continue to my account
                            </AccountButton>
                        </>
                    )}
                </Centered>
            </Card>
        </FullContainer>
    )
}
