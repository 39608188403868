import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

interface SessionResponse {
    mfa_time_left: number
    was_recently_authenticated: boolean
}

export interface Session {
    mfaTimeLeft: number
    wasRecentlyAuthenticated: boolean
}

export default async function getCurrentSession() {
    try {
        const { data }: { data: SessionResponse } = await axios.get(
            `${config.API_BASE_URL}/api/v3/auth/sessions/current/`,
            {
                withCredentials: true,
            },
        )

        return {
            mfaTimeLeft: data.mfa_time_left,
            wasRecentlyAuthenticated: data.was_recently_authenticated,
        } as Session
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get current session: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
