import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function getDealerAssignedLinks() {
    try {
        const response = await axios.get(
            `${config.API_BASE_URL}/api/v3/dealer-assigned-links/`,
            {
                withCredentials: true,
            },
        )

        return response.data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get dealer assigned links: ${axiosError.message}`,
            axiosError,
        )

        throw axiosError
    }
}
