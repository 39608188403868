import { theme } from '@octane/spark'
import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Terms = styled.div`
    display: flex;
    gap: 10px;
    margin: 20px 0 5px;
`

export const TermsCheck = styled.div`
    flex: fit-content;
    align-self: center;
`

export const TermsText = styled.div`
    color: ${theme.colors.monochrome.asphalt};
    ${typography.Copy16};
    align-self: center;
    line-height: 1.4em;
    cursor: pointer;
`
