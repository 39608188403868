import React from 'react'

import { StatusIndicator } from '@octane/spark'

import {
    DateText,
    IconWrapper,
    ComponentWrapper,
} from './ApplicationStatusIndicator.styled'

interface ApplicationStatusProps {
    status: string
    submittedAt: string
    createdAt: string
}

function formatDate(dateString: string) {
    const date = new Date(dateString)

    const formattedDate = date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    })

    return formattedDate
}

function ApplicationStatusIndicator({
    status,
    submittedAt,
    createdAt,
}: Readonly<ApplicationStatusProps>) {
    const formattedTimestamp = submittedAt
        ? `Submitted at ${formatDate(submittedAt)}`
        : `Created at ${formatDate(createdAt)}`

    return (
        <ComponentWrapper>
            <IconWrapper status={status}>
                <StatusIndicator
                    iconPosition="left"
                    text={status}
                    variant="noStatus"
                />
            </IconWrapper>
            <DateText>{formattedTimestamp}</DateText>
        </ComponentWrapper>
    )
}

export default ApplicationStatusIndicator
