import React from 'react'

import { Row } from './FormContent.styled'

type Props = {
    gridTemplate?: string
    children: React.ReactNode
} & typeof defaultProps

const defaultProps = {
    gridTemplate: '1fr 1fr 1fr',
}

function FormContent({ gridTemplate, children }: Props) {
    return <Row gridTemplateColumns={gridTemplate}>{children}</Row>
}

FormContent.defaultProps = defaultProps

export default FormContent
