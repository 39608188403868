export default function convertObjectToQueryString(
    value: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any
        condition?: string
        lender_id?: number | undefined
        data_source?: string | null
        asset_type?: string | null | undefined
        primary_vehicle_id?: number | undefined
        year?: number
        disable?: boolean | undefined
        make_id?: number
        model_id?: number
        vehicle_id?: number | undefined
        order_by?: null
    },
    removeEmpty = false,
) {
    let newValue = value

    newValue = { ...value }

    if (removeEmpty) {
        // Clone and re-assign the value so
        // we don't modify the original reference.

        Object.keys(value).forEach((key) => {
            if (value[key] === undefined || value[key] === null) {
                delete newValue[key]
            }
        })
    }

    return Object.keys(newValue)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value[key])}`,
        )
        .join('&')
}
