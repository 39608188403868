/* eslint-disable max-len */
import React from 'react'

import { Button } from '@octane/spark'
import FocusTopFormError from 'components/FocusTopFormError'
import FormContent from 'components/FormContent'
import FormValidationMessage from 'components/FormValidationMessage/FormValidationMessage'
import InformationSection from 'components/InformationSection'
import ConsumerShoppedCollateralNotice from 'components/notices/ConsumerShoppedCollateralNotice'
import {
    FieldGroup,
    FieldInstructionBundle,
    FieldKit,
    Form,
    FormBuilder,
    FormBuilderProps,
    FormValues,
} from 'containers/FormBuilder'
import { FormikValues } from 'formik'
import CASPartnerCopyWarning from '../../components/CASPartnerCopyWarning'
import config from '../../config'
import {
    StyledFinanceSection,
    StyledApplicationSection,
    StyledFormSection,
    FinanceRuleLine,
    ApplicantRuleLine,
    StyledConsentSection,
} from './ApplicationFormBuilder.styled'
import { generateFieldInstructionsWithPrefill } from './utils'

export const citizenshipStatusDisclaimer = `We only provide financing to US Citizens
and Permanent Residents. We cannot accept Driver's Licenses with
the words 'Limited Term' or 'Temp Visitor'. Contracts will not
be honored for non-permanent residents.`

const strongerApplicantBannerText = `Please be sure to put the stronger
applicant as the primary applicant.`

function CustomInformationSection(props: {
    id: string
    bannerText: string
    padding?: string
    height?: string
}) {
    const { id, bannerText, padding, height } = props

    return (
        <InformationSection
            id={id}
            icon="Information"
            bannerText={bannerText}
            padding={padding}
            height={height}
        />
    )
}

CustomInformationSection.defaultProps = {
    padding: undefined,
    height: undefined,
}

export type ApplicationSubmissionFormInstructions = {
    financeManagerFields?: FieldInstructionBundle
    collateralFields: FieldInstructionBundle
    applicantNameFields: FieldInstructionBundle
    applicantIdentityFields: FieldInstructionBundle
    applicantPersonalFields: FieldInstructionBundle
    emailFields: FieldInstructionBundle
    contactInfoFields: FieldInstructionBundle
    residenceFields: FieldInstructionBundle
    employmentFields: FieldInstructionBundle
    coapplicantNameFields: FieldInstructionBundle
    coapplicantIdentityFields: FieldInstructionBundle
    coapplicantEmailFields: FieldInstructionBundle
    coapplicantPersonalFields: FieldInstructionBundle
    coapplicantAddressFields: FieldInstructionBundle
    coapplicantContactInfoFields: FieldInstructionBundle
    coapplicantResidenceFields: FieldInstructionBundle
    coapplicantEmploymentFields: FieldInstructionBundle
    applicationTypeFields: FieldInstructionBundle
    relationOfCoapplicantFields: FieldInstructionBundle
    consentFields: FieldInstructionBundle
    verificationFields: FieldInstructionBundle
}

type FormValuesToBoolFunc = (formValues: FormValues) => boolean

export type ApplicationFormBuilderProps = {
    instructions: ApplicationSubmissionFormInstructions
    coapplicantTag: string
    collateralType: string
    isApplicationJointFunc: FormValuesToBoolFunc
    showCitizenshipStatusDisclaimer: FormValuesToBoolFunc
    showCoapplicantCitizenshipStatusDisclaimer: FormValuesToBoolFunc
    showConsumerShoppedCollateralNotice?: boolean
    swapApplicantsFunc: (fieldKit: FieldKit) => void
    isCoapplicantAddressSameFunc: FormValuesToBoolFunc
    // eslint-disable-next-line max-len
    overrideDefaultInitialValues?: FormBuilderProps['overrideDefaultInitialValues']
    enableReinitialize?: FormBuilderProps['enableReinitialize']
    onSubmit: FormBuilderProps['onSubmit']
    sectionWidth?: string
    sectionMargin?: string
    isCopy?: boolean
    parentUuid?: string
    partner?: string
    setFinalize: (state: boolean) => void
    canSubmit: boolean
    isReadOnly?: boolean
    isCrossoverApplication?: boolean
}

const renderFinanceManager = (
    isCrossoverApplication: boolean | undefined,
    sectionWidth: string | undefined,
    sectionMargin: string | undefined,
    fieldKit: FieldKit<FormikValues> | undefined,
    financeManagerFields: FieldInstructionBundle | undefined,
    // eslint-disable-next-line consistent-return
) => {
    if (!isCrossoverApplication && financeManagerFields) {
        return (
            <StyledFinanceSection
                title=""
                width={sectionWidth}
                margin={sectionMargin}
            >
                {/* eslint-disable-next-line max-len */}
                <FormContent gridTemplate="1fr 1fr">
                    <FieldGroup
                        fieldKit={fieldKit}
                        fieldInstructionBundle={financeManagerFields}
                    />
                    <div />
                </FormContent>
                <FinanceRuleLine />
            </StyledFinanceSection>
        )
    }
}

export default function ApplicationFormBuilder(
    props: ApplicationFormBuilderProps,
): JSX.Element {
    const {
        instructions,
        coapplicantTag,
        collateralType,
        isApplicationJointFunc,
        showCitizenshipStatusDisclaimer,
        showCoapplicantCitizenshipStatusDisclaimer,
        showConsumerShoppedCollateralNotice,
        swapApplicantsFunc,
        isCoapplicantAddressSameFunc,
        overrideDefaultInitialValues,
        enableReinitialize,
        onSubmit,
        sectionWidth,
        sectionMargin,
        isCopy,
        parentUuid,
        partner,
        setFinalize,
        canSubmit,
        isReadOnly,
        isCrossoverApplication,
    } = props

    let allInstructions = instructions

    if (isReadOnly) {
        allInstructions = Object.keys(allInstructions).reduce(
            (acc: ApplicationSubmissionFormInstructions, key: string) => {
                const bundleName =
                    key as keyof ApplicationSubmissionFormInstructions

                const innerBundle = allInstructions[bundleName]

                if (innerBundle) {
                    acc[bundleName] =
                        generateFieldInstructionsWithPrefill(innerBundle)
                }

                return acc
            },
            {} as ApplicationSubmissionFormInstructions,
        )
    }

    const combinedFieldsBundle: FieldInstructionBundle = Object.values(
        allInstructions,
    ).reduce<FieldInstructionBundle>((acc, instructionBundle) => {
        return {
            ...acc,
            ...instructionBundle,
        }
    }, {})

    const autoCompleteSetting = config.ENVIRONMENT === 'local' ? 'on' : 'off'
    const applicationSectionMargin = '20px 0 0 0'

    return (
        <FormBuilder
            fieldInstructionBundle={combinedFieldsBundle}
            onSubmit={onSubmit}
            overrideDefaultInitialValues={overrideDefaultInitialValues || {}}
            enableReinitialize={enableReinitialize}
        >
            {({ fieldKit, formKit }) => {
                return (
                    <Form autoComplete={autoCompleteSetting}>
                        <CASPartnerCopyWarning
                            isCopy={isCopy}
                            partner={partner}
                            sectionMargin={sectionMargin}
                            sectionWidth={sectionWidth}
                        />
                        {/* FINANCE MANAGER */}
                        {renderFinanceManager(
                            isCrossoverApplication,
                            sectionWidth,
                            sectionMargin,
                            fieldKit,
                            allInstructions.financeManagerFields,
                        )}
                        {/* COLLATERAL FIELDS */}
                        <StyledFormSection
                            title="Collateral Information"
                            subtitle={collateralType}
                            width={sectionWidth}
                            margin={sectionMargin}
                        >
                            {showConsumerShoppedCollateralNotice && parentUuid && (
                                <FormContent gridTemplate="2fr 1fr">
                                    <ConsumerShoppedCollateralNotice
                                        applicationUuid={parentUuid}
                                    />
                                </FormContent>
                            )}
                            <FieldGroup
                                fieldKit={fieldKit}
                                fieldInstructionBundle={
                                    allInstructions.collateralFields
                                }
                            />
                        </StyledFormSection>

                        {/* APPLICANT FIELDS */}
                        <StyledApplicationSection
                            title="Applicant"
                            width={sectionWidth}
                            margin={applicationSectionMargin}
                        >
                            <FormContent gridTemplate="2fr 1fr">
                                <FieldGroup
                                    fieldKit={fieldKit}
                                    fieldInstructionBundle={
                                        allInstructions.applicationTypeFields
                                    }
                                />
                            </FormContent>
                            <ApplicantRuleLine />
                            <h3>Personal Info</h3>

                            {isApplicationJointFunc(fieldKit.values) && (
                                <FormContent gridTemplate="2fr 1fr">
                                    {!isReadOnly && (
                                        <>
                                            <CustomInformationSection
                                                id="strongerApplicantBanner"
                                                bannerText={
                                                    strongerApplicantBannerText
                                                }
                                                padding="0px 15px"
                                                height="50px"
                                            />
                                            <Button
                                                type="button"
                                                id="swapApplicantsButton"
                                                testID="swapApplicantsButton"
                                                size="medium"
                                                disabled={false}
                                                onClick={() => {
                                                    swapApplicantsFunc(fieldKit)
                                                }}
                                            >
                                                Swap
                                            </Button>
                                        </>
                                    )}
                                </FormContent>
                            )}

                            <FormContent gridTemplate="1fr 1fr">
                                <FieldGroup
                                    fieldKit={fieldKit}
                                    fieldInstructionBundle={
                                        allInstructions.applicantNameFields
                                    }
                                />
                            </FormContent>
                            <FormContent gridTemplate="1fr 1fr 1fr">
                                <FieldGroup
                                    fieldKit={fieldKit}
                                    fieldInstructionBundle={
                                        allInstructions.applicantIdentityFields
                                    }
                                />
                            </FormContent>
                            {showCitizenshipStatusDisclaimer(
                                fieldKit.values,
                            ) && (
                                <FormContent gridTemplate="1fr">
                                    {/* eslint-disable-next-line max-len */}
                                    <CustomInformationSection
                                        id="citizenshipStatusDisclaimer"
                                        bannerText={citizenshipStatusDisclaimer}
                                    />
                                </FormContent>
                            )}

                            <FormContent gridTemplate="repeat(12, 1fr)">
                                <FieldGroup
                                    fieldKit={fieldKit}
                                    fieldInstructionBundle={
                                        allInstructions.applicantPersonalFields
                                    }
                                />
                            </FormContent>
                            <FormContent gridTemplate="repeat(6, 1fr)">
                                <FieldGroup
                                    fieldKit={fieldKit}
                                    fieldInstructionBundle={
                                        allInstructions.residenceFields
                                    }
                                />
                            </FormContent>

                            <ApplicantRuleLine />
                            <h3>Contact Info</h3>
                            <FormContent gridTemplate="repeat(3, 1fr)">
                                <FieldGroup
                                    fieldKit={fieldKit}
                                    fieldInstructionBundle={
                                        allInstructions.emailFields
                                    }
                                />
                            </FormContent>
                            <FormContent gridTemplate="repeat(3, 1fr)">
                                <FieldGroup
                                    fieldKit={fieldKit}
                                    fieldInstructionBundle={
                                        allInstructions.contactInfoFields
                                    }
                                />
                            </FormContent>
                            <ApplicantRuleLine />

                            <h3>Income & Employment</h3>
                            <FormContent gridTemplate="repeat(24, 1fr)">
                                <FieldGroup
                                    fieldKit={fieldKit}
                                    fieldInstructionBundle={
                                        allInstructions.employmentFields
                                    }
                                />
                            </FormContent>
                            <ApplicantRuleLine />
                        </StyledApplicationSection>

                        {/* CO-APPLICANT FIELDS */}
                        {isApplicationJointFunc(fieldKit.values) && (
                            <StyledApplicationSection
                                title="Co-Applicant"
                                width={sectionWidth}
                                margin={sectionMargin}
                            >
                                <FormContent gridTemplate="1fr">
                                    <FieldGroup
                                        fieldKit={fieldKit}
                                        fieldInstructionBundle={
                                            // eslint-disable-next-line max-len
                                            allInstructions.relationOfCoapplicantFields
                                        }
                                    />
                                </FormContent>
                                <ApplicantRuleLine />
                                <h3>Co-Applicant Personal Info</h3>
                                <FormContent gridTemplate="1fr 1fr">
                                    <FieldGroup
                                        fieldKit={fieldKit}
                                        fieldInstructionBundle={
                                            // eslint-disable-next-line max-len
                                            allInstructions.coapplicantNameFields
                                        }
                                    />
                                </FormContent>
                                <FormContent gridTemplate="1fr 1fr 1fr">
                                    <FieldGroup
                                        fieldKit={fieldKit}
                                        fieldInstructionBundle={
                                            // eslint-disable-next-line max-len
                                            allInstructions.coapplicantIdentityFields
                                        }
                                    />
                                </FormContent>
                                {showCoapplicantCitizenshipStatusDisclaimer(
                                    fieldKit.values,
                                ) && (
                                    <FormContent gridTemplate="1fr">
                                        <CustomInformationSection
                                            // eslint-disable-next-line max-len
                                            id={`${coapplicantTag}CitizenshipStatusDisclaimer`}
                                            bannerText={
                                                citizenshipStatusDisclaimer
                                            }
                                        />
                                    </FormContent>
                                )}

                                <FormContent gridTemplate="repeat(24, 1fr)">
                                    <FieldGroup
                                        fieldKit={fieldKit}
                                        fieldInstructionBundle={
                                            // eslint-disable-next-line max-len
                                            allInstructions.coapplicantPersonalFields
                                        }
                                    />
                                </FormContent>

                                {!isCoapplicantAddressSameFunc(
                                    fieldKit.values,
                                ) && (
                                    <FormContent gridTemplate="repeat(12, 1fr)">
                                        <FieldGroup
                                            fieldKit={fieldKit}
                                            fieldInstructionBundle={
                                                allInstructions.coapplicantAddressFields
                                            }
                                        />
                                    </FormContent>
                                )}

                                <FormContent gridTemplate="repeat(6, 1fr)">
                                    <FieldGroup
                                        fieldKit={fieldKit}
                                        fieldInstructionBundle={
                                            // eslint-disable-next-line max-len
                                            allInstructions.coapplicantResidenceFields
                                        }
                                    />
                                </FormContent>

                                <ApplicantRuleLine />
                                <h3>Co-Applicant Contact Info</h3>
                                <FormContent gridTemplate="repeat(3, 1fr)">
                                    <FieldGroup
                                        fieldKit={fieldKit}
                                        fieldInstructionBundle={
                                            // eslint-disable-next-line max-len
                                            allInstructions.coapplicantEmailFields
                                        }
                                    />
                                </FormContent>
                                <FormContent gridTemplate="repeat(3, 1fr)">
                                    <FieldGroup
                                        fieldKit={fieldKit}
                                        fieldInstructionBundle={
                                            // eslint-disable-next-line max-len
                                            allInstructions.coapplicantContactInfoFields
                                        }
                                    />
                                </FormContent>
                                <ApplicantRuleLine />

                                <h3>Co-Applicant Employment Info</h3>
                                <FormContent gridTemplate="repeat(24, 1fr)">
                                    <FieldGroup
                                        fieldKit={fieldKit}
                                        fieldInstructionBundle={
                                            // eslint-disable-next-line max-len
                                            allInstructions.coapplicantEmploymentFields
                                        }
                                    />
                                </FormContent>
                                <ApplicantRuleLine />
                            </StyledApplicationSection>
                        )}

                        <StyledConsentSection
                            title=""
                            subtitle=""
                            width={sectionWidth}
                            margin={sectionMargin}
                        >
                            <FieldGroup
                                fieldKit={fieldKit}
                                fieldInstructionBundle={
                                    allInstructions.consentFields
                                }
                            />
                        </StyledConsentSection>
                        <StyledConsentSection
                            title=""
                            subtitle=""
                            width={sectionWidth}
                            margin={sectionMargin}
                        >
                            <FieldGroup
                                fieldKit={fieldKit}
                                fieldInstructionBundle={
                                    allInstructions.verificationFields
                                }
                            />
                        </StyledConsentSection>
                        {!isReadOnly && (
                            <StyledFormSection
                                title=""
                                width={sectionWidth}
                                margin={sectionMargin}
                            >
                                <FormContent gridTemplate="1fr">
                                    <FormValidationMessage
                                        message={`
                                            Please address all validation messages
                                            in the form before submitting this
                                            application
                                        `}
                                        formIsValid={formKit.isValid}
                                        submitAttempted={
                                            formKit.submitCount > 0
                                        }
                                    />
                                    <Button
                                        type="submit"
                                        id="instantDecisionButton"
                                        size="large"
                                        disabled={!canSubmit}
                                        testID="instantDecisionButton"
                                        onClick={() => setFinalize(true)}
                                    >
                                        Advance to Instant Decision
                                    </Button>
                                    <Button
                                        type="submit"
                                        id="saveButton"
                                        size="large"
                                        disabled={!canSubmit}
                                        variant="secondary"
                                        testID="saveButton"
                                        onClick={() => setFinalize(false)}
                                    >
                                        Save Application
                                    </Button>
                                </FormContent>
                            </StyledFormSection>
                        )}
                        <FocusTopFormError />
                    </Form>
                )
            }}
        </FormBuilder>
    )
}

ApplicationFormBuilder.defaultProps = {
    overrideDefaultInitialValues: undefined,
    enableReinitialize: undefined,
    sectionWidth: '100%',
    sectionMargin: '0',
    showConsumerShoppedCollateralNotice: false,
    isCopy: false,
    parentUuid: undefined,
    partner: '',
    isReadOnly: false,
    isCrossoverApplication: false,
}
