import React from 'react'

import { IconComponent } from '@octane/spark'
import brandConfig from 'branding'

import {
    NavbarDropdownButtonStyled,
    NavbarDropdownHelpIcon,
    NavbarDropdownIcon,
    NavbarDropdownSubtext,
} from '../NavbarDropdown.styled'

const ICON_SIZE = 21

export interface NavbarDropdownButtonProps {
    id: string
    text: string
    icon: IconComponent
}

export default function NavbarDropdownButton(
    props: NavbarDropdownButtonProps,
): JSX.Element {
    const { id, text, icon: DropdownIcon } = props

    return (
        <NavbarDropdownButtonStyled id={id}>
            <NavbarDropdownHelpIcon>
                <DropdownIcon
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    color={brandConfig.theme.navbar.color}
                />
            </NavbarDropdownHelpIcon>
            <NavbarDropdownSubtext>
                {text}
                <NavbarDropdownIcon
                    color={brandConfig.theme.navbar.color}
                    width={9}
                    height={9}
                />
            </NavbarDropdownSubtext>
        </NavbarDropdownButtonStyled>
    )
}
