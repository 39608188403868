import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function getCollateralsAssets(
    applicationUuid: string,
    abortController: AbortController,
) {
    try {
        if (!applicationUuid) {
            throw new Error('invalid UUID')
        }

        const { data } = await axios.get(
            // eslint-disable-next-line max-len
            `${config.API_BASE_URL}/api/v3/applications/${applicationUuid}/collaterals-assets/`,
            {
                signal: abortController.signal,
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get collateral assets: ${axiosError.message}`,
            axiosError,
            { applicationUuid },
        )

        throw axiosError
    }
}
