/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FactorType, MfaWaitError } from 'api/auth/mfa'
import axios, { AxiosError } from 'axios'
import config from 'config'
import moment from 'moment'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

const defaultErrorMessage = 'An unknown error occurred. Please contact support.'

function handleResponseError(
    error: AxiosError<any, unknown>,
    errorHandler?: (statusCode: number | undefined, data: unknown) => void,
) {
    const response = error?.response
    const responseData = response?.data

    OctaneLogger.axiosError(`MFA Error: ${error.message}`, error)

    if (errorHandler) {
        errorHandler(response?.status, responseData)
    }

    const errorMessage =
        responseData?.message ||
        responseData?.detail?.message ||
        defaultErrorMessage

    throw new AxiosError<any, unknown>(
        errorMessage,
        error.code,
        error.config,
        error.request,
        response,
    )
}

export async function getMfaProfile() {
    try {
        return await axios.get(
            `${config.API_BASE_URL}/api/v3/auth/mfa/get_mfa_profile/`,
            {
                withCredentials: true,
            },
        )
    } catch (error: any) {
        handleResponseError(error)
    }
}

export async function getMfaFactors() {
    try {
        return await axios.get(
            `${config.API_BASE_URL}/api/v3/auth/mfa/factors/`,
            {
                withCredentials: true,
            },
        )
    } catch (error: any) {
        handleResponseError(error)
    }
}

export async function sendMfaFactorChallenge(id: FactorType) {
    try {
        return await axios.post(
            `${config.API_BASE_URL}/api/v3/auth/mfa/factors/${id}/challenge/`,
            {},
            {
                withCredentials: true,
            },
        )
    } catch (error: any) {
        handleResponseError(
            error,
            (status: number | undefined, data: any): void => {
                if (status === 429) {
                    const message = data?.message
                    const rateLimitedUntil = data?.rate_limited_until

                    if (rateLimitedUntil) {
                        throw new MfaWaitError(
                            message,
                            moment(rateLimitedUntil),
                        )
                    }
                }
            },
        )
    }
}

export async function setupMfaFactor(id: FactorType, value: string) {
    try {
        return await axios.post(
            `${config.API_BASE_URL}/api/v3/auth/mfa/factors/${id}/setup/`,
            { value },
            {
                withCredentials: true,
            },
        )
    } catch (error: any) {
        handleResponseError(error)
    }
}

export async function verifyMfaFactor(id: FactorType, answer: string) {
    try {
        return await axios.post(
            `${config.API_BASE_URL}/api/v3/auth/mfa/factors/${id}/verify/`,
            { answer },
            {
                withCredentials: true,
            },
        )
    } catch (error: any) {
        handleResponseError(error)
    }
}
