import { TextArea } from '@octane/spark'
import styled, { keyframes } from 'styled-components'

const moveDown = keyframes`
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`

export const AnimatedField = styled.div`
    span {
        display: block;
        animation-duration: 0.2s;
        animation-timing-function: ease-out;
        animation-name: ${moveDown};
        margin-top: 2px;
    }
`
export const FormikInput = styled(TextArea)`
    &:disabled {
        color: ${(props) => props.theme.colors.monochrome.asphalt};
        background-color: ${(props) => props.theme.colors.monochrome.moonGrey};
        border: none;
    }
`
