import styled from 'styled-components'

export const BorderLine = styled.div`
    width: 100%;
    height: 0px;
    border-top: 2px solid #d5d9e0;

    flex: none;
    order: 0;
    flex-grow: 0;
`
interface RowProps {
    gridTemplateColumns: string
}

export const Row = styled.div<RowProps>`
    display: grid;
    grid-template-columns: ${(props) => props.gridTemplateColumns};
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    :last-child {
        margin-bottom: 0;
    }
`

export const CheckContainer = styled.div`
    display: flex;
`
export const ErrorMessage = styled.span`
    ${(props) => props.theme.fonts.Karla['s12-ln16-fw400']};
    color: ${(props) => props.theme.colors.alert.error};
    margin-top: -15px;
`
export const ResidenceParagraph = styled.p`
    margin: 3px 0 0 0;
    padding-left: 1vw;
    font-size: 14px;
    line-height: 20px;
    font-family: Karla, Helvetica, san-serif;
`
