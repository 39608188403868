import { useCallback, useEffect, useRef, useState } from 'react'

import { getCurrentFeatureFlags } from 'api/auth'
import getCurrentWebAppVersion from 'api/auth/getCurrentWebAppVersion'
import config from 'config'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'
import _ from 'lodash'

import useUser from './useUser'

const CHECK_UPDATES_INTERVAL_MS = 5 * 60 * 1000 // 5 minutes

declare global {
    interface Window {
        waffle:
            | {
                  flag_is_active: (flag: string) => boolean
                  switch_is_active: (flag: string) => boolean
              }
            | undefined
    }
}

window.waffle = {
    flag_is_active: () => false,
    switch_is_active: () => false,
}

async function isWebAppNewVersion(): Promise<boolean> {
    if (config.ENVIRONMENT !== 'local') {
        try {
            const response = await getCurrentWebAppVersion()

            if (response && response.build !== '' && response.build !== null) {
                const buildVersion = String(response.build)

                const releaseVersion = String(
                    process.env.REACT_APP_RELEASE_VERSION,
                )

                return buildVersion !== releaseVersion
            }
        } catch (error) {
            OctaneLogger.error(
                'Error getting deployment json',
                undefined,
                error as Error,
            )
        }
    }

    return false
}

function useCurrentFlags(): [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
    () => Promise<void>,
] {
    const [currentFlags, setCurrentFlags] = useState({})
    const [isFlagOrSwitchUpdated, setIsFlagOrSwitchUpdated] = useState(false)

    useEffect(() => {
        window.waffle = {
            flag_is_active: (name: string) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Boolean((currentFlags as any)[name]?.is_active),
            switch_is_active: (name: string) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Boolean((currentFlags as any)[name]?.is_active),
        }
    }, [currentFlags])

    const checkFeatureFlagUpdate = useCallback(async () => {
        try {
            const response = await getCurrentFeatureFlags()
            const remoteFlags = { ...response.flags, ...response.switches }

            if (
                !_.isEqualWith(
                    currentFlags,
                    remoteFlags,
                    (_value1, _value2, key) => {
                        return key === 'last_modified' ? true : undefined
                    },
                )
            ) {
                setCurrentFlags(remoteFlags)

                if (Object.keys(currentFlags).length > 0) {
                    setIsFlagOrSwitchUpdated(true)
                }
            } else {
                setIsFlagOrSwitchUpdated(false)
            }
        } catch (error) {
            OctaneLogger.error(
                `Error getting feature flag JSON`,
                undefined,
                error as Error,
            )

            throw error
        }
    }, [currentFlags])

    return [
        isFlagOrSwitchUpdated,
        setIsFlagOrSwitchUpdated,
        checkFeatureFlagUpdate,
    ]
}

export default function useKeepAppUpToDate(
    renderNotification: (value: boolean) => void,
    setShouldUpdateFlags?: () => void,
) {
    const { isLoggedIn } = useUser()

    const [
        isFlagOrSwitchUpdated,
        setIsFlagOrSwitchUpdated,
        checkFeatureFlagUpdate,
    ] = useCurrentFlags()

    const timeoutId = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        const checkUpdates = async () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current)
                timeoutId.current = null
            }

            if (!document.hidden) {
                await checkFeatureFlagUpdate()

                const isANewVersion = await isWebAppNewVersion()

                if (isANewVersion || isFlagOrSwitchUpdated) {
                    renderNotification(true)
                    if (setShouldUpdateFlags) {
                        setShouldUpdateFlags()
                    }

                    setIsFlagOrSwitchUpdated(false)
                }
            }

            timeoutId.current = setTimeout(
                checkUpdates,
                CHECK_UPDATES_INTERVAL_MS,
            )
        }

        if (isLoggedIn) {
            checkUpdates()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        checkFeatureFlagUpdate,
        isLoggedIn,
        isFlagOrSwitchUpdated,
        setIsFlagOrSwitchUpdated,
    ])
}

export const testExports = {
    isWebAppNewVersion,
    useCurrentFlags,
}
