import axios, { AxiosError } from 'axios'
import config from 'config'

export default async function getNotifications() {
    try {
        const { data } = await axios.get(
            `${config.API_BASE_URL}/notifications/`,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        throw new Error(axiosError.message)
    }
}
