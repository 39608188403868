import React from 'react'

import { Button, Modal } from '@octane/spark'
import { editUserPermissions } from 'api/user'
import { AxiosError } from 'axios'
import { UserBasicInfoForm } from 'components/UserForm'
import { Form, Formik } from 'formik'
import useAppDispatch from 'hooks/useAppDispatch'
import { loadUsersAndSalespersons } from 'state/dealership/actions'
import * as toast from 'utils/toast'

import { FormTitle, Divisor, LinkButton } from '../UserForm/UserForm.styled'
import { UserPermissionsSchema } from './schema.yup'
import { UserPermissions, UserPermissionsModalProps } from './types'

const INITIAL_SESSION_INACTIVITY_MINUTES = '30'
const INITIAL_PASSWORD_ROTATION_DAY = '90'

export default function UserPermissionsModal({
    onClose,
    user,
}: Readonly<UserPermissionsModalProps>) {
    const initialValues: UserPermissions = {
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        emailAddress: user?.emailAddress ?? '',
        levelOfAccess: user?.levelOfAccess ?? '',
        applicationAccess: user?.applicationAccess ?? '',
        sessionInactivity: INITIAL_SESSION_INACTIVITY_MINUTES,
        passwordRotation: INITIAL_PASSWORD_ROTATION_DAY,
        useMfa: 'No',
        disabledFields:
            user?.levelOfAccess === 'Sales'
                ? []
                : ['firstName', 'lastName', 'emailAddress'],
        phoneNumber: user?.phoneNumber ?? '',
    }

    const dispatch = useAppDispatch()

    function handleEditConfirm(userPermissions: UserPermissions) {
        if (user)
            editUserPermissions(user.id, userPermissions)
                .then((response: { detail: string }) => {
                    toast.success({
                        message: response.detail || '',
                    })

                    dispatch(loadUsersAndSalespersons())
                })
                .catch((error: AxiosError) => {
                    toast.error({
                        message: error.message || '',
                    })
                })
                .finally(() => {
                    onClose()
                })
    }

    return (
        <Modal isOpen onClose={onClose} size="large">
            <Modal.Body>
                <FormTitle>Edit User&apos;s Permissions</FormTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={UserPermissionsSchema}
                    onSubmit={(e) => handleEditConfirm(e)}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <UserBasicInfoForm selectedUser={user} />
                            <Divisor />
                            <Button
                                fullWidth
                                loading={isSubmitting}
                                type="submit"
                            >
                                Save Updated Permissions
                            </Button>
                            <LinkButton
                                fullWidth
                                variant="white"
                                onClick={onClose}
                            >
                                Cancel
                            </LinkButton>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}
