import React, { useEffect } from 'react'

import {
    ColumnDef,
    ColumnSort,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    Updater,
    useReactTable,
} from '@tanstack/react-table'

import OrderIcon from './OrderIcon'
import { Table, TableHead, TableHeadButton, TableRow } from './Table.styled'

interface TableProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: ColumnDef<any>[]
    defaultSortingState?: SortingState
    cbRefreshData?: (state: { sorting: SortingState }) => void | null
    disableClientSideSorting?: boolean
    enableMultiColumnSorting?: boolean
    onColumnClick?: (columnHeader: string) => void
}

export default function TableComponent<MetaProps>(
    props: TableProps & MetaProps,
): JSX.Element {
    const {
        data,
        columns,
        cbRefreshData,
        defaultSortingState,
        disableClientSideSorting,
        enableMultiColumnSorting,
        onColumnClick,
        ...meta
    } = props

    const [sorting, setSorting] = React.useState<SortingState>(
        defaultSortingState ?? new Array<ColumnSort>(),
    )

    const cbChangeSorting = (sortingStateUpdater: Updater<SortingState>) => {
        let newSortingState: SortingState

        if (typeof sortingStateUpdater === 'function') {
            newSortingState = sortingStateUpdater(sorting)
        } else {
            newSortingState = sortingStateUpdater
        }

        if (defaultSortingState && newSortingState.length === 0) {
            setSorting(defaultSortingState)
        } else setSorting(newSortingState)
    }

    if (disableClientSideSorting === true && !cbRefreshData) {
        throw new Error(
            // eslint-disable-next-line max-len
            'If the attribute disableClientSideSorting is true, then cbRefreshData must be defined.',
        )
    }

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: cbChangeSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel:
            disableClientSideSorting === true ? undefined : getSortedRowModel(),
        debugTable: true,
        manualSorting: disableClientSideSorting === true,
        ...(enableMultiColumnSorting === true
            ? {
                  enableMultiSort: true,
                  isMultiSortEvent: () => {
                      return true
                  },
              }
            : {}),
        meta: meta ?? {},
    })

    useEffect(() => {
        if (disableClientSideSorting && cbRefreshData)
            cbRefreshData({
                sorting,
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting])

    return (
        <Table>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <TableHead
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    size={header.getSize()}
                                >
                                    {header.isPlaceholder ||
                                    header.id === 'actions' ? null : (
                                        <TableHeadButton
                                            type="button"
                                            canSort={header.column.getCanSort()}
                                            onClick={(
                                                event: React.MouseEvent<HTMLButtonElement>, // eslint-disable-line max-len
                                            ) => {
                                                const cbGetToggleSortingHandler = // eslint-disable-line max-len
                                                    header.column.getToggleSortingHandler() // eslint-disable-line max-len

                                                if (cbGetToggleSortingHandler) {
                                                    cbGetToggleSortingHandler(
                                                        event,
                                                    )
                                                }

                                                if (onColumnClick) {
                                                    onColumnClick(
                                                        header.column.columnDef
                                                            .header as string,
                                                    )
                                                }
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}

                                            {header.column.getCanSort() && (
                                                <OrderIcon
                                                    direction={
                                                        header.column.getIsSorted() ?? // eslint-disable-line max-len
                                                        null
                                                    }
                                                />
                                            )}
                                        </TableHeadButton>
                                    )}
                                </TableHead>
                            )
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => {
                    return (
                        <TableRow key={row.id} data-testid="row">
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <td key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </tbody>
        </Table>
    )
}

TableComponent.defaultProps = {
    cbRefreshData: null,
    disableClientSideSorting: false,
    enableMultiColumnSorting: false,
    defaultSortingState: null,
    onColumnClick: null,
}
