import styled from 'styled-components'

import { shadows, typography } from '.'

export const DropdownContent = styled.div`
    ${shadows.Modal}
    background-color: ${({ theme }) => theme.colors.monochrome.white};
    color: ${({ theme }) => theme.colors.monochrome.ravenSub};
    padding: 16px 0;
    width: 200px;
    position: absolute;
    right: -10px;
    top: 49px;
    display: flex;
    flex-direction: column;
    ${typography.Pulldown16}

    span {
        cursor: pointer;
        width: 100%;
        padding: 0 16px;
        &:hover {
            background-color: ${({ theme }) =>
                theme.colors.monochrome.moonGrey};
        }
    }
`

export const DropdownLink = styled.div`
    width: 100%;
    padding: 0 16px;
    cursor: pointer;
    &:hover {
        background-color: ${({ theme }) => theme.colors.monochrome.moonGrey};
    }
`
