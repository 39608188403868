/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { patchApplication, postApplication } from 'api/application'
import getActiveLoans from 'api/application/getAllActiveLoans'
import getFinalDecision from 'api/application/getFinalDecision'
import { Disclosure } from 'api/lender/getLenderDisclosures'
import { AxiosError } from 'axios'
import SecondCheckpointModal from 'components/CheckpointModal/SecondCheckpointModal'
import Verified from 'components/Icons/Verified.svg'
import NewApplicationFormBetaNotice from 'components/notices/NewApplicationFormBetaNotice'
import RoadrunnerDealershipAgreementNotice from 'components/notices/RoadrunnerDealershipAgreementNotice'
import { extendFieldInstructionAny, FormValues } from 'containers/FormBuilder'
import ReturnCustomerModal from 'containers/ReturnCustomer/ReturnCustomerModal'
import {
    ApplicationDataType,
    ActiveLoanType,
} from 'containers/ReturnCustomer/types/returnCustomerTypes'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useRoadrunnerDisclosures from 'hooks/useRoadrunnerDisclosures'
import useUser from 'hooks/useUser'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'
import LoadingPage from 'pages/LoadingPage/LoadingPage'
import SourceClient from 'state/enums/SourceClient'
import SourceSubclient from 'state/enums/SourceSubclient'
import getApplicationDecisionPath from 'utils/getApplicationDecisionPath'
import * as toast from 'utils/toast'

import ApplicationFormBuilder, {
    ApplicationFormBuilderProps,
} from '../ApplicationFormBuilder'
import {
    COAPPLICANT_TAG,
    isApplicationJointFunc,
    isCoapplicantAddressSameFunc,
    applicantCitizenshipStatusLinkingFunc,
    coapplicantCitizenshipStatusLinkingFunc,
} from '../sharedInstructions'
import { swapApplicantsFunc } from '../utils'
import { powersportsFormInstructions } from './powersportsApplicationFields'
import {
    Label,
    MainContainer,
    Title,
    Titlebar,
} from './PowersportsApplicationFormBuilder.styled'
import setApplicationCollaterals from './setApplicationCollaterals'
/**
 * TODO: BLUR-494
 * For now we will use the Automobile form logic to create the application DTO.
 * In the future the fields used for the Powersports application will be
 * customized, at that point the logic to create the DTO object will also diverge
 * from the logic for the Automobile form. This work will be accomplished in
 * BLUR-494.
 */
// eslint-disable-next-line import/order, import-helpers/order-imports
import createPostApplicationDTO from '../AutomobileApplication/createPostApplicationDTO'

type PowersportsApplicationFormProps = {
    overrideDefaultInitialValues?: ApplicationFormBuilderProps['overrideDefaultInitialValues']
    isLoading?: boolean
    isCopy?: boolean
    parentId?: number
    parentUuid?: string
    isReadOnly?: boolean
    crossoverApplication?: boolean
    vehicleTypeList?: string[]
}

const submitApplication = async (
    uuid: string,
    values: FormValues,
    setShowSubmissionModal: (state: boolean) => void,
    setShowReturnCustomerModal: (state: boolean) => void,
    setApplicationData: (state: ApplicationDataType) => void,
    setActiveLoans: (state: ActiveLoanType[]) => void,
    returnCustomerPsApp2FeatureFlag: boolean | undefined,
    decisionPage2Powersports: boolean | undefined,
    finalize: boolean,
    isCopy?: boolean,
    parentId?: number,
    disclosures?: Disclosure[],
    // eslint-disable-next-line consistent-return
): Promise<string> => {
    let redirectUrl
    let targetUuid: string = uuid

    const tryShowReturnCustomerModal = async (
        applicationData: ApplicationDataType,
    ) => {
        const activeLoans = await getActiveLoans({
            id: applicationData.id,
            application_type: applicationData.application_type,
            social_security_number: applicationData.social_security_number,
            coapplicant_social_security_number:
                applicationData.coapplicant_social_security_number,
            parent_id: parentId,
        })

        if (activeLoans?.length) {
            setActiveLoans(activeLoans)
            setApplicationData(applicationData)
            setShowReturnCustomerModal(true)

            return true
        }

        return false
    }

    try {
        const applicationDTO = createPostApplicationDTO(
            values,
            isCopy,
            parentId,
            SourceClient.OCTANE_EXTENSION,
            SourceSubclient.POWERSPORTS_APP_V2,
            disclosures,
        )

        let applicationData
        let deleteExistingCollaterals = false

        if (targetUuid) {
            applicationData = await patchApplication(applicationDTO, targetUuid)
            deleteExistingCollaterals = true
        } else {
            applicationData = await postApplication(applicationDTO)
            targetUuid = applicationData.uuid
        }

        await setApplicationCollaterals(
            targetUuid,
            values.vehicle,
            deleteExistingCollaterals,
        )

        if (finalize) {
            if (returnCustomerPsApp2FeatureFlag) {
                if (await tryShowReturnCustomerModal(applicationData)) {
                    return targetUuid
                }
            }

            setShowSubmissionModal(true)
            await getFinalDecision(targetUuid)

            redirectUrl = getApplicationDecisionPath(
                applicationData.id,
                decisionPage2Powersports,
            )
        } else {
            toast.success({
                message: `Application saved`,
                withCloseIcon: false,
            })
        }
    } catch (error) {
        OctaneLogger.axiosError(
            'Unexpected error when trying to submit an application.',
            error as AxiosError,
            {
                applicationUuid: uuid,
                isCopy,
                parentId,
            },
        )

        setShowSubmissionModal(false)
        setShowReturnCustomerModal(false)

        redirectUrl = `#!/saved-applications`

        toast.error({
            message: `An unexpected error occurred. Please try again or contact support.`,
            withCloseIcon: false,
        })
    }

    if (redirectUrl) {
        window.location.hash = redirectUrl
    }

    return targetUuid
}

export default function PowersportsApplicationForm({
    overrideDefaultInitialValues,
    isLoading,
    isCopy,
    parentId,
    parentUuid,
    isReadOnly,
    crossoverApplication,
    vehicleTypeList,
}: PowersportsApplicationFormProps) {
    const [uuid, setUuid] = useState('')
    const [showSubmissionModal, setShowSubmissionModal] = useState(false)
    const [finalize, setFinalize] = useState(false)
    const [activeLoans, setActiveLoans] = useState<ActiveLoanType[]>([])

    const [
        isAgreementSignedWithRoadrunner,
        setIsAgreementSignedWithRoadrunner,
    ] = useState<boolean>()

    const dealershipLenderAgreementValidation =
        useFeatureFlagContext()?.dealershipLenderAgreementValidation

    const { user } = useUser()
    const { disclosures } = useRoadrunnerDisclosures()

    const newInstructions = {
        ...powersportsFormInstructions(crossoverApplication),
    }

    useEffect(() => {
        setIsAgreementSignedWithRoadrunner(
            user?.dealership?.isAgreementSignedWithRoadrunner,
        )
    }, [user?.dealership?.isAgreementSignedWithRoadrunner])

    const [applicationData, setApplicationData] = useState<
        ApplicationDataType | undefined
    >()

    const [showReturnCustomerModal, setShowReturnCustomerModal] =
        useState(false)

    const returnCustomerPsApp2FeatureFlag =
        useFeatureFlagContext()?.returnCustomerPsApp2

    const decisionPage2Powersports =
        useFeatureFlagContext()?.decisionPage2Powersports

    if (isLoading) {
        return <LoadingPage />
    }

    const showRoadrunnerDealershipAgreementNotice =
        isAgreementSignedWithRoadrunner === false &&
        dealershipLenderAgreementValidation

    const canSubmit = !(
        dealershipLenderAgreementValidation === true &&
        isAgreementSignedWithRoadrunner === false
    )

    if (crossoverApplication) {
        newInstructions.collateralFields.vehicle = extendFieldInstructionAny(
            newInstructions.collateralFields.vehicle,
            (instruction) => {
                return {
                    ...instruction,
                    config: {
                        ...instruction.config,
                        vehicleTypeList,
                    },
                }
            },
        )
    }

    // Currently, the vehicleTypeList is only populated in a crossover application when
    // a user selects the PWC tile.
    const collateralType = vehicleTypeList?.length ? 'PWC' : 'Powersports'

    return (
        <MainContainer>
            {showRoadrunnerDealershipAgreementNotice && (
                <RoadrunnerDealershipAgreementNotice />
            )}

            <ReturnCustomerModal
                applicationData={applicationData}
                activeLoans={activeLoans}
                isOpen={showReturnCustomerModal}
                handleClose={() => setShowReturnCustomerModal(false)}
            />
            <SecondCheckpointModal isOpen={showSubmissionModal} animated />

            {!isReadOnly && (
                <>
                    <NewApplicationFormBetaNotice />
                    {!crossoverApplication && (
                        <Titlebar>
                            <Title data-testid="newapplication-title">
                                {isCopy ? 'Copy Into New' : 'New Application'}
                            </Title>
                            <Label data-testid="newapplication-label">
                                Instant decisions • No credit impact &nbsp;
                                <img src={Verified} alt="Verified Shield" />
                            </Label>
                        </Titlebar>
                    )}
                </>
            )}

            <ApplicationFormBuilder
                onSubmit={async (values) => {
                    const newUuid = await submitApplication(
                        uuid,
                        values,
                        setShowSubmissionModal,
                        setShowReturnCustomerModal,
                        setApplicationData,
                        setActiveLoans,
                        returnCustomerPsApp2FeatureFlag,
                        decisionPage2Powersports,
                        finalize,
                        isCopy,
                        parentId,
                        disclosures,
                    )

                    setUuid(newUuid)
                }}
                collateralType={collateralType}
                instructions={newInstructions}
                coapplicantTag={COAPPLICANT_TAG}
                isApplicationJointFunc={isApplicationJointFunc}
                showCitizenshipStatusDisclaimer={
                    applicantCitizenshipStatusLinkingFunc
                }
                showCoapplicantCitizenshipStatusDisclaimer={
                    coapplicantCitizenshipStatusLinkingFunc
                }
                showConsumerShoppedCollateralNotice={Boolean(
                    isCopy && parentUuid,
                )}
                swapApplicantsFunc={swapApplicantsFunc}
                overrideDefaultInitialValues={{
                    ...overrideDefaultInitialValues,
                }}
                enableReinitialize
                isCoapplicantAddressSameFunc={isCoapplicantAddressSameFunc}
                setFinalize={setFinalize}
                canSubmit={canSubmit}
                isReadOnly={isReadOnly}
                parentUuid={parentUuid}
                isCrossoverApplication={crossoverApplication}
            />
        </MainContainer>
    )
}

PowersportsApplicationForm.defaultProps = {
    overrideDefaultInitialValues: undefined,
    isCopy: false,
    isLoading: false,
    parentId: undefined,
    parentUuid: undefined,
    isReadOnly: false,
    crossoverApplication: false,
    vehicleTypeList: null,
}

export const testExports = {
    submitApplication,
}
