import React from 'react'

import { DropdownLink } from 'assets/styles/dropdown'

export interface DropdownCustomItemProps {
    text: string
    handleClick: () => void
}

export function DropdownCustomItem({
    text,
    handleClick,
}: DropdownCustomItemProps) {
    return <DropdownLink onClick={handleClick}>{text}</DropdownLink>
}
