import React from 'react'

export default function TermsContent(): JSX.Element {
    return (
        <>
            <p>
                The terms and conditions (“Terms”) listed below govern use of
                the online services (the “Services”) and manuals, handbooks,
                guides, and other user instructions, documentation, programs,
                and materials (the foregoing collectively the “Documentation”)
                available herein (“Site”) provided by Octane Lending, Inc., and
                its subsidiaries (collectively “Octane”). The terms “you” and
                “your” in uppercase or lowercase shall mean you, the user of the
                Site, and/or the legal entity (e.g., corporation, partnership,
                sole proprietor, limited liability company, etc.) you represent
                and anyone you authorize on your behalf who shall utilize the
                Site. The term “Dealership” means the person or entity utilizing
                the Services offered on the Site and for which your access to
                the Site is associated. All obligations of the Dealership
                hereunder shall apply to You for the purposes of these Terms.
            </p>
            <p>
                BY ACCESSING, BROWSING, OR USING THIS SITE, YOU ACKNOWLEDGE THAT
                YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS
                THESE TERM. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT
                USE THIS SITE. THESE TERMS BIND YOU INDIVIDUALLY AS WELL AS ANY
                OTHER PERSON OR OTHER ENTITY ON WHOSE BEHALF YOU ARE ACCESSING
                THIS SITE.
            </p>
            <h3>1. General Usage and License</h3>
            <p>
                This Site is owned and operated by Octane. By offering this Site
                to you, Octane is providing you with a worldwide, nonexclusive,
                nontransferable, non-sublicensable, revocable, limited license
                to access and use the Services and Documentation on this Site,
                strictly in accordance with these Terms and for your own
                internal business usage. Our Services may contain data obtained
                from third parties. Octane has the absolute right at any time to
                change or discontinue any aspect or feature of the Site,
                including, without limitation, the content, links to third party
                websites, hours of availability, and equipment needed for access
                or use of this Site. Such changes, modifications, additions, or
                deletions shall be effective immediately upon notice thereof,
                which may be given by any means including, without limitation,
                posting on the Site, or by electronic or conventional mail, or
                by any other means. Any use of the Site by you after such notice
                shall be deemed to constitute acceptance of such changes,
                modifications, additions, or deletions. You acknowledge that
                some or all of the Services and Documentation and other content
                to be provided may still be under development and may have
                defects or deficiencies that cannot or will not be corrected by
                Octane, its third-party providers, its licensors or other
                suppliers.
            </p>
            <p>
                Your failure to comply with these Terms will result in automatic
                termination of this license, with or without prior notice.
                Certain portions of this Site may be linked to a website owned
                and/or operated by a third party which are governed by terms and
                conditions of such third party. Except for the limited license
                set forth in these terms, Octane, our third-party providers or
                our licensors do not grant you any express or implied rights or
                licenses under any patents, trademarks, copyrights, or other
                proprietary or intellectual property rights owned or licensed by
                Octane or any third party.
            </p>
            <p>
                We will deliver our Services and any Documentation
                electronically, or by other means, in our sole discretion. When
                you access or download our Services or Documentation, you agree
                to use same in accordance with applicable law. The Services
                provided on the Site are solely offered to citizens and
                residents of the United States of America and may not be
                accessed while outside of the USA. The United States Export
                Control laws prohibit the export of certain technical data and
                software to certain territories. No contents from this site may
                be downloaded or otherwise exported in violation of United
                States Law.
            </p>
            <h3>2. Data Usage</h3>
            <p>
                You hereby grant us a nonexclusive license and irrevocable right
                to use, copy, store, host, display, transmit and process Your
                Data (defined below) solely as necessary for Octane, our
                employees, contractors, and vendors to provide our Services and
                Documentation under the Terms and/or in accordance with
                applicable law. “Your Data” means information, data, and other
                content, including confidential customer information, in any
                form or medium, that is submitted, posted or otherwise
                transmitted by you or on your behalf through the Services in
                accordance with applicable law. Octane will not sell or
                otherwise transfer confidential customer information except in
                compliance with the law. Information regarding how we share data
                can be found here:{' '}
                <a
                    href="https://octane.co/o/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://octane.co/o/privacy-policy/
                </a>
            </p>
            <p>
                By providing the contact information of a consumer in connection
                with an Application, such as phone number and/or email address,
                you agree that the consumer has given affirmative consent for
                Octane to call, send text messages (including calling and
                sending text messages using equipment to automatically dial
                telephone numbers) and/or send emails to the consumer further
                agree that it is you and the Dealerships obligation to ensure
                that the consumer understands and agrees that Octane may always
                communicate with the consumer in any manner permitted by law
                that does not require the consumer&lsquo;s prior consent.
            </p>
            <p>
                You further agree that any information provided in an
                Application may be provided by Octane, to a 3rd party customer
                relationship management (“CRM”) systems that is operated on a
                Dealership’s behalf. You agree that you will obtain the
                consumer’s consent to share Application information with those
                CRM systems and that you are solely responsible for any
                information that is shared with them at your or the Dealership’s
                request. You understand and agree that data may be shared
                bidirectionally with these CRM systems until you notify us to
                cease sharing data. Furthermore, it is your responsibility to
                notify Octane, of any changes to or discontinuance of the use of
                any CRM system.
            </p>
            <h3>3. Restrictions on Use of the Site</h3>
            <ul>
                <li>
                    You will not use, intentionally or unintentionally any of
                    the content, information, or Services on this Site in a
                    manner contrary to or in violation of any applicable
                    federal, state, or local statute or regulation.
                </li>
                <li>
                    You will not use this Site in any manner which could damage,
                    disable, overburden, or impair the Site or interfere with
                    the Services and Documentation provided via this Site or
                    interfere with any other person’s or entity’s use of this
                    Site or otherwise impair any of Octane’s computer systems,
                    including, but not limited to servers, networks, and other
                    components connected to or used for this Site.
                </li>
                <li>
                    You will not upload, post, or transmit content that you do
                    not have a right to transmit under any law or under any
                    contractual relationship.
                </li>
                <li>
                    You will not upload, post, or transmit any material that
                    contains software viruses, or any computer code, files, or
                    programs designed to interfere, destroy or limit the
                    functionality of any computer software or hardware or
                    telecommunications equipment.
                </li>
                <li>
                    You will not attempt to gain unauthorized access to any
                    services, accounts, computer systems or networks connected
                    to any server used for this Site, through password mining,
                    hacking, or other means nor attempt to gain access to any
                    documentation or materials not intentionally made available
                    by Octane on the Site.
                </li>
                <li>
                    You will not modify any of the information, content,
                    Services, or Documentation provided on this Site.
                </li>
                <li>
                    You will not make any independent use of Octane’s
                    trademarks, service marks, trade names, logos and graphics
                    on this Site without Octane’s prior written consent or
                    consent of any necessary third party. The aforestated
                    intellectual property and marks displayed on the Site are
                    subject to federal, state and/or international trademark
                    protection. All other trademarks appearing on the Site are
                    the property of their respective owners.
                </li>
                <li>
                    You will not use any network monitoring or discovery
                    software to determine the Site architecture, extract
                    portions of any database, other information or extract usage
                    information regarding individual identities, or users of the
                    Site not otherwise permitted herein or without written
                    approval of Octane.
                </li>
                <li>
                    You will not use or otherwise export or re-export any
                    software or technical data from this Site, or any portion
                    thereof, in violation of the export control laws and
                    regulations of the United States of America or any other
                    country.
                </li>
            </ul>
            <p>
                The foregoing provisions of Section 3 are for the benefit of
                Octane and its third-party content providers and licensors, if
                any, and each shall have the right to assert and enforce such
                provisions directly on its own behalf. Octane and its
                third-party content providers and licensors, if any, may take
                any legal action they deem appropriate and necessary, including,
                without limitation, civil and criminal proceedings, and
                proceedings for restraining orders and injunctions. You agree
                that monetary damages may not be sufficient for a suitable
                remedy, and you consent to injunctive or other equitable relief
                for such violations without the requirement that Octane or
                Octane’s third-party providers and licensors post a bond or
                prove damages.
            </p>
            <h3>4. Program Revision Changes / Interruption in Services</h3>
            <p>
                Octane may, from time to time, revise or update, or perform
                maintenance upon the Site, the Services, the Documentation
                and/or related material, resulting in interrupted service or
                errors in the Services or rendering prior versions obsolete. We
                also may need to change the scope of our Services from time to
                time. We will attempt to provide prior notice of such
                interruptions and changes but cannot guarantee that such notice
                will be provided. We reserve the right to terminate these Terms
                as to all prior versions of the Site, the Services, the
                Documentation, and/or related material and limit access to our
                more recent versions and updates.
            </p>
            <h3>5. Disclaimer of Liability; Limitation of Liability</h3>
            <p style={{ fontWeight: 'bold' }}>
                YOU EXPRESSLY AGREE THAT USE OF THE SITE IS AT YOUR SOLE RISK.
            </p>
            <p>
                THE SITE, RELATED SERVICES AND DOCUMENTATION ARE PROVIDED WITH
                ALL FAULTS ON AN “AS IS” BASIS AND THE ENTIRE RISK AS TO
                SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT REST WITH
                YOU. THE SITE, RELATED SERVICES AND DOCUMENTATION ARE PROVIDED
                WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING
                WITHOUT LIMITATION WARRANTIES THAT THEY ARE FREE OF DEFECTS,
                VIRUS FREE, ABLE TO OPERATE ON AN UNINTERRUPTED BASIS,
                MERCHANTABLE, OF SATISFACTORY QUALITY, FIT FOR A PARTICULAR
                PURPOSE, OR NON-INFRINGING, EXCEPT TO THE EXTENT SUCH WARRANTIES
                ARE LEGALLY INCAPABLE OF EXCLUSION. OCTANE, ITS THIRD-PARTY
                PROVIDERS, LICENSORS AND OTHER SUPPLIERS DO NOT WARRANT THAT THE
                FUNCTIONALITY OF THE SITE WILL MEET YOUR REQUIREMENTS OR THAT
                ERRORS WILL BE CORRECTED. THIS DISCLAIMER OF WARRANTY
                CONSTITUTES AN ESSENTIAL PART OF THIS AGREEMENT. NO USE OF THE
                SITE IS AUTHORIZED HEREUNDER EXCEPT UNDER THIS DISCLAIMER.
            </p>
            <p>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                WILL OCTANE, ITS DIRECTORS, OFFICERS, MANAGERS, EMPLOYEES OR
                CONTRACTORS (COLLECTIVELY, THE “OCTANE LENDING GROUP”), OCTANE’S
                THIRD PARTY PROVIDERS AND LICENSORS BE LIABLE FOR ANY INDIRECT,
                SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING
                OUT OF OR IN ANY WAY RELATING TO THE USE OF OR INABILITY TO USE
                THE SITE OR THE SERVICES AND DOCUMENTATION, EVEN IF THEY HAVE
                BEEN ADVISED OF THE POSSIBILITY THEREOF. THE ENTIRE COLLECTIVE
                LIABILITY OF OCTANE, OCTANE’S THIRD PARTY PROVIDERS AND
                LICENSORS AND YOUR EXCLUSIVE REMEDY UNDER ANY PROVISION OF THESE
                TERMS SHALL BE TERMINATION OF THESE TERMS AND INABILITY TO USE
                THE SITE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                LIMITATION OF INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES, SO
                THIS EXCLUSION AND LIMITATION MAY NOT APPLY. TO THE EXTENT THAT
                THE FOREGOING LIMITATION IS NOT APPLICABLE FOR ANY REASON, THE
                LIABILITY OF THE OCTANE LENDING GROUP, OCTANE’S THIRD PARTY
                PROVIDERS AND ITS LICENSIRS AND THEIR RESPECTIVE AGENTS SHALL BE
                LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
            </p>
            <h3>6. Indemnification</h3>
            <p>
                Dealership agrees to defend, indemnify, and hold harmless Octane
                and its respective directors, officers, employees, managers and
                agents from and against any and all claims, actions, demands,
                damages, costs, liabilities, losses, and expenses (including
                reasonable attorneys’ fees) arising out of (i) your use of the
                Site or any information you obtain from the Site or its reports
                (ii) any distribution, publication, refusal to publish,
                deletion, editing or other use of the content you provide,
                and/or (iii) your breach of these Terms.
            </p>
            <h3>7. Third Party Content</h3>
            <p>
                Any information or content expressed or made available by third
                parties, including information providers and users, are those of
                the respective author(s) or distributor(s) and not of Octane.
                The Site may contain links to third party web sites with which
                Octane has a linking arrangement but for which Octane does not
                maintain control or assume responsibility. The inclusion of such
                a link does not necessarily imply endorsement of such sites by
                Octane or any association with the operators of such linked
                sites. Any concerns regarding any such third-party provider or
                resource, or any link thereto, should be directed to the
                particular service or resource. You should review these sites’
                terms of use and privacy policies.
            </p>
            <h3>8. Data Protection</h3>
            <p>
                Octane recognizes that information submitted by you through the
                Site may be sensitive and confidential in nature. Octane agrees
                (i) to hold that information in strict confidence and to take
                all reasonable precautions to protect such information, (ii) not
                to communicate, disclose or disseminate it or any information
                derived therefrom to any third person, including, but not
                limited to, any affiliated entity (unless as part of an approved
                business transaction), and (iii) not to make any use whatsoever
                at any time of it except as contemplated by these Terms or
                applicable law. It is Octane’s intent to protect the privacy and
                confidentiality of nonpublic personal information of the
                potential customers that the Dealership submits through the
                Site, and to comply with all applicable regulations related to
                privacy, including but not limited to Title V of the
                Gramm-Leach-Bliley Act (“GLB”) and Parts 716 and 748 of the
                National Credit Union Administration Rules and Regulations.
                Octane has implemented policies and procedures designed to
                protect against unauthorized or unintentional disposal of
                consumer information and disposal of such information in
                accordance with the provisions specified in 12 CFR 682(b)(1) and
                682(b)(2), including any revisions of same.
            </p>
            <h3>9. Feedback</h3>
            <p>
                Dealership acknowledges that feedback concerning its experiences
                with the Octane Site and Services are essential to its
                improvement. Dealership agrees that Octane may gather
                information from Dealership relating to Dealership’s use of the
                Site, Services or Documentation, including frequency of use and
                nature of use, to be used in connection with evaluating and
                improving the Site. Dealership acknowledges and agrees that any
                feedback provided is given voluntarily and without compensation.
                Dealership further agrees that any feedback provided shall
                become the property of Octane.
            </p>
            <h3>10. Notice of Dealership Change.</h3>
            <p>
                Dealership shall give notice to Octane of any material or
                significant change (“Change”) in the ownership, structure or
                business of Dealership, the death of a principal, whether a
                shareholder, partner, or owner, a dissolution, insolvency,
                bankruptcy, reorganization, merger or consolidation, a sale of
                assets or stock, a conversion to another legal structure or type
                of business, or cessation of business. Such notice shall be
                provided thirty (30) days prior to the Change or, if unknown to
                Dealership prior to the Change, then within five (5) days via
                U.S. certified mail after knowledge of such Change. Failure to
                provide adequate notice of any Dealership changes may result in
                access to the Site being terminated without prior notice.
            </p>
            <h3>
                11. Consumer Credit Application Requirements & Program Terms
            </h3>
            <ol type="A">
                <li>
                    <p>
                        Submitting Consumer Credit Applications: Prior to
                        Dealership submitting a consumer credit application
                        (“Application”) through the Site, you agree that you
                        will obtain a signed paper credit application and/or an
                        electronic credit application for that consumer stating
                        that the information provided is correct to the best of
                        the consumer’s knowledge. You further agree that any
                        information contained in that paper/electronic
                        application will be used to populate the information as
                        contained in any Application submitted through the Site,
                        and everything stated on that Application is true and
                        accurate to the best of Dealership’s knowledge and
                        belief. When submitting an Application through the Site,
                        you agree that the Dealership has a “permissible
                        purpose” under the federal Fair Credit Reporting Act, 15
                        U.S.C. 1681 et. seq., as amended (the &quot;FCRA&quot;)
                        and authorizes Octane, to obtain a consumer report from
                        one or more consumer reporting agencies and to check the
                        consumer’s credit, employment history, or any other
                        information, and to report such information, and its
                        credit experience with the consumer, to others. You
                        further agree that any consumer for which an Application
                        is submitted, is at least 18 years of age based upon
                        credible evidence provided to the associated Dealership.
                        Dealership agrees that for any Application submitted by
                        Dealership through the Site, a record of a consumer’s
                        ‘written instruction’ shall be retained by the
                        Dealership in a form that is capable of being accurately
                        reproduced for later reference by Octane, upon request.
                        You agree that in connection with utilizing the Services
                        you will notify the consumer subject to any Application
                        that any financing offered through the Site is being
                        offered by Roadrunner Financial, Inc.
                    </p>
                    <p>
                        You understand that in order to initially check the
                        rates and terms that a consumer qualifies for, one or
                        more soft credit pulls will be done by Octane, that will
                        not affect the consumer’s credit score. However, in
                        order to complete the Application and generate the
                        consumer&apos;s contract, a full credit report from one
                        or more consumer reporting agencies will be requested,
                        which is considered a hard credit pull and may affect
                        the consumer’s credit. This is a requirement in order to
                        complete the contracting process and you agree that that
                        for any Application you submit, you will obtain the
                        written consent of the consumer to perform a hard credit
                        pull at the time the initial Application is submitted.
                    </p>
                    <p>
                        You shall not operate as the agent of any third party
                        other than the Dealership while utilizing the Services
                        or accessing the Site. Dealership shall not utilize the
                        Site, Service or Documentation in order to (a) operate
                        as a reseller of credit data or (b) directly or
                        indirectly charge a consumer any costs or fees, or
                        accept any other payment or valuable consideration from
                        a consumer, for the Services or any information derived
                        therefrom (&quot;Consumer Credit Information&quot;),
                        including, without limitation, by offering the Services
                        or Consumer Credit Information (if any) as the sole
                        additional feature of a higher-priced service offering
                        or as an incentive to or bundled with a fee-based
                        offering. In connection with Dealership’s use of the
                        Site and the Services, Dealership shall be responsible
                        for compliance with all applicable federal and state
                        laws, including, without limitation, the provisions of
                        the FCRA, the federal Equal Credit Opportunity Act, as
                        amended (the &quot;ECOA&quot;), the federal Telephone
                        Consumer Protection Act, 47 U.S.C. § 227, as amended
                        (the &quot;TCPA&quot;), the federal Gramm-Leach-Bliley
                        Act of 1999, as amended (the &quot;GLBA&quot;), all
                        consumer protection laws, all state law counterparts of
                        them, and all applicable regulations promulgated under
                        any of them.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        DEALERSHIP SHALL NOT UTILIZE THE SITE OR THE SERVICES TO
                        REPLACE A HARD INQUIRY FOR THE PURPOSE OF OFFERING
                        CREDIT TO CONSUMERS.
                    </p>
                </li>
                <li>
                    <p>
                        Digital Deal Service: Upon approval by Octane in its
                        sole discretion, should Dealership wish to utilize the
                        Digital Deal service Dealership will be required to
                        abide by the terms set forth herein. For the purposes of
                        these Terms, the “Digital Deal Services” (i) is a
                        consumer consent-based business model; (ii) is a
                        consumer-initiated transaction, in which consumers
                        consent to have their credit report pulled to see what
                        credit options may be available to them from Dealership
                        (iii) is a solution where the consumer reviews the
                        credit options returned by Dealership and decides
                        whether or not to apply for such options; (iv) requires
                        that each Dealership’s permissible purpose is consumer’s
                        written instructions, and a soft consent credit inquiry
                        is posted on all files accessed; and (v) is different
                        from a prescreen process, and, therefore, no firm offer
                        of credit is involved.
                    </p>
                    <p>
                        The Digital Deal Services credit data pull shall be used
                        by Dealership on a per session basis and shall not be
                        used by such Dealership after such session ends,
                        provided, however, that Dealership shall have the right,
                        for a period of up to thirty (30) days after a Digital
                        Deal Services credit data pull, to remind a consumer via
                        email that credit options continue to be available for
                        such consumer, provided that the content of such email
                        specifically excludes any details on the credit options
                        and any Experian information, if any.
                    </p>
                    <p>
                        Dealership shall allow only consumer-supplied contact
                        data and other consumer-supplied data to be passed on to
                        lenders, and only after (i) consumer has provided
                        Dealership with consumer’s specific affirmative written
                        authorization (in a manner acceptable to Experian &
                        Octane), authorizing Dealership to forward such
                        consumer-supplied data, together with information
                        identifying whether such consumer passed Dealerships’
                        credit criteria, which may include certain screening
                        criteria provided by the lenders to Dealership, to the
                        lenders (which record of such written authorization must
                        be retained by Dealership in a form that is capable of
                        being accurately reproduced for later reference by the
                        parties) (“Consumer Authorization”); and (ii) applicable
                        credit options available through the Digital Deal
                        Services have been presented to the consumer for
                        consumer’s review.
                    </p>
                    <p>
                        Dealership shall not provide the credit report (in whole
                        or in part) obtained through Digital Deal Services, if
                        applicable, to a consumer or to any third party, and
                        shall not provide a score, decision or any other
                        information or indicator of the consumer’s credit
                        worthiness, derived in whole or in part from such credit
                        report, to the consumer or to any third party.
                        Dealership shall not make any credit decision, nor
                        provide FCRA-regulated pre-qualify services on behalf of
                        a third party. Dealership will refer all consumers who
                        have questions or who dispute consumer credit
                        information contained on their credit profile to
                        Experian. In no case will Dealership attempt to or hold
                        itself out to the consumer or to the public as being
                        able to handle disputes on behalf of Experian or to
                        reinvestigate Experian information.
                    </p>
                    <p>
                        Dealership hereby releases and holds harmless Octane and
                        Experian and their respective officers, directors,
                        employees, agents or affiliated companies, and any
                        third-party contractors or suppliers of Octane or
                        Experian from liability for any damages, losses, costs
                        or expenses, whether direct or indirect, suffered or
                        incurred by Dealership resulting from any failure of the
                        Digital Deal Services to accurately predict that a
                        consumer will repay their existing or future credit
                        obligations satisfactorily.
                    </p>
                </li>
                <li>
                    <p>
                        SafeCheck Service: Upon approval by Octane in its sole
                        discretion, should Dealership wish to utilize the
                        SafeCheck service, You will use the Experian product
                        information provided using the SafeCheck service and all
                        credit information therein for no other purpose other
                        than to evaluate the associated consumer for
                        pre-qualified offers of credit and for no other reason
                        in violation of the Customer Prequalification Service
                        Agreement entered into between the associated dealership
                        and Octane. You certify that you will not sell the
                        SafeCheck credit report data to any third party nor the
                        consumer. You acknowledge that if Your system is used
                        improperly by You or other personnel, or if Your user
                        credentials are made available to any unauthorized
                        personnel due to carelessness, the associated dealership
                        may be held responsible for any financial losses, fees,
                        or monetary charges that may be incurred and that access
                        privileges to the Site and/or the SafeCheck services may
                        be terminated. Any unauthorized access or use of the
                        SafeCheck services is prohibited and a violation of the
                        Fair Credit Reporting Act and applicable law.
                    </p>
                    <p>
                        YOU SHALL NOT DIRECTLY OR INDIRECTLY CHARGE AN CONSUMER
                        ANY FEES OR COSTS OR REQUIRE ANY PAYMENT FOR ANY
                        PRE-QUALIFICATION SERVICES OR OTHER INFORMATION DERIVED
                        FROM THE SAFECHECK CREDIT DATA. YOU SHALL NOT PROVIDE
                        ANY SAFECHECK CREDIT DATA (IN WHOLE OR IN PART) OBTAINED
                        THROUGH THE SAFECHECK SERVICES TO THE CONSUMER OR ANY
                        THIRD PARTIES INCLUDING BUT NOT LIMITED TO CREDIT SCORE
                        OR OTHER INFORMATION OR INDICATOR OF AN CONSUMER’S
                        CREDIT WORTHINESS.
                    </p>
                    <p>
                        By accessing the SafeCheck services, You further agree
                        that You shall not; a.) Operate as the agent of any
                        third party (Other than the associated Dealership); b.)
                        Offer depersonalized credit data for bid by third
                        parties; c.) Utilize pre-qualification services to
                        replace a hard inquiry for the purpose of approving
                        credit or any other option displayed to the consumer;
                        d.) Operate as a reseller of pre-qualification services
                        or provide any leads generated by pre-qualification
                        services to another entity; and e.) Make any credit
                        decision nor provide FCRA prescreen services on behalf
                        of a third party.
                    </p>
                    <p>
                        You further acknowledge that you have obtained the
                        written instructions and authorization from the
                        consumer, in compliance with the Fair Credit Reporting
                        Act and applicable law, prior to accessing the SafeCheck
                        services, the associated credit data and any
                        pre-qualification services.
                    </p>
                </li>
            </ol>
            <h3>12. Information Security</h3>
            <p>
                Dealership agrees to implement and maintain a comprehensive
                information security program that contains administrative,
                technical, and physical safeguards that protect login
                credentials and confidential consumer information in connection
                with the use of the Services or Documentation and access to the
                Site. Such safeguards shall include the elements set forth in 16
                C.F.R. § 314.4 and shall be reasonably designed to (i) insure
                the security and confidentiality of the information provided by
                Octane, (ii) protect against any anticipated threats or hazards
                to the security or integrity of such information, and (iii)
                protect against unauthorized access to or use of such
                information that could result in substantial harm or
                inconvenience to any consumer. Dealership acknowledges that if
                the Services, Documentation or the Site are used improperly by
                Dealership personnel, or if Dealership user credentials are made
                available to any unauthorized personnel due to carelessness on
                the part of any employee of Dealership, Dealership may be held
                responsible for financial losses, fees, or monetary charges that
                may be incurred and that access privileges to the Site may be
                terminated without prior notice.
            </p>
            <h3>13. Audit</h3>
            <p>
                In addition to any audit or review rights set forth in any
                agreement Dealership may have with Octane and Experian, Octane
                may review and audit Dealership’s access to and use of the Site
                and Services or any other prequalification service provided by
                Octane as a reseller of Experian data. Such audit rights
                include, without limitation, the right to review and audit
                Dealership’s terms and conditions of use applicable to the
                Services or any other prequalification service provided by
                Octane as a reseller of Experian data, as well as all consumer
                consents. In connection with any audits hereunder, Octane or
                Experian shall have the right, from time to time, to conduct
                audits by mail, email or similar electronic means that may
                require Dealership to provide documentation regarding compliance
                with the terms hereunder. Dealership gives its consent to Octane
                and Experian to conduct such audits and agrees that any failure
                to cooperate fully and promptly in the conduct of any audit, or
                Dealership’s breach of these Terms and constitutes grounds for
                immediate suspension of Service and/or access to the Site.
            </p>
            <h3>14. Termination</h3>
            <p>
                Octane may terminate these Terms and access to this Site in its
                sole discretion at any time with or without prior notice and
                your rights under these Terms will terminate concurrently.
            </p>
            <h3>15. Miscellaneous </h3>
            <ul>
                <li>
                    By using this Site, you agree that these Terms will be
                    governed by and construed in accordance with the laws of the
                    State of New York, without regard to its conflict of laws
                    rules.
                </li>
                <li>
                    These Terms constitute the entire agreement of you and the
                    parties with respect to the subject matter hereof and
                    supersede all previous written or oral agreements between
                    the parties with respect to such subject matter.
                </li>
                <li>
                    No full or partial waiver by any party of any breach or
                    default hereunder shall be deemed to be a full or partial
                    waiver of any preceding or subsequent breach or default nor
                    shall any delay in asserting a breach against party shall be
                    deemed a full or partial waiver thereof.
                </li>
                <li>
                    The section headings used herein are for convenience only
                    and shall not be given any legal effect.
                </li>
                <li>
                    IF FOR ANY REASON A DISPUTE ARISES BETWEEN THE PARTIES WHICH
                    RESULTS INDIRECTLY OR INDIRECTLY PURSUANT TO THESE TERMS AND
                    THE SUBJECT MATTER HEREIN, EACH PARTY WAIVES ANY RIGHT TO A
                    JURY TRIAL AND AGREES THAT ANY SUCH PROCEEDING SHALL BE
                    CONDUCTED ON AN INDIVIDUAL BASIS AND NOT IN A CLASS,
                    REPRESENTATIVE, CONSOLIDATED OR MASS ACTION.
                </li>
                <li>
                    You and Octane agree that the jurisdiction and venue shall
                    be vested exclusively in the state courts in New York
                    County, New York, or the U.S. District Court for the
                    Southern District of New York, Manhattan Division. You and
                    Octane waive all rights to assert the defense of
                    inconvenient forum to the maintenance of any action or
                    proceeding arising out of or related to these Terms and the
                    subject matter herein.
                </li>
                <li>
                    If any part of these Terms are found to be unenforceable,
                    the remainder of the Terms shall be given full force and
                    effect.
                </li>
            </ul>
        </>
    )
}
