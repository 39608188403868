import axios, { AxiosError } from 'axios'
import config from 'config'
import { ReturnCustomerSelectionsType } from 'containers/ReturnCustomer/types/returnCustomerTypes'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'
import { SubmitApplicationDTO } from './postApplication'

export interface UpdateClientApplication {
    home_phone: string
    mobile_phone: string
    residential_status: string
    residential_years: string
    residential_months: string
    monthly_housing_payment: string
    job_title: string
    employment_status: string
    employer_name: string
    employer_years: string
    employer_months: string
    employer_monthly_gross: string
    email_address: string

    application_type: string
    return_customer?: ReturnCustomerSelectionsType
    type?: string // Loan or Lease

    coapplicant_home_phone: string
    coapplicant_mobile_phone: string
    coapplicant_residential_status: string
    coapplicant_residential_years: string
    coapplicant_residential_months: string
    coapplicant_monthly_housing_payment: string
    coapplicant_job_title: string
    coapplicant_employment_status: string
    coapplicant_employer_name: string
    coapplicant_employer_years: string
    coapplicant_employer_months: string
    coapplicant_employer_monthly_gross: string
    coapplicant_email_address: string
}

export default async function patchApplication(
    params: UpdateClientApplication | SubmitApplicationDTO,
    uuid: string,
) {
    try {
        const { data } = await axios.patch(
            `${config.API_BASE_URL}/api/v3/client-application/${uuid}/`,
            params,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to update application: ${axiosError.message}`,
            axiosError,
            { applicationUuid: uuid },
        )

        throw new Error(axiosError.message)
    }
}
