import { FormikProps } from 'formik'
import * as Yup from 'yup'

import {
    FormValues,
    FieldKitErrors,
    FieldKit,
    FormKit,
    FieldComponent,
    ConfigFromFieldComponent,
    FieldInstructionBundle,
    FieldInstructionAny,
} from '../index'

const noOpFieldKit: FieldKit = {
    values: {},
    errors: {},
    touched: {},
    handleChange: () => undefined,
    handleBlur: () => undefined,
    setFieldValue: () => undefined,
    setFieldTouched: () => undefined,
    setFieldError: () => undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validateForm: () => undefined as any,
}

function fieldConfigFromFieldInstructionAny<C extends FieldComponent>(
    fieldInstructionAny: FieldInstructionAny,
    formValues: FormValues = {},
): ConfigFromFieldComponent<C> {
    if (typeof fieldInstructionAny === 'function') {
        return fieldInstructionAny(formValues, noOpFieldKit).config
    }

    return fieldInstructionAny.config
}

export function initialValuesFromFieldInstructionBundle(
    fieldsBundle: FieldInstructionBundle,
) {
    const allowFalsyInitialValueTypes = ['boolean', 'number']
    const fieldNames = Object.keys(fieldsBundle)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return fieldNames.reduce((acc: { [key: string]: any }, fieldName) => {
        const inputConfig = fieldsBundle[fieldName]
        const config = fieldConfigFromFieldInstructionAny(inputConfig)

        const value = allowFalsyInitialValueTypes.includes(
            typeof config.initialValue,
        )
            ? config.initialValue
            : config.initialValue || ''

        acc[fieldName] = value

        return acc
    }, {})
}

export function mapFormikPropsToFieldKit(
    formikProps: FormikProps<FormValues>,
): FieldKit {
    return {
        values: formikProps.values,
        errors: formikProps.errors as FieldKitErrors,
        touched: formikProps.touched,
        handleChange: formikProps.handleChange,
        handleBlur: formikProps.handleBlur,
        setFieldValue: formikProps.setFieldValue,
        setFieldTouched: formikProps.setFieldTouched,
        setFieldError: formikProps.setFieldError,
        validateForm: formikProps.validateForm,
    }
}

export function mapFormikPropsToFormKit(
    formikProps: FormikProps<FormValues>,
): FormKit {
    return {
        handleSubmit: formikProps.handleSubmit,
        validateForm: formikProps.validateForm,
        values: formikProps.values,
        errors: formikProps.errors as FieldKitErrors,
        touched: formikProps.touched,
        submitCount: formikProps.submitCount,
        isSubmitting: formikProps.isSubmitting,
        isValid: formikProps.isValid,
    }
}

export function validationSchemaFromFieldInstructionBundle(
    fieldInstructionBundle: FieldInstructionBundle,
) {
    return Yup.lazy((formValues: FormValues) => {
        return Yup.object().shape(
            Object.keys(fieldInstructionBundle).reduce(
                (acc: { [key: string]: Yup.AnySchema }, fieldName: string) => {
                    const config = fieldConfigFromFieldInstructionAny(
                        fieldInstructionBundle[fieldName],
                        formValues,
                    )

                    acc[fieldName] =
                        typeof config.validator === 'function'
                            ? config.validator(formValues)
                            : config.validator ?? Yup.mixed().optional()

                    return acc
                },
                {},
            ),
        )
    })
}

export const testExports = {
    fieldConfigFromFieldInstructionAny,
}
