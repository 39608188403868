import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Centered = styled.div`
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const AccountButton = styled.button`
    margin-top: 16px;
    color: ${(props) => props.theme.colors.brand.rideOctaneBlue};
    ${typography.Copy16}
`
export const BoldText = styled.div`
    ${typography.Copy16Bold}
    margin: 4px 0 20px 0;
`
