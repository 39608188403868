import React from 'react'

import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'
import { hardcodedMakes } from './HardcodedMakes'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
}

export default function MakeDropdown({ formik }: QueryProps) {
    const { marine } = formik.values
    const { make } = marine
    const options = hardcodedMakes

    return (
        <FormikDropdown
            name="marine.make"
            label="Hull Makes"
            placeholder="Select One"
            error={make === null}
        >
            {options.map((child) => {
                const newBorn: FormikDropdownChild = {
                    name: child,
                    value: child,
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}
