import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DropdownLink } from 'assets/styles/dropdown'
import useGoogleTagManager from 'hooks/useGoogleTagManager'

const defaultProps = {
    windowOpen: undefined,
    hashNavigate: undefined,
    iFrameNavigate: undefined,
    testId: undefined,
}

export default function TrackedDropdownLink({
    text,
    windowOpen,
    hashNavigate,
    iFrameNavigate,
    testId,
}: {
    text: string
    windowOpen?: string
    hashNavigate?: string
    iFrameNavigate?: string
    testId?: string
}) {
    const googleTagManager = useGoogleTagManager()
    const navigate = useNavigate()

    const handleClick = () => {
        if (hashNavigate) {
            navigate(hashNavigate)

            googleTagManager.trackNavbarEvent(text, window.location.href)
        }

        if (windowOpen) {
            window.open(windowOpen, '_blank')
            googleTagManager.trackNavbarEvent(text, windowOpen)
        }

        if (iFrameNavigate) {
            window.location.href = iFrameNavigate
            googleTagManager.trackNavbarEvent(text, window.location.href)
        }
    }

    return (
        <DropdownLink
            data-testid={testId}
            onClick={() => {
                handleClick()
            }}
        >
            {text}
        </DropdownLink>
    )
}

TrackedDropdownLink.defaultProps = defaultProps
