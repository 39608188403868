import React, { useEffect, useState } from 'react'

import FormSection from 'components/FormSection'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import { useVisibilityContext } from 'contexts/VisibilityContext'
import AssetType from 'state/enums/AssetType'
import VehicleType from 'state/enums/VehicleType'

import { CustomerAllowedState } from '../../api/application/getCustomersAllowedStates'
import StateEligibilityDropdown, {
    OptionStateNotListed,
} from './StateEligibilityDropdown'

interface BaseCustomerEligibilityProps {
    sectionWidth?: string
    sectionMargin?: string
    children?: (selectedState: string) => JSX.Element
    assetType: AssetType
    vehicleType?: VehicleType
    renderCustomerNotEligibleContent: () => React.ReactNode
    stateNotListedToolTipText: string
    wrapInFormSection?: boolean
    initialValue?: string
    onChange?: (value: string) => void
    prefilled?: boolean
}

const defaultProps = {
    sectionWidth: undefined,
    sectionMargin: undefined,
    children: undefined,
    vehicleType: undefined,
    wrapInFormSection: false,
    initialValue: undefined,
    onChange: undefined,
    prefilled: false,
}

function BaseCustomerEligibility(
    props: BaseCustomerEligibilityProps,
): JSX.Element | null {
    const {
        sectionWidth,
        sectionMargin,
        children,
        renderCustomerNotEligibleContent,
        assetType,
        vehicleType,
        stateNotListedToolTipText,
        wrapInFormSection,
        initialValue,
        onChange,
        prefilled,
    } = props

    const featureFlags = useFeatureFlagContext()
    const [selectedState, setSelectedState] = useState<string>('')
    const [copiedState, setCopiedState] = useState<string>('')
    const usableState = selectedState || copiedState

    const [availableStates, setAvailableStates] = useState<
        CustomerAllowedState[]
    >([])

    const visibilityContext = useVisibilityContext()
    const isAutomobile = assetType === AssetType.AUTOMOBILE

    const customerEligibilityEnabledForAutoApps =
        featureFlags?.customerEligibilityEnabledForAutoApps

    const triggerOnChange = (value: string) => {
        if (!onChange) {
            return
        }

        if (value === OptionStateNotListed.value) {
            onChange('')
        } else {
            onChange(value)
        }
    }

    useEffect(() => {
        if (
            visibilityContext &&
            isAutomobile &&
            !customerEligibilityEnabledForAutoApps
        )
            visibilityContext?.updateCondition('eligibleStatesLoaded', true)
    }, [visibilityContext, isAutomobile, customerEligibilityEnabledForAutoApps])

    useEffect(() => {
        if (
            initialValue &&
            availableStates.find(
                (event: CustomerAllowedState) => event.value === initialValue,
            )
        ) {
            setCopiedState(initialValue)
            triggerOnChange(initialValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, availableStates])

    if (isAutomobile && !customerEligibilityEnabledForAutoApps) {
        return children ? children('') : null
    }

    const renderDropdown = () => (
        <>
            <StateEligibilityDropdown
                name="stateEligibility"
                assetType={assetType}
                vehicleType={vehicleType}
                // eslint-disable-next-line max-len
                stateNotListedToolTipText={stateNotListedToolTipText}
                onChange={(value: string) => {
                    setSelectedState(value)
                    triggerOnChange(value)
                }}
                value={usableState}
                setAvailableParentStates={setAvailableStates}
                prefilled={prefilled}
            />
            {usableState === OptionStateNotListed.value &&
                renderCustomerNotEligibleContent()}
        </>
    )

    return (
        <>
            {wrapInFormSection ? (
                <FormSection
                    title="Customer Eligibility"
                    width={sectionWidth}
                    margin={sectionMargin}
                >
                    {renderDropdown()}
                </FormSection>
            ) : (
                renderDropdown()
            )}

            {usableState &&
                usableState !== OptionStateNotListed.value &&
                children &&
                children(usableState)}
        </>
    )
}

BaseCustomerEligibility.defaultProps = defaultProps

export default BaseCustomerEligibility
