import { theme } from '@octane/spark'
import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    height: 90vh;
`
export const FullContainer = styled.div`
    max-width: 1327px;
    width: 1327px;
`
export const Centered = styled.div`
    text-align: center;
    svg {
        margin: 0 auto;
    }
`
export const Content = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 1327px;
    width: 1327px;
    height: 700px;
`

const Column = styled.div`
    padding: 0 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

export const Left = styled(Column)`
    background: linear-gradient(82.57deg, #0b2b65 0%, #2366b0 100%);
    height: 100%;
    justify-content: center;
`

export const LeftLight = styled(Left)`
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(82.57deg, #d3e7ff 0%, #f3f8fe 100%);
`

export const Right = styled(Column)`
    height: 100%;
    background-color: ${(props) => props.theme.colors.monochrome.white};
`

export const Title = styled.h1`
    ${typography.Hero36};
    color: ${theme.colors.monochrome.asphalt};
`
export const Divider = styled.div`
    height: 1px;
    width: 100%;
    margin-top: 30px;
    background-color: ${theme.colors.monochrome.smoke};
`

export const SubTitle = styled.h1`
    ${typography.Heading24Bold}
    margin-bottom: 0;
`

export const Text = styled.div`
    ${typography.Copy16}
    margin: 4px 0 20px 0;
`
