import styled from 'styled-components'

export const Form = styled.div`
    text-align: center;
    svg {
        display: inline-block;
    }
`

export const Field = styled.div`
    margin: 20px 0;
`
