import React from 'react'

import { Checkbox } from '@octane/spark'
import { toggleNotification } from 'api/notifications'
import toastConsts from 'utils/consts/toast'
import removeAllSpaces from 'utils/removeAllSpaces'
import * as toast from 'utils/toast'

import {
    DefaultEmailText,
    NotificationDefaultEmailContainer,
} from './NotificationDefaultEmail.styled'

export interface NotificationDefaultEmailProps {
    name: string
    email: string
    status: boolean
    callback: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default function NotificationDefaultEmail({
    name,
    email,
    status,
    callback,
}: NotificationDefaultEmailProps) {
    const checkBoxId = `${removeAllSpaces(name)}DefaultEmailCheckbox`

    const handleChange = async () => {
        try {
            await toggleNotification(name, status)

            toast.success({
                message: toastConsts.notifications.preferences.updated,
            })

            await callback()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            toast.error({
                message: error.message,
            })
        }
    }

    return (
        <NotificationDefaultEmailContainer>
            <Checkbox
                data-testid="default-email-checkbox"
                id={checkBoxId}
                value="what"
                checked={status}
                onChange={handleChange}
            />
            <DefaultEmailText htmlFor={checkBoxId}>{email}</DefaultEmailText>
        </NotificationDefaultEmailContainer>
    )
}
