import { theme, Button } from '@octane/spark'
import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 116px;
    height: 100vh;
`

export const Avatar = styled.div`
    font-size: 64px;
`

const Title = styled.h1`
    ${typography.Hero48}
    color: ${theme.colors.brand.octaneIce};
    margin: 0;
`

export const WelcomeMessage = styled(Title)`
    margin-top: 90px;
`

export const UserName = styled(Title)``

export const SaveButton = styled(Button)`
    margin-top: 20px;
`
export const CheckboxWrapper = styled.div`
    margin-top: 20px;
`
export const Center = styled.div`
    text-align: center;
    div[speed] {
        margin: 0 auto;
        margin-top: 2em;
        margin-bottom: 2em;
    }
`
