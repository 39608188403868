import React, { forwardRef, useImperativeHandle, useState } from 'react'

import ErrorCard from 'components/error/ErrorCard'
import SizedLoadingSpinner from 'components/LoadingSpinner/SizedLoadingSpinner'
import Modal from 'components/Modal'
import { getLeadDetails } from 'pages/LeadsPage/utils'
import LoadingStatus from 'state/enums/LoadingStatus'

import { pushLeadModalClosed } from '../../../../utils/gtm-utils'
import { LeadDetail } from '../../types'
import LeadQuickView from './LeadQuickView'

export type LeadQuickViewModalRef = {
    showLead: (leadId: number) => Promise<LeadDetail>
}

// This empty prop is required because the forwardRef obligues the component
// to have a prop variable in the constructor, even if it is a empty one
// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {}

function LeadQuickViewModal(
    _: Props,
    ref?: React.Ref<LeadQuickViewModalRef>,
): JSX.Element | null {
    const [lead, setLead] = useState<LeadDetail | null>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
        LoadingStatus.IDLE,
    )

    const showLead = async (leadId: number) => {
        if (lead && lead.id === leadId) {
            setLoadingStatus(LoadingStatus.LOADED)
            setIsOpen(true)

            return lead
        }

        setLoadingStatus(LoadingStatus.LOADING)
        setLead(null)
        setError(null)
        setIsOpen(true)

        let leadDetails: LeadDetail

        try {
            leadDetails = await getLeadDetails(leadId)
        } catch (errorThrown) {
            setLead(null)
            setError(errorThrown as Error)
            setLoadingStatus(LoadingStatus.FAILED)
            throw errorThrown
        }

        setLead(leadDetails)
        setLoadingStatus(LoadingStatus.LOADED)

        return leadDetails
    }

    useImperativeHandle(ref, () => ({
        showLead,
    }))

    const showLeadQuickView = () => {
        if (!lead && error) return <ErrorCard />

        if (lead) return <LeadQuickView lead={lead} />

        return null
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false)
                setLoadingStatus(LoadingStatus.IDLE)
                if (lead) pushLeadModalClosed(lead.id)
            }}
            showCloseButton={loadingStatus !== LoadingStatus.LOADING}
        >
            {showLeadQuickView()}

            {loadingStatus === LoadingStatus.LOADING && (
                <SizedLoadingSpinner
                    width="900px"
                    height="300px"
                    text={"Just a second, we're loading your lead..."}
                    backgroundColor="white"
                />
            )}
        </Modal>
    )
}

export default forwardRef<LeadQuickViewModalRef, Props>(LeadQuickViewModal)
