import { combineReducers } from 'redux'

import dashboard from './dashboard'
import dealership from './dealership'
import iframe from './iframe'
import invitation from './invitation'
import user from './user'

const reducer = combineReducers({
    user,
    dashboard,
    dealership,
    invitation,
    iframe,
})

export default reducer
