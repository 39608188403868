import config from 'config'
import IntercomChat from 'integrations/intercom/IntercomChat'

const intercomChat = new IntercomChat({
    appId: config.INTERCOM_APP_ID,
})

export default function useIntercomChat() {
    return intercomChat
}
