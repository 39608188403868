import styled from 'styled-components'

import { typography } from '../../../assets/styles'

interface StyleProps {
    active: boolean | undefined
}

export const CardContent = styled.div<StyleProps>`
    display: flex;
    align-content: center;
    justify-content: center;
    padding: ${(props) => (props.active ? '0' : '60px 0 0 0')};
`

export const LoaderWrapper = styled.div`
    max-width: 460px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

export const Loader = styled.div`
    align-content: center;
    margin-bottom: 30px;
`

export const Title = styled.h1`
    ${typography.Heading24Bold};
    color: ${({ theme }) => theme.colors.monochrome.jetlineBlack};
    padding: 0;
    margin: 0 0 0 0;
    align-self: stretch;
    text-align: center;
`
export const Content = styled.p`
    ${typography.BodyCopy16};
    color: ${({ theme }) => theme.colors.monochrome.ravenSub};
    text-align: center;
    margin-top: 10px;
`

export const FadeWrapperDiv = styled.div<{
    fadeOut?: boolean
}>`
    transition: opacity 1.5s ease-in-out;
    opacity: ${(props) => (props.fadeOut ? 0 : 1)};
`
