import { createAsyncThunk } from '@reduxjs/toolkit'
import { getLoggedInUser } from 'api/user'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let loadingPromise: Promise<any> | undefined

const loadUser = createAsyncThunk('user/loadUser', async () => {
    /**
     * If a loading promise already exists, return it instead
     * of initiating another API request.
     */
    if (loadingPromise) {
        return loadingPromise
    }

    loadingPromise = getLoggedInUser()?.finally(() => {
        loadingPromise = undefined
    })

    return loadingPromise
})

export default loadUser
