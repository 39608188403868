import { useEffect, useState } from 'react'

import { loadStats } from 'state/dashboard/actions'
import LoadingStatus from 'state/enums/LoadingStatus'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useUser from './useUser'

const useDashboard = () => {
    const dispatch = useAppDispatch()
    const userInterface = useUser()
    const status = useAppSelector((state) => state.dashboard.status)
    const [isLoaded, setIsLoaded] = useState(false)
    const hasDashboard = useAppSelector((state) => state.dashboard.hasDashboard)

    useEffect(() => {
        if (hasDashboard) return

        if (
            !isLoaded &&
            userInterface.user?.uuid &&
            status === LoadingStatus.IDLE
        ) {
            dispatch(loadStats(userInterface))
            setIsLoaded(true)
        }
        // eslint-disable-next-line max-len
    }, [userInterface.user, status]) // eslint-disable-line react-hooks/exhaustive-deps

    return { hasDashboard, status }
}

export default useDashboard
