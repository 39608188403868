import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { Button } from '@octane/spark'

import { ButtonWrapper } from './ErrorTryAgain.styled'

type ErrorTryAgainProps = {
    waitRetry?: number
    navigateHistorySteps?: number
}

const defaultProps = {
    waitRetry: 10,
    navigateHistorySteps: 0,
}

export default function ErrorTryAgain({
    waitRetry = 5,
    navigateHistorySteps = 0,
}: ErrorTryAgainProps) {
    const navigate = useNavigate()
    const [waitSeconds, setWaitSeconds] = useState(waitRetry)

    useEffect(() => {
        if (waitSeconds > 0) {
            setTimeout(() => {
                setWaitSeconds(waitSeconds - 1)
            }, 1 * 1000)
        }
    }, [waitSeconds])

    const handleClickEvent = (event: SyntheticEvent) => {
        event.preventDefault()

        if (navigateHistorySteps === 0) {
            window.location.reload()
        } else {
            navigate(navigateHistorySteps)
        }
    }

    return (
        <ButtonWrapper>
            <Button
                tabIndex={0}
                onKeyDown={handleClickEvent}
                onClick={handleClickEvent}
                disabled={waitSeconds > 0}
            >
                Try Again{waitSeconds > 0 ? ` (${waitSeconds})` : ''}
            </Button>
        </ButtonWrapper>
    )
}

ErrorTryAgain.defaultProps = defaultProps
