import React, { useContext, useEffect, useState } from 'react'
import { ReactReduxContext } from 'react-redux'

import { Button, Modal, UiPlusIcon } from '@octane/spark'
import { TrailerCustomerEligibility } from 'components/CustomerEligibility'
import VehicleUsedInformation from 'containers/VehicleUsedInformation/VehicleUsedInformation'
import clearFormikFields from 'utils/clearFormikField'

import FormContent from '../../components/FormContent'
import resetTouchFormikFields from '../../utils/resetTouchedFormikField'
import TrailerWriteIn from '../TrailerWriteIn'
import ConditionTab from './ConditionTab'
import RemoveSecondaryVehicle from './RemoveSecondaryVehicle'
import ResetVehicleSelection from './ResetVehicleSelection'
import VehicleSelection from './VehicleSelection'
import {
    Vehicle1Banner,
    Vehicle2Banner,
    SeperationLine,
    ModalInformation,
    ModalButton,
    Content,
    LinkStyle,
} from './VehicleSelectionBase.styled'

interface vehicleSelectionProps {
    assetType?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    prefilled?: boolean
    vehicleTypeList?: []
}

const defaultProps = {
    assetType: null,
    prefilled: false,
    vehicleTypeList: null,
}

const eligibleVehicleTypesMultiUnit = ['snow', 'atv', 'utv', 'pwc']

function vehicleTypeIsMultiUnitEligible(vehicleType: string | undefined) {
    return eligibleVehicleTypesMultiUnit.includes(vehicleType ?? '')
}

const usedVehicleInformationFields = [
    'vehicle.primary.mileage',
    'vehicle.primary.noTitleReason',
    'vehicle.primary.noTitleOtherReason',
    'vehicle.secondary.mileage',
    'vehicle.secondary.noTitleReason',
    'vehicle.secondary.noTitleOtherReason',
]

const vehicleInformationFields = [
    'vehicle.primary.year',
    'vehicle.primary.modelId',
    'vehicle.primary.selectedVehicleId',
    'vehicle.makeId',
]

export default function VehicleSelectionBase({
    assetType,
    formik,
    prefilled,
    vehicleTypeList,
}: vehicleSelectionProps) {
    const { store } = useContext(ReactReduxContext)

    const [showMultiUnit, setShowMultiUnit] = useState(
        formik.values.vehicle?.multiUnit ?? false,
    )

    const [vehicleTypeEligibility, setVehicleTypeEligibility] = useState(
        vehicleTypeIsMultiUnitEligible(formik.values.vehicle?.vehicleType) ||
            false,
    )

    const [multiUnitEligibility, setMultiUnitEligibility] = useState(false)
    const [trailerEligibility, setTrailerEligibility] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [showTwoUnitTooltip, setShowTwoUnitTooltip] = useState(false)
    const isReadOnly = prefilled

    const dealerHasMultiUnitEligibility =
        store.getState().user.data?.dealership.hasMultiUnitEligibility !==
            undefined &&
        store.getState().user.data.dealership.hasMultiUnitEligibility

    const isConditionUsed = formik.values.vehicle.condition === 'used'
    const { isTrailer } = formik.values.vehicle

    useEffect(() => {
        if (dealerHasMultiUnitEligibility && vehicleTypeEligibility) {
            setMultiUnitEligibility(true)
        } else {
            formik.setFieldValue('vehicle.multiUnit', false)
            setMultiUnitEligibility(false)
            setShowMultiUnit(false)

            Object.keys(formik.values).forEach((key) => {
                if (key === 'vehicle.secondary') {
                    formik.setFieldValue(key, undefined)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.vehicle.vehicleType, vehicleTypeEligibility])

    useEffect(() => {
        formik.validateForm()

        if (isReadOnly && formik.values.vehicle?.multiUnit === true) {
            setShowMultiUnit(formik.values.vehicle?.multiUnit)
        }

        if (vehicleTypeIsMultiUnitEligible(formik.values.vehicle.vehicleType)) {
            setVehicleTypeEligibility(true)
            // setShowMultiUnit(false)
        } else {
            setVehicleTypeEligibility(false)
            if (
                dealerHasMultiUnitEligibility &&
                formik.values.vehicle.primary.selectedVehicleId !== '' &&
                formik.values.vehicle.primary.selectedVehicleId !== undefined
            ) {
                setShowTwoUnitTooltip(true)
            } else {
                setShowTwoUnitTooltip(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.vehicle.primary.selectedVehicleId])

    useEffect(() => {
        if (formik.values.vehicle.condition === 'new') {
            clearFormikFields(formik, usedVehicleInformationFields)
            formik.setFieldValue('vehicle.primary.hasTitle', false)
            formik.setFieldValue('vehicle.secondary.hasTitle', false)

            formik.validateForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.vehicle.condition])

    useEffect(() => {
        if (!isTrailer) {
            setTrailerEligibility(false)
        }
    }, [isTrailer])

    useEffect(() => {
        if (!isReadOnly) {
            clearFormikFields(formik, vehicleInformationFields)
            resetTouchFormikFields(formik, vehicleInformationFields)
            formik.setFieldValue('vehicle.primary.hasTitle', false)
            formik.setFieldValue('vehicle.secondary.hasTitle', false)
            formik.setFieldValue('vehicle.primary.condition', 'new')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleTypeList])

    return (
        <>
            <Modal
                title="Helpful Tips to Add a Second Vehicle"
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                scroll="body"
            >
                <Modal.Body>
                    <Content data-testid="modalContent">
                        <ol>
                            <li>
                                Supported vehicles types are <b>Snow</b>,{' '}
                                <b>ATV, UTV and PWC</b>
                            </li>
                            <li>
                                {/* eslint-disable-next-line max-len */}
                                The <b>condition, make</b> and{' '}
                                <b>vehicle type</b> of both vehicles must be
                                identical <br />
                                <span className="content_fade">
                                    Example: 2 new Sea-Doo personal watercraft
                                    vehicles. Note: ATVs and UTVs are the same
                                    type of vehicles.
                                </span>
                            </li>
                            <li>
                                The <b>models</b> for both vehicles can be
                                different. <br />
                                <span className="content_fade">
                                    Example: 1 new Sea-Doo Spark and a new
                                    Sea-Doo GTI
                                </span>
                            </li>
                            <li>
                                While two vehicles can be financed, only one
                                trailer is allowed to be added.
                            </li>
                        </ol>
                    </Content>
                </Modal.Body>
                <Modal.Footer>
                    <ModalInformation>
                        <p>
                            If the vehicle you’re looking for is not currently
                            listed, please send a request to{' '}
                            <a href="#/new-application">
                                help@roadrunnerfinancial
                            </a>
                        </p>
                    </ModalInformation>
                    <ModalButton>
                        <Button
                            type="button"
                            variant="primaryOutlined"
                            onClick={() => setIsOpenModal(false)}
                        >
                            Close
                        </Button>
                    </ModalButton>
                </Modal.Footer>
            </Modal>
            <FormContent gridTemplate="2fr .96fr">
                <ConditionTab prefilled={prefilled} />
            </FormContent>
            {showMultiUnit && (
                <Vehicle1Banner>
                    <span>vehicle 1</span>
                    {!isReadOnly && <ResetVehicleSelection formik={formik} />}
                </Vehicle1Banner>
            )}
            <VehicleSelection
                assetType={assetType}
                formik={formik}
                vehicleNumber={1}
                prefilled={prefilled}
                vehicleTypeList={vehicleTypeList}
            />

            {isTrailer && (
                <FormContent gridTemplate="2fr .96fr">
                    <TrailerCustomerEligibility
                        initialValue={formik.values.state}
                        onChange={(selectedState: string) => {
                            setTrailerEligibility(Boolean(selectedState))
                        }}
                        prefilled={prefilled}
                    />
                </FormContent>
            )}
            {isTrailer && trailerEligibility && (
                <TrailerWriteIn
                    formik={formik}
                    isPrimary
                    prefilled={prefilled}
                />
            )}

            {isConditionUsed && (!isTrailer || trailerEligibility) && (
                <VehicleUsedInformation
                    formik={formik}
                    isPrimary
                    prefilled={prefilled}
                />
            )}

            {showMultiUnit && (
                <>
                    <FormContent gridTemplate="1fr">
                        <SeperationLine />
                    </FormContent>
                    <Vehicle2Banner>
                        <div>
                            <span>vehicle 2</span>
                            {!isReadOnly && (
                                <RemoveSecondaryVehicle
                                    setMultiUnit={setShowMultiUnit}
                                    formik={formik}
                                />
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={() => setIsOpenModal(true)}
                        >
                            Not seeing your vehicle? Read about program
                            eligibility
                        </button>
                    </Vehicle2Banner>
                    <VehicleSelection
                        assetType={assetType}
                        formik={formik}
                        vehicleNumber={2}
                        prefilled={prefilled}
                    />
                    {isConditionUsed && (
                        <VehicleUsedInformation
                            formik={formik}
                            isPrimary={false}
                            prefilled={prefilled}
                        />
                    )}
                </>
            )}
            {!showMultiUnit && dealerHasMultiUnitEligibility && !isReadOnly && (
                <FormContent gridTemplate="1fr 2fr">
                    <Button
                        id="multipleVehiclesButton"
                        testID="multipleVehiclesButton"
                        startIcon={UiPlusIcon}
                        variant="secondary"
                        disabled={
                            !multiUnitEligibility ||
                            formik.values.vehicle.primary.selectedVehicleId ===
                                '' ||
                            formik.values.vehicle.primary.selectedVehicleId ===
                                undefined
                        }
                        onClick={() => {
                            setShowMultiUnit(true)
                            formik.setFieldValue('vehicle.multiUnit', true)
                        }}
                    >
                        Add A Second Vehicle
                    </Button>
                    {showTwoUnitTooltip && (
                        <LinkStyle
                            type="button"
                            onClick={() => setIsOpenModal(true)}
                        >
                            Two-unit applications are not available for this
                            vehicle type.
                        </LinkStyle>
                    )}
                </FormContent>
            )}
        </>
    )
}

VehicleSelectionBase.defaultProps = defaultProps
