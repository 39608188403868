/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import getMakes, { GetMakesOptions, Make } from 'api/vehicles/getMakes'
import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'
import { setFieldValueAndTouched } from 'form/fields/utils/fieldUtils'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

interface queryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    lenderId?: number
    disable?: boolean
    dataSource?: string[]
    assetType?: unknown
    primaryVehicleId?: number
    vehicleNumber: number
    prefilled?: boolean
    vehicleTypeList?: string[]
}

const defaultProps = {
    lenderId: null,
    disable: false,
    dataSource: null,
    assetType: null,
    primaryVehicleId: null,
    prefilled: false,
    vehicleTypeList: null,
}

export default function MakeDropdown({
    formik,
    lenderId,
    disable,
    dataSource,
    assetType,
    primaryVehicleId,
    vehicleNumber,
    prefilled,
    vehicleTypeList,
}: queryProps) {
    const [response, updateResponse] = useState([] as Make[])
    const { vehicle } = formik.values
    const { condition } = vehicle
    const FIRST_VEHICLE = 1
    const IS_FIRST_VEHICLE = vehicleNumber === FIRST_VEHICLE

    const year = IS_FIRST_VEHICLE
        ? vehicle.primary.year
        : vehicle.secondary.year

    const { makeId } = vehicle

    useEffect(() => {
        const abortController = new AbortController()

        if (condition && year) {
            const getMakesOptions = {
                condition,
                year,
                lenderId,
                disable,
                dataSource,
                assetType,
                primaryVehicleId,
                vehicleTypeList,
            } as GetMakesOptions

            if (!IS_FIRST_VEHICLE) {
                // eslint-disable-next-line prefer-destructuring
                getMakesOptions.primaryVehicleId =
                    formik.values.vehicle.primary.selectedVehicleId.split(
                        ':',
                    )[0]
            }

            updateResponse([])

            getMakes(getMakesOptions, abortController)
                .then((makes: Make[]) => {
                    updateResponse(makes)

                    const selectedMake = makes.find(
                        (make) => make.id.toString() === makeId,
                    )

                    if (IS_FIRST_VEHICLE) {
                        if (selectedMake) {
                            formik.setFieldValue(
                                'vehicle.isTrailer',
                                selectedMake.isTrailer,
                            )

                            formik.setFieldValue(
                                'vehicle.primary.make',
                                selectedMake.name,
                            )
                        } else {
                            formik.setFieldValue('vehicle.makeId', '')
                            formik.setFieldValue('vehicle.primary.make', '')
                            formik.setFieldValue('vehicle.primary.makeId', '')
                            formik.setFieldValue('vehicle.primary.modelId', '')
                            formik.setFieldValue('vehicle.vehicleType', '')
                            formik.setFieldValue('vehicle.isTrailer', '')

                            formik.setFieldValue(
                                'vehicle.primary.selectedVehicleId',
                                '',
                            )
                        }
                    }

                    if (makes.length === 1) {
                        setFieldValueAndTouched(
                            formik,
                            'vehicle.makeId',
                            makes[0].id.toString(),
                        )
                    }
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        OctaneLogger.error(
                            `Unexpected error when trying to load makes.`,
                            undefined,
                            error as Error,
                        )
                    }
                })
        }

        return () => abortController.abort()
    }, [condition, year])

    useEffect(() => {
        const selectedMake = response.find(
            (make) => make.id.toString() === formik.values.vehicle.makeId,
        )

        if (selectedMake) {
            formik.setFieldValue('vehicle.primary.make', selectedMake.name)
            formik.setFieldValue('vehicle.isTrailer', selectedMake.isTrailer)
        }
    }, [formik.values.vehicle.makeId])

    if (!prefilled && (!year || year === '0') && response.length < 1) {
        return (
            <FormikDropdown
                name="vehicle.makeId"
                label="Make"
                placeholder="Select Make"
                disabled
            >
                {{ name: '', value: '' }}
                {{ name: '', value: '' }}
            </FormikDropdown>
        )
    }

    return (
        <FormikDropdown
            name="vehicle.makeId"
            label="Make"
            placeholder={prefilled ? '' : 'Select Make'}
            disabled={IS_FIRST_VEHICLE ? response.length === 0 : true}
            error={IS_FIRST_VEHICLE ? makeId === null || makeId === '' : false}
            searchable
            searchPlaceholder="Search makes..."
            prefilled={prefilled}
        >
            {response.map((child: Make) => {
                const newBorn: FormikDropdownChild = {
                    name: child.name,
                    value: child.id.toString(),
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}

MakeDropdown.defaultProps = defaultProps
