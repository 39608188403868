import styled from 'styled-components'

export const Container = styled.div`
    input:disabled {
        color: ${(props) => props.theme.colors.monochrome.asphalt};
        background-color: ${(props) => props.theme.colors.monochrome.moonGrey};
        border: none;
    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;

    @media (max-width: 1100px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-row-gap: 40px;
    }
`
