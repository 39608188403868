import axios from 'axios'
import config from 'config'

import convertBase64ToBlob from './convertBase64ToBlob'
import extractAttributeFromJson from './extractAttributeFromJson'

export type ResponseType = 'jsonWithBase64Attribute' | 'binary'

export interface DownloadOptions {
    jsonPath?: string
    contentType?: string
}

export default async function downloadFileFromUrl(
    path: string,
    responseType: ResponseType,
    abortController: AbortController,
    options?: DownloadOptions,
): Promise<Blob> {
    const fullUrl = `${config.API_BASE_URL}${path}`

    const response = await axios.get(fullUrl, {
        signal: abortController.signal,
        responseType: responseType === 'binary' ? 'blob' : 'json',
        withCredentials: true,
    })

    if (response.status !== 200) {
        throw new Error(`Erro on downloading the file from: ${fullUrl}`)
    }

    if (responseType === 'binary') {
        return response.data
    }

    if (!options?.jsonPath)
        throw new Error(
            'The path to attribute that contains the Blob is required',
        )

    const dataFromJson = extractAttributeFromJson(
        response.data,
        options.jsonPath,
    ) as string

    return convertBase64ToBlob(dataFromJson, options?.contentType ?? '')
}
