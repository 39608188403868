import React, { useState } from 'react'

import { Modal, Button, CepWarningIcon } from '@octane/spark'
import { deleteDealerAssignedLink } from 'api/flexLinks/'
import useAppDispatch from 'hooks/useAppDispatch'
import { loadFlexLinks } from 'state/dealership/actions'
import toastConsts from 'utils/consts/toast'
import * as toast from 'utils/toast'

import { Form, Field } from './FlexLinkDeleteModal.styled'

type FlexLinkDeleteModalProps = {
    isOpen: boolean
    onModalClose: () => void
    linkId: number | undefined
}

function FlexLinkDeleteModal({
    isOpen,
    onModalClose,
    linkId,
}: FlexLinkDeleteModalProps): JSX.Element {
    const {
        dealershipSettings: {
            manageAssignedFlexLinks: manageAssignedFlexLinksConsts,
        },
    } = toastConsts

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)

    function confirmFlexLinkDelete() {
        if (linkId) {
            setLoading(true)

            deleteDealerAssignedLink(linkId)
                .then(() => {
                    toast.success({
                        message:
                            manageAssignedFlexLinksConsts.deleteAssignedFlexLink
                                .success.message,
                    })

                    dispatch(loadFlexLinks())
                    setLoading(false)
                    onModalClose()
                })
                .catch(({ response }) => {
                    const responseMessage = response?.data?.detail

                    toast.error({
                        title: manageAssignedFlexLinksConsts
                            .deleteAssignedFlexLink.error.title,
                        message:
                            responseMessage ||
                            manageAssignedFlexLinksConsts.deleteAssignedFlexLink
                                .error.message,
                    })

                    setLoading(false)
                })
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onModalClose} scroll="paper">
            <Modal.Body>
                <Form>
                    <CepWarningIcon width={140} height={140} color="" />
                    <h2>Are you sure you want to delete this link?</h2>
                    <Field>
                        <p>
                            Once you delete this link, the salesperson will no
                            longer receive Lead Emails. Any new applications
                            through this deleted link will be unassigned.
                        </p>
                    </Field>
                    <Field>
                        <Button
                            fullWidth
                            onClick={() => confirmFlexLinkDelete()}
                            loading={loading}
                        >
                            Yes, delete this link
                        </Button>
                        <Button
                            fullWidth
                            variant="white"
                            onClick={() => onModalClose()}
                        >
                            No, don’t delete it
                        </Button>
                    </Field>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default FlexLinkDeleteModal
