import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export type Disclosure = {
    id: number
    lender: number
    name: string
    text: string
}

type Response = Disclosure[]

export default async function getLenderDisclosures(
    lenderId: number,
    applicationId?: number,
) {
    const applicationQueryString = applicationId
        ? `?application=${applicationId}`
        : ''

    try {
        const response = await axios.get<Response>(
            // eslint-disable-next-line max-len
            `${config.API_BASE_URL}/api/v3/lenders/${lenderId}/disclosures/${applicationQueryString}`,
            {
                withCredentials: true,
            },
        )

        return response
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get lender disclosures: ${axiosError.message}`,
            axiosError,
            {
                lenderId,
                applicationId,
            },
        )

        throw new Error(axiosError.message)
    }
}
