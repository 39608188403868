import React, { useState } from 'react'

import { UiDeleteIcon, UiEditIcon } from '@octane/spark'
import RowActionButton from 'components/Table/RowActionButton'
import { ActionsContainer } from 'components/Table/Table.styled'
import UserDeleteModal from 'components/UserDeleteModal'
import UserPermissionsModal from 'components/UserPermissionsModal'
import useUser from 'hooks/useUser'
import { DealershipUser } from 'state/dealership/DealershipState'

type RowActionsProps = {
    value: DealershipUser
}

export default function RowActions({ value }: RowActionsProps) {
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isEditModalOpen, setEditModalOpen] = useState(false)
    const { user } = useUser()

    function handleDelete() {
        setDeleteModalOpen(true)
    }

    function handleEdit() {
        setEditModalOpen(true)
    }

    return (
        <ActionsContainer>
            <RowActionButton
                onClick={() => handleEdit()}
                title="Edit User"
                icon={UiEditIcon}
            />
            {isEditModalOpen && (
                <UserPermissionsModal
                    onClose={() => setEditModalOpen(false)}
                    user={value}
                />
            )}
            {user?.email !== value.emailAddress && (
                <RowActionButton
                    onClick={() => handleDelete()}
                    title="Delete User"
                    icon={UiDeleteIcon}
                />
            )}
            {isDeleteModalOpen && (
                <UserDeleteModal
                    isOpen
                    onModalClose={() => setDeleteModalOpen(false)}
                    userId={value.id}
                    levelOfAccess={value.levelOfAccess}
                />
            )}
        </ActionsContainer>
    )
}
