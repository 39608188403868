import { DefaultTheme } from 'styled-components'

export enum Brand {
    Octane,
    GoodSam,
    RideNow,
}

export type BrandConfig = {
    brand: Brand
    name: string
    theme: DefaultTheme
    logo?: string
    navbarLogo?: string
    hideNavbarAtLogin: boolean
    loginHeaderTitle: string
    loginLogoWidth?: number
    whitelabelId?: string
    footerContent?: JSX.Element
    hostRegex?: RegExp
    faviconPath?: string
    pageTitle?: string
}
