import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateSecuritySettings as updateSecuritySettingsApi } from 'api/dealership'
import { DealershipSecuritySettings } from 'state/dealership/DealershipState'

interface ParamsTypes {
    securitySettingsId: number
    payload: DealershipSecuritySettings
}

const updateSecuritySettings = createAsyncThunk(
    'dealership/updateSecuritySettings',
    async ({ securitySettingsId, payload }: ParamsTypes) => {
        return updateSecuritySettingsApi(securitySettingsId, payload)
    },
)

export default updateSecuritySettings
