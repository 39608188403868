import React, { useState, useRef, useEffect } from 'react'

import useUser from 'hooks/useUser'
import LoadingStatus from 'state/enums/LoadingStatus'
import { pushPageView } from 'utils/gtm-utils'

import { StyledCard, Title, Titlebar } from './LeadsPage.styled'
import LeadQuickViewModal from './partials/LeadQuickViewModal'
import { LeadQuickViewModalRef } from './partials/LeadQuickViewModal/LeadQuickViewModal'
import LeadsFilter from './partials/LeadsFilter'
import LeadsSearch from './partials/LeadsSearch'
import { CtaActions } from './partials/LeadsSearchTable/CtaButtons'
import LeadsSearchTable, {
    LeadsSearchTableRef,
} from './partials/LeadsSearchTable/LeadsSearchTable'
import { getLeadDetails } from './utils'

export const LEADS_PAGE_ROUTE_PATH = 'leads'

export default function LeadsPage(): JSX.Element {
    const [prequalifiedChecked, setPrequalifiedChecked] = useState(false)
    const [declinedChecked, setDeclinedChecked] = useState(false)
    const [search, setSearch] = useState('')

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
        LoadingStatus.IDLE,
    )

    const leadQuickViewModalRef = useRef<LeadQuickViewModalRef>(null)
    const { user } = useUser()
    const leadsSearchTableRef = useRef<LeadsSearchTableRef>(null)

    const ctaButtonClickedHandler = async (
        action: CtaActions,
        leadId: number,
    ) => {
        let viewedLead = null

        if (action === 'viewLead' && leadQuickViewModalRef?.current) {
            viewedLead = await leadQuickViewModalRef.current.showLead(leadId)
        } else {
            viewedLead = await getLeadDetails(leadId)
        }

        if (leadsSearchTableRef?.current) {
            leadsSearchTableRef.current.markLeadAsViewed(viewedLead)
        }
    }

    useEffect(() => {
        pushPageView(user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Titlebar>
                <Title>Consumer Leads</Title>
                <LeadsSearch
                    onSearch={(value: string) => {
                        setSearch(value)
                    }}
                />
                <LeadsFilter
                    prequalifiedChecked={prequalifiedChecked}
                    declinedChecked={declinedChecked}
                    onChangePrequalified={setPrequalifiedChecked}
                    onChangeDeclined={setDeclinedChecked}
                />
            </Titlebar>
            <StyledCard>
                <LeadsSearchTable
                    ref={leadsSearchTableRef}
                    cbCtaClicked={ctaButtonClickedHandler}
                    prequalifiedChecked={prequalifiedChecked}
                    declinedChecked={declinedChecked}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    search={search}
                />
            </StyledCard>
            <LeadQuickViewModal ref={leadQuickViewModalRef} />
        </>
    )
}
