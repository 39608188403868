import axios, { AxiosError } from 'axios'
import config from 'config'
import { ActiveLoanType } from 'containers/ReturnCustomer/types/returnCustomerTypes'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface GetAllActiveLoansRequestDTO {
    id: number
    application_type: string
    social_security_number: string
    coapplicant_social_security_number: string
    parent_id?: number
}

interface GetAllActiveLoansResponseDTO {
    active_loans: GetAllActiveLoansItemDTO[]
}

interface GetAllActiveLoansItemDTO {
    loan_id: string
    collaterals: string[]
}

export default async function getAllActiveLoans(
    applicantInfo: GetAllActiveLoansRequestDTO,
): Promise<ActiveLoanType[]> {
    try {
        const { data }: { data: GetAllActiveLoansResponseDTO } =
            await axios.post(
                `${config.API_BASE_URL}/api/v3/return-customer/get_all_active_loans/`,
                applicantInfo,
                {
                    withCredentials: true,
                },
            )

        return (
            data?.active_loans?.map(
                (loan) =>
                    <ActiveLoanType>{
                        loanId: loan.loan_id,
                        collaterals: loan.collaterals,
                    },
            ) || []
        )
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get all active loans: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
