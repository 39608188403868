export function formatCurrency(number?: number | null) {
    if (!number) {
        return '$0.00'
    }

    return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)
}
