import styled from 'styled-components'

export const Container = styled.div`
    width: 1100px;
    margin: 0 auto;
    padding: 30px 0;
`
export const InnerContainer = styled.div`
    width: 600px;
    margin: 0 auto;
`
export const Title = styled.h1`
    margin-top: 30px;
    margin-bottom: 10px;
`
export const ConfirmationContainer = styled.div`
    display: flex;
    height: 380px;
    align-items: center;
    justify-content: center;
    .icon {
        margin-right: 20px;
    }
    p {
        margin-top: 20px;
        width: 700px;
    }
    a {
        color: ${(props) => props.theme.colors.brand.rideOctaneBlue};
        font-weight: bold;
    }
`
