import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import AsideMenu from 'components/AsideMenu'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useLiveChat from 'hooks/useLiveChat'
import useUser from 'hooks/useUser'

import AssignFlexLinks from './AssignFlexLinks'
import { Title, Divisor, Container, OutletContainer } from './Dealership.styled'
import ManageUsers from './ManageUsers'
import SecuritySettings from './SecuritySettings'

export const NestedDealershipRoutes = [
    {
        index: true,
        element: <SecuritySettings />,
        path: 'manage-dealership',
        label: 'Manage Dealership',
    },
    {
        index: false,
        element: <ManageUsers />,
        path: 'manage-users',
        label: 'Manage Users',
    },
    {
        index: false,
        element: <AssignFlexLinks />,
        path: 'assign-flex-links',
        label: 'Assign Flex Links',
    },
]

export default function Dealership(): JSX.Element {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const defaultRoute = 'manage-dealership'
    const featureFlags = useFeatureFlagContext()
    const { isPowersportsDealership } = useUser()

    const showSalesPersonFlexLinks =
        featureFlags?.salesPersonFlexLink && isPowersportsDealership

    useLiveChat()

    useEffect(() => {
        const baseRoutes = ['/dealership-settings', '/dealership-settings/']

        if (baseRoutes.indexOf(pathname) !== -1) {
            navigate(defaultRoute)
        }
    }, [pathname, navigate])

    return (
        <>
            <Title data-testid="dealership-title">Dealership Settings</Title>
            <Divisor />
            <Container>
                <AsideMenu
                    showAvatar
                    basePath="/dealership-settings"
                    nestedRoutes={NestedDealershipRoutes}
                    showAssignFlexLinks={showSalesPersonFlexLinks}
                />
                <TransitionGroup component={OutletContainer}>
                    <CSSTransition
                        key={pathname}
                        classNames="fade"
                        timeout={300}
                    >
                        <Outlet />
                    </CSSTransition>
                </TransitionGroup>
            </Container>
        </>
    )
}
