import React from 'react'

import { FieldInstruction } from 'containers/FormBuilder'
import InputFieldMasked from 'form/fieldComponents/InputFieldMasked'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import * as Yup from 'yup'

export const phoneNumberField: FieldInstruction<typeof InputFieldMasked> = {
    Component: InputFieldMasked,
    config: {
        label: 'Phone Number',
        mask: '000-000-0000',
        placeholder: 'xxx-xxx-xxxx',
        info: <small>Please enter a valid US phone number</small>,
        validator: Yup.string()
            .required(FIELD_REQUIRED)
            .min(12, 'Phone number needs to be 10 digits exactly.')
            .max(12, 'Phone number needs to be 10 digits exactly.'),
    },
}
