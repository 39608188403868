import React, { useCallback, useContext } from 'react'

import { Button, UiCloseIcon } from '@octane/spark'
import { DocumentViewerContext } from 'containers/DocumentViewerManager/DocumentViewerManager'

export interface Props {
    onErrorCb?: (error: Error) => void
}

export default function CloseButton({ onErrorCb }: Props) {
    const fileViewerContext = useContext(DocumentViewerContext)

    const cbClickCloseBtn = useCallback(() => {
        if (fileViewerContext) fileViewerContext.close()
        else {
            const error = new Error('FileViewerContext not found.')

            if (onErrorCb) onErrorCb(error)
        }
    }, [fileViewerContext, onErrorCb])

    return (
        <Button startIcon={UiCloseIcon} onClick={cbClickCloseBtn}>
            Close
        </Button>
    )
}

CloseButton.defaultProps = {
    onErrorCb: () => undefined,
}
