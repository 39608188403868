import React from 'react'

import { FormikAmountField } from '../../../components/FormikFields'
import { Row } from '../MarineWriteIn.styled'
import { MotorTab } from './index'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
}

export default function RepowerWriteIn({ formik }: Readonly<QueryProps>) {
    return (
        <>
            <Row gridTemplateColumns="1fr">
                <MotorTab formik={formik} />
            </Row>
            <Row gridTemplateColumns="1fr 2fr">
                <FormikAmountField
                    name="marine.invoice"
                    label="Total Unit Value"
                    helpText="Sum of all items"
                />
            </Row>
        </>
    )
}
