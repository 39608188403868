const featureFlags = {
    shortApp: 'SWED-8251-add-shortened-application-feature-flag',
    newPowersportsApp: 'BLUR-486-enable-new-powersports-application',
    decisionPage2Powersports: 'DP-136-decision-page-2-0-for-powersports',
    selfServiceManagement: 'CSP-1740-add-self-service-management-feature-flag',
    leadsPage: 'ROGUE-270-enable-leads-page-for-dealer-portal',
    webAppLoyaltyDashboard:
        'CSP-1875-migrate-loyalty-dashboard-from-old-to-new-dealer-portal',
    customerEligibilityEnabledForAutoApps:
        'SHOCK-67-enable-state-eligibility-for-automobile-applications',
    newWtdPath: 'ABBA-1454-determine-wtd-path',
    flatCancel: 'ZEN-619-flat-cancel-button-feature-flags',
    documentViewerForStips:
        'SHOCK-247-enable-document-viewer-for-stips-on-dealer-portal',
    dealTermsTableFiltering: 'BLUR-459-add-filters-to-available-deal-terms',
    oemRebatePowerSports: 'ABBA-3007-enable-oem-rebate',
    dealTermsTable:
        'ABBA-1974-show_new_terms_table_in_work_the_deal_for_powersport_and_rv',
    dealTermsTableExtendedLogging:
        'ABBA-3112-enable-deal-terms-table-extended-logging',
    showNewSafeCheckPage: 'ABBA-2032-new-safecheck-report-page',
    showFlexLink: 'DITTO-690-show-flex-link-homepage',
    tradeInAdjustment: 'LP-16-enable-trade-in-adjustment',
    returnCustomerPsApp2: 'FIKA-2643-return-customer-ps-app-2-0',
    dealershipLenderAgreementValidation:
        'FIKA-3041-dealership-lender-agreement-submission-validation',
    sweepstakesTheme: 'FIKA-3055-sweepstakes-theme-for-dealer-portal',
    whiteLabelRedirect:
        'SHOCK-796-redirect-dealers-to-white-labelled-dealer-portal',
    salesPersonFlexLink: 'DITTO-943-enable-salesperson-flex-link-dealer-portal',
    routePsAppToNewReadOnlyPage: 'FIKA-3222-dealer-ps-app-read-only-page',
    showRePowerTile: 'BLUR-658-show-repower-tile',
}

export default featureFlags
