import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px;
`

export const Title = styled.div`
    margin-top: 15px;
    ${typography.Heading24Bold};
`

export const Description = styled.div`
    margin-top: 16px;
    text-align: justify;
    ${typography.BodyCopy16};
`

export const CancelButton = styled.button`
    ${typography.BodyCopy16};
    color: ${({ theme }) => theme.colors.brand.rideOctaneBlue};
    text-decoration: underline;
    width: 100%;
    margin-top: 20px;
`
