import { useEffect, useRef, useState } from 'react'
import type { PDFPageProxy } from 'react-pdf'

import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'

const IMAGE_MARGIN = 5

export interface ImageMeta {
    isLandscape: boolean
    rotation: number
    scale: number
    zoom: string
    X: number
    Y: number
    baseX: number
    baseY: number
}

const useFileViewer = (documentName: string) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const pdfRef = useRef<HTMLDivElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const imageRef = useRef<HTMLImageElement>(null)
    const blockPageWheelRef = useRef(false)
    const [isImage, setIsImage] = useState<boolean>(false)
    const [loaded, setLoaded] = useState(false)
    const [containerHeight, setContainerHeight] = useState(400)
    const [containerWidth, setContainerWidth] = useState(400)
    const [page, setPage] = useState(1)
    const [numberOfPages, setNumberOfPages] = useState(1)

    const [image, setImage] = useState<ImageMeta>({
        isLandscape: true,
        rotation: 0,
        scale: 1,
        zoom: '100%',
        X: 0,
        Y: 0,
        baseX: 0,
        baseY: 0,
    })

    useEffect(() => {
        const fileName = documentName.toLowerCase()

        setIsImage(
            fileName.endsWith('.png') ||
                fileName.endsWith('.jpg') ||
                fileName.endsWith('.jpeg'),
        )
    }, [documentName])

    useEffect(() => {
        if (isImage) {
            const cancelWheel = (e: WheelEvent) =>
                blockPageWheelRef.current && e.preventDefault()

            window.document.body.addEventListener('wheel', cancelWheel, {
                passive: false,
            })

            return () =>
                window.document.body.removeEventListener('wheel', cancelWheel)
        }

        return () => undefined
    }, [isImage])

    useEffect(() => {
        if (isImage && imageRef?.current && containerRef?.current) {
            let offsetY = 0
            let offsetX = 0
            const currentImageHeight = imageRef.current.offsetHeight
            const currentImageWidth = imageRef.current.offsetWidth
            const currentContainerHeight = containerRef.current.offsetHeight
            const currentContanerWidth = containerRef.current.offsetWidth

            if (currentImageHeight * image.scale > currentContainerHeight) {
                offsetY =
                    currentImageHeight / 2 -
                    currentContainerHeight / (2 * image.scale) +
                    IMAGE_MARGIN
            }

            if (currentImageWidth * image.scale > currentContanerWidth) {
                offsetX =
                    currentImageWidth / 2 -
                    currentContanerWidth / (2 * image.scale) +
                    IMAGE_MARGIN
            }

            window.document.documentElement.style.setProperty(
                '--document-media-transform',
                `rotate(${image.rotation}deg) scale(${image.scale}) translate(${
                    offsetX + image.X
                }px, ${offsetY + image.Y}px)`,
            )
        } else {
            window.document.documentElement.style.setProperty(
                '--document-media-transform',
                `rotate(${image.rotation}deg)`,
            )
        }
    }, [image, isImage])

    function handleContainerMouseLeave() {
        blockPageWheelRef.current = false
    }

    function handleContainerMouseOver() {
        blockPageWheelRef.current = true
    }

    function handleFileLoad(img: HTMLImageElement) {
        if (containerRef?.current) {
            setContainerHeight(containerRef?.current.offsetHeight)
            setContainerWidth(containerRef?.current.offsetWidth)
        }

        setImage({ ...image, isLandscape: img.offsetWidth > img.offsetHeight })
        setLoaded(true)
    }

    function handlePdfLoad(document: PDFDocumentProxy) {
        if (document) setNumberOfPages(document.numPages)

        if (containerRef?.current) {
            setContainerHeight(containerRef.current.offsetHeight)

            setContainerWidth(containerRef.current.offsetWidth)
        }
    }

    function handlePageLoad(loadedPage: PDFPageProxy) {
        setImage({
            ...image,
            isLandscape: loadedPage.width > loadedPage.height,
        })

        setLoaded(true)
    }

    function nextPage() {
        if (page < numberOfPages) setPage((p) => p + 1)
    }

    function prevPage() {
        if (page > 1) setPage((p) => p - 1)
    }

    function openPage(n: number) {
        setPage(n)
    }

    function rotate(value: string) {
        const direction = value === 'left' ? -1 : 1
        let rotation = image.rotation + 90 * direction

        if (rotation > 270 || rotation < -270) {
            rotation = 0
        }

        setImage({ ...image, rotation })
    }

    function zoom(value: string) {
        const direction = value === 'out' ? -1 : 1

        if (
            (direction > 0 && image.scale < 3) ||
            (direction < 0 && image.scale > 0.5)
        ) {
            const scale = image.scale + 0.25 * direction

            setImage({
                ...image,
                scale,
                zoom: `${(scale * 100).toFixed(0)}%`,
            })
        }
    }

    function fitScreen(dimension: string) {
        let scale = 1

        if (!isImage && dimension === 'w') {
            const pdfWidth = pdfRef.current ? pdfRef.current.offsetWidth : 0

            scale = pdfWidth ? containerWidth / pdfWidth : scale
            if (pdfRef.current)
                pdfRef.current.style.transform = `translateY(0px)`
        }

        setImage({
            ...image,
            isLandscape: dimension === 'w',
            scale,
            zoom: '100%',
            X: 0,
            Y: 0,
            baseX: 0,
            baseY: 0,
        })
    }

    function handleImgDragging(e: MouseEvent) {
        e.preventDefault()
    }

    function handleImgWheel(e: WheelEvent) {
        if (isImage) {
            zoom(e.deltaY > 0 ? 'out' : 'in')
        }
    }

    function handleContextMenu(blockDownload: boolean, e: Event) {
        if (blockDownload) {
            e.preventDefault()
        }
    }

    function defineZoomValue(value: string) {
        setImage({ ...image, zoom: value })
    }

    function handleZoomChange(value: string) {
        let zoomValue = value.replace('%', '')

        if (Number.isNaN(zoomValue)) zoomValue = '100'

        const scale = parseInt(zoomValue, 10) / 100

        setImage({ ...image, scale, zoom: `${(scale * 100).toFixed(0)}%` })
    }

    return {
        imageRef,
        containerRef,
        containerHeight,
        containerWidth,
        isImage,
        loaded,
        setContainerHeight,
        setLoaded,
        image,
        page,
        numberOfPages,
        pdfRef,
        canvasRef,
        handlePageLoad,
        nextPage,
        prevPage,
        openPage,
        handleFileLoad,
        handlePdfLoad,
        handleImgDragging,
        handleImgWheel,
        handleContextMenu,
        handleContainerMouseOver,
        handleContainerMouseLeave,
        defineZoomValue,
        handleZoomChange,
        rotate,
        zoom,
        fitScreen,
    }
}

export default useFileViewer
