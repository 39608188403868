import React from 'react'

import {
    Popover,
    StCompleteIcon,
    StErrorIcon,
    StProcessingIcon,
    UiDeleteIcon,
} from '@octane/spark'
import { removeSubscriber } from 'api/notifications'
import { useTheme } from 'styled-components'
import toastConsts from 'utils/consts/toast'
import removeAllSpaces from 'utils/removeAllSpaces'
import * as toast from 'utils/toast'

import {
    DeleteWrapper,
    RecipientContent,
    RecipientEmailAddress,
    RecipientEmailText,
} from './NotificationRecipientEmail.styled'

interface RecipientProps {
    email: string
    status: string
    uuid: string
}

export interface NotificationRecipientEmailProps {
    recipient: RecipientProps
    notificationName: string
    callback: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default function NotificationRecipientEmail({
    recipient: { email, status, uuid },
    notificationName,
    callback,
}: NotificationRecipientEmailProps) {
    const theme = useTheme()
    let NotificationIcon = StProcessingIcon
    let popoverMessage = 'User has not yet responded to invitation'
    const notificationId = removeAllSpaces(notificationName)

    if (status === 'Subscribed') {
        NotificationIcon = StCompleteIcon
        popoverMessage = 'User has subscribed to this notification'
    } else if (status === 'Unsubscribed') {
        NotificationIcon = StErrorIcon
        popoverMessage = 'User has unsubscribed from this notification'
    }

    const handleDelete = async () => {
        try {
            const params = {
                name: notificationName,
                recipient_email: email,
            }

            await removeSubscriber(params)

            toast.success({
                message: toastConsts.notifications.subscriber.removed,
            })

            await callback()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            toast.error({
                message: error.message,
            })
        }
    }

    return (
        <RecipientEmailAddress>
            <RecipientContent>
                <Popover
                    id={`${notificationId}-${uuid}`}
                    trigger={
                        <NotificationIcon
                            data-testid="popover-trigger"
                            color=""
                            height={25}
                            width={25}
                        />
                    }
                >
                    <div>{popoverMessage}</div>
                </Popover>
                <RecipientEmailText>{email}</RecipientEmailText>
            </RecipientContent>
            <DeleteWrapper>
                <UiDeleteIcon
                    data-testid="delete-button"
                    id={`button-delete-recipient-${notificationId}`}
                    width={21}
                    height={21}
                    color={theme.colors.brand.rideOctaneBlue}
                    onClick={handleDelete}
                />
            </DeleteWrapper>
        </RecipientEmailAddress>
    )
}
