import { useEffect, useState } from 'react'

import DataDogRUM from '../integrations/datadog/DataDogRUM'

export default function useDataDogRUM() {
    const [ddRUM, setDDRUM] = useState<DataDogRUM>()

    useEffect(() => {
        const dataDogRUM = new DataDogRUM()

        setDDRUM(dataDogRUM)
    }, [])

    return { ddRUM }
}
