/* eslint-disable no-param-reassign, max-len */
import { createSlice, createAction } from '@reduxjs/toolkit'
import LoadingStatus from 'state/enums/LoadingStatus'

import {
    loadUsers,
    loadUsersAndSalespersons,
    loadSecuritySettings,
    updateSecuritySettings,
    loadFlexLinks,
} from './actions'
import DealershipState from './DealershipState'
import ParseFlexLinks from './parsers/ParseFlexLinks'
import ParseUser from './parsers/ParseUser'

export const setPage = createAction(
    'dealership/setPage',
    (page: number, entityType: string) => {
        return {
            type: 'dealership/setPage',
            payload: { page, entityType },
        }
    },
)

const initialState = {
    securitySettings: {
        data: {},
        status: LoadingStatus.IDLE,
        error: undefined,
    },
    users: {
        data: [],
        meta: {
            count: 0,
            pages: 1,
            page: 1,
        },
        status: LoadingStatus.IDLE,
        error: undefined,
    },
    flexLinks: {
        data: [],
        meta: {
            count: 0,
            pages: 1,
            page: 1,
        },
        status: LoadingStatus.IDLE,
        error: undefined,
    },
} as DealershipState

const dealershipSlice = createSlice({
    name: 'dealership',
    initialState,
    reducers: {
        setPage: (state: DealershipState, action) => {
            const {
                payload: { page, entityType },
            } = action

            if (entityType === 'users') {
                state.users.meta.page = page
            } else if (entityType === 'flexLinks') {
                state.flexLinks.meta.page = page
            }
        },
    },
    extraReducers(builder) {
        builder
            // Load Users Reducers
            .addCase(loadUsers.pending, (state: DealershipState) => {
                state.users.status = LoadingStatus.LOADING
            })
            .addCase(loadUsers.fulfilled, (state: DealershipState, action) => {
                const { payload } = action
                const { count, pages } = payload.meta

                state.users.data = payload.results.map(ParseUser)
                state.users.meta.count = count
                state.users.meta.pages = pages
                state.users.status = LoadingStatus.LOADED
            })
            .addCase(loadUsers.rejected, (state: DealershipState, action) => {
                state.users.status = LoadingStatus.FAILED
                state.users.error = action.error.message
                state.users.meta.page = 1
            })

            // Load Users and Salespersons Reducers
            .addCase(
                loadUsersAndSalespersons.pending,
                (state: DealershipState) => {
                    state.users.status = LoadingStatus.LOADING
                },
            )
            .addCase(
                loadUsersAndSalespersons.fulfilled,
                (state: DealershipState, action) => {
                    const { payload } = action
                    const { count, pages } = payload.meta

                    state.users.data = payload.results.map(ParseUser)
                    state.users.meta.count = count
                    state.users.meta.pages = pages
                    state.users.status = LoadingStatus.LOADED
                },
            )
            .addCase(
                loadUsersAndSalespersons.rejected,
                (state: DealershipState, action) => {
                    state.users.status = LoadingStatus.FAILED
                    state.users.error = action.error.message
                    state.users.meta.page = 1
                },
            )

            // Load Flex Links Reducers
            .addCase(loadFlexLinks.pending, (state: DealershipState) => {
                state.flexLinks.status = LoadingStatus.LOADING
            })
            .addCase(
                loadFlexLinks.fulfilled,
                (state: DealershipState, action) => {
                    const { payload } = action
                    const pageSize = 10
                    const count = payload.length
                    const pages = Math.ceil(count / pageSize)

                    state.flexLinks.data = payload.map(ParseFlexLinks)
                    state.flexLinks.meta.count = count
                    state.flexLinks.meta.pages = pages
                    state.flexLinks.status = LoadingStatus.LOADED
                },
            )
            .addCase(
                loadFlexLinks.rejected,
                (state: DealershipState, action) => {
                    state.flexLinks.status = LoadingStatus.FAILED
                    state.flexLinks.error = action.error.message
                    state.flexLinks.meta.page = 1
                },
            )

            // Load Security Settings Reducers
            .addCase(loadSecuritySettings.pending, (state: DealershipState) => {
                state.securitySettings.status = LoadingStatus.LOADING
            })
            .addCase(
                loadSecuritySettings.fulfilled,
                (state: DealershipState, action) => {
                    const { payload } = action

                    state.securitySettings.data = {
                        passwordRotation: `${payload.password_rotation}`,
                        sessionInactivity: `${payload.session_inactivity}`,
                    }

                    state.securitySettings.status = LoadingStatus.LOADED
                },
            )
            .addCase(
                loadSecuritySettings.rejected,
                (state: DealershipState, action) => {
                    state.securitySettings.status = LoadingStatus.FAILED
                    state.securitySettings.error = action.error.message
                },
            )

            // Update Security Settings Reducers
            .addCase(
                updateSecuritySettings.pending,
                (state: DealershipState) => {
                    state.securitySettings.status = LoadingStatus.LOADING
                },
            )
            .addCase(
                updateSecuritySettings.fulfilled,
                (state: DealershipState, action) => {
                    const { payload } = action

                    state.securitySettings.data = {
                        passwordRotation: `${payload.password_rotation}`,
                        sessionInactivity: `${payload.session_inactivity}`,
                    }

                    state.securitySettings.status = LoadingStatus.LOADED
                },
            )
            .addCase(
                updateSecuritySettings.rejected,
                (state: DealershipState, action) => {
                    state.securitySettings.status = LoadingStatus.FAILED
                    state.securitySettings.error = action.error.message
                },
            )
    },
})

export default dealershipSlice
