import React from 'react'

import { Spinner } from '@octane/spark'

import { SizedContainer, ModalSpinnerDivStyle } from './LoadingSpinner.styled'

interface Props {
    height?: string
    width?: string
    text?: string
    backgroundColor?: 'grey' | 'white' | undefined
}

const defaultProps = {
    height: '600px',
    width: '800px',
    text: '',
    backgroundColor: 'white',
}

function SizedLoadingSpinner(props: Props) {
    const { width, height, text, backgroundColor } = props

    return (
        <SizedContainer width={width} height={height}>
            <ModalSpinnerDivStyle>
                <Spinner
                    size={100}
                    shadow
                    speed={0.7}
                    background={backgroundColor}
                />
            </ModalSpinnerDivStyle>
            <div
                style={{
                    position: 'relative',
                    bottom: -210,
                }}
            >
                {text}
            </div>
        </SizedContainer>
    )
}

SizedLoadingSpinner.defaultProps = defaultProps

export default SizedLoadingSpinner
