import React from 'react'

import { FmInformationIcon, ToolTip } from '@octane/spark'

import { Legend } from './StateNotListedToolTip.styled'

export default function StateNotListedToolTip({
    fieldName,
    text,
}: {
    fieldName: string
    text: string
}): JSX.Element {
    return (
        <Legend>
            <ToolTip
                id={`${fieldName}__infoToolTip`}
                showOnHover
                trigger={
                    <>
                        <span>Don&apos;t see their state?</span>{' '}
                        <FmInformationIcon height={12} width={16} />
                    </>
                }
                placement="top-end"
                text={text}
            >
                {false}
            </ToolTip>
        </Legend>
    )
}
