import React from 'react'

import { StatusIndicator } from '@octane/spark'

interface Props {
    status: string
}

export default function InvitationStatusColumn({ status }: Props) {
    return (
        <StatusIndicator
            text={status}
            variant={status === 'Expired' ? 'error' : 'processing'}
            size="medium"
            iconPosition="left"
        />
    )
}
