import IFrameClient from './IFrameClient'

export default class WindowNavigatorIFrameClient extends IFrameClient {
    constructor(target: Window, targetOrigin: string) {
        super('windowNavigator', target, targetOrigin)
    }

    setHash(hash: string) {
        this.sendRequest('setHash', [hash])
    }
}
