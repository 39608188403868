import React from 'react'

import { Label } from '@octane/spark'
import LeadActivity from 'pages/LeadsPage/models/lead_activity'

interface Props {
    value: LeadActivity
}

export default function Activity({ value }: Props) {
    if (value.type === 'new') return <Label text="new" color="cornflower" />

    return null
}
