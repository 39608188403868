import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useUser from 'hooks/useUser'

export default function ApplicationRedirector() {
    const {
        isLoggedIn,
        isAutoDealership,
        isPowersportsDealership,
        isRVDealership,
    } = useUser()

    const navigate = useNavigate()
    const splitLocation = window.location.hash.split('/')
    const applicationId = splitLocation.pop()

    useEffect(() => {
        if (
            isLoggedIn &&
            window.location.hash.startsWith('#!/application/copy-vehicle/') &&
            applicationId
        ) {
            if (isAutoDealership || isPowersportsDealership || isRVDealership) {
                navigate(`copy-vehicle/${applicationId}`)
            }
        }
    })

    return null
}
