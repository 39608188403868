import React from 'react'

import { Button } from '@octane/spark'
import ApplicationStatusIndicator from 'components/ApplicationStatusIndicator'
import FormContent from 'components/FormContent'

import {
    ButtonContainer,
    Header,
    MainContainer,
    StyledHeaderSection,
    Wrapper,
    LenderNameText,
} from './ReadOnlyApplicationStatus.styled'

interface StatusProps {
    id: number
    applicant: string
    status: string
    lender: string
    submittedAt: string
    createdAt: string
}

function ReadOnlyApplicationStatus({
    id,
    applicant,
    status,
    lender,
    submittedAt,
    createdAt,
}: Readonly<StatusProps>) {
    const showStatusButton = ['Approved', 'Declined'].includes(status)
    const headerIdText = `#${id}`

    const headerText = applicant
        ? `${headerIdText}: ${applicant}`
        : headerIdText

    return (
        <MainContainer>
            <StyledHeaderSection title="">
                <FormContent gridTemplate="1fr">
                    <Wrapper>
                        <div>
                            <Header>{headerText}</Header>
                            <LenderNameText>{lender}</LenderNameText>
                        </div>
                        <ApplicationStatusIndicator
                            status={status}
                            submittedAt={submittedAt}
                            createdAt={createdAt}
                        />
                    </Wrapper>
                </FormContent>
                <ButtonContainer>
                    <FormContent gridTemplate="1fr 1fr 1fr">
                        <a
                            href={`#/copy-into-new/${id}`}
                            target="_self"
                            rel="noreferrer"
                        >
                            <Button variant="primary" style={{ width: '100%' }}>
                                Copy Into New
                            </Button>
                        </a>
                        <Button
                            variant="primaryOutlined"
                            onClick={window.print}
                        >
                            Print
                        </Button>

                        {showStatusButton && (
                            <a
                                href={`#/application/status/${id}`}
                                target="_self"
                                rel="noreferrer"
                            >
                                <Button
                                    variant="primaryOutlined"
                                    style={{ width: '100%' }}
                                >
                                    Status
                                </Button>
                            </a>
                        )}
                    </FormContent>
                </ButtonContainer>
            </StyledHeaderSection>
        </MainContainer>
    )
}

export default ReadOnlyApplicationStatus
