import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@octane/spark'
import { FormikTextInput, FormikMaskedTextInput } from 'components/FormikFields'
import { Formik, Form, FormikProps, FormikErrors } from 'formik'
import useAppDispatch from 'hooks/useAppDispatch'
import useUser from 'hooks/useUser'
import { updateUser } from 'state/user/actions'
import toastConsts from 'utils/consts/toast'
import * as toast from 'utils/toast'

import { FormContent, MobilePhoneInfo } from './GeneralInfoForm.styled'
import GeneralInfoSchema from './schema.yup'

export type formProps = {
    firstName: string
    lastName: string
    username: string
    email: string
    phone: string
    mobilePhone: string
}

export default function GeneralInfoForm(): JSX.Element {
    const { user } = useUser()
    const dispatch = useAppDispatch()

    const initialValues: formProps = {
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        username: user ? user.username : '',
        email: user ? user.email : '',
        phone: user ? user.phoneNumber : '',
        mobilePhone: user ? user.mfaPhoneNumber : '',
    }

    const handleSubmit = async (values: formProps) => {
        const params = {
            first_name: values.firstName,
            last_name: values.lastName,
            dealer_phone: values.phone,
            email: values.email,
        }

        dispatch(updateUser(params)).then(({ type }) => {
            if (type === 'user/updateUser/fulfilled') {
                toast.success({
                    title: toastConsts.settings.success.title,
                    message: toastConsts.settings.success.message,
                })
            } else {
                toast.error({
                    title: toastConsts.settings.error.title,
                    message: toastConsts.settings.error.message,
                })
            }
        })
    }

    const validateFormOnClick = async (
        validateForm: (values?: unknown) => Promise<FormikErrors<formProps>>,
        values: formProps,
        formikSubmit: () => void,
    ) => {
        const resultValidation = await validateForm(values)

        if (Object.keys(resultValidation).length) return

        formikSubmit()
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={GeneralInfoSchema}
            onSubmit={handleSubmit}
        >
            {({
                handleSubmit: formikSubmit,
                isSubmitting,
                isValid,
                dirty,
                validateForm,
                values,
            }: FormikProps<formProps>) => (
                <Form data-testid="general-info-form">
                    <FormContent>
                        <FormikTextInput
                            label="First Name"
                            name="firstName"
                            placeholder="John"
                        />
                        <FormikTextInput
                            label="Last Name"
                            name="lastName"
                            placeholder="Doe"
                        />
                        <FormikTextInput
                            disabled
                            label="Username"
                            name="username"
                            placeholder="jmartinez"
                        />
                        <FormikTextInput
                            label="Email"
                            name="email"
                            placeholder="jmartinez@powersports.com"
                        />
                        <FormikMaskedTextInput
                            label="Work Phone"
                            name="phone"
                            placeholder="(000) 000-0000"
                            mask="(000) 000-0000"
                            data-testid="phone"
                        />
                        <div>
                            <FormikMaskedTextInput
                                name="mobilePhone"
                                label="Mobile Phone"
                                placeholder="(000) 000-0000"
                                mask="(000) 000-0000"
                                info={
                                    <MobilePhoneInfo>
                                        Some features within Octane require
                                        Multifactor Authentication for
                                        additional security. A mobile phone
                                        number is required to set up{' '}
                                        <Link to="/account-settings/security">
                                            MFA
                                        </Link>
                                    </MobilePhoneInfo>
                                }
                                disabled
                            />
                        </div>
                    </FormContent>
                    <FormContent>
                        <Button
                            id="save-general-info"
                            size="medium"
                            onClick={() =>
                                validateFormOnClick(
                                    validateForm,
                                    values,
                                    formikSubmit,
                                )
                            }
                            disabled={!dirty || !isValid || isSubmitting}
                            loading={isSubmitting}
                        >
                            Save
                        </Button>
                    </FormContent>
                </Form>
            )}
        </Formik>
    )
}
