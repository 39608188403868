import React, { useEffect, useState } from 'react'

import { Spinner } from '@octane/spark'

import { Container, SpinnerDivStyle } from './LoadingSpinner.styled'

function LoadingSpinner() {
    const [showSpinner, setShowSpinner] = useState(false)

    // Set a timeout to first show loader after 500ms,
    // so the user is not flashed with the loader.
    useEffect(() => {
        setTimeout(() => {
            setShowSpinner(true)
        }, 500)
    }, [setShowSpinner])

    if (!showSpinner) {
        return null
    }

    return (
        <Container>
            <SpinnerDivStyle>
                <Spinner size={100} speed={0.7} />
            </SpinnerDivStyle>
        </Container>
    )
}

export default LoadingSpinner
