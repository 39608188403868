import axios, { AxiosError } from 'axios'
import config from 'config'
import { PostCollateralDTO } from 'pages/NewApplication/Powersports/utils/createPostCollateralDTO'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function postApplicationCollateral(
    uuid: string,
    collateral: PostCollateralDTO,
) {
    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/applications/${uuid}/collaterals/`,
            collateral,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get post application collaterals: ${axiosError.message}`,
            axiosError,
            { applicationUuid: uuid },
        )

        throw new Error(axiosError.message)
    }
}
