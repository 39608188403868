import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import * as Yup from 'yup'

export const UserPermissionsSchema = Yup.object().shape({
    levelOfAccess: Yup.string().required(FIELD_REQUIRED),
    applicationAccess: Yup.string().required(FIELD_REQUIRED),
    sessionInactivity: Yup.string().required(FIELD_REQUIRED),
    passwordRotation: Yup.string().required(FIELD_REQUIRED),
    useMfa: Yup.string().required(FIELD_REQUIRED),
    phoneNumber: Yup.string().when('levelOfAccess', {
        is: 'Sales',
        then: Yup.string()
            .required(FIELD_REQUIRED)
            .min(10, 'Phone number needs to be 10 digits exactly.')
            .max(10, 'Phone number needs to be 10 digits exactly.'),
    }),
})
