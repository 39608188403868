/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import { Button, Modal } from '@octane/spark'
import useLenderDisclosure from 'hooks/useLenderDisclosure'
import DisclosureType from 'state/enums/DisclosureType'
import camelCase from 'utils/camelCase'

import { ModalButton, Content } from './DisclosureModal.styled'

type Props = {
    showModal: boolean
    toggleModal: React.Dispatch<React.SetStateAction<boolean>>
    modalTitle: string
    disclosureName: DisclosureType
    lenderId: number
    applicationId?: number
} & typeof defaultProps

const defaultProps = {
    applicationId: 0,
}

export default function DisclosureModal({
    showModal,
    toggleModal,
    modalTitle,
    disclosureName,
    lenderId,
    applicationId,
}: Props) {
    const { disclosureText } = useLenderDisclosure(
        disclosureName,
        lenderId,
        applicationId,
    )

    const testId = `modalContent-${camelCase(modalTitle)}`

    return (
        <Modal
            title={modalTitle}
            isOpen={showModal}
            onClose={() => toggleModal(false)}
            scroll="body"
            size="large"
        >
            <Modal.Body>
                <Content data-testid={testId}>
                    <p data-testid={disclosureName}>{disclosureText}</p>
                </Content>
            </Modal.Body>
            <Modal.Footer>
                <ModalButton>
                    <Button
                        type="button"
                        variant="primaryOutlined"
                        onClick={() => toggleModal(false)}
                    >
                        Close
                    </Button>
                </ModalButton>
            </Modal.Footer>
        </Modal>
    )
}

DisclosureModal.defaultProps = defaultProps
