import axios, { AxiosError } from 'axios'
import config from 'config'
import { camelizeKeys } from 'humps'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function getCreditProfile(
    uuid: string,
    creditAccessId: string,
) {
    if (!uuid || !creditAccessId) {
        throw new Error('invalid ID')
    }

    try {
        const { data } = await axios.get(
            `${config.API_BASE_URL}/api/v3/applications/${uuid}/credit-profiles/${creditAccessId}/`,
            {
                withCredentials: true,
            },
        )

        return camelizeKeys(data)
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get credit profile: ${axiosError.message}`,
            axiosError,
            { applicationUuid: uuid },
        )

        throw new Error(axiosError.message)
    }
}
