import octaneTheme from 'themes/octaneTheme'

import { Brand, BrandConfig } from '../types'

const octaneConfig: BrandConfig = {
    brand: Brand.Octane,
    name: 'Octane',
    theme: octaneTheme,
    hideNavbarAtLogin: false,
    loginHeaderTitle: 'Welcome Back!',
}

export default octaneConfig
