import { FieldInstruction } from 'containers/FormBuilder'
import {
    FIELD_MAX_VALUE,
    FIELD_GREATER_THAN_ZERO,
    FIELD_MAX_VALUE_USED_MILEAGE,
    FIELD_NEGATIVE_NUMBER,
    FIELD_REQUIRED,
    FIELD_TOO_LONG,
} from 'form/validation/validationMessages'
import AssetType from 'state/enums/AssetType'
import * as Yup from 'yup'

import MarineWriteInBaseField from '../fieldComponents/MarineWriteInBaseField'

const marineWriteInSchema = Yup.object().shape({
    condition: Yup.string().when('repower', {
        is: false,
        then: Yup.string().required(FIELD_REQUIRED),
    }),
    year: Yup.number().when('repower', {
        is: false,
        then: Yup.number().required(FIELD_REQUIRED).moreThan(0, FIELD_REQUIRED),
    }),
    make: Yup.string().when('repower', {
        is: false,
        then: Yup.string().required(FIELD_REQUIRED),
    }),
    type: Yup.string(),
    model: Yup.string().when('repower', {
        is: false,
        then: Yup.string()
            .max(200, FIELD_TOO_LONG(200))
            .matches(
                /^[A-Za-z0-9 \-.()/&,'"*+!:#]*$/,
                'Only the following special characters are allowed: - . ( ) / & , \' " * + ! : #', // eslint-disable-line max-len
            )
            .required(FIELD_REQUIRED),
    }),
    hours: Yup.number().when('condition', {
        is: 'U',
        then: Yup.number()
            .nullable()
            .integer()
            .moreThan(0, FIELD_GREATER_THAN_ZERO)
            .max(100000, FIELD_MAX_VALUE_USED_MILEAGE),
    }),
    trailer: Yup.bool().when('repower', {
        is: false,
        then: Yup.bool().required(FIELD_REQUIRED),
    }),
    motors: Yup.number()
        .moreThan(-1, FIELD_NEGATIVE_NUMBER)
        .max(2, FIELD_MAX_VALUE(2))
        .required(FIELD_REQUIRED),
    invoice: Yup.number()
        .required(FIELD_REQUIRED)
        .moreThan(0, FIELD_GREATER_THAN_ZERO),
    primaryResidence: Yup.bool().when('repower', {
        is: false,
        then: Yup.bool().required(FIELD_REQUIRED).oneOf([true], FIELD_REQUIRED),
    }),
})

export const marineWriteIn: FieldInstruction<typeof MarineWriteInBaseField> = {
    Component: MarineWriteInBaseField,
    config: {
        initialValue: {
            condition: 'N',
            type: '',
            year: '',
            make: '',
            model: '',
            hours: '',
            trailer: 'false',
            motors: '',
            invoice: '',
            primaryResidence: false,
            repower: false,
        },
        assetType: AssetType.MARINE,
        validator: marineWriteInSchema,
    },
}
