import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export type SortingParam = { field: string; order: string }

export type LeadsFilter = 'all' | 'leads-only' | 'converted-applications-only'

function buildSortingParamString(
    sortingParams: Array<{ field: string; order: string }>,
) {
    return sortingParams
        .map((sortingParam: { field: string; order: string }) => {
            return `${sortingParam.order === 'desc' ? '-' : ''}${
                sortingParam.field
            }`
        })
        .join(',')
}

export default async function getLeadsApplications(
    page = 1,
    filters: string[] = new Array<string>(),
    sortingParams: Array<SortingParam> = new Array<SortingParam>(),
    search = '',
    leadsFilter: LeadsFilter = 'all',
) {
    try {
        const { data } = await axios.get(
            `${config.API_BASE_URL}/api/v3/applications/`,
            {
                withCredentials: true,
                params: {
                    leads_filter: leadsFilter,
                    sorted_by: buildSortingParamString(sortingParams),
                    filters: filters.join(','),
                    page,
                    search,
                },
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get lead applications: ${axiosError.message}`,
            axiosError,
        )

        throw error
    }
}
