import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import useUser from 'hooks/useUser'
import LoadingStatus from 'state/enums/LoadingStatus'
import { validateInvitation } from 'state/invitation/actions'

export default function ValidateToken() {
    const { token } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { isLoggedIn, status: userStatus } = useUser()
    const { status, error } = useAppSelector((state) => state.invitation)

    useEffect(() => {
        if (status === LoadingStatus.LOADED) {
            navigate(`/invitation/${token}/welcome/`)
        }

        if (status === LoadingStatus.FAILED || error) {
            navigate(`/invitation/${token}/expired/`)
        }
    }, [status, error]) // eslint-disable-line

    useEffect(() => {
        if (isLoggedIn && userStatus === LoadingStatus.LOADED) {
            navigate(`/invitation/${token}/logged-in/`)
        }

        if (token && !isLoggedIn && userStatus === LoadingStatus.FAILED) {
            dispatch(
                validateInvitation({
                    token,
                }),
            )
        }
    }, [isLoggedIn, userStatus]) // eslint-disable-line

    return null
}
