import React from 'react'

import { getIn, FieldProps, Field } from 'formik'

import { FormikMaskedInput } from './FormikMaskedTextInput.styled'

type FormikTextInputProps = {
    name: string
    placeholder: string
    label: string
    mask: string
    disabled?: boolean
    info?: React.ReactNode
    helpText?: string
    infoText?: string
} & typeof defaultProps

const defaultProps = {
    disabled: false,
    info: <> </>,
    helpText: '',
    infoText: '',
}

function FormikMaskedTextInput({
    name,
    placeholder,
    label,
    mask,
    disabled,
    info,
    infoText,
    helpText,
}: FormikTextInputProps) {
    let extraProps = {}

    if (info?.props.children !== ' ') {
        extraProps = {
            info,
        }
    }

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                const touched = getIn(form.touched, field.name)
                const error = getIn(form.errors, field.name)

                return (
                    <FormikMaskedInput
                        disabled={disabled}
                        error={!!(touched && error)}
                        errorText={error}
                        id={field.name}
                        name={field.name}
                        label={label}
                        value={field.value}
                        helpText={helpText}
                        infoText={infoText}
                        onChange={(val: string) => {
                            const event = {
                                target: {
                                    name,
                                    value: val,
                                },
                            }

                            form.handleChange(event)
                        }}
                        onBlur={form.handleBlur}
                        placeholder={placeholder}
                        mask={mask}
                        data-testid={name}
                        {...extraProps}
                    />
                )
            }}
        </Field>
    )
}

FormikMaskedTextInput.defaultProps = defaultProps

export default FormikMaskedTextInput
