import {
    FieldInstruction,
    FieldInstructionBundle,
    FormValues,
} from 'containers/FormBuilder'
import * as fields from 'form/fields'
import { createEmploymentFieldInstructionBundle } from 'form/fields/employmentFields'
import { createCoapplicantSsnValidator } from 'form/fields/ssn'

import SsnInputField from '../../form/fieldComponents/SsnInputField'
import { createZipFieldInstruction } from '../../form/fields/addressFields'
import { gridColumnWrapper } from './gridColumnWrapper'
import {
    generateConditionallyValidatedFields,
    generateConditionallyDisabledFields,
} from './utils'

export const COAPPLICANT_TAG = 'coapplicant_'

/*
 +-+-+-+-+-+
 FIELD NAMES
 +-+-+-+-+-+
*/
function createCoapplicantFieldName(
    applicantFieldName: string,
    coapplicantTag: string,
) {
    return `${coapplicantTag}${applicantFieldName}`
}

export const applicantFieldNames = {
    citizenshipStatus: 'citizenshipStatus',
    ssn: 'ssn',
    firstName: 'firstName',
    middleInitial: 'middleInitial',
    lastName: 'lastName',
    suffix: 'suffix',
    dateOfBirth: 'dateOfBirth',
    street: 'street',
    apartmentSuite: 'apartmentSuite',
    city: 'city',
    state: 'state',
    zip: 'zip',
    residenceStatus: 'residenceStatus',
    phoneType: 'phoneType',
    phoneNumber: 'phoneNumber',
    residentialYears: 'residentialYears',
    residentialMonths: 'residentialMonths',
    monthlyHousingPayment: 'monthlyHousingPayment',
    jobTitle: 'jobTitle',
    employmentStatus: 'employmentStatus',
    employerName: 'employerName',
    employerYears: 'employerYears',
    employerMonths: 'employerMonths',
    employerMonthlyGross: 'employerMonthlyGross',
    employerGrossAmount: 'employerGrossAmount',
    employerGrossInterval: 'employerGrossInterval',
    hasOtherIncome: 'hasOtherIncome',
    otherIncomeSource: 'otherIncomeSource',
    otherMonthlyGrossIncome: 'otherMonthlyGrossIncome',
    otherIncomeGrossAmount: 'otherIncomeGrossAmount',
    otherIncomeGrossInterval: 'otherIncomeGrossInterval',
    removeOtherIncome: 'removeOtherIncome',
    email: 'email',
    homePhone: 'homePhone',
    mobilePhone: 'mobilePhone',
    workPhone: 'workPhone',
}

export const coapplicantFieldNames = Object.keys(applicantFieldNames).reduce(
    (acc, key) => {
        const currentKey = key as keyof typeof applicantFieldNames

        acc[currentKey] = createCoapplicantFieldName(
            applicantFieldNames[currentKey],
            COAPPLICANT_TAG,
        )

        return acc
    },
    {} as { [Key in keyof typeof applicantFieldNames]: string },
)

// employment fields are highly interdependent so
// they have a special creation mechanism
const employmentFieldNames = {
    employmentStatus: applicantFieldNames.employmentStatus,
    employerMonthlyGross: applicantFieldNames.employerMonthlyGross,
    employerGrossAmount: applicantFieldNames.employerGrossAmount,
    employerGrossInterval: applicantFieldNames.employerGrossInterval,
    hasOtherIncome: applicantFieldNames.hasOtherIncome,
    otherIncomeSource: applicantFieldNames.otherIncomeSource,
    otherIncomeGrossAmount: applicantFieldNames.otherIncomeGrossAmount,
    otherIncomeGrossInterval: applicantFieldNames.otherIncomeGrossInterval,
    removeOtherIncome: applicantFieldNames.removeOtherIncome,
    employerYears: applicantFieldNames.employerYears,
    employerMonths: applicantFieldNames.employerMonths,
    jobTitle: applicantFieldNames.jobTitle,
    employerName: applicantFieldNames.employerName,
    workPhone: applicantFieldNames.workPhone,
}

// generate coapplicant field names from applicant field names
const coapplicantEmploymentFieldNames = Object.keys(
    employmentFieldNames,
).reduce((acc, key) => {
    const currentKey = key as keyof typeof employmentFieldNames

    acc[currentKey] = createCoapplicantFieldName(
        employmentFieldNames[currentKey],
        COAPPLICANT_TAG,
    )

    return acc
}, {} as typeof employmentFieldNames)

export const otherFieldNames = {
    financeManager: 'financeManager',
    applicationType: 'applicationType',
    relationOfCoapplicant: 'relationOfCoapplicant',
    isCoapplicantAddressSame: 'isCoapplicantAddressSame',
}
export const verificationFieldNames = {
    customerVerification: 'customerVerification',
}

/*
 +-+-+-+-+-+-+-+-+-+-+
 VALUES WE NEED TO PASS TO THE BACKEND BUT ARE NOT VISIBLE FIELDS
 +-+-+-+-+-+-+-+-+-+-+
*/
export const hiddenFormValues = {
    group: 'group',
}

/*
 +-+-+-+-+-+-+-+-+-+-+
 UTILITY FUNCTIONS
 +-+-+-+-+-+-+-+-+-+-+
*/
export function applicantCitizenshipStatusLinkingFunc(formValues: FormValues) {
    return formValues[applicantFieldNames.citizenshipStatus] === 'other'
}

export function coapplicantCitizenshipStatusLinkingFunc(
    formValues: FormValues,
) {
    return formValues[coapplicantFieldNames.citizenshipStatus] === 'other'
}

export function isApplicationJointFunc(values: FormValues) {
    return values[otherFieldNames.applicationType] === 'joint'
}

export function isCoapplicantAddressSameFunc(values: FormValues) {
    return values[otherFieldNames.isCoapplicantAddressSame] === true
}

function isApplicationJointAndCoapplicantSameAddress(values: FormValues) {
    return (
        isApplicationJointFunc(values) && !isCoapplicantAddressSameFunc(values)
    )
}

/*
 +-+-+-+-+-+-+-+-+-+-+-+-+
 FIELD INSTRUCTION BUNDLES
 +-+-+-+-+-+-+-+-+-+-+-+-+
*/
const financeManagerFields: FieldInstructionBundle = {
    [otherFieldNames.financeManager]: fields.financeManager,
}

const applicantZipField = createZipFieldInstruction({
    city: applicantFieldNames.city,
    state: applicantFieldNames.state,
})

const citizenshipLinkedFields: FieldInstructionBundle = {
    [applicantFieldNames.firstName]: gridColumnWrapper(
        fields.firstName,
        'span 9',
    ),
    [applicantFieldNames.middleInitial]: gridColumnWrapper(
        fields.middleInitial,
        'span 3',
    ),
    [applicantFieldNames.lastName]: gridColumnWrapper(
        fields.lastName,
        'span 9',
    ),
    [applicantFieldNames.suffix]: gridColumnWrapper(fields.suffix, 'span 3'),
    [applicantFieldNames.dateOfBirth]: gridColumnWrapper(
        fields.dateOfBirth,
        'span 8',
    ),
    [applicantFieldNames.ssn]: fields.ssn,
    [applicantFieldNames.street]: gridColumnWrapper(
        fields.streetAddress,
        'span 8',
    ),
    [applicantFieldNames.apartmentSuite]: gridColumnWrapper(
        fields.apartmentSuite,
        'span 8',
    ),
    [applicantFieldNames.zip]: gridColumnWrapper(applicantZipField, 'span 8'),
    [applicantFieldNames.city]: gridColumnWrapper(fields.city, 'span 8'),
    [applicantFieldNames.state]: gridColumnWrapper(fields.state, 'span 8'),
}

// these generated fields will be dynamically disabled
// when the `citizenshipStatus` field's value is 'other'
const citizenshipLinkedApplicantFields = generateConditionallyDisabledFields(
    citizenshipLinkedFields,
    applicantCitizenshipStatusLinkingFunc,
)

const verificationFields: FieldInstructionBundle = {
    [verificationFieldNames.customerVerification]:
        fields.customerVerificationCheckbox,
}

const applicationTypeFields: FieldInstructionBundle = {
    [otherFieldNames.applicationType]: fields.applicationType,
}

const applicantIdentityFields: FieldInstructionBundle = {
    [applicantFieldNames.citizenshipStatus]: fields.citizenshipStatus,
    [applicantFieldNames.ssn]:
        citizenshipLinkedApplicantFields[applicantFieldNames.ssn],
}

const applicantPersonalFields: FieldInstructionBundle = {
    [applicantFieldNames.firstName]:
        citizenshipLinkedApplicantFields[applicantFieldNames.firstName],

    [applicantFieldNames.middleInitial]:
        citizenshipLinkedApplicantFields[applicantFieldNames.middleInitial],

    [applicantFieldNames.lastName]:
        citizenshipLinkedApplicantFields[applicantFieldNames.lastName],

    [applicantFieldNames.suffix]:
        citizenshipLinkedApplicantFields[applicantFieldNames.suffix],

    [applicantFieldNames.dateOfBirth]:
        citizenshipLinkedApplicantFields[applicantFieldNames.dateOfBirth],

    [applicantFieldNames.street]:
        citizenshipLinkedApplicantFields[applicantFieldNames.street],

    [applicantFieldNames.apartmentSuite]:
        citizenshipLinkedApplicantFields[applicantFieldNames.apartmentSuite],

    [applicantFieldNames.zip]:
        citizenshipLinkedApplicantFields[applicantFieldNames.zip],

    [applicantFieldNames.city]:
        citizenshipLinkedApplicantFields[applicantFieldNames.city],

    [applicantFieldNames.state]:
        citizenshipLinkedApplicantFields[applicantFieldNames.state],
}

const contactInfoFields: FieldInstructionBundle = {
    [applicantFieldNames.email]: fields.email,
    [applicantFieldNames.homePhone]: fields.homePhone,
    [applicantFieldNames.mobilePhone]: fields.mobilePhone,
}

const residenceFields: FieldInstructionBundle = {
    [applicantFieldNames.residenceStatus]: gridColumnWrapper(
        fields.residenceStatus,
        'span 2',
    ),
    [applicantFieldNames.residentialYears]: fields.residentialYears,
    [applicantFieldNames.residentialMonths]: fields.residentialMonths,
    [applicantFieldNames.monthlyHousingPayment]: gridColumnWrapper(
        fields.monthlyHousingPayment,
        'span 2',
    ),
}

const employmentFields =
    createEmploymentFieldInstructionBundle(employmentFieldNames)

/*
 +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-
 VALIDATION AND DISABLING FOR CO-APPLICANT FIELDS
 +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-
*/
const coapplicantEmploymentFields = generateConditionallyValidatedFields(
    createEmploymentFieldInstructionBundle(coapplicantEmploymentFieldNames),
    isApplicationJointFunc,
)

// Co-Applicant ssn validation needs to check that the two SSNs are not equal
// so create a new field instruction based on the SSN field but with
// this extra level of validation
const coapplicantSsnValidator = createCoapplicantSsnValidator(
    applicantFieldNames.ssn,
    coapplicantFieldNames.ssn,
)

const coapplicantSSNField: FieldInstruction<typeof SsnInputField> = {
    ...fields.ssn,
    config: {
        ...fields.ssn.config,
        validator: (formValues) => {
            return coapplicantSsnValidator(formValues)
        },
    },
}

const coapplicantZipField = createZipFieldInstruction({
    city: coapplicantFieldNames.city,
    state: coapplicantFieldNames.state,
})

const generatedCoapplicantFields = generateConditionallyValidatedFields(
    {
        ...citizenshipLinkedFields,
        [applicantFieldNames.zip]: coapplicantZipField,
        ...contactInfoFields,
        ...residenceFields,
        [applicantFieldNames.citizenshipStatus]: fields.citizenshipStatus,
        [applicantFieldNames.ssn]: coapplicantSSNField,
        [otherFieldNames.relationOfCoapplicant]: fields.relationOfCoapplicant,
    },
    isApplicationJointFunc,
)

const generatedCoapplicantAddressFields = generateConditionallyValidatedFields(
    {
        [applicantFieldNames.street]: fields.streetAddress,
        [applicantFieldNames.apartmentSuite]: fields.apartmentSuite,
        [applicantFieldNames.zip]: coapplicantZipField,
        [applicantFieldNames.city]: fields.city,
        [applicantFieldNames.state]: fields.state,
    },
    isApplicationJointAndCoapplicantSameAddress,
)

const citizenshipLinkedCoapplicantFields = generateConditionallyDisabledFields(
    {
        [applicantFieldNames.ssn]:
            generatedCoapplicantFields[applicantFieldNames.ssn],

        [applicantFieldNames.firstName]:
            generatedCoapplicantFields[applicantFieldNames.firstName],

        [applicantFieldNames.middleInitial]:
            generatedCoapplicantFields[applicantFieldNames.middleInitial],

        [applicantFieldNames.lastName]:
            generatedCoapplicantFields[applicantFieldNames.lastName],

        [applicantFieldNames.suffix]:
            generatedCoapplicantFields[applicantFieldNames.suffix],

        [applicantFieldNames.dateOfBirth]:
            generatedCoapplicantFields[applicantFieldNames.dateOfBirth],

        [otherFieldNames.isCoapplicantAddressSame]:
            fields.isCoapplicantAddressSame,

        [applicantFieldNames.street]:
            generatedCoapplicantAddressFields[applicantFieldNames.street],

        [applicantFieldNames.apartmentSuite]:
            generatedCoapplicantAddressFields[
                applicantFieldNames.apartmentSuite
            ],

        [applicantFieldNames.zip]:
            generatedCoapplicantAddressFields[applicantFieldNames.zip],

        [applicantFieldNames.city]:
            generatedCoapplicantAddressFields[applicantFieldNames.city],

        [applicantFieldNames.state]:
            generatedCoapplicantAddressFields[applicantFieldNames.state],
    },
    coapplicantCitizenshipStatusLinkingFunc,
)

/*
 +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
 CREATE FINAL CO-APPLICANT FIELD INSTRUCTION BUNDLES
 +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
*/
const relationOfCoapplicantFields: FieldInstructionBundle = {
    [otherFieldNames.relationOfCoapplicant]:
        generatedCoapplicantFields[otherFieldNames.relationOfCoapplicant],
}

const coapplicantIdentityFields: FieldInstructionBundle = {
    [coapplicantFieldNames.citizenshipStatus]:
        generatedCoapplicantFields[applicantFieldNames.citizenshipStatus],

    [coapplicantFieldNames.ssn]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.ssn],
}

const coapplicantPersonalFields: FieldInstructionBundle = {
    [coapplicantFieldNames.firstName]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.firstName],

    [coapplicantFieldNames.middleInitial]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.middleInitial],

    [coapplicantFieldNames.lastName]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.lastName],

    [coapplicantFieldNames.suffix]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.suffix],

    [coapplicantFieldNames.dateOfBirth]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.dateOfBirth],

    [otherFieldNames.isCoapplicantAddressSame]: gridColumnWrapper(
        fields.isCoapplicantAddressSame,
        '1 / span 24',
    ),
}

const coapplicantAddressFields: FieldInstructionBundle = {
    [coapplicantFieldNames.street]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.street],

    [coapplicantFieldNames.apartmentSuite]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.apartmentSuite],

    [coapplicantFieldNames.zip]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.zip],

    [coapplicantFieldNames.city]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.city],

    [coapplicantFieldNames.state]:
        citizenshipLinkedCoapplicantFields[applicantFieldNames.state],
}

const coapplicantContactInfoFields: FieldInstructionBundle = Object.keys(
    contactInfoFields,
).reduce<FieldInstructionBundle>((acc, fieldName) => {
    const newFieldName = createCoapplicantFieldName(fieldName, COAPPLICANT_TAG)

    acc[newFieldName] = generatedCoapplicantFields[fieldName]

    return acc
}, {})

const coapplicantResidenceFields: FieldInstructionBundle = Object.keys(
    residenceFields,
).reduce<FieldInstructionBundle>((acc, fieldName) => {
    const newFieldName = createCoapplicantFieldName(fieldName, COAPPLICANT_TAG)

    acc[newFieldName] = generatedCoapplicantFields[fieldName]

    return acc
}, {})

export const tradeInFieldNames = {
    intent: 'intent',
}

const tradeInFields: FieldInstructionBundle = {
    [tradeInFieldNames.intent]: fields.tradeInFields,
}

export default {
    financeManagerFields,
    applicantIdentityFields,
    applicantPersonalFields,
    contactInfoFields,
    residenceFields,
    employmentFields,
    coapplicantIdentityFields,
    coapplicantPersonalFields,
    coapplicantAddressFields,
    coapplicantContactInfoFields,
    coapplicantResidenceFields,
    coapplicantEmploymentFields,
    applicationTypeFields,
    relationOfCoapplicantFields,
    verificationFields,
    tradeInFields,
}
