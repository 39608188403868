import styled from 'styled-components'

interface RowProps {
    gridArea: string
}

export const Placement = styled.div<RowProps>`
    grid-area: ${(props) => props.gridArea};
    padding-right: 1em;
    border-right: 1px solid #d5d9e0;

    :last-child {
        border-right: 0;
    }
`

export const SpacedItems = styled.div`
    > div {
        margin-bottom: 15px;
    }
`
