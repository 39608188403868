import React, { useCallback } from 'react'

import * as typography from 'assets/styles/typography'
import {
    AnimatedField,
    FormikInput,
} from 'components/FormikFields/FormikTextInput/FormikTextInput.styled'
import styled from 'styled-components'

import {
    FieldConfigBase,
    FieldKit,
    FieldProps,
    FormValues,
} from '../../containers/FormBuilder'

export const Subtitle = styled.div`
    ${typography.Copy16}
    font-size: .75em;
    margin-bottom: 1em;
    color: #30333a;
`
export type InputFieldOnChange = (
    value: string,
    fieldName: string,
    formValues: FormValues,
    fieldKit: FieldKit,
) => void

interface InputFieldConfig extends FieldConfigBase {
    label: string
    type?: 'text' | 'email' | 'password'
    disabled?: boolean
    placeholder?: string
    subtitle?: string | React.ReactNode
    onChange?: InputFieldOnChange
    maxLength?: number
    prefilled?: boolean
    formatFn?: (value: string) => string
}

export default function InputField(props: FieldProps<InputFieldConfig>) {
    const { fieldName, fieldKit, fieldConfig } = props
    const touched = fieldKit.touched[fieldName]
    const error = fieldKit.errors[fieldName]
    const value = fieldKit.values[fieldName]

    const handleBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            fieldKit.handleBlur(event)

            let formattedValue = (value || '').trim()

            if (fieldConfig.formatFn) {
                formattedValue = fieldConfig.formatFn(formattedValue)
            }

            if (value !== formattedValue) {
                fieldKit.setFieldValue(fieldName, formattedValue)
            }
        },
        [fieldConfig, fieldKit, fieldName, value],
    )

    return (
        <div>
            <AnimatedField>
                <FormikInput
                    type={fieldConfig.type}
                    disabled={fieldConfig.disabled}
                    prefilled={fieldConfig.prefilled}
                    error={!!(touched && error)}
                    errorText={error}
                    id={fieldName}
                    name={fieldName}
                    label={fieldConfig.label}
                    value={value}
                    onChange={(val: string) => {
                        if (fieldConfig.onChange) {
                            fieldConfig.onChange(
                                val,
                                fieldName,
                                fieldKit.values,
                                fieldKit,
                            )
                        } else {
                            const event = {
                                target: {
                                    name: fieldName,
                                    value: val,
                                },
                            }

                            fieldKit.handleChange(event)
                        }
                    }}
                    onBlur={handleBlur}
                    placeholder={fieldConfig.placeholder}
                    maxLength={fieldConfig.maxLength}
                    data-testid={fieldName}
                />
            </AnimatedField>
            {fieldConfig.subtitle && (
                <Subtitle className="no-print">{fieldConfig.subtitle}</Subtitle>
            )}
        </div>
    )
}

InputField.defaultProps = {
    type: 'text',
    disabled: false,
    placeholder: '',
}
