import styled from 'styled-components'

export interface Size {
    width?: string
    height?: string
}
export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(248, 248, 250, 0.9);
    z-index: 2;
    cursor: pointer;
`

export const SpinnerDivStyle = styled.div`
    position: absolute;
    top: 40%;
    left: 45%;
`

export const ModalSpinnerDivStyle = styled.div`
    position: absolute;
    top: 25%;
    left: 43%;
`

export const SizedContainer = styled.div<Size>`
    height: ${(size: Size) => size.height};
    width: ${(size: Size) => size.width};
    background-color: rgb(248, 248, 250, 0.9);
    z-index: 2;
    cursor: pointer;
    text-align: center;
    ${({ theme }) => theme.fonts.Montserrat['s32-ln40-fw800']};
`
