import RequestInterface from './RequestInterface'

export default class IFrameClient {
    namespace: string

    target: Window

    targetOrigin: string

    constructor(namespace: string, target: Window, targetOrigin: string) {
        this.namespace = namespace
        this.target = target
        this.targetOrigin = targetOrigin
    }

    sendRequest(method: string, parameters: string[]) {
        const request: RequestInterface = {
            namespace: this.namespace,
            method,
            parameters,
        }

        this.target.postMessage(JSON.stringify(request), this.targetOrigin)
    }

    reload() {
        this.sendRequest('reload', [])
    }
}
