import { Document as ReactPdfDocument } from 'react-pdf'

import styled, { css } from 'styled-components'

export const Document = styled(ReactPdfDocument)``

export const Container = styled.div`
    position: absolute;
    left: 20px;
    top: 20px;
    bottom: 20px;
    width: 120px;

    ${Document} {
        height: 100%;
        width: 100%;
    }
`

export const InnerContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow: auto;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #777;
    }
`

export const PageThumbContainer = styled.div`
    color: #fff;
    cursor: pointer;
    text-align: center;
    margin-bottom: 0.5em;
    width: fit-content;
    height: fit-content;
    small {
        margin-top: 10px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        padding: 0 8px 2px 8px;
    }
`

export const PdfPageThumb = styled.div<{ selected: boolean }>`
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 0.25em;
    cursor: pointer;
    border-radius: 0.2em;
    ${({ selected }) =>
        !!selected &&
        css`
            box-sizing: border-box;
            border: solid 0.25em
                ${(props) => props.theme.colors.brand.rideOctaneBlue};
            padding: 0;
            canvas {
                opacity: 1;
            }
        `};

    &:hover {
        padding: 0;
        border: solid 0.25em
            ${(props) =>
                props.selected
                    ? props.theme.colors.brand.rideOctaneBlue
                    : props.theme.colors.monochrome.concrete};
    }
    canvas {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
        opacity: 0.4;
        &:hover {
            opacity: 0.85;
        }
    }
`
