import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function getDynamicPricingApr(
    applicationId: string,
    approvalTermId: number,
    baseLtv: number,
    totalFinanced: number,
    abortController: AbortController,
) {
    try {
        const { data } = await axios.get(
            `${config.API_BASE_URL}/api/v3/applications/${applicationId}/dynamic-pricing/apr`,
            {
                params: {
                    approval_term_id: approvalTermId,
                    base_ltv: baseLtv,
                    total_financed: totalFinanced,
                },
                signal: abortController.signal,
                withCredentials: true,
            },
        )

        return data.calculated_apr
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get dynamic pricing: ${axiosError.message}`,
            axiosError,
            { applicationId },
        )

        throw new Error(axiosError.message)
    }
}
