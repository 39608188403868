import { useEffect } from 'react'

import { useFormikContext } from 'formik'
import _ from 'lodash'

function getElementAtTop(elements: HTMLElement[]) {
    let topPosition = Infinity
    let elementAtTop

    elements.forEach((element) => {
        if (element) {
            const rect = element.getBoundingClientRect()

            if (rect) {
                const topRelativeToViewport = rect.top

                if (topRelativeToViewport < topPosition) {
                    topPosition = topRelativeToViewport
                    elementAtTop = element
                }
            }
        }
    })

    return elementAtTop as HTMLElement | undefined
}

function extractObjectPaths(
    obj: Record<string, unknown>,
    path: string[] = [],
): string[] {
    return _.flatMap(_.entries(obj), ([key, value]) => {
        const newPath = [...path, key]

        if (_.isObject(value)) {
            return extractObjectPaths(value as Record<string, unknown>, newPath)
        }

        return [newPath.join('.')]
    })
}

export default function FocusTopFormError() {
    const { errors, isSubmitting, isValidating } = useFormikContext()

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            const keys = extractObjectPaths(errors)
            const elementsWithErrors: HTMLElement[] = []

            keys.forEach((key) => {
                const selector = `[name="${key}"]`
                const fallbackSelector = `[id="${key}"]`

                const errorElement = (document.querySelector(selector) ||
                    document.querySelector(
                        fallbackSelector,
                    )) as HTMLInputElement

                elementsWithErrors.push(errorElement)
            })

            if (elementsWithErrors.length > 0) {
                const elementAtTop = getElementAtTop(elementsWithErrors)

                if (elementAtTop) {
                    elementAtTop.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })

                    elementAtTop.focus({ preventScroll: true })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting, isValidating])

    return null
}
