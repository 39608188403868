import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react'

import styled from 'styled-components'

const ConditionalVisibilityContainer = styled.div<{ visible: boolean }>`
    display: ${(props) => (props.visible ? 'block' : 'none')};
`

export type VisibilityConditions = {
    [k: string]: boolean
}

type VisibilityContextInterface = {
    isReady: () => boolean
    updateCondition: (
        condition: keyof VisibilityConditions,
        value: boolean,
    ) => void
}

export const VisibilityContext = createContext<
    VisibilityContextInterface | undefined
>(undefined)

interface VisibilityContextProviderProps {
    children: React.ReactNode
    conditionsInitialState: VisibilityConditions
    childrenIfFalse: React.ReactNode
}

function VisibilityContextProvider(props: VisibilityContextProviderProps) {
    const { conditionsInitialState, children, childrenIfFalse } = props

    const [conditionsState, setConditionState] = useState(
        conditionsInitialState,
    )

    const updateCondition = useCallback(
        (conditionKey: keyof VisibilityConditions, value: boolean) => {
            if (conditionsState[conditionKey] === value) return

            setConditionState((prevConditionState) => {
                return {
                    ...prevConditionState,
                    [conditionKey]: value,
                }
            })
        },
        [setConditionState, conditionsState],
    )

    const isReady = useCallback(() => {
        return Object.entries(conditionsState).every(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([_key, value]) => value === true,
        )
    }, [conditionsState])

    const ctx = useMemo(() => {
        return {
            isReady,
            updateCondition,
        }
    }, [isReady, updateCondition])

    return (
        <VisibilityContext.Provider value={ctx}>
            {!isReady() && childrenIfFalse}
            <ConditionalVisibilityContainer
                visible={isReady()}
                data-testid="visibility-context-container"
            >
                {children}
            </ConditionalVisibilityContainer>
        </VisibilityContext.Provider>
    )
}

export default VisibilityContextProvider
export const useVisibilityContext = () => useContext(VisibilityContext)
