import React from 'react'

import { FieldInstructionBundle, FieldKit } from '../../index'
import FieldBuilder from '../FieldBuilder/FieldBuilder'

interface FieldGroupProps {
    fieldInstructionBundle: FieldInstructionBundle
    fieldKit?: FieldKit
}

function FieldGroup(props: FieldGroupProps) {
    const { fieldInstructionBundle, fieldKit } = props
    const fieldNames = Object.keys(fieldInstructionBundle)

    const elements = fieldNames.map((fieldName) => {
        const inputConfig = fieldInstructionBundle[fieldName]

        return (
            <FieldBuilder
                key={fieldName}
                fieldName={fieldName}
                fieldInstructionAny={inputConfig}
                fieldKit={fieldKit}
            />
        )
    })

    // The fragment is not useless here,
    // it turns an array of elements into a single element.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{elements}</>
}

export default FieldGroup

FieldGroup.defaultProps = {
    fieldKit: undefined,
}
