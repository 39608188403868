import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getClientApplication, getCollaterals } from 'api/application'
import { AxiosError } from 'axios'
import ReadOnlyApplicationStatus from 'components/ReadOnlyStatusHeader/ReadOnlyApplicationStatus'
import convertAppValues from 'containers/ApplicationSubmissionForms/PowersportsApplication/convertAppValues'
import convertCollateralValues from 'containers/ApplicationSubmissionForms/PowersportsApplication/convertCollateralValues'
import PowersportsApplicationForm from 'containers/ApplicationSubmissionForms/PowersportsApplication/PowersportsApplicationForm'
import { vehicleSelectionPowersports } from 'form/fields'
import ErrorPage from 'pages/ErrorPage'
import LoadingPage from 'pages/LoadingPage/LoadingPage'

import { Container, Signature } from './PowersportsReadOnly.styled'

interface ApplicationStatusProps {
    id: number
    first_name: string
    last_name: string
    status: string
    lender_name: string
    submitted_at: string
    created: string
}

export default function PowersportsReadOnly() {
    const { id } = useParams()
    const [error, setError] = useState<Error>()
    const [loading, setLoading] = useState(true)
    const [appData, setAppData] = useState({})
    const [appStatus, setAppStatus] = useState({} as ApplicationStatusProps)

    useEffect(() => {
        const abortController = new AbortController()

        if (id) {
            getClientApplication(id, abortController)
                .then((app) => {
                    const appValues = convertAppValues(app)

                    setAppData(appValues)
                    setAppStatus(app)

                    return getCollaterals(app.uuid).then((collaterals) => {
                        const collateralValues =
                            convertCollateralValues(collaterals)

                        if (collateralValues) {
                            setAppData({
                                ...appValues,
                                vehicle: {
                                    ...vehicleSelectionPowersports.config
                                        .initialValue,
                                    ...collateralValues,
                                    primary: {
                                        ...vehicleSelectionPowersports.config
                                            .initialValue.primary,
                                        ...collateralValues.primary,
                                    },
                                    secondary: {
                                        ...vehicleSelectionPowersports.config
                                            .initialValue.secondary,
                                        ...(collateralValues.secondary ?? {}),
                                    },
                                },
                            })
                        } else {
                            setAppData({ ...appValues })
                            setAppStatus(app)
                        }
                    })
                })
                .catch((appError) => {
                    if (appError.code === AxiosError.ERR_CANCELED) {
                        return
                    }

                    setError(appError)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [id])

    const applicantName = `${appStatus.first_name || ''} ${
        appStatus.last_name || ''
    }`.trim()

    if (loading) {
        return <LoadingPage />
    }

    if (error) {
        return (
            <ErrorPage
                title="Failed to load application"
                body={
                    'An error occurred when trying to load the application. ' +
                    'Please try again. If the problem persists, contact us.'
                }
                errorData={{ error, context: { application: { id } } }}
            />
        )
    }

    return (
        <Container>
            <ReadOnlyApplicationStatus
                id={appStatus.id}
                applicant={applicantName}
                status={appStatus.status}
                lender={appStatus.lender_name}
                submittedAt={appStatus.submitted_at}
                createdAt={appStatus.created}
            />
            <PowersportsApplicationForm
                isReadOnly
                isLoading={loading}
                overrideDefaultInitialValues={appData}
            />
            <Signature>Customer signature</Signature>
        </Container>
    )
}
