import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSecuritySettings } from 'api/dealership'

const loadSecuritySettings = createAsyncThunk(
    'dealership/loadSecuritySettings',
    async (securitySettingsId: number) => {
        return getSecuritySettings(securitySettingsId)
    },
)

export default loadSecuritySettings
