import React from 'react'

import { Tab } from '@octane/spark'
import { Field, FieldProps } from 'formik'

type FormikTabSelectProps = {
    name: string
    label: string
    items: TabItem[]
    initialValue: string
    info?: React.ReactNode
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange?: Function
    prefilled?: boolean
} & typeof defaultProps

const defaultProps = {
    info: <> </>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange: (value: string) => null,
    prefilled: false,
}

export type TabItem = {
    label: string
    value: string
    disabled?: boolean
}

function FormikTabSelect({
    name,
    label,
    items,
    initialValue,
    info,
    onChange,
    prefilled,
}: FormikTabSelectProps) {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                return (
                    <div>
                        <Tab
                            id={field.name}
                            testID={name}
                            value={field.value}
                            items={items}
                            label={label}
                            info={info}
                            onChange={(val: string) => {
                                const event = {
                                    target: {
                                        name,
                                        value: val,
                                    },
                                }

                                form.handleChange(event)
                                onChange(val)
                            }}
                            prefilled={prefilled}
                        />
                    </div>
                )
            }}
        </Field>
    )
}

FormikTabSelect.defaultProps = defaultProps

export default FormikTabSelect
