import React, { useRef, useState } from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'

import { Spinner } from '@octane/spark'
import Portal from 'components/Portal'

import Body from './Body'
import {
    ContainerAlerts,
    ContainerModal,
    ViewPortContainer,
    GlobalStyle,
    Overlay,
    ContainerLoading,
} from './DocumentViewerModal.styled'
import ErrorPanel from './ErrorPanel'
import Header from './Header'
import useDownloadFileAsBlob from './hooks/useDownloadFileAsBlob'
import { DownloadOptions, ResponseType } from './utils/downloadFileFromUrl'

export interface FileRequest {
    name: string
    url: string
    responseType: ResponseType
    allowDownload: boolean
    downloadOptions?: DownloadOptions
}

export interface Props {
    fileRequest: FileRequest | null
}

export default function DocumentViewerModal({ fileRequest }: Props) {
    const portalContentRef = useRef<HTMLDivElement>(null)

    const [fileViewerIsLoading, setFileViewerIsLoading] =
        useState<boolean>(true)

    const { isDownloading, failed, fileBlobUrl } = useDownloadFileAsBlob(
        fileRequest ? fileRequest.url : null,
        fileRequest ? fileRequest.responseType : null,
        fileRequest ? fileRequest.downloadOptions : null,
    )

    const showFileViewerPanel = !isDownloading && !failed && !!fileBlobUrl
    const showErrorPanel = !!failed
    const isOpen = !!fileRequest

    const renderFileViewerPanel = (
        fileName: string,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        fileBlobUrl: string,
        allowDownload: boolean,
    ) => {
        return (
            <ContainerModal
                isVisible={!fileViewerIsLoading}
                data-testid="DocumentViewerModal__FileViewerPanel"
            >
                <Header
                    fileName={fileName}
                    fileBlobUrl={fileBlobUrl}
                    allowDownload={allowDownload}
                />
                <Body
                    fileName={fileName}
                    fileUrl={fileBlobUrl}
                    cbLoadEnd={() => {
                        setFileViewerIsLoading(false)
                    }}
                />
            </ContainerModal>
        )
    }

    const renderLoadingPanel = (state: TransitionStatus) => {
        return (
            <ContainerLoading
                state={state}
                data-testid="DocumentViewerModal__LoadingPanel"
            >
                <Spinner
                    size={100}
                    speed={0.7}
                    colored
                    shadow
                    background="grey"
                />
            </ContainerLoading>
        )
    }

    const renderErrorPanel = (fileName: string) => {
        return (
            <ContainerAlerts data-testid="DocumentViewerModal__ErrorPanel">
                <ErrorPanel fileName={fileName} />
            </ContainerAlerts>
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const renderContent = (state: TransitionStatus) => {
        if (!isOpen) return null

        if (showFileViewerPanel)
            return (
                <>
                    {renderFileViewerPanel(
                        fileRequest.name,
                        fileBlobUrl,
                        fileRequest.allowDownload,
                    )}
                </>
            )

        if (showErrorPanel) {
            return renderErrorPanel(fileRequest.name)
        }

        return renderLoadingPanel(state)
    }

    return (
        <>
            <GlobalStyle isOpen={isOpen} />
            <Transition
                nodeRef={portalContentRef}
                in={isOpen}
                timeout={200}
                unmountOnExit
                appear
            >
                {(state) => (
                    <Portal>
                        <ViewPortContainer
                            ref={portalContentRef}
                            data-testid="DocumentViewerModal__ViewPortContainer"
                        >
                            <Overlay state={state} />
                            {renderContent(state)}
                        </ViewPortContainer>
                    </Portal>
                )}
            </Transition>
        </>
    )
}
