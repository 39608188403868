import isJsonObject from './isJsonObject'

export default function extractAttributeFromJson(
    object: Record<string, unknown>,
    path: string,
): unknown {
    if (!isJsonObject(object)) return undefined

    const pathKeys = path.split('.').reverse()
    let targetObject = object
    let targetAttributeValue: unknown

    while (pathKeys.length > 0) {
        const key = pathKeys.pop()

        if (!(key && Object.getOwnPropertyNames(targetObject).includes(key)))
            break

        if (pathKeys.length === 0) {
            targetAttributeValue = targetObject[key]
            break
        } else {
            if (!isJsonObject(targetObject[key])) break

            targetObject = targetObject[key] as Record<string, unknown>
        }
    }

    return targetAttributeValue
}
