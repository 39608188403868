import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import { registerMfaErrorResponseHandler } from 'api/auth/mfa'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'
import { applyBrandConfig } from 'utils/applyBrandConfig'

import App from './App'
import store from './state/store'

import './index.css'

registerMfaErrorResponseHandler()

function RootComponent(): React.ReactElement {
    useEffect(() => {
        try {
            applyBrandConfig()
        } catch (error: unknown) {
            OctaneLogger.error('Failed to apply brandConfig title and favicon')
        }
    }, [])

    return (
        <React.StrictMode>
            <HashRouter>
                <Provider store={store}>
                    <App />
                </Provider>
            </HashRouter>
        </React.StrictMode>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<RootComponent />)
