import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Title = styled.h1`
    ${typography.Heading24Bold};
    color: ${({ theme }) => theme.colors.monochrome.jetlineBlack};
    padding: 0;
    margin: 0 0 0 0;
`

export const CardContent = styled.div`
    max-width: 520px;
    text-align: center;
    margin-left: 0px;
`

export const Content = styled.p`
    ${typography.BodyCopy16};
    color: ${({ theme }) => theme.colors.monochrome.ravenSub};
    text-align: left;
    margin-top: 20px;
`

export const DataContainer = styled.div`
    display: flex;
    width: 716px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.monochrome.white};
    margin-bottom: 40px;
`
export const RadioButtonContainer = styled.div`
    display: flex;
    width: 716px;
    padding: 0px;
    flex-direction: row;
    align-items: left;
    gap: 20px;
`
export const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
`

export const CollateralDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
`

export const CollateralOptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 20px;
    margin-top: 20px;
`
export const CollateralData = styled.span`
    ${typography.BodyCopy16};
    color: ${({ theme }) => theme.colors.monochrome.jetlineBlack};
`

export const CheckBoxContent = styled.div`
    margin-top: 20px;
`
export const TradeInQuestionContent = styled.p`
    ${typography.BodyCopy16};
    color: ${({ theme }) => theme.colors.monochrome.ravenSub};
    text-align: left;
    margin-top: 40px;
`
