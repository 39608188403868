import styled from 'styled-components'

import { typography } from '../../assets/styles'

export const Title = styled.h1`
    ${typography.Hero36};
    padding: 0;
    margin: 40px 0 20px 0;
`

export const Divisor = styled.hr`
    background-color: ${(props) => props.theme.colors.monochrome.concrete};
    height: 1px;
    margin-bottom: 40px;
`

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
`

export const OutletContainer = styled.div`
    flex-grow: 1;
    margin-left: 30px;
`
