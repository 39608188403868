import { useEffect } from 'react'

import AssetType from 'state/enums/AssetType'
import LoadingStatus from 'state/enums/LoadingStatus'
import { loadUser } from 'state/user/actions'

import { UseUserInterface } from './types/useUser.types'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'

const useUser = (): UseUserInterface => {
    const dispatch = useAppDispatch()
    const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn)
    const status = useAppSelector((state) => state.user.status)
    const user = useAppSelector((state) => state.user.data)

    const isAutoDealership =
        user?.dealership?.primaryAssetType === AssetType.AUTOMOBILE

    const isRVDealership =
        user?.dealership?.primaryAssetType === AssetType.RECREATIONAL_VEHICLE

    const isPowersportsDealership =
        user?.dealership?.primaryAssetType === AssetType.POWERSPORTS_VEHICLE

    const isMarineDealership =
        user?.dealership?.primaryAssetType === AssetType.MARINE

    const assetTypes = user?.dealership?.assetTypes || [
        AssetType.POWERSPORTS_VEHICLE,
    ]

    useEffect(() => {
        if (status === LoadingStatus.IDLE) {
            dispatch(loadUser())
        }
    }, [status, dispatch])

    return {
        isLoggedIn,
        user,
        status,
        isAutoDealership,
        isRVDealership,
        isPowersportsDealership,
        isMarineDealership,
        assetTypes,
    }
}

export default useUser
