import React from 'react'

import { VehicleWriteIn } from 'containers/VehicleWriteIn'
import AssetType from 'state/enums/AssetType'

import { FieldConfigBase, FieldProps } from '../../containers/FormBuilder'

export interface VehicleWriteInBaseFieldConfig extends FieldConfigBase {
    assetType: AssetType
}

export default function VehicleWriteInBaseField(
    props: FieldProps<VehicleWriteInBaseFieldConfig>,
) {
    const { fieldConfig, fieldKit } = props

    return (
        <VehicleWriteIn assetType={fieldConfig.assetType} formik={fieldKit} />
    )
}
