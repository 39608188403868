import AssetType from 'state/enums/AssetType'
import LoadingStatus from 'state/enums/LoadingStatus'

export type UserSecuritySettings = {
    passwordRotation: string
    sessionInactivity: string
}

export type DealershipAddressInfo = {
    street?: string
    city?: string
    state?: string
    zipCode?: string
}

export type DealershipInfo = {
    id: number
    name: string
    phoneNumber: string
    openInvitationsLimit: number
    address?: string
    addressInfo?: DealershipAddressInfo
    type?: string
    faxNumber?: string
    onWatchList?: boolean
    redirectUrl?: string
    isTheClosingDocsEnabled?: boolean
    isPrequalEnabled?: boolean
    isSafeCheckEnabled?: boolean
    chain?: DealershipChain
    principal?: DealershipPrincipal
    hasMultiUnitEligibility?: string
    mfaEnabled?: boolean
    securitySettingsId?: number
    assetTypes?: AssetType[]
    primaryAssetType?: AssetType
    flexLink?: string | null
    flexPdf?: string
    isAgreementSignedWithRoadrunner?: boolean
}

export type DealershipPrincipal = {
    firstName: string
    lastName?: string
    jobTitle?: string
    phoneNumber: string
    phoneNumberExtension?: string
    emailAddress: string
}

export type DealershipChain = {
    name: string
}

export type User = {
    id: number
    username: string
    uuid: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    mfaPhoneNumber: string
    mfaVerified: boolean
    dealerPortalUrl?: string
    passwordInfoMessage: string
    securitySettings: UserSecuritySettings
    groups: string[]
    permissions: string[]
    levelOfAccess?: string
    applicationAccess?: string
    dealership?: DealershipInfo
    intercomHash?: string
    actualNumberOfEndUsers?: number
    lastLogin?: string
    dateJoined?: string
    isLender: boolean
}

export interface UserState {
    isLoggedIn: boolean
    data?: User
    status: LoadingStatus
    error?: string
}

export const UserPermissions = {
    CAN_DEACTIVATE_USERS_IN_SAME_DEALERSHIP:
        'dealer.can_deactivate_users_in_same_dealership',
    CAN_UPDATE_GROUPS_FOR_USERS_IN_SAME_DEALERSHIP:
        'dealer.can_update_groups_for_users_in_same_dealership',
    CAN_CREATE_NEW_USERS_IN_SAME_DEALERSHIP:
        'dealer.can_create_new_users_in_same_dealership',
    CAN_UPDATE_USERS_IN_SAME_DEALERSHIP:
        'dealer.can_update_users_in_same_dealership',
    CAN_VIEW_DEALERSHIP_SETTINGS: 'dealer.can_view_dealership_settings',
}

export default UserState
