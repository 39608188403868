// this was moved to a separate file due to a "circular import" problem in one of the tests

import styled from 'styled-components'

import { FieldInstructionAny } from '../FormBuilder'

export function gridColumnWrapper(
    fieldInstructionAny: FieldInstructionAny,
    gridColumnDefinition: string,
): FieldInstructionAny {
    const wrapper = styled.div`
        grid-column: ${gridColumnDefinition}};
    `

    if (typeof fieldInstructionAny === 'function') {
        return (formValues, fieldKit) => {
            const fieldInstruction = fieldInstructionAny(formValues, fieldKit)

            return {
                ...fieldInstruction,
                WrapperComponent: wrapper,
            }
        }
    }

    return {
        ...fieldInstructionAny,
        WrapperComponent: wrapper,
    }
}
