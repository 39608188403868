import React from 'react'

import { Placement, SpacedItems } from './FormGridPlacement.styled'

type Props = {
    gridArea?: string
    children: React.ReactNode
} & typeof defaultProps

const defaultProps = {
    gridArea: '1 / 1 / 1 / 1',
}

export default function FormGridPlacement({ gridArea, children }: Props) {
    return (
        <Placement gridArea={gridArea}>
            <SpacedItems>{children}</SpacedItems>
        </Placement>
    )
}

FormGridPlacement.defaultProps = defaultProps
