/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback } from 'react'

import { Pagination } from '@octane/spark'
import { ColumnDef } from '@tanstack/react-table'
import { AssignToPerson } from 'api/flexLinks/getAssignToPersons'
import Table from 'components/Table'
import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import { setPage } from 'state/dealership'
import { FlexLink } from 'state/dealership/DealershipState'

import { buildAssignedToId, findPerson } from '../../utils'
import Actions from '../Actions'
import { AssignedTo } from '../TableColumns'
import { PaginationWrapper } from './AssignFlexLinksTable.styled'

const LINK_LABEL: ColumnDef<FlexLink> = {
    header: 'Link Label',
    accessorKey: 'label',
    size: 150,
}

const ASSIGNED_TO: ColumnDef<FlexLink & { assignedTo: string }> = {
    header: 'Assigned to',
    accessorKey: 'assignedTo',
    cell: ({ row }) => (
        <AssignedTo name={row.original.assignedTo || 'Unassigned'} />
    ),
    size: 150,
}

type AssignFlexLinksTableProps = {
    persons: AssignToPerson[]
    onUpdateLink: (flexLink: FlexLink) => void
}

function AssignFlexLinksTable({
    persons,
    onUpdateLink,
}: Readonly<AssignFlexLinksTableProps>): JSX.Element {
    const dispatch = useAppDispatch()
    const flexLinksState = useAppSelector((state) => state.dealership.flexLinks)
    const dealershipFlexLinks: FlexLink[] = flexLinksState.data
    const { page, pages } = flexLinksState.meta

    const handleChangePage = useCallback(
        (nextPage: number) => {
            dispatch(setPage({ page: nextPage, entityType: 'flexLinks' }))
        },
        [dispatch],
    )

    const startIndex = (page - 1) * 10
    const endIndex = startIndex + 10
    const currentPageItems = dealershipFlexLinks.slice(startIndex, endIndex)

    // Build the assignedTo column data
    const items = currentPageItems.map((flexLink: FlexLink) => {
        let person = { name: 'Unassigned' }

        if (flexLink.dealerUserId || flexLink.salesPersonId) {
            person = findPerson(
                persons,
                buildAssignedToId(
                    flexLink.dealerUserId,
                    flexLink.salesPersonId,
                ),
            )
        }

        return {
            ...flexLink,
            assignedTo: person?.name ?? 'Unassigned',
        }
    })

    return (
        <>
            <Table
                columns={[
                    LINK_LABEL,
                    ASSIGNED_TO,
                    {
                        header: 'Link',
                        accessorKey: 'link',
                        cell: ({ row }) => (
                            <span
                                role="button"
                                tabIndex={0}
                                onMouseDown={() => onUpdateLink(row.original)}
                            >
                                {row.original.link}
                            </span>
                        ),
                        size: 200,
                    } as ColumnDef<FlexLink>,
                    {
                        id: 'actions',
                        accessorFn: (flexLinkInfo) => flexLinkInfo,
                        cell: ({ getValue }) => (
                            <Actions value={getValue()} onEdit={onUpdateLink} />
                        ),
                    } as ColumnDef<FlexLink>,
                ]}
                data={items}
            />

            {pages > 0 && (
                <PaginationWrapper>
                    <Pagination.PaginationBar
                        rounded
                        currentPage={page}
                        pageCount={pages}
                        onChangePage={handleChangePage}
                    />
                </PaginationWrapper>
            )}
        </>
    )
}

export default React.memo(AssignFlexLinksTable)
