import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function deleteUser(userId: number) {
    try {
        const { status } = await axios.delete(
            `${config.API_BASE_URL}/api/v3/users/${userId}/`,
            {
                withCredentials: true,
            },
        )

        return status
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to delete user: ${axiosError.message}`,
            axiosError,
        )

        throw axiosError
    }
}
