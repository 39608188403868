import { ApiResponse } from 'api/types'
import { InvitationDataType } from 'state/invitation/InvitationState'

export function ValidateInvitationResponseParser(
    payload: ApiResponse,
): InvitationDataType {
    return {
        firstName: payload.data.first_name as string,
        dealershipName: payload.data.dealership_name as string,
        dealershipId: payload.data.dealership_id as number,
        email: payload.data.email as string,
    }
}
