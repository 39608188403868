import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const Divider = styled.hr`
    background-color: ${(props) => props.theme.colors.monochrome.concrete};
    height: 1px;
    width: 100%;
    margin-bottom: 20px;
`

export const NotificationContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const NotificationName = styled.div`
    ${typography.Copy16Bold};
`

export const NotificationDescription = styled.div`
    ${typography.BodyCopy16};
    color: ${(props) => props.theme.colors.monochrome.asphalt};
    flex-basis: 640px;

    @media (max-width: 1350px) {
        max-width: 320px;
    }
`

export const NotificationContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-width: 250px;

    @media (max-width: 1200px) {
        margin-top: 16px;
    }
`

export const NotificationBody = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
    }
`
