import React from 'react'

import {
    NoticeBox,
    NoticeBoxColors,
    NoticeBoxVariants,
    VsExclamationIcon,
} from '@octane/spark'
import CASPartner from 'state/enums/CASPartner'

import FormSection from '../FormSection'
import { BoldWarning } from './CASPartnerCopyWarning.styled'

const warningTextComponentMap: Record<string, JSX.Element> = {
    [CASPartner.ROUTE_ONE]: (
        <>
            <BoldWarning> Please Note</BoldWarning> Applications resubmitted
            through through this page will not appear in your RouteOne account.
            Only applications resubmitted through RouteOne will appear in your
            account.
        </>
    ),
    [CASPartner.DEALERTRACK]: (
        <>
            <BoldWarning> Please Note</BoldWarning> Applications resubmitted
            through this page will not appear in your Dealertrack account. Only
            applications resubmitted through Dealertrack will appear in your
            account.
        </>
    ),
}

export default function CASPartnerCopyWarning(props: {
    partner?: string | CASPartner
    isCopy?: boolean
    sectionWidth?: string
    sectionMargin?: string
}): JSX.Element | null {
    const { partner, isCopy, sectionWidth, sectionMargin } = props

    if (isCopy && partner && warningTextComponentMap[partner] !== undefined) {
        return (
            <FormSection
                title=""
                subtitle=""
                width={sectionWidth}
                margin={sectionMargin}
            >
                <NoticeBox
                    content={warningTextComponentMap[partner]}
                    heading=""
                    icon={VsExclamationIcon}
                    color={NoticeBoxColors.NOTE}
                    variant={NoticeBoxVariants.BOX_HIGHLIGHTED}
                />
            </FormSection>
        )
    }

    return null
}

CASPartnerCopyWarning.defaultProps = {
    partner: undefined,
    isCopy: false,
    sectionWidth: undefined,
    sectionMargin: undefined,
}
