import AssetType from 'state/enums/AssetType'

export const getIFrameNewsPath = (
    RVDealership: boolean,
    CWCaptive: boolean,
    RidenowCaptive: boolean,
    MarineDealership: boolean,
): string => {
    if (CWCaptive) {
        return 'good-sam-news'
    }

    if (RidenowCaptive) {
        return 'ride-now-news'
    }

    if (RVDealership) {
        return 'rv-dealer-news'
    }

    if (MarineDealership) {
        return 'marine-dealer-news'
    }

    return 'news'
}

export interface FramePath {
    name: string
    path: string
}

export const getIFrameNewsPathAsList = (arrayType: string[]): FramePath[] => {
    const paths = []

    if (arrayType.includes(AssetType.POWERSPORTS_VEHICLE)) {
        paths.push({ path: 'news', name: 'Powersports' })
    }

    if (arrayType.includes(AssetType.MARINE)) {
        paths.push({ path: 'marine-dealer-news', name: 'Marine' })
    }

    return paths
}
