import React from 'react'

import { Spinner } from '@octane/spark'

import { SpinnerStyle } from './LoadingPage.styled'

export default function LoadingPage() {
    return (
        <SpinnerStyle>
            <div data-testid="loadingSpinner">
                <Spinner background="grey" shadow />
            </div>
        </SpinnerStyle>
    )
}
