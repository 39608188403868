import React from 'react'

import { FormikTabSelect } from 'components/FormikFields'

interface ConditionTabProps {
    readonly prefilled?: boolean
}

const defaultProps = {
    prefilled: false,
}

export default function ConditionTab({ prefilled }: ConditionTabProps) {
    const conditions = [
        { value: 'new', label: 'New' },
        { value: 'used', label: 'Pre-owned' },
    ]

    return (
        <FormikTabSelect
            name="vehicle.condition"
            label="Condition"
            items={conditions}
            initialValue="new"
            prefilled={prefilled}
        />
    )
}

ConditionTab.defaultProps = defaultProps
