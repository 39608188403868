export default function camelCase(str: string) {
    if (str.length < 1) {
        return ''
    }

    const wordsArray = str.toLowerCase().match(/\b(\w+)\b/g)

    const camelCased = wordsArray?.map((word, index) => {
        if (index === 0) {
            return word
        }

        return word.charAt(0).toUpperCase() + word.substring(1)
    })

    return camelCased?.join('')
}
