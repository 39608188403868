/* eslint-disable react/prop-types */
import React from 'react'

import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

function FadeIn({
    duration = 300,
    delay = 0,
    children,
    ...delegated
}: {
    duration: number
    delay: number
    // eslint-disable-next-line react/require-default-props
    children?: any // eslint-disable-line @typescript-eslint/no-explicit-any
    // eslint-disable-next-line react/require-default-props
    delegated?: {
        new (): CSSStyleDeclaration
        prototype: CSSStyleDeclaration
    }
}) {
    return (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <Wrapper
            {...delegated}
            style={{
                ...(delegated || {}),
                animationDuration: `${duration}ms`,
                animationDelay: `${delay}ms`,
            }}
        >
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    @media (prefers-reduced-motion: no-preference) {
        animation-name: ${fadeIn};
        animation-fill-mode: backwards;
    }
`

export default FadeIn
