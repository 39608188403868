import React from 'react'

import { Container, Header, Title } from './Card.styled'

type Props = {
    title?: string
    children: React.ReactNode
    rightComponent?: React.ReactNode
} & typeof defaultProps

const defaultProps = {
    title: '',
    rightComponent: <> </>,
}

function Card({ title, children, rightComponent, ...props }: Props) {
    return (
        <Container {...props}>
            {title && (
                <Header>
                    <Title>{title}</Title>
                    {rightComponent}
                </Header>
            )}
            {children}
        </Container>
    )
}

Card.defaultProps = defaultProps

export default Card
