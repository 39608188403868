import React from 'react'
import { Page, Document, PDFPageProxy, pdfjs } from 'react-pdf'

import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'

import { ImageMeta } from './hooks/useFileViewer'
import { Container, PdfViewerPage, PdfViewerViewport } from './PdfViewer.styled'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import PdfViewerNavigator from './PdfViewerNavigator'

// eslint-disable-next-line max-len
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export type Props = {
    containerRef: React.RefObject<HTMLDivElement>
    pdfRef: React.RefObject<HTMLDivElement>
    image: ImageMeta
    handleContextMenu: (blockDownload: boolean, e: Event) => void
    handlePageLoad: (loadedPage: PDFPageProxy) => void
    documentUrl: string
    numberOfPages: number
    openPage: (n: number) => void
    page: number
    containerHeight: number
    blockDownload?: boolean
    handleImgWheel: (e: WheelEvent) => void
    handlePdfLoad: (document: PDFDocumentProxy) => void
    canvasRef: React.RefObject<HTMLCanvasElement>
}

function PdfViewer({
    containerRef,
    image,
    pdfRef,
    documentUrl,
    page,
    openPage,
    numberOfPages,
    handlePageLoad,
    handleContextMenu,
    containerHeight,
    blockDownload,
    handleImgWheel,
    handlePdfLoad,
    canvasRef,
}: Props): JSX.Element {
    return (
        <Container>
            <PdfViewerViewport
                ref={containerRef}
                onWheel={(e) => handleImgWheel(e.nativeEvent)}
            >
                <PdfViewerPage
                    ref={pdfRef}
                    onContextMenu={(e) =>
                        handleContextMenu(!!blockDownload, e.nativeEvent)
                    }
                >
                    <Document
                        file={documentUrl}
                        loading=""
                        onLoadSuccess={(e: PDFDocumentProxy) =>
                            handlePdfLoad(e)
                        }
                    >
                        <Page
                            canvasRef={canvasRef}
                            pageNumber={page}
                            height={containerHeight * 0.8}
                            onLoadSuccess={handlePageLoad}
                            renderAnnotationLayer={false}
                            renderInteractiveForms={false}
                            renderTextLayer={false}
                            scale={image.scale}
                        />
                    </Document>
                </PdfViewerPage>
            </PdfViewerViewport>
            {numberOfPages > 1 && (
                <PdfViewerNavigator
                    documentUrl={documentUrl}
                    numberOfPages={numberOfPages}
                    page={page}
                    openPage={openPage}
                />
            )}
        </Container>
    )
}

PdfViewer.defaultProps = {
    blockDownload: true,
}

export default PdfViewer
