import styled from 'styled-components'

export const RecipientEmailAddress = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    max-width: 400px;
    justify-content: space-between;
`

export const RecipientEmailText = styled.div`
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 8px;
    margin-right: 10px;
    font-weight: bold;
    margin-right: 32px;
    min-height: 20px;
`

export const RecipientContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const DeleteWrapper = styled.div`
    cursor: pointer;
`
