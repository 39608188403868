export default function isLightspeed(): boolean {
    if (sessionStorage.getItem('partner') === 'lightspeed') {
        return true
    }

    if (window.location.href.includes('partner/lightspeed')) {
        sessionStorage.setItem('partner', 'lightspeed')

        return true
    }

    return false
}
