import { Moment } from 'moment'

export class MfaWaitError extends Error {
    public waitUntil: Moment

    constructor(msg: string, waitUntil: Moment) {
        super(msg)
        this.waitUntil = waitUntil
    }
}
