import React from 'react'

import brandConfig from 'branding'

import BrandLogo from './BrandLogo'
import { NavbarWrapper, NavbarContainer, NavbarNav } from './Navbar.styled'
import NavbarDropdown from './NavbarDropdown/NavbarDropdown'
import NavbarItem from './NavbarItem'
import OctaneLogo from './OctaneLogo'

export interface NavbarProps {
    children?: React.ReactNode
}

export default function Navbar({ children }: NavbarProps): JSX.Element {
    return (
        <NavbarWrapper
            role="navigation"
            background={brandConfig.theme.navbar.background}
        >
            <NavbarContainer className={brandConfig.whitelabelId}>
                <div data-testid="brand-logo">
                    {brandConfig.navbarLogo ? <BrandLogo /> : <OctaneLogo />}
                </div>
                <NavbarNav>{children}</NavbarNav>
            </NavbarContainer>
        </NavbarWrapper>
    )
}

Navbar.defaultProps = {
    children: null,
}

Navbar.Item = NavbarItem
Navbar.Dropdown = NavbarDropdown
