import { typography } from 'assets/styles'
import FormSection from 'components/FormSection'
import styled from 'styled-components'

export const StyledFormSection = styled(FormSection)`
    border: none;

    &:not(:last-child) {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`

export const StyledFinanceSection = styled(FormSection)`
    padding-bottom: 0;
    padding-top: 30px;
    border: none;
`

export const StyledApplicationSection = styled(FormSection)`
    padding-bottom: 0;
    border: none;

    h3 {
        ${typography.Heading16Bold}
    }

    button {
        font-family: Karla, Helvetica, san-serif;
    }
`

export const StyledConsentSection = styled(FormSection)`
    padding: 30px 30px 0 30px;
    border: none;
`

export const FinanceRuleLine = styled.div`
    border: 0.5px solid #d5d9e0;

    @media print {
        border: none;
    }
`

export const ApplicantRuleLine = styled.div`
    border: 0.5px solid #d5d9e0;

    @media print {
        border: none;
    }

    &:nth-child(4) {
        margin-bottom: 20px;
    }
`
