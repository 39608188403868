import { Checkbox } from '@octane/spark'
import styled from 'styled-components'

export const FormikCheckbox = styled(Checkbox)`
    &:disabled {
        color: ${(props) => props.theme.colors.monochrome.asphalt};
    }
`
export const ConsentLabel = styled.div`
    display: flex;

    > * {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        display: flex;
        flex-direction: row-reverse;
        position: relative;
    }
    > * button {
        color: #2366b1;
    }
    > * .content-span {
        margin-left: 10px;
    }
    > * svg {
        top: -15%;
    }
    .print-only {
        display: none;
    }
`
