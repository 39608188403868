import React from 'react'

import { FormikTabSelect } from 'components/FormikFields'

export default function TrailerTab() {
    const options = [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
    ]

    return (
        <FormikTabSelect
            name="marine.trailer"
            label="Add a trailer?"
            items={options}
            initialValue="new"
        />
    )
}
