/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'

import { IconComponent } from '@octane/spark'
import { DropdownContent } from 'assets/styles/dropdown'
import brandConfig from 'branding'
import { DropdownWrapper } from 'containers/Navbar/LoggedInUser/LoggedInUser.styled'
import styled from 'styled-components'

import { DropdownCustomItem } from './DropdownCustomItem/DropdownCustomItem'
import { DropdownUrlItem } from './DropdownUrlItem/DropdownUrlItem'
import { NavbarDropdownList } from './NavbarDropdown.styled'
import NavbarDropdownButton from './NavbarDropdownButton/NavbarDropdownButton'

const NavbarDropdownContent = styled(DropdownContent)`
    top: 55px;
    color: ${brandConfig.theme.colors.monochrome.ravenSub};
`

export interface NavbarDropdownProps {
    id: string
    text: string
    icon: IconComponent
    children: React.ReactNode
}

export default function NavbarDropdown({
    id,
    icon,
    text,
    children,
}: NavbarDropdownProps): JSX.Element {
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const node = useRef<HTMLInputElement>(null)

    const clickOutside = (event: MouseEvent) => {
        if (node?.current?.contains(event.target as Node)) {
            return
        }

        setToggleDropdown(false)
    }

    useEffect(() => {
        document.addEventListener('click', clickOutside)

        return () => {
            document.removeEventListener('click', clickOutside)
        }
    }, [])

    return (
        <DropdownWrapper
            ref={node}
            className="NavbarItem"
            onClick={() => setToggleDropdown(!toggleDropdown)}
        >
            <NavbarDropdownList>
                <NavbarDropdownButton id={id} icon={icon} text={text} />
                {toggleDropdown && (
                    <NavbarDropdownContent>{children}</NavbarDropdownContent>
                )}
            </NavbarDropdownList>
        </DropdownWrapper>
    )
}

NavbarDropdown.UrlItem = DropdownUrlItem
NavbarDropdown.CustomItem = DropdownCustomItem
