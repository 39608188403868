import React from 'react'

import { Label } from '@octane/spark'

interface Props {
    value: string
}

function getColor(value: string) {
    if (value === 'Admin') return 'sapphire'

    if (value === 'Sales') return 'umber'

    return 'moss'
}

export default function LevelOfAccess({ value }: Props) {
    return <Label text={value} color={getColor(value)} />
}
