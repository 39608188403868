import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
    margin-left: 20px;
    display: flex;
    align-items: center;
    max-height: 60px;
    cursor: pointer;
    padding: 12px;

    &: hover {
        background-color: ${(props) =>
            props.theme.navbar.loggedInHoverBackground};
    }
`

export const Greeting = styled.div`
    ${typography.ButtonMedium16};
    color: ${(props) => props.theme.navbar.loggedInNameColor};
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const DropdownWrapper = styled.div`
    ${typography.BodyCopy16};
    line-height: 16px;
    color: ${(props) => props.theme.navbar.color};
    display: flex;
    align-items: center;
    flex-direction: row;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 0px;

    span {
        margin-right: 6px;
    }
`

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    position: relative;
`
