import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'
import { RawLeadActivity } from './types'

export default async function markLeadAsViewedByDealer(leadId: number) {
    try {
        const { data }: { data: RawLeadActivity } = await axios.post(
            // eslint-disable-next-line max-len
            `${config.API_BASE_URL}/api/v3/applications/${leadId}/leads-activities/mark-as-viewed/`,
            {},
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to mark lead ${leadId} as viewed.`,
            axiosError,
            {
                leadId,
            },
        )

        throw axiosError
    }
}
