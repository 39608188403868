import React, { ReactNode, useRef } from 'react'
import { Transition } from 'react-transition-group'

import { UiCloseIcon } from '@octane/spark'
import Portal from 'components/Portal'
import { useTheme } from 'styled-components'

import {
    GlobalStyle,
    PortalContent,
    Overlay,
    ContentWrapper,
    ModalWrapper,
    ActionContainer,
} from './Modal.styled'

export interface ModalProps {
    isOpen: boolean
    onClose: () => void
    showCloseButton?: boolean
    children: ReactNode
}

export default function Modal({
    isOpen,
    onClose,
    showCloseButton,
    children,
}: ModalProps) {
    const theme = useTheme()
    const portalContentRef = useRef<HTMLDivElement>(null)

    return (
        <>
            <GlobalStyle isOpen={isOpen} />
            <Transition
                nodeRef={portalContentRef}
                in={isOpen}
                timeout={200}
                unmountOnExit
                appear
            >
                {(state) => (
                    <Portal>
                        <PortalContent ref={portalContentRef}>
                            <ModalWrapper>
                                <Overlay onClick={onClose} state={state} />
                                <ContentWrapper state={state}>
                                    {showCloseButton && (
                                        <ActionContainer>
                                            <button
                                                type="button"
                                                onClick={onClose}
                                            >
                                                <UiCloseIcon
                                                    width={20}
                                                    height={20}
                                                    color={
                                                        theme.colors.brand
                                                            .rideOctaneBlue
                                                    }
                                                />
                                            </button>
                                        </ActionContainer>
                                    )}
                                    {children}
                                </ContentWrapper>
                            </ModalWrapper>
                        </PortalContent>
                    </Portal>
                )}
            </Transition>
        </>
    )
}

Modal.defaultProps = {
    showCloseButton: true,
}
