/**
 * This file just exposes window.config which comes from public/config.js
 * and is loaded by the browser before the app is loaded.
 */

type Config = {
    ENVIRONMENT: string
    LEGACY_APP_URL: string
    API_BASE_URL: string
    STATIC_DIST_BASE_URL: string
    LOYALTY_DASHBOARD_BASE_URL: string
    INTERCOM_APP_ID: string
    GOOGLE_TAG_MANAGER_CONTAINER_ID: string
    GOOGLE_TAG_MANAGER_AUTH: string
    GOOGLE_TAG_MANAGER_ENVIRONMENT: string
    AUTO_BOARD_MARKETING_CONTENT: string
    ROADRUNNER_LENDER_ID: number
}

declare global {
    interface Window {
        config: Config
    }
}

const config: Config = { ...window.config }

export default config
