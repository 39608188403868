import { BodyCopy12 } from 'assets/styles/typography'
import styled from 'styled-components'

export const FormContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    margin-bottom: 20px;

    @media (max-width: 1100px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`

export const MobilePhoneInfo = styled.div`
    ${BodyCopy12};
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.monochrome.ravenSub};

    a {
        color: ${({ theme }) => theme.colors.brand.rideOctaneBlue};
        text-decoration: underline;
        font-weight: 600;
        &:hover {
            text-decoration: underline;
        }
    }
`
