import React, { useState } from 'react'

import { Popover, Toggle } from '@octane/spark'
import Card from 'components/Card'
import MfaProfileSetupModal from 'components/MfaModal/MfaProfileSetupModal'
import ReAuthenticationModal from 'components/ReAuthentication'
import SecuritySettingsForm from 'containers/MyAccount/Security/SecuritySettingsForm'
import useUser from 'hooks/useUser'

import {
    CardMultifactorBody,
    Information,
    LabelWrapper,
    MFALabel,
} from './Security.styled'
import YourPassword from './YourPassword'

export default function SecurityPage(): JSX.Element {
    const [mfaModalOpen, setMfaModalOpen] = useState(false)
    const [reauthModalOpen, setReauthModalOpen] = useState(false)
    const { user } = useUser()

    const handleOpenMfaModal = () => {
        setTimeout(() => setMfaModalOpen(true), 300)
    }

    return (
        <div>
            <YourPassword />
            <Card title="Multifactor Authentication Setting">
                <CardMultifactorBody>
                    <LabelWrapper>
                        <MFALabel>Use MFA for added security.</MFALabel>
                        <Information>
                            This is required for some features and products
                        </Information>
                    </LabelWrapper>
                    {user?.mfaVerified && (
                        <Popover
                            trigger={
                                <Toggle
                                    checked
                                    disabled
                                    onChange={() => null}
                                />
                            }
                            id="mfaVerifiedPopover"
                        >
                            <div>
                                You have already configured an MFA number. You
                                will need to contact Octane in order to change
                                it.
                            </div>
                        </Popover>
                    )}
                    {user && !user.mfaVerified && (
                        <Toggle
                            checked={false}
                            disabled={false}
                            onChange={() => setReauthModalOpen(true)}
                        />
                    )}
                </CardMultifactorBody>
            </Card>
            {user && !user.isLender && (
                <Card title="Security Settings">
                    <SecuritySettingsForm />
                </Card>
            )}
            <MfaProfileSetupModal
                isOpen={mfaModalOpen}
                user={user}
                handleClose={() => setMfaModalOpen(false)}
            />
            <ReAuthenticationModal
                isOpen={reauthModalOpen}
                handleCallback={handleOpenMfaModal}
                handleClose={() => setReauthModalOpen(false)}
            />
        </div>
    )
}
