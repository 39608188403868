import { typography } from 'assets/styles'
import styled from 'styled-components'

export const CloseButtonWrapper = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
`

export const DownloadButtonWrapper = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
`

export const FileInfo = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
`

export const FileName = styled.div`
    text-align: center;
`

export const FileNameLabel = styled.span`
    ${typography.Copy24}
`

export const FileNameValue = styled.span`
    ${typography.Heading24Bold}
`

export const Container = styled.div<{ paddingLeft: boolean }>`
    flex-grow: 0;
    flex-shrink: 0;
    align-self: auto;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.monochrome.white};
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: ${(props) => (props.paddingLeft ? '100px' : '10px')};
    ${CloseButtonWrapper}:last-child {
        margin-left: auto;
    }
    z-index: 9999999;
`
