import React from 'react'

import { StErrorIcon } from '@octane/spark'

import CloseButton from './CloseButton'
import { CloseButtonWrapper, ErrorText } from './ErrorPanel.styled'

interface Props {
    fileName: string
}

export default function ErrorPanel({ fileName }: Props): JSX.Element {
    return (
        <>
            <CloseButtonWrapper>
                <CloseButton />
            </CloseButtonWrapper>
            <StErrorIcon />
            <ErrorText>
                It was not possible to download the file:{' '}
                <strong>{fileName}</strong>
            </ErrorText>
        </>
    )
}
