import React from 'react'

import { AmountAlignText, UiCloseIcon, UiPlusIcon } from '@octane/spark'
import {
    FieldComponent,
    FieldInstruction,
    FieldInstructionBundle,
    FieldInstructionCreator,
    FieldKit,
    FormValues,
    ValidatorConcrete,
} from 'containers/FormBuilder'
import CurrencyAmountField from 'form/fieldComponents/CurrencyAmountField'
import InputField from 'form/fieldComponents/InputField'
import InputFieldMasked from 'form/fieldComponents/InputFieldMasked'
import SelectField, {
    SelectFieldOnChange,
} from 'form/fieldComponents/SelectField'
import {
    FIELD_GREATER_THAN_ZERO,
    FIELD_NEGATIVE_NUMBER,
    FIELD_REQUIRED,
    FIELD_TOO_LONG,
    FIELD_TOO_SHORT,
    FIELD_VALUE_MONTHS,
} from 'form/validation/validationMessages'
import styled from 'styled-components'
import * as Yup from 'yup'

import { gridColumnWrapper } from '../../containers/ApplicationSubmissionForms/gridColumnWrapper'
import LinkButtonField from '../fieldComponents/LinkButtonField'
import { phoneNumberField } from './phoneNumberField'

export type EmploymentFieldNames = {
    employmentStatus: string
    employerMonthlyGross: string
    employerGrossAmount: string
    employerGrossInterval: string
    hasOtherIncome: string
    otherIncomeSource: string
    otherIncomeGrossAmount: string
    otherIncomeGrossInterval: string
    removeOtherIncome: string
    employerYears: string
    employerMonths: string
    jobTitle: string
    employerName: string
    workPhone: string
}

const EMPLOYMENT_STATUSES = {
    employed: { name: 'Employed', value: 'employed' },
    self: { name: 'Self-employed', value: 'self' },
    retired: { name: 'Retired', value: 'retired' },
    disability: { name: 'Disability', value: 'disability' },
    military: { name: 'Military', value: 'military' },
    tenNinetyNine: { name: '1099', value: '1099' },
    unemployed: { name: 'Unemployed', value: 'unemployed' },
    student: { name: 'Student', value: 'student' },
    homemaker: { name: 'Homemaker', value: 'homemaker' },
    other: { name: 'Other', value: 'other' },
}

export const INCOME_INTERVAL = {
    yearly: { name: <>Yearly</>, value: 'yearly' },
    monthly: { name: <>Monthly</>, value: 'monthly' },
    biweekly: { name: <>Biweekly</>, value: 'biweekly' },
    semiMonthly: { name: <>Semi-Monthly</>, value: 'semi-monthly' },
    weekly: { name: <>Weekly</>, value: 'weekly' },
}

function createFieldNameToDisabledEmploymentStatusesMap(
    fieldNames: EmploymentFieldNames,
) {
    // ensure the returned object contains a key for each employment field
    const emptyDefaults = Object.keys(fieldNames).reduce((acc, fieldName) => {
        acc[fieldName as keyof typeof fieldNames] = []

        return acc
    }, {} as { [key in keyof EmploymentFieldNames]: string[] })

    return {
        ...emptyDefaults,
        [fieldNames.employerGrossAmount]: [],
        [fieldNames.employerGrossInterval]: [],
        [fieldNames.otherIncomeSource]: [],
        [fieldNames.otherIncomeGrossAmount]: [],
        [fieldNames.otherIncomeGrossInterval]: [],
        [fieldNames.employerYears]: [
            EMPLOYMENT_STATUSES.retired.value,
            EMPLOYMENT_STATUSES.disability.value,
            EMPLOYMENT_STATUSES.military.value,
            EMPLOYMENT_STATUSES.tenNinetyNine.value,
            EMPLOYMENT_STATUSES.unemployed.value,
            EMPLOYMENT_STATUSES.student.value,
            EMPLOYMENT_STATUSES.homemaker.value,
        ],
        [fieldNames.employerMonths]: [
            EMPLOYMENT_STATUSES.retired.value,
            EMPLOYMENT_STATUSES.disability.value,
            EMPLOYMENT_STATUSES.military.value,
            EMPLOYMENT_STATUSES.tenNinetyNine.value,
            EMPLOYMENT_STATUSES.unemployed.value,
            EMPLOYMENT_STATUSES.student.value,
            EMPLOYMENT_STATUSES.homemaker.value,
        ],
        [fieldNames.jobTitle]: [
            EMPLOYMENT_STATUSES.retired.value,
            EMPLOYMENT_STATUSES.disability.value,
            EMPLOYMENT_STATUSES.military.value,
            EMPLOYMENT_STATUSES.tenNinetyNine.value,
            EMPLOYMENT_STATUSES.unemployed.value,
            EMPLOYMENT_STATUSES.student.value,
            EMPLOYMENT_STATUSES.homemaker.value,
        ],
        [fieldNames.employerName]: [
            EMPLOYMENT_STATUSES.retired.value,
            EMPLOYMENT_STATUSES.disability.value,
            EMPLOYMENT_STATUSES.military.value,
            EMPLOYMENT_STATUSES.tenNinetyNine.value,
            EMPLOYMENT_STATUSES.unemployed.value,
            EMPLOYMENT_STATUSES.self.value,
            EMPLOYMENT_STATUSES.student.value,
            EMPLOYMENT_STATUSES.homemaker.value,
        ],
    }
}

type FieldNameToDisabledEmploymentStatusesMap = ReturnType<
    typeof createFieldNameToDisabledEmploymentStatusesMap
>

function isFieldDisabledForCurrentEmploymentStatus(
    employmentStatusFieldName: EmploymentFieldNames['employmentStatus'],
    fieldName: string,
    formValues: FormValues,
    fieldNameToDisabledStatusesMap: FieldNameToDisabledEmploymentStatusesMap,
) {
    return fieldNameToDisabledStatusesMap[
        fieldName as keyof EmploymentFieldNames
    ].includes(formValues[employmentStatusFieldName])
}

const employerGrossAmount: FieldInstruction<typeof CurrencyAmountField> = {
    Component: CurrencyAmountField,
    config: {
        label: 'Gross Income Amount',
        placeholder: 'Income before taxes',
        alignText: AmountAlignText.LEFT,
        validator: Yup.number()
            .required(FIELD_REQUIRED)
            .max(
                1000000000,
                `
                        Gross income cannot be equal to, or greater
                        than, 1000000000
                    `,
            )
            .min(0, FIELD_NEGATIVE_NUMBER),
    },
}

const employerGrossInterval: FieldInstruction<typeof SelectField> = {
    Component: SelectField,
    config: {
        label: 'Gross Income Interval',
        tooltip: (
            <small>
                Interval choice is only used to calculate the Annual Gross
                Income, which is based off the Gross Income Amount
            </small>
        ),
        placeholder: 'Select one',
        options: Object.values(INCOME_INTERVAL).map(
            (statusOption) => statusOption,
        ),
        validator: Yup.string().required(FIELD_REQUIRED),
    },
}

const createAddOtherIncome = (
    fieldNamesMap: EmploymentFieldNames,
): FieldInstructionCreator<typeof LinkButtonField> => {
    return (formValues: FormValues, fieldKit: FieldKit) => {
        const hasOtherIncome = formValues[fieldNamesMap.hasOtherIncome]

        return {
            Component: !hasOtherIncome ? LinkButtonField : null,
            config: {
                icon: UiPlusIcon,
                label: 'Add other income',
                onClick: () => {
                    fieldKit.setFieldValue(fieldNamesMap.hasOtherIncome, true)
                },
            },
        }
    }
}

const createOtherIncomeSource = (
    fieldNamesMap: EmploymentFieldNames,
): FieldInstructionCreator<typeof InputField> => {
    return (formValues: FormValues) => {
        const hasOtherIncome = formValues[fieldNamesMap.hasOtherIncome]

        return {
            Component: hasOtherIncome ? InputField : null,
            config: {
                label: 'Other Gross Income Source',
                validator: hasOtherIncome
                    ? Yup.string()
                          .trim()
                          .min(2, FIELD_TOO_SHORT(2))
                          .max(64, FIELD_TOO_LONG(64))
                          .required(FIELD_REQUIRED)
                    : null,
            },
        }
    }
}

const createOtherIncomeGrossAmount = (
    fieldNamesMap: EmploymentFieldNames,
): FieldInstructionCreator<typeof CurrencyAmountField> => {
    return (formValues: FormValues) => {
        const hasOtherIncome = formValues[fieldNamesMap.hasOtherIncome]

        return {
            Component: hasOtherIncome ? CurrencyAmountField : null,
            config: {
                label: 'Other Gross Income',
                placeholder: '0',
                validator: hasOtherIncome
                    ? Yup.number()
                          .min(1, FIELD_GREATER_THAN_ZERO)
                          .required(FIELD_REQUIRED)
                    : null,
            },
        }
    }
}

const createOtherIncomeGrossInterval = (
    fieldNamesMap: EmploymentFieldNames,
): FieldInstructionCreator<typeof SelectField> => {
    return (formValues: FormValues) => {
        const hasOtherIncome = formValues[fieldNamesMap.hasOtherIncome]

        return {
            Component: hasOtherIncome ? SelectField : null,
            config: {
                label: 'Other Gross Income Interval',
                placeholder: 'Select one',
                tooltip: (
                    <small>
                        Interval choice is only used to calculate the Annual
                        Gross Income, which is based off the Gross Income Amount
                    </small>
                ),
                options: [{ name: <>&nbsp;</>, value: '' }].concat(
                    Object.values(INCOME_INTERVAL).map(
                        (statusOption) => statusOption,
                    ),
                ),
                validator: hasOtherIncome
                    ? Yup.string().required(FIELD_REQUIRED)
                    : null,
            },
        }
    }
}

const removeOtherIncomeWrapper = styled.div`
    margin-top: 34px;
`

const createRemoveOtherIncome = (
    fieldNamesMap: EmploymentFieldNames,
): FieldInstructionCreator<typeof LinkButtonField> => {
    return (formValues: FormValues, fieldKit: FieldKit) => {
        const hasOtherIncome = formValues[fieldNamesMap.hasOtherIncome]

        return {
            Component: hasOtherIncome ? LinkButtonField : null,
            config: {
                icon: UiCloseIcon,
                iconWidth: 20,
                iconHeight: 20,
                onClick: () => {
                    fieldKit.setFieldValue(fieldNamesMap.hasOtherIncome, false)
                },
            },
            WrapperComponent: removeOtherIncomeWrapper,
        }
    }
}

const createEmployerWorkPhone = (fieldNamesMap: EmploymentFieldNames) => {
    return (formValues: FormValues) => {
        // work phone is optional for these
        const optionalStatuses = [
            EMPLOYMENT_STATUSES.retired.value,
            EMPLOYMENT_STATUSES.disability.value,
            EMPLOYMENT_STATUSES.self.value,
            EMPLOYMENT_STATUSES.unemployed.value,
            EMPLOYMENT_STATUSES.student.value,
            EMPLOYMENT_STATUSES.homemaker.value,
            EMPLOYMENT_STATUSES.other.value,
        ]

        const isOptional = optionalStatuses.includes(
            formValues[fieldNamesMap.employmentStatus],
        )

        let validator: ValidatorConcrete
        const existingValidator = phoneNumberField.config.validator

        if (typeof existingValidator === 'function') {
            validator = existingValidator(formValues)
        } else {
            validator = existingValidator
        }

        if (isOptional && validator) {
            validator = validator.notRequired()
        }

        return {
            ...phoneNumberField,
            config: {
                ...phoneNumberField.config,
                label: 'Work Phone Number',
                validator,
            },
        }
    }
}

type EmploymentStatusLinkedInstructionCreator<C extends FieldComponent> = (
    fieldNamesMap: EmploymentFieldNames,
    fieldNameToDisabledStatusesMap: FieldNameToDisabledEmploymentStatusesMap,
) => FieldInstructionCreator<C>

// eslint-disable-next-line max-len
const createEmployerYearsFieldInstruction: EmploymentStatusLinkedInstructionCreator<
    typeof InputFieldMasked
> = (fieldNamesMap, fieldNameToDisabledStatusesMap) => {
    return (formValues) => {
        const isDisabled = isFieldDisabledForCurrentEmploymentStatus(
            fieldNamesMap.employmentStatus,
            fieldNamesMap.employerYears,
            formValues,
            fieldNameToDisabledStatusesMap,
        )

        const validator = isDisabled
            ? null
            : Yup.number()
                  .min(0, FIELD_NEGATIVE_NUMBER)
                  .required(FIELD_REQUIRED)

        return {
            Component: InputFieldMasked,
            config: {
                label: 'Years at Job',
                mask: '00',
                disabled: isDisabled,
                validator,
            },
        }
    }
}

// eslint-disable-next-line max-len
const createEmployerMonthsFieldInstruction: EmploymentStatusLinkedInstructionCreator<
    typeof InputFieldMasked
> = (fieldNamesMap, fieldNameToDisabledStatusesMap) => {
    return (formValues) => {
        const isDisabled = isFieldDisabledForCurrentEmploymentStatus(
            fieldNamesMap.employmentStatus,
            fieldNamesMap.employerMonths,
            formValues,
            fieldNameToDisabledStatusesMap,
        )

        const validator = isDisabled
            ? null
            : Yup.number()
                  .min(0, FIELD_VALUE_MONTHS)
                  .max(11, FIELD_VALUE_MONTHS)
                  .required(FIELD_REQUIRED)

        return {
            Component: InputFieldMasked,
            config: {
                label: 'Months at Job',
                mask: '00',
                disabled: isDisabled,
                validator,
            },
        }
    }
}

const createJobTitleFieldInstruction: EmploymentStatusLinkedInstructionCreator<
    typeof InputField
> = (fieldNamesMap, fieldNameToDisabledStatusesMap) => {
    return (formValues) => {
        const isDisabled = isFieldDisabledForCurrentEmploymentStatus(
            fieldNamesMap.employmentStatus,
            fieldNamesMap.jobTitle,
            formValues,
            fieldNameToDisabledStatusesMap,
        )

        const validator = isDisabled
            ? null
            : Yup.string()
                  .trim()
                  .min(2, FIELD_TOO_SHORT(2))
                  .max(32, FIELD_TOO_LONG(32))
                  .required(FIELD_REQUIRED)

        return {
            Component: InputField,
            config: {
                label: 'Job Title',
                disabled: isDisabled,
                validator,
            },
        }
    }
}

// eslint-disable-next-line max-len
const createEmployerNameFieldInstruction: EmploymentStatusLinkedInstructionCreator<
    typeof InputField
> = (fieldNamesMap, fieldNameToDisabledStatusesMap) => {
    return (formValues) => {
        const isDisabled = isFieldDisabledForCurrentEmploymentStatus(
            fieldNamesMap.employmentStatus,
            fieldNamesMap.employerName,
            formValues,
            fieldNameToDisabledStatusesMap,
        )

        const validator = isDisabled
            ? null
            : Yup.string()
                  .min(2, FIELD_TOO_SHORT(2))
                  .max(32, FIELD_TOO_LONG(32))
                  .required(FIELD_REQUIRED)

        return {
            Component: InputField,
            config: {
                label: 'Employer Name',
                disabled: isDisabled,
                validator,
            },
        }
    }
}

/**
 * Employment years/months fields are a masked field and needs
 * it's underlying HTML input value set manually when cleared.
 * This is definitely a bit of a hack to get around a
 * limitation of the masked input.
 */
function clearEmployerYearsDateField(fieldNames: EmploymentFieldNames) {
    const employerYearsInput = window.document.getElementsByName(
        fieldNames.employerYears,
    )[0] as HTMLInputElement

    if (employerYearsInput) {
        employerYearsInput.value = ''
    }
}

function clearEmployerMonthsDateField(fieldNames: EmploymentFieldNames) {
    const employerYearsInput = window.document.getElementsByName(
        fieldNames.employerMonths,
    )[0] as HTMLInputElement

    if (employerYearsInput) {
        employerYearsInput.value = ''
    }
}

function createEmploymentStatusChangeHandler(
    fieldNames: EmploymentFieldNames,
    fieldNameToDisabledStatusesMap: FieldNameToDisabledEmploymentStatusesMap,
): SelectFieldOnChange {
    return (value, fieldName, _formValues, fieldKit) => {
        const fieldsToClear = Object.keys(
            fieldNameToDisabledStatusesMap,
        ).filter((keyName) => {
            return fieldNameToDisabledStatusesMap[
                keyName as keyof EmploymentFieldNames
            ].includes(value)
        })

        fieldsToClear.forEach((fieldToClear) => {
            fieldKit.setFieldValue(fieldToClear, '')

            if (fieldToClear === fieldNames.employerYears) {
                clearEmployerYearsDateField(fieldNames)
            }

            if (fieldToClear === fieldNames.employerMonths) {
                clearEmployerMonthsDateField(fieldNames)
            }
        })

        fieldKit.setFieldValue(fieldName, value)
    }
}

function createEmploymentStatusFieldInstruction(
    fieldNames: EmploymentFieldNames,
    fieldNameToDisabledStatusesMap: FieldNameToDisabledEmploymentStatusesMap,
): FieldInstruction<typeof SelectField> {
    return {
        Component: SelectField,
        config: {
            label: 'Employment Status',
            onChange: createEmploymentStatusChangeHandler(
                fieldNames,
                fieldNameToDisabledStatusesMap,
            ),
            options: Object.values(EMPLOYMENT_STATUSES).map((statusOption) => ({
                name: statusOption.name,
                value: statusOption.value,
            })),
            validator: Yup.string().required(FIELD_REQUIRED),
        },
    }
}

export function createEmploymentFieldInstructionBundle(
    fieldNames: EmploymentFieldNames,
): FieldInstructionBundle {
    const fieldNameToDisabledEmploymentStatusesMap =
        createFieldNameToDisabledEmploymentStatusesMap(fieldNames)

    return {
        [fieldNames.employmentStatus]: gridColumnWrapper(
            createEmploymentStatusFieldInstruction(
                fieldNames,
                fieldNameToDisabledEmploymentStatusesMap,
            ),
            'span 8',
        ),
        [fieldNames.employerGrossAmount]: gridColumnWrapper(
            employerGrossAmount,
            'span 8',
        ),
        [fieldNames.employerGrossInterval]: gridColumnWrapper(
            employerGrossInterval,
            'span 8',
        ),
        [fieldNames.employerName]: gridColumnWrapper(
            createEmployerNameFieldInstruction(
                fieldNames,
                fieldNameToDisabledEmploymentStatusesMap,
            ),
            'span 8',
        ),
        [fieldNames.employerYears]: gridColumnWrapper(
            createEmployerYearsFieldInstruction(
                fieldNames,
                fieldNameToDisabledEmploymentStatusesMap,
            ),
            'span 4',
        ),
        [fieldNames.employerMonths]: gridColumnWrapper(
            createEmployerMonthsFieldInstruction(
                fieldNames,
                fieldNameToDisabledEmploymentStatusesMap,
            ),
            'span 4',
        ),
        [fieldNames.jobTitle]: gridColumnWrapper(
            createJobTitleFieldInstruction(
                fieldNames,
                fieldNameToDisabledEmploymentStatusesMap,
            ),
            'span 8',
        ),
        [fieldNames.workPhone]: gridColumnWrapper(
            createEmployerWorkPhone(fieldNames),
            'span 8',
        ),
        [fieldNames.hasOtherIncome]: gridColumnWrapper(
            createAddOtherIncome(fieldNames),
            '1 / span 24',
        ),
        [fieldNames.otherIncomeSource]: gridColumnWrapper(
            createOtherIncomeSource(fieldNames),
            '1 / span 8',
        ),
        [fieldNames.otherIncomeGrossAmount]: gridColumnWrapper(
            createOtherIncomeGrossAmount(fieldNames),
            'span 8',
        ),
        [fieldNames.otherIncomeGrossInterval]: gridColumnWrapper(
            createOtherIncomeGrossInterval(fieldNames),
            'span 7',
        ),
        [fieldNames.removeOtherIncome]: createRemoveOtherIncome(fieldNames),
    }
}

export const testExports = {
    createEmployerWorkPhone,
}
