import React from 'react'

import { CepBrokenRobotIcon } from '@octane/spark'

import { DetailsStyle, ErrorPanel, SummaryStyle } from './ErrorCard.styled'

function ErrorCard() {
    return (
        <>
            <ErrorPanel>
                <CepBrokenRobotIcon width={100} height={100} />
            </ErrorPanel>
            <SummaryStyle>We encountered an error.</SummaryStyle>
            <DetailsStyle>
                Sometimes we lose connectivity or have a small technical glitch.
                Please try again later.
            </DetailsStyle>
        </>
    )
}

export default ErrorCard
