import { TransitionStatus } from 'react-transition-group'

import styled from 'styled-components'

import { typography } from '../../assets/styles'

export const CarouselContainer = styled.div`
    min-width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;
`
export const Title = styled.h1`
    ${typography.Heading24Bold};
    font-size: 30px;
`

export const Copy = styled.span`
    ${typography.BodyCopy16};
`

const cardTranstionStateStyles = {
    entered: `
        opacity: 1;
        margin-right: 0;
        margin-left: 0;  
    `,
    entering: `
        opacity: 1;
        margin-right: 0;
        margin-left: 0;
    `,
    exiting: `
        opacity: 0;
        margin-right: 0;
        margin-left: -200%;
    `,
    exited: `
        opacity: 0;
        margin-right: -200%;
        margin-left: 0;
    `,
    unmounted: `
        opacity: 0;
        margin-left: 0;
        margin-right: -200%;
    `,
}

export const AnimationWrapper = styled.div<{
    transtionStatus: TransitionStatus
}>`
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    margin-left: 0;
    margin-right: -200%;
    transition: opacity 1s ease-in-out, margin-right 1s ease-in-out,
        margin-left 1s ease-in-out;
    ${({ transtionStatus }) => {
        return cardTranstionStateStyles[transtionStatus]
    }}
`

export const Card = styled.article`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
    min-width: 100%;
    grid-row-start: 1;
    grid-column-start: 1;
`

export const SpinnerWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 2px;
`

export const IconWrapper = styled.div<{
    paddingTop?: number
    paddingBotton?: number
}>`
    padding-top: ${({ paddingTop }) => `${paddingTop ?? 0}px`};
    padding-bottom: ${({ paddingBotton }) => `${paddingBotton ?? 0}px`};
`
