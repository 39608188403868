/* eslint-disable camelcase */
import {
    CollateralResponse,
    PowersportsAssetResponse,
} from 'api/application/getCollaterals'
import { constructVehicleDropdownValue } from 'containers/VehicleSelection/dropdowns/VehicleDropdown'

function transformResponseCollateral(
    collateral: CollateralResponse<PowersportsAssetResponse>,
) {
    const { asset } = collateral
    const year = asset.year ? asset.year.toString() : ''
    const mileage = asset.mileage ?? undefined
    const modelId = asset.model_id ? asset.model_id.toString() : ''
    const hasTitle = asset.title_information?.has_title === true
    const noTitleReason = asset.title_information?.no_title_reason ?? ''
    const isTrailer = asset.vehicle_type === 'trailer'

    const trailerValue =
        (isTrailer &&
            collateral.asset_value &&
            parseInt(collateral.asset_value, 10)) ??
        ''

    const trailerModel = isTrailer && asset.model

    const trailerWeight =
        (isTrailer && asset.weight && parseInt(asset.weight, 10)) ?? ''

    const noTitleOtherReason =
        asset.title_information?.no_title_other_reason ?? ''

    const selectedVehicleId = constructVehicleDropdownValue({
        id: asset.vehicle ?? 0,
        vehicleType: asset.vehicle_type,
        uuid: asset.vehicle_uuid,
    })

    return {
        year,
        mileage,
        modelId,
        selectedVehicleId,
        hasTitle,
        noTitleReason,
        noTitleOtherReason,
        trailerValue,
        trailerModel,
        trailerWeight,
    }
}

interface ConvertedCollateralValues {
    primary: ReturnType<typeof transformResponseCollateral>
    secondary?: ReturnType<typeof transformResponseCollateral>
    makeId: string
    condition: string
    vehicleType: string
    multiUnit?: boolean
    isTrailer?: boolean
}

export default function convertCollateralValues(
    collaterals: CollateralResponse<PowersportsAssetResponse>[],
): ConvertedCollateralValues | null {
    if (collaterals.length < 1) {
        return null
    }

    const rawPrimaryAsset = collaterals[0].asset
    const primary = transformResponseCollateral(collaterals[0])

    const primaryAssetCondition =
        rawPrimaryAsset.condition === 'N' ? 'new' : 'used'

    const primaryAssetVehicleType = rawPrimaryAsset.vehicle_type

    const primaryAssetMakeId = rawPrimaryAsset.make_id
        ? rawPrimaryAsset.make_id.toString()
        : ''

    const secondary =
        collaterals.length > 1
            ? transformResponseCollateral(collaterals[1])
            : null

    const primaryAssetIsTrailer = rawPrimaryAsset.vehicle_type === 'trailer'

    const data = {
        primary,
        makeId: primaryAssetMakeId,
        condition: primaryAssetCondition,
        vehicleType: primaryAssetVehicleType,
        isTrailer: primaryAssetIsTrailer,
    }

    if (secondary) {
        return {
            ...data,
            secondary,
            multiUnit: true,
        }
    }

    return data
}
