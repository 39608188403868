import React from 'react'

import { Avatar } from '@octane/spark'

import { Container, Email } from './UserName.styled'

interface Props {
    name: string
    email: string
}

export default function UserName({ name, email }: Props) {
    return (
        <Container>
            <Avatar name={name} size="small" />
            <div>
                {name}
                <Email>{email}</Email>
            </div>
        </Container>
    )
}
