import axios, { AxiosError } from 'axios'
import config from 'config'
import convertObjectToQueryString from 'utils/convertObjectToQueryString'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface GetYearsOptions {
    condition: 'new' | 'used'
    lenderId?: number
    dataSource?: string[]
    assetType?: string
    primaryVehicleId?: number
    vehicleTypeList?: string[]
}

export interface Year {
    id: number
    count: number
}

interface ResponseYear {
    id: number
    count: number
}

export default async function getYears(
    options: GetYearsOptions,
    abortController: AbortController,
) {
    let url = `${config.API_BASE_URL}/api/v3/vehicles/years/`
    let dataSourceParam = ''

    if (options.dataSource) {
        dataSourceParam = options.dataSource.join(',')
    }

    const queryString = convertObjectToQueryString(
        {
            condition: options.condition,
            lender_id: options.lenderId,
            data_source: dataSourceParam,
            asset_type: options.assetType,
            primary_vehicle_id: options.primaryVehicleId,
            vehicle_type_list: options.vehicleTypeList,
        },
        true,
    )

    url += `?${queryString}`

    try {
        const response = await axios.get(url, {
            signal: abortController.signal,
            withCredentials: true,
        })

        const years = response.data.results
            .map((result: ResponseYear) => ({
                id: result.id,
                count: result.count,
            }))
            .filter((year: Year) => {
                return (
                    year.id.toString().length === 4 &&
                    year.id > 1700 &&
                    year.id < 2100
                )
            })

        return years as Year[]
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get vehicle years: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
