import React, { ReactNode, lazy, Suspense, useState } from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'

import LoadingSpinner from 'components/LoadingSpinner'
import UpdateAvailableNotice from 'components/notices/UpdateAvailableNotice'
import {
    useFeatureFlagContext,
    FeatureFlagContextInterface,
} from 'contexts/FeatureFlagContextProvider'
import useKeepAppUpToDate from 'hooks/useKeepAppUpToDate'
import { LEADS_PAGE_ROUTE_PATH } from 'pages/LeadsPage'
import { PowersportsReadOnly } from 'pages/ReadOnly/PowersportsReadOnly'
import AssetType from 'state/enums/AssetType'

import useUser from './hooks/useUser'
import { NestedDealershipRoutes } from './pages/Dealership'
import DecisionResult from './pages/DecisionResult'
import Home from './pages/Home'
import { NestedInvitationRoutes } from './pages/Invitation'
import { NestedMyAccountRoutes } from './pages/MyAccount'
import { NestedResetPasswordRoutes } from './pages/MyAccount/Security/ResetPassword'
import hasPermission from './state/user/userPermissions'
import { User, UserPermissions } from './state/user/UserState'

const ProtectedRoutes = lazy(() => import('components/ProtectedRoutes'))

const CopyIntoNewPage = lazy(() =>
    import('pages/CopyIntoNew').then(({ CopyIntoNew }) => ({
        default: CopyIntoNew,
    })),
)

const DashboardPage = lazy(() => import('pages/Dashboard'))
const DefaultErrorPage = lazy(() => import('pages/DefaultErrorPage'))
const LeadPage = lazy(() => import('pages/LeadsPage'))

const NewApplicationPreQualified = lazy(
    () => import('pages/NewApplicationPreQualified'),
)

const NewUserTourPage = lazy(() =>
    import('pages/NewUserTour').then(({ NewUserTour }) => ({
        default: NewUserTour,
    })),
)

const SafeCheckReportPage = lazy(() => import('pages/SafeCheckReportPage'))
const ApplicationStatus = lazy(() => import('./pages/ApplicationStatus'))
const CopyVehicle = lazy(() => import('./pages/CopyVehicle'))
const Dealership = lazy(() => import('./pages/Dealership'))
const Example = lazy(() => import('./pages/Example'))
const FlatCancel = lazy(() => import('./pages/FlatCancel'))
const Invitation = lazy(() => import('./pages/Invitation'))
const MfaEnrollmentPage = lazy(() => import('./pages/MfaEnrollmentPage'))
const MfaVerificationPage = lazy(() => import('./pages/MfaVerificationPage'))
const MyAccount = lazy(() => import('./pages/MyAccount'))

const SalesPersonFlexLink = lazy(
    () => import('./pages/Dealership/AssignFlexLinks'),
)

const ResetPasswordPage = lazy(
    () => import('./pages/MyAccount/Security/ResetPassword'),
)

const NewApplication = lazy(() => import('./pages/NewApplication'))
const NotFound = lazy(() => import('./pages/NotFound'))
const ReturnCustomerPage = lazy(() => import('./pages/ReturnCustomerPage'))
const TenDayPayOff = lazy(() => import('./pages/TenDayPayOff'))

/**
 * Routes for the new dealer frontend should be added here.
 * They are then reachable at #/<new-route>. Routes for the
 * old dealer frontend live in the octane-dealer-frontend
 * project and are reachable at #!/<old-route> (note the !).
 */

type NestedRoutesType = {
    index: boolean
    element: ReactNode
    path: string
    label?: string
}

type RouteType = {
    path: string
    element: ReactNode
    nestedRoutes?: NestedRoutesType[]
    permissions?: string[]
}

export const PUBLIC_ROUTES = [
    {
        path: 'password-reset',
        element: <ResetPasswordPage />,
        nestedRoutes: NestedResetPasswordRoutes,
    },
    {
        path: 'invitation/:token/',
        element: <Invitation />,
        nestedRoutes: NestedInvitationRoutes,
    },
    {
        path: 'auth/mfa/verify',
        element: <MfaVerificationPage />,
    },
    {
        path: 'auth/mfa/enroll',
        element: <MfaEnrollmentPage />,
    },
]

const buildRoutes = (routes: RouteType[], user: User | undefined) => {
    return routes
        .filter((route) => {
            if (!route?.permissions) {
                return true
            }

            return route.permissions.every((permission) =>
                hasPermission(user, permission),
            )
        })
        .map((route: RouteType) => (
            <Route path={route.path} element={route.element} key={route.path}>
                {route.nestedRoutes?.map((nestedRoute: NestedRoutesType) => (
                    <Route
                        index={nestedRoute.index}
                        key={nestedRoute.path}
                        element={nestedRoute.element}
                        path={nestedRoute.path}
                    />
                ))}
            </Route>
        ))
}

const getPrivateRoutes = (
    featureFlags: FeatureFlagContextInterface | null,
    user?: User,
): RouteType[] => {
    const primaryAssetType = user?.dealership?.primaryAssetType

    const containsMarineAsSecondaryAssetType =
        user?.dealership?.assetTypes?.includes(AssetType.MARINE)

    const enableMarineAppFormEasyRoute =
        primaryAssetType === AssetType.RECREATIONAL_VEHICLE &&
        containsMarineAsSecondaryAssetType

    return [
        {
            path: 'example',
            element: <Example />,
        },
        {
            path: 'account-settings',
            element: featureFlags?.selfServiceManagement ? (
                <MyAccount />
            ) : (
                <NotFound />
            ),
            nestedRoutes: NestedMyAccountRoutes,
        },
        {
            path: 'dealership-settings',
            element: featureFlags?.selfServiceManagement ? (
                <Dealership />
            ) : (
                <NotFound />
            ),
            nestedRoutes: NestedDealershipRoutes,
            permissions: [UserPermissions.CAN_VIEW_DEALERSHIP_SETTINGS],
        },
        {
            path: 'new-application',
            element: <NewApplication />,
        },
        // Temporary route for the marine application form for RV dealerships
        // This route will be removed once the new RV application form is implemented
        {
            path: 'new-boat-app',
            element: enableMarineAppFormEasyRoute ? (
                <NewApplication />
            ) : (
                <NotFound />
            ),
        },
        {
            path: 'new-application-prequalified/:id/',
            element: <NewApplicationPreQualified />,
        },
        {
            path: 'copy-vehicle/:id/',
            element: <CopyVehicle />,
        },
        {
            path: 'copy-into-new/:id',
            element: <CopyIntoNewPage user={user} />,
        },
        {
            path: 'application/:id',
            element: <PowersportsReadOnly />,
        },
        {
            path: 'application/status/:id/',
            element: <ApplicationStatus />,
        },
        {
            path: 'application/decision/:applicationId/',
            element: <DecisionResult />,
        },
        {
            path: 'routeone/application/status/:id/',
            element: <ApplicationStatus />,
        },
        {
            path: LEADS_PAGE_ROUTE_PATH,
            element: featureFlags?.leadsPage ? <LeadPage /> : <NotFound />,
        },
        {
            path: 'new-user-tour',
            element: <NewUserTourPage />,
        },
        {
            path: 'dashboard',
            element: <DashboardPage />,
        },
        {
            path: 'ten-day-payoff',
            element: <TenDayPayOff />,
        },
        {
            path: 'flat-cancel/:applicationId/',
            element: featureFlags?.flatCancel ? <FlatCancel /> : <NotFound />,
        },
        {
            path: '/safecheck/report/:uuid',
            element: <SafeCheckReportPage />,
        },
        {
            path: '/safecheck/report/:uuid/:section',
            element: <SafeCheckReportPage />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
        {
            path: 'error',
            element: <DefaultErrorPage navigateHistorySteps={-1} />,
        },
        {
            path: 'return-customer',
            element: (
                <ReturnCustomerPage
                    applicationData={
                        window.windowNavigatorIFrameServer?.applicationData
                    }
                    activeLoans={
                        window.windowNavigatorIFrameServer?.returnCustomerLoans
                    }
                />
            ),
        },
        {
            path: 'dealership-settings/assign-flex-links',
            element: featureFlags?.salesPersonFlexLink ? (
                <SalesPersonFlexLink />
            ) : (
                <NotFound />
            ),
        },
    ]
}

function AppRoutes(): JSX.Element {
    const [shouldRenderNotifications, setShouldRenderNotifications] =
        useState(false)

    const { user, isLoggedIn } = useUser()
    const featureFlags = useFeatureFlagContext()
    const privateRoutes = getPrivateRoutes(featureFlags, user)

    const handleShouldRenderNotifications = (value: boolean) => {
        setShouldRenderNotifications(value)
    }

    useKeepAppUpToDate(
        handleShouldRenderNotifications,
        featureFlags?.handleShouldUpdateFlags,
    )

    return (
        <Suspense fallback={<LoadingSpinner />}>
            {shouldRenderNotifications && (
                <UpdateAvailableNotice
                    renderNotification={handleShouldRenderNotifications}
                />
            )}
            <Routes>
                <Route path="/" element={<Outlet />}>
                    {isLoggedIn && <Route index element={<Home />} />}
                    {buildRoutes(PUBLIC_ROUTES, user)}
                    <Route element={<ProtectedRoutes />}>
                        {buildRoutes(privateRoutes, user)}
                    </Route>
                </Route>
            </Routes>
        </Suspense>
    )
}

export default AppRoutes
