import React from 'react'

import VehicleSelectionBase from 'containers/VehicleSelection/VehicleSelectionBase'

import { FieldConfigBase, FieldProps } from '../../containers/FormBuilder'

export interface VehicleSelectionBaseFieldConfig extends FieldConfigBase {
    assetType: 'powersports_vehicle' | 'automobile'
    prefilled?: boolean
    vehicleTypeList?: []
}

export default function VehicleSelectionBaseField(
    props: FieldProps<VehicleSelectionBaseFieldConfig>,
) {
    const { fieldConfig, fieldKit } = props

    const formik = {
        ...fieldKit,
        initialValues: {
            vehicle: { ...fieldConfig.initialValue },
        },
    }

    return (
        <VehicleSelectionBase
            assetType={fieldConfig.assetType}
            formik={formik}
            prefilled={fieldConfig.prefilled}
            vehicleTypeList={fieldConfig.vehicleTypeList}
        />
    )
}
