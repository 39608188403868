import React, { useState } from 'react'

import { DisclosureModal } from 'components/DisclosureModal'
import { ConsentLabel } from 'components/FormikFields/FormikCheckbox/FormikCheckbox.styled'
import useRoadrunnerDisclosures from 'hooks/useRoadrunnerDisclosures'
import DisclosureType from 'state/enums/DisclosureType'

interface ConsentCheckboxLabelProps {
    disclosureType: DisclosureType
    withDisclosureModal?: boolean
}

export default function ConsentCheckboxLabel({
    disclosureType,
    withDisclosureModal,
}: Readonly<ConsentCheckboxLabelProps>): JSX.Element {
    const { disclosures } = useRoadrunnerDisclosures()
    const [showDisclosureModal, setShowDisclosureModal] = useState(false)
    const disclosure = disclosures?.find((item) => item.name === disclosureType)

    const disclosureText = disclosures?.find(
        (item) => item.name === disclosureType,
    )?.text

    const textPrefix = `By checking this box you have read and agreed to the following: ${' '}`

    return (
        <ConsentLabel>
            {withDisclosureModal === true ? (
                <>
                    <DisclosureModal
                        showModal={showDisclosureModal}
                        toggleModal={setShowDisclosureModal}
                        modalTitle="General Application Disclosures"
                        disclosureName={disclosureType}
                        lenderId={disclosure ? disclosure.lender : 0}
                    />
                    <div
                        className="no-print"
                        style={{
                            display: 'block',
                            paddingLeft: '1vw',
                        }}
                    >
                        {textPrefix}
                        <button
                            type="button"
                            onClick={() => setShowDisclosureModal(true)}
                        >
                            General Application Disclosures
                        </button>
                        .
                    </div>
                    <div
                        style={{
                            paddingLeft: '1vw',
                        }}
                        className="print-only"
                    >
                        {textPrefix}
                        <div
                            style={{
                                paddingTop: '20px',
                            }}
                        >
                            {disclosureText}
                        </div>
                    </div>
                </>
            ) : (
                <div
                    style={{
                        display: 'block',
                        paddingLeft: '1vw',
                        whiteSpace: 'pre-line',
                    }}
                >
                    {`Customer ID Verification Acknowledgment:\n\n${
                        disclosure?.text || ''
                    }`}
                </div>
            )}
        </ConsentLabel>
    )
}

ConsentCheckboxLabel.defaultProps = {
    withDisclosureModal: true,
}
