import React from 'react'

import Card from 'components/Card'
import Info from 'components/Info'
import { DealershipInfo } from 'state/user/UserState'
import { SUPPORT_PHONE } from 'utils/consts/contactInfo'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import joinTextValues from 'utils/joinTextValues'

type DealershipInformationProps = {
    dealership?: DealershipInfo
} & typeof defaultProps

const defaultProps = {
    dealership: {},
}

function DealershipInformation({
    dealership,
}: DealershipInformationProps): JSX.Element {
    return (
        <Card
            title="Dealership Information"
            rightComponent={
                <small>
                    If you need to request any change on this page please call
                    us at
                    {` ${SUPPORT_PHONE}`}
                </small>
            }
        >
            <Info>
                <div>
                    <Info.Field>
                        <Info.Label>Dealership Name</Info.Label>
                        <Info.Data>{dealership?.name}</Info.Data>
                    </Info.Field>
                    <Info.Field>
                        <Info.Label>Dealership Phone Number</Info.Label>
                        <Info.Data>
                            {formatPhoneNumber(dealership?.phoneNumber)}
                        </Info.Data>
                    </Info.Field>
                    <Info.Field>
                        <Info.Label>Dealership Address</Info.Label>
                        <Info.Data>{dealership?.address}</Info.Data>
                    </Info.Field>
                    <Info.Field>
                        <Info.Label>Chain Name</Info.Label>
                        <Info.Data>{dealership?.chain?.name}</Info.Data>
                    </Info.Field>
                </div>
                <div>
                    <Info.Field>
                        <Info.Label>Principal Name</Info.Label>
                        <Info.Data>
                            {joinTextValues([
                                dealership?.principal?.firstName,
                                dealership?.principal?.lastName,
                            ])}
                        </Info.Data>
                    </Info.Field>
                    <Info.Field>
                        <Info.Label>Principal Phone Number</Info.Label>
                        <Info.Data>
                            {formatPhoneNumber(
                                dealership?.principal?.phoneNumber,
                            )}
                        </Info.Data>
                    </Info.Field>
                    <Info.Field>
                        <Info.Label>
                            Principal Phone Number Extension
                        </Info.Label>
                        <Info.Data>
                            {dealership?.principal?.phoneNumberExtension}
                        </Info.Data>
                    </Info.Field>
                    <Info.Field>
                        <Info.Label>Principal Email</Info.Label>
                        <Info.Data>
                            {dealership?.principal?.emailAddress}
                        </Info.Data>
                    </Info.Field>
                </div>
            </Info>
        </Card>
    )
}

DealershipInformation.defaultProps = defaultProps

export default DealershipInformation
