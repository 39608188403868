import styled from 'styled-components'

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    #safeCheckInformation {
        padding-left: 5px;
    }
`

export const PopOverText = styled.div`
    font-family: 'Karla';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
`
