import React from 'react'

import {
    FormikAmountField,
    FormikTextInput,
} from '../../../components/FormikFields'
import { Row } from '../MarineWriteIn.styled'
import PrimaryResidenceCheckbox from '../ResidenceCheckbox'
import { ConditionTab, MotorDropdown, TrailerTab, YearDropdown } from './index'
import MakeDropdown from './MakeDropdown'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
}

export default function HullWriteIn({ formik }: Readonly<QueryProps>) {
    const { condition } = formik.values.marine

    return (
        <>
            <Row gridTemplateColumns="1fr 1fr">
                <ConditionTab />
            </Row>
            {condition === 'N' && (
                <Row gridTemplateColumns=".5fr .75fr 1.25fr">
                    <YearDropdown formik={formik} />
                    <MakeDropdown formik={formik} />
                    <FormikTextInput name="marine.model" label="Hull Model" />
                </Row>
            )}
            {condition === 'U' && (
                <Row gridTemplateColumns=".5fr .75fr 1fr .35fr">
                    <YearDropdown formik={formik} />
                    <MakeDropdown formik={formik} />
                    <FormikTextInput name="marine.model" label="Hull Model" />
                    <FormikAmountField
                        name="marine.hours"
                        label="Hours"
                        placeholder=""
                        currencySymbol=""
                        omitDecimals
                    />
                </Row>
            )}
            <Row gridTemplateColumns="1fr 1fr 1fr">
                <MotorDropdown formik={formik} />
                <TrailerTab />
            </Row>
            <Row gridTemplateColumns="1fr 2fr">
                <FormikAmountField
                    name="marine.invoice"
                    label={
                        condition === 'U'
                            ? 'Total Unit Value'
                            : 'Total Invoice Amount'
                    }
                    helpText="Sum of all items"
                />
            </Row>
            <PrimaryResidenceCheckbox formik={formik} />
        </>
    )
}
