import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    margin-left: auto;
`
export const InputDiv = styled.div`
    padding: 10px;
    & input {
        padding-right: 30px;
        padding-left: 10px;
        font-size: 13px;
        font-style: italic;
        height: 40px;
        width: 220px;
    }
`
export const IconDiv = styled.div`
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 20px;
`
