import React from 'react'

import { Logo } from '@octane/spark'
import SummerSweepstakesLogo from 'assets/img/summer-sweepstakes.svg'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useUser from 'hooks/useUser'

import { NavbarLink } from './Navbar.styled'

export default function OctaneLogo(): JSX.Element {
    const { isAutoDealership, isPowersportsDealership } = useUser()
    const featureFlags = useFeatureFlagContext()

    const showSweepstakesTheme =
        isPowersportsDealership === true &&
        featureFlags?.sweepstakesTheme === true

    if (showSweepstakesTheme) {
        return (
            <a
                href="https://octane.co/o/fuel-your-summer-sweepstakes/"
                target="_blank"
                rel="noreferrer"
            >
                <img
                    src={SummerSweepstakesLogo}
                    alt="Summer Sweepstakes"
                    width={200}
                    height={50}
                />
            </a>
        )
    }

    return (
        <NavbarLink href={isAutoDealership ? '#/dashboard' : '#'}>
            <Logo
                logo="lgOctaneDPPrimary"
                color="#2366b1"
                width={200}
                height={50}
            />
        </NavbarLink>
    )
}
