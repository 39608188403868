import React from 'react'

import { HullWriteIn, RepowerWriteIn } from './writeInFields'

interface MarineSelectionProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: any
}

const defaultProps = {
    formik: null,
}

export default function MarineWriteIn({ formik }: MarineSelectionProps) {
    const { repower } = formik.values.marine

    if (repower) {
        return <RepowerWriteIn formik={formik} />
    }

    return <HullWriteIn formik={formik} />
}

MarineWriteIn.defaultProps = defaultProps
