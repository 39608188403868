import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../../integrations/datadog/OctaneLogger'

export { MfaWaitError } from './errors'
export { FactorType } from './enums'
export { default as requestChallenge } from './requestChallenge'
export { default as verifyFactor } from './verifyFactor'
export { default as enrollFactor } from './enrollFactor'
export { default as getFactor } from './getFactor'
export { default as getFactors } from './getFactors'

export interface MfaErrorData {
    type: string
    code: string
}

let navigateTimer: ReturnType<typeof setTimeout> | null = null

function navigateOnce(hashPath: string) {
    // To prevent multiple requests entering and wanting
    // to redirect at the same time, set a timer that is
    // cleared if entered within 250ms of timer being set.
    if (navigateTimer !== null) {
        clearTimeout(navigateTimer)
    }

    navigateTimer = setTimeout(() => {
        navigateTimer = null
        const redirectToHash = `#${hashPath || '/'}`

        if (redirectToHash && redirectToHash !== window.location.hash) {
            OctaneLogger.info(`MFA is redirecting to: ${hashPath}`)
            window.location.hash = redirectToHash
        }
    }, 250)
}

function isMfaResponse(
    error: AxiosError<unknown>,
    // data is not really a required arg but supplying it allows us to
    // narrow its type to MfaErrorData via the return type definition
    data: unknown,
): data is MfaErrorData {
    if (error?.response?.status !== 403 || !data) return false

    if (!error?.response?.config?.url?.startsWith(config.API_BASE_URL)) {
        return false
    }

    const mfaData = data as MfaErrorData

    try {
        return 'type' in mfaData && mfaData.type === 'mfa'
    } catch (e) {
        return false
    }
}

export function handleMfaResponse(
    error: AxiosError<unknown, unknown>,
): boolean {
    const data = error?.response?.data

    if (!isMfaResponse(error, data)) {
        // nothing to do here
        return false
    }

    if (data.code === 'verification_required') {
        navigateOnce('/auth/mfa/verify')

        return true
    }

    if (data.code === 'enrollment_required') {
        navigateOnce('/auth/mfa/enroll')

        return true
    }

    const mfaError = new Error(`Unhandled MFA code: ${data.code}`)

    OctaneLogger.error(mfaError.message, undefined, mfaError)
    throw mfaError
}

export function mfaInterceptorOnRejected(error: unknown) {
    let handled = false

    if (axios.isAxiosError(error)) {
        handled = handleMfaResponse(error)
    }

    // if not an MFA response, let it bubble up to next level
    if (!handled) {
        throw error
    }
}

export function registerMfaErrorResponseHandler() {
    axios.interceptors.response.use(undefined, mfaInterceptorOnRejected)
}
