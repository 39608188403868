import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0 0 0;
`

export const ActionText = styled.div`
    margin-left: 5px;
    cursor: pointer;
    ${typography.BodyCopy16}
    color: ${(props) => props.theme.colors.brand.rideOctaneBlue};
`

export const FieldWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
`

export const Field = styled.div`
    display: flex;
    align-items: center;
`

export const AddEmailWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
    margin-left: 10px;
`
