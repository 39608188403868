import React from 'react'

import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
}

export default function MotorDropdown({ formik }: QueryProps) {
    const { marine } = formik.values
    const { motors } = marine

    const options = [
        { name: '0', value: 0 },
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
    ]

    return (
        <FormikDropdown
            name="marine.motors"
            label="How many motors do you need?"
            placeholder="Select One"
            error={motors === null}
        >
            {options.map((child) => {
                const newBorn: FormikDropdownChild = {
                    name: child.name.toString(),
                    value: child.value.toString(),
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}
