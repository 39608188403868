import React, { useEffect, useState } from 'react'

import getYears, { GetYearsOptions, Year } from 'api/vehicles/getYears'
import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    dataSource?: string[]
    primaryVehicleId?: number
    assetType?: unknown
}

const defaultProps = {
    dataSource: null,
    primaryVehicleId: null,
    assetType: null,
}

export default function YearDropdown({
    formik,
    dataSource,
    primaryVehicleId,
    assetType,
}: QueryProps) {
    const [response, updateResponse] = useState([] as Year[])
    const { vehicle } = formik.values
    const { condition } = vehicle
    const { year } = vehicle

    function getYearsForVehicle(abortController: AbortController) {
        const getYearsOptions = {
            condition,
            dataSource,
            assetType,
            primaryVehicleId,
        } as GetYearsOptions

        getYears(getYearsOptions, abortController)
            .then((years: Year[]) => {
                updateResponse(years)

                if (years.filter((e) => `${e.id}` === year).length === 0) {
                    formik.setFieldValue('vehicle.year', '0')
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {})
    }

    useEffect(() => {
        const abortController = new AbortController()

        if (condition) {
            getYearsForVehicle(abortController)
        }

        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition])

    if (response.length < 1) {
        return (
            <FormikDropdown
                name="vehicle.year"
                label="Year"
                placeholder="Select One"
                disabled
            >
                {{ name: '', value: '' }}
                {{ name: '', value: '' }}
            </FormikDropdown>
        )
    }

    return (
        <FormikDropdown
            name="vehicle.year"
            label="Year"
            placeholder="Select One"
            disabled={response.length === 0}
            error={year === null || year === 0}
        >
            {response.map((child: Year) => {
                const newBorn: FormikDropdownChild = {
                    name: child.id.toString(),
                    value: child.id.toString(),
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}

YearDropdown.defaultProps = defaultProps
