import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Description = styled.div`
    ${typography.BodyCopy16}
`

export const SecuritySettingsWrapper = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;

    @media (max-width: 928px) {
        & > :nth-child(2) {
            margin-top: 16px;
        }
    }

    @media (max-width: 928px) {
        grid-template-columns: 1fr;

        & > :nth-child(2) {
            margin-top: 0;
        }
    }
`
