import { AmountAlignText } from '@octane/spark'
import { FieldInstruction } from 'containers/FormBuilder'
import CurrencyAmountField from 'form/fieldComponents/CurrencyAmountField'
import InputFieldMasked from 'form/fieldComponents/InputFieldMasked'
import TabSelectField from 'form/fieldComponents/TabSelectField'
import {
    FIELD_NEGATIVE_NUMBER,
    FIELD_REQUIRED,
    FIELD_VALUE_MONTHS,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

export const residenceStatus: FieldInstruction<typeof TabSelectField> = {
    Component: TabSelectField,
    config: {
        label: 'Residence Status',
        items: [
            { value: 'O', label: 'Own' },
            { value: 'R', label: 'Rent' },
            { value: 'L', label: 'Rent-free' },
        ],
        initialValue: 'O',
        validator: Yup.string().required(FIELD_REQUIRED),
    },
}

export const residentialYears: FieldInstruction<typeof InputFieldMasked> = {
    Component: InputFieldMasked,
    config: {
        label: 'Years at Res.',
        mask: '00',
        validator: Yup.number()
            .min(0, FIELD_NEGATIVE_NUMBER)
            .required(FIELD_REQUIRED),
    },
}

export const residentialMonths: FieldInstruction<typeof InputFieldMasked> = {
    Component: InputFieldMasked,
    config: {
        label: 'Months at Res.',
        mask: '00',
        validator: Yup.number()
            .min(0, FIELD_VALUE_MONTHS)
            .max(11, FIELD_VALUE_MONTHS)
            .required(FIELD_REQUIRED),
    },
}

export const monthlyHousingPayment: FieldInstruction<
    typeof CurrencyAmountField
> = {
    Component: CurrencyAmountField,
    config: {
        label: 'Monthly Housing Payment',
        placeholder: 'Monthly housing payment',
        alignText: AmountAlignText.LEFT,
        validator: Yup.number()
            .required(FIELD_REQUIRED)
            .min(0, FIELD_NEGATIVE_NUMBER),
    },
}
