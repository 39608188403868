/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import LoadingStatus from 'state/enums/LoadingStatus'

import { logoutUser } from '../user/actions'
import { loadStats } from './actions'
import ParseDashboard from './parsers/ParseDashboard'
import { DashboardData } from './types'

export interface DashboardState {
    hasDashboard: boolean
    dashboardData: DashboardData
    status: LoadingStatus
}

const initialState = {
    hasDashboard: false,
    dashboardData: {},
    status: LoadingStatus.IDLE,
} as DashboardState

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        enableDashboard: (state: DashboardState) => {
            state.hasDashboard = true
        },
        disableDashboard: (state: DashboardState) => {
            state.hasDashboard = false
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadStats.pending, (state: DashboardState) => {
                state.status = LoadingStatus.LOADING
            })
            .addCase(loadStats.fulfilled, (state: DashboardState, action) => {
                const { payload } = action

                state.status = LoadingStatus.LOADED

                state.dashboardData = ParseDashboard(payload)

                state.hasDashboard = true
            })
            .addCase(loadStats.rejected, (state: DashboardState) => {
                state.status = LoadingStatus.FAILED
                state.hasDashboard = false
            })

            // Reset state when user logs out.
            .addCase(logoutUser.fulfilled, (state: DashboardState) => {
                state.status = LoadingStatus.IDLE
                state.dashboardData = {} as DashboardData
                state.hasDashboard = false
            })
    },
})

export default dashboardSlice
