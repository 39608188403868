import styled from 'styled-components'

export const Body = styled.div`
    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        button {
            width: 340px;
        }

        @media (max-width: 1120px) {
            flex-direction: column;

            button {
                width: 100%;
                margin-top: 16px;
            }
        }
    }
`

export const InfoMessage = styled.span`
    @media (max-width: 1512px) {
        max-width: 400px;
    }

    @media (max-width: 1279px) {
        max-width: 300px;
    }

    @media (max-width: 1120px) {
        max-width: 100%;
    }
`
