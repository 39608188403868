import React from 'react'

import { AmountAlignText, AmountField } from '@octane/spark'
import { Field, FieldProps, getIn } from 'formik'

type FormikAmountFieldProps = {
    name: string
    label: string
    placeholder?: string
    disabled?: boolean
    tooltip?: string
    currencySymbol?: string
    omitDecimals?: boolean
    alignText?: AmountAlignText
    prefilled?: boolean
    helpText?: string
    // eslint-disable-next-line @typescript-eslint/ban-types
} & typeof defaultProps

const defaultProps = {
    placeholder: '0.00',
    disabled: false,
    tooltip: '',
    currencySymbol: '$',
    omitDecimals: false,
    alignText: AmountAlignText.RIGHT,
    prefilled: false,
    helpText: '',
}

function FormikAmountField({
    name,
    label,
    placeholder,
    disabled,
    tooltip,
    currencySymbol,
    omitDecimals,
    alignText,
    prefilled,
    helpText,
}: FormikAmountFieldProps) {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                const touched = getIn(form.touched, field.name)
                const error = getIn(form.errors, field.name)
                const amountValue = getIn(form.values, field.name)
                const displayTooltip = tooltip ? <small>{tooltip}</small> : null

                return (
                    <AmountField
                        name={field.name}
                        id={field.name}
                        testID={field.name}
                        label={label}
                        disabled={disabled}
                        alignText={alignText}
                        onBlur={form.handleBlur}
                        placeholder={placeholder}
                        info={displayTooltip}
                        onChange={(value) => {
                            const newValue =
                                value.formattedValue !== ''
                                    ? value.floatValue
                                    : ''

                            const event = {
                                target: {
                                    name,
                                    value: newValue,
                                },
                            }

                            form.handleChange(event)
                        }}
                        error={!!(touched && error)}
                        errorText={error}
                        value={amountValue}
                        currencySymbol={currencySymbol}
                        omitDecimals={omitDecimals}
                        prefilled={prefilled}
                        helpText={helpText}
                    />
                )
            }}
        </Field>
    )
}

FormikAmountField.defaultProps = defaultProps

export default FormikAmountField
