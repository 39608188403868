/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react'

// eslint-disable-next-line import/no-unresolved
import { Avatar, UiArrowExpandIcon } from '@octane/spark'
import { DropdownContent, DropdownLink } from 'assets/styles/dropdown'
import brandConfig from 'branding'
// eslint-disable-next-line import/no-unresolved
import TrackedDropdownLink from 'components/TrackedDropdownLink/TrackedDropdownLink'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useAppDispatch from 'hooks/useAppDispatch'
import useGoogleTagManager from 'hooks/useGoogleTagManager'
import useUser from 'hooks/useUser'
import { logoutUser } from 'state/user/actions'

import hasPermission from '../../../state/user/userPermissions'
import { UserPermissions } from '../../../state/user/UserState'
import {
    Greeting,
    DropdownWrapper,
    Wrapper,
    UserInfo,
} from './LoggedInUser.styled'

export default function LoggedInUserContainer(): JSX.Element {
    const featureFlags = useFeatureFlagContext()
    const googleTagManager = useGoogleTagManager()
    const dispatch = useAppDispatch()
    const { user } = useUser()
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const node = useRef<HTMLInputElement>(null)

    const clickOutside = (e: MouseEvent) => {
        if (node?.current?.contains(e.target as Node)) {
            return
        }

        setToggleDropdown(false)
    }

    useEffect(() => {
        document.addEventListener('click', clickOutside)

        return () => {
            document.removeEventListener('click', clickOutside)
        }
    }, [])

    if (!user) {
        return <Wrapper />
    }

    return (
        <Wrapper
            data-testid="wrapper"
            ref={node}
            onClick={() => setToggleDropdown(!toggleDropdown)}
        >
            {user.firstName && user.lastName && (
                <Avatar
                    name={`${user.firstName} ${user.lastName}`}
                    size="large"
                    color="auto"
                />
            )}
            <UserInfo>
                <Greeting>Hi {user?.firstName}</Greeting>
                <DropdownWrapper data-testid="dropdown-toggle-your-account">
                    <span>Your Account</span>
                    <UiArrowExpandIcon
                        width={16}
                        height={16}
                        color={brandConfig.theme.navbar.color}
                    />
                </DropdownWrapper>
                {toggleDropdown && (
                    <DropdownContent data-testid="dropdown-content">
                        {featureFlags?.selfServiceManagement && (
                            <TrackedDropdownLink
                                testId="myaccount-link"
                                text="Account Settings"
                                hashNavigate="account-settings"
                            />
                        )}
                        {!featureFlags?.selfServiceManagement && (
                            <TrackedDropdownLink
                                testId="myaccount-link-legacy"
                                text="Account Settings"
                                iFrameNavigate="/#!/my-account"
                            />
                        )}

                        {featureFlags?.selfServiceManagement &&
                            hasPermission(
                                user,
                                UserPermissions.CAN_VIEW_DEALERSHIP_SETTINGS,
                            ) && (
                                <TrackedDropdownLink
                                    testId="mydealership-link"
                                    text="Dealership Settings"
                                    hashNavigate="dealership-settings"
                                />
                            )}
                        <DropdownLink
                            data-testid="logout-link"
                            onClick={() => {
                                googleTagManager.trackNavbarEvent(
                                    'Log Out',
                                    '#/',
                                )

                                dispatch(logoutUser())
                            }}
                        >
                            Log Out
                        </DropdownLink>
                    </DropdownContent>
                )}
            </UserInfo>
        </Wrapper>
    )
}
