import React from 'react'

import {
    AmountAlignText,
    AmountField,
    AmountFieldChangedValue,
} from '@octane/spark'

import {
    FieldConfigBase,
    FieldKit,
    FieldProps,
    FormValues,
} from '../../containers/FormBuilder'

interface CurrencyAmountFieldConfig extends FieldConfigBase {
    label: string
    placeholder?: string
    disabled?: boolean
    alignText?: AmountAlignText
    prefilled?: boolean
    onChange?: (
        value: number | string,
        fieldName: string,
        formValues: FormValues,
        fieldKit: FieldKit,
    ) => void
}

export default function CurrencyAmountField(
    props: FieldProps<CurrencyAmountFieldConfig>,
) {
    const { fieldName, fieldKit, fieldConfig } = props
    const touched = fieldKit.touched[fieldName]
    const error = fieldKit.errors[fieldName]
    const value = fieldKit.values[fieldName]

    const handleChange = (val: AmountFieldChangedValue) => {
        const newValue = val.formattedValue !== '' ? val.floatValue : ''

        if (fieldConfig.onChange) {
            fieldConfig.onChange(newValue, fieldName, fieldKit.values, fieldKit)
        } else {
            const event = {
                target: {
                    name: fieldName,
                    value: newValue,
                },
            }

            fieldKit.handleChange(event)
        }
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const formattedValue = event.target.value
        const unformattedValue = formattedValue.replace(',', '')
        const floatValue = parseFloat(unformattedValue)

        if (floatValue !== value) {
            /**
             * Workaround in order to allow the masked input's value to be
             * changed externally by an extension (e.g. Revvable.com).
             */
            handleChange({
                formattedValue,
                floatValue,
                value: unformattedValue,
            } as AmountFieldChangedValue)

            setTimeout(() => fieldKit.handleBlur(event))
        } else {
            fieldKit.handleBlur(event)
        }
    }

    return (
        <AmountField
            name={fieldName}
            id={fieldName}
            testID={fieldName}
            label={fieldConfig.label}
            alignText={fieldConfig.alignText}
            disabled={fieldConfig.disabled}
            onBlur={handleBlur}
            placeholder={fieldConfig.placeholder ?? '0.00'}
            onChange={handleChange}
            error={!!(touched && error)}
            errorText={error}
            value={value}
            omitDecimals
            prefilled={fieldConfig.prefilled}
        />
    )
}
