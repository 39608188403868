export default function isJsonObject(object: unknown) {
    if (
        !object ||
        typeof object !== 'object' ||
        Array.isArray(object) ||
        Object.getOwnPropertySymbols(object).length > 0
    )
        return false

    return Object.getOwnPropertyNames(object).every(
        (prop) => typeof prop === 'string',
    )
}
