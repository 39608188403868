import { FieldInstruction } from 'containers/FormBuilder'
import InputField from 'form/fieldComponents/InputField'
import InputFieldMasked from 'form/fieldComponents/InputFieldMasked'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import * as Yup from 'yup'

import emailValidation from '../validation/emailValidation'
import { phoneNumberField } from './phoneNumberField'

export const email: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Customer Email Address',
        type: 'email' as const,
        subtitle:
            'Verify correct customer email. Billing statements and contracting documents are sent here.',
        validator: Yup.string()
            .required(FIELD_REQUIRED)
            .test(
                'email_validation',
                'Must be a valid email address',
                emailValidation,
            ),
    },
}

export const homePhone: FieldInstruction<typeof InputFieldMasked> = {
    ...phoneNumberField,
    config: {
        ...phoneNumberField.config,
        label: 'Home Phone Number',
    },
}

export const mobilePhone: FieldInstruction<typeof InputFieldMasked> = {
    ...phoneNumberField,
    config: {
        ...phoneNumberField.config,
        label: 'Cell Phone Number',
    },
}
