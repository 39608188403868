import React from 'react'

import styled from 'styled-components'

const MessageContainer = styled.div`
    text-align: center;
`

const ErrorMessage = styled.span`
    color: red;
    font-size: 20px;
`

export default function FormValidationMessage(props: {
    message: string
    formIsValid: boolean
    submitAttempted: boolean
}) {
    const { message, formIsValid, submitAttempted } = props
    const showMessage = !formIsValid && submitAttempted

    if (!showMessage) {
        return null
    }

    return (
        <MessageContainer>
            <ErrorMessage>{message}</ErrorMessage>
        </MessageContainer>
    )
}
