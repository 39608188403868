import { shadows, typography } from 'assets/styles'
import styled from 'styled-components'

export const Container = styled.div`
    ${shadows.Tile};
    background-color: ${(props) => props.theme.colors.monochrome.white};
    padding: 40px;
    margin-bottom: 20px;
    border-radius: 5px;
`

export const Header = styled.div`
    display: flex;
    border-bottom: 1px solid
        ${(props) => props.theme.colors.monochrome.concrete};
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 1140px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const Title = styled.h3`
    ${typography.Hero24};
    margin: 0 0 5px 0;
`
