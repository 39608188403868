import React, { useEffect, useState } from 'react'

import { Select } from '@octane/spark'
import getCustomersAllowedStates, {
    CustomerAllowedState,
} from 'api/application/getCustomersAllowedStates'
import { useVisibilityContext } from 'contexts/VisibilityContext'
import AssetType from 'state/enums/AssetType'
import VehicleType from 'state/enums/VehicleType'

import { Container } from './StateEligibilityDropdown.styled'
import StateNotListedToolTip from './StateNotListedToolTip'

export const OptionStateNotListed = {
    name: 'State not listed',
    value: 'State not listed',
}

interface StateEligibilityDropdownProps {
    stateNotListedToolTipText?: string
    name: string
    assetType: AssetType
    vehicleType?: VehicleType
    disabled?: boolean
    value?: string
    onChange: (value: string) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setAvailableParentStates: any
    prefilled?: boolean
}

const defaultProps = {
    disabled: false,
    stateNotListedToolTipText: undefined,
    vehicleType: undefined,
    value: undefined,
    prefilled: false,
}

function StateEligibilityDropdown(
    props: StateEligibilityDropdownProps,
): JSX.Element {
    const {
        stateNotListedToolTipText,
        name,
        onChange,
        disabled,
        assetType,
        vehicleType,
        value,
        setAvailableParentStates,
        prefilled,
    } = props

    const [availableStates, setAvailableStates] = useState(
        [] as CustomerAllowedState[],
    )

    const visibilityContext = useVisibilityContext()

    useEffect(() => {
        const abortController = new AbortController()

        getCustomersAllowedStates({ assetType, vehicleType }, abortController)
            .then((states: CustomerAllowedState[]) => {
                setAvailableStates([...states, OptionStateNotListed])
                setAvailableParentStates([...states, OptionStateNotListed])
            })
            .catch(() => undefined)

        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (availableStates.length > 0)
            visibilityContext?.updateCondition('eligibleStatesLoaded', true)
    }, [availableStates, visibilityContext])

    return (
        <Container>
            {stateNotListedToolTipText && (
                <StateNotListedToolTip
                    text={stateNotListedToolTipText}
                    fieldName={name}
                />
            )}
            <Select
                disabled={disabled}
                label="What state does the applicant live in?"
                placeholder="Select State"
                id={name}
                testID={name}
                onChange={onChange}
                value={value}
                prefilled={prefilled}
            >
                {availableStates.map((state) => {
                    return (
                        <Select.Option
                            value={state.value}
                            key={state.value}
                            data-testid={state.value}
                        >
                            {state.name}
                        </Select.Option>
                    )
                })}
            </Select>
        </Container>
    )
}

StateEligibilityDropdown.defaultProps = defaultProps

export default StateEligibilityDropdown
