import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, CepCelebrateIcon, UiArrowBackIcon } from '@octane/spark'
import Card from 'components/Card'

import { ConfirmationContainer, Title } from './ResetPasswordPage.styled'

export default function ResetConfirmation(): JSX.Element {
    const navigate = useNavigate()

    return (
        <>
            <Card>
                <ConfirmationContainer>
                    <div>
                        <CepCelebrateIcon
                            className="icon"
                            height={140}
                            width={140}
                            color=""
                        />
                    </div>
                    <div>
                        <Title className="title">Success!</Title>
                        <p>Your password has been reset</p>
                    </div>
                </ConfirmationContainer>
            </Card>
            <Button
                fullWidth={false}
                startIcon={UiArrowBackIcon}
                size="small"
                variant="white"
                onClick={() => navigate('/')}
            >
                Click here to login
            </Button>
        </>
    )
}
