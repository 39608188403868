import styled, { css } from 'styled-components'

export const Container = styled.div`
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    align-self: stretch;
    position: relative;
`

export const ImageViewerViewport = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
    overscroll-behavior: contain;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Image = styled.img<{
    isLandscape: boolean
}>`
    transform: var(--document-media-transform);
    box-shadow: 0 0 15px #0007;
    position: relative;
    margin-bottom: 30px;
    box-sizing: content-box;
    transition: transform 0.3s ease-out;

    ${({ isLandscape }) =>
        isLandscape &&
        css`
            width: 97%;
        `};
    ${({ isLandscape }) =>
        !isLandscape &&
        css`
            height: 97%;
        `};
`
