import styled from 'styled-components'

export const Container = styled.div`
    @media print {
        form > div {
            padding: 0;
            margin: 0;

            div:empty {
                display: none;
            }

            * {
                padding: 0;
                margin: 0;
                font-weight: normal;
                font-size: 13px;
                border: none;
                text-align: left;
                overflow: visible;
                white-space: initial;
                height: auto;
                padding: 0px;
                color: ${(props) =>
                    props.theme.colors.monochrome.jetlineBlack} !important;
            }

            h2 {
                margin: 12px 5px 5px 0;
                font-size: 17px;
                font-weight: bold;
            }

            h3 {
                margin: 12px 0 5px 0;
                font-size: 15px;
                font-weight: bold;
            }

            * label {
                padding-top: 7px;
                font-weight: bold;
            }

            * > span > svg {
                padding: 0 5px 0 0;
                display: block;
            }

            input {
                height: auto;
                font-weight: normal;
            }

            svg {
                display: none;
            }
        }

        form > :nth-last-child(2) {
            padding-bottom: 10px;
            page-break-before: always;
            page-break-after: always;
        }

        form * {
            padding-left: 0px;
            margin-left: 0px;
            grid-column-gap: 0px !important;
            grid-row-gap: 0px !important;

            * button {
                width: fit-content !important;
                font-family: Karla, Helvetica, san-serif;
            }
        }

        * [aria-selected='false'] {
            display: none !important;
        }

        label[for='isCoapplicantAddressSame'] {
            padding-bottom: 10px;
        }

        #amount-field-monthlyHousingPayment,
        #amount-field-employerGrossAmount,
        #amount-field-otherIncomeGrossAmount,
        #amount-field-coapplicant_monthlyHousingPayment,
        #amount-field-coapplicant_employerGrossAmount,
        #amount-field-coapplicant_otherIncomeGrossAmount {
            div :nth-child(1) {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            div > div {
                position: absolute;
                left: 0;
            }

            div > input {
                position: relative;
                left: 10px;
            }
        }

        .no-print {
            display: none !important;
        }

        .print-only {
            display: block !important;
        }
    }

    .print-only {
        display: none;
    }
`

export const Signature = styled.div`
    display: none;

    font-family: Karla, Helvetica, san-serif;
    font-size: 14px;
    border: 1px solid black;
    height: 100px;
    width: 100%;
    padding: 5px 5px 0px 5px;
    margin-top: 30px;

    @media print {
        display: block;
    }
`
