import React, { useEffect } from 'react'

import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

export default function NotFound(): JSX.Element {
    useEffect(() => {
        OctaneLogger.info('User was shown NotFound (404).')
    }, [])

    return (
        <div>
            <h1>Not Found</h1>
        </div>
    )
}
