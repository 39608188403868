import React from 'react'

import { FieldInstructionAny, FieldKit } from '../../index'
import { useFormBuilderContext } from '../FormBuilder/FormBuilderContext'

interface FieldBuilderProps {
    fieldName: string
    fieldInstructionAny: FieldInstructionAny
    fieldKit?: FieldKit
    className?: string
}

function FieldBuilder(props: FieldBuilderProps): JSX.Element | null {
    const formContext = useFormBuilderContext()
    const { fieldName, fieldInstructionAny, className } = props
    // eslint-disable-next-line react/destructuring-assignment
    const fieldKit = props.fieldKit || formContext?.fieldKit

    if (!fieldKit) {
        throw Error(
            // eslint-disable-next-line max-len
            'FieldBuilder component must receive `fieldKit` either as a prop or from FormBuilder context',
        )
    }

    const fieldInstruction =
        typeof fieldInstructionAny === 'function'
            ? fieldInstructionAny(fieldKit.values, fieldKit)
            : fieldInstructionAny

    const { Component, config, WrapperComponent } = fieldInstruction

    if (!Component) {
        return null
    }

    if (WrapperComponent) {
        return (
            <WrapperComponent>
                <Component
                    fieldName={fieldName}
                    fieldConfig={config}
                    fieldKit={fieldKit}
                    className={className}
                />
            </WrapperComponent>
        )
    }

    return (
        <Component
            fieldName={fieldName}
            fieldConfig={config}
            fieldKit={fieldKit}
            className={className}
        />
    )
}

export default FieldBuilder

FieldBuilder.defaultProps = {
    fieldKit: undefined,
    className: undefined,
}
