import { css } from 'styled-components'

export const Tile = css`
    ${(props) => `
        box-shadow: ${props.theme.shadows.tile};
    `};
`

export const Modal = css`
    ${(props) => `
        box-shadow: ${props.theme.shadows.modal};
    `};
`
