import React from 'react'

import { CepDealerApprovedIcon, Button } from '@octane/spark'
import ClientApplication from 'api/application/interfaces/ClientApplication'

import { Container, SubmittedTitle } from './DecisionResult.styled'

export default function DecisionResultSubmitted({
    clientApplication,
}: {
    clientApplication: ClientApplication
}): JSX.Element {
    return (
        <Container>
            <CepDealerApprovedIcon />
            <SubmittedTitle>Application Submitted</SubmittedTitle>
            <a href={`#/application/status/${clientApplication?.id}`}>
                <Button>Check Status</Button>
            </a>
        </Container>
    )
}
