import { datadogRum } from '@datadog/browser-rum'
import axios, { AxiosError } from 'axios'

import config from '../../config'

export type LogContextType = {
    [key: string]: string | number | undefined | boolean | LogContextType
}

export class OctaneLogger {
    private static readonly allowedEnvironmentList = [
        'production',
        'qa',
        'sandbox',
        'dev-eks',
        'staging',
        'demo',
    ]

    public static DD_LOGS_HAVE_BEEN_ENABLED = false

    // default the logger to console
    private static LOGGER: {
        info: (
            message: string,
            messageContext?: object | undefined,
            error?: Error | undefined,
        ) => void
        warn: (
            message: string,
            messageContext?: object | undefined,
            error?: Error | undefined,
        ) => void
        error: (
            message: string,
            messageContext?: object | undefined,
            error?: Error | undefined,
        ) => void
    } = console

    private static init() {
        if (OctaneLogger.shouldEnableDatadogLogs()) {
            OctaneLogger.DD_LOGS_HAVE_BEEN_ENABLED = true

            window.DD_LOGS.setGlobalContextProperty('env', config.ENVIRONMENT)

            window.DD_LOGS.setGlobalContextProperty(
                'service',
                process.env.REACT_APP_NAME,
            )

            window.DD_LOGS.setGlobalContextProperty(
                'version',
                process.env.REACT_APP_RELEASE_VERSION,
            )

            window.DD_LOGS.logger.setHandler(OctaneLogger.environmentHandler())
            window.DD_LOGS.logger.setLevel('info')
            // override the console logger with the DD logger
            OctaneLogger.LOGGER = window.DD_LOGS.logger
        }
    }

    public static error(
        message: string,
        messageContext?: LogContextType,
        error?: Error,
    ) {
        OctaneLogger.init()
        OctaneLogger.LOGGER.error(message, messageContext, error)
        if (window.DD_RUM_ENABLED && datadogRum && datadogRum.addError) {
            if (error) {
                datadogRum.addError(error, messageContext)
            } else {
                datadogRum.addError(message, messageContext)
            }
        }
    }

    public static axiosError(
        message: string,
        error: AxiosError,
        messageContext?: LogContextType,
    ) {
        const context: LogContextType = messageContext || {}

        if (axios.isAxiosError(error)) {
            context.requestResponseCode = error.response?.status
            context.requestResponseBody = error.response?.data
        }

        OctaneLogger.error(message, context, error as Error)
    }

    public static warn(
        message: string,
        messageContext?: object,
        error?: Error,
    ) {
        OctaneLogger.init()
        OctaneLogger.LOGGER.warn(message, messageContext, error)
    }

    public static info(
        message: string,
        messageContext?: object,
        error?: Error,
    ) {
        OctaneLogger.init()
        OctaneLogger.LOGGER.info(message, messageContext, error)
    }

    /**
     * Allow simple `console.debug` logging for non-prod environments
     */
    public static debug(
        message: string,
        messageContext?: object,
        error?: Error,
    ) {
        if (config.ENVIRONMENT !== 'production') {
            // eslint-disable-next-line no-console
            console.debug(message, messageContext, error)
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static setUser(user: any) {
        OctaneLogger.init()
        if (!window || !window.DD_LOGS) {
            return
        }

        window.DD_LOGS.setUser({
            id: user?.id?.toString(),
            name: [user?.firstName, user?.lastName].join(' '),
            email: user?.email,
            username: user?.username,
        })

        const dealership = user?.dealership
        const dealershipId = dealership?.id
        const dealershipName = dealership?.name
        const primaryAssetType = dealership?.primaryAssetType

        if (dealershipId) {
            window.DD_LOGS.setGlobalContextProperty('dealership', {
                id: dealershipId,
                name: dealershipName,
            })
        }

        if (primaryAssetType) {
            window.DD_LOGS.setGlobalContextProperty(
                'primaryAssetType',
                primaryAssetType,
            )
        }
    }

    public static clearUser() {
        if (!window || !window.DD_LOGS) {
            return
        }

        window.DD_LOGS.clearUser()
    }

    private static shouldEnableDatadogLogs() {
        return (
            !OctaneLogger.DD_LOGS_HAVE_BEEN_ENABLED &&
            window &&
            window.DD_LOGS &&
            OctaneLogger.allowedEnvironmentList.includes(config.ENVIRONMENT)
        )
    }

    private static environmentHandler() {
        const handlers = ['http']

        if (config.ENVIRONMENT !== 'production') {
            // for non-prod environments, also send logs to console
            handlers.push('console')
        }

        return handlers
    }
}
