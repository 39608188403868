import styled from 'styled-components'

import { typography } from '../../assets/styles'

const VehicleBanner = styled.h3`
    ${typography.BodyCopy16};
    padding: 0;
    height: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    margin-bottom: 20px;
    width: 799px;
    color: #2366b1;
    gap: 52px;
`

export const Vehicle1Banner = styled(VehicleBanner)`
    > :first-child {
        ${typography.Copy16Bold}
        text-transform: uppercase;
    }

    > button {
        height: 25px;
        width: 195px;
        display: flex;
        flex-direction: row;
        align-items: center;

        > * {
            margin: auto;
        }
    }

    order: 1;
`

export const Vehicle2Banner = styled(VehicleBanner)`
    > *:not(:first-child) {
        text-align: right;
        text-decoration: underline;
    }

    > :first-child {
        display: flex;
        flex-direction: row;
        width: 209px;
        justify-content: space-between;

        > :first-child {
            ${typography.Copy16Bold}
            text-transform: uppercase;
        }

        > button {
            height: 25px;
            width: 85px;
            display: flex;
            flex-direction: row;
            align-items: center;

            > * {
                margin: auto;
            }
        }
    }

    order: 4;
`

export const LinkStyle = styled.button`
    ${typography.Copy16Bold}
    text-decoration: underline;
    color: #2366b1;
`

export const SeperationLine = styled.div`
    border: 0.5px solid #d5d9e0;
`

export const ModalInformation = styled.div`
    padding: 20px;
    border-radius: 5px;
    background-color: #f3f8fe;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > * > * {
        margin: 0;
    }
    > * a {
        color: #2366b1;
    }
`

export const ModalButton = styled.div`
    > Button {
        width: 100%;
        margin-top: 20px;
    }
`

export const Content = styled.div`
    size: 16px;
    line-height: 25px;

    > ol > li > .content_fade {
        color: #898f99;
    }
`
