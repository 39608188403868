import React from 'react'

import { FieldInstruction } from 'containers/FormBuilder'
import DisclosureField from 'form/fieldComponents/DisclosureField'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import DisclosureType from 'state/enums/DisclosureType'
import * as Yup from 'yup'

import ConsentCheckboxLabel from './consentCheckboxLabel'

function createDisclosureField(
    disclosureType: DisclosureType,
    withDisclosureModal?: boolean,
): FieldInstruction<typeof DisclosureField> {
    return {
        Component: DisclosureField,
        config: {
            content: (
                <ConsentCheckboxLabel
                    disclosureType={disclosureType}
                    withDisclosureModal={withDisclosureModal}
                />
            ),
            validator: Yup.object()
                .shape({
                    name: Yup.string(),
                    checked: Yup.bool().oneOf([true]),
                })
                .required(FIELD_REQUIRED),
            initialValue: false,
            disclosureName: disclosureType,
        },
    }
}

export default createDisclosureField
