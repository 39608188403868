import { datadogRum } from '@datadog/browser-rum'
import config from 'config'
import AssetType from 'state/enums/AssetType'
import { User } from 'state/user/UserState'

export default class DataDogRUM {
    allowedEnvironmentList = [
        'production',
        'qa',
        'dev-eks',
        'sandbox',
        'staging',
        'demo',
    ]

    constructor() {
        if (
            !window.DD_RUM_ENABLED &&
            this.allowedEnvironmentList.includes(config.ENVIRONMENT)
        ) {
            datadogRum.init({
                clientToken: 'pubb0c7be21e79ad96ed7ac545e3c83238d',
                applicationId: '082d131f-ee5b-407c-8a33-a7a2f22753c9',
                site: 'datadoghq.com',
                service: process.env.REACT_APP_NAME,
                version: process.env.REACT_APP_RELEASE_VERSION,
                env: config.ENVIRONMENT,
                sessionSampleRate: 0,
                trackUserInteractions: true,
                trackViewsManually: true,
                trackResources: true,
                trackLongTasks: true,
                silentMultipleInit: true,
                allowFallbackToLocalStorage: true,
                storeContextsAcrossPages: true,
                // Note: datadogRum.startSessionReplayRecording() is only called after
                // the user logs in and currently is only enabled for auto dealerships
                sessionReplaySampleRate: 0,
                defaultPrivacyLevel: 'mask',
                // options that must match the DD Logs config
                trackSessionAcrossSubdomains: true,
                useSecureSessionCookie: true,
                usePartitionedCrossSiteSessionCookie: true,
                // Tracing
                allowedTracingUrls: [config.API_BASE_URL],
            })

            window.DD_RUM_ENABLED = true
        }
    }

    // eslint-disable-next-line class-methods-use-this
    setUser(user: User) {
        datadogRum.setUser({
            id: user?.id?.toString(),
            name: [user?.firstName, user?.lastName].join(' '),
            email: user?.email,
            username: user?.username,
        })

        const dealership = user?.dealership
        const dealershipId = dealership?.id
        const dealershipName = dealership?.name
        const primaryAssetType = dealership?.primaryAssetType

        if (dealershipId) {
            datadogRum.setGlobalContextProperty('dealership', {
                id: dealershipId,
                name: dealershipName,
            })
        }

        if (primaryAssetType) {
            datadogRum.setGlobalContextProperty(
                'primaryAssetType',
                primaryAssetType,
            )

            // if (primaryAssetType === AssetType.AUTOMOBILE) {
            //     datadogRum.startSessionReplayRecording()
            // }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    clearUser() {
        datadogRum.clearUser()
    }

    // eslint-disable-next-line class-methods-use-this
    startView(path: string) {
        datadogRum.startView(path)
    }
}
