import React from 'react'

import Swap from 'components/Icons/Swap.svg'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ResetVehicleSelection(props: { formik: any }) {
    const { formik } = props

    return (
        <button
            type="button"
            onClick={() => {
                formik.setFieldValue('vehicle', {
                    ...formik.initialValues.vehicle,
                    condition: formik.values.vehicle.condition,
                })
            }}
        >
            <img src={Swap} alt="Swap icon" />
            <span>Reset Vehicle Selection</span>
        </button>
    )
}
