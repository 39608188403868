import { useEffect, useState } from 'react'

import { getLenderOptions, Lender } from 'api/lender'
import LenderName from 'state/enums/LenderName'

const useRoadrunnerLender = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [lender, setLender] = useState<Lender | undefined>()

    useEffect(() => {
        getLenderOptions().then((lenderOptions) => {
            const roadrunnerLender = lenderOptions?.data.objects.filter(
                (item) => item.name === LenderName.ROADRUNNER_FINANCIAL,
            )[0]

            setLender(roadrunnerLender)

            setLoading(false)
        })
    }, [])

    return {
        loading,
        lender,
    }
}

export default useRoadrunnerLender
