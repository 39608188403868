// eslint-disable react/no-unstable-nested-components
import React, { useEffect, useState } from 'react'

import { Spinner, Popover, UiPlusIcon } from '@octane/spark'
import Card from 'components/Card'
import UserInvitationList from 'components/UserInvitationList'
import UserInvitationModal from 'components/UserInvitationModal'
import { UserInvitation } from 'components/UserInvitationModal/types'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import { loadUsers, loadUsersAndSalespersons } from 'state/dealership/actions'
import LoadingStatus from 'state/enums/LoadingStatus'
import { loadInvitations } from 'state/invitation/actions'

import {
    LoadingWrapper,
    InviteButton,
    CollapsableCard,
    PopoverContent,
} from './ManageUsers.styled'
import UsersTable from './partials/UsersTable'

export default function ManageUsers(): JSX.Element {
    const dispatch = useAppDispatch()
    const [showInvitationModal, setShowInvitationModal] = useState(false)
    const invitationState = useAppSelector((state) => state.invitation)
    const dealershipState = useAppSelector((state) => state.dealership)
    const { count: dealershipUsersCount, page } = dealershipState.users.meta
    const dealershipUsersStatus: LoadingStatus = dealershipState?.users?.status
    const featureFlags = useFeatureFlagContext()

    const openInvitationsLimit =
        useAppSelector(
            (state) => state.user.data?.dealership?.openInvitationsLimit,
        ) || 10

    const invitationList: UserInvitation[] =
        invitationState?.invitationList as UserInvitation[]

    const invitationsListStatus: LoadingStatus =
        invitationState?.status as LoadingStatus

    const canInvite =
        invitationsListStatus === LoadingStatus.LOADED &&
        invitationList.length < openInvitationsLimit

    const canRenderInvites = !(
        invitationState.status === LoadingStatus.FAILED &&
        invitationState.error?.includes('403')
    )

    useEffect(() => {
        dispatch(loadInvitations())
    }, [dispatch])

    useEffect(() => {
        dispatch(
            featureFlags?.salesPersonFlexLink
                ? loadUsersAndSalespersons(page)
                : loadUsers(page),
        )
    }, [dispatch, page, featureFlags?.showFlexLink])

    function handleInviteUser() {
        setShowInvitationModal(true)
    }

    function renderSpinner() {
        return (
            <LoadingWrapper>
                <Spinner size={40} />
            </LoadingWrapper>
        )
    }

    function renderInvitationList() {
        return invitationsListStatus !== LoadingStatus.LOADING ? (
            <UserInvitationList invitations={invitationList ?? []} />
        ) : (
            renderSpinner()
        )
    }

    function renderUsersList() {
        return (
            <>
                {dealershipUsersStatus !== LoadingStatus.LOADING ? (
                    <UsersTable />
                ) : (
                    renderSpinner()
                )}
                {showInvitationModal && (
                    <UserInvitationModal
                        onClose={() => setShowInvitationModal(false)}
                    />
                )}
            </>
        )
    }

    function renderInviteNewUserButton() {
        const button = (
            <InviteButton
                endIcon={UiPlusIcon}
                variant="primary"
                size="small"
                onClick={() => handleInviteUser()}
                disabled={!canInvite}
            >
                Invite New User
            </InviteButton>
        )

        if (!canInvite) {
            return (
                <Popover
                    size="large"
                    placement="bottom-end"
                    trigger={button}
                    offset={[0, 10]}
                    rounded
                >
                    <PopoverContent>
                        You have reached the maximum number of outstanding
                        accounts allowed, please clean up your invite list by
                        Resending or Cancelling Invitations.
                    </PopoverContent>
                </Popover>
            )
        }

        return button
    }

    return (
        <div>
            {canRenderInvites && (
                <CollapsableCard
                    title={`Invites (${
                        invitationList?.length ? invitationList?.length : 0
                    })`}
                    $collapsed={!invitationList?.length}
                    rightComponent={renderInviteNewUserButton()}
                >
                    {invitationList?.length ? renderInvitationList() : ''}
                </CollapsableCard>
            )}
            <Card title={`Active Users (${dealershipUsersCount})`}>
                {renderUsersList()}
            </Card>
        </div>
    )
}
