import React from 'react'

import { MarineWriteIn } from 'containers/MarineWriteIn'
import AssetType from 'state/enums/AssetType'

import { FieldConfigBase, FieldProps } from '../../containers/FormBuilder'

export interface MarineWriteInBaseFieldConfig extends FieldConfigBase {
    assetType: AssetType
}

export default function MarineWriteInBaseField(
    props: FieldProps<MarineWriteInBaseFieldConfig>,
) {
    const { fieldKit } = props

    return <MarineWriteIn formik={fieldKit} />
}
