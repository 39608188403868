import React, { ReactNode, useState } from 'react'

import { Select } from '@octane/spark'
import { FieldProps, Field, getIn } from 'formik'

import TextInputWithAutoFocus from '../../TextInputWithAutoFocus'
import { ErrorMessage, Legend, Container } from './FormikDropdown.styled'

export interface FormikDropdownChild {
    name: string | JSX.Element
    value: string
}

type FormikSelectProps = {
    name: string
    label: string
    placeholder?: string
    info?: ReactNode
    infoText?: ReactNode
    children: FormikDropdownChild[]
    disabled?: boolean
    prefilled?: boolean
    error?: boolean
    searchable?: boolean
    searchPlaceholder?: string
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange?: Function
} & Pick<typeof defaultProps, 'onChange'>

const defaultProps = {
    placeholder: 'Select',
    info: '',
    infoText: '',
    disabled: false,
    prefilled: false,
    error: false,
    searchable: false,
    searchPlaceholder: 'Search...',
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onChange: (value: string) => {},
}

const filterChildren = (
    children: FormikDropdownChild[],
    searchPhrase: string,
) => {
    return children.filter((child) => {
        if (searchPhrase && typeof child.name === 'string') {
            const nameMatch = child.name
                ?.toLowerCase()
                ?.includes(searchPhrase.toLowerCase())

            const valueMatch = child.value
                ?.toLowerCase()
                ?.includes(searchPhrase.toLowerCase())

            return nameMatch || valueMatch
        }

        return true
    })
}

function FormikDropdown({
    name,
    placeholder,
    label,
    children,
    info,
    infoText,
    disabled,
    onChange,
    error,
    searchable,
    searchPlaceholder,
    prefilled,
}: FormikSelectProps) {
    const [searchPhrase, setSearchPhrase] = useState('')

    return (
        <Container>
            <Field name={name}>
                {({ field, form }: FieldProps) => {
                    const touched = getIn(form.touched, field.name)
                    const formikErrorMessage = getIn(form.errors, field.name)
                    const hasError = !!(formikErrorMessage || error)

                    return (
                        <>
                            {info && (
                                <Legend withIcon={!!infoText}>{info}</Legend>
                            )}
                            <Select
                                disabled={disabled}
                                prefilled={prefilled}
                                label={label}
                                value={String(field.value)}
                                placeholder={placeholder}
                                id={field.name}
                                info={infoText}
                                testID={name}
                                error={!!(touched && hasError)}
                                onChange={(val: string) => {
                                    const event = {
                                        target: {
                                            name,
                                            value: val,
                                        },
                                    }

                                    form.handleChange(event)
                                    onChange(val)
                                }}
                                noMatchMessage="No results found"
                                searchable={searchable}
                                searchComponent={
                                    <TextInputWithAutoFocus
                                        id={`search-input-${name}`}
                                        placeholder={searchPlaceholder}
                                        value={searchPhrase}
                                        onChange={setSearchPhrase}
                                    />
                                }
                            >
                                {filterChildren(children, searchPhrase).map(
                                    (child) => (
                                        <Select.Option
                                            value={child.value}
                                            key={child.value}
                                            data-testid={child.value}
                                        >
                                            {child.name}
                                        </Select.Option>
                                    ),
                                )}
                            </Select>{' '}
                            {!!(touched && formikErrorMessage) && (
                                <ErrorMessage>
                                    {formikErrorMessage}
                                </ErrorMessage>
                            )}
                        </>
                    )
                }}
            </Field>
        </Container>
    )
}

FormikDropdown.defaultProps = defaultProps

export default FormikDropdown
