import React from 'react'

import { StatusIndicator } from '@octane/spark'

import { Container } from './Decision.styled'

interface Props {
    value: string
}

export default function Decision({ value }: Props) {
    let variant = StatusIndicator.Variants.NO_STATUS

    if (value === 'Declined') {
        variant = StatusIndicator.Variants.REVIEW
    } else if (value === 'Prequalified') {
        variant = StatusIndicator.Variants.PREQUALIFIED
    } else if (value === 'Converted') {
        variant = StatusIndicator.Variants.CONVERTED
    }

    // replace prequalified with the correct icon once added in spark
    return (
        <Container>
            <StatusIndicator variant={variant} text={value} size="medium" />
        </Container>
    )
}
