import React from 'react'

import { FieldInstruction } from 'containers/FormBuilder'
import CheckboxField from 'form/fieldComponents/CheckboxField'

import LabelStyle from './styles/LabelStyle'

const isCoapplicantAddressSame: FieldInstruction<typeof CheckboxField> = {
    Component: CheckboxField,
    config: {
        label: (
            <LabelStyle>
                Co-Applicant&apos;s Address is the Same as Applicant&apos;s
            </LabelStyle>
        ),
        initialValue: false,
    },
}

export default isCoapplicantAddressSame
