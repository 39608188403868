import { typography } from 'assets/styles'
import styled from 'styled-components'

export const QrCodeContainer = styled.div`
    display: flex;
`

export const QrCode = styled.img`
    width: 246px;
    height: 246px;
    margin-right: 20px;
    background-color: gray;
`

export const Container = styled.div`
    form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`

export const ContainerAside = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const Title = styled.h1`
    ${typography.Hero24};
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
`
export const Row = styled.div<{ columns?: number }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns || 2}, 1fr);
    gap: 2em;
    margin: 0 0 0 0;
`

export const Divisor = styled.hr`
    background-color: ${(props) => props.theme.colors.monochrome.concrete};
    height: 1px;
    margin: 20px 0;
`

export const Actions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
`

export const PopupInfo = styled.span`
    ${typography.Copy14};
`

export const UrlActions = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    gap: 20px;
`
