import axios, { AxiosError } from 'axios'
import config from 'config'
import { DealershipSecuritySettings } from 'state/dealership/DealershipState'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function updateSecuritySettings(
    securitySettingsId: number,
    payload: DealershipSecuritySettings,
) {
    const parsedPayload = {
        password_rotation: parseInt(payload.passwordRotation, 10),
        session_inactivity: parseInt(payload.sessionInactivity, 10),
    }

    try {
        const { data } = await axios.put(
            `${config.API_BASE_URL}/api/v3/dealerships/security-settings/${securitySettingsId}/`, // eslint-disable-line max-len
            parsedPayload,
            {
                withCredentials: true,
            },
        )

        return data
        // eslint-disable-next-line
    } catch (error: any) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to update security settings: ${axiosError.message}`,
            axiosError,
            { securitySettingsId },
        )

        if (error?.response?.data?.detail) {
            throw new Error(error.response.data.detail)
        }

        throw new Error(axiosError.message)
    }
}
