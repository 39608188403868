/* eslint-disable @typescript-eslint/no-explicit-any */
import { DashboardData, DashboardItem } from '../types'

export default function ParseDashboard(payload: any): DashboardData {
    const dashboardData: DashboardData = {
        type: payload.type,
        header: payload.header,
        dashboard: {
            lastUpdated: payload.dashboard.lastUpdated,
            items: payload.dashboard.items.map((item: any) => {
                const dashboardItem: DashboardItem = {
                    component: item.component,
                    componentStyle: item.style,
                    data: item.data,
                }

                return dashboardItem
            }),
        },
    }

    return dashboardData
}
