/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { Spinner, MaskedTextInput } from '@octane/spark'
import { ChangePasswordFields } from 'containers/MyAccount/ChangePassword/ChangePasswordFields'
import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import useUser from 'hooks/useUser'
import LoadingStatus from 'state/enums/LoadingStatus'
import { acceptInvitation } from 'state/invitation/actions'
import { InvitationStep } from 'state/invitation/InvitationState'
import isValidPhoneNumber from 'utils/isValidPhoneNumber'
import * as toast from 'utils/toast'

import { Left, Right, Content, Title, Divider } from '../Invitation.styled'
import TermsCheckbox from './partials/TermsCheckbox/TermsCheckbox'
import {
    Avatar,
    UserName,
    WelcomeMessage,
    SaveButton,
    Center,
} from './Welcome.styled'

export default function Welcome(): JSX.Element {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [formValid, setFormValid] = useState(false)
    const [settingUpAccount, setSettingUpAccount] = useState(false)
    const [terms, setTerms] = useState(false)
    const { isLoggedIn } = useUser()

    const {
        data: { firstName, email },
        status,
        message,
        step,
    } = useAppSelector((state) => state.invitation)

    const handleSave = async () => {
        const { token } = params
        const errors = !!passwordError || phoneError

        if (!errors && token) {
            dispatch(
                acceptInvitation({
                    token,
                    password,
                    phone,
                }),
            )
        }
    }

    const validatePhone = () => {
        setPhoneError(!isValidPhoneNumber(phone))
    }

    useEffect(() => {
        setFormValid(
            !!password.length && !!phone.length && !phoneError && terms,
        )
    }, [password, phone, terms, phoneError])

    useEffect(() => {
        if (isLoggedIn) {
            setSettingUpAccount(false)
            navigate('/new-user-tour')
        }
    }, [isLoggedIn]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            step === InvitationStep.ACCEPTANCE &&
            status === LoadingStatus.LOADED
        ) {
            window.authenticatorIFrameClient?.authenticate(email, password)

            setSettingUpAccount(true)
        }

        if (status === LoadingStatus.FAILED) {
            toast.error({
                message: message as string,
                title: 'Error creating new user',
            })
        }
    }, [status, step, message, email]) // eslint-disable-line

    return (
        <Content>
            <Left>
                <UserName>
                    <Avatar>👋</Avatar>
                    Hello {firstName}.
                </UserName>
                <WelcomeMessage>
                    Welcome to <br /> the Octane Dealer <br /> Platform
                </WelcomeMessage>
            </Left>
            <Right>
                {!settingUpAccount && (
                    <>
                        <Title>Get started</Title>
                        <MaskedTextInput
                            data-testid="phoneNumberInput"
                            id="phoneNumber"
                            onChange={setPhone}
                            onBlur={validatePhone}
                            mask="(000) 000-0000"
                            placeholder="(000) 000-0000"
                            label="Your Phone Number"
                            error={phoneError}
                            errorText="Please enter your phone number"
                        />
                        <Divider />
                        <ChangePasswordFields
                            passwordLabel="Password"
                            onChange={setPassword}
                            newPasswordError={passwordError}
                            onClearErrors={() => setPasswordError('')}
                        />
                        <TermsCheckbox onChange={setTerms} />

                        <SaveButton
                            disabled={
                                !formValid || status === LoadingStatus.LOADING
                            }
                            onClick={handleSave}
                        >
                            Create Account
                        </SaveButton>
                    </>
                )}

                {settingUpAccount && (
                    <Center>
                        <h1>Please Wait</h1>
                        <Spinner background="grey" shadow size={80} />
                        <h1>Setting Up Your Account </h1>
                    </Center>
                )}
            </Right>
        </Content>
    )
}
