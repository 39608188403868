import React from 'react'

import TrackedDropdownLink from 'components/TrackedDropdownLink'

export interface DropdownUrlItemProps {
    text: string
    url?: string
    hashNavigate?: string
}

export function DropdownUrlItem({
    text,
    url,
    hashNavigate,
}: DropdownUrlItemProps) {
    return (
        <TrackedDropdownLink
            text={text}
            hashNavigate={hashNavigate}
            windowOpen={url}
        />
    )
}

DropdownUrlItem.defaultProps = {
    hashNavigate: '',
    url: '',
}
