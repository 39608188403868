import { useEffect, useState } from 'react'

import { getClientApplication } from 'api/application'
import ClientApplication from 'api/application/interfaces/ClientApplication'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

export interface UseClientApplicationInterface {
    clientApplication: ClientApplication | undefined
    loading: boolean
    error: Error | null
}

const useClientApplication = (
    applicationId: string | undefined,
): UseClientApplicationInterface => {
    const [clientApplication, setClientApplication] = useState(
        {} as ClientApplication,
    )

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const abortController = new AbortController()

        const loadClientApplication = async () => {
            return getClientApplication(applicationId || '', abortController)
        }

        if (!applicationId) {
            return
        }

        loadClientApplication()
            .then((application: ClientApplication) => {
                setError(null)
                setClientApplication(application)
            })
            .catch((clientApplicationError) => {
                OctaneLogger.error(
                    'Error when getting client application.',
                    { applicationId },
                    clientApplicationError as Error,
                )

                setError(clientApplicationError)
            })
            .finally(() => {
                setLoading(false)
            })

        // eslint-disable-next-line consistent-return
        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId])

    return {
        clientApplication,
        loading,
        error,
    }
}

export default useClientApplication
