import React from 'react'

import { IconComponent } from '@octane/spark'
import useGoogleTagManager from 'hooks/useGoogleTagManager'
import './NavbarItem.css'

const ICON_SIZE = 21

export enum UrlMatchType {
    Exact,
    StartsWith,
}

export interface NavbarItemProps {
    id: string
    href: string
    text: string
    icon: IconComponent
    urlMatchType: UrlMatchType
    /*  eslint-disable react/require-default-props */
    notificationCount?: number | string | null
    shouldShowNotification?: boolean
    newTab?: boolean
    onClick?: () => void
    /* eslint-enable react/require-default-props */
}

const isHrefActive = (href: string, urlMatchType: UrlMatchType) => {
    switch (urlMatchType) {
        case UrlMatchType.Exact:
            return href === window.location.hash

        case UrlMatchType.StartsWith:
            return window.location.hash.startsWith(href)

        default:
            return false
    }
}

export default function NavbarItem({
    id,
    href,
    icon: NavIcon,
    text,
    urlMatchType,
    notificationCount,
    shouldShowNotification = true,
    newTab,
    onClick,
}: NavbarItemProps): JSX.Element {
    const isActive = isHrefActive(href, urlMatchType)
    const googleTagManager = useGoogleTagManager()
    const onLeadsPage = window.location.hash === '#/leads'

    return (
        <li
            className={`NavbarItem ${isActive ? 'active' : ''}`}
            role="menuitem"
            tabIndex={0}
            onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    onClick?.()
                }
            }}
            onClick={onClick}
        >
            <a
                href={href}
                id={id}
                className={isActive ? 'active' : ''}
                onClick={() => {
                    googleTagManager.trackNavbarEvent(text, href)
                }}
                target={newTab ? '_blank' : '_self'}
                rel="noopener noreferrer"
            >
                <div style={{ position: 'relative' }}>
                    <NavIcon width={ICON_SIZE} height={ICON_SIZE} />
                    {!onLeadsPage &&
                        shouldShowNotification &&
                        notificationCount && (
                            <span className="notification-counter">
                                {notificationCount}
                            </span>
                        )}
                </div>
                <span className="Navbar-nav-label">{text}</span>
            </a>
        </li>
    )
}

NavbarItem.UrlMatchType = UrlMatchType
