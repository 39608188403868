import React from 'react'

import { NoticeBox, NoticeBoxColors, VsExclamationIcon } from '@octane/spark'
import styled from 'styled-components'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;

    @media print {
        display: none;
    }
`

export default function NewApplicationFormBetaNotice() {
    const onClickHandler = () => {
        OctaneLogger.info(
            '[NewApplicationFormBetaNotice] User clicked link to go back to the old form',
        )
    }

    return (
        <Container>
            <NoticeBox
                icon={VsExclamationIcon}
                color={NoticeBoxColors.SPOTLIGHT}
                heading="You are seeing the new version of the application form."
                content={
                    <>
                        Prefer the old experience?&nbsp;
                        <a
                            href="#!/application/new/legacy"
                            onClick={onClickHandler}
                        >
                            Click here
                        </a>
                    </>
                }
            />
        </Container>
    )
}
