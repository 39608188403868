/* eslint-disable max-len */
enum DisclosureType {
    CONSUMER_FORM_LEASE_DISCLOSURE = 'consumer_form_lease_disclosure',
    CONSUMER_FORM_LEASE_STATE_SELECTION_DISCLOSURE = 'consumer_form_lease_state_selection_disclosure',
    CONSUMER_FORM_APPROVAL_EMAIL_DISCLOSURE = 'consumer_form_approval_email_disclosure',
    CREDIT_SOFT_PULL_APPLICATION_APPROVAL = 'credit_soft_pull_application_approval',
    CREDIT_SAFECHECK_SOFT_PULL_APPLICATION_APPROVAL = 'credit_safecheck_soft_pull_application_approval',
    SOFT_PULL_CONTRACT_DISCLOSURE = 'soft_pull_contract_disclosure',
    HARD_PULL_CONTRACT_DISCLOSURE = 'hard_pull_contract_disclosure',
    APPLICATION_FORM_DISCLOSURE = 'application_form_disclosure',
    AUTOMOBILE_APPLICATION_FORM_DISCLOSURE = 'automobile_application_form_disclosure',
    MARINE_APPLICATION_FORM_DISCLOSURE = 'marine_application_form_disclosure',
    CUSTOMER_VERIFICATION_DISCLOSURE = 'customer_verification_disclosure',
}

export default DisclosureType
