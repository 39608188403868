import styled from 'styled-components'

import { typography } from '../../../assets/styles'

export const MainContainer = styled.main`
    max-width: 825px;
    padding: 0;
    margin-top: 0;
`

export const Titlebar = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 0 20px 0;
    padding: 0;
`

export const Title = styled.h1`
    ${typography.Hero24};
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
`

export const Label = styled.h3`
    ${typography.BodyCopy16};
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
`

export const FooterLabelRight = styled.span`
    ${typography.BodyCopy16};
    float: right;
    margin-bottom: 24px;
`

export const LoaderWrapper = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`
