import { useEffect, useState } from 'react'

import { ping } from 'api/auth'

import useUser from './useUser'

export default function useKeepSessionAlive() {
    const { isLoggedIn } = useUser()
    const [intervalId, setIntervalId] = useState(0)

    useEffect(() => {
        if (isLoggedIn && !intervalId) {
            const currentIntervalId = setInterval(() => {
                ping().catch()
            }, 60000) as unknown as number

            setIntervalId(currentIntervalId)
        } else if (!isLoggedIn && intervalId) {
            clearInterval(intervalId)
        }
    }, [isLoggedIn, intervalId])
}
