import IFrameClient from './IFrameClient'

export default class WindowLocalStorageIFrameClient extends IFrameClient {
    constructor(target: Window, targetOrigin: string) {
        super('windowLocalStorage', target, targetOrigin)
    }

    setItem(key: string, item: string) {
        this.sendRequest('setItem', [key, item])
        this.reload()
    }
}
