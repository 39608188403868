import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUsersAndSalespersons } from 'api/dealership'
import { RootState } from 'state/store'

const loadUsersAndSalespersons = createAsyncThunk<
    any, // eslint-disable-line @typescript-eslint/no-explicit-any
    number | undefined,
    {
        state: RootState
    }
>(
    'dealership/loadUsersAndSalespersons',
    async (page: number | undefined, thunkAPI) => {
        const state: RootState = thunkAPI.getState()
        const currentPage = state.dealership.users.meta.page || 1

        return getUsersAndSalespersons(page ?? currentPage)
    },
)

export default loadUsersAndSalespersons
