import { AxiosResponse } from 'axios'

export interface ForbiddenDataInterface {
    detail: {
        forbidden_reason: number
        message: string
    }
}

export interface RateLimitDataInterface {
    rate_limit: {
        ends_at: string
        human_friendly_ends_in: string
    }
}

function getForbiddenMessage(data: ForbiddenDataInterface) {
    if (data.detail.forbidden_reason === 1) {
        // "failed_due_to_expired_password"
        // eslint-disable-next-line max-len
        return 'Your password has expired. Please reset it using the "Forgot Password" link.'
    }

    return data.detail.message
}

function parseErrorData(data: RateLimitDataInterface) {
    let rateLimitEndsIn = 'a little moment'

    rateLimitEndsIn =
        (data && data.rate_limit && data.rate_limit.human_friendly_ends_in) ||
        rateLimitEndsIn

    return rateLimitEndsIn
}

export const getStatusMessage = (response: AxiosResponse): string => {
    const { data, status } = response
    let statusMessage = ''

    switch (status) {
        case 200:
            // Return no message if we succeeded
            break
        case 401:
            statusMessage =
                'The information you entered is not correct. Please try again.'

            break
        case 429:
            // eslint-disable-next-line max-len
            statusMessage = `Your account has been blocked because of too many failed login attempts. Please try again in ${parseErrorData(
                data,
            )} or contact us.`

            break
        case 403:
        default:
            statusMessage = getForbiddenMessage(data)
            break
    }

    return statusMessage
}
