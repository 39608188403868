import React, { useState } from 'react'

import { Button, TextInput, UiArrowLeftIcon, UiPlusIcon } from '@octane/spark'
import { addSubscriber } from 'api/notifications'
import { useTheme } from 'styled-components'
import toastConsts from 'utils/consts/toast'
import isValidEmail from 'utils/isValidEmail'
import removeAllSpaces from 'utils/removeAllSpaces'
import * as toast from 'utils/toast'

import {
    AddEmailWrapper,
    Container,
    ActionText,
    ButtonWrapper,
    FieldWrapper,
    Field,
} from './AddRecipientButton.styled'

export interface AddRecipientButtonProps {
    notificationName: string
    callback: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default function AddRecipientButton({
    notificationName,
    callback,
}: AddRecipientButtonProps) {
    const theme = useTheme()
    const [showEntry, setShowEntry] = useState(false)
    const [emailAddress, setEmailAddress] = useState('')
    const recipientId = removeAllSpaces(notificationName)

    const handleAddSubscriber = () => {
        const params = {
            name: notificationName,
            recipient_email: emailAddress,
        }

        addSubscriber(params)
            .then(() => {
                toast.success({
                    message:
                        toastConsts.notifications.subscriber.added(
                            emailAddress,
                        ),
                })

                setEmailAddress('')
                setShowEntry(false)
                callback()
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
                toast.error({
                    message: error.message,
                })
            })
    }

    return (
        <Container>
            {showEntry ? (
                <FieldWrapper>
                    <Field>
                        <TextInput
                            id={`input-${recipientId}`}
                            placeholder="Enter an email address"
                            onChange={setEmailAddress}
                            type="email"
                        />
                        <ButtonWrapper>
                            <Button
                                fullWidth={false}
                                size="small"
                                id={`button-add-recipient-${recipientId}`}
                                disabled={!isValidEmail(emailAddress)}
                                onClick={handleAddSubscriber}
                            >
                                <UiArrowLeftIcon
                                    color={theme.colors.monochrome.white}
                                    width={24}
                                    height={24}
                                />
                            </Button>
                        </ButtonWrapper>
                    </Field>
                    <ActionText onClick={() => setShowEntry(false)}>
                        Cancel
                    </ActionText>
                </FieldWrapper>
            ) : (
                <AddEmailWrapper>
                    <UiPlusIcon
                        data-testid="add-recipient-button"
                        width={16}
                        height={16}
                        color={theme.colors.brand.rideOctaneBlue}
                        onClick={() => setShowEntry(true)}
                    />
                    <ActionText onClick={() => setShowEntry(true)}>
                        Add another email address
                    </ActionText>
                </AddEmailWrapper>
            )}
        </Container>
    )
}
