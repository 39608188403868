import React, { useState } from 'react'

import { Radio, Button } from '@octane/spark'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import getApplicationDecisionPath from 'utils/getApplicationDecisionPath'

import { patchApplication } from '../../api/application'
import getFinalDecision from '../../api/application/getFinalDecision'
import { UpdateClientApplication } from '../../api/application/patchApplication'
import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'
import Loading from './partials/Loading'
import SelectLoan from './partials/SelectLoan'
import {
    DataContainer,
    RadioButtonContainer,
    Title,
    Content,
} from './ReturnCustomer.styled'
import {
    ActiveLoanType,
    ApplicationDataType,
    ReturnCustomerSelectionsType,
} from './types/returnCustomerTypes'

type ComponentProps = {
    readonly applicationData: ApplicationDataType | undefined
    readonly activeLoans: ActiveLoanType[]
    modal?: boolean
}

export default function ReturnCustomer({
    applicationData,
    activeLoans,
    modal,
}: ComponentProps): JSX.Element {
    const [isCustomerTradingIn, setIsCustomerTradingIn] =
        useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false)
    const [selectedLoanIds, setSelectedLoanIds] = React.useState<string[]>([])

    const decisionPage2Powersports =
        useFeatureFlagContext()?.decisionPage2Powersports

    const handleSetSelectedLoans = (selectedLoans: string[]) => {
        setSelectedLoanIds(selectedLoans)
    }

    const hasMoreThanOneActiveLoan = activeLoans.length > 1

    const renderSelectLoan = (): JSX.Element | null => {
        const renderSelectLoanView: boolean =
            isCustomerTradingIn && hasMoreThanOneActiveLoan

        return renderSelectLoanView ? (
            <SelectLoan
                data={activeLoans}
                handleSelectLoan={handleSetSelectedLoans}
            />
        ) : null
    }

    const getLoansForSubmission = (): string[] => {
        if (activeLoans && isCustomerTradingIn) {
            return hasMoreThanOneActiveLoan
                ? selectedLoanIds
                : [activeLoans[0].loanId]
        }

        return []
    }

    const createPatchApplicationDTOFromApplicationData = (
        payload: ReturnCustomerSelectionsType,
    ): UpdateClientApplication => {
        let patchDTO = {
            application_type: applicationData?.applicationType,
            return_customer: payload,
            type: applicationData?.type,

            // Primary Applicant Fields
            home_phone: applicationData?.homePhone,
            mobile_phone: applicationData?.mobilePhone,
            residential_status: applicationData?.residentialStatus,
            residential_years: applicationData?.residentialYears,
            residential_months: applicationData?.residentialMonths,
            monthly_housing_payment: applicationData?.monthlyHousingPayment,
            job_title: applicationData?.jobTitle,
            employment_status: applicationData?.employmentStatus,
            employer_name: applicationData?.employerName,
            employer_years: applicationData?.employerYears,
            employer_months: applicationData?.employerMonths,
            employer_monthly_gross: applicationData?.employerMonthlyGross,
            email_address: applicationData?.emailAddress,
        } as UpdateClientApplication

        const applicationIsJoint = applicationData?.applicationType === 'J'

        if (!applicationIsJoint) {
            return patchDTO
        }

        const coapplicantPatchDTO = {
            // Coapplicant Fields
            coapplicant_home_phone: applicationData?.coapplicantHomePhone,
            coapplicant_mobile_phone: applicationData?.coapplicantMobilePhone,
            coapplicant_residential_status:
                applicationData?.coapplicantResidentialStatus,
            coapplicant_residential_years:
                applicationData?.coapplicantResidentialYears,
            coapplicant_residential_months:
                applicationData?.coapplicantResidentialMonths,
            coapplicant_monthly_housing_payment:
                applicationData?.coapplicantMonthlyHousingPayment,
            coapplicant_job_title: applicationData?.coapplicantJobTitle,
            coapplicant_employment_status:
                applicationData?.coapplicantEmploymentStatus,
            coapplicant_employer_name: applicationData?.coapplicantEmployerName,
            coapplicant_employer_years:
                applicationData?.coapplicantEmployerYears,
            coapplicant_employer_months:
                applicationData?.coapplicantEmployerMonths,
            coapplicant_employer_monthly_gross:
                applicationData?.coapplicantEmployerMonthlyGross,
            coapplicant_email_address: applicationData?.coapplicantEmailAddress,
        }

        patchDTO = { ...patchDTO, ...coapplicantPatchDTO }

        return patchDTO
    }

    const handleSubmit = async (): Promise<void> => {
        const redirectUrl = getApplicationDecisionPath(
            applicationData?.id,
            decisionPage2Powersports,
        )

        try {
            setLoading(true)

            const returnCustomerPayload = {
                is_trade_in: isCustomerTradingIn,
                selected_loans: getLoansForSubmission(),
            }

            const payloadDTO = createPatchApplicationDTOFromApplicationData(
                returnCustomerPayload,
            )

            await patchApplication(payloadDTO, applicationData?.uuid)

            await getFinalDecision(applicationData?.uuid)
        } catch (error) {
            OctaneLogger.error(
                `Failed to submit return customer: ${error}`,
                {
                    applicationUuid: applicationData?.uuid,
                    applicaitonId: applicationData?.id,
                },
                error as Error,
            )
        }

        // To maintain consistent behavior, will always redirect to the current app's
        // status page on success/error
        window.location.hash = redirectUrl
    }

    const atLeastOneLoanSelectedForTradingIn: boolean =
        selectedLoanIds.length > 0

    const isCustomerTradingInButHasOnlyOneActiveLoan: boolean =
        isCustomerTradingIn && !hasMoreThanOneActiveLoan

    const multipleActiveLoansAndAtLeastOneLoanSelectedForTradingIn: boolean =
        isCustomerTradingIn &&
        hasMoreThanOneActiveLoan &&
        atLeastOneLoanSelectedForTradingIn

    /**
     * User should be able to continue if:
     * (a) They are not trading in or (b) they are trading in but have only one active loan or
     *  (c) they are trading in AND have more than one active loan AND
     *  they have selected at least one loan from for trading
     */
    const canSubmit: boolean =
        !isCustomerTradingIn ||
        isCustomerTradingInButHasOnlyOneActiveLoan ||
        multipleActiveLoansAndAtLeastOneLoanSelectedForTradingIn

    const renderContent = (): JSX.Element => {
        return (
            <>
                <DataContainer>
                    <Title>
                        This customer has an existing loan with Roadrunner
                        Financial.
                    </Title>
                    <Content>
                        Does the customer have a trade-in which is financed by
                        Roadrunner Financial?
                    </Content>
                    <RadioButtonContainer>
                        <Radio
                            label="Yes"
                            value="yes"
                            disabled={false}
                            key="yes"
                            checked={isCustomerTradingIn}
                            onChange={() => setIsCustomerTradingIn(true)}
                        />
                        <Radio
                            label="No"
                            value="No"
                            key="no"
                            disabled={false}
                            checked={!isCustomerTradingIn}
                            onChange={() => {
                                setIsCustomerTradingIn(false)
                                setSelectedLoanIds([])
                            }}
                        />
                    </RadioButtonContainer>
                    {renderSelectLoan()}
                </DataContainer>
                <Button
                    fullWidth
                    size="medium"
                    disabled={!canSubmit}
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Continue
                </Button>
            </>
        )
    }

    return <div>{loading ? <Loading modal={modal} /> : renderContent()}</div>
}

ReturnCustomer.defaultProps = {
    modal: false,
}
