import React from 'react'

import { Formik } from 'formik'

import { FormBuilderProps } from '../../index'
import {
    initialValuesFromFieldInstructionBundle,
    validationSchemaFromFieldInstructionBundle,
    mapFormikPropsToFieldKit,
    mapFormikPropsToFormKit,
} from '../../utils/formBuilderUtils'
import { FormBuilderContextProvider } from './FormBuilderContext'

function FormBuilder(props: FormBuilderProps) {
    const {
        onSubmit,
        fieldInstructionBundle,
        overrideDefaultInitialValues,
        enableReinitialize,
        validateOnMount,
        children,
    } = props

    const defaultInitialValues = {
        ...initialValuesFromFieldInstructionBundle(fieldInstructionBundle),
    }

    const initialValues = {
        ...defaultInitialValues,
        ...overrideDefaultInitialValues,
    }

    const validationSchema = validationSchemaFromFieldInstructionBundle(
        fieldInstructionBundle,
    )

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={enableReinitialize}
            validateOnMount={validateOnMount}
        >
            {(formikProps) => {
                const fieldKit = mapFormikPropsToFieldKit(formikProps)
                const formKit = mapFormikPropsToFormKit(formikProps)

                return (
                    <FormBuilderContextProvider value={{ fieldKit, formKit }}>
                        {typeof children === 'function'
                            ? children({ fieldKit, formKit })
                            : children}
                    </FormBuilderContextProvider>
                )
            }}
        </Formik>
    )
}

export default FormBuilder

FormBuilder.defaultProps = {
    enableReinitialize: false,
    validateOnMount: false,
    overrideDefaultInitialValues: undefined,
}
