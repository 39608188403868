import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
    const { pathname } = useLocation()

    useEffect(() => {
        // SafeCheck report manages its own scrolling
        if (pathname.startsWith('/safecheck/report/')) {
            return
        }

        window.scrollTo(0, 0)
    }, [pathname])

    return null
}
