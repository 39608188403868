import { DealershipUser } from '../DealershipState'

function getLevelOfAccess(salespersonId: number, groups: any) {
    if (salespersonId) return 'Sales'

    if (groups?.includes('End User:Dealer Admin')) return 'Admin'

    return 'Teammate'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ParseUser(payload: any): DealershipUser {
    return {
        id: payload.id,
        userName: `${payload.first_name} ${payload.last_name}`,
        firstName: payload.first_name,
        lastName: payload.last_name,
        emailAddress: payload.email,
        phoneNumber: payload.phone_number,
        levelOfAccess: getLevelOfAccess(payload.salesperson_id, payload.groups),
        applicationAccess: payload.dealer_user_type
            ? payload.dealer_user_type
            : 'Sales',
        prequalAppAccess: payload.dealership?.is_prequal_enabled ?? false,
        lastActive: payload.last_login,
    }
}
