import React, { useCallback, useEffect, useState } from 'react'

import { TextInput } from '@octane/spark'

import { Field } from '../ChangePassword.styled'
import ValidationsField, { Validations } from './ValidationsField'

type Props = {
    passwordLabel?: string
    confirmPasswordLabel?: string
    onChange: (validPassword: string) => void
    newPasswordError: string
    onClearErrors: () => void
} & typeof defaultProps

const defaultProps = {
    passwordLabel: 'New Password',
    confirmPasswordLabel: 'Confirm Password',
}

export function ChangePasswordFields({
    onChange,
    newPasswordError,
    onClearErrors,
    passwordLabel,
    confirmPasswordLabel,
}: Props): JSX.Element {
    const [newPassword, setNewPassword] = useState('')
    const [confirmedPassword, setConfirmedPassword] = useState('')
    const [someNewPassError, setSomeNewPassError] = useState(false)

    const [validations, setValidations] = useState<Validations>({
        hasSymbols: null,
        passwordsMatch: null,
        hasMinCharacters: null,
        hasNumbers: null,
        hasLowerUpper: null,
    })

    const checkValidation = useCallback(() => {
        if (!newPassword) {
            setValidations({
                hasSymbols: null,
                passwordsMatch: null,
                hasMinCharacters: null,
                hasNumbers: null,
                hasLowerUpper: null,
            })

            return
        }

        const validationItems: Validations = {
            hasMinCharacters: newPassword.length >= 8,
            hasLowerUpper: /(?=.*?[a-z])(?=.*?[A-Z])/.test(newPassword),
            hasNumbers: /\d/.test(newPassword),
            hasSymbols: /[-!$%^&*()_+|~=`{}[\]:/;<>?,.@#]/.test(newPassword),
            passwordsMatch: confirmedPassword
                ? confirmedPassword === newPassword
                : null,
        }

        setValidations(validationItems)

        if (!Object.values(validationItems).some((i) => !i)) {
            onChange(newPassword)
            setSomeNewPassError(false)
        } else {
            setSomeNewPassError(
                !validationItems.hasMinCharacters ||
                    !validationItems.hasLowerUpper ||
                    !validationItems.hasNumbers ||
                    !validationItems.hasSymbols,
            )
        }
    }, [onChange, newPassword, confirmedPassword])

    function handleNewPasswordChange(value: string) {
        setNewPassword(value)
        onClearErrors()
    }

    useEffect(
        () => checkValidation(),
        [checkValidation, newPassword, confirmedPassword],
    )

    return (
        <>
            <Field>
                <TextInput
                    id="new-password"
                    data-testid="new-password"
                    errorText={newPasswordError}
                    error={!!newPasswordError || someNewPassError}
                    label={passwordLabel}
                    maxLength={30}
                    value={newPassword}
                    onChange={(value) => handleNewPasswordChange(value)}
                    placeholder=""
                    type="password"
                />
            </Field>
            <Field>
                <TextInput
                    id="confirmed-password"
                    data-testid="confirmed-password"
                    label={confirmPasswordLabel}
                    maxLength={30}
                    value={confirmedPassword}
                    onChange={setConfirmedPassword}
                    error={
                        validations.passwordsMatch != null &&
                        !validations.passwordsMatch
                    }
                    placeholder=""
                    type="password"
                />
            </Field>
            <ValidationsField validations={validations} />
        </>
    )
}

ChangePasswordFields.defaultProps = defaultProps

export default ChangePasswordFields
