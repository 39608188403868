import React from 'react'

import brandConfig from 'branding'

import { FooterContainer, FooterText, FooterWrapper } from './Footer.styled'

function Footer(): JSX.Element | null {
    if (!brandConfig.footerContent) {
        return null
    }

    return (
        <FooterWrapper>
            <FooterContainer>
                <FooterText>{brandConfig.footerContent}</FooterText>
            </FooterContainer>
        </FooterWrapper>
    )
}

export default Footer
