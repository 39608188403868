import React from 'react'

import { Button, UiArrowLeftIcon, UiArrowRightIcon } from '@octane/spark'
import { pushLeadPagination } from 'utils/gtm-utils'

import { Container, Information } from './LeadsPagination.styles'
import { PaginationProps } from './LeadsPagination.types'

function LeadsPagination({
    pageCount,
    disabled,
    currentPage,
    onChangePage,
}: PaginationProps): JSX.Element {
    const handlePagination = (action: string, page: number): void | null => {
        if (disabled) return null

        if (currentPage === 1 && action === 'prev') return null

        if (currentPage === pageCount && action === 'next') return null

        return onChangePage(page)
    }

    return (
        <Container data-testid="table-pagination">
            <Button
                variant="white"
                id="page-left"
                startIcon={UiArrowLeftIcon}
                disabled={disabled || currentPage === 1}
                onClick={() => {
                    handlePagination('prev', currentPage - 1)
                    pushLeadPagination('Previous', currentPage - 1)
                }}
            />
            <Information isLoading={disabled}>
                Page {currentPage} of {pageCount}
            </Information>
            <Button
                variant="white"
                id="page-right"
                endIcon={UiArrowRightIcon}
                disabled={disabled || currentPage === pageCount}
                onClick={() => {
                    handlePagination('next', currentPage + 1)
                    pushLeadPagination('Next', currentPage + 1)
                }}
            />
        </Container>
    )
}

export default LeadsPagination
