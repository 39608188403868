import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.monochrome.graphite};
    }
    svg {
        cursor: pointer;
        fill: ${({ theme }) => theme.colors.brand.rideOctaneBlue};
    }
`

export const RowItem = styled.div`
    border-radius: 3px;
    padding: 7px 10px;
    border: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    display: flex;
    align-items: center;
`
