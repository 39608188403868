import styled, { createGlobalStyle, css } from 'styled-components'

export const GlobalStyle = createGlobalStyle<{ isOpen: boolean }>`
    ${({ isOpen }) =>
        isOpen &&
        css`
            html {
                overflow-y: hidden;
            }
        `}
`

export const PortalContent = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow-y: auto;
    z-index: 1000;
`

export const Overlay = styled.div<{ state: string }>`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: ${({ theme }) => theme.colors.monochrome.ravenSub};
    z-index: 1;
    transition: 0.2s;
    opacity: ${({ state }) => (state === 'entered' ? 0.6 : 0)};
`

export const ContentWrapper = styled.div<{ state: string }>`
    background-color: ${({ theme }) => theme.colors.monochrome.moonGrey};
    border: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    border-radius: 2px;
    margin: 30px;
    max-width: 100%;
    padding: 22px;
    position: relative;
    transition: 0.2s;
    transform: ${({ state }) =>
        state === 'entered' ? 'scale(1)' : 'scale(0.8)'};
    opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
    z-index: 10;
`

export const ModalWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
`

export const ActionContainer = styled.div`
    justify-content: flex-end;
    display: flex;
    margin-bottom: 10px;
    width: 100%;
`
