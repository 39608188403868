import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function reassignApps(
    fromUserId: number,
    toUserId: number,
) {
    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/users/${fromUserId}/reassign-apps/`,
            {
                to_user_id: toUserId,
            },
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to reassign apps: ${axiosError.message}`,
            axiosError,
            {
                fromUserId,
                toUserId,
            },
        )

        throw axiosError
    }
}
