import { User } from 'state/user/UserState'

const timeStamp = () => {
    return `${new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'America/New_York',
    })
        .format(new Date())
        .replace(/,/g, '')} ET`
}

/**
 * Push GTM event when a page is viewed in the Dealer Portal.
 *
 * @param {User} user - object of the current user
 */
export function pushPageView(user: User | undefined) {
    window.dataLayer.push({
        event: 'pageview',
        page: {
            page_location: window.location.pathname,
            page_referrer: window.document.referrer,
            page_type: 'Dealer Portal - Leads Page',
        },
        user,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when a search query is entered.
 *
 * @param {string} searchQuery - string of query in search bar
 */
export function pushLeadsSearchQuery(searchQuery: string) {
    window.dataLayer.push({
        event: 'leads_search_query',
        search_query: searchQuery,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when the Filter button is clicked.
 */
export function pushFilterButtonClick() {
    window.dataLayer.push({
        event: 'filter_button_click',
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when a filter is set and saved.
 *
 * @param {string} filter - string of clicked filter
 */
export function pushFilterSet(filter: string) {
    window.dataLayer.push({
        event: 'filter_set',
        filter_query: filter,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when the View Lead button is clicked in the main table.
 *
 * @param {number} leadId - selected lead number
 */
export function pushViewLeadClick(leadId: number) {
    window.dataLayer.push({
        event: 'view_lead_click',
        lead_id: leadId,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when the Convert To App button is clicked in the main table.
 *
 * @param {number} leadId - selected lead number
 */
export function pushConvertToAppClick(leadId: number) {
    window.dataLayer.push({
        event: 'convert_to_app_click_main',
        lead_id: leadId,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when a pagination button is pressed.
 *
 * @param {string} direction - string with pagination direction
 * @param {number} page - number of following page at time of click
 */
export function pushLeadPagination(direction: string, page: number) {
    window.dataLayer.push({
        event: 'lead_pagination',
        paging_page: page,
        paging_direction: direction,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when a column sorting option is clicked.
 *  *
 * @param {string} expression - string with sorting expression
 */
export function pushSortingClick(expression: string) {
    window.dataLayer.push({
        event: 'sorting_click',
        sort_expression: expression,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when a lead has been viewed.
 *
 * @param {number} leadId - selected lead number
 */
export function pushViewLeadModalImpression(leadId: number) {
    window.dataLayer.push({
        event: 'view_lead_modal_impression',
        lead_id: leadId,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when the Convert To App button is clicked within the modal.
 *
 * @param {number} leadId - converted lead number
 */
export function pushConvertToAppModalClick(leadId: number) {
    window.dataLayer.push({
        event: 'convert_to_app_click_modal',
        lead_id: leadId,
        time_stamp: timeStamp(),
    })
}

/**
 * Push a GTM event when a lead modal is closed.
 *
 * @param {number} leadId - closed lead number
 */
export function pushLeadModalClosed(leadId: number) {
    window.dataLayer.push({
        event: 'view_lead_modal_closed',
        lead_id: leadId,
        time_stamp: timeStamp(),
    })
}
