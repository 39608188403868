import React from 'react'

import { UserInvitation } from 'components/UserInvitationModal/types'
import dayjs from 'dayjs'

import { ColunmWrapper } from '../UserInvitationList.styled'

type Props = {
    value: UserInvitation
}

export default function SendingInfoColumn({ value }: Props) {
    return (
        <div>
            {dayjs(value.invitationSent).fromNow()}
            <ColunmWrapper>by {value.inviterName}</ColunmWrapper>
        </div>
    )
}
