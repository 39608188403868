import React from 'react'

import AssetType from 'state/enums/AssetType'
import VehicleType from 'state/enums/VehicleType'

import BaseCustomerEligibility from './BaseCustomerEligibility'
import CustomerNotEligibleWarningBox from './CustomerNotEligibleWarningBox'

interface TrailerCustomerEligibilityProps {
    sectionWidth?: string
    sectionMargin?: string
    children?: (selectedState: string) => JSX.Element
    initialValue?: string
    onChange?: (value: string) => void
    prefilled?: boolean
}

const defaultProps = {
    sectionWidth: undefined,
    sectionMargin: undefined,
    children: undefined,
    initialValue: undefined,
    onChange: undefined,
    prefilled: false,
}

const renderCustomerNotEligibleContent = () => (
    <CustomerNotEligibleWarningBox
        style={{ gridArea: 2, marginTop: 0 }}
        content={
            <>
                The primary applicant must be a resident of one of the listed
                states in order to be able to purchase a trailer.
            </>
        }
    />
)

const STATE_NOT_LISTED_TOOLTIP_TEXT =
    // eslint-disable-next-line max-len
    'We only fund trailers in select states. The primary customer must live in an eligible state to qualify for funding for a trailer.'

function TrailerCustomerEligibility(
    props: TrailerCustomerEligibilityProps,
): JSX.Element {
    return (
        <BaseCustomerEligibility
            assetType={AssetType.POWERSPORTS_VEHICLE}
            vehicleType={VehicleType.TRAILER}
            renderCustomerNotEligibleContent={renderCustomerNotEligibleContent}
            stateNotListedToolTipText={STATE_NOT_LISTED_TOOLTIP_TEXT}
            {...props}
        />
    )
}

TrailerCustomerEligibility.defaultProps = defaultProps

export default TrailerCustomerEligibility
