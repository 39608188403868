import IFrameServer from './IFrameServer'
import SyncLocalStorageItems from './SyncLocalStorageItems'
import WindowLocalStorageIFrameClient from './WindowLocalStorageIFrameClient'

export default class WindowLocalStorageIFrameServer extends IFrameServer {
    client?: WindowLocalStorageIFrameClient

    syncLocalStorageItems: SyncLocalStorageItems | null

    setIsLoaded: () => void

    constructor(
        target: Window,
        targetOrigin: string,
        setIsLoaded: () => void,
        client?: WindowLocalStorageIFrameClient,
    ) {
        super('windowLocalStorage', target, targetOrigin)
        this.syncLocalStorageItems = null
        this.setIsLoaded = setIsLoaded

        if (client) {
            this.syncLocalStorageItems = new SyncLocalStorageItems(
                localStorage,
                client,
            )
        }
    }

    // eslint-disable-next-line class-methods-use-this
    setItem(key: string, item: string) {
        localStorage.setItem(key, item)
    }

    onLoad() {
        if (this.syncLocalStorageItems !== null) {
            this.syncLocalStorageItems.sync()
        }
    }

    // eslint-disable-next-line class-methods-use-this
    dispatchIsLoaded() {
        this.setIsLoaded()
    }
}
