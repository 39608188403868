import React from 'react'

import Delete from 'components/Icons/Delete.svg'

// eslint-disable-next-line @typescript-eslint/ban-types
export default function RemoveSecondaryVehicle(props: {
    setMultiUnit: React.Dispatch<React.SetStateAction<boolean>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
}) {
    const { formik } = props

    return (
        <button
            type="button"
            onClick={() => {
                // eslint-disable-next-line react/destructuring-assignment
                props.setMultiUnit(false)

                formik.setFieldValue(
                    'vehicle.secondary',
                    formik.initialValues.vehicle.secondary,
                )

                formik.setFieldValue('vehicle.multiUnit', false)
            }}
        >
            <img src={Delete} alt="Delete icon" />
            <span>Remove</span>
        </button>
    )
}
