import getLeadsApplications, {
    SortingParam,
} from 'api/leads/getLeadsApplications'

export default async function getLeadsList(
    page = 1,
    filters: string[] = new Array<string>(),
    sortingParams: Array<SortingParam> = new Array<SortingParam>(),
    search = '',
): Promise<unknown> {
    return getLeadsApplications(
        page,
        filters,
        sortingParams,
        search,
        filters.length > 0 ? 'leads-only' : 'all',
    )
}
