import { Button } from '@octane/spark'
import { typography } from 'assets/styles'
import Card from 'components/Card'
import styled from 'styled-components'

export const ContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const LoadingWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2em;
`
export const InviteButton = styled(Button)`
    margin-bottom: 0.5em;
`
export const CollapsableCard = styled(Card)<{ $collapsed?: boolean }>`
    ${(props) =>
        props.$collapsed
            ? `
            padding: 30px 40px 25px;
            & > div {
                border: none;
                margin-bottom: 0;
            }
            `
            : ''}
`

export const PopoverContent = styled.p`
    ${typography.BodyCopy16};
`
