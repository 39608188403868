/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import {
    UiApplicationIcon,
    UiDashboardIcon,
    UiHomeIcon,
    UiLeadsIcon,
    UiLoanStatusIcon,
    UiMegaphoneIcon,
    UiResourcesIcon,
} from '@octane/spark'
import getLeadsCount from 'api/leads/getLeadsCount'
import brandConfig from 'branding'
import Navbar from 'components/Navbar'
import { UrlMatchType } from 'components/Navbar/NavbarItem'
import TermsOfUseModal from 'components/TermsOfUseModal/TermsOfUseModal'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useDashboard from 'hooks/useDashboard'
import useUser from 'hooks/useUser'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'
import { LEADS_PAGE_ROUTE_PATH } from 'pages/LeadsPage'
import { getAlreadyAddedLenders } from 'pages/MyAccount/Lenders/utils'
import AssetType from 'state/enums/AssetType'
import getNewApplicationPath from 'utils/getNewApplicationPath'

import LoggedInUser from './LoggedInUser/LoggedInUser'
import { shouldShowMenus } from './utils'

const shouldShowSelectLenderPage = (userId: number) => {
    const lenders = getAlreadyAddedLenders(userId)

    return lenders.length > 1
}

const getSelectLenderRoute = () => {
    return '#!/application/new'
}

export default function NavbarContainer(this: unknown): JSX.Element | null {
    const [isOpen, setIsOpen] = useState(false)
    const { user, isLoggedIn, isAutoDealership } = useUser()
    const { hasDashboard } = useDashboard()
    const { pathname } = useLocation()
    const featureFlags = useFeatureFlagContext()

    const [newLeadsCount, setNewLeadsCount] = useState<number | string | null>(
        null,
    )

    const [showNotification, setShowNotification] = useState(true)
    const navigate = useNavigate()

    const handleLeadsPageClick = () => {
        navigate(LEADS_PAGE_ROUTE_PATH)
        setShowNotification(false)
    }

    const isPowersportsDealership =
        user?.dealership?.primaryAssetType === AssetType.POWERSPORTS_VEHICLE

    let newApplicationPath = getNewApplicationPath(
        featureFlags,
        user?.dealership?.primaryAssetType,
    )

    if (user) {
        if (shouldShowSelectLenderPage(user.id)) {
            newApplicationPath = getSelectLenderRoute()
        }
    }

    useEffect(() => {
        let isMounted = true

        async function fetchNewLeadsCount() {
            if (
                isLoggedIn &&
                featureFlags?.leadsPage &&
                isPowersportsDealership
            ) {
                try {
                    const leadsCount = await getLeadsCount()
                    const newLeads = leadsCount?.new_leads_count

                    if (isMounted) {
                        if (newLeads > 99) {
                            setNewLeadsCount('99+')
                        } else if (newLeads > 0) {
                            setNewLeadsCount(newLeads)
                        } else {
                            setNewLeadsCount(null)
                        }
                    }
                } catch (error) {
                    if (isMounted) {
                        OctaneLogger.error(
                            `Error fetching total leads count:`,
                            undefined,
                            error as Error,
                        )
                    }
                }
            }
        }

        fetchNewLeadsCount()

        return () => {
            isMounted = false
        }
    }, [isLoggedIn, featureFlags?.leadsPage, isPowersportsDealership])

    if (brandConfig.hideNavbarAtLogin && !isLoggedIn) {
        return null
    }

    return (
        <Navbar>
            <TermsOfUseModal
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
            />
            {shouldShowMenus(pathname) && isLoggedIn ? (
                <>
                    {(hasDashboard || isAutoDealership) && (
                        <Navbar.Item
                            id="menu-dashboard"
                            href={
                                featureFlags?.webAppLoyaltyDashboard ||
                                isAutoDealership
                                    ? '#/dashboard'
                                    : '#!/loyalty-dashboard'
                            }
                            icon={UiDashboardIcon}
                            text="Dashboard"
                            urlMatchType={UrlMatchType.Exact}
                        />
                    )}
                    {!isAutoDealership && (
                        <Navbar.Item
                            id="menu-home"
                            href="#/"
                            icon={UiHomeIcon}
                            text="Home"
                            urlMatchType={UrlMatchType.Exact}
                        />
                    )}
                    {featureFlags?.leadsPage && isPowersportsDealership && (
                        <div>
                            <Navbar.Item
                                id="menu-leads"
                                href={`#/${LEADS_PAGE_ROUTE_PATH}`}
                                icon={UiLeadsIcon}
                                text="Leads"
                                urlMatchType={UrlMatchType.Exact}
                                notificationCount={newLeadsCount}
                                shouldShowNotification={showNotification}
                                onClick={handleLeadsPageClick}
                            />
                        </div>
                    )}

                    <Navbar.Item
                        id="menu-new-app"
                        href={newApplicationPath}
                        icon={UiApplicationIcon}
                        text="New App"
                        urlMatchType={UrlMatchType.StartsWith}
                    />
                    <Navbar.Item
                        id="menu-status"
                        href="#!/saved-applications"
                        icon={UiLoanStatusIcon}
                        text="Status"
                        urlMatchType={UrlMatchType.Exact}
                    />
                    <Navbar.Dropdown
                        id="menu-help"
                        icon={UiResourcesIcon}
                        text="Help"
                    >
                        <Navbar.Dropdown.UrlItem
                            text="Request 10-day Payoff"
                            hashNavigate="ten-day-payoff"
                        />
                        <Navbar.Dropdown.UrlItem
                            text="Contact Us"
                            url={
                                isAutoDealership
                                    ? 'https://www.roadrunnerfinancial.com/auto-dealer-contact'
                                    : 'https://www.roadrunnerfinancial.com/dealer-contact'
                            }
                        />
                        {!isAutoDealership && (
                            <Navbar.Dropdown.UrlItem
                                text="About our Lenders"
                                url="https://www.roadrunnerfinancial.com/about-our-lenders"
                            />
                        )}
                        <Navbar.Dropdown.CustomItem
                            text="Terms of Use"
                            handleClick={() => setIsOpen(true)}
                        />
                    </Navbar.Dropdown>
                    <Navbar.Item
                        id="menu-feedback"
                        href="https://roadrunnerfinancial2ndorg.my.site.com/roadrunner/s/dealer-advocacy-feedback"
                        icon={UiMegaphoneIcon}
                        text="Feedback"
                        urlMatchType={UrlMatchType.Exact}
                        newTab
                    />
                    <LoggedInUser />
                </>
            ) : null}
        </Navbar>
    )
}
