import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'

import { ColumnDef, ColumnSort, SortingState } from '@tanstack/react-table'
import markLeadAsViewedByDealer from 'api/leads/markLeadAsViewedByDealer'
import EmptyCard from 'components/Empty/EmptyCard'
import ErrorCard from 'components/error/ErrorCard'
import LeadsPagination from 'components/LeadsPagination'
import LoadingSpinner from 'components/LoadingSpinner'
import Table from 'components/Table'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'
import LeadActivity from 'pages/LeadsPage/models/lead_activity'
import { LeadDetail, LeadResult } from 'pages/LeadsPage/types'
import LoadingStatus from 'state/enums/LoadingStatus'
import { pushSortingClick } from 'utils/gtm-utils'

import { parseLead } from '../../utils'
import getLeadsList from '../../utils/getLeadsList'
import { Activity, Decision, DealerAssigned } from './Columns'
import CtaButtons, { CtaActions } from './CtaButtons'

export interface LeadsSearchTableProps {
    cbCtaClicked: (action: CtaActions, leadId: number) => void
    prequalifiedChecked: boolean
    declinedChecked: boolean
    loadingStatus: LoadingStatus
    search: string
    setLoadingStatus: (loadingStatus: LoadingStatus) => void
}

export type LeadsSearchTableRef = {
    markLeadAsViewed: (viewedLead: LeadDetail) => Promise<void>
}
export interface TableMetaProps {
    cbCtaClicked: (action: CtaActions, leadId: number) => void
}

export type SortingParamOrder = 'asc' | 'desc'
export interface SortingParam {
    field: string
    order: SortingParamOrder
}

export type SortingParams = Array<SortingParam>

const defaultSortingParam: SortingParam = {
    field: 'received_n_days_ago',
    order: 'asc',
}

function parseSortingStateToSortingParams(
    sortingState: SortingState,
): SortingParams {
    const sortingParams = sortingState.map<SortingParam>(
        (value: ColumnSort) => ({
            field: value.id,
            order: !value.desc ? 'asc' : 'desc',
        }),
    )

    if (!sortingParams.find((el) => el.field === defaultSortingParam.field)) {
        sortingParams.push(defaultSortingParam)
    }

    return sortingParams
}

const ACTIVITY: ColumnDef<LeadResult> = {
    header: 'Activity',
    accessorKey: 'lastActivity',
    cell: ({ getValue }) => {
        const lastActivity = getValue<LeadActivity | null>()

        return lastActivity ? <Activity value={lastActivity} /> : null
    },
    size: 100,
    enableSorting: false,
}

const RECEIVED: ColumnDef<LeadResult> = {
    header: 'Received',
    id: 'received_n_days_ago',
    accessorKey: 'received',
    size: 150,
}

const DECISION: ColumnDef<LeadResult> = {
    id: 'status',
    header: 'Decision',
    accessorKey: 'decision',
    cell: ({ getValue }) => <Decision value={getValue()} />,
    size: 200,
}

const SOURCE: ColumnDef<LeadResult> = {
    header: 'Source',
    accessorKey: 'source',
    size: 200,
    enableSorting: false,
}

const NAME: ColumnDef<LeadResult> = {
    id: 'name',
    header: 'Name',
    accessorKey: 'name',
    size: 250,
}

const ASSIGNED: ColumnDef<LeadResult> = {
    id: 'assigned',
    header: 'Assigned to',
    accessorKey: 'assigned',
    cell: ({ getValue }) => <DealerAssigned value={getValue()} />,
    size: 250,
}

const CONDITION: ColumnDef<LeadResult> = {
    header: 'Condition',
    accessorKey: 'condition',
    size: 100,
    enableSorting: false,
}

const VEHICLE_REQUESTED: ColumnDef<LeadResult> = {
    header: 'Vehicle Requested',
    accessorKey: 'vehicleRequested',
    size: 400,
    enableSorting: false,
}

const ACTION_BUTTONS: ColumnDef<LeadResult> = {
    id: 'actions',
    cell: ({ row, table }) => {
        const lead = row.original
        const { cbCtaClicked } = table.options.meta as TableMetaProps

        return <CtaButtons lead={lead} cbCtaClicked={cbCtaClicked} />
    },
    size: 400,
    enableSorting: false,
}

const leadColumns = [
    ACTIVITY,
    RECEIVED,
    DECISION,
    SOURCE,
    NAME,
    CONDITION,
    VEHICLE_REQUESTED,
    ACTION_BUTTONS,
]

const flexLinkColumns = [
    ACTIVITY,
    RECEIVED,
    DECISION,
    SOURCE,
    NAME,
    ASSIGNED,
    CONDITION,
    VEHICLE_REQUESTED,
    ACTION_BUTTONS,
]

function usePrevious<T>(value: T) {
    const ref = useRef<T>()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

function LeadsSearchTable(
    props: LeadsSearchTableProps,
    ref?: React.Ref<LeadsSearchTableRef>,
): JSX.Element {
    const {
        cbCtaClicked,
        prequalifiedChecked,
        declinedChecked,
        loadingStatus,
        setLoadingStatus,
        search,
    } = props

    const [leadsSearchResults, setLeadsSearchResults] = useState<LeadResult[]>(
        [],
    )

    const pageSize = 20
    const [page, setPage] = useState(1)
    const prevPage = usePrevious(page)
    const [pageCount, setPageCount] = useState(0)
    const blocksEffectsOnMount = useRef<boolean>(true)
    const featureFlag = useFeatureFlagContext()

    const [sortingParams, setSortingParams] = useState<
        Array<{
            field: string
            order: string
        }>
    >([])

    const [, setError] = useState<Error | null>(null)

    const markLeadAsViewed = async (viewedLead: LeadDetail): Promise<void> => {
        if (
            viewedLead.lastActivity === null ||
            viewedLead.lastActivity?.type !== 'new'
        )
            return

        const markAsViewedResponse = await markLeadAsViewedByDealer(
            viewedLead.id,
        )

        // If the leadsSearchResults are empty, there is nothing to upate
        if (leadsSearchResults.length === 0) return

        const viewedleadIndex = leadsSearchResults.findIndex(
            (lead: LeadResult) => viewedLead.id === lead.id,
        )

        setLeadsSearchResults([
            ...leadsSearchResults.slice(0, viewedleadIndex),
            {
                ...leadsSearchResults[viewedleadIndex],
                lastActivity:
                    LeadActivity.parseLeadActivityFromApiResponse(
                        markAsViewedResponse,
                    ),
            },
            ...leadsSearchResults.slice(viewedleadIndex + 1),
        ])
    }

    useImperativeHandle(ref, () => ({
        markLeadAsViewed,
    }))

    const cbRefreshTableData = ({ sorting }: { sorting: SortingState }) => {
        setSortingParams(parseSortingStateToSortingParams(sorting))
        setPage(1)
    }

    const handleFilter = (prequalified: boolean, declined: boolean) => {
        const filterParams: string[] = []

        if (prequalified) filterParams.push(`Approved`)

        if (declined) filterParams.push(`Declined`)

        return filterParams
    }

    function getLeads() {
        setLoadingStatus(LoadingStatus.LOADING)
        OctaneLogger.debug('Starting to fetch leads')

        getLeadsList(
            page,
            handleFilter(prequalifiedChecked, declinedChecked),
            sortingParams,
            search,
        )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((data: any) => {
                const { results, count } = data
                const parsedLeads = results.map(parseLead)

                setPageCount(Math.ceil(count / pageSize))
                setLeadsSearchResults(parsedLeads)

                if (results.length !== parsedLeads.length) {
                    OctaneLogger.error(
                        `Parsed leads length of ${parsedLeads.length} does 
                        not match expected length of ${results.length}`,
                    )
                }

                setLoadingStatus(LoadingStatus.LOADED)
                setError(null)
            })
            .catch((e: Error) => {
                if (prevPage && prevPage !== page) {
                    setPage(prevPage)
                }

                setError(e)

                OctaneLogger.error(
                    'Error while requesting LeadsList:',
                    undefined,
                    e,
                )

                setLoadingStatus(LoadingStatus.FAILED)
            })
    }

    useEffect(() => {
        if (sortingParams.length > 0 && blocksEffectsOnMount.current) {
            blocksEffectsOnMount.current = false
        }
    }, [sortingParams])

    useEffect(() => {
        if (
            blocksEffectsOnMount.current ||
            loadingStatus === LoadingStatus.LOADING
        )
            return

        OctaneLogger.debug('Fetching leads...')
        setLoadingStatus(LoadingStatus.LOADING)

        getLeads()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, prequalifiedChecked, declinedChecked, sortingParams])

    useEffect(() => {
        if (blocksEffectsOnMount.current) return

        setPage(1)
    }, [prequalifiedChecked, declinedChecked, search])

    useEffect(() => {
        if (blocksEffectsOnMount.current) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {}
        }

        const timer = setTimeout(getLeads, 500)

        return () => {
            clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    if (loadingStatus === LoadingStatus.FAILED) {
        OctaneLogger.error('Error while fetching leads.')

        return <ErrorCard />
    }

    return (
        <>
            <Table<TableMetaProps>
                columns={
                    !featureFlag?.salesPersonFlexLink
                        ? leadColumns
                        : flexLinkColumns
                }
                defaultSortingState={[
                    {
                        id: defaultSortingParam.field,
                        desc: defaultSortingParam.order === 'desc',
                    },
                ]}
                data={leadsSearchResults}
                cbRefreshData={cbRefreshTableData}
                cbCtaClicked={cbCtaClicked}
                onColumnClick={(column: string) => {
                    pushSortingClick(column)
                }}
                disableClientSideSorting
            />

            {pageCount > 0 && (
                <LeadsPagination
                    pageCount={pageCount}
                    disabled={loadingStatus === LoadingStatus.LOADING}
                    currentPage={page}
                    onChangePage={(newPage) => {
                        if (prevPage === newPage) return

                        setPage(newPage)
                    }}
                />
            )}

            {LoadingStatus.LOADING === loadingStatus && <LoadingSpinner />}
            {LoadingStatus.LOADED === loadingStatus &&
                leadsSearchResults.length === 0 && <EmptyCard />}
        </>
    )
}

export default forwardRef<LeadsSearchTableRef, LeadsSearchTableProps>(
    LeadsSearchTable,
)
