import React, { useEffect, useState } from 'react'

import getModels, { GetModelsOptions, Model } from 'api/vehicles/getModels'
import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'
import { setFieldValueAndTouched } from 'form/fields/utils/fieldUtils'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

interface modelProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    lenderId?: number
    disable?: boolean
    dataSource?: string[]
    assetType?: unknown
    primaryVehicleId?: number
    vehicleNumber: number
    prefilled?: boolean
    vehicleTypeList?: string[]
}

const defaultProps = {
    lenderId: null,
    disable: null,
    dataSource: null,
    assetType: null,
    primaryVehicleId: null,
    prefilled: false,
    vehicleTypeList: null,
}

export default function ModelDropdown({
    formik,
    lenderId,
    disable,
    dataSource,
    assetType,
    primaryVehicleId,
    vehicleNumber,
    prefilled,
    vehicleTypeList,
}: modelProps) {
    const [response, updateResponse] = useState([] as Model[])
    const { vehicle } = formik.values
    const { condition } = vehicle
    const FIRST_VEHICLE = 1
    const IS_FIRST_VEHICLE = vehicleNumber === FIRST_VEHICLE

    const vehicleFieldName = IS_FIRST_VEHICLE
        ? 'vehicle.primary.modelId'
        : 'vehicle.secondary.modelId'

    const year = IS_FIRST_VEHICLE
        ? vehicle.primary.year
        : vehicle.secondary.year

    const { makeId } = vehicle

    const model = IS_FIRST_VEHICLE
        ? vehicle.primary.modelId
        : vehicle.secondary.modelId

    useEffect(() => {
        const abortController = new AbortController()

        if (condition && year && makeId) {
            const getModelsOptions = {
                condition,
                year,
                makeId,
                lenderId,
                disable,
                dataSource,
                assetType,
                primaryVehicleId,
                vehicleTypeList,
            } as GetModelsOptions

            if (!IS_FIRST_VEHICLE) {
                // eslint-disable-next-line prefer-destructuring
                getModelsOptions.primaryVehicleId =
                    formik.values.vehicle.primary.selectedVehicleId.split(
                        ':',
                    )[0]
            }

            updateResponse([])

            getModels(getModelsOptions, abortController)
                .then((models: Model[]) => {
                    updateResponse(models)

                    if (
                        !prefilled &&
                        models.filter((e) => `${e.id}` === model).length === 0
                    ) {
                        formik.setFieldValue(
                            IS_FIRST_VEHICLE
                                ? 'vehicle.primary.modelId'
                                : 'vehicle.secondary.modelId',
                            '',
                        )

                        formik.setFieldValue(
                            IS_FIRST_VEHICLE
                                ? 'vehicle.primary.selectedVehicleId'
                                : 'vehicle.secondary.selectedVehicleId',
                            '',
                        )

                        if (IS_FIRST_VEHICLE) {
                            formik.setFieldValue('vehicle.vehicleType', '')
                        }
                    }

                    if (models.length === 1) {
                        setFieldValueAndTouched(
                            formik,
                            vehicleFieldName,
                            models[0].id.toString(),
                        )
                    }
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        OctaneLogger.error(
                            `Unexpected error when trying to load models.`,
                            undefined,
                            error as Error,
                        )
                    }
                })
        }

        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition, year, makeId])

    if ((!makeId || !year) && response.length < 1) {
        return (
            <FormikDropdown
                name={
                    IS_FIRST_VEHICLE
                        ? 'vehicle.primary.modelId'
                        : 'vehicle.secondary.modelId'
                }
                label="Model"
                placeholder={prefilled ? '' : 'Select Model'}
                disabled
            >
                {{ name: '', value: '' }}
                {{ name: '', value: '' }}
            </FormikDropdown>
        )
    }

    return (
        <FormikDropdown
            name={
                IS_FIRST_VEHICLE
                    ? 'vehicle.primary.modelId'
                    : 'vehicle.secondary.modelId'
            }
            label="Model"
            placeholder={prefilled ? '' : 'Select Model'}
            disabled={response.length === 0}
            error={model === null || model === ''}
            searchable
            searchPlaceholder="Search models..."
            prefilled={prefilled}
        >
            {response.map((child: Model) => {
                const newBorn: FormikDropdownChild = {
                    name: child.name,
                    value: child.id.toString(),
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}

ModelDropdown.defaultProps = defaultProps
