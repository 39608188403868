import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function getConsumerShoppedCollateralString(
    applicationUuid: string,
) {
    if (!applicationUuid) {
        throw new Error('Invalid application UUID')
    }

    try {
        const { data } = await axios.get(
            // eslint-disable-next-line max-len
            `${config.API_BASE_URL}/api/v3/applications/${applicationUuid}/consumer-shopped-collateral/first/`,
            {
                withCredentials: true,
            },
        )

        return data.consumer_shopped_collateral
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get consumer shopped collateral: ${axiosError.message}`,
            axiosError,
            { applicationUuid },
        )

        throw new Error(axiosError.message)
    }
}
