import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { shadows, typography } from 'assets/styles'
import styled from 'styled-components'

interface StyledLinkProps {
    active: boolean
}

export const StyledLink = styled.div<StyledLinkProps>`
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    ${typography.BodyCopy16};
    ${(props) => `
        color: ${props.theme.colors.brand.rideOctaneBlue};
        ${
            props.active &&
            `
                font-weight: bold;
                color: ${props.theme.colors.monochrome.jetlineBlack};
            `
        }
    `};

    &:hover {
        filter: brightness(50%);
    }
`

/**
 * Workaround to remove warning when running tests
 * because of react-router issue passing all properties to the DOM
 * Ref: https://bit.ly/3xt77GT
 */
interface MenuItemProps extends LinkProps {
    active: boolean
    testId: string
}

export function MenuItem(props: MenuItemProps) {
    const { to, children, testId, active } = props

    return (
        <Link to={to} data-testid={testId}>
            <StyledLink active={active}>{children}</StyledLink>
        </Link>
    )
}

export const Aside = styled.aside`
    ${shadows.Tile};
    background-color: ${(props) => props.theme.colors.monochrome.white};
    border-radius: 5px;
    align-self: flex-start;
`

export const Navigation = styled.nav`
    display: flex;
    flex-direction: column;
    width: 380px;
    padding: 22px;
`

export const AvatarWrapper = styled.div`
    position: absolute;
    top: -20px;
    left: calc(50% - 28px);
`

export const Wrapper = styled.div`
    position: relative;

    @media (max-width: 1050px) {
        width: 230px;
    }
`
