import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export type Lender = {
    name: string
    partner: boolean
    id: number
}

type Response = {
    meta: {
        limit: number
        offset: number
        total_count: number
    }
    objects: Lender[]
}

export default async function getLenderOptions() {
    try {
        const response = await axios.get<Response>(
            `${config.API_BASE_URL}/api/v1/lender/?offset=0&limit=5000`,
            {
                withCredentials: true,
            },
        )

        return response
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get lender options: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
