import { Button } from '@octane/spark'
import styled from 'styled-components'

export const Row = styled.div<{ columns?: number }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns || 2}, 1fr);
    gap: 2em;
    margin: 0.5em 0 0 0;
    min-height: 95px;
`
export const FormTitle = styled.h2`
    margin-top: 0;
`
export const Divisor = styled.hr`
    background-color: ${(props) => props.theme.colors.monochrome.concrete};
    height: 1px;
    margin: 20px 0;
`
export const LinkButton = styled(Button)`
    text-decoration: underline;
    height: 30px;
    margin-top: 10px;
    font-weight: normal;
    text-decoration-color: ${(props) =>
        props.theme.colors.brand.rideOctaneBlue}50;
    cursor: pointer;
`

interface WarningProps {
    'data-testid': string
}
export const AllDealershipChainWarning = styled.div<WarningProps>`
    color: ${(props) => props.theme.colors.monochrome.ravenSub};
    background-color: ${(props) => props.theme.colors.brand.octaneIce};
    font-size: 15px;
    display: flex;
    gap: 10px;
    padding: 0.5em 1em;
    margin: 10px 0;
    figure {
        margin: 1em 0.2em 1em 0.2em;
    }
    strong {
        color: ${(props) => props.theme.colors.brand.rideOctaneBlue};
    }
`
