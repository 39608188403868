import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

interface PasswordChange {
    userId: number
    userPassword: string
    newPassword: string
    resetPasswordToken: string | undefined
}

export default async function changePassword(passwordChange: PasswordChange) {
    const requestParams = {
        user_password: passwordChange.userPassword,
        new_password: passwordChange.newPassword,
        new_password_confirm: passwordChange.newPassword,
        reset_password_token: passwordChange.resetPasswordToken,
    }

    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/users/${passwordChange.userId}/change_password/`, // eslint-disable-line max-len
            requestParams,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to change password: ${axiosError.message}`,
            axiosError,
        )

        throw axiosError
    }
}
