import React, { useState } from 'react'
import { useNavigate } from 'react-router'

import {
    Button,
    CepCelebrateIcon,
    MaskedTextInput,
    Modal,
    TextInput,
} from '@octane/spark'
import { FactorType, requestChallenge, verifyFactor } from 'api/auth/mfa'
import updateUser from 'api/user/updateUser'
import { AnimatedField } from 'components/FormikFields/FormikTextInput/FormikTextInput.styled' // eslint-disable-line max-len
import useAppDispatch from 'hooks/useAppDispatch'
import { loadUser } from 'state/user/actions'
import { User } from 'state/user/UserState'
import * as toast from 'utils/toast'

import {
    MfaModalButtonWrapper,
    MfaModalConfirmation,
    MfaModalFooterText,
    MfaModalText,
    MfaModalTitle,
    MfaLinkButton,
} from './MfaModal.styled'
import { getMaskedPhoneNumber } from './utils'

type MfaProfileSetupModalProps = {
    isOpen: boolean
    handleClose: () => void
    user: User | undefined
}

function MfaProfileSetupModal({
    isOpen,
    handleClose,
    user,
}: MfaProfileSetupModalProps): JSX.Element {
    const dispatch = useAppDispatch()
    const [showTokenEntry, setShowTokenEntry] = useState(false)
    const [mfaToken, setMfaToken] = useState('')
    const [showMfaConfirmed, setShowMfaConfirmed] = useState(false)
    const [mfaPhoneNumber, setMfaPhoneNumber] = useState('')
    const [tokenInvalid, setTokenInvalid] = useState(false)
    const [tokenErrorText, setTokenErrorText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const handleMfaSendToken = async () => {
        try {
            await requestChallenge(FactorType.PHONE_NUMBER)

            toast.success({
                message: 'The code was successfully sent.',
                withCloseIcon: false,
            })

            return Promise.resolve()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            toast.error({ message: error.message, withCloseIcon: false })

            return Promise.reject(error.message)
        }
    }

    const handleMfaPhoneNumberSubmit = async () => {
        if (!mfaPhoneNumber.trim()) {
            return
        }

        try {
            setIsLoading(true)
            await updateUser({ mfa_phone: mfaPhoneNumber })
            await dispatch(loadUser())

            handleMfaSendToken().then(() => {
                setIsLoading(false)
                setShowTokenEntry(true)
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            toast.error({ message: error.message, withCloseIcon: false })
        } finally {
            setIsLoading(false)
        }
    }

    const verifyCode = (unverifiedCode: string) => {
        return verifyFactor(FactorType.PHONE_NUMBER, unverifiedCode.toString())
    }

    const handleMfaTokenSubmit = async () => {
        try {
            setIsLoading(true)
            await verifyCode(mfaToken)
            setShowTokenEntry(false)
            setShowMfaConfirmed(true)
            await dispatch(loadUser())
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            setTokenInvalid(true)
            setTokenErrorText(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleMfaModalClose = () => {
        handleClose()
        setMfaPhoneNumber('')
        setTokenErrorText('')
        setTokenInvalid(false)

        setTimeout(() => {
            setShowTokenEntry(false)
            setShowMfaConfirmed(false)
        }, 1000)
    }

    const handleMfaTokenChange = (value: string) => {
        setTokenInvalid(false)
        const onlyDigits = value.replace(/\D/g, '')

        setMfaToken(onlyDigits)
    }

    const handleSendAgain = async () => {
        setMfaToken('')
        setTokenInvalid(false)
        setTokenErrorText('')

        await handleMfaSendToken()
    }

    const handlePhoneNumberKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') handleMfaPhoneNumberSubmit()
    }

    const handleTokenKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') handleMfaTokenSubmit()
    }

    const renderMfaModalBody = () => {
        if (showMfaConfirmed) {
            return (
                <MfaModalConfirmation>
                    <CepCelebrateIcon width={120} height={120} />
                    <h2>Success!</h2>
                    <MfaModalText>
                        Multifactor authentication is now enabled.
                    </MfaModalText>
                    <Button
                        fullWidth
                        size="small"
                        onClick={() => navigate('/account-settings')}
                        loading={isLoading}
                    >
                        View Your Account
                    </Button>
                </MfaModalConfirmation>
            )
        }

        if (showTokenEntry) {
            return (
                <div>
                    <MfaModalTitle>Verify your access</MfaModalTitle>
                    <MfaModalText>
                        Please enter the one-time verification code sent to your
                        cell phone. {getMaskedPhoneNumber(user?.mfaPhoneNumber)}
                    </MfaModalText>
                    <AnimatedField>
                        <TextInput
                            data-testid="tokenInput"
                            id="tokenInput"
                            label="Verification Code"
                            value={mfaToken}
                            onChange={handleMfaTokenChange}
                            error={tokenInvalid}
                            errorText={tokenErrorText}
                            onKeyDown={handleTokenKeyDown}
                        />
                    </AnimatedField>
                    <MfaModalButtonWrapper>
                        <Button
                            fullWidth
                            size="small"
                            onClick={handleMfaTokenSubmit}
                            loading={isLoading}
                        >
                            Submit
                        </Button>
                        <MfaLinkButton
                            fullWidth
                            variant="white"
                            onClick={handleSendAgain}
                        >
                            Resend the Code
                        </MfaLinkButton>
                    </MfaModalButtonWrapper>
                </div>
            )
        }

        return (
            <div>
                <MfaModalTitle>Add your cell number</MfaModalTitle>
                <MfaModalText>
                    Please enter your cell phone number so you can receive
                    verification codes via text. Verification codes are used
                    when extra security is needed.
                </MfaModalText>
                <AnimatedField>
                    <MaskedTextInput
                        data-testid="phoneNumberInput"
                        id="mfaPhoneNumber"
                        onChange={setMfaPhoneNumber}
                        mask="(000) 000-0000"
                        placeholder="(000) 000-0000"
                        label="Cell Phone"
                        onKeyDown={handlePhoneNumberKeyDown}
                    />
                </AnimatedField>
                <MfaModalButtonWrapper>
                    <Button
                        fullWidth
                        size="small"
                        onClick={handleMfaPhoneNumberSubmit}
                        loading={isLoading}
                    >
                        Submit
                    </Button>
                </MfaModalButtonWrapper>
                <MfaModalFooterText>Data rates may apply.</MfaModalFooterText>
            </div>
        )
    }

    return (
        <Modal
            title=""
            isOpen={isOpen}
            onClose={handleMfaModalClose}
            scroll="paper"
            size="medium"
            withCloseIcon={false}
        >
            <Modal.Body>{renderMfaModalBody()}</Modal.Body>
        </Modal>
    )
}

export default MfaProfileSetupModal
