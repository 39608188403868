import axios, { AxiosError } from 'axios'
import config from 'config'
import AssetType from 'state/enums/AssetType'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

interface TitleInformation {
    id: number
    has_title: boolean
    no_title_reason: string | null
    no_title_other_reason: string | null
}

interface UccTitleDecision {
    id: number
    ucc_fee: number
    created: string
    updated: string
    document_type: string
    fee: string
}

export interface PowersportsAssetResponse {
    id: number
    vehicle_uuid: string
    title_information: TitleInformation | null
    vehicle_type: string
    condition: string
    mileage: number | null
    year: number
    make: string
    make_id: number | null
    model: string
    model_id: number | null
    oem: string | null
    msrp: string
    low_retail: string | null
    high_trade: string | null
    average_wholesale: string | null
    transmission: string | null
    weight: string | null
    stroke: string | null
    cylinders: string | null
    cc: string | null
    did_find_matching_make: boolean | null
    vehicle: number | null
}

export interface CollateralResponse<Asset> {
    id: number
    asset: Asset
    ucc_title_decision: UccTitleDecision | null
    created: string
    updated_at: string | null
    asset_type: AssetType
    asset_name: string
    asset_value: string
    asset_valuation_method: string
    asset_sales_price: string | null
    unique_external_id: string | null
    data_source: string
}

export default async function getCollaterals(
    applicationUuid: string,
    abortController?: AbortController | null,
) {
    try {
        if (!applicationUuid) {
            throw new Error('Invalid applicationUuid used with getCollaterals')
        }

        const response = await axios.get<
            CollateralResponse<PowersportsAssetResponse>[]
        >(
            `${config.API_BASE_URL}/api/v3/applications/${applicationUuid}/collaterals/`,
            {
                signal: abortController?.signal,
                withCredentials: true,
            },
        )

        if (response.status !== 200) {
            throw new Error(
                `Unexpected response status of ${response.status} while fetching app collaterals`,
            )
        }

        return response.data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get application collaterals: ${axiosError.message}`,
            axiosError,
            { applicationUuid },
        )

        throw axiosError
    }
}
