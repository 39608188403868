import React, { useEffect, useState } from 'react'

import { Modal, Button, CepWarningIcon } from '@octane/spark'
import {
    checkUserDeleteRequirements,
    checkSalespersonDeleteRequirements,
    deleteUser,
    deleteSalesperson,
} from 'api/user/'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useAppDispatch from 'hooks/useAppDispatch'
import { loadUsers, loadUsersAndSalespersons } from 'state/dealership/actions'
import toastConsts from 'utils/consts/toast'
import * as toast from 'utils/toast'

import FloatingPanel, { FloatingStates } from '../FloatingPanel/FloatingPanel'
import ReassignApps from './ReassignApps'
import { Form, Field } from './UserDeleteModal.styled'

type UserDeleteModalProps = {
    isOpen: boolean
    onModalClose: () => void
    userId: number | undefined
    levelOfAccess: string | undefined
}

function UserDeleteModal({
    isOpen,
    onModalClose,
    userId,
    levelOfAccess,
}: UserDeleteModalProps): JSX.Element {
    const {
        dealershipSettings: { manageUsers: managerUsersConsts },
    } = toastConsts

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)
    const [showWarning, setShowWarning] = useState<FloatingStates>('none')
    const [usersToReassign, setUsersToReassign] = useState<[]>([])
    const featureFlags = useFeatureFlagContext()

    function confirmUserDelete() {
        if (userId) {
            setLoading(true)

            if (levelOfAccess && levelOfAccess === 'Sales') {
                deleteSalesperson(userId)
                    .then(() => {
                        toast.success({
                            message:
                                managerUsersConsts.deleteUser.success.message,
                        })

                        dispatch(
                            featureFlags?.salesPersonFlexLink
                                ? loadUsersAndSalespersons()
                                : loadUsers(),
                        )

                        setLoading(false)
                        onModalClose()
                    })
                    .catch(({ response }) => {
                        const responseMessage = response?.data?.detail

                        toast.error({
                            title: managerUsersConsts.deleteUser.error.title,
                            message:
                                responseMessage ||
                                managerUsersConsts.deleteUser.error.message,
                        })

                        setLoading(false)
                    })
            } else {
                deleteUser(userId)
                    .then(() => {
                        toast.success({
                            message:
                                managerUsersConsts.deleteUser.success.message,
                        })

                        dispatch(loadUsersAndSalespersons())
                        setLoading(false)
                        onModalClose()
                    })
                    .catch(({ response }) => {
                        const responseMessage = response?.data?.detail

                        toast.error({
                            title: managerUsersConsts.deleteUser.error.title,
                            message:
                                responseMessage ||
                                managerUsersConsts.deleteUser.error.message,
                        })

                        setLoading(false)
                    })
            }
        }
    }

    useEffect(() => {
        if (userId) {
            if (levelOfAccess && levelOfAccess === 'Sales') {
                checkSalespersonDeleteRequirements(userId)
                    .then(() => {
                        setLoading(false)
                    })
                    .catch(({ response }) => {
                        const responseMessage = response?.data?.detail

                        toast.error({
                            message:
                                responseMessage ||
                                managerUsersConsts.checkUserDeleteRequirements
                                    .error.message,
                        })

                        onModalClose()
                    })
            } else {
                checkUserDeleteRequirements(userId)
                    .then((data) => {
                        setLoading(false)

                        if (data.users && data.users.length) {
                            const users = data.users as []

                            setShowWarning('show')
                            setUsersToReassign(users)
                        }
                    })
                    .catch(({ response }) => {
                        const responseMessage = response?.data?.detail

                        toast.error({
                            message:
                                responseMessage ||
                                managerUsersConsts.checkUserDeleteRequirements
                                    .error.message,
                        })

                        onModalClose()
                    })
            }
        }
    }, [userId, onModalClose, managerUsersConsts])

    return (
        <Modal isOpen={isOpen} onClose={onModalClose} scroll="paper">
            <Modal.Body>
                <FloatingPanel show={showWarning}>
                    <ReassignApps
                        usersToReassign={usersToReassign}
                        userId={userId}
                        onSuccess={() => setShowWarning('hide')}
                    />
                </FloatingPanel>
                <Form>
                    <CepWarningIcon width={140} height={140} color="" />
                    <h2>Sure you want to delete this user?</h2>
                    <Field>
                        <p>
                            Once you delete this user, they will no longer have
                            access and they won’t be able to get their account
                            back.
                        </p>
                    </Field>
                    <Field>
                        <Button
                            fullWidth
                            onClick={() => confirmUserDelete()}
                            loading={loading}
                        >
                            Yes, delete this account
                        </Button>
                        <Button
                            fullWidth
                            variant="white"
                            onClick={() => onModalClose()}
                        >
                            No, don’t delete it
                        </Button>
                    </Field>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UserDeleteModal
