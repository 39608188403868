import React from 'react'

import { Button, Popover, UiFilterIcon } from '@octane/spark'
import { pushFilterButtonClick } from 'utils/gtm-utils'

import { FilterProps } from './LeadsFilter.types'
import LeadsFilterContent from './LeadsFilterContent'

function LeadsFilter({
    prequalifiedChecked,
    declinedChecked,
    onChangePrequalified,
    onChangeDeclined,
}: FilterProps): JSX.Element {
    return (
        <div>
            <Popover
                size="medium"
                placement="bottom-end"
                offset={[0, 5]}
                trigger={
                    <Button
                        variant="white"
                        size="small"
                        startIcon={UiFilterIcon}
                        onClick={pushFilterButtonClick}
                    >
                        Filter
                    </Button>
                }
            >
                <LeadsFilterContent
                    prequalifiedChecked={prequalifiedChecked}
                    declinedChecked={declinedChecked}
                    onChangePrequalified={onChangePrequalified}
                    onChangeDeclined={onChangeDeclined}
                />
            </Popover>
        </div>
    )
}

export default LeadsFilter
