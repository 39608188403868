import styled from 'styled-components'

export const BorderLine = styled.div`
    width: 100%;
    height: 0px;
    border-top: 1px solid #d5d9e0;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 1em;
`
