import React, { useState } from 'react'

import { UiSearchIcon, theme } from '@octane/spark'
import { pushLeadsSearchQuery } from 'utils/gtm-utils'

import { Container, InputDiv, IconDiv } from './LeadsSearch.styled'
import { SearchProps } from './LeadsSearch.types'

function LeadsSearch({ onSearch }: Readonly<SearchProps>): JSX.Element {
    const [search, setSearch] = useState<string>('')

    const triggerSearch = (e: { key: string }) => {
        if (e.key === 'Enter') {
            pushLeadsSearchQuery(search)
            onSearch(search)
        }
    }

    return (
        <Container>
            <InputDiv>
                <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={triggerSearch}
                    placeholder="Search by Name or Vehicle"
                    size={20}
                    value={search}
                />
                <IconDiv>
                    <UiSearchIcon
                        width={30}
                        height={17}
                        color={theme.colors.brand.rideOctaneBlue}
                    />
                </IconDiv>
            </InputDiv>
        </Container>
    )
}

export default LeadsSearch
