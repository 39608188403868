/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import ClientApplication from '../../../api/application/interfaces/ClientApplication'
import {
    coapplicantFieldNames,
    applicantFieldNames,
    otherFieldNames,
    hiddenFormValues,
} from '../sharedInstructions'

const convertAmountToString = (amount: number | string | null | undefined) =>
    amount ? amount.toString() : '0'

export default function convertAppValues(app: ClientApplication) {
    const dob = app.dob.replaceAll('/', ' / ')

    const employerMonthlyGross = app.employer_monthly_gross
        ? app.employer_monthly_gross.toString()
        : '0'

    const employerGrossAmount = app.employer_gross_amount
        ? app.employer_gross_amount.toString()
        : employerMonthlyGross

    const employerGrossInterval = app.employer_gross_interval
        ? app.employer_gross_interval
        : 'monthly'

    const hasOtherIncome = !!(
        app.other_income_source ||
        app.other_monthly_gross_income ||
        app.other_income_gross_amount ||
        app.other_income_gross_interval
    )

    const otherIncomeSource = app.other_income_source
    const otherMonthlyGrossIncome = app.other_monthly_gross_income?.toString()

    const otherIncomeGrossAmount = app.other_income_gross_amount
        ? app.other_income_gross_amount.toString()
        : otherMonthlyGrossIncome

    const otherIncomeGrossInterval =
        !app.other_income_gross_interval && hasOtherIncome
            ? 'monthly'
            : app.other_income_gross_interval

    const data = {
        [hiddenFormValues.group]: app.group,
        [otherFieldNames.applicationType]:
            app.application_type === 'J' ? 'joint' : 'individual',
        [otherFieldNames.financeManager]: app.finance_manager,
        [applicantFieldNames.ssn]: app.social_security_number,
        [applicantFieldNames.firstName]: app.first_name,
        [applicantFieldNames.middleInitial]: app.middle_initial,
        [applicantFieldNames.lastName]: app.last_name,
        [applicantFieldNames.suffix]: app.suffix,
        [applicantFieldNames.dateOfBirth]: dob,
        [applicantFieldNames.email]: app.email_address,
        [applicantFieldNames.street]: app.primary_address,
        [applicantFieldNames.city]: app.primary_city,
        [applicantFieldNames.state]: app.primary_state,
        [applicantFieldNames.residenceStatus]: app.residential_status,
        [applicantFieldNames.residentialYears]:
            app.residential_years?.toString(),
        [applicantFieldNames.residentialMonths]:
            app.residential_months?.toString(),
        [applicantFieldNames.apartmentSuite]: app.primary_apt,
        [applicantFieldNames.zip]: app.primary_zip,
        [applicantFieldNames.homePhone]: app.home_phone,
        [applicantFieldNames.mobilePhone]: app.mobile_phone,
        [applicantFieldNames.workPhone]: app.work_phone,
        [applicantFieldNames.monthlyHousingPayment]: convertAmountToString(
            app.monthly_housing_payment,
        ),
        [applicantFieldNames.employmentStatus]: app.employment_status,
        [applicantFieldNames.employerMonthlyGross]: employerMonthlyGross,
        [applicantFieldNames.employerGrossAmount]: employerGrossAmount,
        [applicantFieldNames.employerGrossInterval]: employerGrossInterval,
        [applicantFieldNames.employerYears]: app.employer_years?.toString(),
        [applicantFieldNames.employerMonths]: app.employer_months?.toString(),
        [applicantFieldNames.employerName]: app.employer_name,
        [applicantFieldNames.jobTitle]: app.job_title,
        [applicantFieldNames.citizenshipStatus]: app.citizenship_status,
        [applicantFieldNames.hasOtherIncome]: hasOtherIncome,
        [applicantFieldNames.otherIncomeSource]: otherIncomeSource,
        [applicantFieldNames.otherMonthlyGrossIncome]: otherMonthlyGrossIncome,
        [applicantFieldNames.otherIncomeGrossAmount]: otherIncomeGrossAmount,
        [applicantFieldNames.otherIncomeGrossInterval]:
            otherIncomeGrossInterval,
        id: app.id,
        uuid: app.uuid,
    }

    if (app.application_type === 'J') {
        const coapplicant_dob = app.coapplicant_dob.replaceAll('/', ' / ')

        const coapplicant_employerMonthlyGross =
            app.coapplicant_employer_monthly_gross
                ? app.coapplicant_employer_monthly_gross.toString()
                : '0'

        const coapplicant_employerGrossAmount =
            app.coapplicant_employer_gross_amount
                ? app.coapplicant_employer_gross_amount.toString()
                : coapplicant_employerMonthlyGross

        const coapplicant_employerGrossInterval =
            app.coapplicant_employer_gross_interval
                ? app.coapplicant_employer_gross_interval
                : 'monthly'

        const coapplicant_hasOtherIncome = !!(
            app.coapplicant_other_income_source ||
            app.coapplicant_other_monthly_gross_income ||
            app.coapplicant_other_income_gross_amount ||
            app.coapplicant_other_income_gross_interval
        )

        const coapplicant_otherIncomeSource =
            app.coapplicant_other_income_source

        const coapplicant_otherMonthlyGrossIncome =
            app.coapplicant_other_monthly_gross_income?.toString()

        const coapplicant_otherIncomeGrossAmount =
            app.coapplicant_other_income_gross_amount
                ? app.coapplicant_other_income_gross_amount.toString()
                : coapplicant_otherMonthlyGrossIncome

        const coapplicant_otherIncomeGrossInterval =
            !app.coapplicant_other_income_gross_interval &&
            coapplicant_hasOtherIncome
                ? 'monthly'
                : app.coapplicant_other_income_gross_interval

        return {
            ...data,
            [otherFieldNames.relationOfCoapplicant]:
                app.coapplicant_relationship,
            [coapplicantFieldNames.ssn]: app.coapplicant_social_security_number,
            [coapplicantFieldNames.firstName]: app.coapplicant_first_name,
            [coapplicantFieldNames.middleInitial]:
                app.coapplicant_middle_initial,
            [coapplicantFieldNames.lastName]: app.coapplicant_last_name,
            [coapplicantFieldNames.suffix]: app.coapplicant_suffix,
            [coapplicantFieldNames.dateOfBirth]: coapplicant_dob,
            [coapplicantFieldNames.email]: app.coapplicant_email_address,
            [coapplicantFieldNames.street]: app.coapplicant_primary_address,
            [coapplicantFieldNames.city]: app.coapplicant_primary_city,
            [coapplicantFieldNames.state]: app.coapplicant_primary_state,
            [coapplicantFieldNames.residenceStatus]:
                app.coapplicant_residential_status,
            [coapplicantFieldNames.residentialYears]:
                app.coapplicant_residential_years?.toString(),
            [coapplicantFieldNames.residentialMonths]:
                app.coapplicant_residential_months?.toString(),
            [coapplicantFieldNames.apartmentSuite]: app.coapplicant_primary_apt,
            [coapplicantFieldNames.zip]: app.coapplicant_primary_zip,
            [coapplicantFieldNames.homePhone]: app.coapplicant_home_phone,
            [coapplicantFieldNames.mobilePhone]: app.coapplicant_mobile_phone,
            [coapplicantFieldNames.workPhone]: app.coapplicant_work_phone,
            [coapplicantFieldNames.monthlyHousingPayment]:
                convertAmountToString(app.coapplicant_monthly_housing_payment),
            [coapplicantFieldNames.employmentStatus]:
                app.coapplicant_employment_status,
            [coapplicantFieldNames.employerMonthlyGross]:
                coapplicant_employerMonthlyGross,
            [coapplicantFieldNames.employerGrossAmount]:
                coapplicant_employerGrossAmount,
            [coapplicantFieldNames.employerGrossInterval]:
                coapplicant_employerGrossInterval,
            [coapplicantFieldNames.employerYears]:
                app.coapplicant_employer_years?.toString(),
            [coapplicantFieldNames.employerMonths]:
                app.coapplicant_employer_months?.toString(),
            [coapplicantFieldNames.employerName]: app.coapplicant_employer_name,
            [coapplicantFieldNames.jobTitle]: app.coapplicant_job_title,
            [coapplicantFieldNames.citizenshipStatus]:
                app.coapplicant_citizenship_status,
            [coapplicantFieldNames.hasOtherIncome]: coapplicant_hasOtherIncome,
            [coapplicantFieldNames.otherIncomeSource]:
                coapplicant_otherIncomeSource,
            [coapplicantFieldNames.otherMonthlyGrossIncome]:
                coapplicant_otherMonthlyGrossIncome,
            [coapplicantFieldNames.otherIncomeGrossAmount]:
                coapplicant_otherIncomeGrossAmount,
            [coapplicantFieldNames.otherIncomeGrossInterval]:
                coapplicant_otherIncomeGrossInterval,
        }
    }

    return data
}
