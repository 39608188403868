import React, { createContext, useEffect, useMemo, useState } from 'react'

import DocumentViewerModal, {
    FileRequest,
} from 'components/DocumentViewerModal/DocumentViewerModal'

export interface DocumentViewerContextInterface {
    view: (file: FileRequest) => void
    close: () => void
}

export const DocumentViewerContext =
    createContext<DocumentViewerContextInterface | null>(null)

declare global {
    interface Window {
        documentViewer: {
            view: (value: FileRequest) => void
            close: () => void
        }
    }
}

export default function DocumentViewerManager(): JSX.Element | null {
    const [fileRequest, setFileRequest] = useState<FileRequest | null>(null)

    const ctx = useMemo(
        () => ({
            view: (value: FileRequest) => {
                setFileRequest(value)
            },
            close: () => setFileRequest(null),
        }),
        [setFileRequest],
    )

    useEffect(() => {
        window.documentViewer = ctx
    }, [ctx])

    return (
        <DocumentViewerContext.Provider value={ctx}>
            <DocumentViewerModal fileRequest={fileRequest} />
        </DocumentViewerContext.Provider>
    )
}
