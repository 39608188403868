import React from 'react'

import { Button, Modal } from '@octane/spark'

import { ModalButton, Content } from './DisclosureModal.styled'

export default function COPPAModal(props: {
    showModal: boolean
    toggleModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const { showModal, toggleModal } = props

    return (
        <Modal
            title="COPPA Disclosure"
            isOpen={showModal}
            onClose={/* istanbul ignore next */ () => toggleModal(false)}
            scroll="body"
        >
            <Modal.Body>
                <Content data-testid="modalContent-COPPADisclosure">
                    <p>
                        We do not knowingly collect or retain personally
                        identifiable information from consumers under the age of
                        eighteen. A child’s online privacy is protected by the
                        Children’s Online Privacy Protection Act (COPPA). Learn
                        more about COPPA by visiting the Federal Trade
                        Commission’s{' '}
                        <a
                            target="_blank"
                            // eslint-disable-next-line max-len
                            href="https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy"
                            rel="noreferrer"
                        >
                            website.
                        </a>
                    </p>
                </Content>
            </Modal.Body>
            <Modal.Footer>
                <ModalButton>
                    <Button
                        type="button"
                        variant="primaryOutlined"
                        onClick={() => toggleModal(false)}
                    >
                        Close
                    </Button>
                </ModalButton>
            </Modal.Footer>
        </Modal>
    )
}
