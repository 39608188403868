import ClientApplication from 'api/application/interfaces/ClientApplication'
import { SubmitApplicationDTO } from 'api/application/postApplication'
import { Disclosure } from 'api/lender/getLenderDisclosures'
import SourceAction from 'state/enums/SourceAction'
import SourceClient from 'state/enums/SourceClient'
import SourceSubclient from 'state/enums/SourceSubclient'

import { INCOME_INTERVAL } from '../../../form/fields/employmentFields'
import { FormValues } from '../../FormBuilder'
import {
    applicantFieldNames,
    tradeInFieldNames,
    coapplicantFieldNames,
    otherFieldNames,
    hiddenFormValues,
    isApplicationJointFunc,
    isCoapplicantAddressSameFunc,
} from '../sharedInstructions'

function calculateMonthlyGross(
    incomeAmount: string,
    incomeInterval: string,
): string {
    const amountNumber: number = parseFloat(incomeAmount)
    let monthlyNumber: number

    switch (incomeInterval) {
        case INCOME_INTERVAL.yearly.value:
            monthlyNumber = amountNumber / 12
            break
        case INCOME_INTERVAL.monthly.value:
            monthlyNumber = amountNumber
            break
        case INCOME_INTERVAL.biweekly.value:
            monthlyNumber = (amountNumber * 26) / 12
            break
        case INCOME_INTERVAL.semiMonthly.value:
            monthlyNumber = amountNumber * 2
            break
        case INCOME_INTERVAL.weekly.value:
            monthlyNumber = (amountNumber * 52) / 12
            break
        default:
            monthlyNumber = NaN
            break
    }

    return monthlyNumber.toFixed(0)
}

export default function createPostApplicationDTO(
    values: FormValues,
    isCopy = false,
    parentId = 0,
    sourceClient: SourceClient = SourceClient.OCTANE_EXTENSION,
    sourceSubclient: SourceSubclient | undefined = undefined,
    disclosures: Disclosure[] | undefined = undefined,
): SubmitApplicationDTO {
    let otherIncomeFields = {}

    if (values[applicantFieldNames.hasOtherIncome]) {
        otherIncomeFields = {
            other_income_source: values[applicantFieldNames.otherIncomeSource],
            other_monthly_gross_income: calculateMonthlyGross(
                values[applicantFieldNames.otherIncomeGrossAmount],
                values[applicantFieldNames.otherIncomeGrossInterval],
            ),
            other_income_gross_amount:
                values[applicantFieldNames.otherIncomeGrossAmount],
            other_income_gross_interval:
                values[applicantFieldNames.otherIncomeGrossInterval],
        }
    } else {
        otherIncomeFields = {
            other_income_source: '',
            other_monthly_gross_income: null,
            other_income_gross_amount: null,
            other_income_gross_interval: '',
        }
    }

    let dto: SubmitApplicationDTO = {
        source_client: sourceClient,
        source_subclient: sourceSubclient,
        source_action: isCopy
            ? SourceAction.COPY
            : SourceAction.FORM_SUBMISSION,
        group: values[hiddenFormValues.group],
        application_type: isApplicationJointFunc(values) ? 'J' : 'I',
        finance_manager: values[otherFieldNames.financeManager],
        first_name: values[applicantFieldNames.firstName].toUpperCase(),
        middle_initial:
            values[applicantFieldNames.middleInitial]?.toUpperCase(),
        last_name: values[applicantFieldNames.lastName].toUpperCase(),
        suffix: values[applicantFieldNames.suffix],
        primary_address: values[applicantFieldNames.street],
        primary_apt: values[applicantFieldNames.apartmentSuite],
        primary_zip: values[applicantFieldNames.zip],
        primary_state: values[applicantFieldNames.state],
        primary_city: values[applicantFieldNames.city],
        citizenship_status: values[applicantFieldNames.citizenshipStatus],
        social_security_number: values[applicantFieldNames.ssn],
        dob: values[applicantFieldNames.dateOfBirth].replace(/\s/g, ''),
        residential_status: values[applicantFieldNames.residenceStatus],
        home_phone: values[applicantFieldNames.homePhone],
        mobile_phone: values[applicantFieldNames.mobilePhone],

        // fallback to mobilePhone if no value provided
        work_phone:
            values[applicantFieldNames.workPhone] ||
            values[applicantFieldNames.mobilePhone],

        residential_years: values[applicantFieldNames.residentialYears],
        residential_months: values[applicantFieldNames.residentialMonths],
        monthly_housing_payment:
            values[applicantFieldNames.monthlyHousingPayment],

        // fallback to employmentStatus if no value provided
        job_title:
            values[applicantFieldNames.jobTitle] ||
            values[applicantFieldNames.employmentStatus],

        employment_status: values[applicantFieldNames.employmentStatus],
        employer_name: values[applicantFieldNames.employerName],
        employer_years: values[applicantFieldNames.employerYears],
        employer_months: values[applicantFieldNames.employerMonths],
        employer_monthly_gross: calculateMonthlyGross(
            values[applicantFieldNames.employerGrossAmount],
            values[applicantFieldNames.employerGrossInterval],
        ),
        employer_gross_amount: values[applicantFieldNames.employerGrossAmount],
        employer_gross_interval:
            values[applicantFieldNames.employerGrossInterval],
        ...otherIncomeFields,
        email_address: values[applicantFieldNames.email],
        trade_in_intent: values[tradeInFieldNames.intent],
    }

    if (isCopy && parentId !== 0) {
        dto = {
            ...dto,
            parent: parentId,
        }
    }

    if (disclosures) {
        const matchedDisclosures = disclosures.filter(
            (item) =>
                item.name === values.consent.disclosure ||
                item.name === values.customerVerification.disclosure,
        )

        if (matchedDisclosures.length > 0) {
            dto = {
                ...dto,
                disclosures: matchedDisclosures.map((matchedDisclosure) => ({
                    disclosure_id: matchedDisclosure.id,
                    checked:
                        matchedDisclosure.name === values.consent.disclosure
                            ? values.consent.checked
                            : values.customerVerification.checked,
                })),
            }
        }
    }

    if (!isApplicationJointFunc(values)) {
        return dto
    }

    let coapplicantResidentialData = {
        coapplicant_primary_address: values[coapplicantFieldNames.street],
        coapplicant_primary_apt: values[coapplicantFieldNames.apartmentSuite],
        coapplicant_primary_zip: values[coapplicantFieldNames.zip],
        coapplicant_primary_state: values[coapplicantFieldNames.state],
        coapplicant_primary_city: values[coapplicantFieldNames.city],
    }

    // use same values as primary if address is the same
    if (isCoapplicantAddressSameFunc(values)) {
        coapplicantResidentialData = {
            coapplicant_primary_address: values[applicantFieldNames.street],
            coapplicant_primary_apt: values[applicantFieldNames.apartmentSuite],
            coapplicant_primary_zip: values[applicantFieldNames.zip],
            coapplicant_primary_state: values[applicantFieldNames.state],
            coapplicant_primary_city: values[applicantFieldNames.city],
        }
    }

    let coapplicantOtherIncomeFields = {}

    if (values[coapplicantFieldNames.hasOtherIncome]) {
        coapplicantOtherIncomeFields = {
            coapplicant_other_income_source:
                values[coapplicantFieldNames.otherIncomeSource],
            coapplicant_other_monthly_gross_income: calculateMonthlyGross(
                values[coapplicantFieldNames.otherIncomeGrossAmount],
                values[coapplicantFieldNames.otherIncomeGrossInterval],
            ),
            coapplicant_other_income_gross_amount:
                values[coapplicantFieldNames.otherIncomeGrossAmount],
            coapplicant_other_income_gross_interval:
                values[coapplicantFieldNames.otherIncomeGrossInterval],
        }
    } else {
        coapplicantOtherIncomeFields = {
            coapplicant_other_income_source: '',
            coapplicant_other_monthly_gross_income: null,
            coapplicant_other_income_gross_amount: null,
            coapplicant_other_income_gross_interval: '',
        }
    }

    return {
        ...dto,
        ...coapplicantResidentialData,
        coapplicant_first_name:
            values[coapplicantFieldNames.firstName].toUpperCase(),
        coapplicant_middle_initial:
            values[coapplicantFieldNames.middleInitial]?.toUpperCase(),
        coapplicant_last_name:
            values[coapplicantFieldNames.lastName].toUpperCase(),
        coapplicant_suffix: values[coapplicantFieldNames.suffix],
        coapplicant_citizenship_status:
            values[coapplicantFieldNames.citizenshipStatus],
        coapplicant_social_security_number: values[coapplicantFieldNames.ssn],
        coapplicant_dob: values[coapplicantFieldNames.dateOfBirth].replace(
            /\s/g,
            '',
        ),
        coapplicant_relationship: values[otherFieldNames.relationOfCoapplicant],
        coapplicant_residential_status:
            values[coapplicantFieldNames.residenceStatus],
        coapplicant_home_phone: values[coapplicantFieldNames.homePhone],
        coapplicant_mobile_phone: values[coapplicantFieldNames.mobilePhone],

        // fallback to mobilePhone if no value provided
        coapplicant_work_phone:
            values[coapplicantFieldNames.workPhone] ||
            values[coapplicantFieldNames.mobilePhone],

        coapplicant_residential_years:
            values[coapplicantFieldNames.residentialYears],
        coapplicant_residential_months:
            values[coapplicantFieldNames.residentialMonths],
        coapplicant_monthly_housing_payment:
            values[coapplicantFieldNames.monthlyHousingPayment],

        // fallback to employmentStatus if no value provided
        coapplicant_job_title:
            values[coapplicantFieldNames.jobTitle] ||
            values[coapplicantFieldNames.employmentStatus],

        coapplicant_employment_status:
            values[coapplicantFieldNames.employmentStatus],
        coapplicant_employer_name: values[coapplicantFieldNames.employerName],
        coapplicant_employer_years: values[coapplicantFieldNames.employerYears],
        coapplicant_employer_months:
            values[coapplicantFieldNames.employerMonths],
        coapplicant_employer_monthly_gross: calculateMonthlyGross(
            values[coapplicantFieldNames.employerGrossAmount],
            values[coapplicantFieldNames.employerGrossInterval],
        ),
        coapplicant_employer_gross_amount:
            values[coapplicantFieldNames.employerGrossAmount],
        coapplicant_employer_gross_interval:
            values[coapplicantFieldNames.employerGrossInterval],
        ...coapplicantOtherIncomeFields,
        coapplicant_email_address: values[coapplicantFieldNames.email],
    }
}

export function createPostApplicationDTOFromClientApplication(
    application: ClientApplication,
): SubmitApplicationDTO {
    /* eslint-disable camelcase,@typescript-eslint/no-unused-vars */
    const {
        id,
        lender_name,
        progress,
        status,
        uuid,
        source_action,
        parent,
        ...postApplication
    } = application

    return postApplication as unknown as SubmitApplicationDTO
}

export const testExports = {
    calculateMonthlyGross,
}
