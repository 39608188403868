import React, { useState } from 'react'

import { Button, Popover, FmInformationIcon, UiLoanIcon } from '@octane/spark'
import { MfaVerificationModal } from 'components/MfaModal'
import { User } from 'state/user/UserState'

import { ButtonWrapper, PopOverText } from './SafeCheckComponent.styled'

type Props = {
    user: User | undefined
    hasSafeCheckReport: boolean
    safeCheckReportUrl: string
    buttonText: string
    disabledButtonText: string
    requireMFA: boolean
    onReportOpen: () => void
}

const openSafeCheckReport = (safeCheckReportUrl: string) => {
    window.open(safeCheckReportUrl, '_blank')
}

function SafeCheckReportButton({
    user,
    hasSafeCheckReport,
    safeCheckReportUrl,
    buttonText,
    disabledButtonText,
    requireMFA,
    onReportOpen,
}: Props) {
    const popOverMessage =
        // eslint-disable-next-line max-len
        'A credit report is not available because no SafeCheck soft inquiries have been made on this application'

    const [isMfaVerificationModalOpen, setIsMfaVerificationModalOpen] =
        useState(false)

    return (
        <div>
            {!hasSafeCheckReport ? (
                <ButtonWrapper>
                    <Button variant="secondary" size="tiny" disabled>
                        {disabledButtonText}
                    </Button>
                    <Popover
                        id="safeCheckInformation"
                        size="large"
                        placement="top"
                        rounded
                        trigger={
                            <FmInformationIcon
                                data-testid="popover-trigger"
                                color=""
                                height={20}
                                width={20}
                            />
                        }
                    >
                        <PopOverText>{popOverMessage}</PopOverText>
                    </Popover>
                </ButtonWrapper>
            ) : (
                <Button
                    variant="secondary"
                    size="tiny"
                    onClick={() => {
                        if (!requireMFA) {
                            onReportOpen()
                            openSafeCheckReport(safeCheckReportUrl)
                        } else {
                            setIsMfaVerificationModalOpen(true)
                        }
                    }}
                >
                    <UiLoanIcon />
                    {buttonText}
                    {requireMFA && (
                        <MfaVerificationModal
                            isOpen={isMfaVerificationModalOpen}
                            handleClose={() => {
                                setIsMfaVerificationModalOpen(false)
                            }}
                            handleCallback={() => {
                                onReportOpen()
                                openSafeCheckReport(safeCheckReportUrl)
                            }}
                            user={user}
                        />
                    )}
                </Button>
            )}
        </div>
    )
}

export default SafeCheckReportButton
