import React from 'react'
import { useNavigate } from 'react-router'

import { CepExpiredIcon } from '@octane/spark'
import Card from 'components/Card'
import { LinkButton } from 'components/UserForm/UserForm.styled'

import { FullContainer, Text, Centered } from '../Invitation.styled'

export type formProps = {
    verificationCode: string
}

export default function ExpiredToken(): JSX.Element {
    const navigate = useNavigate()

    return (
        <FullContainer>
            <Card>
                <Centered>
                    <CepExpiredIcon width={100} height={100} />
                    <h1>Your invitation is invalid</h1>
                    <Text>
                        Invitations are only good for 30 days. Reach out to your
                        admin to get a new invitation sent.
                    </Text>
                    <LinkButton
                        onClick={() => navigate('/')}
                        variant="white"
                        fullWidth
                    >
                        Ok, got it
                    </LinkButton>
                </Centered>
            </Card>
        </FullContainer>
    )
}
