import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function toggleNotification(
    name: string,
    status: boolean,
) {
    const action = !status ? 'subscribe' : 'unsubscribe'

    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/notifications/${action}/`,
            {
                notification: name,
            },
            {
                withCredentials: true,
            },
        )

        return data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to toggle notification: ${axiosError.message}`,
            axiosError,
            { action },
        )

        if (error?.response?.data?.error) {
            throw new Error(error.response.data.error)
        }

        throw new Error(axiosError.message)
    }
}
