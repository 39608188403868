import axios, { AxiosError } from 'axios'
import { UserPermissions } from 'components/UserPermissionsModal/types'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function editUserPermissions(
    userId: number,
    userPermissions: UserPermissions,
) {
    try {
        if (userPermissions.levelOfAccess === 'Sales') {
            const { data } = await axios.put(
                `${config.API_BASE_URL}/api/v3/salesperson/${userId}/`,
                {
                    email: userPermissions.emailAddress,
                    first_name: userPermissions.firstName,
                    last_name: userPermissions.lastName,
                    dealer_phone: userPermissions.phoneNumber,
                },
                {
                    withCredentials: true,
                },
            )

            return data
        }

        const { data } = await axios.patch(
            `${config.API_BASE_URL}/api/v3/users/${userId}/`,
            {
                application_access: userPermissions.applicationAccess,
                level_of_access: userPermissions.levelOfAccess,
            },
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to edit user permissions: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
