import React from 'react'

import { CepEmptyStateGenericIcon } from '@octane/spark'

import {
    ContainerStyle,
    DetailsStyle,
    EmptyPanel,
    SummaryStyle,
} from './EmptyCard.styled'

function EmptyCard() {
    return (
        <ContainerStyle>
            <EmptyPanel>
                <CepEmptyStateGenericIcon width={100} height={100} />
            </EmptyPanel>
            <SummaryStyle>There are no leads to view.</SummaryStyle>
            <DetailsStyle>
                You can refresh the page or come back later.
            </DetailsStyle>
        </ContainerStyle>
    )
}

export default EmptyCard
