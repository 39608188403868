import styled from 'styled-components'

import { typography } from '../../assets/styles'

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > :nth-child(2) {
        ${typography.Heading24Bold};
        font-size: 30px;
    }

    > :nth-child(3) {
        ${typography.BodyCopy16};
    }
`

export const Spinner = styled.div`
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`
