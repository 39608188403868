import styled from 'styled-components'

export const Container = styled.div`
    padding-top: 20px;
    padding-bottom: 40px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    min-width: 980px;
    max-width: 1305px;
    min-height: 600px;
`

export const WrapperLoader = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
`

export const SectionPrequal = styled.div`
    padding: 0 25px;
    margin-bottom: 35px;
    background-color: ${({ theme }) => theme.colors.monochrome.white};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    display: flex;
    align-items: stretch;

    .column {
        width: 50%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .separator {
        width: 1px;
        height: auto;
        margin-left: 20px;
        margin-right: 40px;
        flex: 1 0 auto;
        border-left: 1px solid
            ${({ theme }) => theme.colors.monochrome.concrete};
    }
`

export const Title = styled.h1`
    display: block;
    ${({ theme }) => theme.fonts.Montserrat['s24-ln30-fw800']};
    color: ${({ theme }) => theme.colors.monochrome.jetlineBlack};
    margin-bottom: 5px;
    margin-top: 0;
`

export const RowSubtitle = styled.div`
    display: flex;
    align-items: center;

    button {
        flex-shrink: 0;
    }
`

export const Subtitle = styled.span`
    display: inline-block;
    ${({ theme }) => theme.fonts.Karla['s20-ln28-fw400']};
    color: ${({ theme }) => theme.colors.monochrome.ravenSub};
    margin-right: 15px;
    flex-shrink: 1;
`

export const InputTitle = styled.span`
    display: block;
    ${({ theme }) => theme.fonts.Karla['s14-ln16-fw800']};
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.brand.rideOctaneBlue};
`

export const TabContainer = styled.span`
    display: inline-block;
    background-color: '#f5f5f5';
    padding: '10px 5px 0px 5px';
    float: 'left';
    align-items: center;
    width: 20%;
`

export const RowInput = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 10px;

    #flex-link-input {
        min-width: 280px;
    }

    @media (max-width: 1110px) {
        #flex-link-input {
            min-width: auto;
            flex-grow: 1;
        }

        button {
            width: 190px;
        }
    }
`

export const SectionNews = styled.div<{
    isRVDealer: boolean
}>`
    border: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    background-color: ${({ theme }) => theme.colors.monochrome.white};
    border-radius: 5px;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;

    iframe {
        min-height: 520px;
        width: 100%;
        border: 0;
        overflow: hidden;

        ${({ isRVDealer }) => isRVDealer && `min-height: 560px;`}
    }
`
