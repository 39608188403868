import { FieldInstruction } from 'containers/FormBuilder'
import VehicleSelectionBaseField, {
    VehicleSelectionBaseFieldConfig,
} from 'form/fieldComponents/VehicleSelectionBaseField'
import {
    FIELD_GREATER_THAN_ZERO,
    FIELD_REQUIRED,
    FIELD_TOO_LONG,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

const usedSharedInformation = {
    mileage: Yup.number()
        .required(FIELD_REQUIRED)
        .moreThan(0, FIELD_GREATER_THAN_ZERO),
    noTitleReason: Yup.string().when('hasTitle', {
        is: false,
        then: Yup.string().required(FIELD_REQUIRED),
    }),
    noTitleOtherReason: Yup.string().when('noTitleReason', {
        is: 'other',
        then: Yup.string()
            .required(FIELD_REQUIRED)
            .nullable()
            .max(500, FIELD_TOO_LONG(500)),
    }),
}

const newTrailerVehicleInformation = {
    trailerValue: Yup.number().required(FIELD_REQUIRED),
    trailerModel: Yup.string().required(FIELD_REQUIRED),
    trailerWeight: Yup.number().required(FIELD_REQUIRED),
}

const usedTrailerVehicleInformation = {
    ...usedSharedInformation,
    ...newTrailerVehicleInformation,
}

const newVehicleInformation = {
    year: Yup.string().required(FIELD_REQUIRED),
    modelId: Yup.string().required(FIELD_REQUIRED),
    selectedVehicleId: Yup.string().required(FIELD_REQUIRED),
}

const usedVehicleInformation = {
    ...newVehicleInformation,
    ...usedSharedInformation,
}

const vehicleSchema = Yup.object().when('condition', {
    is: 'used',
    then: Yup.object().when('isTrailer', {
        is: true,
        then: Yup.object().shape(usedTrailerVehicleInformation),
        otherwise: Yup.object().shape(usedVehicleInformation),
    }),
    otherwise: Yup.object().when('isTrailer', {
        is: true,
        then: Yup.object().shape(newTrailerVehicleInformation),
        otherwise: Yup.object().shape(newVehicleInformation),
    }),
})

export const defaultConfig: VehicleSelectionBaseFieldConfig = {
    assetType: 'powersports_vehicle',
    vehicleTypeList: [],
    initialValue: {
        condition: 'new',
        multiUnit: false,
        isTrailer: false,
        primary: {
            year: '',
            modelId: '',
            selectedVehicleId: '',
            mileage: '',
            hasTitle: false,
            noTitleOtherReason: '',
            noTitleReason: '',
        },
        secondary: {
            year: '',
            modelId: '',
            selectedVehicleId: '',
            mileage: '',
            hasTitle: false,
            noTitleOtherReason: '',
            noTitleReason: '',
        },
        makeId: '',
        vehicleType: '',
    },
    validator: Yup.object().shape({
        condition: Yup.string().required(FIELD_REQUIRED),
        makeId: Yup.string().required(FIELD_REQUIRED),
        primary: vehicleSchema,
        secondary: Yup.object().when('multiUnit', {
            is: true,
            then: vehicleSchema,
        }),
    }),
}

export const vehicleSelectionAutomobile: FieldInstruction<
    typeof VehicleSelectionBaseField
> = {
    Component: VehicleSelectionBaseField,
    config: {
        ...defaultConfig,
        assetType: 'automobile',
    },
}

export const vehicleSelectionPowersports: FieldInstruction<
    typeof VehicleSelectionBaseField
> = {
    Component: VehicleSelectionBaseField,
    config: {
        ...defaultConfig,
        assetType: 'powersports_vehicle',
    },
}
