import { Button } from '@octane/spark'
import { typography } from 'assets/styles'
import Card from 'components/Card'
import styled from 'styled-components'

export const NewLinkButton = styled(Button)`
    margin-bottom: 0.5em;
`

export const FlexLinksCard = styled(Card)`
    margin-right: 80px;
    padding: 20px;
`

export const LinksCountBox = styled.div`
    ${typography.BodyCopy16};
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 69px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid
        var(
            --monochrome-concrete-rule-line-theme-colors-monochrome-concrete,
            #d5d9e0
        );
    background: var(--Content-Color, #fff);
    text-align: center;

    span:first-child {
        font-size: 24px;
    }

    span:last-child {
        font-size: 14px;
    }
`

export const LoadingWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2em;
`
