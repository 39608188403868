import React from 'react'

import { NoticeBox, NoticeBoxColors, VsExclamationIcon } from '@octane/spark'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    margin-bottom: 20px;
    > div {
        align-items: center;
    }
`

export default function RoadrunnerDealershipAgreementNotice() {
    return (
        <Container>
            <NoticeBox
                icon={VsExclamationIcon}
                color={NoticeBoxColors.ERROR}
                content={
                    <>
                        In order to proceed with submitting a credit
                        application, the dealership must have an electronically
                        signed dealer agreement with Roadrunner Financial
                    </>
                }
            />
        </Container>
    )
}
