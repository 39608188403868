import { theme as BaseTheme } from '@octane/spark'
import { DefaultTheme } from 'styled-components'

const goodSamTheme: DefaultTheme = {
    ...BaseTheme,
    colors: {
        ...BaseTheme.colors,
        brand: {
            ...BaseTheme.colors.brand,
            rideOctaneBlue: '#23410a',
        },
        monochrome: {
            ...BaseTheme.colors.monochrome,
            moonGrey: '#f8f8fa',
        },
    },
    navbar: {
        background: '#23410a',
        color: BaseTheme.colors.monochrome.moonGrey,
        hoverColor: BaseTheme.colors.monochrome.moonGrey,
        loggedInNameColor: BaseTheme.colors.monochrome.moonGrey,
        loggedInHoverBackground: '#1c3607',
    },
}

export default goodSamTheme
