import React from 'react'

import { Tab } from '@octane/spark'
import { ErrorMessage } from 'components/FormikFields/FormikDropdown/FormikDropdown.styled'
import { TabItem } from 'components/FormikFields/FormikTabSelect/FormikTabSelect'
import { FieldConfigBase, FieldProps } from 'containers/FormBuilder/index'

interface TabSelectFieldConfig extends FieldConfigBase {
    label: string
    items: TabItem[]
    info?: JSX.Element
    prefilled?: boolean
}

export default function TabSelectField(
    props: FieldProps<TabSelectFieldConfig>,
) {
    const { fieldConfig, fieldKit, fieldName } = props
    const touched = fieldKit.touched[fieldName]
    const error = fieldKit.errors[fieldName]
    const value = fieldKit.values[fieldName]

    const handleChange = (newValue: string) => {
        const event = {
            target: {
                name: fieldName,
                value: newValue,
            },
        }

        fieldKit.setFieldTouched(fieldName, true)
        fieldKit.handleChange(event)
    }

    return (
        <div>
            <Tab
                id={fieldName}
                testID={fieldName}
                prefilled={fieldConfig.prefilled}
                value={value}
                items={fieldConfig.items}
                label={fieldConfig.label}
                info={fieldConfig.info}
                onChange={handleChange}
            />

            {/**
             * Workaround in order to allow the value to be changed
             * externally by an extension (e.g. Revvable.com).
             */}
            <input
                hidden
                name={fieldName}
                value={value}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event.target.value)
                }}
            />

            {touched && error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
    )
}
