/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import LoadingStatus from 'state/enums/LoadingStatus'

import IframeState from './IframeState'

const initialState = {
    status: LoadingStatus.LOADING,
} as IframeState

const iframeSlice = createSlice({
    name: 'iframe',
    initialState,
    reducers: {
        setIsLoaded: (state) => {
            state.status = LoadingStatus.LOADED
        },
    },
    extraReducers: {},
})

export default iframeSlice
