import { FieldInstruction, FormValues } from 'containers/FormBuilder'
import SsnInputField from 'form/fieldComponents/SsnInputField'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import * as Yup from 'yup'

const ssnValidator = Yup.string()
    .required(FIELD_REQUIRED)
    .min(11, 'SSN needs to be 9 digits exactly.')
    .max(11, 'SSN needs to be 9 digits exactly.')
    .matches(
        /^[0-8]/,
        'We do not allow ITINs (Individual Taxpayer Identification Numbers).',
    )

export const ssn: FieldInstruction<typeof SsnInputField> = {
    Component: SsnInputField,
    config: {
        label: 'SSN',
        placeholder: 'xxx-xx-xxxx',
        validator: ssnValidator,
    },
}

export function createCoapplicantSsnValidator(
    applicantSsnFieldName: string,
    coapplicantSsnFieldName: string,
): (formValues: FormValues) => Yup.StringSchema {
    return (formValues) => {
        return ssnValidator.test(
            'coapplicantSsn',
            "SSN can't be the same for both applicants.",
            () => {
                const ssnMatch =
                    formValues[applicantSsnFieldName] ===
                    formValues[coapplicantSsnFieldName]

                return !ssnMatch
            },
        )
    }
}
