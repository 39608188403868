import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, TextInput } from '@octane/spark'
import { changePassword } from 'api/user'
import { AxiosError } from 'axios'
import useUser from 'hooks/useUser'
import { decodeBase64ToNumber } from 'utils/base64'
import * as toast from 'utils/toast'

import { Field } from './ChangePassword.styled'
import { ChangePasswordFields } from './ChangePasswordFields'

export interface ChangePasswordError {
    user_password: string[]
    new_password: string[]
}

interface Props {
    onSuccess: () => void
}

export default function ChangePassword({ onSuccess }: Props): JSX.Element {
    const { user } = useUser()
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const [currentPasswordError, setCurrentPasswordError] = useState('')
    const [isResetPassword, setIsResetPassword] = useState(false)
    const [resetToken, setResetToken] = useState<string | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const { token, dealer } = useParams()

    useEffect(() => {
        if (token) {
            setIsResetPassword(true)
            setResetToken(token)
        }
    }, [token])

    function clear() {
        setNewPasswordError('')
        setNewPassword('')
        setCurrentPasswordError('')
        setCurrentPassword('')
    }

    function requestChangePassword() {
        let userId = user?.id

        if (isResetPassword && dealer) userId = decodeBase64ToNumber(dealer)

        if (userId) {
            setIsLoading(true)

            changePassword({
                userId,
                userPassword: currentPassword,
                newPassword,
                resetPasswordToken: resetToken,
            })
                .then(({ detail }) => {
                    toast.success({ message: detail || '' })
                    clear()
                    onSuccess()
                })
                .catch((error: AxiosError<ChangePasswordError>) => {
                    setNewPasswordError('')
                    setCurrentPasswordError('')
                    const data = error.response?.data

                    if (data?.user_password)
                        setCurrentPasswordError(data.user_password?.join(' '))
                    else if (data?.new_password)
                        setNewPasswordError(data.new_password?.join(' '))
                    else toast.error({ message: error.message || '' })
                })
                .finally(() => setIsLoading(false))
        }
    }

    function isButtonDisabled() {
        const itsAllEmpty = !newPassword || !currentPassword
        const isJustChangingAndItsAllEmpty = !isResetPassword && itsAllEmpty
        const isResettingAndNewPasswordIsEmpty = isResetPassword && !newPassword

        return isJustChangingAndItsAllEmpty || isResettingAndNewPasswordIsEmpty
    }

    function clearPasswordErrors() {
        setNewPasswordError('')
        setCurrentPasswordError('')
    }

    function handleChangePassword(value: string) {
        setCurrentPassword(value)
        clearPasswordErrors()
    }

    return (
        <div>
            {!isResetPassword && (
                <Field>
                    <TextInput
                        errorText={currentPasswordError}
                        error={!!currentPasswordError}
                        label="Current Password"
                        id="current-password"
                        data-testid="current-password"
                        value={currentPassword}
                        onChange={(value) => handleChangePassword(value)}
                        type="password"
                    />
                </Field>
            )}
            <ChangePasswordFields
                onChange={setNewPassword}
                newPasswordError={newPasswordError}
                onClearErrors={() => clearPasswordErrors()}
            />
            <Button
                fullWidth
                onClick={() => requestChangePassword()}
                disabled={isButtonDisabled()}
                loading={isLoading}
                id="button-save-password"
            >
                {isResetPassword ? 'Reset My Password' : 'Change My Password'}
            </Button>
        </div>
    )
}
