import { UserInvitation } from 'components/UserInvitationModal/types'
import LoadingStatus from 'state/enums/LoadingStatus'

export interface InvitationDataType {
    firstName: string
    dealershipName: string
    dealershipId?: number
    email: string
}

export enum InvitationStep {
    VALIDATION,
    ACCEPTANCE,
    LISTING,
}

interface InvitationState {
    data: InvitationDataType
    step: InvitationStep
    invitationList?: UserInvitation[]
    status?: LoadingStatus
    message?: string
    error?: string
}

export default InvitationState
