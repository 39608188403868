import React from 'react'

import brandConfig from 'branding'
import useUser from 'hooks/useUser'

import { NavbarLink } from './Navbar.styled'

export default function BrandLogo(): JSX.Element {
    const { isAutoDealership } = useUser()

    return (
        <NavbarLink href={isAutoDealership ? '#/dashboard' : '#'}>
            <img src={brandConfig.navbarLogo} alt="Logotype" />
        </NavbarLink>
    )
}
