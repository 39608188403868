/* eslint-disable max-len */
import { SecuritySettingsType } from './types'

export const minutesToDays = (value: string) => {
    return parseInt(value, 10) / 1440
}

export const renderTimeLabel = (value: string, unit?: 'days' | 'minutes') => {
    if (unit === 'days') {
        return `${value} days`
    }

    const minutesInt = parseInt(value, 10)

    switch (minutesInt) {
        case 15:
            return '15 minutes'
        case 30:
            return '30 minutes'
        case 60:
            return '1 hour'
        case 120:
            return '2 hours'
        case 720:
            return '12 hours'
        case 1440:
            return '1 day'
        case 10080:
            return '1 week'
        default:
            return `${minutesToDays(value)} days`
    }
}

export const buildSecuritySettingsUpdateToastMessage = (
    key: keyof SecuritySettingsType,
    oldValue: SecuritySettingsType,
    newValue: SecuritySettingsType,
) => {
    switch (key) {
        case 'passwordRotation':
            return `Your password rotation was ${renderTimeLabel(
                oldValue[key],
                'days',
            )} and has been updated to ${renderTimeLabel(
                newValue[key],
                'days',
            )}.`
        case 'sessionInactivity':
            return `Your session inactivity was ${renderTimeLabel(
                oldValue[key],
            )} and has been updated to ${renderTimeLabel(newValue[key])}.`

        default:
            return ''
    }
}
