export function isRouteOneSSO(): boolean {
    if (sessionStorage.getItem('partner') === 'routeone') {
        return true
    }

    if (window.location.href.includes('/routeone')) {
        sessionStorage.setItem('partner', 'routeone')

        return true
    }

    return false
}
