import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export type AssignToPerson = {
    name: string
    email: string
    phoneNumber?: string
    salesPersonId?: string
    dealerUserId?: string
}

export default async function getAssignToPersons(): Promise<AssignToPerson[]> {
    try {
        const response = await axios.get(
            `${config.API_BASE_URL}/api/v3/dealer-assigned-links/list-dealer-users-salespersons/`,
            {
                withCredentials: true,
            },
        )

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return response.data.map((person: any) => {
            return {
                name: `${person.first_name} ${person.last_name}`,
                email: person.email,
                phoneNumber: person.dealer_phone,
                salesPersonId: person.salesperson_id,
                dealerUserId: person.dealer_user_id,
            }
        })
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to create new link: ${axiosError.message}`,
            axiosError,
        )

        throw axiosError
    }
}
