import React from 'react'

import { FormikTabSelect } from 'components/FormikFields'

import { setFieldValueAndTouched } from '../../../form/fields/utils/fieldUtils'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
}

export default function MotorTab({ formik }: Readonly<QueryProps>) {
    const motorItems = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
    ]

    function setValue(value: string): null {
        setFieldValueAndTouched(formik, 'marine.motors', parseInt(value, 10))

        return null
    }

    return (
        <FormikTabSelect
            name="marine.motors"
            label="How Many New Motors"
            items={motorItems}
            initialValue="new"
            onChange={(value) => setValue(value)}
        />
    )
}
