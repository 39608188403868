import { ApplicationSourceClient } from '../types'

export default function getLeadSourceClient(
    sourceClient: ApplicationSourceClient,
) {
    if (
        sourceClient === 'direct_to_consumer' ||
        sourceClient === 'direct_to_consumer_media_group_partner'
    )
        return 'Octane'

    if (sourceClient === 'direct_to_consumer_dealer_partner')
        return 'Dealer Website'

    if (sourceClient === 'direct_to_consumer_flex') return 'Prequal Flex'

    if (
        sourceClient === 'direct_to_consumer_partner' ||
        sourceClient === 'direct_to_consumer_rv_dealer_partner'
    )
        return 'OEM'

    if (sourceClient !== 'octane_extension') {
        // We send this to log(and consequently to datadog) to catch
        // faster as possible any unexpected source_clients that
        // might be sent to the leads page
        // eslint-disable-next-line no-console
        console.error(
            // eslint-disable-next-line max-len
            `The sourceClient ${sourceClient} was not expected on the Leads Page.`,
        )
    }

    return '-'
}
