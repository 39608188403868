import React from 'react'

import { useFormBuilderContext } from '../FormBuilder/FormBuilderContext'

interface FormProps {
    children: React.ReactNode | React.ReactNode[]
    autoComplete?: 'on' | 'off'
}

export default function Form(props: FormProps) {
    const context = useFormBuilderContext()
    const { children, autoComplete } = props
    const formKit = context?.formKit

    if (formKit === undefined) {
        throw Error(
            // eslint-disable-next-line max-len
            'Form component must receive the `formKit` through FormBuilder context',
        )
    }

    return (
        <form
            autoComplete={autoComplete}
            onSubmit={formKit.handleSubmit}
            aria-label="form"
        >
            {children}
        </form>
    )
}

Form.defaultProps = {
    autoComplete: 'on',
}
