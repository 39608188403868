/* eslint-disable max-len */
import React from 'react'

export function DownloadIcon() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="current"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 1.5C12.9142 1.5 13.25 1.83579 13.25 2.25V14.9393L15.9697 12.2197C16.2626 11.9268 16.7374 11.9268 17.0303 12.2197C17.3232 12.5126 17.3232 12.9874 17.0303 13.2803L13.0303 17.2803C12.7374 17.5732 12.2626 17.5732 11.9697 17.2803L7.96967 13.2803C7.67678 12.9874 7.67678 12.5126 7.96967 12.2197C8.26256 11.9268 8.73744 11.9268 9.03033 12.2197L11.75 14.9393V2.25C11.75 1.83579 12.0858 1.5 12.5 1.5ZM3 16.75C3 16.3358 2.66421 16 2.25 16C1.83579 16 1.5 16.3358 1.5 16.75C1.5 20.4779 4.52208 23.5 8.25 23.5H16.75C20.4779 23.5 23.5 20.4779 23.5 16.75C23.5 16.3358 23.1642 16 22.75 16C22.3358 16 22 16.3358 22 16.75C22 19.6495 19.6495 22 16.75 22H8.25C5.3505 22 3 19.6495 3 16.75Z"
                fill="current"
            />
        </svg>
    )
}
