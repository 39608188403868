import {
    ApplicationDataType,
    ActiveLoanType,
} from 'containers/ReturnCustomer/types/returnCustomerTypes'

import HashChangedMessages from './HashChangedMessages'
import IFrameServer from './IFrameServer'

export default class WindowNavigatorIFrameServer extends IFrameServer {
    hashChangedMessages: HashChangedMessages

    returnCustomerLoans: ActiveLoanType[] | undefined

    applicationData: ApplicationDataType | undefined

    constructor(target: Window, targetOrigin: string) {
        super('windowNavigator', target, targetOrigin)
        this.hashChangedMessages = new HashChangedMessages()
        this.returnCustomerLoans = undefined
        this.applicationData = undefined
    }

    setHash(hash: string) {
        const decodedHash = decodeURIComponent(hash)
        const decodedExistingHash = decodeURIComponent(window.location.hash)

        if (decodedExistingHash === decodedHash) {
            return false
        }

        window.location.hash = decodedHash

        /*
         * Hash was updated, add a sync message for client to check for.
         * We dont want to post back a message for these messages as
         * it may lead to an infinite loop.
         */
        this.hashChangedMessages.add(hash)

        return true
    }

    // eslint-disable-next-line class-methods-use-this
    sendReturnCustomerData(
        activeLoans: ActiveLoanType[],
        applicationData: ApplicationDataType,
    ) {
        this.returnCustomerLoans = activeLoans

        this.applicationData = applicationData
    }
}
