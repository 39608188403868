import {
    FieldComponent,
    FieldInstructionAny,
    FieldInstruction,
    FieldInstructionCreator,
    FormValues,
    FieldKit,
} from '.'

/**
 * Used to extend any FieldInstruction object or FieldInstructionCreator function
 * via a callback function.
 *
 * This function handles the complexity of accessing the FieldInstruction object
 * from within a FieldInstructionCreator function and allows the underlying
 * FieldInstruction object to be modified by a callback function.
 * @param fieldInstruction
 * @param extensionFunc
 * @returns A FieldInstructionCreator function
 */
export function extendFieldInstructionAny(
    fieldInstruction: FieldInstructionAny,
    extensionFunc: (
        fieldInstruction: FieldInstruction<FieldComponent>,
        formValues: FormValues,
        fieldKit: FieldKit,
    ) => FieldInstruction<FieldComponent>,
) {
    const newInstructionCreator: FieldInstructionCreator<FieldComponent> = (
        formValues,
        fieldKit,
    ) => {
        let innerInstruction = fieldInstruction

        if (typeof fieldInstruction === 'function') {
            innerInstruction = fieldInstruction(formValues, fieldKit)
        }

        return extensionFunc(
            innerInstruction as FieldInstruction<FieldComponent>,
            formValues,
            fieldKit,
        )
    }

    return newInstructionCreator
}
