import { FieldInstruction } from 'containers/FormBuilder'
import SelectField from 'form/fieldComponents/SelectField'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import * as Yup from 'yup'

export const relationOfCoapplicant: FieldInstruction<typeof SelectField> = {
    Component: SelectField,
    config: {
        label: 'Co-Applicant Relationship to Borrower',
        placeholder: 'Select',
        options: [
            { name: 'Sibling', value: 'Sibling' },
            { name: 'Spouse', value: 'Spouse' },
            { name: 'Parent', value: 'Parent' },
            { name: 'Resides with', value: 'Resides_with' },
            { name: 'Other', value: 'Other' },
        ],
        validator: Yup.string().required(FIELD_REQUIRED),
    },
}
