import { useLayoutEffect, useState, ReactNode } from 'react'
import { createPortal } from 'react-dom'

function createWrapperAndAppendToBody(wrapperId: string) {
    const wrapperElement = document.createElement('div')

    wrapperElement.setAttribute('id', wrapperId)
    document.body.appendChild(wrapperElement)

    return wrapperElement
}

export interface PortalProps {
    children?: ReactNode
    wrapperId?: string
}

export default function Portal({
    children,
    wrapperId = 'portal-wrapper',
}: PortalProps) {
    const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
        null,
    )

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId)
        let systemCreated = false

        if (!element) {
            systemCreated = true
            element = createWrapperAndAppendToBody(wrapperId)
        }

        setWrapperElement(element)

        return () => {
            if (systemCreated && element?.parentNode) {
                element.parentNode.removeChild(element)
            }
        }
    }, [wrapperId])

    if (wrapperElement === null) return null

    return createPortal(children, wrapperElement)
}

Portal.defaultProps = {
    children: null,
    wrapperId: 'portal-wrapper',
}
