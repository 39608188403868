import IFrameClient from './IFrameClient'

export default class AuthenticatorIFrameClient extends IFrameClient {
    constructor(target: Window, targetOrigin: string) {
        super('authenticator', target, targetOrigin)
    }

    logout() {
        this.sendRequest('logout', [])
    }

    authenticate(username: string, password: string) {
        this.sendRequest('authenticate', [username, password])
    }

    setLoggedIn() {
        this.sendRequest('setLoggedIn', [])
    }
}
