import React from 'react'
import { connect } from 'react-redux'

import { ping } from 'api/auth'
import config from 'config'
import LoadingStatus from 'state/enums/LoadingStatus'
import { setIsLoaded } from 'state/iframe'
import store, { RootState } from 'state/store'
import { logoutUser } from 'state/user/actions'

import './LegacyFrame.css'

import { isPublicRoute } from '../../utils/isPublicRoute'
import IFrameSync, { createIFrameSync } from './IFrameSync'

export type LegacyFrameProps = {
    url: string
    pathname: string
    setIsLoaded?: () => void
    iFrameStatus: LoadingStatus
}

type LegacyFrameState = { src: string }

function mapStateToProps(state: RootState) {
    return {
        iFrameStatus: state.iframe.status,
    }
}

const mapDispatchToProps = { setIsLoaded }

class LegacyFrame extends React.PureComponent<
    LegacyFrameProps,
    LegacyFrameState
> {
    ref = React.createRef<HTMLIFrameElement>()

    iFrameSync: IFrameSync | null = null

    initialPathname?: string

    // eslint-disable-next-line react/static-property-placement
    public static defaultProps = {
        setIsLoaded: () => null,
    }

    constructor(props: LegacyFrameProps) {
        super(props)

        this.initialPathname = props.pathname

        this.state = {
            src: '',
        }
    }

    componentDidMount() {
        if (!this.iFrameSync) {
            const { setIsLoaded: setIframeIsLoaded } = this.props

            this.iFrameSync = createIFrameSync(
                window,
                this.ref.current,
                config.LEGACY_APP_URL,
                setIframeIsLoaded as () => null,
            )

            this.setState({ src: this.getSourceUrl() })
            this.updateHash()
        }
    }

    async componentDidUpdate(prevProps: LegacyFrameProps) {
        const { pathname, iFrameStatus } = this.props

        const iFrameHasLoaded =
            prevProps.iFrameStatus === LoadingStatus.LOADING &&
            iFrameStatus === LoadingStatus.LOADED

        /* istanbul ignore else */
        if (pathname !== prevProps.pathname || iFrameHasLoaded) {
            // This is a hack until we implement the ping logic in the new web
            // to make sure that the user is still logged in.
            const sessionIsValid = await ping()

            if (sessionIsValid || isPublicRoute(pathname)) {
                this.updateHash()
            } else {
                await store.dispatch(
                    logoutUser({
                        skipLogoutLegacyApp: false,
                        skipRedirect: false,
                    }),
                )
            }
        }
    }

    getStyles() {
        const display = this.shouldShowFrame() ? 'block' : 'none'

        return { display }
    }

    getSourceUrl() {
        const { initialPathname } = this
        const { url } = this.props
        const queryParams = window.location.search

        if (this.shouldShowFrame()) {
            return `${url}/index.html${queryParams}#${initialPathname}`
        }

        return `${url}/index.html${queryParams}`
    }

    shouldShowFrame() {
        const { pathname } = this.props

        return pathname[0] === '!'
    }

    updateHash() {
        const { pathname } = this.props

        if (this.shouldShowFrame()) {
            /**
             * using setTimeout to put this task in the end
             * of Event Loop Stack to assure that the iframe is
             * fully loaded before emit a postMessage
             */
            setTimeout(() => this.iFrameSync?.setHash(`#${pathname}`), 0)
        }
    }

    render() {
        const { src } = this.state

        return (
            <iframe
                className="LegacyFrame"
                style={this.getStyles()}
                title="Legacy dealer app"
                src={src}
                ref={this.ref}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LegacyFrame)
