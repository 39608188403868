/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vehicle } from 'api/vehicles/getVehicles'

export interface PostCollateralAssetDTO {
    vehicle_uuid?: string | undefined
    title_information: {
        has_title: boolean
        no_title_reason?: string | null
        no_title_other_reason?: string | null
    } | null
    vehicle_type: string
    condition: string
    mileage?: any | null
    year: any
    make?: string | null
    model: string
    oem?: string | null
    msrp?: number | null
    low_retail?: number | null
    average_retail?: number | null
    low_trade?: number | null
    high_trade?: number | null
    average_wholesale?: number | null
    transmission?: any | null
    weight?: any | null
    stroke?: any | null
    cylinders?: any | null
    cc?: any | null
    did_find_matching_make?: null
    vehicle?: number | null
    asset_valuation_method?: string | null
}

export interface PostCollateralDTO {
    asset_type: string
    asset_value?: number | null
    asset: PostCollateralAssetDTO
}

export interface UsedInformation {
    mileage: string
    hasTitle: boolean
    noTitleReason: string
    noTitleOtherReason: string
}

export default function createPostCollateralDTO(
    vehicle: Vehicle,
    usedInformation: UsedInformation | null,
) {
    let titleInformation = null
    let mileage = null

    if (usedInformation) {
        titleInformation = {
            has_title: usedInformation.hasTitle ?? false,
            no_title_reason:
                usedInformation.noTitleReason !== ''
                    ? usedInformation.noTitleReason
                    : null,
            no_title_other_reason:
                usedInformation.noTitleOtherReason !== ''
                    ? usedInformation.noTitleOtherReason
                    : null,
        }

        mileage = usedInformation.mileage
    }

    return {
        asset_type: 'powersports_vehicle',
        asset: {
            vehicle_uuid: vehicle.uuid,
            title_information: titleInformation,
            vehicle_type: vehicle.vehicleType,
            condition: vehicle.condition,
            mileage,
            year: vehicle.year,
            make: vehicle.make,
            model: vehicle.model,
            oem: vehicle.oem,
            msrp: vehicle.msrp,
            low_retail: vehicle.lowRetail,
            average_retail: vehicle.averageRetail,
            low_trade: vehicle.lowTrade,
            high_trade: vehicle.highTrade,
            average_wholesale: vehicle.averageWholesale,
            transmission: vehicle.transmission,
            weight: vehicle.weight,
            stroke: vehicle.stroke,
            cylinders: vehicle.cylinders,
            cc: vehicle.cc,
            did_find_matching_make: null,
            vehicle: vehicle.id,
        },
    }
}
