import brandConfig from 'branding'
import styled from 'styled-components'

export const NavbarWrapper = styled.div<{ background?: string }>`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    margin-bottom: 20px;
    border-width: 0 0 1px;
    box-sizing: border-box;
    user-select: none;

    color: #f8f8fa !important;
    fill: #f8f8fa !important;
    background-color: ${({ background }) => background || '#f3f8fe'};

    @media print {
        display: none !important;
        box-shadow: none !important;
        border: none !important;
    }

    ${({ background }) =>
        (!background || brandConfig.theme.navbar.border) &&
        `
        background-image: linear-gradient(to bottom, #fff 0%, #f8f8f8 100%);
        background-repeat: repeat-x;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
            0 1px 5px rgba(0, 0, 0, 0.075);
        border: 1px solid transparent;
        border-color: #e7e7e7;
    `}
`

export const NavbarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 60px;
    padding: 12px 50px;
`

export const NavbarLink = styled.a`
    text-decoration: none;
`

export const NavbarLogo = styled.img`
    height: 40px;
`

export const NavbarNav = styled.ul`
    display: flex;
    align-items: center;
    max-height: 60px;
`
