import React, { useEffect, useState } from 'react'

import { VsNoteIcon } from '@octane/spark'
import { FormikDropdown, FormikTextInput } from 'components/FormikFields'
import { UserPermissions } from 'components/UserPermissionsModal/types'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import { useFormikContext } from 'formik'
import useUser from 'hooks/useUser'
import { DealershipUser } from 'state/dealership/DealershipState'
import { useTheme } from 'styled-components'
import FadeIn from 'utils/animations/FadeIn.styled'
import {
    applicationAccessOptions,
    levelOfAccessOptions,
} from 'utils/consts/securityOptions'

import { Row, AllDealershipChainWarning } from './UserForm.styled'

const LEVEL_OF_ACCESS_ADMIN = 'Admin'
const LEVEL_OF_ACCESS_SALES = 'Sales'
const APPLICATION_ACCESS_OWN_APPS = 'Default'
const APPLICATION_ACCESS_ALL_CHAIN_APPS = 'Administrator'

function ApplicationAccessWarning() {
    const theme = useTheme()

    return (
        <AllDealershipChainWarning data-testid="all-dealerships-warning">
            <figure>
                <VsNoteIcon
                    color={theme.colors.brand.rideOctaneBlue}
                    width={24}
                    height={24}
                />
            </figure>
            <p>
                <strong>Allowing dealership-level access?</strong> With this
                level of access, the user must set up Multifactor Authentication
                as an added security measure. Don’t worry, it’s quick and they
                will be prompted to set it up.
            </p>
        </AllDealershipChainWarning>
    )
}

type UserBasicInfoFormProps = {
    setLevelOfAccess?: (levelOfAccess: string) => void
    selectedUser?: DealershipUser
}

export default function UserBasicInfoForm({
    setLevelOfAccess,
    selectedUser,
}: Readonly<UserBasicInfoFormProps>) {
    const {
        values: { applicationAccess, levelOfAccess, emailAddress },
    } = useFormikContext<UserPermissions>()

    const [
        filteredApplicationAccessOptions,
        setFilteredApplicationAccessOptions,
    ] = useState(applicationAccessOptions)

    const [filteredLevelOfAccessOptions, setFilteredLevelOfAccessOptions] =
        useState(levelOfAccessOptions)

    const { user } = useUser()

    const isLevelOfAccessDisabled =
        (emailAddress === user?.email &&
            user?.applicationAccess === APPLICATION_ACCESS_ALL_CHAIN_APPS) ||
        selectedUser?.levelOfAccess === LEVEL_OF_ACCESS_SALES

    const featureFlags = useFeatureFlagContext()

    useEffect(() => {
        if (setLevelOfAccess) setLevelOfAccess(levelOfAccess)

        if (levelOfAccess === LEVEL_OF_ACCESS_ADMIN) {
            setFilteredApplicationAccessOptions(
                applicationAccessOptions.filter(
                    (i) =>
                        i.value !== APPLICATION_ACCESS_OWN_APPS &&
                        i.value !== LEVEL_OF_ACCESS_SALES,
                ),
            )
        } else if (levelOfAccess === LEVEL_OF_ACCESS_SALES) {
            setFilteredApplicationAccessOptions(
                applicationAccessOptions.filter(
                    (i) => i.value === LEVEL_OF_ACCESS_SALES,
                ),
            )
        } else {
            setFilteredApplicationAccessOptions(
                applicationAccessOptions.filter(
                    (i) => i.value !== LEVEL_OF_ACCESS_SALES,
                ),
            )
        }
    }, [levelOfAccess, setLevelOfAccess])

    useEffect(() => {
        if (
            (selectedUser &&
                selectedUser.levelOfAccess !== LEVEL_OF_ACCESS_SALES) ||
            !featureFlags?.salesPersonFlexLink
        ) {
            setFilteredLevelOfAccessOptions(
                levelOfAccessOptions.filter(
                    (i) => i.value !== LEVEL_OF_ACCESS_SALES,
                ),
            )
        }
    }, [selectedUser, featureFlags?.salesPersonFlexLink])

    const placeholder =
        levelOfAccess === LEVEL_OF_ACCESS_SALES
            ? 'No portal access'
            : 'Select Access'

    return (
        <>
            <Row>
                <FormikDropdown
                    name="levelOfAccess"
                    info="Admins can manage all users"
                    label="User Type"
                    placeholder="Select Level"
                    disabled={isLevelOfAccessDisabled}
                >
                    {filteredLevelOfAccessOptions}
                </FormikDropdown>
                <div>
                    <FormikDropdown
                        name="applicationAccess"
                        info="Allow users to access only certain apps"
                        label="Application Access"
                        placeholder={placeholder}
                        disabled={levelOfAccess === LEVEL_OF_ACCESS_SALES}
                    >
                        {filteredApplicationAccessOptions}
                    </FormikDropdown>

                    {applicationAccess ===
                        APPLICATION_ACCESS_ALL_CHAIN_APPS && (
                        <FadeIn delay={100} duration={350} />
                    )}
                </div>
            </Row>
            {applicationAccess === APPLICATION_ACCESS_ALL_CHAIN_APPS && (
                <Row columns={1}>
                    <FadeIn delay={100} duration={350}>
                        <ApplicationAccessWarning />
                    </FadeIn>
                </Row>
            )}
            <Row>
                <FormikTextInput name="firstName" label="First Name" />
                <FormikTextInput name="lastName" label="Last Name" />
            </Row>
            <Row>
                <FormikTextInput name="emailAddress" label="Email Address" />

                {levelOfAccess === LEVEL_OF_ACCESS_SALES && (
                    <FormikTextInput name="phoneNumber" label="Phone Number" />
                )}
            </Row>
        </>
    )
}

UserBasicInfoForm.defaultProps = {
    setLevelOfAccess: undefined,
    selectedUser: undefined,
}
