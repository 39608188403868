import axios, { AxiosError } from 'axios'

import config from '../../config'

async function logout() {
    try {
        await axios.post(
            `${config.API_BASE_URL}/api/v3/auth/sessions/logout/`,
            {
                withCredentials: true,
            },
        )
    } catch (error) {
        const axiosError = error as AxiosError

        if (axiosError.response?.status !== 401) {
            throw new Error(axiosError.message)
        }
    }
}

export default logout
