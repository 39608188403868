import { useEffect } from 'react'

import GoogleTagManager from 'integrations/googletagmanager/GoogleTagManager'

import useUser from './useUser'

const googleTagManager = new GoogleTagManager()

export default function useGoogleTagManager() {
    const { user } = useUser()

    useEffect(() => {
        if (user) {
            googleTagManager.init(user.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id])

    return googleTagManager
}
