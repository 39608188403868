import React, { useEffect, useState } from 'react'

import { AmountAlignText, Checkbox } from '@octane/spark'
import FormContent from 'components/FormContent'
import {
    FormikAmountField,
    FormikDropdown,
    FormikTextInput,
} from 'components/FormikFields'
import clearFormikFields from 'utils/clearFormikField'

import {
    Container,
    TitleSubmissionTimeLimit,
} from './VehicleUsedInformation.styled'

export const noTitleReasons = [
    {
        value: 'replacement_ordered',
        name: 'A replacement title has been ordered from the State',
    },
    {
        value: 'awaiting_delivery',
        name: 'Awaiting delivery from previous owner',
    },
    {
        value: 'never_titled',
        name: 'This vehicle was never titled (vehicle from UCC State)',
    },
    { value: 'other', name: 'Other' },
]

export const hasTitleText = 'Dealership has vehicle title'
export const titleProvideBeforeText =
    'Title must be provided within 30 days of funding'
export const explainMissingTitleText = 'Please explain missing title'
export const otherReasonTitleText = 'Please specify other reason'

export default function VehicleUsedInformation(props: {
    isPrimary: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    prefilled?: boolean
}): JSX.Element {
    const { formik, isPrimary, prefilled } = props
    const whatVehicle = isPrimary ? 'primary' : 'secondary'
    const { hasTitle } = formik.values.vehicle[whatVehicle]

    const [noTitleReason, setNoTitleReason] = useState<string>(
        formik.values.vehicle[whatVehicle].noTitleReason,
    )

    useEffect(
        () => {
            if (formik.values.vehicle[whatVehicle].hasTitle === true) {
                setNoTitleReason('')

                clearFormikFields(formik, [
                    `vehicle.${whatVehicle}.noTitleReason`,
                    `vehicle.${whatVehicle}.noTitleOtherReason`,
                ])
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.values.vehicle[whatVehicle].hasTitle],
    )

    useEffect(
        () => {
            if (formik.values.vehicle[whatVehicle].noTitleReason !== 'other') {
                clearFormikFields(formik, [
                    `vehicle.${whatVehicle}.noTitleOtherReason`,
                ])
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.values.vehicle[whatVehicle].noTitleReason],
    )

    return (
        <>
            <FormContent gridTemplate="2fr .96fr">
                <FormikAmountField
                    label="Mileage"
                    name={`vehicle.${whatVehicle}.mileage`}
                    placeholder=""
                    currencySymbol=""
                    omitDecimals
                    alignText={AmountAlignText.LEFT}
                    prefilled={prefilled}
                />
            </FormContent>
            <FormContent gridTemplate="1fr 2.20fr">
                <Checkbox
                    id={`vehicle.${whatVehicle}.hasTitle`}
                    value={`vehicle.${whatVehicle}.hasTitle`}
                    label={hasTitleText}
                    checked={hasTitle}
                    onChange={(e) => {
                        formik.setFieldValue(
                            `vehicle.${whatVehicle}.hasTitle`,
                            e,
                        )
                    }}
                    prefilled={prefilled}
                />
                {!hasTitle && (
                    <Container>
                        <TitleSubmissionTimeLimit>
                            {titleProvideBeforeText}
                        </TitleSubmissionTimeLimit>
                    </Container>
                )}
            </FormContent>
            <FormContent gridTemplate="2fr .96fr">
                {!hasTitle && (
                    <FormikDropdown
                        onChange={(e) => {
                            setNoTitleReason(e)
                        }}
                        label={explainMissingTitleText}
                        name={`vehicle.${whatVehicle}.noTitleReason`}
                        prefilled={prefilled}
                    >
                        {noTitleReasons}
                    </FormikDropdown>
                )}
            </FormContent>
            {noTitleReason === 'other' && !hasTitle && (
                <FormContent gridTemplate="2fr .96fr">
                    <FormikTextInput
                        label={otherReasonTitleText}
                        name={`vehicle.${whatVehicle}.noTitleOtherReason`}
                        prefilled={prefilled}
                    />
                </FormContent>
            )}
        </>
    )
}

const defaultProps = {
    prefilled: false,
}

VehicleUsedInformation.defaultProps = defaultProps
