import { useEffect, useState } from 'react'

import getCurrentSession, { Session } from 'api/auth/getCurrentSession'

const useSession = () => {
    const [session, setSession] = useState<Session>({
        mfaTimeLeft: 0,
        wasRecentlyAuthenticated: false,
    })

    useEffect(() => {
        getCurrentSession().then((currentSession: Session) => {
            setSession(currentSession)
        })
    }, [])

    return session
}

export default useSession
