import { FieldInstruction } from 'containers/FormBuilder'
import InputField from 'form/fieldComponents/InputField'
import { FIELD_TOO_LONG } from 'form/validation/validationMessages'
import * as Yup from 'yup'

export const middleInitial: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Middle Initial',
        validator: Yup.string()
            .max(1, FIELD_TOO_LONG(1))
            .matches(
                /^[A-Za-z]+$/,
                // eslint-disable-next-line max-len
                'This must be a letter',
            ),
        maxLength: 1,
    },
}
