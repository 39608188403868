import React from 'react'

import AcessInfo from './AcessInfo'
import GeneralInfo from './GeneralInfo'

export default function MyAccountHome(): JSX.Element {
    return (
        <div>
            <GeneralInfo />
            <AcessInfo />
        </div>
    )
}
