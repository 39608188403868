import React from 'react'

import { Avatar } from '@octane/spark'

import { Container } from './AssignedTo.styled'

interface Props {
    name: string
}

export default function AssignedTo({ name }: Props) {
    const displayName = name || 'Unassigned'

    return (
        <Container>
            {displayName !== 'Unassigned' && (
                <Avatar name={displayName} size="small" />
            )}
            <div>{displayName}</div>
        </Container>
    )
}
