import RequestInterface from './RequestInterface'

export default class IFrameServer {
    namespace: string

    targetOrigin: string

    constructor(namespace: string, target: Window, targetOrigin: string) {
        this.namespace = namespace
        this.targetOrigin = targetOrigin

        target.addEventListener('message', this.onRequest.bind(this))
    }

    static getAllowedMethods(): Array<string> {
        return [
            'setHash',
            'login',
            'logout',
            'setItem',
            'onLoad',
            'dispatchIsLoaded',
            'view',
            'closeModal',
            'sendReturnCustomerData',
        ]
    }

    onRequest({ data, origin }: { data: string; origin: string }) {
        let request: RequestInterface | undefined
        let testOrigin: string = origin

        if (process.env.TEST_ORIGIN) {
            testOrigin = process.env.TEST_ORIGIN as string
        }

        if (testOrigin !== this.targetOrigin) {
            return
        }

        try {
            request = JSON.parse(data)
        } catch (error) {} // eslint-disable-line no-empty

        if (!request) {
            return
        }

        const { namespace, method } = request

        if (namespace !== this.namespace) {
            return
        }

        if (!IFrameServer.getAllowedMethods().includes(method)) {
            return
        }

        if (request.parameters) {
            /* @ts-expect-error: because it resolves the method dynamically */
            this[method](...request.parameters)
        } else {
            /* @ts-expect-error: because it resolves the method dynamically */
            this[method]()
        }
    }
}
