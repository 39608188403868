export default class HashChangedMessages {
    items: string[] = []

    constructor() {
        this.items = []
    }

    getAll() {
        return this.items
    }

    add(value: string) {
        this.items?.push(value)
    }

    remove(value: string) {
        this.items = this.items?.filter((item: string) => {
            return item !== value
        })
    }

    exists(hash: string) {
        const hashMessages = this.getAll()

        return Boolean(hashMessages?.includes(hash))
    }
}
