import React from 'react'

import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'
import _ from 'lodash'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
}

export default function YearDropdown({ formik }: QueryProps) {
    const { marine } = formik.values
    const { year, condition } = marine
    const currentYear = new Date().getFullYear()

    const years =
        condition === 'N'
            ? _.range(currentYear, currentYear - 10, -1)
            : _.range(currentYear + 1, currentYear - 2, -1)

    const yearsDict = years.map((x) => ({ name: x, value: x }))

    return (
        <FormikDropdown
            name="marine.year"
            label="Year"
            placeholder="Select One"
            error={year === null || year === 0}
        >
            {yearsDict.map((child) => {
                const newBorn: FormikDropdownChild = {
                    name: child.name.toString(),
                    value: child.name.toString(),
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}
