import { DealershipInfo, User } from 'state/user/UserState'
import joinTextValues from 'utils/joinTextValues'

import Intercom from './Intercom'
import { IntercomSettings, ChatWindowState, IntercomCompany } from './types'

export default class IntercomChat {
    user: User | null

    config: { appId: string }

    unreadCount: number

    stateChangeTimeoutId: null

    isEnabled: boolean

    state: ChatWindowState

    intercom: Intercom

    constructor(config: { appId: string }) {
        this.user = null
        this.config = config
        this.unreadCount = 0
        this.stateChangeTimeoutId = null
        this.isEnabled = true
        this.state = 'closed'

        this.intercom = new Intercom(config?.appId)
    }

    onIsEnabledChanged(isEnabled: boolean) {
        if (!isEnabled) this.intercom.shutdown()

        this.intercom.initialize()
    }

    loadState() {
        const stateKey = this.getStateKey()
        const result = window.localStorage.getItem(stateKey)
        const state = result ? JSON.parse(result) : null

        this.state = state || 'closed'
    }

    setState(state: ChatWindowState) {
        this.state = state
        window.localStorage.setItem(this.getStateKey(), JSON.stringify(state))
    }

    static getSettings(user: User): IntercomSettings {
        const { dealership } = user

        return {
            user_id: String(user.id),
            user_hash: user.intercomHash,
            email: user.email,
            username: user.username,
            name: joinTextValues([user.firstName, user.lastName]),
            phone: user.phoneNumber,
            last_login: user.lastLogin,
            actual_number_of_end_users: user.actualNumberOfEndUsers,
            created_at: user.dateJoined,
            company: IntercomChat.getCompany(dealership) as IntercomCompany,
        }
    }

    static getCompany(dealership?: DealershipInfo) {
        if (!dealership) return null

        const { chain, principal } = dealership

        return {
            id: dealership.id,
            name: dealership.name,
            website: dealership.redirectUrl,
            type: dealership.type,
            on_watchlist: dealership.onWatchList,
            phone_number: dealership.phoneNumber,
            fax_number: dealership.faxNumber,
            zip_code: dealership.addressInfo?.zipCode,
            city: dealership.addressInfo?.city,
            state: dealership.addressInfo?.state,
            address: dealership.address,
            redirect_url: dealership.redirectUrl,
            is_prequal_enabled: dealership.isPrequalEnabled,
            is_the_closing_doc_enabled: dealership.isTheClosingDocsEnabled,
            chain_name: chain?.name,
            person_in_charge_first_name: principal?.firstName,
            person_in_charge_last_name: principal?.lastName,
            person_in_charge_job_title: principal?.jobTitle,
            person_in_charge_phone_number: principal?.phoneNumber,
            person_in_charge_phone_number_extension:
                principal?.phoneNumberExtension,
            person_in_charge_email_address: principal?.emailAddress,
        }
    }

    setUser(user: User) {
        this.user = user
        this.loadState()

        this.intercom.onHide(this.onIntercomHide.bind(this))
        this.intercom.onShow(this.onIntercomShow.bind(this))

        this.intercom.configure({
            hide_default_launcher: false,
        })

        const intercomUser = IntercomChat.getSettings(user)

        this.intercom.setUser(intercomUser)

        return intercomUser
    }

    getStateKey = () => `livechat.state.${this.user?.id}`

    boot = () => {
        this.intercom.boot(window.intercomSettings)
    }

    open = () => {
        this.intercom.show()
        this.setState('open')
    }

    close = () => {
        this.intercom.hide()
        this.setState('closed')
    }

    toggle = () => (this.state === 'open' ? this.close() : this.open())

    onIntercomHide = () => this.close()

    onIntercomShow = () => this.open()
}
