/* eslint-disable no-param-reassign, max-len */
import { createSlice } from '@reduxjs/toolkit'
import LoadingStatus from 'state/enums/LoadingStatus'
import isValidPhoneNumber from 'utils/isValidPhoneNumber'
import joinTextValues from 'utils/joinTextValues'

import { loadUser, logoutUser } from './actions'
import UserState from './UserState'

const initialState = {
    isLoggedIn: false,
    data: undefined,
    status: LoadingStatus.IDLE,
    error: undefined,
} as UserState

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state: UserState) => {
            state.status = LoadingStatus.IDLE
            state.isLoggedIn = true
        },
    },
    extraReducers(builder) {
        builder
            // Load User Reducers
            .addCase(loadUser.pending, (state: UserState) => {
                state.status = LoadingStatus.LOADING
            })
            .addCase(loadUser.fulfilled, (state: UserState, action) => {
                state.status = LoadingStatus.LOADED

                const { payload: userInfo } = action

                const { security_settings: securitySettings, dealership = {} } =
                    userInfo

                const principal = dealership?.person_in_charge
                    ? dealership?.person_in_charge[0]
                    : undefined

                const chain = dealership?.chain ?? undefined

                const phoneNumber = isValidPhoneNumber(userInfo.phone_number)
                    ? userInfo.phone_number
                    : ''

                const mfaPhoneNumber = isValidPhoneNumber(
                    userInfo.mfa_cell_phone,
                )
                    ? userInfo.mfa_cell_phone
                    : ''

                function getLevelOfAccess(salespersonId: number, groups: any) {
                    if (salespersonId) return 'Sales'

                    if (groups?.includes('End User:Dealer Admin'))
                        return 'Admin'

                    return 'Teammate'
                }

                state.data = {
                    id: userInfo.id,
                    username: userInfo.username,
                    uuid: userInfo.uuid,
                    firstName: userInfo.first_name,
                    lastName: userInfo.last_name,
                    email: userInfo.email,
                    phoneNumber,
                    mfaPhoneNumber,
                    mfaVerified: userInfo.is_mfa_cell_phone_verified,
                    dealerPortalUrl: userInfo.dealer_portal_url,
                    passwordInfoMessage: userInfo.password_info_message,
                    intercomHash: userInfo.intercom_hash,
                    actualNumberOfEndUsers: userInfo.actual_number_of_end_users,
                    lastLogin: userInfo.last_login,
                    dateJoined: userInfo.date_joined,
                    securitySettings: {
                        passwordRotation: `${securitySettings?.password_rotation}`,
                        sessionInactivity: `${securitySettings?.session_inactivity}`,
                    },
                    levelOfAccess: getLevelOfAccess(
                        userInfo.salesperson_id,
                        userInfo.groups,
                    ),
                    applicationAccess: userInfo.dealer_user_type
                        ? userInfo.dealer_user_type
                        : 'Sales',
                    groups: userInfo.groups,
                    permissions: userInfo.permissions,
                    isLender: userInfo.is_lender,
                    dealership: {
                        id: dealership?.id,
                        name: dealership?.name,
                        phoneNumber: dealership?.phone_number,
                        openInvitationsLimit:
                            dealership?.open_invitations_limit,
                        hasMultiUnitEligibility:
                            dealership?.has_multi_unit_eligibility,
                        address: joinTextValues(
                            [
                                dealership?.street_1,
                                dealership?.city,
                                dealership?.state,
                                dealership?.zipcode,
                            ],
                            ', ',
                        ),
                        addressInfo: {
                            street: dealership?.street_1,
                            city: dealership?.city,
                            state: dealership?.state,
                            zipCode: dealership?.zipcode,
                        },
                        principal: principal
                            ? {
                                  firstName: principal.first_name,
                                  lastName: principal.last_name,
                                  emailAddress: principal.email_address,
                                  jobTitle: principal.job_title,
                                  phoneNumber: principal.phone_number,
                                  phoneNumberExtension:
                                      principal.phone_number_extension,
                              }
                            : undefined,
                        chain,
                        type: dealership?.type,
                        faxNumber: dealership?.fax_number,
                        onWatchList: dealership?.on_watch_list,
                        isPrequalEnabled: dealership?.is_prequal_enabled,
                        isTheClosingDocsEnabled:
                            dealership?.is_the_closing_docs_enabled,
                        redirectUrl: dealership?.redirect_url,
                        isSafeCheckEnabled: dealership?.is_safecheck_enabled,
                        mfaEnabled: dealership?.mfa_enabled,
                        primaryAssetType: dealership?.primary_asset_type,
                        assetTypes: dealership?.asset_types,
                        securitySettingsId:
                            dealership?.dealership_security_settings,
                        flexLink: dealership?.flex_link,
                        flexPdf: dealership?.flex_pdf,
                        isAgreementSignedWithRoadrunner:
                            dealership?.is_agreement_signed_with_roadrunner,
                    },
                }

                state.isLoggedIn = true
            })
            .addCase(loadUser.rejected, (state: UserState, action) => {
                state.status = LoadingStatus.FAILED
                state.error = action.error.message
                state.isLoggedIn = false
            })

            // Logout User Reducers
            .addCase(logoutUser.fulfilled, (state: UserState) => {
                delete state.data
                state.status = LoadingStatus.IDLE
                state.isLoggedIn = false
            })
    },
})

export default userSlice
