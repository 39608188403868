import React, { useEffect, useState } from 'react'

import { Button, Modal, TextInput } from '@octane/spark'
import { FactorType, verifyFactor, requestChallenge } from 'api/auth/mfa'
import getFactors from 'api/auth/mfa/getFactors'
import { AnimatedField } from 'components/FormikFields/FormikTextInput/FormikTextInput.styled' // eslint-disable-line max-len
import { User } from 'state/user/UserState'

import { MfaModalText, MfaModalButtonWrapper } from './MfaModal.styled'

type MfaVerificationModalProps = {
    isOpen: boolean
    handleClose: () => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleCallback: any
    user: User | undefined
}

function MfaVerificationModal({
    isOpen,
    handleClose,
    handleCallback,
    user,
}: MfaVerificationModalProps): JSX.Element {
    const [tokenSent, setTokenSent] = useState(false)
    const [mfaToken, setMfaToken] = useState('')
    const [tokenInvalid, setTokenInvalid] = useState(false)
    const [tokenErrorText, setTokenErrorText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [factorValue, setFactorValue] = useState<string>()

    const handleSendTokenSubmit = async () => {
        await requestChallenge(FactorType.PHONE_NUMBER as FactorType)
        setTokenSent(true)
    }

    const verifyCode = (unverifiedCode: string) => {
        return verifyFactor(
            FactorType.PHONE_NUMBER as FactorType,
            unverifiedCode.toString(),
        )
    }

    const handleMfaTokenSubmit = async () => {
        try {
            await verifyCode(mfaToken)
            handleCallback()
            handleClose()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            setTokenInvalid(true)
            setTokenErrorText(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleMfaModalClose = async () => {
        handleClose()
        setTokenErrorText('')
        setTokenInvalid(false)

        setTimeout(() => {
            setTokenSent(false)
        }, 1000)
    }

    const handleMfaTokenChange = (value: string) => {
        setTokenInvalid(false)
        setMfaToken(value)
    }

    useEffect(() => {
        setIsLoading(true)

        getFactors()
            .then((factors) => {
                const primaryFactor = factors.find(
                    (factor) => factor.type === FactorType.PHONE_NUMBER,
                )

                setFactorValue(primaryFactor?.value as string)
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }, [setIsLoading])

    const renderMfaModalBody = () => {
        if (!user?.mfaVerified) {
            return <div>You do not have a verified MFA profile set up.</div>
        }

        if (!tokenSent) {
            return (
                <div>
                    <MfaModalText>
                        Click to send a token to {factorValue}
                    </MfaModalText>
                    <Button
                        testID="submitButton"
                        fullWidth={false}
                        onClick={handleSendTokenSubmit}
                        loading={isLoading}
                    >
                        Send
                    </Button>
                </div>
            )
        }

        return (
            <div>
                <MfaModalText>
                    Enter the token sent to {factorValue}
                </MfaModalText>
                <AnimatedField>
                    <TextInput
                        id="tokenInput"
                        onChange={handleMfaTokenChange}
                        data-testid="tokenInput"
                        error={tokenInvalid}
                        errorText={tokenErrorText}
                    />
                </AnimatedField>
                <MfaModalButtonWrapper>
                    <Button
                        fullWidth={false}
                        onClick={handleMfaTokenSubmit}
                        loading={isLoading}
                        testID="submitButton"
                    >
                        Submit
                    </Button>
                </MfaModalButtonWrapper>
                <MfaModalText>Didn&#39;t receive it?</MfaModalText>
                <MfaModalButtonWrapper>
                    <Button
                        fullWidth={false}
                        onClick={handleSendTokenSubmit}
                        testID="sendAgainButton"
                        loading={isLoading}
                    >
                        Send again
                    </Button>
                </MfaModalButtonWrapper>
            </div>
        )
    }

    return (
        <Modal
            title="Verify MFA"
            isOpen={isOpen}
            onClose={handleMfaModalClose}
            scroll="paper"
            size="medium"
            withCloseIcon={false}
        >
            <Modal.Body>{renderMfaModalBody()}</Modal.Body>
        </Modal>
    )
}

export default MfaVerificationModal
