import React from 'react'

import { Select } from '@octane/spark'
import {
    ErrorMessage,
    Legend,
} from 'components/FormikFields/FormikDropdown/FormikDropdown.styled'

import {
    FieldConfigBase,
    FieldKit,
    FieldProps,
    FormValues,
} from '../../containers/FormBuilder'

interface SelectFieldOption {
    name: string | JSX.Element
    value: string
}

export type SelectFieldOnChange = (
    value: string,
    fieldName: string,
    formValues: FormValues,
    fieldKit: FieldKit,
) => void

interface SelectFieldConfig extends FieldConfigBase {
    label: string
    options: SelectFieldOption[]
    disabled?: boolean
    placeholder?: string
    info?: string
    prefilled?: boolean
    onChange?: SelectFieldOnChange
    tooltip?: JSX.Element
}

export default function SelectField(props: FieldProps<SelectFieldConfig>) {
    const { fieldName, fieldKit, fieldConfig } = props
    const touched = fieldKit.touched[fieldName]
    const error = fieldKit.errors[fieldName]
    const value = fieldKit.values[fieldName]

    const handleChange = (newValue: string) => {
        fieldKit.setFieldTouched(fieldName, true)

        if (fieldConfig.onChange) {
            fieldConfig.onChange(newValue, fieldName, fieldKit.values, fieldKit)
        } else {
            const event = {
                target: {
                    name: fieldName,
                    value: newValue,
                },
            }

            fieldKit.handleChange(event)
        }
    }

    return (
        <div>
            {fieldConfig.info && <Legend>{fieldConfig.info}</Legend>}
            <Select
                disabled={fieldConfig.disabled}
                prefilled={fieldConfig.prefilled}
                label={fieldConfig.label}
                value={value}
                info={fieldConfig.tooltip}
                error={!!(touched && error)}
                id={fieldName}
                onChange={handleChange}
                placeholder={fieldConfig.placeholder}
                testID={fieldName}
            >
                {fieldConfig.options.map((option) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                            data-testid={option.value}
                        >
                            {option.name}
                        </Select.Option>
                    )
                })}
            </Select>

            {/**
             * Workaround in order to allow the value to be changed
             * externally by an extension (e.g. Revvable.com).
             */}
            <input
                hidden
                name={fieldName}
                value={value}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event.target.value)
                }}
            />

            {touched && error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
    )
}

SelectField.defaultProps = {
    disabled: false,
    placeholder: 'Select',
}
