import { useEffect, useRef, useState } from 'react'

import { OctaneLogger } from '../../../integrations/datadog/OctaneLogger'
import downloadFileFromURL, {
    ResponseType,
    DownloadOptions,
} from '../utils/downloadFileFromUrl'

interface DownloadedFile {
    originalUrl: string | null
    value: Blob | null
    blobUrl: string | null
}

export default function useDownloadFileAsBlob(
    url: string | null,
    responseType: ResponseType | null,
    downloadOptions?: DownloadOptions | null,
) {
    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const [failed, setFailed] = useState<boolean>(false)

    const data = useRef<DownloadedFile>({
        originalUrl: null,
        value: null,
        blobUrl: null,
    })

    const resetFileData = () => {
        setFailed(false)
        setIsDownloading(false)
        if (data.current.blobUrl) {
            URL.revokeObjectURL(data.current.blobUrl)
        }

        data.current = {
            originalUrl: url,
            value: null,
            blobUrl: null,
        }
    }

    const setNewFileData = (dataBlob: Blob) => {
        data.current = {
            originalUrl: url,
            value: dataBlob,
            blobUrl: URL.createObjectURL(dataBlob),
        }
    }

    useEffect(() => {
        if (!url || !responseType) {
            resetFileData()

            return
        }

        if (data.current.originalUrl === url && data.current.value !== null)
            return

        resetFileData()
        setIsDownloading(true)

        const abortController = new AbortController()

        downloadFileFromURL(
            url,
            responseType,
            abortController,
            downloadOptions ?? undefined,
        )
            .then((dataBlob: Blob) => {
                setNewFileData(dataBlob)

                setIsDownloading(false)
                setFailed(false)
            })
            .catch((e) => {
                setFailed(true)
                setIsDownloading(false)

                OctaneLogger.error(
                    `Failed to download file: ${url}`,
                    undefined,
                    e,
                )
            })

        // eslint-disable-next-line consistent-return
        return () => {
            abortController.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, responseType, downloadOptions])

    return {
        isDownloading,
        failed,
        fileData: data.current.value,
        fileBlobUrl: data.current.blobUrl,
    }
}
