import axios from 'axios'
import config from 'config'

export default async function ping() {
    try {
        await axios.get(`${config.API_BASE_URL}/api/v3/auth/ping/`, {
            withCredentials: true,
        })

        return true
    } catch (error) {
        // If the request fails it means that the session is not valid anymore.
        return false
    }
}
