/* eslint-disable no-param-reassign, max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiPaginatedResponse, ApiResultItem } from 'api/types'
import LoadingStatus from 'state/enums/LoadingStatus'

import {
    acceptInvitation,
    validateInvitation,
    loadInvitations,
} from './actions'
import InvitationState, { InvitationStep } from './InvitationState'
import { InvitationListParser } from './parsers'
import { ValidateInvitationResponseParser } from './parsers/ValidateInvitationResponseParser'

const initialState = {
    data: {
        firstName: '',
        dealershipName: '',
        dealershipId: undefined,
        email: '',
    },
    status: LoadingStatus.IDLE,
    message: undefined,
    error: undefined,
    invitationList: undefined,
} as InvitationState

const invitationSlice = createSlice({
    name: 'invitation',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // Validate Invitation Reducers
            .addCase(validateInvitation.pending, (state: InvitationState) => {
                state.status = LoadingStatus.LOADING
            })
            .addCase(
                validateInvitation.fulfilled,
                (state: InvitationState, action) => {
                    const { payload } = action

                    state.step = InvitationStep.VALIDATION
                    state.data = ValidateInvitationResponseParser(payload)
                    state.message = payload.message
                    state.status = LoadingStatus.LOADED
                },
            )
            .addCase(
                validateInvitation.rejected,
                (state: InvitationState, action) => {
                    state.status = LoadingStatus.FAILED
                    state.error = action.error.message
                },
            )

            // Accept Invitation Reducers
            .addCase(acceptInvitation.pending, (state: InvitationState) => {
                state.status = LoadingStatus.LOADING
            })
            .addCase(
                acceptInvitation.fulfilled,
                (state: InvitationState, action) => {
                    const { payload } = action

                    state.step = InvitationStep.ACCEPTANCE
                    state.message = payload.message
                    state.status = LoadingStatus.LOADED
                },
            )
            .addCase(
                acceptInvitation.rejected,
                (state: InvitationState, action) => {
                    state.status = LoadingStatus.FAILED
                    state.error = action.error.message
                },
            )

            // Invitations List reducers
            .addCase(loadInvitations.pending, (state: InvitationState) => {
                state.status = LoadingStatus.LOADING
            })
            .addCase(
                loadInvitations.fulfilled,
                (
                    state: InvitationState,
                    action: PayloadAction<ApiPaginatedResponse>,
                ) => {
                    const { payload }: { payload: ApiPaginatedResponse } =
                        action

                    state.step = InvitationStep.LISTING

                    state.invitationList = payload.results
                        .filter((i: ApiResultItem) => i.status !== 'Accepted')
                        .map(InvitationListParser)

                    state.status = LoadingStatus.LOADED
                },
            )
            .addCase(
                loadInvitations.rejected,
                (state: InvitationState, action) => {
                    state.status = LoadingStatus.FAILED
                    state.error = action.error.message
                },
            )
    },
})

export default invitationSlice
