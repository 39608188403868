import React from 'react'

import { Button, TextLink, UiArrowRightIcon } from '@octane/spark'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useUser from 'hooks/useUser'
import { LeadResult } from 'pages/LeadsPage/types'
import getApplicationReadOnlyPath from 'utils/getApplicationReadOnlyPath'
import getCopyIntoNewUrl from 'utils/getCopyIntoNewPath'
import { pushViewLeadClick, pushConvertToAppClick } from 'utils/gtm-utils'

import { Center } from './CtaButtons.styled'

export type CtaActions = 'viewLead' | 'convertToApp'

export interface CtaButtonsProps {
    lead: LeadResult
    cbCtaClicked: (action: CtaActions, leadId: number) => void
}

export default function CtaButtons(props: CtaButtonsProps): JSX.Element {
    const { user } = useUser()
    const featureFlags = useFeatureFlagContext()
    const { lead, cbCtaClicked } = props

    return (
        <Center>
            {lead.decision !== 'Converted' && lead.is_oem_child === false && (
                <Button
                    variant="primaryOutlined"
                    size="small"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault()
                        cbCtaClicked('viewLead', lead.id)
                        pushViewLeadClick(lead.id)
                    }}
                >
                    View Lead
                </Button>
            )}

            {lead.decision !== 'Converted' && lead.is_oem_child === false && (
                <a
                    href={getCopyIntoNewUrl(
                        featureFlags,
                        user?.dealership?.primaryAssetType,
                        lead.id,
                    )}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        variant="primaryOutlined"
                        size="small"
                        onClick={() => {
                            cbCtaClicked('convertToApp', lead.id)
                            pushConvertToAppClick(lead.id)
                        }}
                    >
                        Convert to App
                    </Button>
                </a>
            )}

            {lead.is_oem_child === true && (
                <a
                    href={getApplicationReadOnlyPath(
                        featureFlags,
                        user?.dealership?.primaryAssetType,
                        lead.id,
                    )}
                    target="_blank"
                    rel="noreferrer"
                >
                    <TextLink variant="simple" endIcon={UiArrowRightIcon}>
                        View App
                    </TextLink>
                </a>
            )}
        </Center>
    )
}
