import axios, { AxiosError } from 'axios'
import { UserInvitation } from 'components/UserInvitationModal/types'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function saveNewSalesperson(values: UserInvitation) {
    const requestBody = {
        email: values.emailAddress,
        first_name: values.firstName,
        last_name: values.lastName,
        dealer_phone: values.phoneNumber,
    }

    try {
        const { data } = await axios.post(
            `${config.API_BASE_URL}/api/v3/salesperson/`,
            requestBody,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to save new salesperson: ${axiosError.message}`,
            axiosError,
        )

        throw axiosError
    }
}
