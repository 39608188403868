import { ApiResponse } from 'api/types'
import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

const BASE_URL = `${config.API_BASE_URL}/api/v3/user_invitation`

export default async function validateInvitation(
    token: string,
): Promise<ApiResponse> {
    try {
        const { data } = await axios.get(`${BASE_URL}/${token}/validate/`)

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to validate invitation: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
