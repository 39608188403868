import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Description = styled.div`
    ${typography.BodyCopy16};
    padding-bottom: 20px;
    border-bottom: 1px solid
        ${(props) => props.theme.colors.monochrome.concrete};
`

export const LenderSelectRow = styled.div`
    margin-top: 20px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

export const SelectWrapper = styled.div`
    width: 350px;
`

export const LendersList = styled.div``

export const AddedLender = styled.div`
    padding: 24px 0px;
    border-bottom: 1px solid
        ${(props) => props.theme.colors.monochrome.concrete};

    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        min-width: 189px;
    }

    @media (max-width: 1080px) {
        flex-direction: column;
        align-items: flex-start;
        button {
            margin-top: 8px;
            width: 100%;
        }
    }
`

export const LenderName = styled.div`
    ${typography.Copy16Bold}
`

export const LenderWrapper = styled.div``

export const LenderInfo = styled.span`
    margin-left: 20px;
    ${typography.BodyCopy16}
`

export const LenderInfoWrapper = styled.div`
    display: flex;
`

export const LenderExtraInfo = styled.div`
    margin-top: 5px;
    ${typography.Copy14}
    max-width: 700px;

    @media (max-width: 1386px) {
        max-width: 600px;
    }
`
