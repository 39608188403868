import styled from 'styled-components'

export const EmptyPanel = styled.div`
    margin: auto;
    width: 100px;
`
export const SummaryStyle = styled.div`
    ${({ theme }) => theme.fonts.Montserrat['s32-ln40-fw800']};
    text-align: center;
    width: 450px;
    height: 50px;
    margin: auto;
`
export const DetailsStyle = styled.div`
    ${({ theme }) => theme.fonts.Montserrat['s16-ln20-fw400']};
    text-align: center;
    width: 650px;
    height: 30px;
    margin: auto;
`
export const ContainerStyle = styled.div`
    padding: 60px !important;
`
