import { FieldInstructionBundle } from 'containers/FormBuilder'
import { vehicleSelectionPowersports } from 'form/fields'
import * as fields from 'form/fields'
import DisclosureType from 'state/enums/DisclosureType'

import { ApplicationSubmissionFormInstructions } from '../ApplicationFormBuilder'
import { gridColumnWrapper } from '../gridColumnWrapper'
import sharedInstructions, {
    applicantFieldNames,
    otherFieldNames,
    coapplicantFieldNames,
    verificationFieldNames,
} from '../sharedInstructions'

export const COAPPLICANT_TAG = 'coapplicant_'

export const collateralFieldNames = {
    vehicle: 'vehicle',
}

export const consentFieldNames = {
    consent: 'consent',
}

const collateralFields: FieldInstructionBundle = {
    // key must be `vehicle` because of how vehicle selection components work
    [collateralFieldNames.vehicle]: vehicleSelectionPowersports,
}

const consentFields: FieldInstructionBundle = {
    [consentFieldNames.consent]: fields.createDisclosureField(
        DisclosureType.APPLICATION_FORM_DISCLOSURE,
    ),
}

const verificationFields: FieldInstructionBundle = {
    [verificationFieldNames.customerVerification]: fields.createDisclosureField(
        DisclosureType.CUSTOMER_VERIFICATION_DISCLOSURE,
        false,
    ),
}

const emailFields: FieldInstructionBundle = {
    [applicantFieldNames.email]: gridColumnWrapper(
        sharedInstructions.contactInfoFields[applicantFieldNames.email],
        'span 2',
    ),
}

const contactInfoFields: FieldInstructionBundle = {
    [applicantFieldNames.homePhone]: gridColumnWrapper(
        sharedInstructions.contactInfoFields[applicantFieldNames.homePhone],
        'span 1',
    ),
    [applicantFieldNames.mobilePhone]: gridColumnWrapper(
        sharedInstructions.contactInfoFields[applicantFieldNames.mobilePhone],
        'span 1',
    ),
}

const applicantNameFields: FieldInstructionBundle = {
    [applicantFieldNames.firstName]: gridColumnWrapper(
        sharedInstructions.applicantPersonalFields[
            applicantFieldNames.firstName
        ],
        'span 1',
    ),
    [applicantFieldNames.lastName]: gridColumnWrapper(
        sharedInstructions.applicantPersonalFields[
            applicantFieldNames.lastName
        ],
        'span 1',
    ),
}

const applicantPersonalFields: FieldInstructionBundle = {
    [applicantFieldNames.street]: gridColumnWrapper(
        sharedInstructions.applicantPersonalFields[applicantFieldNames.street],
        'span 8',
    ),
    [applicantFieldNames.apartmentSuite]: gridColumnWrapper(
        sharedInstructions.applicantPersonalFields[
            applicantFieldNames.apartmentSuite
        ],
        'span 4',
    ),
    [applicantFieldNames.zip]: gridColumnWrapper(
        sharedInstructions.applicantPersonalFields[applicantFieldNames.zip],
        'span 4',
    ),
    [applicantFieldNames.city]: gridColumnWrapper(
        sharedInstructions.applicantPersonalFields[applicantFieldNames.city],
        'span 4',
    ),
    [applicantFieldNames.state]: gridColumnWrapper(
        sharedInstructions.applicantPersonalFields[applicantFieldNames.state],
        'span 4',
    ),
}

const applicantIdentityFields: FieldInstructionBundle = {
    [applicantFieldNames.citizenshipStatus]:
        sharedInstructions.applicantIdentityFields[
            applicantFieldNames.citizenshipStatus
        ],
    [applicantFieldNames.dateOfBirth]: gridColumnWrapper(
        sharedInstructions.applicantPersonalFields[
            applicantFieldNames.dateOfBirth
        ],
        'span 1',
    ),
    [applicantFieldNames.ssn]: gridColumnWrapper(
        sharedInstructions.applicantIdentityFields[applicantFieldNames.ssn],
        'span 1',
    ),
}

const residenceFields: FieldInstructionBundle = {
    [applicantFieldNames.residenceStatus]: gridColumnWrapper(
        sharedInstructions.residenceFields[applicantFieldNames.residenceStatus],
        'span 2',
    ),
    [applicantFieldNames.residentialYears]: gridColumnWrapper(
        sharedInstructions.residenceFields[
            applicantFieldNames.residentialYears
        ],
        'span 1',
    ),
    [applicantFieldNames.residentialMonths]: gridColumnWrapper(
        sharedInstructions.residenceFields[
            applicantFieldNames.residentialMonths
        ],
        'span 1',
    ),
    [applicantFieldNames.monthlyHousingPayment]: gridColumnWrapper(
        sharedInstructions.residenceFields[
            applicantFieldNames.monthlyHousingPayment
        ],
        'span 2',
    ),
}

const employmentFields: FieldInstructionBundle = {
    [applicantFieldNames.employmentStatus]:
        sharedInstructions.employmentFields[
            applicantFieldNames.employmentStatus
        ],
    [applicantFieldNames.employerGrossInterval]:
        sharedInstructions.employmentFields[
            applicantFieldNames.employerGrossInterval
        ],
    [applicantFieldNames.employerGrossAmount]:
        sharedInstructions.employmentFields[
            applicantFieldNames.employerGrossAmount
        ],
    [applicantFieldNames.jobTitle]:
        sharedInstructions.employmentFields[applicantFieldNames.jobTitle],
    [applicantFieldNames.employerName]:
        sharedInstructions.employmentFields[applicantFieldNames.employerName],
    [applicantFieldNames.employerYears]:
        sharedInstructions.employmentFields[applicantFieldNames.employerYears],
    [applicantFieldNames.employerMonths]:
        sharedInstructions.employmentFields[applicantFieldNames.employerMonths],
    [applicantFieldNames.workPhone]:
        sharedInstructions.employmentFields[applicantFieldNames.workPhone],
    [applicantFieldNames.hasOtherIncome]:
        sharedInstructions.employmentFields[applicantFieldNames.hasOtherIncome],
    [applicantFieldNames.otherIncomeSource]:
        sharedInstructions.employmentFields[
            applicantFieldNames.otherIncomeSource
        ],
    [applicantFieldNames.otherIncomeGrossAmount]:
        sharedInstructions.employmentFields[
            applicantFieldNames.otherIncomeGrossAmount
        ],
    [applicantFieldNames.otherIncomeGrossInterval]:
        sharedInstructions.employmentFields[
            applicantFieldNames.otherIncomeGrossInterval
        ],
    [applicantFieldNames.removeOtherIncome]:
        sharedInstructions.employmentFields[
            applicantFieldNames.removeOtherIncome
        ],
}

const coapplicantEmailFields: FieldInstructionBundle = {
    [coapplicantFieldNames.email]: gridColumnWrapper(
        sharedInstructions.coapplicantContactInfoFields[
            coapplicantFieldNames.email
        ],
        'span 2',
    ),
}

const coapplicantContactInfoFields: FieldInstructionBundle = {
    [coapplicantFieldNames.homePhone]:
        sharedInstructions.coapplicantContactInfoFields[
            coapplicantFieldNames.homePhone
        ],
    [coapplicantFieldNames.mobilePhone]:
        sharedInstructions.coapplicantContactInfoFields[
            coapplicantFieldNames.mobilePhone
        ],
}

const coapplicantEmploymentFields: FieldInstructionBundle = {
    [coapplicantFieldNames.employmentStatus]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.employmentStatus
        ],
    [coapplicantFieldNames.employerGrossInterval]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.employerGrossInterval
        ],
    [coapplicantFieldNames.employerGrossAmount]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.employerGrossAmount
        ],
    [coapplicantFieldNames.jobTitle]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.jobTitle
        ],
    [coapplicantFieldNames.employerName]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.employerName
        ],
    [coapplicantFieldNames.employerYears]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.employerYears
        ],
    [coapplicantFieldNames.employerMonths]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.employerMonths
        ],
    [coapplicantFieldNames.workPhone]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.workPhone
        ],
    [coapplicantFieldNames.hasOtherIncome]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.hasOtherIncome
        ],
    [coapplicantFieldNames.otherIncomeSource]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.otherIncomeSource
        ],
    [coapplicantFieldNames.otherIncomeGrossAmount]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.otherIncomeGrossAmount
        ],
    [coapplicantFieldNames.otherIncomeGrossInterval]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.otherIncomeGrossInterval
        ],
    [coapplicantFieldNames.removeOtherIncome]:
        sharedInstructions.coapplicantEmploymentFields[
            coapplicantFieldNames.removeOtherIncome
        ],
}

const coapplicantNameFields: FieldInstructionBundle = {
    [coapplicantFieldNames.firstName]: gridColumnWrapper(
        sharedInstructions.coapplicantPersonalFields[
            coapplicantFieldNames.firstName
        ],
        'span 1',
    ),
    [coapplicantFieldNames.lastName]: gridColumnWrapper(
        sharedInstructions.coapplicantPersonalFields[
            coapplicantFieldNames.lastName
        ],
        'span 1',
    ),
}

const coapplicantIdentityFields: FieldInstructionBundle = {
    [coapplicantFieldNames.citizenshipStatus]:
        sharedInstructions.coapplicantIdentityFields[
            coapplicantFieldNames.citizenshipStatus
        ],
    [coapplicantFieldNames.dateOfBirth]: gridColumnWrapper(
        sharedInstructions.coapplicantPersonalFields[
            coapplicantFieldNames.dateOfBirth
        ],
        'span 1',
    ),
    [coapplicantFieldNames.ssn]: gridColumnWrapper(
        sharedInstructions.coapplicantIdentityFields[coapplicantFieldNames.ssn],
        'span 1',
    ),
}

const coapplicantPersonalFields: FieldInstructionBundle = {
    [otherFieldNames.isCoapplicantAddressSame]: gridColumnWrapper(
        sharedInstructions.coapplicantPersonalFields[
            otherFieldNames.isCoapplicantAddressSame
        ],
        '1 / span 24',
    ),
}

const coapplicantAddressFields: FieldInstructionBundle = {
    [coapplicantFieldNames.street]: gridColumnWrapper(
        sharedInstructions.coapplicantAddressFields[
            coapplicantFieldNames.street
        ],
        'span 8',
    ),
    [coapplicantFieldNames.apartmentSuite]: gridColumnWrapper(
        sharedInstructions.coapplicantAddressFields[
            coapplicantFieldNames.apartmentSuite
        ],
        'span 4',
    ),
    [coapplicantFieldNames.zip]: gridColumnWrapper(
        sharedInstructions.coapplicantAddressFields[coapplicantFieldNames.zip],
        'span 4',
    ),
    [coapplicantFieldNames.city]: gridColumnWrapper(
        sharedInstructions.coapplicantAddressFields[coapplicantFieldNames.city],
        'span 4',
    ),
    [coapplicantFieldNames.state]: gridColumnWrapper(
        sharedInstructions.coapplicantAddressFields[
            coapplicantFieldNames.state
        ],
        'span 4',
    ),
}

const coapplicantResidenceFields: FieldInstructionBundle = {
    [coapplicantFieldNames.residenceStatus]: gridColumnWrapper(
        sharedInstructions.coapplicantResidenceFields[
            coapplicantFieldNames.residenceStatus
        ],
        'span 2',
    ),
    [coapplicantFieldNames.residentialYears]: gridColumnWrapper(
        sharedInstructions.coapplicantResidenceFields[
            coapplicantFieldNames.residentialYears
        ],
        'span 1',
    ),
    [coapplicantFieldNames.residentialMonths]: gridColumnWrapper(
        sharedInstructions.coapplicantResidenceFields[
            coapplicantFieldNames.residentialMonths
        ],
        'span 1',
    ),
    [coapplicantFieldNames.monthlyHousingPayment]: gridColumnWrapper(
        sharedInstructions.coapplicantResidenceFields[
            coapplicantFieldNames.monthlyHousingPayment
        ],
        'span 2',
    ),
}

const { financeManagerFields, ...modifiedSharedInstructions } =
    sharedInstructions

financeManagerFields[otherFieldNames.financeManager] = gridColumnWrapper(
    sharedInstructions.financeManagerFields[otherFieldNames.financeManager],
    'span 1',
)

export const powersportsFormInstructions = (
    isCrossoverApplication?: boolean | undefined,
): ApplicationSubmissionFormInstructions => {
    const instructions = isCrossoverApplication
        ? modifiedSharedInstructions
        : sharedInstructions

    return {
        ...instructions,
        collateralFields,
        consentFields,
        verificationFields,
        emailFields,
        contactInfoFields,
        applicantNameFields,
        applicantIdentityFields,
        applicantPersonalFields,
        residenceFields,
        employmentFields,
        coapplicantEmailFields,
        coapplicantContactInfoFields,
        coapplicantEmploymentFields,
        coapplicantNameFields,
        coapplicantPersonalFields,
        coapplicantAddressFields,
        coapplicantIdentityFields,
        coapplicantResidenceFields,
    }
}
