import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface ResponseMake {
    id: number
    name: string
}

export interface ResponseModel {
    id: number
    make: ResponseMake
    name: string
}

export interface ResponseVehicle {
    average_retail?: unknown
    average_wholesale?: unknown
    cc: number
    condition: string
    cylinders?: unknown
    data_source?: string
    data_source_id?: number
    high_trade?: number
    id: number
    image?: string
    is_dummy?: boolean
    is_financeable: boolean
    is_leasable: boolean
    low_retail?: unknown
    low_trade?: unknown
    model: ResponseModel
    msrp: number
    name: string
    oem: string
    stroke?: unknown
    sku?: string
    transmission?: unknown
    uuid: string
    vehicle_type: string
    weight?: unknown
    year: number
}

export default async function getVehicle(vehicleId: number) {
    try {
        const { data }: { data: ResponseVehicle } = await axios.get(
            `${config.API_BASE_URL}/api/v1/vehicles/${vehicleId}`,
            { withCredentials: true },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get vehicle: ${axiosError.message}`,
            axiosError,
            { vehicleId },
        )

        throw new Error(axiosError.message)
    }
}
