import axios, { AxiosError } from 'axios'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

const DEPLOYMENT_JSON_URL = `${window.location.origin}/deployment.json`

interface DeploymentData {
    build: string
    createdAt: string
}

export default async function getCurrentWebAppVersion(): Promise<DeploymentData | null> {
    try {
        const response = await axios.get<DeploymentData>(DEPLOYMENT_JSON_URL, {
            withCredentials: true,
        })

        return response.data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get current web app version: ${axiosError.message}`,
            axiosError,
        )

        return { build: '', createdAt: '' } as DeploymentData
    }
}
