import styled from 'styled-components'

interface RowProps {
    gridTemplateColumns: string
}

export const Row = styled.div<RowProps>`
    display: grid;
    grid-template-columns: ${(props) => props.gridTemplateColumns};
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 20px;

    :last-child {
        margin-bottom: 0;
    }
`
