import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function getLeadsCount() {
    try {
        const { data } = await axios.get(
            // eslint-disable-next-line max-len
            `${config.API_BASE_URL}/api/v3/applications/leads-count/new-leads-count/`,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get leads count: ${axiosError.message}`,
            axiosError,
        )

        throw error
    }
}
