import { ApiResultItem } from 'api/types'
import { UserInvitation } from 'components/UserInvitationModal/types'

export function InvitationListParser(payload: ApiResultItem): UserInvitation {
    return {
        token: `${payload.token}`,
        emailAddress: `${payload.email}`,
        levelOfAccess: `${payload.level_of_access}`,
        applicationAccess: `${payload.application_access}`,
        fullName: `${payload.first_name} ${payload.last_name}`,
        invitationStatus: `${payload.status}`,
        invitationSent: `${payload.last_emailed_at}`,
        inviterName: `${payload.inviter_name}`,
    }
}
