import React from 'react'

import { Select } from '@octane/spark'
import { AssignToPerson } from 'api/flexLinks/getAssignToPersons'
import updateLeadLink from 'api/flexLinks/updateLeadLink'
import * as toast from 'utils/toast'

interface DealerAssignedProps {
    users: AssignToPerson[]
    setEditUser: (editUser: boolean) => void
    appId: string
    user: AssignToPerson | undefined
    updateUser: (user: AssignToPerson) => void
}

export default function DealerUserDropdown({
    users,
    setEditUser,
    appId,
    user,
    updateUser,
}: Readonly<DealerAssignedProps>) {
    async function handlePersonChange(value: string) {
        if (value !== '') {
            let person = users.find((x) => x.dealerUserId?.toString() === value)

            if (!person) {
                person = users.find(
                    (x) => x.salesPersonId?.toString() === value,
                )
            }

            if (person) {
                await updateLeadLink({
                    id: appId,
                    dealerUserId: person.dealerUserId,
                    salesPersonId: person.salesPersonId,
                })

                updateUser(person)

                toast.info({
                    title: 'Lead has been updated',
                    message: `${person.name} is the new owner of this lead`,
                    withCloseIcon: false,
                })

                setEditUser(false)
            } else {
                toast.error({
                    title: 'Lead not found',
                    message: ``,
                    withCloseIcon: false,
                })
            }
        } else {
            const person = {
                name: '',
                email: '',
                dealUserId: '',
                salesPersonId: '',
            }

            updateUser(person)

            toast.info({
                title: 'Lead has been updated',
                message: `This lead is unassigned`,
                withCloseIcon: false,
            })

            setEditUser(false)
        }
    }

    return (
        <Select
            label=""
            placeholder="Select owner"
            id="dropdown-assigned-link"
            value={user?.dealerUserId}
            onChange={(value) => handlePersonChange(value)}
        >
            <Select.Option value="" key="0">
                Unassigned
            </Select.Option>
            {users.map((item) => {
                let userId = ''

                if (item.dealerUserId) {
                    userId = item.dealerUserId
                }

                if (item.salesPersonId) {
                    userId = item.salesPersonId
                }

                return (
                    <Select.Option value={`${userId}`} key={userId}>
                        {item.name}
                    </Select.Option>
                )
            })}
        </Select>
    )
}
