import { createAsyncThunk } from '@reduxjs/toolkit'
import { reauthenticate } from 'api/user'
import { reauthenticateParams } from 'api/user/reauthenticate'

const reauthenticateUser = createAsyncThunk(
    'user/reauthenticateUser',
    async (params: reauthenticateParams) => {
        return reauthenticate(params)
    },
)

export default reauthenticateUser
