import styled from 'styled-components'

export const DeclineReasonsContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.monochrome.moonGrey};
    border-radius: 4px;
    padding: 24px;
    padding-top: 20px;
    padding-bottom: 12px;
    margin-bottom: 16px;
    text-align: left;
    width: 100%;

    button {
        margin-bottom: 10px;
    }
`

export const BoxHeader = styled.div`
    ${({ theme }) => theme.fonts.Karla['s16-ln20-fw400']};
    color: ${({ theme }) => theme.colors.monochrome.ravenSub};
    border-bottom: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    padding-bottom: 12px;
`

export const DeclineReasonList = styled.ul`
    padding: 0px;
    margin: 8px 0px;
`

export const DeclineReasonItem = styled.li`
    ${({ theme }) => theme.fonts.Karla['s16-ln20-fw700']};
    display: grid;
    grid-template-columns: 32px auto;
    list-style: none;
    padding: 8px 0px;

    path {
        fill: ${({ theme }) => theme.colors.alert.error};
    }
`

export const DeclineReasonText = styled.span``

export const DeclineReasonsSpinnerWrapper = styled.div`
    padding: 1em;
    width: 100%;
    display: flex;
    justify-content: center;
`
