/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import { Modal } from '@octane/spark'
import LoadingSpinner from 'components/Icons/LoadingSpinner.svg'

import AnimatedSecondCheckpointBody from './AnimatedSecondCheckpointBody'
import { Body, Spinner } from './FirstCheckpointModal.styled'

function SecondCheckpointBody() {
    return (
        <Body data-testid="SecondCheckpointModalContent">
            <Spinner>
                <img src={LoadingSpinner} alt="Loading Spinner" />
            </Spinner>
            <h2>Finalizing Decision</h2>
            <span>Validating prequalification and loading offer terms.</span>
        </Body>
    )
}

function SecondCheckpointModal(props: { isOpen: boolean; animated?: boolean }) {
    const { isOpen, animated } = props

    // When setting 'isOpen' from true to false in quick succession
    // (as seen in the Application Forms),
    // the overlay 'sticks' and prevents page interaction.
    // We explicitly stop sending the component to avoid this.
    if (!isOpen) return null

    const bodyContent = animated ? (
        <AnimatedSecondCheckpointBody />
    ) : (
        <SecondCheckpointBody />
    )

    return (
        <Modal
            isOpen={isOpen}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClose={/* istanbul ignore next */ () => {}}
            scroll="paper"
            withCloseIcon={false}
        >
            <Modal.Body>{bodyContent}</Modal.Body>
        </Modal>
    )
}

SecondCheckpointModal.defaultProps = {
    animated: false,
}

export default SecondCheckpointModal
