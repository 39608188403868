import React, { useState } from 'react'

import { UiDeleteIcon, UiEditIcon } from '@octane/spark'
import FlexLinkDeleteModal from 'components/FlexLinkDeleteModal'
import RowActionButton from 'components/Table/RowActionButton'
import { ActionsContainer } from 'components/Table/Table.styled'
import { FlexLink } from 'state/dealership/DealershipState'

type RowActionsProps = {
    value: FlexLink
    onEdit: (flexLink: FlexLink) => void
}

export default function RowActions({
    value,
    onEdit,
}: Readonly<RowActionsProps>) {
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

    function handleDelete() {
        setDeleteModalOpen(true)
    }

    return (
        <ActionsContainer>
            <RowActionButton
                onClick={() => onEdit(value)}
                title="Edit Link"
                icon={UiEditIcon}
            />
            <RowActionButton
                onClick={() => handleDelete()}
                title="Delete Link"
                icon={UiDeleteIcon}
            />
            {isDeleteModalOpen && (
                <FlexLinkDeleteModal
                    isOpen
                    onModalClose={() => setDeleteModalOpen(false)}
                    linkId={value.id}
                />
            )}
        </ActionsContainer>
    )
}
