import styled from 'styled-components'

export const PdfViewerPage = styled.div`
    width: fit-content;
    height: fit-content;
    box-shadow: 0 0 15px #0007;
    margin: auto;
    transform: var(--document-media-transform);
    transition: transform 0.3s ease-out;

    canvas {
        background-color: #ffffff;
    }
`

export const PdfViewerViewport = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
    overscroll-behavior: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #777;
    }
`

export const Container = styled.div`
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    align-self: stretch;
    position: relative;
`
