import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Copy = styled.p`
    ${typography.BodyCopy16};
    margin: 20px 0 15px 0;
`
export const ModalTitle = styled.h2`
    position: absolute;
    transform: translateY(-2.5em);
`
