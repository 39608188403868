import React, { useEffect, useState } from 'react'

import { PanelContainer, PanelBody } from './FloatingPanel.styled'

export type FloatingStates = 'none' | 'show' | 'hide'

type Props = {
    width?: number
    height?: number
    show?: FloatingStates
    children: React.ReactNode
} & typeof defaultProps

const defaultProps = {
    width: 450,
    height: 390,
    show: '',
}

function FloatingPanel({ show, children, width, height }: Props): JSX.Element {
    const [showWarning, setShowWarning] = useState('')
    const [overflowed, setOverflowed] = useState('')

    useEffect(() => {
        setShowWarning(show)
    }, [show])

    useEffect(() => {
        if (showWarning === 'show')
            window.setTimeout(() => {
                setOverflowed('overflowed')
            }, 500)
        else setOverflowed('')
    }, [showWarning])

    return (
        <PanelContainer
            className={overflowed}
            style={{ width: `${width}px`, height: `${height}px` }}
        >
            <PanelBody
                className={showWarning}
                style={{ width: `${width}px`, height: `${height}px` }}
            >
                {children}
            </PanelBody>
        </PanelContainer>
    )
}

FloatingPanel.defaultProps = defaultProps

export default FloatingPanel
