import React from 'react'

import DealershipInformation from 'components/DealershipInformation'
import useUser from 'hooks/useUser'

export default function MyDealership(): JSX.Element {
    const { user } = useUser()

    return <DealershipInformation dealership={user?.dealership} />
}
