/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import { Button, Modal } from '@octane/spark'

import { ModalButton, Content } from './DisclosureModal.styled'

export default function ESignActModal(props: {
    showModal: boolean
    toggleModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const { showModal, toggleModal } = props

    return (
        <Modal
            title="E-Sign Act Disclosure"
            isOpen={showModal}
            onClose={/* istanbul ignore next */ () => toggleModal(false)}
            scroll="body"
        >
            <Modal.Body>
                <Content data-testid="modalContent-ESignDisclosure">
                    <p>
                        This E-Sign Act Disclosure is made in connection with an
                        application for credit ("Application") for Dealership’s
                        customer(s) (“Applicant”) to Roadrunner Financial, Inc.
                        ("Roadrunner"). By giving your consent, you agree that
                        you provide affirmative consent and have obtained the
                        affirmative consent of the Applicant, to conduct
                        transactions with Octane Lending, Inc. and its
                        affiliates/subsidiaries ("Octane") electronically,
                        including using electronic signatures. You agree that
                        such electronic signatures will consist of clicking on
                        buttons and/or checking boxes where indicated. You also
                        consent and agree that you have obtained the affirmative
                        consent of the Applicant for the use of electronic
                        records, including records sent to you & the Applicant
                        electronically, instead of receiving them on paper or by
                        regular mail. Your consent will be effective upon
                        accepting this terms and conditions on the foregoing web
                        page and the affirmative consent of the Applicant will
                        be effective as of the date that you obtained said
                        consent in writing from the Applicant. This consent will
                        apply to any transaction or agreement that you or the
                        Applicant may enter into with us in the future that
                        modifies or extends your original transaction. If you or
                        the Applicant does not consent to the use of electronic
                        signatures and Records, neither you nor the Applicant
                        will be able to complete this Application.
                    </p>
                    <p>
                        Should this Application be denied, Notice of Adverse
                        Action will be sent to the Applicant via U.S. Postal
                        Service. Should the Application be approved, and
                        Applicant(s) enter into a loan with Roadrunner through
                        the dealership of their choice, copies of loan-related
                        disclosures must be provided to them by your Dealership.
                        Applicant must agree, and Dealership must have the
                        authority on behalf of the Applicant to agree to this
                        notice for the application process to continue.
                    </p>
                    <p>
                        "Communication" means any disclosures, notices, and all
                        other information related to the application including
                        but not limited to information that we are required by
                        law to provide to you or the Applicant in writing.
                        "Online Service" means the submission of an application
                        through Octane’s website and any other online product or
                        service offered through our website in which you have
                        enrolled that is not otherwise governed by an electronic
                        disclosure and consent.
                    </p>
                    <p>
                        By accepting the terms of this E-Sign Act Disclosure,
                        you are agreeing on the Dealership’s behalf, and agree
                        that you have obtained the Applicant’s affirmative
                        consent, to the following:
                    </p>
                    <ol>
                        <li>
                            We may electronically provide you or the Applicant
                            all legal or regulatory Communications associated
                            with this Application and associated with any credit
                            transaction that may result from this Application.
                        </li>
                        <li>
                            We may deliver Communications to you or the
                            Applicant by any of the following methods: (a) by
                            posting a notice and making the information
                            available to you through the Online Service; or (b)
                            by sending the information to an email address you
                            have provided to us; or (c) to a wireless device you
                            or the Applicant has designated; or (d) to the
                            extent permissible by law, by access to a web site
                            that we will generally designate in advance for such
                            purpose; or (e) any other electronic means we have
                            mutually agreed upon. Delivery of electronic
                            Communications by any of these methods will be
                            considered "in writing" and you intend that the
                            electronic Communications have the same legal effect
                            as written and signed paper communications. You
                            agree to promptly notify us of any change in your
                            contact information. You can update your contact
                            information through the Online Service as well as by
                            contacting Octane at (646) 809-4123
                        </li>
                        <li>
                            You or the Applicant may withdraw consent to receive
                            electronic Communications by contacting us at (646)
                            809-4123. If you or the Applicant withdraws consent
                            to receive electronic Communications, we may
                            terminate your access to the Online Service for the
                            Application.
                        </li>
                        <li>
                            You or the Applicant may obtain paper copies of the
                            electronic Communications by printing them within
                            the Online Service or by calling at (646) 809-4123.
                            There is no fee for paper copies.
                        </li>
                        <li>
                            The minimum hardware and software requirements to
                            access and retain the electronic Communications are:
                            <ul>
                                <li>
                                    A personal computer or other device meeting
                                    the following requirements:
                                    <ul>
                                        <li>
                                            <b>Compatible Operating Systems</b>
                                            <ul>
                                                <li>Windows 7 or higher</li>
                                                <li>Mac OS</li>
                                                <li>Android</li>
                                                <li>iOS</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <b>
                                                Compatible Browsers (latest
                                                version)
                                            </b>
                                            <ul>
                                                <li>Google Chrome</li>
                                                <li>Safari</li>
                                                <li>Edge</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    You will also need telecommunications
                                    connections to the Internet capable of
                                    receiving, accessing, displaying, and either
                                    printing or storing electronic
                                    Communications, and sufficient electronic
                                    storage capacity on your computer’s hard
                                    drive or other data storage unit.
                                </li>
                                <li>
                                    You are solely responsible to keep your
                                    operating system and software up to date
                                    regardless of information as to system
                                    requirements as may be provided herein.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </Content>
            </Modal.Body>
            <Modal.Footer>
                <ModalButton>
                    <Button
                        type="button"
                        variant="primaryOutlined"
                        onClick={() => toggleModal(false)}
                    >
                        Close
                    </Button>
                </ModalButton>
            </Modal.Footer>
        </Modal>
    )
}
