import { setupMfaFactor } from 'api/user/mfa'

import { FactorType } from './enums'

export default async function enrollFactor(
    factorType: FactorType,
    value: string,
): Promise<unknown> {
    return setupMfaFactor(factorType, value)
}
