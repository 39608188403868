import { toast } from '@octane/spark'

type Type = 'success' | 'error' | 'warning' | 'info'

type ToastSize = 'medium' | 'large'

interface ShowProps {
    type: Type
    message: string
    title?: string
    withCloseIcon?: boolean
    size?: ToastSize
}

function show({ type, message, title, withCloseIcon = true, size }: ShowProps) {
    toast(message, {
        duration: 5000,
        title: title || type.charAt(0).toUpperCase() + type.slice(1),
        size: size ?? 'medium',
        position: 'top-right',
        variant: type,
        closeIcon: !!withCloseIcon,
    })
}

interface ToastProps {
    message: string
    title?: string
    withCloseIcon?: boolean
    size?: ToastSize
}

export function success(props: ToastProps) {
    show({
        type: 'success',
        ...props,
    })
}

export function info(props: ToastProps) {
    show({
        type: 'info',
        ...props,
    })
}

export function error(props: ToastProps) {
    show({
        type: 'error',
        ...props,
    })
}
