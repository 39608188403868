import React from 'react'

import { TextLink } from '@octane/spark'

import { FooterWrapper } from './ErrorFooter.styled'
import ErrorFooterProps from './ErrorFooterProps'

function ErrorFooter({ supportPhoneNumber, supportEmail }: ErrorFooterProps) {
    return (
        <FooterWrapper>
            <span>
                Need help? Call us at{' '}
                <a
                    data-testid="phone-number"
                    href={`tel:${supportPhoneNumber}`}
                >
                    <TextLink>{supportPhoneNumber}</TextLink>
                </a>
            </span>
            <span>
                {' '}
                or email us at{' '}
                <a data-testid="e-mail" href={`mailto:${supportEmail}`}>
                    <TextLink>{supportEmail}</TextLink>
                </a>
            </span>
        </FooterWrapper>
    )
}

export default ErrorFooter
