export const hardcodedMakes = [
    'Alumacraft',
    'Alweld Boats',
    'AquaPatio',
    'Arima Boats',
    'ATX',
    'Avalon',
    'Avenger Boats',
    'Avid Boats',
    'Axis',
    'Baja',
    'Barletta Boats',
    'Bass Cat Boats',
    'Bayliner',
    'Bennington',
    'Bentley Pontoons',
    'Berkshire Pontoons',
    'Blackfin',
    'BlackJack Boats',
    'Blazer Boats',
    'Boston Whaler',
    'BRIG',
    'Bulls Bay',
    'Carolina Skiff',
    'Caymas Boats',
    'Centurion',
    'Chaparral',
    'Charger',
    'Chris-Craft',
    'Coach Pontoons',
    'Cobalt',
    'Cobia',
    'Contender Boats',
    'Cougar Marine',
    'Crest Pontoons',
    'Crestliner',
    'Crevalle Boats',
    'Crownline',
    'Cruisers Yachts',
    'Cutwater Boats',
    'Cypress Cay',
    'Defiance Boats',
    'Donzi',
    'Duckworth',
    'EdgeWater',
    'Excel Boats',
    'Falcon Bass Boats',
    'Formula',
    'Fountain Powerboats',
    'Four Winns',
    'Freedom Marine',
    'G3 Boats',
    'Glastron',
    'Godfrey Pontoons',
    'Grady-White',
    'Harris',
    'Havoc Boats',
    'Hewes',
    'Hewescraft',
    'Heyday',
    'Hurricane',
    'iKon Boats',
    'JC TriToon',
    'Key West',
    'KingFisher',
    'Klamath Boats',
    'Landau Boats',
    'Larson',
    'Lowe',
    'Lund',
    'Majek',
    'Mako Boats',
    'Malibu Boats',
    'Manitou Pontoon',
    'Marlon',
    'MasterCraft',
    'Maverick Boats',
    'May-Craft',
    'MB Sports',
    'Mighty G',
    'MirroCraft',
    'Monaco',
    'Montara',
    'Montego Bay',
    'Monterey',
    'Moomba',
    'Nautic Star',
    'Nautique',
    'Nitro',
    'North River Boats',
    'Parker',
    'Parker Poland',
    'Pathfinder',
    'Phoenix',
    'Pioneer Boats',
    'Polar Kraft',
    'Premier Pontoons',
    'Princecraft',
    'Pro-Drive',
    'Pursuit',
    'Qwest Pontoons',
    'Ranger',
    'Regal',
    'Regency Boats',
    'Regulator Marine',
    'RH Boats',
    'Rinker',
    'Robalo',
    'Rossiter',
    'Sailfish Boats',
    'Sanger Boats',
    'Sanpan',
    'Savannah',
    'Scarab',
    'Scout',
    'Sea Born',
    'Sea Chaser',
    'Sea Fox',
    'Sea Hunt',
    'Sea Pro',
    'Sea Ray',
    'Sea-Doo/BRP',
    'SeaArk Boats',
    'Shallow Sport Boats',
    'Shearwater',
    'Silver Wave',
    'Skeeter',
    'Smoker-Craft',
    'Southwind',
    'Sportsman Boats',
    'Spyder',
    'Stabicraft',
    'Stanley Boats',
    'Starcraft',
    'Starweld',
    'Stingray',
    'Stratos',
    'Sun Tracker',
    'SunCatcher',
    'SunChaser',
    'Sundance Boats',
    'Supra',
    'Supreme',
    'Sweetwater',
    'Sylvan',
    'Tahoe Boats',
    'Tahoe Pontoons',
    'Thunder Jet Boats',
    'Tidewater Boats',
    'Tige',
    'TRACKER',
    'Trifecta',
    'Triton',
    'Veranda',
    'Vexus',
    'Viaggio',
    'War Eagle',
    'Warrior Boats',
    'Weldcraft',
    'Wellcraft',
    'Xcursion',
    'Xpress Boats',
    'Yamaha',
    'Zodiac',
]
