import React from 'react'

import { IconComponent, theme } from '@octane/spark'
import styled from 'styled-components'

import {
    FieldConfigBase,
    FieldKit,
    FieldProps,
    FormValues,
} from '../../containers/FormBuilder'

export type LinkButtonFieldOnClick = (
    fieldName: string,
    formValues: FormValues,
    fieldKit: FieldKit,
) => void

interface LinkButtonFieldConfig extends FieldConfigBase {
    icon?: IconComponent
    iconWidth?: number
    iconHeight?: number
    label?: string
    prefilled?: boolean
    onClick: LinkButtonFieldOnClick
}

const Label = styled.span`
    font-size: 16px;
    line-height: 25px;
    color: ${(props) => props.theme.colors.brand.rideOctaneBlue};
`

export default function LinkButtonField(
    props: Readonly<FieldProps<LinkButtonFieldConfig>>,
) {
    const { fieldName, fieldKit, fieldConfig } = props
    const isReadOnly = fieldConfig.prefilled

    return (
        <div>
            {!isReadOnly && (
                <button
                    data-testid={fieldName}
                    type="button"
                    onClick={() => {
                        fieldConfig.onClick(
                            fieldName,
                            fieldKit.values,
                            fieldKit,
                        )
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {fieldConfig.icon && (
                        <fieldConfig.icon
                            color={theme.colors.brand.rideOctaneBlue}
                            width={fieldConfig.iconWidth ?? 16}
                            height={fieldConfig.iconHeight ?? 16}
                            style={{ marginRight: '5px' }}
                        />
                    )}
                    {fieldConfig.label && <Label>{fieldConfig.label}</Label>}
                </button>
            )}
        </div>
    )
}
