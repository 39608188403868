import React from 'react'

import { FieldInstruction } from 'containers/FormBuilder'
import CheckboxField from 'form/fieldComponents/CheckboxField'

import LabelStyle from './styles/LabelStyle'

const tradeInIntent: FieldInstruction<typeof CheckboxField> = {
    Component: CheckboxField,
    config: {
        label: <LabelStyle>Customer has a trade-in</LabelStyle>,
        initialValue: false,
    },
}

export default tradeInIntent
