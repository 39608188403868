/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import { Button, Modal } from '@octane/spark'

import { ModalButton, Content } from './DisclosureModal.styled'

// eslint-disable-next-line max-len, no-empty-pattern
export default function GeneralApplicationModal(props: {
    showModal: boolean
    toggleModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const { showModal, toggleModal } = props

    return (
        <Modal
            title="General Application Disclosures"
            isOpen={showModal}
            onClose={/* istanbul ignore next */ () => toggleModal(false)}
            scroll="body"
        >
            <Modal.Body>
                <Content data-testid="modalContent-generalDisclosure">
                    <p>
                        This Application for Credit ("Application") for
                        Dealership’s customer (“Applicant”) is to Roadrunner
                        Financial, Inc. ("Roadrunner"). The Applicant has signed
                        a paper credit application and/or acknowledged an
                        electronic credit application stating that the
                        information provided is correct to the best of the
                        Applicant’s knowledge. The information contained in that
                        paper/electronic application was used to populate the
                        information as contained herein, and everything stated
                        is true and accurate to the best of Dealership’s
                        knowledge and belief. Based upon the written application
                        provided by the Applicant, the Dealership has the
                        authority under the Fair Credit Reporting Act to
                        authorize Roadrunner, its affiliates/subsidiaries and/or
                        its parent company, to obtain a consumer report from one
                        or more consumer reporting agencies and to check the
                        Applicant’s credit, employment history, or any other
                        information, and to report such information, and its
                        credit experience with Applicant, to others. The
                        Applicant is at least 18 years of age based upon
                        credible evidence provided to the Dealer. Alimony, child
                        support or separate maintenance income need not be
                        revealed if the Applicant does not wish to have it
                        considered as a basis for repaying this obligation.{' '}
                    </p>
                    <p>
                        Dealer understands that in order to initially check the
                        rates and terms that an Applicant qualified for, one or
                        more soft credit pulls will be done by Roadrunner and/or
                        its affiliates/subsidiaries, that do not affect the
                        Applicant’s credit score. However, in order to complete
                        the application at your dealership and generate the
                        Applicant's contract, a full credit report from one or
                        more consumer reporting agencies will be requested,
                        which is considered a hard credit pull and may affect
                        the Applicant’s credit. This is a requirement in order
                        to complete the contracting process and you agree that
                        you have already obtained the written consent of the
                        consumer to perform a hard credit pull should the
                        Application proceed to contracting.
                    </p>
                    <p>
                        Dealership hereby certifies that to the best of its
                        knowledge and belief, the property intended to be
                        purchased in connection with this application is for
                        personal use of the Applicant and is not intended to be
                        used/possessed by another (i.e. straw purchase); that
                        Applicant has been made aware that if the application is
                        approved and financing is provided, that they are fully
                        responsible for making all payments for such property;
                        that such property will be the Applicant’s possession or
                        under Applicant’s control until the amount financed and
                        all finance charges have been paid in full; and that
                        Applicant is not permitted to purchase any property
                        financed through Roadrunner for the benefit or use of
                        another without the prior written approval of
                        Roadrunner.
                    </p>
                    <p>
                        Important Information About Account Opening Procedures:
                        Federal law requires all financial institutions to
                        obtain, verify, and record information that identifies
                        each person who requests to open an account prior to
                        account opening. When you apply for credit on behalf of
                        the Applicant, we may ask for the Applicant’s name,
                        address, date of birth, and other information that will
                        allow us to identify the Applicant. We may also ask to
                        see the Applicant’s driver's license or other
                        identifying documents. Failure to provide the required
                        information may result in denial of the Applicant’s
                        request to open an account.
                    </p>
                    <p>
                        By providing the contact information of the Applicant,
                        such as phone number and/or email address, you hereby
                        certify that the Applicant(s) have given affirmative
                        consent for Roadrunner, its affiliates/subsidiaries,
                        and/or its parent company, to call, send text messages
                        (including calling and sending text messages using
                        equipment to automatically dial telephone numbers)
                        and/or send emails to Applicant for any other servicing
                        or informational purpose related to the Application. You
                        hereby certify that the Applicant understands and agrees
                        that Roadrunner may always communicate with the
                        Applicant in any manner permitted by law that does not
                        require Applicant's prior consent. You further agree
                        that any information provided in this Application may be
                        provided by Roadrunner, its affiliates/subsidiaries
                        and/or its parent company, to a 3rd party customer
                        relationship management (“CRM”) systems that is operated
                        on your behalf. You agree that you have the Applicant’s
                        consent to share Application information with those CRM
                        systems and that you are solely responsible for any
                        information that is shared with them at your request.
                        Furthermore, it is your responsibility to notify
                        Roadrunner, its affiliates/subsidiaries and/or its
                        parent company, of any changes to or discontinuance of
                        the use of any CRM system.
                    </p>
                    <p>
                        Other qualifications and restrictions may apply. Minimum
                        amount financed is $1,000. Maximum amount financed is
                        $50,000 (dependent on vehicle type). UCC filing fee may
                        be charged. Offers not valid for units used for
                        commercial or business purposes. Offers effective on
                        eligible and qualified units purchased from a
                        participating dealer. Offer valid in the U.S. only
                        (Excluding AK, HI & Wash. D.C.). Other financing offers
                        are available. OFFERS SUBJECT TO CHANGE WITHOUT NOTICE.
                    </p>
                    <p>
                        Dealer acknowledges that it will provide the required
                        Credit Score Disclosure as provided by Roadrunner to the
                        Applicant(s), prior to the generation of the contract
                        associated with this transaction.
                    </p>
                    <p>
                        <b>STATE SPECIFIC DISCLOSURES:</b> <br /> <br />
                        <b>NOTICE TO CALIFORNIA RESIDENTS:</b> A married
                        Applicant may apply for a separate account. After credit
                        approval, each Applicant shall have the right to use
                        their account to the extent of any credit limit set by
                        the creditor and each Applicant may be liable for all
                        amounts of credit extended under this account to each
                        joint Applicant.
                    </p>
                    <p>
                        <b>NOTICE TO MAINE RESIDENTS:</b> <br />
                        1) Applicant has the right to free choice in the
                        selection of the insurer through which the insurance is
                        to be placed, and <br />
                        2) obtaining insurance products from a particular agent
                        or broker does not affect the credit decisions by the
                        creditor or lender regarding the purchaser or borrower,
                        unless the insurance product violates the terms of the
                        extension of credit regarding the adequacy of coverage
                        or is otherwise not approved by the creditor.
                    </p>
                    <p>
                        <b>NOTICE TO NEW YORK AND RHODE ISLAND RESIDENTS:</b> A
                        consumer report may be ordered on the Applicant in
                        connection with the Application for credit. If
                        Applicants asks, we will tell them whether or not one
                        was ordered and if one was, the name and address of the
                        consumer reporting agency that provided it. Subsequent
                        consumer reports may be requested or used in connection
                        with an update, renewal or extension of the credit
                        applied for without further notice to Applicant.
                    </p>
                    <p>
                        <b>NOTICE TO OHIO RESIDENTS:</b> The Ohio laws against
                        discrimination require that all creditors make credit
                        equally available to all creditworthy customers, and
                        that credit reporting agencies maintain separate credit
                        histories on each individual upon request. The Ohio
                        Civil Rights Commission administers compliance with this
                        law.
                    </p>
                    <p>
                        <b>NOTICE TO VERMONT RESIDENTS:</b> You agree that the
                        Applicant has authorized us to obtain a consumer credit
                        report from one or more consumer credit reporting
                        agencies in connection with the Application and as
                        otherwise allowed by law. You agree that we may also
                        verify the Applicants employment, income, assets, and
                        debts. If the Application is approved and the contract
                        is entered into, you have obtained the consent of the
                        Applicant to also authorize us, and our employees and
                        agents, to obtain additional credit reports and other
                        information about the Applicant in connection with
                        updates, renewals, extensions, account review,
                        collections activity, or for any other legitimate
                        purpose.
                    </p>
                    <p>
                        <b>NOTICE TO MARRIED WISCONSIN RESIDENTS:</b> Wisconsin
                        law provides that no agreement, unilateral statement or
                        court decree relating to marital property shall
                        adversely affect a creditor's interest, unless prior to
                        the time credit is granted the creditor is furnished a
                        copy of the agreement, statement or decree or has actual
                        knowledge of the adverse provision.
                    </p>
                    <p>
                        <b>MILITARY LENDING ACT (MLA) DISCLOSURE:</b> Federal
                        law provides important protections to members of the
                        Armed Forces and their dependents relating to extensions
                        of consumer credit. In general, the cost of consumer
                        credit to a member of the Armed Forces and his or her
                        dependent may not exceed an annual percentage rate of 36
                        percent. This rate must include, as applicable to the
                        credit transaction or account: The costs associated with
                        credit insurance premiums; fees for ancillary products
                        sold in connection with the credit transaction; any
                        application fee charged (other than certain application
                        fees for specified credit transactions or accounts); and
                        any participation fee charged (other than certain
                        participation fees for a credit card account).
                    </p>
                    <p>
                        Federal law requires that you receive a clear
                        description of your required payments. Please review the
                        disclosures and your credit agreement carefully to
                        understand your payment obligations.
                    </p>
                </Content>
            </Modal.Body>
            <Modal.Footer>
                <ModalButton>
                    <Button
                        type="button"
                        variant="primaryOutlined"
                        onClick={() => toggleModal(false)}
                    >
                        Close
                    </Button>
                </ModalButton>
            </Modal.Footer>
        </Modal>
    )
}
