import React from 'react'

import { FormikCheckbox } from '../../components/FormikFields/FormikCheckbox/FormikCheckbox.styled'
import {
    CheckContainer,
    ResidenceParagraph,
    ErrorMessage,
} from './MarineWriteIn.styled'

interface QueryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
}

const primaryResidenceCheckboxLabel = (
    <ResidenceParagraph>
        This boat will not be their primary residence
    </ResidenceParagraph>
)

export default function PrimaryResidenceCheckbox({ formik }: QueryProps) {
    const touched = formik.touched.marine?.primaryResidence
    const error = formik.errors.marine?.primaryResidence
    const value = formik.values.marine?.primaryResidence
    const formValue = 'marine.primaryResidence'
    const displayError = !!(touched && error)

    return (
        <>
            <CheckContainer>
                <FormikCheckbox
                    disabled={false}
                    error={displayError}
                    id={formValue}
                    label=""
                    value={formValue}
                    checked={value}
                    onChange={(val) => {
                        const event = {
                            target: {
                                name: formValue,
                                value: val,
                            },
                        }

                        formik.handleChange(event)
                    }}
                />
                <label htmlFor={formValue}>
                    {primaryResidenceCheckboxLabel}
                </label>
            </CheckContainer>
            {displayError && <ErrorMessage>The field is required</ErrorMessage>}
        </>
    )
}
