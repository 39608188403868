import {
    FieldInstructionBundle,
    FormValues,
    FieldInstruction,
    FieldInstructionCreator,
    FieldComponent,
    ValidatorFunction,
    FieldKit,
    extendFieldInstructionAny,
} from 'containers/FormBuilder'

import {
    applicantFieldNames,
    coapplicantFieldNames,
    otherFieldNames,
} from './sharedInstructions'

/**
 * Utility function for creating a conditional validator
 * for a passed field instruction.
 * The validator of the passed field instruction will only be enforced when
 * `conditionGeneratingFunction` returns `true`.
 */
function buildConditionalValidator<C extends FieldComponent>(
    fieldInstruction: FieldInstruction<C>,
    conditionGeneratingFunction: (values: FormValues) => boolean,
): ValidatorFunction {
    if (!fieldInstruction.config.validator) {
        // if there is no validator leave it alone
        return fieldInstruction.config.validator
    }

    // create and return a new validation function
    return (values: FormValues) => {
        if (!conditionGeneratingFunction(values)) {
            return null // don't validate when conditional func returns false
        }

        return typeof fieldInstruction.config.validator === 'function'
            ? fieldInstruction.config.validator(values)
            : fieldInstruction.config.validator
    }
}

/**
 * Utility function for generating conditionally validated field instructions.
 * The generated field instructions will enforce the original validator only
 * when the provided `conditionGeneratingFunction` returns `true`.
 */
export function generateConditionallyValidatedFields(
    instructionBundle: FieldInstructionBundle,
    conditionGeneratingFunction: (values: FormValues) => boolean,
): FieldInstructionBundle {
    return Object.keys(instructionBundle).reduce<FieldInstructionBundle>(
        (acc, fieldName) => {
            const fieldInstruction = instructionBundle[fieldName]

            const newFieldInstruction = extendFieldInstructionAny(
                fieldInstruction,
                (instruction) => {
                    return {
                        ...instruction,
                        config: {
                            ...instruction.config,
                            validator: buildConditionalValidator(
                                instruction,
                                conditionGeneratingFunction,
                            ),
                        },
                    }
                },
            )

            acc[fieldName] = newFieldInstruction

            return acc
        },
        {},
    )
}

type FieldName = keyof typeof applicantFieldNames

/**
 * Utility function to create a new FieldInstructionBundle where each
 * field will control its `disabled` config attribute dynamically. The disabled
 * behavior will be determined by a function provided as an argument.
 *
 * Returns a FieldInstructionBundle where all values will be newly generated
 * FieldInstructionCreator functions.
 */
export function generateConditionallyDisabledFields(
    fieldInstructionBundle: FieldInstructionBundle,
    isDisabledFunc: (formValues: FormValues) => boolean,
) {
    return Object.keys(fieldInstructionBundle).reduce<
        FieldInstructionBundle<FieldInstructionCreator<FieldComponent>>
    >((acc, fieldName) => {
        const fieldInstructionAny = fieldInstructionBundle[fieldName]

        const newFieldInstruction = extendFieldInstructionAny(
            fieldInstructionAny,
            (instruction, formValues) => {
                return {
                    ...instruction,
                    config: {
                        ...instruction.config,
                        disabled: isDisabledFunc(formValues),
                    },
                }
            },
        )

        acc[fieldName] = newFieldInstruction

        return acc
    }, {})
}

function executeSwap(
    fieldKit: FieldKit,
    fieldNameKeys: Array<FieldName>,
    temporaryValues: FormValues,
) {
    fieldNameKeys.forEach((fieldNameKey) => {
        const applicantFieldName = applicantFieldNames[fieldNameKey]
        const coapplicantFieldName = coapplicantFieldNames[fieldNameKey]

        fieldKit.setFieldValue(
            applicantFieldName,
            temporaryValues[coapplicantFieldName],
        )

        fieldKit.setFieldTouched(applicantFieldName, false)

        fieldKit.setFieldValue(
            coapplicantFieldName,
            temporaryValues[applicantFieldName],
        )

        fieldKit.setFieldTouched(coapplicantFieldName, false)
    })
}

/**
 * Utility function to swap the fields for primary and coapplicant
 * Tabs need to be selected and clicked
 * Masked field (employment start) has to be selected and clicked
 * Coapplicant relationship has a different mapping when swapped
 * Address fields are copied unless address is the same
 */
export function swapApplicantsFunc(fieldKit: FieldKit) {
    const temporaryValues = { ...fieldKit.values }

    const addressFields: Array<FieldName> = [
        'street',
        'apartmentSuite',
        'city',
        'state',
        'zip',
    ]

    const personalFields: Array<FieldName> = Object.keys(
        applicantFieldNames,
    ).filter(
        (el) => !addressFields.includes(el as FieldName),
    ) as Array<FieldName>

    executeSwap(fieldKit, personalFields, temporaryValues)

    // both applicants can't be parents to each other
    if (fieldKit.values.relationOfCoapplicant === 'Parent') {
        fieldKit.setFieldValue(otherFieldNames.relationOfCoapplicant, 'Other')
    }

    // coapplicant address the same fields
    if (fieldKit.values.isCoapplicantAddressSame) {
        addressFields.forEach((fieldNameKey) => {
            fieldKit.setFieldValue(coapplicantFieldNames[fieldNameKey], '')
        })
    } else {
        executeSwap(fieldKit, addressFields, temporaryValues)
    }

    fieldKit.validateForm()
}

/**
 * Utility function to create a new FieldInstructionBundle where
 * the `prefilled` key of the `config` will be set to true for all
 * FieldInstructions within the FieldInstructionBundle.
 */
export function generateFieldInstructionsWithPrefill(
    instructionBundle: FieldInstructionBundle,
): FieldInstructionBundle {
    return Object.keys(instructionBundle).reduce<FieldInstructionBundle>(
        (acc, fieldName) => {
            const fieldInstruction = instructionBundle[fieldName]

            const newInstruction = extendFieldInstructionAny(
                fieldInstruction,
                (innerInstruction) => {
                    return {
                        ...innerInstruction,
                        config: {
                            ...innerInstruction.config,
                            prefilled: true,
                        },
                    }
                },
            )

            acc[fieldName] = newInstruction

            return acc
        },
        {},
    )
}

export const testExports = {
    buildConditionalValidator,
}
