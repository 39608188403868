import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function getUsers(page: number) {
    try {
        const { data } = await axios.get(
            `${config.API_BASE_URL}/api/v3/users/list-dealer-users-salespersons/?page=${page}`,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get users: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
