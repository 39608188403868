import React, { useEffect, useState } from 'react'

import getVehicles, {
    GetVehiclesOptions,
    Vehicle,
} from 'api/vehicles/getVehicles'
import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'
import { setFieldValueAndTouched } from 'form/fields/utils/fieldUtils'

function getDropdownName(vehicleNumber: number) {
    return vehicleNumber === 1
        ? 'vehicle.primary.selectedVehicleId'
        : 'vehicle.secondary.selectedVehicleId'
}

export function constructVehicleDropdownValue<
    V extends { id: number; vehicleType: string; uuid: string },
>(vehicle: V) {
    return `${vehicle.id}:${vehicle.vehicleType}:${vehicle.uuid}`
}

interface queryProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    lenderId?: number
    vehicleId?: number
    disable?: boolean
    dataSource?: string[]
    assetType?: unknown
    primaryVehicleId?: number
    vehicleNumber: number
    prefilled?: boolean
}

const defaultProps = {
    lenderId: null,
    vehicleId: null,
    disable: null,
    dataSource: null,
    assetType: null,
    primaryVehicleId: null,
    prefilled: false,
}

export default function VehicleDropdown({
    formik,
    lenderId,
    vehicleId,
    disable,
    dataSource,
    assetType,
    primaryVehicleId,
    vehicleNumber,
    prefilled,
}: queryProps) {
    const [response, updateResponse] = useState([] as Vehicle[])
    const { vehicle } = formik.values
    const { condition } = vehicle
    const DROPDOWN_NAME = getDropdownName(vehicleNumber)
    const FIRST_VEHICLE = 1
    const IS_FIRST_VEHICLE = vehicleNumber === FIRST_VEHICLE

    const vehicleFieldName = IS_FIRST_VEHICLE
        ? 'vehicle.primary.selectedVehicleId'
        : 'vehicle.secondary.selectedVehicleId'

    const year = IS_FIRST_VEHICLE
        ? vehicle.primary.year
        : vehicle.secondary.year

    const { makeId } = vehicle

    const modelId = IS_FIRST_VEHICLE
        ? vehicle.primary.modelId
        : vehicle.secondary.modelId

    const selectedVehicle = IS_FIRST_VEHICLE
        ? vehicle.primary.selectedVehicleId
        : vehicle.secondary.selectedVehicleId

    useEffect(() => {
        const abortController = new AbortController()

        const currentPrimaryVehicleValue =
            formik.values.vehicle.primary.selectedVehicleId

        const currentPrimaryVehicleValueParts =
            currentPrimaryVehicleValue.split(':')

        const currentPrimaryVehicleId = currentPrimaryVehicleValueParts[0]
        const currentVehicleTypeValue = currentPrimaryVehicleValueParts[1]

        if (condition && year && makeId && modelId) {
            const getVehiclesOptions = {
                condition,
                year,
                makeId,
                modelId,
                vehicleId,
                lenderId,
                disable,
                dataSource,
                assetType,
                primaryVehicleId,
            } as GetVehiclesOptions

            if (!IS_FIRST_VEHICLE) {
                // eslint-disable-next-line prefer-destructuring
                getVehiclesOptions.primaryVehicleId = currentPrimaryVehicleId
            }

            updateResponse([])

            getVehicles(getVehiclesOptions, abortController)
                .then((vehicles: Vehicle[]) => {
                    updateResponse(vehicles)

                    const currentSecondaryVehicleValue =
                        formik.values.vehicle.secondary.selectedVehicleId

                    const currentVehicleValue = IS_FIRST_VEHICLE
                        ? currentPrimaryVehicleValue
                        : currentSecondaryVehicleValue

                    const currentValueInResponse = vehicles.find(
                        (v) =>
                            constructVehicleDropdownValue(v) ===
                            currentVehicleValue,
                    )

                    if (!currentValueInResponse) {
                        formik.setFieldValue(DROPDOWN_NAME, '')

                        const currentVehicleTypeMatch =
                            formik.values.vehicle.vehicleType ===
                            currentVehicleTypeValue

                        if (IS_FIRST_VEHICLE && !currentVehicleTypeMatch) {
                            formik.setFieldValue('vehicle.vehicleType', '')
                        }
                    }

                    if (vehicles.length === 1) {
                        setFieldValueAndTouched(
                            formik,
                            vehicleFieldName,
                            constructVehicleDropdownValue(vehicles[0]),
                        )

                        setFieldValueAndTouched(
                            formik,
                            'vehicle.vehicleType',
                            vehicles[0].vehicleType,
                        )
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                .catch(() => {})
        }

        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition, year, makeId, modelId])

    if (
        (!modelId || !year || !makeId || modelId === '0') &&
        response.length < 1
    ) {
        return (
            <FormikDropdown
                name={DROPDOWN_NAME}
                label="Vehicle"
                placeholder={prefilled ? '' : 'Select Vehicle'}
                disabled
            >
                {{ name: '', value: '' }}
                {{ name: '', value: '' }}
            </FormikDropdown>
        )
    }

    return (
        <FormikDropdown
            name={DROPDOWN_NAME}
            label="Vehicle"
            placeholder={prefilled ? '' : 'Select Vehicle'}
            disabled={response.length === 0}
            error={selectedVehicle === null || selectedVehicle === ''}
            onChange={(val) => {
                if (IS_FIRST_VEHICLE) {
                    formik.setFieldValue(
                        'vehicle.vehicleType',
                        val.split(':')[1],
                    )
                }
            }}
            searchable
            searchPlaceholder="Search vehicles..."
            prefilled={prefilled}
        >
            {response.map((child: Vehicle) => {
                const newBorn: FormikDropdownChild = {
                    name: child.name,
                    value: constructVehicleDropdownValue(child),
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}

VehicleDropdown.defaultProps = defaultProps
