import React from 'react'

import { Button, UiDownloadReceiptIcon } from '@octane/spark'

import CloseButton from './CloseButton'
import {
    CloseButtonWrapper,
    DownloadButtonWrapper,
    Container,
    FileInfo,
    FileName,
    FileNameValue,
} from './Header.styled'
import saveUrlAsFile from './utils/saveUrlAsFile'

interface Props {
    fileName: string
    fileBlobUrl: string
    allowDownload: boolean
}

export default function Header({
    fileName,
    fileBlobUrl,
    allowDownload,
}: Props) {
    return (
        <Container paddingLeft={!allowDownload}>
            {allowDownload && (
                <DownloadButtonWrapper>
                    <Button
                        startIcon={UiDownloadReceiptIcon}
                        variant="primaryOutlined"
                        onClick={() => saveUrlAsFile(fileBlobUrl, fileName)}
                    >
                        Download
                    </Button>
                </DownloadButtonWrapper>
            )}

            <FileInfo>
                <FileName>
                    <FileNameValue>{fileName}</FileNameValue>
                </FileName>
            </FileInfo>
            <CloseButtonWrapper>
                <CloseButton />
            </CloseButtonWrapper>
        </Container>
    )
}
