import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export type AssignableUser = {
    id: number
    name: string
}

export default async function checkUserDeleteRequirements(userId: number) {
    const basePath = `${config.API_BASE_URL}/api/v3/users/${userId}`

    try {
        await axios.get(`${basePath}/check-salesperson-delete-requirements/`, {
            withCredentials: true,
        })

        return true
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to check user delete requirements: ${axiosError.message}`,
            axiosError,
            { userId },
        )

        throw axiosError
    }
}
