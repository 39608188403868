import { css } from 'styled-components'

/**
 * Hero Headings
 */
export const Hero60 = css`
    ${(props) => props.theme.fonts.Montserrat['s60-ln74-fw800']};
`

export const Hero48 = css`
    ${(props) => props.theme.fonts.Montserrat['s48-ln60-fw800']};
`

export const Hero36 = css`
    ${(props) => props.theme.fonts.Montserrat['s36-ln44-fw800']};
`

export const Hero24 = css`
    ${(props) => props.theme.fonts.Montserrat['s24-ln30-fw800']};
`

/**
 * Headings
 */
export const Heading24Bold = css`
    ${(props) => props.theme.fonts.Karla['s24-ln32-fw800']};
`

export const Heading20Bold = css`
    ${(props) => props.theme.fonts.Karla['s20-ln32-fw800']};
`

export const Heading16Bold = css`
    ${(props) => props.theme.fonts.Karla['s16-ln20-fw800']}
`

/**
 * Button CTA
 */
export const ButtonMedium16 = css`
    ${(props) => props.theme.fonts.Karla['s16-ln20-fw700']};
`

export const ButtonCompact16 = css`
    ${(props) => props.theme.fonts.Karla['s16-ln24-fw700']};
`

/**
 * Body Styles
 */
export const BodyCopy16 = css`
    ${(props) => props.theme.fonts.Karla['s16-ln25-fw400']};
`

export const BodyCopy12 = css`
    ${(props) => props.theme.fonts.Karla['s12-ln16-fw400']};
`

/**
 * Copy
 */

export const Copy48 = css`
    ${(props) => props.theme.fonts.Karla['s48-ln56-fw400']};
`

export const Copy24 = css`
    ${(props) => props.theme.fonts.Karla['s24-ln32-fw400']};
`

export const Copy20 = css`
    ${(props) => props.theme.fonts.Karla['s20-ln28-fw400']};
`

export const Copy16 = css`
    ${(props) => props.theme.fonts.Karla['s16-ln20-fw400']};
`

export const Copy14 = css`
    ${(props) => props.theme.fonts.Karla['s14-ln16-fw400']};
`

/**
 * Pulldown
 */
export const Pulldown16 = css`
    ${(props) => props.theme.fonts.Karla['s16-ln34-fw400']};
`

export const Copy16Bold = css`
    ${(props) => props.theme.fonts.Karla['s16-ln25-fw700']};
`

/**
 * Table
 */

export const TablePrimaryHeader13 = css`
    ${(props) => props.theme.fonts.Lato['s13-ln16-fw400']};
`

export const TableHoverText = css`
    ${(props) => props.theme.fonts.Lato['s15-ln18-fw700']};
`

/**
 * Banner
 */
export const BannerText = css`
    ${(props) => props.theme.fonts.Karla['s16-ln20-fw700']}
`
