import React, { useEffect } from 'react'

import { Checkbox } from '@octane/spark'

import {
    CardContent,
    CheckBoxContainer,
    CheckBoxContent,
    CollateralOptionContainer,
    CollateralDataContainer,
    CollateralData,
    TradeInQuestionContent,
} from '../ReturnCustomer.styled'
import { ActiveLoanType } from '../types/returnCustomerTypes'

type ComponentProps = {
    data: ActiveLoanType[] | undefined
    handleSelectLoan: (selectedLoanIds: string[]) => void
}

export default function SelectLoan({
    data,
    handleSelectLoan,
}: ComponentProps): JSX.Element | null {
    const [selectedLoans, setSelectedLoans] = React.useState<string[]>([])

    useEffect(() => {
        handleSelectLoan(selectedLoans)
    }, [selectedLoans, handleSelectLoan])

    const handleChange = (checked: boolean, loanId: string) => {
        // eslint-disable-next-line no-unused-expressions
        checked
            ? setSelectedLoans([...selectedLoans, loanId])
            : setSelectedLoans(selectedLoans.filter((item) => item !== loanId))
    }

    const isLoanSelected = (loanId: string) => selectedLoans.includes(loanId)
    /*
     * * Helps shorten unnecessarily long collateral names.
     * * We just need Year, Make, Model for vehicle identification purposes
     */
    const maxCollateralNameLength = 50 // Upper bound for number of characters in an collateral name.

    return data ? (
        <CardContent>
            <TradeInQuestionContent>
                Which vehicle(s) is the customer trading-in?
            </TradeInQuestionContent>
            <CheckBoxContent>
                {data.map((item) => (
                    <CollateralOptionContainer
                        key={`collateral-option: ${item.loanId}`}
                    >
                        <CheckBoxContainer
                            key={`checkbox-container: ${item.loanId}`}
                        >
                            <Checkbox
                                data-testid="check-box"
                                value=""
                                key={item.loanId}
                                onChange={(checked) => {
                                    handleChange(checked, item.loanId)
                                }}
                                checked={isLoanSelected(item.loanId)}
                            />
                        </CheckBoxContainer>
                        <CollateralDataContainer
                            key={`collateral-data-container: ${item.loanId}`}
                        >
                            <CollateralData
                                key={`collateral-data: ${item.loanId}`}
                            >
                                <strong>Loan ID: {item.loanId}</strong>
                            </CollateralData>
                            {item.collaterals.map((collateral) => (
                                <CollateralData key={collateral}>
                                    {collateral.substring(
                                        0,
                                        maxCollateralNameLength,
                                    )}
                                </CollateralData>
                            ))}
                        </CollateralDataContainer>
                    </CollateralOptionContainer>
                ))}
            </CheckBoxContent>
        </CardContent>
    ) : null
}
