import React from 'react'

import { ValidationInfo } from '@octane/spark'

import { Field } from './ValidationsField.styled'

export interface Validations {
    hasSymbols: boolean | null
    passwordsMatch: boolean | null
    hasMinCharacters: boolean | null
    hasNumbers: boolean | null
    hasLowerUpper: boolean | null
}

interface Props {
    validations: Validations
}

export default function ValidationsField({ validations }: Props): JSX.Element {
    function checkState(isValid: boolean | null) {
        if (isValid === null) return 'incomplete'

        return isValid ? 'complete' : 'error'
    }

    return (
        <Field>
            <ValidationInfo
                align="left"
                id="characters"
                text="Use at least 8 characters"
                state={checkState(validations.hasMinCharacters)}
            />
            <ValidationInfo
                align="left"
                id="upperAndLower"
                text="Use upper and lower case letters"
                state={checkState(validations.hasLowerUpper)}
            />
            <ValidationInfo
                align="left"
                id="numbers"
                text="Use 1 or more numbers"
                state={checkState(validations.hasNumbers)}
            />
            <ValidationInfo
                align="left"
                id="symbols"
                text="Use at least 1 symbol -!$%^*()_+|~+?"
                state={checkState(validations.hasSymbols)}
            />
            <ValidationInfo
                align="left"
                id="match"
                text="Passwords match"
                state={checkState(validations.passwordsMatch)}
            />
        </Field>
    )
}
