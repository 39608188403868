import React from 'react'
import { useNavigate } from 'react-router-dom'

import Card from 'components/Card'
import ChangePassword from 'containers/MyAccount/ChangePassword'

import { InnerContainer } from './ResetPasswordPage.styled'

export default function ResetPassword(): JSX.Element {
    const navigate = useNavigate()

    function goToSuccessPage() {
        navigate('/password-reset/success')
    }

    return (
        <InnerContainer>
            <Card title="Reset Password">
                <ChangePassword onSuccess={() => goToSuccessPage()} />
            </Card>
        </InnerContainer>
    )
}
