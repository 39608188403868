import React, { useEffect, useState } from 'react'

import { updateUser } from 'api/user'
import Select from 'components/Select'
import useUser from 'hooks/useUser'
import {
    passwordRotationOptions,
    sessionInactivityOptions,
} from 'utils/consts/securityOptions'
import toastConsts from 'utils/consts/toast'
import * as toast from 'utils/toast'

import { Container } from './SecuritySettingsForm.styled'
import { SecuritySettingsType } from './types'
import { buildSecuritySettingsUpdateToastMessage } from './utils'

const DEFAULT_PASSWORD_ROTATION_DAYS = '720'
const DEFAULT_INACTIVE_SESSION_MINUTES = '30'

export default function SecuritySettingsForm() {
    const { user } = useUser()

    const [passwordSecurity, setPasswordSecurity] = useState({
        passwordRotation: DEFAULT_PASSWORD_ROTATION_DAYS,
        sessionInactivity: DEFAULT_INACTIVE_SESSION_MINUTES,
    })

    useEffect(() => {
        if (user?.securitySettings) {
            setPasswordSecurity(user.securitySettings)
        }
    }, [user])

    const handleSecuritySettingsChange = async (
        key: keyof SecuritySettingsType,
        val: string,
    ) => {
        if (passwordSecurity[key] === val) return

        const oldSecuritySettingsObj = passwordSecurity

        const newObjSecurity = {
            ...passwordSecurity,
            [key]: val,
        }

        setPasswordSecurity(newObjSecurity)

        const { passwordRotation, sessionInactivity } = newObjSecurity

        const requestObject = {
            security_settings: {
                password_rotation: parseInt(passwordRotation, 10),
                session_inactivity: parseInt(sessionInactivity, 10),
            },
        }

        updateUser(requestObject).then(({ status }) => {
            if (status === 200) {
                toast.success({
                    title: toastConsts.settings.success.title,
                    message: buildSecuritySettingsUpdateToastMessage(
                        key,
                        oldSecuritySettingsObj,
                        newObjSecurity,
                    ),
                })
            } else {
                toast.error({
                    title: toastConsts.settings.error.title,
                    message: toastConsts.settings.error.message,
                })
            }
        })
    }

    return (
        <Container>
            <Select
                onChange={(val: string) =>
                    handleSecuritySettingsChange('passwordRotation', val)
                }
                value={passwordSecurity.passwordRotation}
                label="Require me to change my password every..."
                placeholder="Select something"
                id="dropdown-password-rotation"
                options={passwordRotationOptions}
            />

            <Select
                onChange={(val: string) =>
                    handleSecuritySettingsChange('sessionInactivity', val)
                }
                value={passwordSecurity.sessionInactivity}
                label="Log me out if I have been inactive for..."
                placeholder="Select something"
                id="dropdown-session-inactivity"
                options={sessionInactivityOptions}
            />
        </Container>
    )
}
