import { ResponseVehicle } from 'api/vehicles/getVehicle'
import axios, { AxiosError } from 'axios'
import config from 'config'

import { RawLeadActivity } from '.'
import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface ResponseApprovalTerm {
    id: number
    is_prime: boolean
    risk_tier: string
    risk_tier_label: string
    term: number
    vehicle_value: string
    apr: string
}

export interface ResponseDecisionBlock {
    id: number
    message?: string | null
    type: string
    approval_terms?: ResponseApprovalTerm[]
}
export interface ResponseClientApplication {
    id: number
    decision_blocks?: ResponseDecisionBlock[]
    misc_data: string
    primary_city: string
    primary_address: string
    primary_county: string
    primary_state: string
    primary_zip: string
    ssn1?: string | null
    ssn2?: string | null
    ssn3?: string | null
    social_security_number?: string | null
    status: string
    vehicle: string
    vehicle_make: string
    vehicle_model: string
    vehicle_vin?: string | null
    vehicle_year: number
    vehicles?: ResponseVehicle[]
    home_phone: string
    first_name: string
    last_name: string
    middle_name: string
    source: string
    source_action: string
    source_client: string
    uuid: string
    email_address: string
    work_phone?: string
    mobile_phone: string
    created: string | number
    progress: string
    last_lead_activity: RawLeadActivity | null
}

export interface ResponseMiscData {
    id?: number | null
    source?: string | null
    source_action?: string | null
    source_client?: string | null
    ssn1?: string | null
    ssn2?: string | null
    ssn3?: string | null
    social_security_number?: string | null
    vehicle?: string | null
    vehicle_vin?: string | null
    vehicle_condition?: string | null
    vehicle_make?: string | null
    vehicle_model?: string | null
    vehicle_year?: number | null
    vehicle_type?: string | null
    w_phone1?: string | null
    w_phone2?: string | null
    w_phone3?: string | null
    m_phone1?: string | null
    m_phone2?: string | null
    m_phone3?: string | null
    h_phone1?: string | null
    h_phone2?: string | null
    h_phone3?: string | null
    uuid?: string | null
    primary_zip?: string | null
    primary_state?: string | null
    primary_county?: string | null
    primary_city?: string | null
    primary_address?: string | null
    first_name?: string | null
    middle_name?: string | null
    last_name?: string | null
    home_phone?: string | null
    work_phone?: string | null
    mobile_phone?: string | null
    email_address?: string | null
}

export default async function getClientApplication(
    clientApplicationId: number,
    options?: {
        include_contracts?: boolean
        include_collaterals?: boolean
        include_input_verifications?: boolean
        include_income_verification?: boolean
        mark_messages_read?: boolean
        include_accessories?: boolean
        include_vehicle_lease_fees?: boolean
        include_vehicle_residual_informations?: boolean
        include_offers?: boolean
        include_vehicles?: boolean
    },
) {
    try {
        if (!clientApplicationId) {
            throw new Error(
                'Invalid clientApplicationId used with getClientApplication',
            )
        }

        const { data }: { data: ResponseClientApplication } = await axios.get(
            // eslint-disable-next-line max-len
            `${config.API_BASE_URL}/api/v1/client_applications/${clientApplicationId}/`,
            {
                withCredentials: true,
                params: {
                    include_offers: true,
                    include_vehicles: true,
                    include_contracts: true,
                    include_collaterals: true,
                    include_input_verifications: true,
                    include_income_verification: true,
                    mark_messages_read: true,
                    include_accessories: true,
                    include_vehicle_lease_fees: true,
                    include_vehicle_residual_informations: true,
                    ...(options ?? {}),
                },
            },
        )

        return data
    } catch (error) {
        OctaneLogger.axiosError(
            `Error getting leadDetails for clientApplicationId ${clientApplicationId}:`,
            error as AxiosError,
            {
                applicationId: clientApplicationId,
            },
        )

        throw error
    }
}
