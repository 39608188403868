import { theme as BaseTheme } from '@octane/spark'
import { DefaultTheme } from 'styled-components'

const rideNowTheme: DefaultTheme = {
    ...BaseTheme,
    colors: {
        ...BaseTheme.colors,
        brand: {
            ...BaseTheme.colors.brand,
            rideOctaneBlue: '#000000',
        },
        monochrome: {
            ...BaseTheme.colors.monochrome,
            moonGrey: '#f8f8fa',
        },
    },
    navbar: {
        background: '#f8f8fa',
        color: BaseTheme.colors.monochrome.graphite,
        hoverColor: BaseTheme.colors.monochrome.asphalt,
        loggedInNameColor: BaseTheme.colors.monochrome.jetlineBlack,
        loggedInHoverBackground: BaseTheme.colors.monochrome.smoke,
        border: true,
    },
}

export default rideNowTheme
