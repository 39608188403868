import { typography } from 'assets/styles'
import ApplicationStatus from 'state/enums/ApplicationStatus'
import styled from 'styled-components'

export const ComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const IconWrapper = styled.div.attrs(
    (props: { status: string }) => props,
)`
    div > svg > circle {
        fill: ${(props) =>
            (props.status === ApplicationStatus.APPROVED &&
                props.theme.colors.brand.rideOctaneGreen) ||
            (props.status === ApplicationStatus.DECLINE &&
                props.theme.colors.alert.error) ||
            (props.status === ApplicationStatus.FUNDED &&
                props.theme.colors.label.sapphire) ||
            (props.status === ApplicationStatus.NEW_APPLICATION &&
                props.theme.colors.monochrome.concrete) ||
            (props.status === ApplicationStatus.CONTRACT_GENERATED &&
                props.theme.colors.label.fuchsia) ||
            (props.status === ApplicationStatus.PREQUALIFIED &&
                props.theme.colors.brand.rideOctaneGreen) ||
            props.theme.colors.monochrome.concrete};
    }
`

export const DateText = styled.div`
    ${typography.BodyCopy16};
    color: ${(props) => props.theme.colors.monochrome.graphite};

    @media print {
        color: black;
    }
`
