import React, { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'

import {
    Spinner,
    IconComponent,
    CepCreditReportIcon,
    CepCalculationIcon,
    CepDocsPendingIcon,
    CepPaperworkIcon,
} from '@octane/spark'

import {
    AnimationWrapper,
    Card,
    CarouselContainer,
    Copy,
    IconWrapper,
    SpinnerWrapper,
    Title,
} from './AnimatedSecondCheckpointBody.styled'

const DEFAULT_CARD_INTERVAL = 15

type CardProps = {
    header: string
    content: string
    icon: IconComponent
    visible?: boolean
    fadeIn?: boolean
    dataTestId?: string
    iconAdjustments?: {
        paddingTop?: number
        paddingBotton?: number
        size?: number
    }
}

const cards: CardProps[] = [
    {
        header: 'Initiating Soft Credit Pull',
        content: 'Your customer’s credit will not be impacted.',
        icon: CepCreditReportIcon,
        iconAdjustments: {
            size: 150,
            paddingTop: 5,
            paddingBotton: -5,
        },
    },
    {
        header: 'Calculating Octane Score',
        content: 'Our Octane scoring model considers more than credit score.',
        icon: CepCalculationIcon,
        iconAdjustments: {
            size: 116,
            paddingBotton: 16,
            paddingTop: 18,
        },
    },
    {
        header: 'Decisioning Application',
        content: 'We’re considering your customer for all of our programs.',
        icon: CepDocsPendingIcon,
        iconAdjustments: {
            size: 116,
            paddingBotton: 16,
            paddingTop: 18,
        },
    },
    {
        header: 'Loading Your Results',
        content: 'Just adding the finishing touches.',
        icon: CepPaperworkIcon,
    },
]

function LoadingModalCard(props: CardProps) {
    const {
        header,
        content,
        icon: CardIcon,
        visible,
        fadeIn,
        iconAdjustments,
        dataTestId,
    } = props

    const cardRef = useRef<HTMLDivElement>(null)
    const iconSize = iconAdjustments?.size ?? 150

    return (
        <Transition
            nodeRef={cardRef}
            in={visible}
            timeout={1100}
            appear={fadeIn}
        >
            {(transtionStatus) => (
                <Card ref={cardRef} data-testid={dataTestId}>
                    <AnimationWrapper transtionStatus={transtionStatus}>
                        <IconWrapper
                            paddingTop={iconAdjustments?.paddingTop}
                            paddingBotton={iconAdjustments?.paddingBotton}
                        >
                            <CardIcon height={iconSize} width={iconSize} />
                        </IconWrapper>
                        <Title>{header}</Title>
                        <Copy>{content}</Copy>
                    </AnimationWrapper>
                </Card>
            )}
        </Transition>
    )
}

LoadingModalCard.defaultProps = {
    visible: false,
    fadeIn: true,
    iconAdjustments: undefined,
    dataTestId: undefined,
}

function AnimatedSecondCheckpointBody(props: { interval?: number }) {
    const { interval } = props
    const [cardIdx, setCardIdx] = useState<number>(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCardIdx((currentCardIdx: number) => {
                const newCardIdx = currentCardIdx + 1

                if (newCardIdx >= cards.length - 1) {
                    clearInterval(intervalId)

                    return cards.length - 1
                }

                return newCardIdx
            })
        }, (interval ?? DEFAULT_CARD_INTERVAL) * 1000)

        return () => clearInterval(intervalId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <CarouselContainer data-testid="SecondCheckpointModalContent">
            {cards.map((cardProps, index) => (
                <LoadingModalCard
                    {...cardProps}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`card-${index}`}
                    visible={index === cardIdx}
                    fadeIn={index !== 0}
                    dataTestId={`card-${index}`}
                />
            ))}
            <SpinnerWrapper>
                <Spinner size={40} background="grey" colored shadow />
            </SpinnerWrapper>
        </CarouselContainer>
    )
}

AnimatedSecondCheckpointBody.defaultProps = {
    interval: DEFAULT_CARD_INTERVAL,
}

export default AnimatedSecondCheckpointBody
export { LoadingModalCard }
export type { CardProps }
