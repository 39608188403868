import Card from 'components/Card'
import styled from 'styled-components'

import { typography } from '../../assets/styles'

export const Title = styled.h1`
    ${typography.Hero36};
    padding: 0;
`

export const Titlebar = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 40px 0 0px 0;
    padding: 0;
    align-items: center;
`

export const Container = styled.div`
    margin: 20px 0 10px 0;
    padding: 0;
`

export const StyledCard = styled(Card)`
    padding: 0px;
`
