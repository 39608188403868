import React, { useEffect } from 'react'

import { TextInput } from '@octane/spark'
import type { TextInputProps } from '@octane/spark'

export default function TextInputWithAutoFocus({ ...props }: TextInputProps) {
    useEffect(() => {
        setTimeout(() => {
            document.getElementById(props.id)?.focus()
        }, 50)
    })

    return <TextInput {...props} />
}
