import React, { useEffect } from 'react'

import { FileViewerContainer } from './FileViewer.styled'
import { useFileViewer } from './hooks'
import ImageViewer from './ImageViewer'
import PdfViewer from './PdfViewer'
import Toolbar from './Toolbar'

export interface Props {
    documentUrl: string
    documentName: string
    blockDownload?: boolean
    documentDownloadUrl?: string
    cbLoadEnd?: () => void
}

export default function FileViewer({
    documentUrl,
    documentName,
    blockDownload,
    documentDownloadUrl,
    cbLoadEnd,
}: Props): JSX.Element {
    const {
        imageRef,
        containerRef,
        containerHeight,
        isImage,
        loaded,
        image,
        numberOfPages,
        page,
        pdfRef,
        canvasRef,
        handlePageLoad,
        nextPage,
        prevPage,
        openPage,
        handleFileLoad,
        handlePdfLoad,
        handleImgDragging,
        handleImgWheel,
        handleContextMenu,
        handleContainerMouseOver,
        handleContainerMouseLeave,
        defineZoomValue,
        handleZoomChange,
        rotate,
        zoom,
        fitScreen,
    } = useFileViewer(documentName)

    useEffect(() => {
        if (cbLoadEnd && loaded === true) cbLoadEnd()
    }, [cbLoadEnd, loaded])

    function renderTools() {
        return (
            <Toolbar
                blockDownload={blockDownload}
                defineZoomValue={defineZoomValue}
                documentName={documentName}
                documentDownloadUrl={documentDownloadUrl ?? documentUrl}
                fitScreen={fitScreen}
                image={image}
                handleZoomChange={handleZoomChange}
                nextPage={nextPage}
                numberOfPages={numberOfPages}
                page={page}
                prevPage={prevPage}
                rotate={rotate}
                zoom={zoom}
            />
        )
    }

    function renderImageViewer() {
        return (
            <ImageViewer
                imageRef={imageRef}
                documentUrl={documentUrl}
                blockDownload={blockDownload}
                isLandscape={image.isLandscape}
                handleContextMenu={handleContextMenu}
                handleFileLoad={handleFileLoad}
                handleImgDragging={handleImgDragging}
                handleImgWheel={handleImgWheel}
                containerRef={containerRef}
            />
        )
    }

    function renderPdfViewer() {
        return (
            <PdfViewer
                containerRef={containerRef}
                pdfRef={pdfRef}
                image={image}
                handleContextMenu={handleContextMenu}
                handlePageLoad={handlePageLoad}
                documentUrl={documentUrl}
                numberOfPages={numberOfPages}
                openPage={openPage}
                page={page}
                containerHeight={containerHeight}
                blockDownload={blockDownload}
                handleImgWheel={handleImgWheel}
                handlePdfLoad={handlePdfLoad}
                canvasRef={canvasRef}
            />
        )
    }

    return (
        <FileViewerContainer
            isLoaded={loaded}
            onMouseOver={handleContainerMouseOver}
            onMouseLeave={handleContainerMouseLeave}
        >
            {isImage && (
                <>
                    {renderTools()}
                    {renderImageViewer()}
                </>
            )}
            {!isImage && (
                <>
                    {renderTools()}
                    {renderPdfViewer()}
                </>
            )}
        </FileViewerContainer>
    )
}

FileViewer.defaultProps = {
    blockDownload: true,
    documentDownloadUrl: undefined,
    cbLoadEnd: () => undefined,
}
