export default function relativeDays(date1: Date, date2: Date) {
    const diffInMs = Math.abs(date2.valueOf() - date1.valueOf())
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

    if (diffInDays === 0) {
        return 'Today'
    }

    if (diffInDays === 1) {
        return `${diffInDays} day ago`
    }

    return `${diffInDays} days ago`
}
