import styled from 'styled-components'

export const Container = styled.div`
    z-index: 1001;
    background-color: #323639;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
    display: flex;

    button,
    a {
        width: 40px;
        height: 40px;
        font-size: 0.6em;
        padding: 1em;
        background-color: transparent;
        border: none;
        margin: 2px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }

        svg {
            pointer-events: none;
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    aside {
        width: 220px;
        display: flex;
        float: left;
        svg {
            width: 12px;
        }
        span {
            color: #fff;
            background-color: rgba(0, 0, 0, 0.333);
            padding: 1px 10px;
            height: 22px;
            display: block;
            margin-top: 10px;
            width: 8em;
            text-align: center;
            margin-left: 5px;
            margin-right: 5px;
            small {
                margin-right: 5px;
            }
        }
    }
`

export const ToolbarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    input {
        background-color: #0005;
        text-align: center;
        width: 4em;
        margin: 0 0.5em;
        color: #fff;
        border: none;
        &:focus-visible {
            outline: none;
        }
    }
`

export const ToolbarDivisor = styled.div`
    display: block;
    width: 1px;
    height: 1.5em;
    background-color: #fff5;
    margin: 0 0.8em 0 0.5em;
`

export const ToolbarSupport = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
`
