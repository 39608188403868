import React from 'react'

import { FieldInstruction } from 'containers/FormBuilder'
import * as Yup from 'yup'

import SelectField from '../fieldComponents/SelectField'

export const suffix: FieldInstruction<typeof SelectField> = {
    Component: SelectField,
    config: {
        label: 'Suffix',
        placeholder: 'Select Suffix',
        options: [
            /*
            this &nbsp; is an empty value to allow the user to clear the field
            using an empty string will cause the option to render too short
            */
            { value: '', name: <>&nbsp;</> },
            { value: 'Jr.', name: 'Jr.' },
            { value: 'Sr.', name: 'Sr.' },
            { value: 'I', name: 'I' },
            { value: 'II', name: 'II' },
            { value: 'III', name: 'III' },
            { value: 'IV', name: 'IV' },
            { value: 'V', name: 'V' },
            { value: 'VI', name: 'VI' },
            { value: 'VII', name: 'VII' },
            { value: 'VIII', name: 'VIII' },
            { value: 'IX', name: 'IX' },
            { value: 'X', name: 'X' },
        ],
        validator: Yup.string(),
    },
}
