import React from 'react'

import { FieldProps, Field } from 'formik'

import { AnimatedField, FormikInput } from './FormikTextArea.styled'

type FormikTextAreaProps = {
    name: string
    label: string
    resize?: boolean
    disabled?: boolean
    placeholder?: string
} & typeof defaultProps

const defaultProps = {
    disabled: false,
    placeholder: '',
    resize: false,
}

function FormikTextArea({
    name,
    placeholder,
    label,
    disabled,
    resize,
}: FormikTextAreaProps) {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                return (
                    <AnimatedField>
                        <FormikInput
                            resize={resize}
                            disabled={
                                disabled ||
                                form.values.disabledFields?.includes(field.name)
                            }
                            id={field.name}
                            name={field.name}
                            label={label}
                            value={field.value}
                            onChange={(val: string) => {
                                const event = {
                                    target: {
                                        name,
                                        value: val,
                                    },
                                }

                                form.handleChange(event)
                            }}
                            onBlur={form.handleBlur}
                            placeholder={placeholder}
                            data-testid={name}
                        />
                    </AnimatedField>
                )
            }}
        </Field>
    )
}

FormikTextArea.defaultProps = defaultProps

export default FormikTextArea
