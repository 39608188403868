import React from 'react'
import { Outlet } from 'react-router-dom'

import ExpiredToken from './ExpiredToken'
import { Container } from './Invitation.styled'
import LoggedIn from './LoggedIn'
import ValidateToken from './ValidateToken'
import Welcome from './Welcome'

export const NestedInvitationRoutes = [
    {
        index: true,
        element: <ValidateToken />,
        path: '',
    },
    {
        index: true,
        element: <Welcome />,
        path: 'welcome',
    },
    {
        index: false,
        element: <LoggedIn />,
        path: 'logged-in',
    },
    {
        index: false,
        element: <ExpiredToken />,
        path: 'expired',
    },
]

export default function Invitation(): JSX.Element {
    return (
        <Container>
            <Outlet />
        </Container>
    )
}
