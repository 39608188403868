export function transformMonthDayYearStringToDate(
    _value: string,
    originalValue: string,
) {
    if (typeof originalValue !== 'string') return originalValue

    const ogValueDateArray = originalValue.split(' / ')

    if (ogValueDateArray.length !== 3) return null

    if (ogValueDateArray[2].length < 4) return null

    const newValue = new Date(
        `${ogValueDateArray[0]}/${ogValueDateArray[1]}/${ogValueDateArray[2]}`,
    )

    return newValue
}
