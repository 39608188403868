import React from 'react'

import { Disclosure } from '@octane/spark'
import DisclosureType from 'state/enums/DisclosureType'

import {
    FieldConfigBase,
    FieldKit,
    FieldProps,
    FormValues,
} from '../../containers/FormBuilder'

interface DisclosureFieldConfig extends FieldConfigBase {
    content: JSX.Element
    disabled?: boolean
    prefilled?: boolean
    onChange?: (
        value: boolean,
        content: JSX.Element,
        fieldName: string,
        formValues: FormValues,
        fieldKit: FieldKit,
        disclosure?: string,
    ) => void
    disclosureName?: DisclosureType
}

export default function DisclosureField(
    props: FieldProps<DisclosureFieldConfig>,
): JSX.Element {
    const { fieldName, fieldKit, fieldConfig } = props
    const touched = fieldKit.touched[fieldName]
    const error = fieldKit.errors[fieldName]
    const value = fieldKit.values[fieldName]
    const disclosure = fieldConfig.disclosureName

    return (
        <Disclosure
            checkBox
            content={fieldConfig.content}
            disabled={fieldConfig.disabled}
            prefilled={fieldConfig.prefilled}
            error={!!(touched && error)}
            size="small"
            id={fieldName}
            value={fieldName}
            checked={value.checked || fieldConfig.prefilled}
            onChange={(checked) => {
                if (fieldConfig.onChange) {
                    fieldConfig.onChange(
                        value.checked,
                        fieldConfig.content,
                        fieldName,
                        fieldKit.values,
                        fieldKit,
                        disclosure,
                    )
                } else {
                    const event = {
                        target: {
                            name: fieldName,
                            value: {
                                disclosure,
                                checked,
                            },
                        },
                    }

                    fieldKit.handleChange(event)
                }
            }}
            data-testid={fieldName}
        />
    )
}
