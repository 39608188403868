import React, { useEffect } from 'react'

import { CepBrokenRobotIcon } from '@octane/spark'
import ErrorLayout from 'components/ErrorLayout'
import { OctaneLogger, LogContextType } from 'integrations/datadog/OctaneLogger'

import { Container } from './ErrorPage.styled'

export interface ErrorData {
    error?: Error
    context?: LogContextType
}

const errorTimeoutMs = 1000 * 2
let latestErrorData: ErrorData | null = null
let errorTimeout: NodeJS.Timeout | null = null

interface ErrorPageProps {
    title: string
    body: string
    errorData?: ErrorData
    navigateHistorySteps?: number
}

const defaultProps = {
    errorData: undefined,
    navigateHistorySteps: 0,
}

function tryLogError(message: string, data: ErrorData) {
    if (errorTimeout !== null) {
        return
    }

    if (!data || data === latestErrorData) {
        return
    }

    latestErrorData = data

    errorTimeout = setTimeout(() => {
        errorTimeout = null

        OctaneLogger.error(message, data.context, data.error)
    }, errorTimeoutMs)
}

export default function ErrorPage({
    title,
    body,
    errorData,
    navigateHistorySteps,
}: ErrorPageProps) {
    useEffect(() => {
        if (errorData) {
            const message = `${title}: ${body}`.trim()

            tryLogError(message, errorData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container>
            <ErrorLayout
                header={{
                    icon: CepBrokenRobotIcon,
                    title,
                    body,
                }}
                footer={{
                    supportPhoneNumber: '(646) 370-5471',
                    supportEmail: 'support@octanelending.com',
                }}
                navigateHistorySteps={navigateHistorySteps}
            />
        </Container>
    )
}

ErrorPage.defaultProps = defaultProps
