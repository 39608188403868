import { getMfaFactors } from 'api/user/mfa'

export interface Factor {
    id: number
    type: string
    status: string
    is_primary: boolean
    value: string
}

export default async function getFactors(): Promise<Factor[]> {
    return getMfaFactors().then((response) => response?.data)
}
