import styled from 'styled-components'

export const DefaultEmailText = styled.label`
    font-weight: bold;
    margin-left: 8px;
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-height: 20px;
`

export const NotificationDefaultEmailContainer = styled.div`
    display: flex;
    align-items: center;
`
