import { FlexLink } from '../DealershipState'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ParseFlexLinks(payload: any): FlexLink {
    return {
        id: payload.id,
        dealershipId: payload.dealership.id,
        label: payload.label,
        isPrimary: payload.in_store,
        dealerUserId: payload.dealer_user?.id,
        salesPersonId: payload.salesperson?.id,
        link: payload.flex_link,
        qrCodeUrl: payload.qr_code,
    }
}
