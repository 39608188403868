import React, { useState } from 'react'

import { Modal, Button, CepWarningIcon } from '@octane/spark'
import { cancelInvitation } from 'api/invitation'
import { ApiResponse } from 'api/types'
import { AxiosError } from 'axios'
import useAppDispatch from 'hooks/useAppDispatch'
import { loadInvitations } from 'state/invitation/actions'
import * as toast from 'utils/toast'

import { Form, Field } from './UserInvitationCancelModal.styled'

type UserInvitationCancelModalProps = {
    isOpen: boolean
    onModalClose: () => void
    token: string | undefined
}

function UserInvitationCancelModal({
    isOpen,
    onModalClose,
    token,
}: UserInvitationCancelModalProps): JSX.Element {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)

    function cancelUserInvitation() {
        if (token) {
            setLoading(true)

            cancelInvitation(token)
                .then((response: ApiResponse) => {
                    toast.success({
                        message: response.message || '',
                    })

                    dispatch(loadInvitations())
                })
                .catch((error: AxiosError) => {
                    toast.error({
                        message: error.message || '',
                    })
                })
                .finally(() => {
                    setLoading(false)
                    onModalClose()
                })
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onModalClose} scroll="paper">
            <Modal.Body>
                <Form>
                    <CepWarningIcon width={140} height={140} color="" />
                    <h2>Sure you want to cancel this invitation?</h2>
                    <Field>
                        <Button
                            fullWidth
                            onClick={() => cancelUserInvitation()}
                            loading={loading}
                        >
                            Yes, cancel this invitation
                        </Button>
                        <Button
                            fullWidth
                            variant="white"
                            onClick={() => onModalClose()}
                        >
                            No, don’t cancel it
                        </Button>
                    </Field>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UserInvitationCancelModal
