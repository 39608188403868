import React from 'react'

import GoodSamLogoWhite from 'assets/img/goodsam-logo-white.svg'
import GoodSamLogo from 'assets/img/goodsam-logo.svg'
import goodSamTheme from 'themes/goodSamTheme'

import { Brand, BrandConfig } from '../types'

const goodSamConfig: BrandConfig = {
    brand: Brand.GoodSam,
    name: 'Good Sam',
    hostRegex: /goodsam\..*/,
    theme: goodSamTheme,
    logo: GoodSamLogo,
    navbarLogo: GoodSamLogoWhite,
    hideNavbarAtLogin: true,
    loginHeaderTitle: 'Financial Portal',
    loginLogoWidth: 300,
    whitelabelId: 'goodSam',
    footerContent: (
        <>
            GOOD SAM&reg; and the GOOD SAM ICON&reg; are registered trademarks
            of Good Sam Enterprises, LLC. Octane&reg;, Roadrunner Financial&reg;
            and Roadrunner Account Services&reg; are registered service marks of
            Octane Lending, Inc.&reg; Good Sam Financing is the brand name for a
            program that offers certain consumer credit plans extended by
            participating dealers to borrowers for the purchase of Recreational
            Vehicles from Good Sam Enterprises, LLC affiliated dealers.
            Financing for these programs is provided by Roadrunner Financial,
            Inc. (NMLS ID 1525116) without regard to race, color, religion,
            national origin, sex or familial status. Roadrunner Account
            Services, LLC (NMLS ID 1758660) services these loans on behalf of
            Roadrunner Financial, Inc. Roadrunner Financial, Inc. and Roadrunner
            Account Services, LLC are subsidiaries of Octane Lending, Inc.
        </>
    ),
    faviconPath: `${window.location.origin}/img/favicons/GoodSam_Favicon.svg`,
    pageTitle: 'Good Sam Powered by Octane Dealer Portal',
}

export default goodSamConfig
