import styled, { createGlobalStyle, css } from 'styled-components'

export const GlobalStyle = createGlobalStyle<{ isOpen: boolean }>`
    ${({ isOpen }) =>
        isOpen &&
        css`
            html {
                overflow-y: hidden;
            }
        `}
`

export const ViewPortContainer = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 99999999999;
    overflow-y: hidden;
`

export const ContainerModal = styled.div<{ isVisible: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    position: relative;
    z-index: 10;
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'collapse')};
`

export const ContainerLoading = styled.div<{ state: string }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    transition: 0.2s;
    opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`

export const ContainerAlerts = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
`

export const Overlay = styled.div<{ state: string }>`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: ${({ theme }) => theme.colors.monochrome.white};
    transition: 0.2s;
    opacity: ${({ state }) => (state === 'entered' ? 0.9 : 0)};
    z-index: 1;
`
