import { createAsyncThunk } from '@reduxjs/toolkit'
import { acceptInvitation as acceptInvitationApi } from 'api/invitation'

interface ParamsType {
    token: string
    password: string
    phone: string
}

const acceptInvitation = createAsyncThunk(
    'invitation/accept',
    async (params: ParamsType) => {
        const { token, password, phone } = params

        return acceptInvitationApi(token, password, phone)
    },
)

export default acceptInvitation
