import React, { useState } from 'react'

import {
    CepApprovedIcon,
    Label,
    Spinner,
    Button,
    TextLink,
    NoticeBox,
    VsExclamationIcon,
    NoticeBoxColors,
    NoticeBoxVariants,
} from '@octane/spark'

import { ApprovalTerm } from 'api/application'
import ClientApplication from 'api/application/interfaces/ClientApplication'
import config from 'config'
import useApprovalTerms from 'hooks/useApprovalTerms'
import useLenderDisclosure from 'hooks/useLenderDisclosure'
import DisclosureType from 'state/enums/DisclosureType'
import { formatCurrency } from 'utils/formatCurrency'

import {
    Container,
    ApprovedTitle,
    BodyText,
    FinePrint,
    RuleLine,
    DisclosureSpinnerWrapper,
    DisclosureContainer,
    DisclosureTitle,
    DisclosureParagraph,
} from './DecisionResult.styled'

const PRIME_RISK_TIERS = ['1', '2']

const hasPrimeRiskTier = (approvalTerms: ApprovalTerm[]) => {
    return approvalTerms.some((approvalTerm: ApprovalTerm) =>
        PRIME_RISK_TIERS.includes(approvalTerm.risk_tier),
    )
}

const getMaxFinancedTerm = (approvalTerms: ApprovalTerm[]) => {
    return approvalTerms.reduce(
        (maxFinancedTerm, approvalTerm: ApprovalTerm) => {
            const computedMaxFinance = Number(approvalTerm.computed_max_finance)

            if (!maxFinancedTerm) {
                return approvalTerm
            }

            if (
                computedMaxFinance >
                Number(maxFinancedTerm.computed_max_finance)
            ) {
                return approvalTerm
            }

            return maxFinancedTerm
        },
        null,
    )
}

const getMinAPR = (approvalTerms: ApprovalTerm[]) => {
    return approvalTerms.reduce((min, approvalTerm: ApprovalTerm) => {
        const apr = Number(approvalTerm.apr)

        return apr < min ? apr : min
    }, 1000)
}

export default function DecisionResultApproved({
    clientApplication,
}: {
    clientApplication: ClientApplication
}): JSX.Element {
    const {
        disclosureText,
        error: disclosureError,
        loading: disclosureLoading,
    } = useLenderDisclosure(
        DisclosureType.CREDIT_SOFT_PULL_APPLICATION_APPROVAL,
        config.ROADRUNNER_LENDER_ID,
        clientApplication.id,
    )

    const [showDisclosure, setShowDisclosure] = useState(false)
    const { approvalTerms, loading } = useApprovalTerms(clientApplication?.uuid)
    const isPrime = hasPrimeRiskTier(approvalTerms)
    const maxFinancedTerm = getMaxFinancedTerm(approvalTerms)
    const minAPR = getMinAPR(approvalTerms)

    const hasOemAccessoriesAdvance =
        Number(maxFinancedTerm?.additional_oem_accessories_dollar_amount) > 0

    const handleClickReadDisclosure = () => {
        setShowDisclosure(true)
    }

    const renderDisclosure = () => {
        if (!showDisclosure) {
            return null
        }

        if (disclosureError) {
            return (
                <DisclosureContainer>
                    <NoticeBox
                        // eslint-disable-next-line max-len
                        content="An error occurred when loading the disclosure. Refresh the page to try again or contact support."
                        heading=""
                        icon={VsExclamationIcon}
                        color={NoticeBoxColors.ERROR}
                        variant={NoticeBoxVariants.BOX_HIGHLIGHTED}
                    />
                </DisclosureContainer>
            )
        }

        if (disclosureLoading || !disclosureText) {
            return (
                <DisclosureSpinnerWrapper data-testid="DecisionResultApproved-Disclosure-spinner">
                    <Spinner size={24} />
                </DisclosureSpinnerWrapper>
            )
        }

        return (
            <DisclosureContainer>
                <RuleLine />
                <DisclosureTitle>Soft Credit Pull Disclosure</DisclosureTitle>
                <div>
                    {disclosureText.split('\n').map((paragraph, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <DisclosureParagraph key={index}>
                            {paragraph}
                        </DisclosureParagraph>
                    ))}
                </div>
            </DisclosureContainer>
        )
    }

    return (
        <Container data-testid="DecisionResultApproved">
            <CepApprovedIcon />
            <ApprovedTitle>Approved</ApprovedTitle>

            {loading ? (
                <div data-testid="DecisionResultApproved-spinner">
                    <Spinner size={24} />
                </div>
            ) : (
                <>
                    {isPrime && (
                        <Label
                            color="success"
                            text="PRIME No Income Verification Required"
                        />
                    )}
                    <BodyText>
                        Total Financed{' '}
                        <strong>
                            {formatCurrency(
                                maxFinancedTerm?.computed_max_finance,
                            )}
                            {hasOemAccessoriesAdvance
                                ? ' + OEM Accessories'
                                : ''}
                        </strong>
                        <br />
                        Rates as low as <strong>{minAPR}%</strong>
                    </BodyText>
                    <a href={`#/application/status/${clientApplication?.id}`}>
                        <Button>Work the Deal</Button>
                    </a>

                    <FinePrint>
                        Applicants are subject to a full credit approval.{' '}
                        <TextLink
                            onClick={handleClickReadDisclosure}
                            data-testid="DecisionResultApproved-read-disclosure-link"
                        >
                            Read Disclosure
                        </TextLink>
                    </FinePrint>

                    {renderDisclosure()}
                </>
            )}
        </Container>
    )
}
