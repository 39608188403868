import React from 'react'

import { Modal, Button, CepWarningIcon } from '@octane/spark'

import {
    Wrapper,
    Title,
    Description,
    CancelButton,
} from './RemoveLenderModal.styled'

interface Props {
    isOpen: boolean
    handleOnClickRemoveButton: () => void
    onClose: () => void
}

export function RemoveLenderModal({
    isOpen,
    onClose,
    handleOnClickRemoveButton,
}: Props): JSX.Element {
    return (
        <Modal isOpen={isOpen} scroll="paper" size="medium" onClose={onClose}>
            <Modal.Body>
                <Wrapper>
                    <CepWarningIcon width={128} height={128} />
                    <Title>Sure you want to remove this lender?</Title>
                    <Description>
                        Once you remove this you will not be able to send new
                        applications to this lender without adding them again.
                    </Description>
                </Wrapper>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    fullWidth
                    onClick={handleOnClickRemoveButton}
                >
                    Yes, remove this Lender
                </Button>
                <CancelButton onClick={onClose}>
                    No, don&apos;t remove it
                </CancelButton>
            </Modal.Footer>
        </Modal>
    )
}
