import { BodyCopy12 } from 'assets/styles/typography'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const Email = styled.div`
    ${BodyCopy12};
    color: ${({ theme }) => theme.colors.monochrome.asphalt};
    text-overflow: ellipsis;
    max-width: 225px;
    overflow: hidden;
`
