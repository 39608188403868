import { useEffect, useState } from 'react'

import { getApprovalTerms, ApprovalTerm } from 'api/application'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

export interface UseApprovalTermsInterface {
    approvalTerms: ApprovalTerm[]
    loading: boolean
    error: Error | null
}

const useApprovalTerms = (
    applicationUuid: string | undefined,
): UseApprovalTermsInterface => {
    const [approvalTerms, setApprovalTerms] = useState<ApprovalTerm[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const abortController = new AbortController()

        const loadApprovalTerms = async () => {
            return getApprovalTerms(applicationUuid || '', abortController)
        }

        if (!applicationUuid) {
            return
        }

        loadApprovalTerms()
            .then((terms: ApprovalTerm[]) => {
                setError(null)
                setApprovalTerms(terms)
            })
            .catch((approvalTermsError) => {
                OctaneLogger.error(
                    'Error when getting approval terms for application.',
                    { applicationUuid },
                    approvalTermsError as Error,
                )

                setError(approvalTermsError)
            })
            .finally(() => {
                setLoading(false)
            })

        // eslint-disable-next-line consistent-return
        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationUuid])

    return {
        approvalTerms,
        loading,
        error,
    }
}

export default useApprovalTerms
