import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

interface updateUserParams {
    [key: string]: string | number | object
}

async function updateUser(params: updateUserParams) {
    try {
        const response = await axios.patch(
            `${config.API_BASE_URL}/api/v3/users/current/`,
            params,
            {
                withCredentials: true,
            },
        )

        return response
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to update user: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}

export default updateUser
