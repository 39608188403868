/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'

import { Spinner, UiPlusIcon } from '@octane/spark'
import { getAssignToPersons } from 'api/flexLinks'
import { AssignToPerson } from 'api/flexLinks/getAssignToPersons'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import useUser from 'hooks/useUser'
import NotFound from 'pages/NotFound'
import { loadFlexLinks } from 'state/dealership/actions'
import { FlexLink } from 'state/dealership/DealershipState'
import LoadingStatus from 'state/enums/LoadingStatus'

import {
    NewLinkButton,
    FlexLinksCard,
    LinksCountBox,
    LoadingWrapper,
} from './AssignFlexLinks.styled'
import CreateNewLink from './CreateNewLink/CreateNewLink'
import AssignFlexLinksTable from './partials/AssignFlexLinksTable'
import UpdateLink from './UpdateLink/UpdateLink'

export default function AssignFlexLinks(): JSX.Element {
    const dispatch = useAppDispatch()
    const [showNewLinkModal, setShowNewLinkModal] = useState(false)

    const [flexLinkToUpdate, setFlexLinkToUpdate] = useState<FlexLink | null>(
        null,
    )

    const [assignToList, setAssignToList] = useState<
        AssignToPerson[] | undefined
    >(undefined)

    const dealershipState = useAppSelector((state) => state.dealership)
    const { count: flexLinksCount } = dealershipState.flexLinks.meta
    const flexLinksStatus: LoadingStatus = dealershipState?.flexLinks?.status
    const featureFlag = useFeatureFlagContext()
    const { isPowersportsDealership } = useUser()

    useEffect(() => {
        async function getPersons() {
            const response = await getAssignToPersons()

            setAssignToList(response)
        }

        getPersons()
    }, [])

    if (!featureFlag?.salesPersonFlexLink || !isPowersportsDealership) {
        return <NotFound />
    }

    const handleRefreshList = () => {
        dispatch(loadFlexLinks())
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        dispatch(loadFlexLinks())
    }, [dispatch])

    function handleNewLink() {
        setShowNewLinkModal(true)
    }

    function onCloseModalNewLink(evt: unknown, refreshList = false) {
        setShowNewLinkModal(false)
        if (refreshList) handleRefreshList()
    }

    function onUpdateLink(flexLink: FlexLink) {
        // set flexLink to update and open modal
        setFlexLinkToUpdate(flexLink)
    }

    function onCloseModalUpdateLink(evt: unknown, refreshList = false) {
        setFlexLinkToUpdate(null) // clean modal data
        if (refreshList) handleRefreshList()
    }

    function renderNewLinkButton() {
        return (
            <NewLinkButton
                endIcon={UiPlusIcon}
                variant="primary"
                size="small"
                onClick={() => handleNewLink()}
            >
                New Link
            </NewLinkButton>
        )
    }

    function renderSpinner() {
        return (
            <LoadingWrapper>
                <Spinner size={40} />
            </LoadingWrapper>
        )
    }

    function renderFlexLinksList() {
        return (
            <>
                {flexLinksStatus !== LoadingStatus.LOADING && assignToList ? (
                    <AssignFlexLinksTable
                        persons={assignToList}
                        onUpdateLink={onUpdateLink}
                    />
                ) : (
                    renderSpinner()
                )}
                {/* Modal code here */}
                {showNewLinkModal && (
                    <CreateNewLink
                        persons={assignToList || []}
                        open={showNewLinkModal}
                        onClose={onCloseModalNewLink}
                    />
                )}
            </>
        )
    }

    return (
        <FlexLinksCard>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                <LinksCountBox>
                    <span>{flexLinksCount}</span>
                    <span>{flexLinksCount === 1 ? 'Link' : 'Links'}</span>
                </LinksCountBox>
                {renderNewLinkButton()}
            </div>
            {renderFlexLinksList()}

            {/* Modal update */}
            {!!flexLinkToUpdate && (
                <UpdateLink
                    persons={assignToList || []}
                    open
                    onClose={onCloseModalUpdateLink}
                    data={flexLinkToUpdate}
                />
            )}
        </FlexLinksCard>
    )
}
