import React, { useEffect, useState } from 'react'

import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'

import getBookingSources from '../../../api/vehicles/getBookingSources'
import {
    BookingSource,
    isLastYearOrNewer,
} from '../../../utils/bookingSourceHelper'

interface bookingSourceProps {
    year?: number
}

const defaultProps = {
    year: null,
}

export default function BookingSourceDropdown({ year }: bookingSourceProps) {
    const [response, updateResponse] = useState([] as string[])

    useEffect(() => {
        getBookingSources()
            .then((bookingSources: string[]) => {
                let bookingSourcesFiltered = bookingSources.sort((a, b) => {
                    // reordering the booking sources so that the like invoice is always at the bottom
                    if (a === BookingSource.LIKE_INVOICE) return 1

                    if (b === BookingSource.LIKE_INVOICE) return -1

                    return 0
                })

                if (!isLastYearOrNewer(year))
                    // eslint-disable-next-line func-names
                    bookingSourcesFiltered = bookingSources.filter(function (
                        item,
                    ) {
                        return item !== BookingSource.LIKE_INVOICE
                    })

                updateResponse(bookingSourcesFiltered)
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {})
    }, [year])

    return (
        <FormikDropdown
            name="vehicle.bookingSource"
            label="Booking Source"
            placeholder="Select One"
        >
            {response &&
                response.map((child: string) => {
                    const newBorn: FormikDropdownChild = {
                        name: child,
                        value: child,
                    }

                    return newBorn
                })}
        </FormikDropdown>
    )
}

BookingSourceDropdown.defaultProps = defaultProps
