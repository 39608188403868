export interface LenderOptions {
    label: string
    value: string
    partner?: boolean
}

export const getAlreadyAddedLenders = (userId: number): string[] => {
    return JSON.parse(localStorage.getItem(`lenders${userId}`) as string) ?? []
}

export const updateAlreadyAddedLendersStorage = (
    userId: number,
    lendersList: string[],
) => {
    localStorage.setItem(`lenders${userId}`, JSON.stringify(lendersList))

    /* @ts-expect-error: this was set in IFrameSync.ts */
    window.windowLocalStorageIFrameClient.setItem(
        `lenders${userId}`,
        JSON.stringify(lendersList),
    )
}

export const getUnaddedLenders = (
    lenderOptions: LenderOptions[],
    selectedLender: LenderOptions | LenderOptions[],
) => {
    return lenderOptions.filter((lender) => {
        if (!Array.isArray(selectedLender)) {
            return lender.value !== selectedLender.value
        }

        return selectedLender.every((l) => l.value !== lender.value)
    })
}

export const adapterLenderOptionsToObject = (
    userId: number,
): LenderOptions[] => {
    return getAlreadyAddedLenders(userId).map((lender) => ({
        value: lender,
        label: lender,
    }))
}

export const isPartner = (
    allLendersOption: LenderOptions[],
    selectedLender: LenderOptions,
) => {
    if (allLendersOption.length) {
        return allLendersOption.some(
            (lender) => lender.value === selectedLender.value && lender.partner,
        )
    }

    return false
}
