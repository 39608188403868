import { useEffect, useState } from 'react'

import getLenderDisclosures, {
    Disclosure,
} from 'api/lender/getLenderDisclosures'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

import useRoadrunnerLender from './useRoadrunnerLender'

const useRoadrunnerDisclosures = () => {
    const { lender } = useRoadrunnerLender()
    const [disclosures, setDisclosures] = useState<Disclosure[]>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        if (lender) {
            getLenderDisclosures(lender.id)
                .then((response) => {
                    setDisclosures(response.data as Disclosure[])
                    setError(null)
                })
                .catch((disclosuresError) => {
                    OctaneLogger.error(`Error when getting lender disclosures.`)

                    setError(disclosuresError)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [lender])

    return {
        disclosures,
        loading,
        error,
    }
}

export default useRoadrunnerDisclosures
