import { typography } from 'assets/styles'
import styled from 'styled-components'

export const Header = styled.h1`
    ${typography.Heading24Bold}
    color: ${(props) => props.theme.colors.monochrome.jetlineBlack};
    margin: 0px;
`

export const Body = styled.p`
    ${typography.BodyCopy16}
    color: ${(props) => props.theme.colors.monochrome.ravenSub};
    margin-top: 0px;
`
