import React from 'react'

import { UiArrowCollapseIcon, UiArrowExpandIcon } from '@octane/spark'
import { SortDirection } from '@tanstack/react-table'
import { useTheme } from 'styled-components'

export default function OrderIcon({
    direction,
}: {
    direction: false | SortDirection
}) {
    const theme = useTheme()
    let color = theme.colors.monochrome.graphite

    if (!direction) {
        color = theme.colors.monochrome.concrete
    }

    if (direction === 'desc') {
        return <UiArrowExpandIcon width={20} height={14} color={color} />
    }

    return <UiArrowCollapseIcon width={20} height={14} color={color} />
}
