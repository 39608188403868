import styled from 'styled-components'

export const ModalInformation = styled.div`
    padding: 20px;
    border-radius: 5px;
    background-color: #f3f8fe;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > * > * {
        margin: 0;
    }
    > * a {
        color: #2366b1;
    }
`

export const ModalButton = styled.div`
    > Button {
        width: 100%;
        margin-top: 20px;
    }
`

export const Content = styled.div`
    size: 16px;
    line-height: 25px;

    > ol > li > .content_fade {
        color: #898f99;
    }
    > * a {
        color: #2366b1;
    }
`
