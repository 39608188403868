const consts = {
    // DEALERSHIP SETTINGS
    dealershipSettings: {
        manageUsers: {
            deleteUser: {
                success: {
                    message: 'User deleted successfully.',
                },
                error: {
                    title: 'Something went wrong',
                    message:
                        "We couldn't delete the user. Please try again later.",
                },
            },
            checkUserDeleteRequirements: {
                error: {
                    message: 'Something went wrong. Please try again later.',
                },
            },
        },
        manageAssignedFlexLinks: {
            deleteAssignedFlexLink: {
                success: {
                    message: 'Link deleted successfully.',
                },
                error: {
                    title: 'Something went wrong',
                    message:
                        "We couldn't delete the link. Please try again later.",
                },
            },
        },
        securitySettings: {
            success: {
                title: 'Security Settings Saved',
                message: 'The changes have been saved.',
            },
            error: {
                title: 'Security Settings Not Saved',
                message:
                    'Your changes were not saved. Please check your connection and try again.', // eslint-disable-line
            },
        },
    },
    // SETTINGS
    settings: {
        success: {
            title: 'Settings Saved',
            message:
                'Your changes have been saved. Your new contact information is now active.', // eslint-disable-line
        },
        error: {
            title: 'Settings Not Saved',
            message:
                'Your changes were not saved. Please check your connection and try again.', // eslint-disable-line
        },
    },
    // NOTIFICATIONS
    notifications: {
        subscriber: {
            added: (emailAddress: string) =>
                `Subscriber ${emailAddress} was added successfully`,
            removed: 'Subscriber removed successfully',
        },
        preferences: {
            updated: 'Notification preference updated',
        },
    },
}

export default consts
