/* eslint-disable max-len */
import React, { useState } from 'react'
import { useNavigate } from 'react-router'

import { TextLink } from '@octane/spark'
import { AxiosResponse } from 'axios'
import brandConfig from 'branding'

import postLoginCredentials, {
    LoginParams,
} from '../../api/auth/login/postLoginCredentials'
import TermsOfUseModal from '../../components/TermsOfUseModal'
import AuthenticationFormBuilder from '../../containers/Authentication/AuthenticationFormBuilder'
import { FinePrint } from '../../containers/Authentication/AuthenticationFormBuilder.styled'
import { FieldInstructionBundle } from '../../containers/FormBuilder'
import { password, username } from '../../form/fields'
import useAppDispatch from '../../hooks/useAppDispatch'
import { loadUser } from '../../state/user/actions'
import { getStatusMessage } from './util/getStatusMessage'

const loginFormInstructionBundle: FieldInstructionBundle = {
    username,
    password,
}

export default function LoginPage(): JSX.Element {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [isTermsModalOpen, setTermsModalOpen] = useState(false)

    const [shouldShowStatusMessage, setShouldShowStatusMessage] =
        useState(false)

    const [statusMessage, setStatusMessage] = useState('')

    const onTermsOfUseClick = () => {
        setTermsModalOpen(!isTermsModalOpen)
    }

    const forgotPasswordLink = (
        <a href="/#/password-reset">
            <TextLink as="span">Forgot Password</TextLink>
        </a>
    )

    let icon

    if (brandConfig.logo && brandConfig.loginLogoWidth) {
        icon = (
            <img
                src={brandConfig.logo}
                width={brandConfig.loginLogoWidth}
                alt="Logotype"
            />
        )
    }

    const finePrint = (
        <>
            <FinePrint>
                <div>
                    Need help? Call us at{' '}
                    <TextLink as="span">
                        <a href="tel: (646) 370-5471">(646) 370-5471</a>
                    </TextLink>{' '}
                    or email us at{' '}
                    <TextLink as="span">
                        <a href="mailto: support@octanelending.com">
                            support@octanelending.com
                        </a>
                    </TextLink>
                </div>
            </FinePrint>
            <FinePrint>
                <div>
                    Our{' '}
                    <TextLink as="span">
                        <button
                            type="button"
                            data-testid="termsOfUseButton"
                            onClick={() => onTermsOfUseClick()}
                        >
                            Terms of Use
                        </button>
                    </TextLink>{' '}
                    apply.
                </div>
            </FinePrint>
        </>
    )

    const loadUserData = async (response: AxiosResponse) => {
        const message = getStatusMessage(response)

        if (response.status === 200) {
            try {
                await dispatch(loadUser())
            } catch (error: unknown) {
                setStatusMessage(message)
                setShouldShowStatusMessage(true)
            }
        } else if (message) {
            setStatusMessage(message)
            setShouldShowStatusMessage(true)
        } else setShouldShowStatusMessage(false)
    }

    const setCatchForOtherErrors = () => {
        setStatusMessage('Error. Please contact us.')

        setShouldShowStatusMessage(true)
    }

    return (
        <div data-testid="loginPageContainer">
            <AuthenticationFormBuilder
                instructions={loginFormInstructionBundle}
                onSubmitFn={(formikValues) => {
                    setShouldShowStatusMessage(false)

                    postLoginCredentials(formikValues as LoginParams)
                        .then((response) => {
                            if (response) {
                                loadUserData(response).catch(() =>
                                    setCatchForOtherErrors(),
                                )

                                const path =
                                    sessionStorage.getItem('previousPath')

                                if (path) {
                                    navigate(path)
                                    sessionStorage.removeItem('previousPath')
                                }
                            }
                        })
                        .catch(() => setCatchForOtherErrors())
                }}
                submitButtonText="Sign In"
                headerTitle={brandConfig.loginHeaderTitle}
                headerSubtitle="Sign in with your existing account."
                textLink={forgotPasswordLink}
                isModal={false}
                finePrint={finePrint}
                statusMessage={statusMessage}
                shouldShowStatusMessage={shouldShowStatusMessage}
                icon={icon}
            />
            <TermsOfUseModal
                isOpen={isTermsModalOpen}
                handleClose={() => setTermsModalOpen(false)}
            />
        </div>
    )
}
