import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { UiCheckmarkIcon } from '@octane/spark'
import Card from 'components/Card'
import useUser from 'hooks/useUser'

import { Body, Status } from './Enrollment.styled'
import { EnrollmentStatus } from './types.d'

interface EnrollmentStateProps {
    status: EnrollmentStatus
}

function EnrollmentState({ status }: EnrollmentStateProps): JSX.Element {
    const navigate = useNavigate()

    function getEnrolled() {
        if (status === EnrollmentStatus.NEEDS_SAFECHECK)
            window.open(
                'https://www.roadrunnerfinancial.com/safecheck',
                'SafeCheckTab',
                'noopener',
            )

        if (status === EnrollmentStatus.NEEDS_MFA) navigate('../security')
    }

    return (
        // eslint-disable-next-line react/jsx-no-bind
        <Status status={status} onClick={getEnrolled}>
            {status === EnrollmentStatus.ENROLLED && (
                <>
                    <UiCheckmarkIcon width={24} height={24} />
                    Enrolled
                </>
            )}
            {status === EnrollmentStatus.NEEDS_MFA && 'Set Up MFA'}
            {status === EnrollmentStatus.NEEDS_SAFECHECK && 'Get Enrolled'}
            {status === EnrollmentStatus.NOT_ENROLLED && 'Not Enrolled'}
        </Status>
    )
}
export default function YourPassword(): JSX.Element {
    const { user } = useUser()

    const [status, setStatus] = useState<EnrollmentStatus>(
        EnrollmentStatus.NEEDS_SAFECHECK,
    )

    const [statusPre, setStatusPre] = useState<EnrollmentStatus>(
        EnrollmentStatus.NOT_ENROLLED,
    )

    useEffect(() => {
        const { isPrequalEnabled = false, isSafeCheckEnabled = false } =
            (user && user.dealership) || {}

        const isMfaVerified = !!user?.mfaVerified

        if (isPrequalEnabled) setStatusPre(EnrollmentStatus.ENROLLED)

        if (isSafeCheckEnabled) {
            setStatus(
                isMfaVerified
                    ? EnrollmentStatus.ENROLLED
                    : EnrollmentStatus.NEEDS_MFA,
            )
        } else {
            setStatus(EnrollmentStatus.NEEDS_SAFECHECK)
        }
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card data-testid="your-password-card" title="Product Enrollment">
            <Body>
                <div>
                    <span>
                        <strong>Soft Pulls</strong>
                        You can do soft-pulls for prequalification and see real
                        rates
                    </span>
                    <EnrollmentState status={statusPre} />
                </div>
                <div>
                    <span>
                        <strong>SafeCheck</strong>
                        You can see the customer’s credit report right in their
                        application
                    </span>
                    <EnrollmentState status={status} />
                </div>
            </Body>
        </Card>
    )
}
