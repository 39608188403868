import { FeatureFlagContextInterface } from 'contexts/FeatureFlagContextProvider'
import AssetType from 'state/enums/AssetType'

import isLightspeed from './lightspeed'

const LEGACY_APPLICATION_PATH = '#!/application/new'
const NEW_APPLICATION_PATH = '#/new-application'

const getNewApplicationPath = (
    featureFlags: FeatureFlagContextInterface | null,
    primaryAssetType: AssetType | undefined,
) => {
    if (!primaryAssetType) {
        return LEGACY_APPLICATION_PATH
    }

    if (isLightspeed()) {
        return LEGACY_APPLICATION_PATH
    }

    if (primaryAssetType === AssetType.AUTOMOBILE) {
        return NEW_APPLICATION_PATH
    }

    if (primaryAssetType === AssetType.MARINE) {
        return NEW_APPLICATION_PATH
    }

    if (primaryAssetType === AssetType.RECREATIONAL_VEHICLE) {
        return LEGACY_APPLICATION_PATH
    }

    if (primaryAssetType === AssetType.POWERSPORTS_VEHICLE) {
        if (featureFlags?.shortApp || featureFlags?.newPowersportsApp) {
            return NEW_APPLICATION_PATH
        }
    }

    return LEGACY_APPLICATION_PATH
}

export default getNewApplicationPath
