import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { getCurrentFeatureFlags } from 'api/auth'
import { FlagFormat } from 'api/auth/getCurrentFeatureFlags'
import useUser from 'hooks/useUser'
import featureFlags from 'utils/featureFlags'

export interface FeatureFlagContextInterface {
    shortApp?: boolean
    newPowersportsApp?: boolean
    decisionPage2Powersports?: boolean
    selfServiceManagement?: boolean
    leadsPage?: boolean
    webAppLoyaltyDashboard?: boolean
    customerEligibilityEnabledForAutoApps?: boolean
    newWtdPath?: boolean
    flatCancel?: boolean
    documentViewerForStips?: boolean
    oemRebatePowerSports?: boolean
    dealTermsTable?: boolean
    dealTermsTableFiltering?: boolean
    showNewSafeCheckPage?: boolean
    showFlexLink?: boolean
    tradeInAdjustment?: boolean
    returnCustomerPsApp2?: boolean
    dealershipLenderAgreementValidation?: boolean
    sweepstakesTheme?: boolean
    whiteLabelRedirect?: boolean
    dealTermsTableExtendedLogging?: boolean
    salesPersonFlexLink?: boolean
    routePsAppToNewReadOnlyPage?: boolean
    showRePowerTile?: boolean
    handleShouldUpdateFlags?: () => void
}

export const FeatureFlagContext =
    createContext<FeatureFlagContextInterface | null>(null)

interface FeatureFlagContextProviderProps {
    children: React.ReactNode
}

function FeatureFlagContextProvider(props: FeatureFlagContextProviderProps) {
    const { children } = props
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [flags, setFlags] = useState({} as FlagFormat | Record<string, any>)
    const [shouldUpdateFlags, setShouldUpdateFlags] = useState(true as boolean)
    const { isLoggedIn } = useUser()

    const cachedFlags = useMemo(
        () => async () => {
            getCurrentFeatureFlags().then((response: FlagFormat) => {
                setFlags(response)

                setShouldUpdateFlags(false)
            })
        },
        [],
    )

    const handleShouldUpdateFlags = () => {
        setShouldUpdateFlags(true)
    }

    useEffect(() => {
        if (shouldUpdateFlags) {
            cachedFlags()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.hash])

    useEffect(() => {
        cachedFlags()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    function getFlagStatus(key: string) {
        if (Object.keys(flags).length === 0) {
            return false
        }

        try {
            return (flags.flags[key] || flags.switches[key]).is_active
        } catch {
            return false
        }
    }

    const shortApp = getFlagStatus(featureFlags.shortApp)
    const newPowersportsApp = getFlagStatus(featureFlags.newPowersportsApp)

    const decisionPage2Powersports = getFlagStatus(
        featureFlags.decisionPage2Powersports,
    )

    const showFlexLink = getFlagStatus(featureFlags.showFlexLink)

    const selfServiceManagement = getFlagStatus(
        featureFlags.selfServiceManagement,
    )

    const leadsPage = getFlagStatus(featureFlags.leadsPage)

    const webAppLoyaltyDashboard = getFlagStatus(
        featureFlags.webAppLoyaltyDashboard,
    )

    const customerEligibilityEnabledForAutoApps = getFlagStatus(
        featureFlags.customerEligibilityEnabledForAutoApps,
    )

    const newWtdPath = getFlagStatus(featureFlags.newWtdPath)
    const flatCancel = getFlagStatus(featureFlags.flatCancel)

    const documentViewerForStips = getFlagStatus(
        featureFlags.documentViewerForStips,
    )

    const oemRebatePowerSports = getFlagStatus(
        featureFlags.oemRebatePowerSports,
    )

    const dealTermsTable = getFlagStatus(featureFlags.dealTermsTable)

    const dealTermsTableFiltering = getFlagStatus(
        featureFlags.dealTermsTableFiltering,
    )

    const showNewSafeCheckPage = getFlagStatus(
        featureFlags.showNewSafeCheckPage,
    )

    const tradeInAdjustment = getFlagStatus(featureFlags.tradeInAdjustment)

    const returnCustomerPsApp2 = getFlagStatus(
        featureFlags.returnCustomerPsApp2,
    )

    const dealershipLenderAgreementValidation = getFlagStatus(
        featureFlags.dealershipLenderAgreementValidation,
    )

    const sweepstakesTheme = getFlagStatus(featureFlags.sweepstakesTheme)
    const whiteLabelRedirect = getFlagStatus(featureFlags.whiteLabelRedirect)

    const dealTermsTableExtendedLogging = getFlagStatus(
        featureFlags.dealTermsTableExtendedLogging,
    )

    const salesPersonFlexLink = getFlagStatus(featureFlags.salesPersonFlexLink)

    const routePsAppToNewReadOnlyPage = getFlagStatus(
        featureFlags.routePsAppToNewReadOnlyPage,
    )

    const showRePowerTile = getFlagStatus(featureFlags.showRePowerTile)

    const ctx = React.useMemo(
        () => ({
            shortApp,
            newPowersportsApp,
            decisionPage2Powersports,
            selfServiceManagement,
            leadsPage,
            webAppLoyaltyDashboard,
            customerEligibilityEnabledForAutoApps,
            newWtdPath,
            flatCancel,
            documentViewerForStips,
            oemRebatePowerSports,
            dealTermsTable,
            dealTermsTableFiltering,
            showNewSafeCheckPage,
            showFlexLink,
            tradeInAdjustment,
            returnCustomerPsApp2,
            dealershipLenderAgreementValidation,
            sweepstakesTheme,
            whiteLabelRedirect,
            dealTermsTableExtendedLogging,
            salesPersonFlexLink,
            routePsAppToNewReadOnlyPage,
            handleShouldUpdateFlags,
            showRePowerTile,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            shortApp,
            newPowersportsApp,
            decisionPage2Powersports,
            selfServiceManagement,
            leadsPage,
            webAppLoyaltyDashboard,
            customerEligibilityEnabledForAutoApps,
            newWtdPath,
            flatCancel,
            documentViewerForStips,
            oemRebatePowerSports,
            dealTermsTable,
            dealTermsTableFiltering,
            showNewSafeCheckPage,
            showFlexLink,
            tradeInAdjustment,
            dealershipLenderAgreementValidation,
            dealTermsTableExtendedLogging,
            handleShouldUpdateFlags,
            showRePowerTile,
        ],
    )

    return (
        <FeatureFlagContext.Provider value={ctx}>
            {children}
        </FeatureFlagContext.Provider>
    )
}

export default FeatureFlagContextProvider
export const useFeatureFlagContext = () => useContext(FeatureFlagContext)
