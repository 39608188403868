import React from 'react'

import AssetType from 'state/enums/AssetType'
import { Button, TextInput, toast, Spinner, TabMenuBar } from '@octane/spark'
import { getMaterialKit } from 'api/dealership'
import brandConfig from 'branding'
import { Brand } from 'branding/types'
import config from 'config'
import { useFeatureFlagContext } from 'contexts/FeatureFlagContextProvider'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

import useUser from '../../hooks/useUser'
import LoadingStatus from '../../state/enums/LoadingStatus'
import {
    Container,
    WrapperLoader,
    SectionPrequal,
    RowSubtitle,
    Subtitle,
    Title,
    InputTitle,
    RowInput,
    SectionNews,
    TabContainer,
} from './Home.styled'
import { getIFrameNewsPath, getIFrameNewsPathAsList, FramePath } from './utils'

const downloadFile = async (dealershipId: number, fileName: string) => {
    const blobData = await getMaterialKit(dealershipId)
    const url = window.URL.createObjectURL(new Blob([blobData]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
}

export default function Home(): JSX.Element {
    const featureFlags = useFeatureFlagContext()
    const { user, status, isRVDealership, isMarineDealership } = useUser()
    const flexLink = user?.dealership?.flexLink
    const assetTypes = user?.dealership?.assetTypes || []
    // relies on routing to the branded Page
    // We should update when captive_type is made available on user or dealership
    const isCWCaptive: boolean = brandConfig.brand === Brand.GoodSam
    const isRidenowCaptive: boolean = brandConfig.brand === Brand.RideNow
    let iframeNewsPaths: FramePath[] = []
    // BLUR-683 Crossover dealer only applies for POWERSPORTS_VEHICLE and MARINE asset types

    const isCrossoverApplication = [
        AssetType.POWERSPORTS_VEHICLE,
        AssetType.MARINE,
    ].every((a) => assetTypes.includes(a))

    if (isCrossoverApplication) {
        iframeNewsPaths = getIFrameNewsPathAsList(assetTypes)
    } else
        iframeNewsPaths.push({
            path: getIFrameNewsPath(
                isRVDealership,
                isCWCaptive,
                isRidenowCaptive,
                isMarineDealership,
            ),
            name: '',
        })

    const handleDownloadKit = async () => {
        const pdfName = `Octane Prequal Flex Kit - [${user?.dealership?.name}].pdf`

        downloadFile(user?.dealership?.id as number, pdfName)

        OctaneLogger.info('Prequal Flex kit downloaded')
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(flexLink as string)

        toast(flexLink ?? '', {
            duration: 3000,
            position: 'bottom-center',
            title: 'Copied to clipboard!',
            size: 'medium',
            variant: 'success',
            closeIcon: true,
        })

        OctaneLogger.info('Prequal Flex link copied')
    }

    const showSectionFlexLink =
        flexLink && featureFlags?.showFlexLink && !isRVDealership

    const [selected, setSelected] = React.useState<number>(0)

    const [iframePath, setIframePath] = React.useState<string>(
        iframeNewsPaths[0].path,
    )

    const choose = (n: number) => {
        setSelected(n)
        setIframePath(iframeNewsPaths[n].path)
    }

    return (
        <Container>
            {status === LoadingStatus.LOADING && !user && (
                <WrapperLoader>
                    <Spinner size={70} />
                </WrapperLoader>
            )}
            {(status === LoadingStatus.LOADED || user) && (
                <>
                    {showSectionFlexLink && (
                        <SectionPrequal data-testid="section-prequal">
                            <div className="column">
                                <Title>
                                    Prequal Flex: Prequalify customers anywhere
                                </Title>
                                <RowSubtitle>
                                    <Subtitle>
                                        QR Codes • Templates & In-store
                                        Materials
                                    </Subtitle>
                                    <Button
                                        size="small"
                                        onClick={handleDownloadKit}
                                    >
                                        Download your kit
                                    </Button>
                                </RowSubtitle>
                            </div>

                            <div className="separator" />

                            <div className="column">
                                <InputTitle>
                                    USE YOUR PREQUAL FLEX LINK
                                </InputTitle>
                                <RowInput>
                                    <TextInput
                                        id="flex-link-input"
                                        type="text"
                                        value={flexLink}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        onChange={() => {}}
                                        readOnly
                                    />
                                    <Button onClick={handleCopyLink}>
                                        Copy Your Prequal Flex Link
                                    </Button>
                                </RowInput>
                            </div>
                        </SectionPrequal>
                    )}

                    {isCrossoverApplication &&
                        iframeNewsPaths.map((child, index) => {
                            return (
                                <TabContainer>
                                    <TabMenuBar>
                                        <TabMenuBar.Item
                                            onClick={() => choose(index)}
                                            active={selected === index}
                                        >
                                            <div>
                                                {iframeNewsPaths[index].name}
                                            </div>
                                        </TabMenuBar.Item>
                                    </TabMenuBar>
                                </TabContainer>
                            )
                        })}

                    <SectionNews isRVDealer={isRVDealership}>
                        <iframe
                            title="News"
                            name="news-panel-iframe"
                            className="main-news-panel"
                            sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms"
                            src={`${config.API_BASE_URL}/${iframePath}/#`}
                        />
                    </SectionNews>
                </>
            )}
        </Container>
    )
}
