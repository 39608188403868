import React from 'react'

import { StCompleteIcon, StErrorIcon } from '@octane/spark'

import { Container } from './PrequalAppAccess.styled'

interface Props {
    value: boolean
}

export default function PrequalAppAccess({ value }: Props) {
    return (
        <Container>
            {value && <StCompleteIcon width={20} height={20} />}
            {!value && <StErrorIcon width={20} height={20} />}
            {value ? 'Yes' : 'No'}
        </Container>
    )
}
