import ZipCodesRepository from 'api/zipCode/ZipCodesRepository'
import { States } from 'containers/ApplicantInformation/States'
import { FieldInstruction, FormValues } from 'containers/FormBuilder'
import InputField from 'form/fieldComponents/InputField'
import SelectField, {
    SelectFieldOnChange,
} from 'form/fieldComponents/SelectField'
import {
    FIELD_REQUIRED,
    FIELD_TOO_LONG,
    FIELD_SAME_STATE,
    FIELD_INVALID_STATE,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

export type ZipCityStateFieldNames = {
    state: string
    city: string
    stateEligibility?: string
}

function autoPopulateCityAndSateByZip(
    fieldNames: ZipCityStateFieldNames,
): SelectFieldOnChange {
    const zipCodesRepositoryInstance = new ZipCodesRepository(
        window.localStorage,
    )

    return (value, fieldName, formValues, fieldKit) => {
        const zipCodeLength = 5

        if (value.length === zipCodeLength) {
            zipCodesRepositoryInstance.getZipCodeMap().then((zipCodeMap) => {
                const zipCodeInfo = zipCodeMap[value]

                if (zipCodeInfo) {
                    fieldKit.setFieldValue(fieldNames.city, zipCodeInfo.city)
                    fieldKit.setFieldValue(fieldNames.state, zipCodeInfo.state)

                    if (
                        fieldNames.stateEligibility &&
                        formValues[fieldNames.stateEligibility]
                    ) {
                        fieldKit.setFieldTouched(fieldNames.state, true)
                    }
                }
            })
        }

        fieldKit.setFieldValue(fieldName, value)
    }
}

export const streetAddress: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Address',
        validator: Yup.string()
            .required(FIELD_REQUIRED)
            .min(2)
            .max(121, FIELD_TOO_LONG(121))
            .matches(
                /^[A-Za-z0-9 ]*$/,
                'Address can only contain letters and numbers. ',
            ),
    },
}

export const apartmentSuite: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Apartment/Suite',
        validator: Yup.string()
            .max(20, FIELD_TOO_LONG(20))
            .matches(
                /^[A-Za-z0-9 ]*$/,
                "Apartment/Suite can't contain special characters",
            ),
    },
}

export const zipCode: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Zip Code',
        validator: Yup.string()
            .matches(/^\d*$/, 'Can only contain digits.')
            .max(5, 'Zip codes can only be 5 digits.')
            .min(5, 'Zip codes can only be 5 digits.')
            .required(FIELD_REQUIRED),
    },
}

export function createZipFieldInstruction(
    fieldNames: ZipCityStateFieldNames,
): FieldInstruction<typeof InputField> {
    return {
        Component: InputField,
        config: {
            ...zipCode.config,
            onChange: autoPopulateCityAndSateByZip(fieldNames),
        },
    }
}

export const city: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'City',
        validator: Yup.string()
            .matches(
                /^[A-Za-z ]*$/,
                'City can only contain letters. If a city has a number in it, please spell it out.', // eslint-disable-line max-len
            )
            .required(FIELD_REQUIRED)
            .min(2)
            .max(121),
    },
}

const stateValidator = Yup.string()
    .required(FIELD_REQUIRED)
    .notOneOf(['AK', 'HI'], FIELD_INVALID_STATE)

export function sameStateValidation(
    stateName: string,
    stateEligibilityName: string,
): (formValues: FormValues) => Yup.StringSchema {
    return (formValues) => {
        return stateValidator.test('test-same-state', FIELD_SAME_STATE, () => {
            const statesValidation =
                !formValues[stateEligibilityName] ||
                formValues[stateName] === formValues[stateEligibilityName]

            return statesValidation
        })
    }
}

export const state: FieldInstruction<typeof SelectField> = {
    Component: SelectField,
    config: {
        label: 'State',
        placeholder: 'Select State',
        options: States,
        validator: stateValidator,
    },
}

export function createStateFieldWithEligibilityValidation(
    stateFieldName: string,
    stateEligibilityFieldName: string,
): FieldInstruction<typeof SelectField> {
    return {
        Component: SelectField,
        config: {
            ...state.config,
            validator: sameStateValidation(
                stateFieldName,
                stateEligibilityFieldName,
            ),
        },
    }
}
