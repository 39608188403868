enum VehicleType {
    CLASSIC = 'classic',
    CRUISER = 'cruiser',
    PWC = 'pwc',
    SCOOTER = 'scooter',
    SPORTS_BIKE = 'sports bike',
    TRAILER = 'trailer',
    TRACTOR = 'tractor',
    TRIKE = 'trike',
}

export default VehicleType
