import React from 'react'

import FileViewer from 'components/FileViewer'

import { Container } from './Body.styled'

interface Props {
    fileUrl: string
    fileName: string
    cbLoadEnd?: () => void
}

export default function Body({
    fileUrl,
    fileName,
    cbLoadEnd,
}: Props): JSX.Element {
    return (
        <Container>
            <FileViewer
                documentName={fileName}
                documentUrl={fileUrl}
                cbLoadEnd={cbLoadEnd}
            />
        </Container>
    )
}

Body.defaultProps = {
    cbLoadEnd: () => undefined,
}
