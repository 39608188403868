import styled from 'styled-components'

export const FooterWrapper = styled.div`
    padding: 0px 30px;
    width: 100%;
`
export const FooterContainer = styled.div`
    margin: auto;
    padding: 15px 0px;
    width: 100%;
    max-width: 1265px;
    border-top: 1px solid #898f99;
`
export const FooterText = styled.div`
    ${({ theme }) => theme.fonts.Karla['s14-ln20-fw400']};
    color: #898f99;
    font-size: 14px;
    text-align: left;
`
