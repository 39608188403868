import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

type CreateLinkProps = {
    dealerUserId?: string
    salesPersonId?: string
    dealershipId: number
    link: string
    isPrimary: boolean
}

export default async function createNewLink({
    dealerUserId,
    salesPersonId,
    dealershipId,
    link,
    isPrimary,
}: CreateLinkProps) {
    const requestBody = {
        salesperson_id: salesPersonId,
        dealer_user_id: dealerUserId,
        dealership_id: dealershipId,
        label: link,
        in_store: isPrimary,
    }

    if (dealerUserId) delete requestBody.salesperson_id

    if (salesPersonId) delete requestBody.dealer_user_id

    try {
        await axios.post(
            `${config.API_BASE_URL}/api/v3/dealer-assigned-links/`,
            requestBody,
            {
                withCredentials: true,
            },
        )

        return true
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to create new link: ${axiosError.message}`,
            axiosError,
        )

        throw axiosError
    }
}
