/* eslint-disable no-useless-escape */
const TRIM_WHITESPACE_AND_HYPHEN_EXPRESSION = /^[\s\-]+|[\s\-]+$|\s+(?=\s)/g // NOSONAR
const NORMALIZE_WHITESPACE_EXPRESSION = /\s\s+/g

const normalizeAscii = (value: string) => {
    return value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[Øø]/g, 'o')
        .replace(/[Ææ]/g, 'a')
}

export const normalizeNameFormatFn = (value: string): string => {
    const normalizedValue = normalizeAscii(value)

    return normalizedValue
        .replace(TRIM_WHITESPACE_AND_HYPHEN_EXPRESSION, '')
        .replace(NORMALIZE_WHITESPACE_EXPRESSION, '')
        .toUpperCase()
}
