import config from '../../config'

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: Array<any>
    }
}

const GOOGLE_TAG_MANAGER_SCRIPT_ID = 'googleTagManager'

const loadScript = (
    src: string,
    id: string,
    asynchronous: boolean,
    queryParams: any, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
    const script = document.createElement('script')
    const urlParams = new URLSearchParams(queryParams)

    script.type = 'text/javascript'
    script.async = asynchronous
    script.id = id
    script.src = `${src}?${urlParams.toString()}`

    document.body.appendChild(script)
}

const loadGoogleTagManager = () => {
    loadScript(
        'https://www.googletagmanager.com/gtm.js',
        GOOGLE_TAG_MANAGER_SCRIPT_ID,
        true,
        {
            id: config.GOOGLE_TAG_MANAGER_CONTAINER_ID,
            gtm_auth: config.GOOGLE_TAG_MANAGER_AUTH,
            gtm_preview: config.GOOGLE_TAG_MANAGER_ENVIRONMENT,
        },
    )
}

const hasGoogleTagManagerScript = () => {
    const script = document.getElementById(
        GOOGLE_TAG_MANAGER_SCRIPT_ID,
    ) as HTMLScriptElement

    return Boolean(script)
}

const generateGA4Timestamp = () => {
    const timestamp = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'America/New_York',
    })
        .format(new Date())
        .replace(/,/g, '')

    return `${timestamp} ET`
}

export default class GoogleTagManager {
    init(userId: number | string | undefined) {
        const isGoogleTagManagerLoaded = hasGoogleTagManagerScript()

        if (!isGoogleTagManagerLoaded) {
            this.trackEvent({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            })
        }

        // Track user ID.
        if (userId) {
            this.trackEvent({ userId })
        }

        if (isGoogleTagManagerLoaded) {
            return
        }

        // Load the Google Tag Manager script.
        loadGoogleTagManager()
    }

    // eslint-disable-next-line class-methods-use-this
    trackPageview(userId: number | string | undefined) {
        let path = window.location.hash.replace(/^#!?/, '') || '/'

        // Replace IDs with a placeholder.
        path = path.replace(/[\d]+/g, ':id')

        this.trackEvent({
            event: 'Pageview',
            title: window.document.title,
            url: path,
        })

        // Google Analytics 4
        this.trackEvent({
            event: 'pageview',
            page: {
                page_location: path,
            },
            user: {
                ga4_user_id: userId,
            },
        })
    }

    // eslint-disable-next-line max-len
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
    trackEvent(event: any) {
        if (!window.dataLayer) {
            window.dataLayer = []
        }

        window.dataLayer.push({
            time_stamp: generateGA4Timestamp(),
            ...event,
        })
    }

    trackNavbarEvent(text: string, href: string) {
        this.trackEvent({
            event: 'navigation',
            link_text: text,
            link_url: href,
        })
    }
}
