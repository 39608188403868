import React from 'react'

import { ImageMeta } from './hooks/useFileViewer'
import * as Icons from './Icons'
import {
    Container,
    ToolbarDivisor,
    ToolbarSupport,
    ToolbarWrapper,
} from './Toolbar.styled'

export type Props = {
    blockDownload?: boolean
    defineZoomValue: (value: string) => void
    documentDownloadUrl?: string
    documentName: string
    fitScreen: (dimension: string) => void
    image: ImageMeta
    handleZoomChange: (value: string) => void
    nextPage: () => void
    numberOfPages: number
    page: number
    prevPage: () => void
    rotate: (value: string) => void
    zoom: (value: string) => void
}

function Toolbar({
    blockDownload,
    defineZoomValue,
    documentName,
    documentDownloadUrl,
    fitScreen,
    image,
    handleZoomChange,
    page,
    prevPage,
    nextPage,
    numberOfPages,
    rotate,
    zoom,
}: Props): JSX.Element {
    if (blockDownload && !documentDownloadUrl)
        throw new Error(
            'If blockDownload is true, the download URL paramenters must have a value',
        )

    return (
        <Container>
            <ToolbarWrapper>
                {numberOfPages > 1 && (
                    <>
                        <aside>
                            <button type="button" onClick={prevPage}>
                                <Icons.GoLeft />
                            </button>
                            <span>
                                <small>Page</small> <strong>{page}</strong> /{' '}
                                {numberOfPages}
                            </span>
                            <button type="button" onClick={nextPage}>
                                <Icons.GoRight />
                            </button>
                        </aside>
                        <ToolbarDivisor />
                    </>
                )}
                <button type="button" onClick={() => zoom('out')}>
                    <Icons.ZoomOut />
                </button>
                <input
                    type="text"
                    value={image.zoom}
                    onChange={(e) => defineZoomValue(e.target.value)}
                    onKeyDown={(e: React.KeyboardEvent) =>
                        e.key === 'Enter' &&
                        handleZoomChange((e.target as HTMLInputElement).value)
                    }
                    onBlur={(e) => handleZoomChange(e.target.value)}
                />
                <button type="button" onClick={() => zoom('in')}>
                    <Icons.ZoomIn />
                </button>
                <ToolbarDivisor />
                {image.isLandscape && (
                    <button type="button" onClick={() => fitScreen('h')}>
                        <Icons.FitScreenHeight />
                    </button>
                )}
                {!image.isLandscape && (
                    <button type="button" onClick={() => fitScreen('w')}>
                        <Icons.FitScreenWidth />
                    </button>
                )}
                <button type="button" onClick={() => rotate('left')}>
                    <Icons.RotateLeft />
                </button>
            </ToolbarWrapper>
            <ToolbarSupport>
                {!blockDownload && (
                    <a download={documentName} href={documentDownloadUrl}>
                        <Icons.Download />
                    </a>
                )}
            </ToolbarSupport>
        </Container>
    )
}

Toolbar.defaultProps = {
    blockDownload: true,
    documentDownloadUrl: null,
}

export default Toolbar
