enum ApplicationStatus {
    APPLICATION_MISSING_INFORMATION = 'Application Missing Information',
    APPROVAL_PENDING = 'Approval Pending',
    APPROVED = 'Approved',
    CONDITIONAL_APPROVAL = 'Conditional Approval',
    CONTRACT_GENERATED = 'Contract Generated',
    CONTRACTED = 'Contracted',
    CREDIT_PROFILE_LOCKED = 'Credit Profile Locked',
    DECLINE = 'Declined',
    DEMO_QUOTE = 'Demo Quote',
    EXPIRED_APPLICATION = 'Expired Application',
    EXPIRED_APPROVAL = 'Expired Approval',
    FUNDED = 'Funded',
    FUNDING_HOLD = 'Funding Hold',
    FUNDING_PENDING = 'Funding Pending',
    LEVEL_TWO = 'Level 2',
    NEW_APPLICATION = 'New Application',
    NO_RECORD_FOUND = 'No Credit Report Found',
    SSN_REQUIRED = 'SSN Required',
    MANUAL_REVIEW = 'Manual Review',
    PREQUALIFIED = 'Prequalified',
}

export default ApplicationStatus
