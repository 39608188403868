import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export default async function deleteApplicationCollateral(
    applicationUuid: string,
    collateralId: string,
) {
    try {
        const { data } = await axios.delete(
            `${config.API_BASE_URL}/api/v3/applications/${applicationUuid}/collaterals/${collateralId}/`,
            {
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to delete application collateral: ${axiosError.message}`,
            axiosError,
            { applicationUuid, collateralId },
        )

        throw new Error(axiosError.message)
    }
}
