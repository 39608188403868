import axios, { AxiosError } from 'axios'
import config from 'config'
import convertObjectToQueryString from 'utils/convertObjectToQueryString'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface GetModelsOptions {
    condition: 'new' | 'used'
    year: number
    makeId: number
    lenderId?: number
    disable?: boolean
    dataSource?: string[]
    assetType?: string
    primaryVehicleId?: number
    vehicleTypeList?: string[]
}

export interface Model {
    disable: boolean
    id: number
    isTrailer: boolean
    name: string
}

interface ResponseModel {
    disable: boolean
    id: number
    is_trailer: boolean
    name: string
}

export default async function getModels(
    options: GetModelsOptions,
    abortController: AbortController,
) {
    let url = `${config.API_BASE_URL}/api/v3/vehicles/models/`
    let dataSourceParam = ''

    if (options.dataSource) {
        dataSourceParam = options.dataSource.join(',')
    }

    const queryString = convertObjectToQueryString(
        {
            condition: options.condition,
            year: options.year,
            make_id: options.makeId,
            lender_id: options.lenderId,
            disable: options.disable,
            data_source: dataSourceParam,
            asset_type: options.assetType,
            primary_vehicle_id: options.primaryVehicleId,
            vehicle_type_list: options.vehicleTypeList,
            limit: 5000,
            offset: 0,
        },
        true,
    )

    url += `?${queryString}`

    try {
        const response = await axios.get(url, {
            signal: abortController.signal,
            withCredentials: true,
        })

        const models = response.data.results.map((result: ResponseModel) => ({
            disable: result.disable,
            id: result.id,
            isTrailer: result.is_trailer,
            name: result.name,
        }))

        return models as Model[]
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get models: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
