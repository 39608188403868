import styled, { css } from 'styled-components'

export const Container = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.colors.monochrome.white};
    border: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    height: 40px;
    border-radius: 5px;
    margin-top: 20px;
    /* to avoid collisions with intercom widget and pagination */
    margin-bottom: 80px;
    overflow: hidden;
    float: right;

    & > button {
        width: 100px;
        height: 100%;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 70px;
    }
`

export const Information = styled.div<{
    isLoading: boolean
}>`
    position: relative;
    color: ${({ theme }) => theme.colors.monochrome.graphite};
    font-size: 16px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 2;
    flex-shrink: 2;
    border-left: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};
    border-right: 1px solid ${({ theme }) => theme.colors.monochrome.concrete};

    ${({ isLoading, theme }) =>
        isLoading &&
        css`
      &::after {
        content: '';
        background: ${theme.colors.monochrome.moonGrey}
        z-index: 1;
        width: 60%;
        height: 50%;
        border-radius: 5px;
        position: absolute;
      }
    `}
`
