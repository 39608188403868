import React from 'react'

import {
    CepDeclinedIcon,
    Button,
    Spinner,
    StAlertIcon,
    TextLink,
    UiArrowRightIcon,
    theme,
} from '@octane/spark'
import ClientApplication from 'api/application/interfaces/ClientApplication'
import useDeclineReasons from 'hooks/useDeclineReasons'
import useSafeCheck from 'hooks/useSafeCheck'
import {
    Container,
    DeclinedTitle,
    BodyText,
    ButtonContainer,
} from './DecisionResult.styled'

import {
    BoxHeader,
    DeclineReasonsContainer,
    DeclineReasonItem,
    DeclineReasonList,
    DeclineReasonText,
    DeclineReasonsSpinnerWrapper,
} from './DecisionResultDeclined.styled'

const getSafeCheckReportUrl = (applicationUUID: string) => {
    return `/#/safecheck/report/${applicationUUID}/`
}

export default function DecisionResultDeclined({
    clientApplication,
}: {
    clientApplication: ClientApplication
}): JSX.Element {
    const { canUseSafeCheck, hasSafeCheckReport } = useSafeCheck(
        clientApplication?.uuid,
    )

    const { declineReasons, loading } = useDeclineReasons(
        clientApplication?.uuid,
    )

    const isJoint = clientApplication?.application_type === 'J'

    const handleClickViewCreditReport = () => {
        window.location.href = getSafeCheckReportUrl(clientApplication?.uuid)
    }

    return (
        <Container>
            <CepDeclinedIcon />
            <DeclinedTitle>Declined</DeclinedTitle>
            <BodyText>
                We&apos;re unable to find an eligible program for this
                <br />
                applicant and vehicle.
            </BodyText>
            <DeclineReasonsContainer>
                <BoxHeader>
                    This application was declined due to the following reasons:
                </BoxHeader>
                <DeclineReasonList>
                    {loading ? (
                        <DeclineReasonsSpinnerWrapper data-testid="DecisionResultDeclined-spinner">
                            <Spinner size={24} />
                        </DeclineReasonsSpinnerWrapper>
                    ) : (
                        declineReasons.map((reason) => (
                            <DeclineReasonItem>
                                <StAlertIcon
                                    color={theme.colors.alert.error}
                                    width={20}
                                    height={20}
                                />
                                <DeclineReasonText>{reason}</DeclineReasonText>
                            </DeclineReasonItem>
                        ))
                    )}
                </DeclineReasonList>
                {canUseSafeCheck && hasSafeCheckReport && (
                    <TextLink
                        onClick={handleClickViewCreditReport}
                        data-testid="DecisionResultDeclined-view-credit-report-link"
                    >
                        View Credit Report <UiArrowRightIcon />
                    </TextLink>
                )}
            </DeclineReasonsContainer>
            <ButtonContainer>
                {!isJoint && (
                    <a
                        href={`#/copy-into-new/${clientApplication?.id}/`}
                        data-testid="DecisionResultDeclined-retry-with-coapplicant-button"
                    >
                        <Button>Retry with a Co-Applicant</Button>
                    </a>
                )}
                <a href={`#/copy-vehicle/${clientApplication?.id}/`}>
                    <Button variant="primaryOutlined">
                        Retry with a Different Vehicle
                    </Button>
                </a>
            </ButtonContainer>
        </Container>
    )
}
