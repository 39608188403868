import React, { useCallback } from 'react'

import { Pagination } from '@octane/spark'
import { ColumnDef } from '@tanstack/react-table'
import Table from 'components/Table'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import { setPage } from 'state/dealership'
import { DealershipUser } from 'state/dealership/DealershipState'

import Actions from '../Actions'
import {
    LevelOfAccess,
    UserName,
    applicationAccess,
    PrequalAppAccess,
} from '../TableColumns'
import { PaginationWrapper } from './UsersTable.styled'

dayjs.extend(relativeTime)

const USER_NAME_COLUMN: ColumnDef<DealershipUser> = {
    header: 'User Name',
    accessorKey: 'userName',
    cell: ({ row }) => (
        <UserName
            name={row.original.userName}
            email={row.original.emailAddress}
        />
    ),
    size: 240,
}

const LEVEL_OF_ACCESS_COLUMN: ColumnDef<DealershipUser> = {
    header: 'Level of Access',
    accessorKey: 'levelOfAccess',
    cell: ({ getValue }) => <LevelOfAccess value={getValue()} />,
    size: 150,
}

const APPLICATION_ACCESS_COLUMN: ColumnDef<DealershipUser> = {
    header: 'Application Access',
    accessorKey: 'applicationAccess',
    cell: ({ getValue }) => applicationAccess(getValue()),
    size: 250,
}

const PREQUAL_APP_ACCESS_COLUMN: ColumnDef<DealershipUser> = {
    header: 'Prequal App Access',
    accessorKey: 'prequalAppAccess',
    cell: ({ getValue }) => <PrequalAppAccess value={getValue()} />,
    size: 170,
}

const LAST_ACTIVE_COLUMN: ColumnDef<DealershipUser> = {
    header: 'Last Active',
    accessorKey: 'lastActive',
    size: 200,
    cell: ({ getValue }) => dayjs(getValue()).fromNow(),
}

const ACTIONS_COLUMN: ColumnDef<DealershipUser> = {
    id: 'actions',
    accessorFn: (userInfo) => userInfo,
    cell: ({ getValue }) => <Actions value={getValue()} />,
}

function UsersTable(): JSX.Element {
    const dispatch = useAppDispatch()
    const dealershipState = useAppSelector((state) => state.dealership.users)
    const dealershipUsers: DealershipUser[] = dealershipState.data
    const { page, pages } = dealershipState.meta

    const handleChangePage = useCallback(
        (nextPage: number) => {
            dispatch(setPage({ page: nextPage, entityType: 'users' }))
        },
        [dispatch],
    )

    return (
        <>
            <Table
                columns={[
                    USER_NAME_COLUMN,
                    LEVEL_OF_ACCESS_COLUMN,
                    APPLICATION_ACCESS_COLUMN,
                    PREQUAL_APP_ACCESS_COLUMN,
                    LAST_ACTIVE_COLUMN,
                    ACTIONS_COLUMN,
                ]}
                data={dealershipUsers}
            />
            <PaginationWrapper>
                <Pagination.PaginationBar
                    rounded
                    currentPage={page}
                    pageCount={pages}
                    onChangePage={handleChangePage}
                />
            </PaginationWrapper>
        </>
    )
}

export default React.memo(UsersTable)
