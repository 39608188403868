import React from 'react'

import { Container, Image, ImageViewerViewport } from './ImageViewer.styled'

export type Props = {
    imageRef: React.RefObject<HTMLImageElement>
    containerRef: React.RefObject<HTMLDivElement>
    isLandscape: boolean
    blockDownload?: boolean
    handleFileLoad: (img: HTMLImageElement) => void
    handleImgDragging: (e: MouseEvent) => void
    handleImgWheel: (e: WheelEvent) => void
    handleContextMenu: (blockDownload: boolean, e: Event) => void
    documentUrl: string
}

function ImageViewer({
    imageRef,
    containerRef,
    isLandscape,
    handleFileLoad,
    handleImgDragging,
    handleImgWheel,
    handleContextMenu,
    documentUrl,
    blockDownload,
}: Props): JSX.Element {
    return (
        <Container>
            <ImageViewerViewport ref={containerRef}>
                <div ref={imageRef} style={{ marginBottom: '30px' }}>
                    <Image
                        isLandscape={isLandscape}
                        alt=""
                        onLoad={(
                            e: React.SyntheticEvent<HTMLImageElement, Event>,
                        ) => {
                            handleFileLoad(e.target as HTMLImageElement)
                        }}
                        onDrag={(e: React.DragEvent<HTMLImageElement>) =>
                            handleImgDragging(e.nativeEvent)
                        }
                        onWheel={(e: React.WheelEvent<HTMLImageElement>) =>
                            handleImgWheel(e.nativeEvent)
                        }
                        onContextMenu={(
                            e: React.MouseEvent<HTMLImageElement, MouseEvent>,
                        ) => handleContextMenu(!!blockDownload, e.nativeEvent)}
                        src={documentUrl}
                    />
                </div>
            </ImageViewerViewport>
        </Container>
    )
}

ImageViewer.defaultProps = {
    blockDownload: true,
}

export default ImageViewer
