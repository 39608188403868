import styled from 'styled-components'

export const HeaderTitle = styled.span`
    ${({ theme }) => theme.fonts.Karla['s24-ln32-fw800']};
`

export const HeaderSubtitle = styled.span`
    ${({ theme }) => theme.fonts.Karla['s16-ln20-fw400']};
`

export const FinePrint = styled.div`
    ${({ theme }) => theme.fonts.Karla['s16-ln20-fw400']};
    padding-bottom: 10px;
`

export const FieldStyle = styled.div`
    padding-top: 16px;

    div[id^='wrapper-input'] {
        padding-top: 20px;
    }
`

export const FormContainer = styled.div`
    margin: auto;
    margin-top: 50px;
`

export const StatusIndicatorStyle = styled.div`
    padding-top: 5px;
`
