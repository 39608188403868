import axios, { AxiosError } from 'axios'
import config from 'config'
import convertObjectToQueryString from 'utils/convertObjectToQueryString'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface GetVehiclesOptions {
    condition: 'new' | 'used'
    year: number
    makeId: number
    modelId: number
    vehicleId?: number
    lenderId?: number
    disable?: boolean
    dataSource?: string[]
    assetType?: string
    primaryVehicleId?: number
}

export interface Vehicle {
    vehicle_type: string
    averageRetail?: unknown
    averageWholesale?: unknown
    cc: number
    condition: string
    cylinders?: unknown
    dataSource?: string
    dataSourceId?: number
    highTrade?: number
    id: number
    image?: string
    isFinanceable: boolean
    isLeasable: boolean
    lowRetail?: unknown
    lowTrade?: unknown
    make: string
    model: string
    msrp: number
    name: string
    oem: string
    stroke?: unknown
    transmission?: unknown
    uuid: string
    vehicleType: string
    weight?: unknown
    year: number
    trim?: string
}

interface ResponseVehicle {
    average_retail?: unknown
    average_wholesale?: unknown
    cc: number
    condition: string
    cylinders?: unknown
    data_source?: string
    data_source_id?: number
    high_trade?: number
    id: number
    image?: string
    is_financeable: boolean
    is_leasable: boolean
    low_retail?: unknown
    low_trade?: unknown
    make: string
    model: string
    msrp: number
    name: string
    oem: string
    stroke?: unknown
    transmission?: unknown
    uuid: string
    vehicle_type: string
    weight?: unknown
    year: number
    trim?: string
}

export async function getVehicleDetails(uuid: string) {
    const url = `${config.API_BASE_URL}/api/v3/vehicles/${uuid}/`

    try {
        const { data } = await axios.get(url, { withCredentials: true })

        return {
            averageRetail: data.average_retail,
            averageWholesale: data.average_wholesale,
            cc: data.cc,
            condition: data.condition,
            cylinders: data.cylinders,
            dataSource: data.data_source,
            dataSourceId: data.data_source_id,
            highTrade: data.high_trade,
            id: data.id,
            image: data.image,
            isFinanceable: data.is_financeable,
            isLeasable: data.is_leasable,
            lowRetail: data.low_retail,
            lowTrade: data.low_trade,
            make: data.make,
            model: data.model,
            msrp: data.msrp,
            name: data.name,
            oem: data.oem,
            stroke: data.stroke,
            transmission: data.transmission,
            uuid: data.uuid,
            vehicleType: data.vehicle_type,
            weight: data.weight,
            year: data.year,
            trim: data.trim,
        } as Vehicle
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get vehicle details: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}

export default async function getVehicles(
    options: GetVehiclesOptions,
    abortController: AbortController,
) {
    let url = `${config.API_BASE_URL}/api/v3/vehicles/`
    let dataSourceParam = ''

    if (options.dataSource) {
        dataSourceParam = options.dataSource.join(',')
    }

    const queryString = convertObjectToQueryString(
        {
            condition: options.condition,
            year: options.year,
            make_id: options.makeId,
            model_id: options.modelId,
            vehicle_id: options.vehicleId,
            lender_id: options.lenderId,
            disable: options.disable,
            data_source: dataSourceParam,
            asset_type: options.assetType,
            primary_vehicle_id: options.primaryVehicleId,
            limit: 5000,
            offset: 0,
        },
        true,
    )

    url += `?${queryString}`

    try {
        const { data } = await axios.get(url, {
            signal: abortController.signal,
            withCredentials: true,
        })

        const vehicles = data.results.map((result: ResponseVehicle) => ({
            averageRetail: result.average_retail,
            averageWholesale: result.average_wholesale,
            cc: result.cc,
            condition: result.condition,
            cylinders: result.cylinders,
            dataSource: result.data_source,
            dataSourceId: result.data_source_id,
            highTrade: result.high_trade,
            id: result.id,
            image: result.image,
            isFinanceable: result.is_financeable,
            isLeasable: result.is_leasable,
            lowRetail: result.low_retail,
            lowTrade: result.low_trade,
            make: result.make,
            model: result.model,
            msrp: result.msrp,
            name: result.name,
            oem: result.oem,
            stroke: result.stroke,
            transmission: result.transmission,
            uuid: result.uuid,
            vehicleType: result.vehicle_type,
            weight: result.weight,
            year: result.year,
            trim: result.trim,
        }))

        return vehicles as Vehicle[]
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get vehicles: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
