import React from 'react'

import { Container, Header, Title, Subtitle } from './FormSection.styled'

type Props = {
    title: string
    subtitle?: string
    children: React.ReactNode
    width?: string
    margin?: string
    bottomMargin?: string
}

const defaultProps = {
    subtitle: '',
    width: undefined,
    margin: undefined,
    bottomMargin: undefined,
}

function FormSection({
    title,
    subtitle,
    children,
    width,
    margin,
    bottomMargin,
    ...props
}: Props) {
    return (
        <Container
            {...props}
            width={width}
            margin={margin}
            bottomMargin={bottomMargin}
        >
            {title && (
                <Header>
                    <Title>{title}</Title>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </Header>
            )}
            {children}
        </Container>
    )
}

FormSection.defaultProps = defaultProps

export default FormSection
