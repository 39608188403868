/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'

import { TextInput } from '@octane/spark'
import Card from 'components/Card'
import useUser from 'hooks/useUser'
import { applicationAccessOptions } from 'utils/consts/securityOptions'

import { Information } from '../GeneralInfo/GeneralInfo.styled'
import { Container } from './AccessInfo.styled'

export default function AccessInfo() {
    const { user } = useUser()

    const renderApplicationLabel = () => {
        return applicationAccessOptions.find(
            (item) => item.value === user?.applicationAccess,
        )?.name
    }

    return (
        <Card
            title="Access Info"
            rightComponent={
                <Information>
                    Contact your administrators to change.
                </Information>
            }
            data-testid="access-info"
        >
            <Container>
                <TextInput
                    id="accessInfoRole"
                    onChange={() => {}}
                    label="Your Role"
                    value={user?.levelOfAccess}
                    disabled
                    info={
                        <small>
                            The role given to you when your account was created.
                            Contact an administrator if you need it changed.
                        </small>
                    }
                />
                <TextInput
                    id="accessInfoRole"
                    onChange={() => {}}
                    label="Application Access Level"
                    value={renderApplicationLabel() || ''}
                    disabled
                    info={
                        <small>
                            Defines which applications you have access to.
                        </small>
                    }
                />
            </Container>
        </Card>
    )
}
