import { FeatureFlagContextInterface } from 'contexts/FeatureFlagContextProvider'
import AssetType from 'state/enums/AssetType'

import isLightspeed from './lightspeed'

const LEGACY_READ_ONLY_PAGE = '#!/application'
const NEW_READ_ONLY_PAGE = '#/application'

const makePath = (path: string, applicationId: number) => {
    return `${path}/${applicationId}/`
}

export default (
    featureFlags: FeatureFlagContextInterface | null,
    assetType: AssetType | undefined,
    applicationId: number,
) => {
    if (
        assetType === AssetType.POWERSPORTS_VEHICLE &&
        featureFlags?.routePsAppToNewReadOnlyPage &&
        !isLightspeed()
    ) {
        return makePath(NEW_READ_ONLY_PAGE, applicationId)
    }

    return makePath(LEGACY_READ_ONLY_PAGE, applicationId)
}
