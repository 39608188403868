import axios, { AxiosError } from 'axios'
import config from 'config'
import AssetType from 'state/enums/AssetType'
import VehicleType from 'state/enums/VehicleType'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export interface CustomerAllowedState {
    name: string
    value: string
}

export default async function getCustomersAllowedStates(
    {
        assetType,
        vehicleType,
    }: {
        assetType: AssetType
        vehicleType: VehicleType | undefined
    },
    abortController: AbortController,
): Promise<CustomerAllowedState[]> {
    // eslint-disable-next-line max-len
    const url = `${config.API_BASE_URL}/api/v3/applications/state-eligibilities/`

    try {
        const response = await axios.get(url, {
            signal: abortController.signal,
            withCredentials: true,
            params: {
                asset_type: assetType,
                vehicle_type: vehicleType,
            },
        })

        return response.data as CustomerAllowedState[]
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get state eligibilities: ${axiosError.message}`,
            axiosError,
        )

        throw new Error(axiosError.message)
    }
}
