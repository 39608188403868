import {
    FIELD_TOO_LONG,
    FIELD_REQUIRED,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

export const UserInvitationSchema = Yup.object().shape({
    firstName: Yup.string()
        .max(30, FIELD_TOO_LONG(30))
        .required(FIELD_REQUIRED),
    lastName: Yup.string().max(30, FIELD_TOO_LONG(30)).required(FIELD_REQUIRED),
    emailAddress: Yup.string()
        .label('Email Address')
        .max(75, FIELD_TOO_LONG(75))
        .email()
        .required(FIELD_REQUIRED),
    levelOfAccess: Yup.string().required(FIELD_REQUIRED),
    applicationAccess: Yup.string().required(FIELD_REQUIRED),
    phoneNumber: Yup.string().when('levelOfAccess', {
        is: 'Sales',
        then: Yup.string()
            .required(FIELD_REQUIRED)
            .min(10, 'Phone number needs to be 10 digits exactly.')
            .max(10, 'Phone number needs to be 10 digits exactly.'),
    }),
})
