/* eslint-disable max-len */
import {
    FIELD_TOO_SHORT,
    FIELD_TOO_LONG,
    FIELD_REQUIRED,
    FIELD_INVALID_EMAIL,
    FIELD_INVALID_PHONE,
    FIELD_INVALID_EMAIL_TOO_LONG,
} from 'form/validation/validationMessages'
import * as Yup from 'yup'

const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const GeneralInfoSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, FIELD_TOO_SHORT(2))
        .max(30, FIELD_TOO_LONG(30))
        .required(FIELD_REQUIRED),
    lastName: Yup.string()
        .min(2, FIELD_TOO_SHORT(2))
        .max(30, FIELD_TOO_LONG(30))
        .required(FIELD_REQUIRED),
    email: Yup.string()
        .email(FIELD_INVALID_EMAIL)
        .max(75, FIELD_INVALID_EMAIL_TOO_LONG)
        .required(FIELD_REQUIRED),
    phone: Yup.string()
        .matches(phoneRegExp, FIELD_INVALID_PHONE)
        .required(FIELD_REQUIRED),
})

export default GeneralInfoSchema
