import React, { useEffect, useState } from 'react'

import { Button } from '@octane/spark'
import { getNotifications } from 'api/notifications'
import useUser from 'hooks/useUser'
import * as toast from 'utils/toast'

import Notification from './Notification'
import {
    Container,
    Header,
    PrimaryEmail,
    RefreshIconWrapper,
    SpinnerIcon,
    Title,
} from './Notifications.styled'

function Notifications() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [notifications, setNotifications] = useState<any[]>([])
    const [spin, setSpin] = useState<boolean>(false)
    const { user } = useUser()

    const fetchNotifications = () => {
        setSpin(true)

        getNotifications()
            .then((data) => setNotifications(data.notifications))
            .catch((error) => toast.error({ message: error.message }))
            .finally(() => setTimeout(() => setSpin(false), 300))
    }

    useEffect(() => {
        fetchNotifications()
    }, [])

    return (
        <Container>
            <Header>
                <Title>
                    <div>Notifications</div>
                    <RefreshIconWrapper>
                        <Button
                            fullWidth={false}
                            size="small"
                            variant="white"
                            onClick={fetchNotifications}
                        >
                            <SpinnerIcon
                                $spin={spin}
                                color="black"
                                height={24}
                                width={24}
                            />
                        </Button>
                    </RefreshIconWrapper>
                </Title>
                <PrimaryEmail>
                    The primary email address for this account is {user?.email}.
                </PrimaryEmail>
            </Header>
            {notifications.map((notification) => (
                <Notification
                    key={notification.name}
                    notification={notification}
                    defaultEmail={user?.email}
                    callback={fetchNotifications}
                />
            ))}
        </Container>
    )
}

export default Notifications
