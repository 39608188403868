import { Button } from '@octane/spark'
import { typography } from 'assets/styles'
import styled from 'styled-components'

export const MfaModalText = styled.p`
    ${typography.BodyCopy16};
    margin: 0 0 15px 0;
`

export const MfaModalFooterText = styled.p`
    margin: 15px 0 0 0;
    line-height: 25px;
    color: ${(props) => props.theme.colors.monochrome.graphite};
    a {
        color: ${(props) => props.theme.colors.brand.rideOctaneBlue};
        text-decoration: underline;
    }
`

export const MfaModalButtonWrapper = styled.div`
    margin: 15px 0 0 0;
`
export const MfaModalTitle = styled.h2`
    margin-top: 0;
`
export const MfaModalConfirmation = styled.div`
    text-align: center;
    svg {
        margin: 0 auto;
    }
`
export const MfaLinkButton = styled(Button)`
    text-decoration: underline;
    height: 30px;
    margin-top: 10px;
    font-weight: normal;
    cursor: pointer;
`
