import React from 'react'

import { Modal } from '@octane/spark'
import { camelizeKeys } from 'humps'

import ReturnCustomer from '../ReturnCustomer'
import {
    ActiveLoanType,
    ApplicationDataType,
} from '../types/returnCustomerTypes'

type ReturnCustomerModalProps = {
    readonly applicationData: ApplicationDataType | undefined
    readonly activeLoans: ActiveLoanType[]
    isOpen: boolean
    handleClose: () => void
}

function ReturnCustomerModal({
    applicationData,
    activeLoans,
    isOpen,
    handleClose,
}: Readonly<ReturnCustomerModalProps>): JSX.Element {
    return (
        <Modal
            isOpen={isOpen}
            scroll="body"
            size="large"
            onClose={() => handleClose()}
        >
            <Modal.Body>
                <ReturnCustomer
                    applicationData={camelizeKeys(applicationData)}
                    activeLoans={activeLoans}
                    modal={isOpen}
                />
            </Modal.Body>
        </Modal>
    )
}

export default ReturnCustomerModal
