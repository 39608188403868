import React, { useState } from 'react'

import { Button, Modal } from '@octane/spark'
import Card from 'components/Card'
import ChangePassword from 'containers/MyAccount/ChangePassword'
import ResetPasswordRequest from 'containers/MyAccount/ResetPassword'
import useUser from 'hooks/useUser'

import { Body, InfoMessage } from './YourPassword.styled'

export default function YourPassword(): JSX.Element {
    const [resetPassOpen, setResetPassOpen] = useState(false)
    const [changePassOpen, setChangePassOpen] = useState(false)
    const { user } = useUser()

    return (
        <Card data-testid="your-password-card" title="Your Password">
            <Body>
                <div>
                    <InfoMessage>{user?.passwordInfoMessage}</InfoMessage>
                    <Button
                        fullWidth={false}
                        data-testid="button-change-password"
                        id="button-change-password"
                        onClick={() => setChangePassOpen(true)}
                    >
                        Change My Password
                    </Button>
                </div>
                <div>
                    Did you forget your password? You can reset it.
                    <Button
                        fullWidth={false}
                        variant="secondary"
                        id="button-reset-password"
                        onClick={() => setResetPassOpen(true)}
                    >
                        Reset My Password
                    </Button>
                </div>
                <Modal
                    scroll="body"
                    title="Change My Password"
                    isOpen={changePassOpen}
                    onClose={() => setChangePassOpen(false)}
                >
                    <Modal.Body>
                        <ChangePassword
                            onSuccess={() => setChangePassOpen(false)}
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    isOpen={resetPassOpen}
                    scroll="body"
                    title="Reset My Password"
                    onClose={() => setResetPassOpen(false)}
                >
                    <Modal.Body>
                        <ResetPasswordRequest />
                    </Modal.Body>
                </Modal>
            </Body>
        </Card>
    )
}
