import React, { useEffect, useState } from 'react'

// eslint-disable-next-line import/no-unresolved
import Information from 'components/Icons/Information.svg'
import FadeIn from 'utils/animations/FadeIn.styled'

import { Banner, BannerText } from './InformationSection.styled'

const SESSION_STORAGE_KEY = 'InformationSection'

function getSessionStorageKey(id: string) {
    return `${SESSION_STORAGE_KEY}_${id}`
}

function getSessionStorageOrDefault(key: string, defaultValue: boolean) {
    const stored = sessionStorage.getItem(key)

    if (!stored) {
        return defaultValue
    }

    return JSON.parse(stored)
}

type InformationSectionProps = {
    id: string
    bannerText?: string
    icon?: string
    padding?: string
    height?: string
}

const defaultProps = {
    bannerText: '',
    icon: undefined,
    padding: undefined,
    height: undefined,
}

export default function InformationSection({
    id,
    bannerText,
    icon,
    padding,
    height,
}: InformationSectionProps) {
    const sessionStorageKey = getSessionStorageKey(id)

    const [bannerVisibility] = useState(
        getSessionStorageOrDefault(sessionStorageKey, true),
    )

    useEffect(() => {
        window.sessionStorage.setItem(
            sessionStorageKey,
            String(bannerVisibility),
        )
    }, [bannerVisibility, sessionStorageKey])

    if (!bannerVisibility) return null

    return (
        <FadeIn delay={100} duration={350}>
            <Banner
                className="information_section"
                data-testid="information_section"
                padding={padding}
                height={height}
            >
                {icon && <img src={Information} alt="Information" />}
                {bannerText && <BannerText>{bannerText}</BannerText>}
            </Banner>
        </FadeIn>
    )
}

InformationSection.defaultProps = defaultProps
