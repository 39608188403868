import React from 'react'

import { InfoContainer, InfoField, InfoLabel, InfoData } from './Info.styled'

type Props = {
    children: React.ReactNode
}

function Info({ children }: Props) {
    return <InfoContainer>{children}</InfoContainer>
}
function Field({ children }: Props) {
    return <InfoField>{children}</InfoField>
}
function Label({ children }: Props) {
    return <InfoLabel data-testid="info-label">{children}</InfoLabel>
}
function Data({ children }: Props) {
    return <InfoData>{children}</InfoData>
}

Info.Field = Field
Info.Label = Label
Info.Data = Data

export default Info
