import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const DropdownWrapper = styled.div`
    display: block;
`

export const User = styled.p`
    align-items: center;
`
