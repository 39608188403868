import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, CepPasswordUpdateIcon, UiArrowBackIcon } from '@octane/spark'
import brandConfig from 'branding'
import Card from 'components/Card'

import { ConfirmationContainer, Title } from './ResetPasswordPage.styled'

export default function EmailConfirmation(): JSX.Element {
    const navigate = useNavigate()

    return (
        <>
            <Card>
                <ConfirmationContainer>
                    <div>
                        <CepPasswordUpdateIcon
                            className="icon"
                            height={140}
                            width={140}
                            color=""
                        />
                    </div>
                    <div>
                        <Title className="title">Check Your Email</Title>
                        <p>
                            You should receive an email within 15 minutes
                            containing password reset information.{' '}
                            <strong>
                                If you don’t receive the instructions within 15
                                minutes please reach out to our support team.
                            </strong>
                        </p>
                        <p>
                            Password reset support email:{' '}
                            <a href="mailto:passwordhelp@octanelending.com">
                                passwordhelp@octanelending.com
                            </a>
                        </p>
                    </div>
                </ConfirmationContainer>
            </Card>
            <Button
                fullWidth={false}
                startIcon={UiArrowBackIcon}
                size="small"
                variant="white"
                onClick={() => navigate('/')}
            >
                Back to {brandConfig.name} Login
            </Button>
        </>
    )
}
