import styled from 'styled-components'

export const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1em;
    & > div {
        width: 50%;
    }
    @media (max-width: 1200px) {
        flex-direction: column;
        & > div {
            width: 100%;
        }
    }
`
export const InfoField = styled.div`
    display: flex;
    margin: 1.5em 0;
    &:last-child {
        margin-bottom: 0;
    }
    &:first-child {
        margin-top: 1em;
    }
`
export const InfoLabel = styled.dt`
    width: 12em;
    font-weight: normal;
    color: ${(props) => props.theme.colors.monochrome.graphite};
    margin-right: 1em;
    width: 35%;
`
export const InfoData = styled.dd`
    max-width: 1599px;
    font-weight: 500;
    margin-left: 0;
    width: 65%;
    overflow-wrap: break-word;
`
