import React, { useEffect, useState } from 'react'

import getYears, { GetYearsOptions, Year } from 'api/vehicles/getYears'
import { FormikDropdown } from 'components/FormikFields'
import { FormikDropdownChild } from 'components/FormikFields/FormikDropdown/FormikDropdown'
import { setFieldValueAndTouched } from 'form/fields/utils/fieldUtils'
import { OctaneLogger } from 'integrations/datadog/OctaneLogger'

interface queryProps {
    lenderId?: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    dataSource?: string[]
    primaryVehicleId?: number
    assetType?: unknown
    vehicleNumber: number
    prefilled?: boolean
    vehicleTypeList?: []
}

const defaultProps = {
    lenderId: null,
    dataSource: null,
    primaryVehicleId: null,
    assetType: null,
    prefilled: false,
    vehicleTypeList: null,
}

export default function YearDropdown({
    lenderId,
    formik,
    dataSource,
    primaryVehicleId,
    assetType,
    vehicleNumber,
    prefilled,
    vehicleTypeList,
}: queryProps) {
    const [response, updateResponse] = useState([] as Year[])
    const { vehicle } = formik.values
    const { condition } = vehicle
    const FIRST_VEHICLE = 1
    const IS_FIRST_VEHICLE = vehicleNumber === FIRST_VEHICLE

    const vehicleFieldName = IS_FIRST_VEHICLE
        ? 'vehicle.primary.year'
        : 'vehicle.secondary.year'

    const year = IS_FIRST_VEHICLE
        ? vehicle.primary.year
        : vehicle.secondary.year

    function getYearsForVehicle(abortController: AbortController) {
        const getYearsOptions = {
            condition,
            lenderId,
            dataSource,
            assetType,
            primaryVehicleId,
            vehicleTypeList,
        } as GetYearsOptions

        if (!IS_FIRST_VEHICLE) {
            // eslint-disable-next-line prefer-destructuring
            getYearsOptions.primaryVehicleId =
                formik.values.vehicle.primary.selectedVehicleId.split(':')[0]
        }

        getYears(getYearsOptions, abortController)
            .then((years: Year[]) => {
                updateResponse(years)

                if (years.filter((e) => `${e.id}` === year).length === 0) {
                    formik.setFieldValue(
                        IS_FIRST_VEHICLE
                            ? 'vehicle.primary.year'
                            : 'vehicle.secondary.year',
                        '',
                    )

                    if (IS_FIRST_VEHICLE) {
                        formik.setFieldValue('vehicle.makeId', '')
                    }

                    formik.setFieldValue(
                        IS_FIRST_VEHICLE
                            ? 'vehicle.primary.modelId'
                            : 'vehicle.secondary.modelId',
                        '',
                    )

                    formik.setFieldValue(
                        IS_FIRST_VEHICLE
                            ? 'vehicle.primary.selectedVehicleId'
                            : 'vehicle.secondary.selectedVehicleId',
                        '',
                    )

                    if (IS_FIRST_VEHICLE) {
                        formik.setFieldValue('vehicle.vehicleType', '')
                    }
                }

                if (years.length === 1) {
                    setFieldValueAndTouched(
                        formik,
                        vehicleFieldName,
                        years[0].id.toString(),
                    )
                }
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    OctaneLogger.error(
                        `Unexpected error when trying to load years.`,
                        undefined,
                        error as Error,
                    )
                }
            })
    }

    useEffect(() => {
        const abortController = new AbortController()

        if (condition && !prefilled) {
            updateResponse([])

            getYearsForVehicle(abortController)
        }

        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition])

    useEffect(() => {
        const abortController = new AbortController()

        if (
            formik.values.vehicle.primary.selectedVehicleId !== '' &&
            !prefilled
        ) {
            getYearsForVehicle(abortController)
        }

        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.vehicle.primary.selectedVehicleId])

    useEffect(() => {
        const abortController = new AbortController()

        if (!prefilled) {
            getYearsForVehicle(abortController)
        }

        return () => abortController.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefilled])

    if (response.length < 1 && !prefilled) {
        return (
            <FormikDropdown
                name={
                    IS_FIRST_VEHICLE
                        ? 'vehicle.primary.year'
                        : 'vehicle.secondary.year'
                }
                label="Year"
                placeholder="Select Year"
                disabled
            >
                {{ name: '', value: '' }}
                {{ name: '', value: '' }}
            </FormikDropdown>
        )
    }

    if (prefilled) {
        return (
            <FormikDropdown
                name={
                    IS_FIRST_VEHICLE
                        ? 'vehicle.primary.year'
                        : 'vehicle.secondary.year'
                }
                label="Year"
                placeholder=""
                prefilled={prefilled}
            >
                {{
                    name: vehicle.primary.year,
                    value: vehicle.primary.year,
                }}
                {{
                    name: vehicle.secondary.year,
                    value: vehicle.secondary.year,
                }}
            </FormikDropdown>
        )
    }

    return (
        <FormikDropdown
            name={
                IS_FIRST_VEHICLE
                    ? 'vehicle.primary.year'
                    : 'vehicle.secondary.year'
            }
            label="Year"
            placeholder="Select Year"
            disabled={response.length === 0}
            prefilled={prefilled}
            error={year === null || year === ''}
            searchable
            searchPlaceholder="Search years..."
        >
            {response.map((child: Year) => {
                const newBorn: FormikDropdownChild = {
                    name: child.id.toString(),
                    value: child.id.toString(),
                }

                return newBorn
            })}
        </FormikDropdown>
    )
}

YearDropdown.defaultProps = defaultProps
