import { FieldInstruction } from 'containers/FormBuilder'
import VehicleWriteInBaseField from 'form/fieldComponents/VehicleWriteInBaseField'
import {
    FIELD_GREATER_THAN_ZERO,
    FIELD_MAX_VALUE_USED_MILEAGE,
    FIELD_REQUIRED,
    FIELD_TOO_LONG,
} from 'form/validation/validationMessages'
import AssetType from 'state/enums/AssetType'
import { BookingSource } from 'utils/bookingSourceHelper'
import * as Yup from 'yup'

const automobileWriteInSchema = Yup.object().shape({
    condition: Yup.string().required(),
    year: Yup.number().required(FIELD_REQUIRED).moreThan(0, FIELD_REQUIRED),
    make: Yup.string().required(FIELD_REQUIRED),
    model: Yup.string()
        .max(200, FIELD_TOO_LONG(200))
        .matches(
            /^[A-Za-z0-9 \-.()/&,'"*+!:#]*$/,
            'Only the following special characters are allowed: - . ( ) / & , \' " * + ! : #', // eslint-disable-line max-len
        )
        .required(FIELD_REQUIRED),
    trim: Yup.string()
        .max(200, FIELD_TOO_LONG(200))
        .matches(
            /^[A-Za-z0-9 \-.()/&,'"*+!:#]*$/,
            'Only the following special characters are allowed: - . ( ) / & , \' " * + ! : #', // eslint-disable-line max-len
        )
        .required(FIELD_REQUIRED),
    invoice: Yup.number().when('condition', {
        is: 'new',
        then: Yup.number()
            .required(FIELD_REQUIRED)
            .moreThan(0, FIELD_GREATER_THAN_ZERO),
    }),
    bookingSource: Yup.string().when('condition', {
        is: 'used',
        then: Yup.string().required(FIELD_REQUIRED),
    }),
    mileage: Yup.number().when('condition', {
        is: 'used',
        then: Yup.number()
            .required(FIELD_REQUIRED)
            .integer()
            .moreThan(0, FIELD_GREATER_THAN_ZERO)
            .max(120000, FIELD_MAX_VALUE_USED_MILEAGE),
    }),
    wholesale: Yup.number().when(['condition', 'bookingSource'], {
        is: (condition: string, bookingSource: string) =>
            condition === 'used' &&
            bookingSource !== BookingSource.LIKE_INVOICE,
        then: Yup.number()
            .required(FIELD_REQUIRED)
            .moreThan(0, FIELD_GREATER_THAN_ZERO),
    }),
    likeInvoice: Yup.number().when(['condition', 'bookingSource'], {
        is: (condition: string, bookingSource: string) =>
            condition === 'used' &&
            bookingSource === BookingSource.LIKE_INVOICE,
        then: Yup.number()
            .required(FIELD_REQUIRED)
            .moreThan(0, FIELD_GREATER_THAN_ZERO),
    }),
})

export const vehicleWriteInAutomobile: FieldInstruction<
    typeof VehicleWriteInBaseField
> = {
    Component: VehicleWriteInBaseField,
    config: {
        initialValue: {
            condition: 'new',
            year: '',
            make: '',
            model: '',
            trim: '',
            invoice: '',
            bookingSource: '',
            mileage: '',
            wholesale: '',
        },
        assetType: AssetType.AUTOMOBILE,
        validator: automobileWriteInSchema,
    },
}
