import axios, { AxiosError } from 'axios'
import config from 'config'

import { UseUserInterface } from '../../hooks/types/useUser.types'
import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

export async function getDashboard(userInterface: UseUserInterface) {
    // Use /dev- prefix for dev data
    const url = `${config.LOYALTY_DASHBOARD_BASE_URL}/${userInterface.user?.uuid}.json`

    try {
        const { data } = await axios.get(url)

        return data
    } catch (error) {
        const axiosError = error as AxiosError
        // Notify if we're missing the json

        if (userInterface.isAutoDealership) {
            OctaneLogger.axiosError(
                `Failed to load dashboard JSON for ${userInterface.user?.uuid}: ${axiosError.message}`,
                axiosError,
                {
                    dealership: userInterface.user?.dealership?.id,
                    user: userInterface.user?.id,
                    json: url,
                },
            )
        }

        throw new Error(axiosError.message)
    }
}

export default getDashboard
