import axios from 'axios'
import config from 'config'

export type FlagFormat = {
    flags: Record<string, { is_active: boolean }>
    switches: Record<string, { is_active: boolean }>
}

export default async function getCurrentFeatureFlags() {
    try {
        const flags = await axios.get(
            `${config.API_BASE_URL}/feature_flags/waffle_status`,
            {
                withCredentials: true,
            },
        )

        return flags.data as FlagFormat
    } catch (error) {
        return { flags: {}, switches: {} } as FlagFormat
    }
}
