import { ButtonCompact16 } from 'assets/styles/typography'
import styled from 'styled-components'

import { EnrollmentStatus } from './types.d'

export const Body = styled.div`
    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px 0;
        border-bottom: 1px solid
            ${({ theme }) => theme.colors.monochrome.concrete};

        span > strong {
            display: block;
            margin-bottom: 5px;
        }

        &:last-child {
            border-bottom: 0;
        }
    }
`

export const Status = styled.div<{ status: EnrollmentStatus }>`
    ${ButtonCompact16};
    padding: 4px 10px;
    width: 172px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    ${({ status, theme }) =>
        status === EnrollmentStatus.ENROLLED
            ? `
                background-color: ${theme.colors.alert.success}15;
                color: ${theme.colors.brand.rideOctaneGreen};
            `
            : ''}

    ${({ status, theme }) =>
        status === EnrollmentStatus.NEEDS_SAFECHECK ||
        status === EnrollmentStatus.NEEDS_MFA
            ? `
                    background-color: ${theme.colors.brand.rideOctaneBlue};
                    color: ${theme.colors.monochrome.white};
                    cursor: pointer;
                    &:hover {
                        opacity: 0.9;
                    }
                `
            : ''}
    & > svg {
        margin-right: 6px;
        fill: ${({ theme }) => theme.colors.brand.rideOctaneGreen};
    }
`
