import React from 'react'

import { Checkbox, UiCloseIcon } from '@octane/spark'
import { pushFilterSet } from 'utils/gtm-utils'

import { FilterProps } from './LeadsFilter.types'
import { Container, Header, RowItem } from './LeadsFilterContent.styled'

type FilterContentProps = FilterProps & {
    onClose?: () => void
}

function LeadsFilterContent({
    prequalifiedChecked,
    declinedChecked,
    onChangePrequalified,
    onChangeDeclined,
    onClose,
}: FilterContentProps) {
    return (
        <Container>
            <Header>
                <span>Decision</span>
                <UiCloseIcon
                    width={15}
                    height={15}
                    role="button"
                    onClick={onClose}
                />
            </Header>
            <RowItem>
                <Checkbox
                    id="prequalified-checkbox"
                    checked={prequalifiedChecked}
                    label="Prequalified leads"
                    value="Approved"
                    variant="primary"
                    onChange={(
                        checked,
                        target: HTMLInputElement | undefined,
                    ) => {
                        onChangePrequalified(!prequalifiedChecked)
                        if (target) pushFilterSet(target.value)
                    }}
                />
            </RowItem>
            <RowItem>
                <Checkbox
                    id="declined-checkbox"
                    checked={declinedChecked}
                    label="Declined leads"
                    value="Declined"
                    variant="primary"
                    onChange={(
                        checked,
                        target: HTMLInputElement | undefined,
                    ) => {
                        onChangeDeclined(!declinedChecked)
                        if (target) pushFilterSet(target.value)
                    }}
                />
            </RowItem>
        </Container>
    )
}

LeadsFilterContent.defaultProps = {
    onClose: () => null,
}

export default LeadsFilterContent
