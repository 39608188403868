export const passwordRotationOptions = [
    {
        label: '30 days',
        value: '30',
    },
    {
        label: '60 days',
        value: '60',
    },
    {
        label: '90 days',
        value: '90',
    },
]

export const sessionInactivityOptions = [
    {
        label: '15 minutes',
        value: '15',
    },
    {
        label: '30 minutes',
        value: '30',
    },
]

export const applicationAccessOptions = [
    {
        value: 'Default',
        name: "User's own applications",
    },
    {
        value: 'Finance Manager',
        name: 'All dealership apps',
    },
    {
        value: 'Administrator',
        name: 'All dealership chain apps',
    },
    {
        value: 'Sales',
        name: 'No portal access',
    },
]

export const levelOfAccessOptions = [
    {
        name: 'Admin',
        value: 'Admin',
    },
    {
        name: 'Teammate',
        value: 'Teammate',
    },
    {
        name: 'Sales',
        value: 'Sales',
    },
]
