import React from 'react'

import FormContent from '../../components/FormContent'
import FormGridPlacement from '../../components/FormGridPlacement'
import {
    FormikTextInput,
    FormikAmountField,
} from '../../components/FormikFields'
import {
    isLikeInvoice,
    LENDING_VALUE_TEXT,
} from '../../utils/bookingSourceHelper'
import ConditionTab from '../VehicleSelection/ConditionTab'
import BookingSourceDropdown from '../VehicleSelection/dropdowns/BookingSourceDropdown'
import { MakeDropdown, YearDropdown } from './dropdowns'
import { BorderLine } from './VehicleWriteIn.styled'

interface VehicleSelectionProps {
    assetType?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik?: any
}

const defaultProps = {
    assetType: null,
    formik: null,
}

export default function VehicleWriteIn({
    assetType,
    formik,
}: VehicleSelectionProps) {
    const { values } = formik
    const { vehicle } = values
    const { condition, year, bookingSource, mileage } = vehicle
    let lendingValueFactor = 1.0 // 100% of the value
    const mileageLikeInvoiceLimit = 6000
    const bSLikeInvoiceCondition = isLikeInvoice(year, bookingSource)

    if (bSLikeInvoiceCondition) {
        // 90% if equals or less than 6000 and 80% if greater
        if (mileage && vehicle.likeInvoice) {
            lendingValueFactor = mileage <= mileageLikeInvoiceLimit ? 0.9 : 0.8

            vehicle.lendingValue = vehicle.likeInvoice * lendingValueFactor
        } else vehicle.lendingValue = ''
    }

    const dataSource =
        condition === 'new' ? ['LOS_ADMIN', 'LOS'] : ['LOS_ADMIN', 'NADA']

    return (
        <>
            <FormContent gridTemplate="1fr">
                <ConditionTab />
            </FormContent>
            <BorderLine />
            <FormContent gridTemplate="1fr 1fr">
                <FormGridPlacement gridArea=" 1 / 1 ">
                    <YearDropdown
                        formik={formik}
                        dataSource={dataSource}
                        assetType={assetType}
                    />
                    <MakeDropdown
                        formik={formik}
                        dataSource={dataSource}
                        assetType={assetType}
                    />
                    <FormikTextInput
                        name="vehicle.model"
                        label="Model"
                        disabled={formik.values.vehicle.make === ''}
                    />
                    <FormikTextInput name="vehicle.trim" label="Trim" />
                </FormGridPlacement>
                <FormGridPlacement gridArea=" 1 / 2 ">
                    {condition === 'used' && (
                        <>
                            <BookingSourceDropdown
                                year={formik.values.vehicle.year}
                            />
                            <FormikAmountField
                                name="vehicle.mileage"
                                label="Mileage"
                                placeholder="0"
                                currencySymbol=""
                                omitDecimals
                            />
                            {!bSLikeInvoiceCondition && (
                                <FormikAmountField
                                    name="vehicle.wholesale"
                                    // eslint-disable-next-line max-len
                                    label="Clean Trade in (including add-ons and mileage adjustments)"
                                    placeholder="0"
                                    omitDecimals
                                />
                            )}
                            {bSLikeInvoiceCondition && (
                                <>
                                    <FormikAmountField
                                        name="vehicle.likeInvoice"
                                        label="Like Invoice"
                                        placeholder="0"
                                        omitDecimals
                                    />
                                    <FormikAmountField
                                        name="vehicle.lendingValue"
                                        label="Lending Value"
                                        placeholder="0"
                                        disabled
                                        tooltip={LENDING_VALUE_TEXT}
                                        omitDecimals
                                    />
                                </>
                            )}
                        </>
                    )}
                    {condition === 'new' && (
                        <FormikAmountField
                            name="vehicle.invoice"
                            label="Invoice"
                            placeholder="0"
                            omitDecimals
                        />
                    )}
                </FormGridPlacement>
            </FormContent>
        </>
    )
}

VehicleWriteIn.defaultProps = defaultProps
