/* eslint-disable max-len */
import React, { useEffect } from 'react'

import Card from 'components/Card'
import Select from 'components/Select'
import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import useUser from 'hooks/useUser'
import {
    loadSecuritySettings,
    updateSecuritySettings,
} from 'state/dealership/actions'
import { DealershipSecuritySettings } from 'state/dealership/DealershipState'
import {
    passwordRotationOptions,
    sessionInactivityOptions,
} from 'utils/consts/securityOptions'
import toastConsts from 'utils/consts/toast'
import * as toast from 'utils/toast'

import { Description, SecuritySettingsWrapper } from './SecuritySettings.styled'

export default function SecuritySettings(): JSX.Element {
    const dispatch = useAppDispatch()
    const { user } = useUser()

    const securitySettings = useAppSelector(
        (state) => state.dealership.securitySettings.data,
    )

    const { passwordRotation = '90', sessionInactivity = '30' } =
        securitySettings

    useEffect(() => {
        if (user?.dealership?.securitySettingsId) {
            dispatch(loadSecuritySettings(user?.dealership?.securitySettingsId))
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line
    const handleDispatch = async (action: any, payload: any) => {
        const result = await dispatch(action(payload))

        if (action.fulfilled.match(result)) {
            toast.success({
                title: toastConsts.dealershipSettings.securitySettings.success
                    .title,
                message:
                    toastConsts.dealershipSettings.securitySettings.success
                        .message,
            })
        } else {
            toast.error({
                title: toastConsts.dealershipSettings.securitySettings.error
                    .title,
                message:
                    result.error.message ||
                    toastConsts.dealershipSettings.securitySettings.error
                        .message,
            })
        }
    }

    const handleChangeSecuritySettingsChange = (
        field: 'passwordRotation' | 'sessionInactivity',
        value: string,
    ) => {
        const payload = {
            ...securitySettings,
            [field]: value,
        } as DealershipSecuritySettings

        const securitySettingsId = user?.dealership?.securitySettingsId

        if (securitySettingsId) {
            handleDispatch(updateSecuritySettings, {
                securitySettingsId,
                payload,
            })
        }
    }

    return (
        <div>
            <Card title="Dealership Security Settings">
                <Description>
                    As an admin, you can set security standards for your
                    dealership. This will help keep your dealership’s and
                    customer&apos;s information more secure.
                </Description>

                <SecuritySettingsWrapper>
                    <Select
                        onChange={(val: string) =>
                            handleChangeSecuritySettingsChange(
                                'passwordRotation',
                                val,
                            )
                        }
                        data-testid="password-rotation"
                        value={passwordRotation}
                        label="Require the user to reset their password every..."
                        placeholder="Select something"
                        options={passwordRotationOptions}
                        id="dropdown-password-rotation"
                    />
                    <Select
                        onChange={(val: string) =>
                            handleChangeSecuritySettingsChange(
                                'sessionInactivity',
                                val,
                            )
                        }
                        data-testid="session-inactivity"
                        value={sessionInactivity}
                        label="Log the user out if they have been inactive for..."
                        placeholder="Select something"
                        options={sessionInactivityOptions}
                        id="dropdown-session-inactivity"
                    />
                </SecuritySettingsWrapper>
            </Card>
        </div>
    )
}
