import goodSamConfig from './brands/goodSam'
import octaneConfig from './brands/octane'
import rideNowConfig from './brands/rideNow'
import { Brand, BrandConfig } from './types'

export const BRAND_CONFIG_MAP: { [key in Brand]: BrandConfig } = {
    [Brand.GoodSam]: goodSamConfig,
    [Brand.Octane]: octaneConfig,
    [Brand.RideNow]: rideNowConfig,
}

export function getBrandFromHostname(hostname: string): Brand {
    const brandMatch = Object.values(BRAND_CONFIG_MAP).find(
        (config) => config.hostRegex && config.hostRegex.test(hostname),
    )

    return brandMatch ? brandMatch.brand : Brand.Octane
}

export function getBrandConfig(): BrandConfig {
    const { hostname } = window.location
    const brand = getBrandFromHostname(hostname)

    return BRAND_CONFIG_MAP[brand]
}

export const brandConfig: BrandConfig = getBrandConfig()

export default brandConfig
