import axios, { AxiosError } from 'axios'
import config from 'config'
import AssetSubtype from 'state/enums/AssetSubtype'
import EngineType from 'state/enums/EngineType'
import { isLikeInvoice } from 'utils/bookingSourceHelper'
import { MARINE_INPUT_PLACEHOLDER_VALUE } from 'utils/consts/marine'

import { BoatType } from '../../containers/MarineWriteIn/enums/MarineTypes'
import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

interface Asset {
    condition: string
    year?: number
    make?: string
    model?: string
    trim?: string
    color?: string
    invoice_price?: number
    booking_source?: string
    mileage?: number
    wholesale_price?: number
    like_invoice_price?: number
    boat_type?: string
    hours?: number
    length_ft?: number
}

interface Collateral {
    asset: Asset
    asset_type: string
    asset_value: number
    data_source: string
    asset_subtype?: string
}

// TODO: Blur-647 This assets types are only for Marine but would be a very good idea use it for every single vertical
interface BaseAsset {
    condition: string
    year?: number
    make?: string
    model?: string
}

interface MarineEngineAsset extends BaseAsset {
    invoice_price?: number
    horsepower?: number
    engine_type?: string
    fuel_type?: string
    hours?: number
}

interface MarineTrailerAsset extends BaseAsset {
    name: string
    description: string
    sales_price?: number
}

interface MarineHullAsset extends BaseAsset {
    invoice_price?: number
    oem?: string
    boat_type?: string
    length_ft?: number
    hours?: number
}

type AssetSubType = MarineEngineAsset | MarineHullAsset | MarineTrailerAsset

interface CollateralMarine<E extends BaseAsset> {
    asset: E
    asset_type: string
    asset_value: number
    data_source: string
    asset_subtype?: string
}
// Blur-647: End

interface Marine {
    condition: string
    year: number
    make: string
    model: string
    invoice: number
    hours: number
    type: string
    length_ft: number
    motors: number
    trailer: string
    repower: boolean
}

interface Vehicle {
    condition: string
    year: number
    make: string
    model: string
    trim: string
    color?: string
    invoice?: number
    bookingSource?: string
    mileage?: number
    wholesale?: number
    likeInvoice?: number
    lendingValue?: number
}

export interface PostCollateralsAssetsDTO {
    collateral: Collateral[]
}

function getCollateralAssetValue(vehicle: Vehicle): number {
    let assetValue: number | undefined = 0

    if (vehicle.condition === 'new') assetValue = vehicle.invoice
    else if (!isLikeInvoice(vehicle.year, vehicle.bookingSource))
        assetValue = vehicle.wholesale
    else assetValue = vehicle.lendingValue

    return assetValue ?? 0
}

export function createPostCollateralsAssetsDTO(
    vehicle: Vehicle,
): PostCollateralsAssetsDTO {
    const dto: PostCollateralsAssetsDTO = {
        collateral: [
            {
                asset: {
                    condition: vehicle.condition === 'new' ? 'N' : 'U',
                    year: vehicle.year,
                    make: vehicle.make,
                    model: vehicle.model,
                    trim: vehicle.trim,
                    invoice_price: vehicle.invoice,
                    booking_source: vehicle.bookingSource,
                    wholesale_price: vehicle.wholesale,
                    like_invoice_price: vehicle.likeInvoice,
                },
                asset_type: 'automobile',
                asset_value: getCollateralAssetValue(vehicle),
                data_source: 'write_in',
            },
        ],
    }

    // the initial form value for mileage is an empty string
    if (typeof vehicle.mileage === 'number' && vehicle.condition !== 'new') {
        dto.collateral[0].asset.mileage = vehicle.mileage
    }

    return dto
}

export const getBoatType = (type: string, make: string) => {
    if (make === 'Sea-Doo/BRP' && type === BoatType.PONTOON) {
        return BoatType.PONTOON_HYRBID
    }

    return type
}

function generateMarineHullAssets(marine: Marine) {
    const hull: CollateralMarine<AssetSubType>[] = []

    if (!marine.repower) {
        hull.push({
            asset: {
                condition: marine.condition,
                year: marine.year,
                make: marine.make,
                model: marine.model,
                hours: String(marine.hours) === '' ? undefined : marine.hours,
                invoice_price: marine.invoice,
                boat_type: getBoatType(marine.type, marine.make),
                length_ft: marine.length_ft ? marine.length_ft : 0,
            },
            asset_subtype: 'marine_hull',
            asset_type: 'marine',
            asset_value: marine.invoice,
            data_source: 'write_in',
        })
    }

    return hull
}

// TODO: This is a temporal function to create mock engine assets for marine, once we are able to get the inputs,
//       we have to replace this function with one to create each engine with its respective values.
function generateMarineEngineAssets(marine: Marine) {
    const engines: CollateralMarine<AssetSubType>[] = []
    const firstInvoice = marine.repower ? marine.invoice : 0

    for (let i = 0; i < marine.motors; i += 1) {
        const invoicePrice = i === 0 ? firstInvoice : 0

        engines.push({
            asset: {
                condition: marine.condition,
                year: new Date().getFullYear(),
                make: MARINE_INPUT_PLACEHOLDER_VALUE,
                model: MARINE_INPUT_PLACEHOLDER_VALUE,
                hours: String(marine.hours) === '' ? undefined : marine.hours,
                invoice_price: invoicePrice,
                engine_type: EngineType.OUTBOARD,
                horsepower: 0,
            },
            asset_subtype: AssetSubtype.MARINE_ENGINE,
            asset_type: 'marine',
            asset_value: invoicePrice,
            data_source: 'write_in',
        })
    }

    return engines
}

function generateTrailerAsset(marine: Marine) {
    const trailer: CollateralMarine<AssetSubType>[] = []

    if (marine.trailer === 'true') {
        trailer.push({
            asset: {
                sales_price: 0,
                description: MARINE_INPUT_PLACEHOLDER_VALUE,
                name: MARINE_INPUT_PLACEHOLDER_VALUE,
                condition: marine.condition,
            },
            asset_subtype: AssetSubtype.TRAILER,
            asset_type: 'marine',
            asset_value: 0,
            data_source: 'write_in',
        })
    }

    return trailer
}

export function createPostMarineCollateralsAssetsDTO(
    marine: Marine,
): PostCollateralsAssetsDTO {
    const collaterals: CollateralMarine<AssetSubType>[] = []
    const hull = generateMarineHullAssets(marine)
    const engines = generateMarineEngineAssets(marine)
    const trailer = generateTrailerAsset(marine)

    if (hull.length > 0) collaterals.push(...hull)

    if (engines.length > 0) collaterals.push(...engines)

    if (trailer.length > 0) collaterals.push(...trailer)

    const dto: PostCollateralsAssetsDTO = {
        collateral: collaterals,
    }

    return dto
}

export default async function postApplicationCollateralsAssets(
    uuid: string,
    collaterals: PostCollateralsAssetsDTO,
) {
    try {
        const { data } = await axios.post(
            // eslint-disable-next-line max-len
            `${config.API_BASE_URL}/api/v3/applications/${uuid}/collaterals-assets/`,
            collaterals,
            {
                withCredentials: true,
            },
        )

        return data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to post application collateral assets: ${axiosError.message}`,
            axiosError,
            {
                applicationUuid: uuid,
            },
        )

        throw new Error(axiosError.message)
    }
}
