import React, { useState } from 'react'

import { UiDeleteIcon, UiSendEmailIcon } from '@octane/spark'
import { resendInvitation } from 'api/invitation'
import { ApiResponse } from 'api/types'
import { AxiosError } from 'axios'
import RowActionButton from 'components/Table/RowActionButton'
import { ActionsContainer } from 'components/Table/Table.styled'
import UserInvitationCancelModal from 'components/UserInvitationCancelModal'
import { UserInvitation } from 'components/UserInvitationModal/types'
import useAppDispatch from 'hooks/useAppDispatch'
import { loadInvitations } from 'state/invitation/actions'
import * as toast from 'utils/toast'

type Props = {
    value: UserInvitation
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActionsColumn({ value }: Props) {
    const dispatch = useAppDispatch()

    const [isCancelInvitationModalOpen, setCancelInvitationModalOpen] =
        useState(false)

    function handleResend() {
        resendInvitation(value.token)
            .then((response: ApiResponse) => {
                toast.success({
                    message: response.message,
                })

                dispatch(loadInvitations())
            })
            .catch((error: AxiosError) => {
                toast.error({
                    message: error.message,
                })
            })
    }

    function handleCancel() {
        setCancelInvitationModalOpen(true)
    }

    return (
        <ActionsContainer>
            <RowActionButton
                title="Resend Invitation"
                icon={UiSendEmailIcon}
                onClick={() => handleResend()}
            />
            <RowActionButton
                title="Cancel Invitation"
                icon={UiDeleteIcon}
                onClick={() => handleCancel()}
            />
            {isCancelInvitationModalOpen && (
                <UserInvitationCancelModal
                    isOpen
                    onModalClose={() => setCancelInvitationModalOpen(false)}
                    token={value.token}
                />
            )}
        </ActionsContainer>
    )
}
