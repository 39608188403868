import { createAsyncThunk } from '@reduxjs/toolkit'
import { validateInvitation as validateInvitationApi } from 'api/invitation'

interface ParamsType {
    token: string
}

const validateInvitation = createAsyncThunk(
    'invitation/validate',
    async (params: ParamsType) => {
        const { token } = params

        return validateInvitationApi(token)
    },
)

export default validateInvitation
