import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

type UpdateLinkProps = {
    id: number
    dealerUserId?: string
    salesPersonId?: string
    dealershipId: number
    label: string
    isPrimary: boolean
}

export default async function updateLink({
    id,
    dealerUserId,
    salesPersonId,
    dealershipId,
    label,
    isPrimary,
}: UpdateLinkProps) {
    const requestBody = {
        label,
        salesperson_id: salesPersonId,
        dealer_user_id: dealerUserId,
        dealership_id: dealershipId,
        in_store: isPrimary,
    }

    if (dealerUserId) delete requestBody.salesperson_id

    if (salesPersonId) delete requestBody.dealer_user_id

    try {
        await axios.put(
            `${config.API_BASE_URL}/api/v3/dealer-assigned-links/${id}/`,
            requestBody,
            {
                withCredentials: true,
            },
        )

        return true
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to update link [${id}]: ${axiosError.message}`,
            axiosError,
        )

        throw axiosError
    }
}
