import * as typography from 'assets/styles/typography'
import styled from 'styled-components'

export const Container = styled('div')<{
    margin?: string
    width?: string
    bottomMargin?: string
}>`
    width: ${(props) => props.width || '859px'};
    margin: ${(props) => props.margin || '0px auto 20px 85px'};
    background-color: ${(props) => props.theme.colors.monochrome.white};
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid #d5d9e0;
    border-radius: 5px;

    &:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: ${(props) => props.bottomMargin || 0};
        border-bottom: 0;
    }

    &:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-bottom: 20px;
    }
`

export const Header = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
`

export const Title = styled.h2`
    ${typography.Copy24}
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
`

export const Subtitle = styled.h3`
    ${typography.Copy24}
    margin-top: 0px;
    margin-bottom: 0px;
`
