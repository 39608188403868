/* eslint-disable max-len */
import React from 'react'

import { Header, Body } from './ErrorHeader.styled'
import ErrorHeaderProps from './ErrorHeaderProps'

export default function ErrorHeader({ title, body }: ErrorHeaderProps) {
    return (
        <>
            <Header>{title}</Header>
            <Body>{body}</Body>
        </>
    )
}
