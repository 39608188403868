import React from 'react'

import { StatusIndicator } from '@octane/spark'

const defaultProps = {
    testid: '',
}

export default function FormStatusIndicator(props: {
    id: string
    testid?: string
    message: string
    variant: 'error' | 'complete' | 'noStatus'
    formIsValid: boolean
    submitAttempted: boolean
}) {
    const { id, testid, message, variant, formIsValid, submitAttempted } = props
    const showMessage = !formIsValid && submitAttempted

    if (!showMessage) {
        return null
    }

    return (
        <div data-testid={testid || id}>
            <StatusIndicator id={id} text={message} variant={variant} />
        </div>
    )
}

FormStatusIndicator.defaultProps = defaultProps
