import axios, { AxiosError } from 'axios'
import config from 'config'

import { OctaneLogger } from '../../integrations/datadog/OctaneLogger'

/**
 * Get an application by its identifier.
 *
 * @param  {number | string} [id] Application identifier.
 *  This can be the PK (int), UUID, or reference ID (prefixed by "r").
 * @param  {AbortController} [abortController] Optional abort controller.
 * @return {Object} Application data.
 */
export default async function getClientApplication(
    id: number | string,
    abortController: AbortController,
) {
    try {
        if (!id) {
            throw new Error('Application ID cannot be empty')
        }

        const { data } = await axios.get(
            `${config.API_BASE_URL}/api/v3/client-application/${id}/`,
            {
                signal: abortController.signal,
                withCredentials: true,
            },
        )

        return data
    } catch (error) {
        const axiosError = error as AxiosError

        OctaneLogger.axiosError(
            `Failed to get application: ${axiosError.message}`,
            axiosError,
            { applicationId: id },
        )

        throw axiosError
    }
}
