import { MaskedTextInput } from '@octane/spark'
import styled from 'styled-components'

export const FormikMaskedInput = styled(MaskedTextInput)`
    &:disabled {
        color: ${(props) => props.theme.colors.monochrome.asphalt};
        background-color: ${(props) => props.theme.colors.monochrome.moonGrey};
        border: none;
    }
`
