import styled, { keyframes } from 'styled-components'

export const Form = styled.div`
    text-align: center;
    svg {
        display: inline-block;
    }
`

export const Field = styled.div`
    margin: 20px 0;
`

const move = {
    up: keyframes`
        from {
            transform: translateY(400px);
            opacity: 0.7;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    `,
    down: keyframes`
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(400px);
        }
    `,
}

export const FloatPanel = styled.div`
    position: absolute;
    top: 80px;
    height: 390px;
    overflow: hidden;
    width: 450px;
    &.overflowed {
        overflow: unset;
    }
`

export const FloatPanelBody = styled.div`
    background-color: #fff;
    position: absolute;
    width: 450px;
    transform: translateY(400px);
    z-index: 100;
    &.hide {
        animation: ${move.down} 0.5s ease-in-out;
    }
    &.show {
        animation: ${move.up} 0.5s ease-in-out;
        transform: translateY(0px);
    }
`
