import { FieldInstruction } from 'containers/FormBuilder'
import InputField from 'form/fieldComponents/InputField'
import { FIELD_REQUIRED } from 'form/validation/validationMessages'
import * as Yup from 'yup'

export const username: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Email Address or Username',
        validator: Yup.string().required(FIELD_REQUIRED),
    },
}

export const password: FieldInstruction<typeof InputField> = {
    Component: InputField,
    config: {
        label: 'Password',
        type: 'password',
        validator: Yup.string().required(FIELD_REQUIRED),
    },
}
