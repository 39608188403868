import styled, { keyframes } from 'styled-components'

const move = (displacement: string | number) => ({
    up: keyframes`
        from {
            transform: translateY(${displacement});
            opacity: 0.7;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    `,
    down: keyframes`
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(${displacement});
        }
    `,
})

export const PanelContainer = styled.div`
    position: absolute;
    top: 80px;
    overflow: hidden;
    &.overflowed {
        overflow: unset;
    }
`

export const PanelBody = styled.div`
    background-color: #fff;
    position: absolute;
    transform: translateY(${(props) => props.style?.height});
    z-index: 100;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    &.hide {
        animation-name: ${(props) => move(props.style?.height ?? 0).down};
    }
    &.show {
        animation-name: ${(props) => move(props.style?.height ?? 0).up};
        transform: translateY(0);
    }
`
