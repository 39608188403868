import styled from 'styled-components'

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 40px;
    @media (max-width: 1280px) {
        & > :nth-child(2) {
            & > :nth-child(2) {
                margin-top: 17px;
            }
        }
    }
    @media (max-width: 1050px) {
        grid-template-columns: 1fr;
    }
`
